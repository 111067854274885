var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.string.js");require("./inflections.core.js");require("./medley.core.js");require("./metabase.lib.card.js");require("./metabase.lib.common.js");require("./metabase.lib.dispatch.js");require("./metabase.lib.equality.js");require("./metabase.lib.filter.js");require("./metabase.lib.filter.operator.js");require("./metabase.lib.hierarchy.js");require("./metabase.lib.join.util.js");require("./metabase.lib.metadata.js");require("./metabase.lib.metadata.calculation.js");require("./metabase.lib.options.js");require("./metabase.lib.query.js");require("./metabase.lib.ref.js");require("./metabase.lib.schema.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.expression.js");require("./metabase.lib.schema.filter.js");require("./metabase.lib.schema.join.js");require("./metabase.lib.schema.metadata.js");require("./metabase.lib.schema.temporal_bucketing.js");require("./metabase.lib.temporal_bucket.js");require("./metabase.lib.types.isa.js");require("./metabase.lib.util.js");require("./metabase.mbql.util.match.js");require("./metabase.shared.util.i18n.js");require("./metabase.util.js");require("./metabase.util.log.js");require("./metabase.util.malli.js");
'use strict';var Vpa,Wpa,Xpa,Ypa,h3,i3,j3,k3,Zpa,$pa,aqa,bqa,cqa,dqa,eqa,fqa,gqa,u3,v3,hqa,iqa,jqa,t3,s3,kqa,w3;$CLJS.c3=function(a,b){$CLJS.eb(function(c,d){return a.h?a.h(d):a.call(null,d)},null,b)};
Vpa=function(a){return function(b){var c=$CLJS.af(!0);return function(){function d(l,m){var t=$CLJS.q(c);if($CLJS.n($CLJS.n(t)?a.h?a.h(m):a.call(null,m):t))return l;$CLJS.Hl(c,null);return b.g?b.g(l,m):b.call(null,l,m)}function e(l){return b.h?b.h(l):b.call(null,l)}function f(){return b.o?b.o():b.call(null)}var k=null;k=function(l,m){switch(arguments.length){case 0:return f.call(this);case 1:return e.call(this,l);case 2:return d.call(this,l,m)}throw Error("Invalid arity: "+arguments.length);};k.o=
f;k.h=e;k.g=d;return k}()}};Wpa=function(a,b){return function f(d,e){return new $CLJS.oe(null,function(){var k;a:{var l=d;for(k=e;;){var m=l;l=$CLJS.I(m,0,null);if(m=$CLJS.y(m)){var t=a.h?a.h(l):a.call(null,l);if($CLJS.Hd(k,t))l=$CLJS.Lc(m);else{k=$CLJS.fe(l,f($CLJS.Lc(m),$CLJS.ce.g(k,t)));break a}}else{k=null;break a}}}return k},null,null)}(b,$CLJS.ch)};
Xpa=function(a){for(var b=[],c=arguments.length,d=0;;)if(d<c)b.push(arguments[d]),d+=1;else break;c=arguments[0];d=arguments[1];var e=arguments[2];b=3<b.length?new $CLJS.w(b.slice(3),0,null):null;return $CLJS.Ua($CLJS.y($CLJS.bA(c,d)))?c:$CLJS.Te.N($CLJS.UW,c,d,e,b)};$CLJS.d3=function(a,b){return $CLJS.w0.g(a,b instanceof $CLJS.M?b:$CLJS.ti.h(b))};
$CLJS.e3=function(a,b,c,d){if($CLJS.y(c)){d=$CLJS.Sk.g($CLJS.NV,d);var e=$CLJS.fg.j($CLJS.ch,$CLJS.jf.h(function(f){return $CLJS.H1.v(a,b,f,c)}),d);return $CLJS.Sk.g(function(f){return $CLJS.R.j(f,$CLJS.jZ,$CLJS.Hd(e,f))},c)}return null};$CLJS.f3=function(a){return $CLJS.E.g($CLJS.xB(a),$CLJS.KK)};Ypa=function(a,b){return $CLJS.n($CLJS.mE.h(a))?$CLJS.Tk.j(a,$CLJS.mE,function(c){return $CLJS.wd(c)?$CLJS.Sk.g(function(d){return $CLJS.g3.g?$CLJS.g3.g(d,b):$CLJS.g3.call(null,d,b)},c):c}):a};
h3=function(a){if($CLJS.n(a)){var b=$CLJS.mf;a:try{if($CLJS.zd(a)&&4===$CLJS.D(a))try{if($CLJS.F(a,0)instanceof $CLJS.M)try{var c=$CLJS.F(a,2);if($CLJS.zd(c)&&3===$CLJS.D(c))try{var d=$CLJS.F(c,0);if($CLJS.ie(d,$CLJS.FF))try{var e=$CLJS.F(a,3);if($CLJS.zd(e)&&3===$CLJS.D(e))try{var f=$CLJS.F(e,0);if($CLJS.ie(f,$CLJS.FF)){$CLJS.F(e,1);$CLJS.F(e,2);$CLJS.F(c,1);$CLJS.F(c,2);$CLJS.F(a,0);$CLJS.F(a,1);var k=new $CLJS.P(null,1,5,$CLJS.Q,[!0],null)}else throw $CLJS.Y;}catch(m){if(m instanceof Error){var l=
m;if(l===$CLJS.Y)throw $CLJS.Y;throw l;}throw m;}else throw $CLJS.Y;}catch(m){if(m instanceof Error){l=m;if(l===$CLJS.Y)throw $CLJS.Y;throw l;}throw m;}else throw $CLJS.Y;}catch(m){if(m instanceof Error){l=m;if(l===$CLJS.Y)throw $CLJS.Y;throw l;}throw m;}else throw $CLJS.Y;}catch(m){if(m instanceof Error){l=m;if(l===$CLJS.Y)throw $CLJS.Y;throw l;}throw m;}else throw $CLJS.Y;}catch(m){if(m instanceof Error){l=m;if(l===$CLJS.Y)throw $CLJS.Y;throw l;}throw m;}else throw $CLJS.Y;}catch(m){if(m instanceof
Error){l=m;if(l===$CLJS.Y){k=new $CLJS.P(null,1,5,$CLJS.Q,[!1],null);break a}throw l;}throw m;}a=$CLJS.z($CLJS.y(b($CLJS.Xa,k)))}else a=null;return a};i3=function(a){if($CLJS.n(h3(a))){$CLJS.I(a,0,null);$CLJS.I(a,1,null);var b=$CLJS.I(a,2,null);$CLJS.I(a,3,null);return b}return null};j3=function(a){return $CLJS.n(h3(a))?($CLJS.I(a,0,null),$CLJS.I(a,1,null),$CLJS.I(a,2,null),$CLJS.I(a,3,null)):null};
k3=function(a,b){var c=$CLJS.g3;if($CLJS.Ua(h3(a)))return a;var d=$CLJS.I(a,0,null),e=$CLJS.I(a,1,null),f=$CLJS.I(a,2,null);a=$CLJS.I(a,3,null);return new $CLJS.P(null,4,5,$CLJS.Q,[d,e,f,$CLJS.Te.j(c,a,b)],null)};
Zpa=function(a,b,c){return $CLJS.td($CLJS.SK.h(a))?a:$CLJS.n(b)?Xpa(a,new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.SK],null),function(d){return function l(f,k){try{if($CLJS.zd(k)&&3===$CLJS.D(k))try{var m=$CLJS.F(k,0);if($CLJS.ie(m,$CLJS.FF))try{var t=$CLJS.F(k,1);if(null!=t?t.C&256||$CLJS.Bc===t.lf||(t.C?0:$CLJS.$a($CLJS.wb,t)):$CLJS.$a($CLJS.wb,t))try{var u=$CLJS.J.j(t,$CLJS.QO,$CLJS.$U);if($CLJS.E.g(u,b))return $CLJS.F(k,2),$CLJS.g3.g?$CLJS.g3.g(k,c):$CLJS.g3.call(null,k,c);throw $CLJS.Y;}catch(x){if(x instanceof
Error){var v=x;if(v===$CLJS.Y)throw $CLJS.Y;throw v;}throw x;}else throw $CLJS.Y;}catch(x){if(x instanceof Error){v=x;if(v===$CLJS.Y)throw $CLJS.Y;throw v;}throw x;}else throw $CLJS.Y;}catch(x){if(x instanceof Error){v=x;if(v===$CLJS.Y)throw $CLJS.Y;throw v;}throw x;}else throw $CLJS.Y;}catch(x){if(x instanceof Error){v=x;if(v===$CLJS.Y)return $CLJS.wW(l,f,k);throw v;}throw x;}}($CLJS.Df,d)}):$CLJS.Tk.j(a,$CLJS.SK,function(d){return $CLJS.Sk.g(function(e){return k3(e,$CLJS.H([c]))},d)})};
$CLJS.g3=function(a,b){var c=$CLJS.xB(a);switch(c instanceof $CLJS.M?c.S:null){case "field":return $CLJS.bE(a,$CLJS.$D,$CLJS.H([$CLJS.QO,b]));case "metadata/column":return $CLJS.$D(a,$CLJS.yZ,b);case "mbql/join":return c=$CLJS.i1(a),Zpa(Ypa($CLJS.$D(a,$CLJS.qE,b),b),c,b);default:return b=$CLJS.lA($CLJS.cy),$CLJS.n($CLJS.kA("metabase.lib.join",b))&&$CLJS.jA("metabase.lib.join",b,$CLJS.aw.l($CLJS.H(["with-join-value should not be called with",$CLJS.Nh.l($CLJS.H([a]))])),null),a}};
$CLJS.l3=function(a,b,c){var d=$CLJS.R.l,e=$CLJS.$z($CLJS.UY,$CLJS.T)(c);a=$CLJS.j1($CLJS.qE.h(a),$CLJS.$z($CLJS.UY,$CLJS.T)(c));b=b.h?b.h(a):b.call(null,a);return d.call($CLJS.R,c,$CLJS.UY,e,$CLJS.H([$CLJS.iZ,b]))};$pa=function(a,b){return $CLJS.Ua(b)?a:$CLJS.Sk.g(function(c){var d=j3(c);d=$CLJS.n(d)?$CLJS.n($CLJS.i1(d))?null:k3(c,$CLJS.H([b])):null;return $CLJS.n(d)?d:c},a)};$CLJS.m3=function(a,b){b=$pa($CLJS.Sk.g($CLJS.OV,b),$CLJS.i1(a));return $CLJS.$D(a,$CLJS.SK,$CLJS.Ge(b))};
$CLJS.n3=function(a,b){b=b instanceof $CLJS.M?b:$CLJS.E.g(b,$CLJS.Df)?$CLJS.Fz:$CLJS.Ge($CLJS.fg.j($CLJS.Df,$CLJS.Qk.g($CLJS.jf.h($CLJS.NV),function(){var c=$CLJS.i1(a);return $CLJS.n(c)?$CLJS.jf.h(function(d){return $CLJS.g3(d,c)}):$CLJS.Td}()),b));return $CLJS.$D(a,$CLJS.mE,b)};
aqa=function(a,b){b=$CLJS.dm(function(d){return $CLJS.H1.g(d,a)},b);var c=$CLJS.QG($CLJS.$z($CLJS.c_,$CLJS.d_),b);if($CLJS.n(c))return c;b=$CLJS.z(b);if($CLJS.n(b))return b;b=$CLJS.QG($CLJS.c_,a);if($CLJS.n(b))return b;b=$CLJS.QG($CLJS.d_,a);return $CLJS.n(b)?b:$CLJS.z(a)};bqa=function(a){return"string"===typeof a?(0,$CLJS.ma)($CLJS.fA(a,/ id$/i,"")):null};
cqa=function(a,b){var c="string"===typeof a;if(c){if(c="string"===typeof b){c=new $CLJS.Kh(function(){return $CLJS.o2(b)});var d=new $CLJS.Kh(function(){return $CLJS.o2(a)});return $CLJS.E.g(a,b)||$CLJS.E.g(a,$CLJS.q(c))||$CLJS.E.g($CLJS.q(d),b)||$CLJS.E.g($CLJS.q(d),$CLJS.q(c))}return c}return c};
dqa=function(a,b,c){var d=$CLJS.D_.g($CLJS.E.g($CLJS.Fj.h(b),$CLJS.EV)?b:a,b),e=$CLJS.n(c)?bqa($CLJS.D_.g(a,c)):null,f=cqa(d,e);return function(){var k=function(){if($CLJS.n(d)){if($CLJS.n(e)){var l=$CLJS.Ua($CLJS.oh(/id/i,e));return l?(l=!f)?[$CLJS.p.h(d)," - ",e].join(""):l:l}return e}return d}();return $CLJS.n(k)?k:$CLJS.n(d)?d:$CLJS.n(e)?e:"source"}()};
eqa=function(a,b,c,d,e){return function m(k,l){try{if($CLJS.eY(l,$CLJS.FF)&&$CLJS.Ed($CLJS.H1.v(a,b,l,e)))return $CLJS.g3(l,d);throw $CLJS.Y;}catch(u){if(u instanceof Error){var t=u;if(t===$CLJS.Y)return $CLJS.wW(m,k,l);throw t;}throw u;}}($CLJS.Df,c)};
fqa=function(a,b,c,d,e,f){c=eqa(a,b,c,d,f);return function t(l,m){try{if($CLJS.zd(m)&&4===$CLJS.D(m))try{var u=$CLJS.F(m,2);if($CLJS.eY(u,$CLJS.FF))try{var v=$CLJS.F(m,3);if($CLJS.eY(v,$CLJS.FF)){var x=$CLJS.F(m,3),A=$CLJS.F(m,2),C=$CLJS.F(m,0),G=$CLJS.F(m,1),K=$CLJS.i1(A),S=$CLJS.i1(x);if($CLJS.Ua($CLJS.n(K)?K:S))return $CLJS.n($CLJS.H1.v(a,b,x,e))?new $CLJS.P(null,4,5,$CLJS.Q,[C,G,$CLJS.g3(A,d),x],null):new $CLJS.P(null,4,5,$CLJS.Q,[C,G,A,$CLJS.g3(x,d)],null);if($CLJS.E.g(K,d)&&$CLJS.E.g(S,d)){var W=
$CLJS.bE(A,$CLJS.Ik,$CLJS.H([$CLJS.QO])),Z=$CLJS.bE(x,$CLJS.Ik,$CLJS.H([$CLJS.QO])),ha=null==$CLJS.H1.v(a,b,W,e);var ua=ha?$CLJS.H1.v(a,b,Z,e):ha;return $CLJS.n(ua)?new $CLJS.P(null,4,5,$CLJS.Q,[C,G,A,Z],null):new $CLJS.P(null,4,5,$CLJS.Q,[C,G,W,x],null)}return m}throw $CLJS.Y;}catch(Cb){if(Cb instanceof Error){var Ta=Cb;if(Ta===$CLJS.Y)throw $CLJS.Y;throw Ta;}throw Cb;}else throw $CLJS.Y;}catch(Cb){if(Cb instanceof Error){Ta=Cb;if(Ta===$CLJS.Y)throw $CLJS.Y;throw Ta;}throw Cb;}else throw $CLJS.Y;
}catch(Cb){if(Cb instanceof Error){Ta=Cb;if(Ta===$CLJS.Y)return $CLJS.wW(t,l,m);throw Ta;}throw Cb;}}($CLJS.Df,c)};gqa=function(a,b){var c=$CLJS.eW();$CLJS.c3(c,b);return c(a)};
$CLJS.o3=function(a,b,c){if($CLJS.Hd(c,$CLJS.qE))return c;var d=$CLJS.bW(a,b),e=$CLJS.n0.j(a,b,d),f=$CLJS.y($CLJS.mf($CLJS.Xa,function v(t,u){try{if($CLJS.zd(u)&&1<=$CLJS.D(u))try{var x=$CLJS.Xk.j(u,0,1);if($CLJS.zd(x)&&1===$CLJS.D(x))try{var A=$CLJS.F(x,0);if($CLJS.ie(A,$CLJS.FF))return new $CLJS.P(null,1,5,$CLJS.Q,[u],null);throw $CLJS.Y;}catch(G){if(G instanceof Error){var C=G;if(C===$CLJS.Y)throw $CLJS.Y;throw C;}throw G;}else throw $CLJS.Y;}catch(G){if(G instanceof Error){C=G;if(C===$CLJS.Y)throw $CLJS.Y;
throw C;}throw G;}else throw $CLJS.Y;}catch(G){if(G instanceof Error){C=G;if(C===$CLJS.Y)return $CLJS.XU(v,t,u);throw C;}throw G;}}($CLJS.Df,$CLJS.SK.h(c))));f=aqa(e,f);var k=gqa(dqa(a,c,f),$CLJS.dm($CLJS.qE,$CLJS.$M.h(d))),l=$CLJS.l0.h($CLJS.b1.g(a,$CLJS.PK.h(c)));return $CLJS.g3($CLJS.Tk.j(c,$CLJS.SK,function(t){return $CLJS.Sk.g(function(u){return fqa(a,b,u,k,e,l)},t)}),k)};$CLJS.p3=function(a){return $CLJS.SK.h(a)};
$CLJS.q3=function(a){return $CLJS.hl.l($CLJS.H([new $CLJS.h(null,2,[$CLJS.Fj,$CLJS.TK,$CLJS.YD,a],null),$CLJS.E.g(a,$CLJS.vE)?new $CLJS.h(null,1,[$CLJS.di,!0],null):null]))};$CLJS.r3=function(a,b){b=$CLJS.z($CLJS.PK.h(b));return $CLJS.n($CLJS.kV.h(b))?$CLJS.y_(a,$CLJS.kV.h(b)):$CLJS.n($CLJS.BO.h(b))?$CLJS.w_(a,$CLJS.BO.h(b)):null};
u3=function(a){a=$CLJS.PG(function(d){return $CLJS.d_(d)?s3:$CLJS.c_(d)?t3:$CLJS.vt},a);var b=$CLJS.O(a);a=$CLJS.J.g(b,s3);var c=$CLJS.J.g(b,t3);b=$CLJS.J.g(b,$CLJS.vt);return $CLJS.hf.l(a,c,$CLJS.H([b]))};v3=function(a,b,c,d){return $CLJS.Ua(c)?d:$CLJS.Sk.g(function(e){return $CLJS.n($CLJS.jZ.h(e))?$CLJS.d3(e,$CLJS.m2(c)):e},$CLJS.e3(a,b,d,new $CLJS.P(null,1,5,$CLJS.Q,[c],null)))};
hqa=function(a,b,c,d){var e=new $CLJS.Kh(function(){return $CLJS.n0.v(a,b,d,new $CLJS.h(null,2,[$CLJS.S_,!1,$CLJS.$_,!1],null))});return $CLJS.Ge($CLJS.fg.j($CLJS.Df,$CLJS.Yl(function(f){var k=$CLJS.O(f),l=$CLJS.J.g(k,$CLJS.uK);return $CLJS.n(function(){var m=$CLJS.c_(k);return m?l:m}())?(f=$CLJS.QG(function(m){return $CLJS.E.g(l,$CLJS.$i.h(m))},$CLJS.q(e)),$CLJS.n(f)?$CLJS.R.j(k,w3,f):null):null}),$CLJS.n0.j(a,b,c)))};iqa={};
jqa=new $CLJS.M("metabase.lib.join","join-fields","metabase.lib.join/join-fields",-2085751377);t3=new $CLJS.M(null,"fk","fk",398442651);s3=new $CLJS.M(null,"pk","pk",-771936732);kqa=new $CLJS.r("metabase.lib.stage","ensure-previous-stages-have-metadata","metabase.lib.stage/ensure-previous-stages-have-metadata",2048342183,null);w3=new $CLJS.M("metabase.lib.join","target","metabase.lib.join/target",203858838);$CLJS.c0.m(null,$CLJS.KK,function(a,b,c){b=$CLJS.O(c);b=$CLJS.J.g(b,$CLJS.PK);b=$CLJS.I(b,0,null);b=$CLJS.O(b);c=$CLJS.J.g(b,$CLJS.BO);b=$CLJS.J.g(b,$CLJS.kV);c=$CLJS.n(c)?$CLJS.ID.h($CLJS.w_(a,c)):null;if($CLJS.n(c))return c;$CLJS.n(b)?(c=$CLJS.y_(a,b),a=$CLJS.n(c)?$CLJS.D_.j(a,0,c):$CLJS.n1(b)):a=null;return $CLJS.n(a)?a:$CLJS.eE("Native Query")});$CLJS.j0.m(null,$CLJS.KK,function(a,b,c){a=$CLJS.D_.j(a,b,c);c=$CLJS.qE.h(c);return new $CLJS.h(null,2,[$CLJS.T,$CLJS.n(c)?c:a,$CLJS.ID,a],null)});
$CLJS.i0.m(null,$CLJS.KK,function(){throw $CLJS.ii("You can't calculate a metadata map for a join! Use lib.metadata.calculation/returned-columns-method instead.",$CLJS.N);});
$CLJS.c0.m(null,$CLJS.TK,function(a,b,c){a=$CLJS.O(c);a=$CLJS.J.g(a,$CLJS.YD);a=a instanceof $CLJS.M?a.S:null;switch(a){case "left-join":return $CLJS.eE("Left outer join");case "right-join":return $CLJS.eE("Right outer join");case "inner-join":return $CLJS.eE("Inner join");case "full-join":return $CLJS.eE("Full outer join");default:throw Error(["No matching clause: ",$CLJS.p.h(a)].join(""));}});
$CLJS.j0.m(null,$CLJS.TK,function(a,b,c){var d=$CLJS.O(c),e=$CLJS.J.g(d,$CLJS.YD);c=$CLJS.J.g(d,$CLJS.di);a=new $CLJS.h(null,2,[$CLJS.a0,$CLJS.zA(e),$CLJS.ID,$CLJS.D_.j(a,b,d)],null);return $CLJS.n(c)?$CLJS.R.j(a,$CLJS.di,!0):a});
$CLJS.k0.m(null,$CLJS.KK,function(a,b,c,d){var e=$CLJS.O(c),f=$CLJS.J.g(e,$CLJS.qE),k=$CLJS.J.j(e,$CLJS.mE,$CLJS.Fz),l=$CLJS.J.g(e,$CLJS.PK);c=$CLJS.O(d);var m=$CLJS.J.g(c,$CLJS.Y_);if($CLJS.E.g(k,$CLJS.Fz))return null;var t="undefined"!==typeof $CLJS.CL&&"undefined"!==typeof iqa&&"undefined"!==typeof $CLJS.lqa&&"undefined"!==typeof $CLJS.x3?new $CLJS.Gc(function(){return $CLJS.x3},$CLJS.od(kqa,new $CLJS.h(null,1,[$CLJS.ck,!0],null)),null):null,u=function(){var v=$CLJS.R.j(a,$CLJS.PK,l);return $CLJS.n(t)?
t.g?t.g(v,-1):t.call(null,v,-1):v}();c=$CLJS.E.g(k,$CLJS.Xx)?$CLJS.l0.v(u,-1,$CLJS.qd(l),c):function(){return function A(x){return new $CLJS.oe(null,function(){for(;;){var C=$CLJS.y(x);if(C){if($CLJS.Ad(C)){var G=$CLJS.lc(C),K=$CLJS.D(G),S=$CLJS.re(K);a:for(var W=0;;)if(W<K){var Z=$CLJS.kd(G,W);Z=$CLJS.bE(Z,$CLJS.Ik,$CLJS.H([$CLJS.QO]));Z=$CLJS.B_.j(u,-1,Z);S.add(Z);W+=1}else{G=!0;break a}return G?$CLJS.ue($CLJS.we(S),A($CLJS.mc(C))):$CLJS.ue($CLJS.we(S),null)}S=$CLJS.z(C);S=$CLJS.bE(S,$CLJS.Ik,$CLJS.H([$CLJS.QO]));
return $CLJS.fe($CLJS.B_.j(u,-1,S),A($CLJS.Lc(C)))}return null}},null,null)}(k)}();return $CLJS.Sk.g(function(v){v=$CLJS.R.j(v,$CLJS.wZ,f);v=$CLJS.g3($CLJS.R.l(v,$CLJS.ID,$CLJS.D_.j(a,b,v),$CLJS.H([$CLJS.iK,$CLJS.fZ])),f);return $CLJS.l3(e,m,v)},c)});$CLJS.m0.m(null,$CLJS.KK,function(a,b,c,d){return $CLJS.l0.v(a,b,$CLJS.R.j(c,$CLJS.mE,$CLJS.Xx),d)});
var y3,mqa=$CLJS.Ye($CLJS.N),nqa=$CLJS.Ye($CLJS.N),oqa=$CLJS.Ye($CLJS.N),pqa=$CLJS.Ye($CLJS.N),qqa=$CLJS.J.j(new $CLJS.h(null,1,[$CLJS.ij,$CLJS.zB],null),$CLJS.ij,$CLJS.Wh.o?$CLJS.Wh.o():$CLJS.Wh.call(null));y3=new $CLJS.ei($CLJS.Gh.g("metabase.lib.join","join-clause-method"),$CLJS.xB,qqa,mqa,nqa,oqa,pqa);y3.m(null,$CLJS.KK,function(a){return a});y3.m(null,$CLJS.EV,function(a){return $CLJS.MV(new $CLJS.h(null,2,[$CLJS.Fj,$CLJS.KK,$CLJS.PK,$CLJS.PK.h($CLJS.$V(a))],null))});
y3.m(null,$CLJS.rV,function(a){return $CLJS.MV(new $CLJS.h(null,2,[$CLJS.Fj,$CLJS.KK,$CLJS.PK,new $CLJS.P(null,1,5,$CLJS.Q,[a],null)],null))});y3.m(null,$CLJS.GK,function(a){return $CLJS.MV(new $CLJS.h(null,2,[$CLJS.Fj,$CLJS.KK,$CLJS.PK,new $CLJS.P(null,1,5,$CLJS.Q,[new $CLJS.h(null,2,[$CLJS.kV,$CLJS.$i.h(a),$CLJS.Fj,$CLJS.rV],null)],null)],null))});
y3.m(null,$CLJS.oK,function(a){var b=$CLJS.O(a),c=$CLJS.J.g(b,$CLJS.yZ);a=$CLJS.J.g(b,jqa);b=y3.h(new $CLJS.h(null,3,[$CLJS.Fj,$CLJS.rV,$CLJS.UA,new $CLJS.h(null,1,[$CLJS.UD,$CLJS.p.h($CLJS.dE())],null),$CLJS.BO,$CLJS.$i.h(b)],null));c=$CLJS.n(c)?$CLJS.g3(b,c):b;return $CLJS.n(a)?$CLJS.n3.g?$CLJS.n3.g(c,a):$CLJS.n3.call(null,c,a):c});
$CLJS.z3=function(){function a(d,e){return $CLJS.m3(c.h?c.h(d):c.call(null,d),e)}function b(d){return $CLJS.t1(y3.h(d),$CLJS.mE,$CLJS.Xx)}var c=null;c=function(d,e){switch(arguments.length){case 1:return b.call(this,d);case 2:return a.call(this,d,e)}throw Error("Invalid arity: "+arguments.length);};c.h=b;c.g=a;return c}();
$CLJS.rqa=function(){function a(d,e,f){var k=$CLJS.z3.h(f);f=$CLJS.td($CLJS.p3.h?$CLJS.p3.h(k):$CLJS.p3.call(null,k))?function(){var m=$CLJS.r3.g?$CLJS.r3.g(d,k):$CLJS.r3.call(null,d,k);return $CLJS.A3.j?$CLJS.A3.j(d,e,m):$CLJS.A3.call(null,d,e,m)}():null;f=$CLJS.y(f)?$CLJS.m3(k,f):k;var l=$CLJS.o3(d,e,f);return $CLJS.nW.l(d,e,$CLJS.Tk,$CLJS.H([$CLJS.$M,function(m){return $CLJS.ce.g($CLJS.Ef(m),l)}]))}function b(d,e){return c.j?c.j(d,-1,e):c.call(null,d,-1,e)}var c=null;c=function(d,e,f){switch(arguments.length){case 2:return b.call(this,
d,e);case 3:return a.call(this,d,e,f)}throw Error("Invalid arity: "+arguments.length);};c.g=b;c.j=a;return c}();$CLJS.B3=function(){function a(d,e){return $CLJS.Ge($CLJS.J.g($CLJS.bW(d,e),$CLJS.$M))}function b(d){return c.g?c.g(d,-1):c.call(null,d,-1)}var c=null;c=function(d,e){switch(arguments.length){case 1:return b.call(this,d);case 2:return a.call(this,d,e)}throw Error("Invalid arity: "+arguments.length);};c.h=b;c.g=a;return c}();
$CLJS.sqa=function(){function a(d){d=$CLJS.Z0(d);d=$CLJS.Gz.h(d);return $CLJS.fg.j($CLJS.Df,$CLJS.Qk.g($CLJS.lf($CLJS.We($CLJS.Hd,d)),$CLJS.jf.h($CLJS.q3)),new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.vE,$CLJS.lE,$CLJS.zE,$CLJS.CE],null))}function b(d){return c.g?c.g(d,-1):c.call(null,d,-1)}var c=null;c=function(d,e){switch(arguments.length){case 1:return b.call(this,d);case 2:return a.call(this,d,e)}throw Error("Invalid arity: "+arguments.length);};c.h=b;c.g=a;return c}();
$CLJS.tqa=function(){function a(d,e,f,k){var l=$CLJS.f3(f)?$CLJS.i1(f):null,m=$CLJS.fg.j($CLJS.ch,$CLJS.Qk.g($CLJS.jf.h($CLJS.i1),Vpa(function(t){return $CLJS.Ok.g(t,l)})),$CLJS.B3.g(d,e));f=$CLJS.n(k)?k:$CLJS.f3(f)?i3($CLJS.z($CLJS.p3(f))):null;return u3(v3(d,e,f,$CLJS.Rk.g(function(t){t=$CLJS.i1(t);return $CLJS.n(t)?$CLJS.Hd(m,t):null},$CLJS.n0.v(d,e,$CLJS.bW(d,e),new $CLJS.h(null,1,[$CLJS.S_,!1],null)))))}function b(d,e,f,k){return c.N?c.N(d,-1,e,f,k):c.call(null,d,-1,e,f,k)}var c=null;c=function(d,
e,f,k,l){switch(arguments.length){case 4:return b.call(this,d,e,f,k);case 5:return a.call(this,d,e,f,k,l)}throw Error("Invalid arity: "+arguments.length);};c.v=b;c.N=a;return c}();
$CLJS.uqa=function(){function a(d,e,f,k,l){k=$CLJS.f3(f)?$CLJS.r3(d,f):f;var m=$CLJS.f3(f)?$CLJS.i1(f):null;f=$CLJS.n(l)?l:$CLJS.f3(f)?j3($CLJS.z($CLJS.p3(f))):null;f=$CLJS.n(f)?$CLJS.Ua(m)?$CLJS.bE(f,$CLJS.Ik,$CLJS.H([$CLJS.QO])):f:null;return u3(v3(d,e,f,$CLJS.jf.g(function(t){t=$CLJS.R.j(t,$CLJS.iK,$CLJS.fZ);return $CLJS.n(m)?$CLJS.g3(t,m):t},$CLJS.n0.v(d,e,k,new $CLJS.h(null,1,[$CLJS.S_,!1],null)))))}function b(d,e,f,k){return c.N?c.N(d,-1,e,f,k):c.call(null,d,-1,e,f,k)}var c=null;c=function(d,
e,f,k,l){switch(arguments.length){case 4:return b.call(this,d,e,f,k);case 5:return a.call(this,d,e,f,k,l)}throw Error("Invalid arity: "+arguments.length);};c.v=b;c.N=a;return c}();$CLJS.vqa=function(){function a(c,d,e){return b.v?b.v(c,-1,d,e):b.call(null,c,-1,d,e)}var b=null;b=function(c,d,e,f){switch(arguments.length){case 3:return a.call(this,c,d,e);case 4:return $CLJS.X1}throw Error("Invalid arity: "+arguments.length);};b.j=a;b.v=function(){return $CLJS.X1};return b}();
$CLJS.A3=function(){function a(d,e,f){function k(u,v){u=$CLJS.Ik.g(u,$CLJS.p1);v=$CLJS.Ik.g(v,$CLJS.p1);return $CLJS.b3.l($CLJS.V1.h($CLJS.or),u,$CLJS.H([v]))}function l(u,v){return $CLJS.Ge(Wpa(function(x){return $CLJS.$i.h(w3.h(x))},hqa(d,e,u,v)))}var m=$CLJS.bW(d,e),t=function(){var u=l(m,f);return $CLJS.n(u)?$CLJS.Sk.g(function(v){return k(v,w3.h(v))},u):null}();if($CLJS.n(t))return t;t=l(f,m);return $CLJS.n(t)?$CLJS.Sk.g(function(u){return k(w3.h(u),u)},t):null}function b(d,e){return c.j?c.j(d,
-1,e):c.call(null,d,-1,e)}var c=null;c=function(d,e,f){switch(arguments.length){case 2:return b.call(this,d,e);case 3:return a.call(this,d,e,f)}throw Error("Invalid arity: "+arguments.length);};c.g=b;c.j=a;return c}();
$CLJS.wqa=function(){function a(e,f,k,l){l=$CLJS.n(l)?l:$CLJS.f3(k)?i3($CLJS.z($CLJS.p3(k))):null;$CLJS.n(l)?(l=$CLJS.G_.j(e,f,l),l=$CLJS.bA(l,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.kW,$CLJS.ID],null))):l=null;if($CLJS.n(l))return l;if(l=0===$CLJS.aW(e,f)){l=$CLJS.B3.g(e,f);var m=$CLJS.td(l);k=m?m:$CLJS.f3(k)?$CLJS.E.g($CLJS.qE.h(k),$CLJS.qE.h($CLJS.z(l))):null;k=$CLJS.n(k)?$CLJS.q_(e):k}else k=l;$CLJS.n(k)?(k=$CLJS.q_(e),k=$CLJS.w_(e,k),e=$CLJS.D_.j(e,f,k)):e=null;return $CLJS.n(e)?e:$CLJS.eE("Previous results")}
function b(e,f,k){return d.v?d.v(e,-1,f,k):d.call(null,e,-1,f,k)}function c(e,f){return d.j?d.j(e,f,null):d.call(null,e,f,null)}var d=null;d=function(e,f,k,l){switch(arguments.length){case 2:return c.call(this,e,f);case 3:return b.call(this,e,f,k);case 4:return a.call(this,e,f,k,l)}throw Error("Invalid arity: "+arguments.length);};d.g=c;d.j=b;d.v=a;return d}();
$CLJS.xqa=function(){function a(d,e,f,k){f=$CLJS.OV.h(f);$CLJS.I(f,0,null);$CLJS.I(f,1,null);var l=$CLJS.I(f,2,null),m=$CLJS.I(f,3,null);k=k instanceof $CLJS.M?k:$CLJS.ti.h(k);e=$CLJS.aW(d,e);l=$CLJS.A0.j(d,e,l);d=$CLJS.A0.j(d,e,m);m=null==k||$CLJS.Hd($CLJS.gh($CLJS.jf.g($CLJS.ti,l)),k);d=null==k||$CLJS.Hd($CLJS.gh($CLJS.jf.g($CLJS.ti,d)),k);f=m?$CLJS.Tk.v(f,2,$CLJS.d3,k):f;return d?$CLJS.Tk.v(f,3,$CLJS.d3,k):f}function b(d,e,f){return c.v?c.v(d,-1,e,f):c.call(null,d,-1,e,f)}var c=null;c=function(d,
e,f,k){switch(arguments.length){case 3:return b.call(this,d,e,f);case 4:return a.call(this,d,e,f,k)}throw Error("Invalid arity: "+arguments.length);};c.j=b;c.v=a;return c}();$CLJS.f0.m(null,$CLJS.$M,function(a,b){var c=$CLJS.Ge($CLJS.B3.g(a,b));c=null==c?null:$CLJS.jf.g(function(d){return $CLJS.D_.j(a,b,d)},c);return null==c?null:$CLJS.Ms(" + ",c)});