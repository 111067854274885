var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.core.protocols.js");require("./clojure.string.js");require("./clojure.walk.js");require("./medley.core.js");require("./metabase.lib.metadata.protocols.js");require("./metabase.lib.util.js");require("./metabase.util.js");require("./metabase.util.log.js");
'use strict';var b9,a9,Fwa,Gwa,Hwa,j9,Iwa,Jwa,Kwa,l9,Lwa,Mwa,c9,o9,Nwa,q9,Owa,Pwa,Qwa;$CLJS.$8=function(a,b){return $CLJS.n($CLJS.n(a)?b in a:a)?$CLJS.Ca(a,b):null};b9=function(a,b){return a9(a,b,$CLJS.N)};
a9=function(a,b,c){c=$CLJS.O(c);c=$CLJS.J.j(c,c9,!0);if($CLJS.xd(b))return $CLJS.fg.j($CLJS.N,a,b);c=$CLJS.n(c)?function(){var d=$CLJS.$8(b,"_plainObject");d=null==d?null:$CLJS.nz(d);return null==d?null:$CLJS.Ge(d)}():null;return $CLJS.n(c)?$CLJS.fg.j($CLJS.N,a,c):$CLJS.fg.j($CLJS.N,$CLJS.Qk.j($CLJS.jf.h(function(d){return new $CLJS.P(null,2,5,$CLJS.Q,[d,$CLJS.$8(b,d)],null)}),$CLJS.Rk.h(function(d){$CLJS.I(d,0,null);return"function"===typeof $CLJS.I(d,1,null)}),a),$CLJS.Ba(b))};
Fwa=function(a){var b=d9.h(a),c=f9.h(a),d=g9.h(a);return $CLJS.Qk.j($CLJS.jf.h(function(e){var f=$CLJS.I(e,0,null);e=$CLJS.I(e,1,null);var k=$CLJS.Q;f=$CLJS.Ah.h(h9(f));if($CLJS.n(d)){var l=d.h?d.h(f):d.call(null,f);f=$CLJS.n(l)?l:f}return new $CLJS.P(null,2,5,k,[f,e],null)}),$CLJS.td(b)?$CLJS.Td:$CLJS.Rk.h(function(e){var f=$CLJS.I(e,0,null);$CLJS.I(e,1,null);return $CLJS.Hd(b,f)}),$CLJS.Ua(c)?$CLJS.Td:$CLJS.jf.h(function(e){var f=$CLJS.I(e,0,null);e=$CLJS.I(e,1,null);return new $CLJS.P(null,2,5,
$CLJS.Q,[f,c.g?c.g(f,e):c.call(null,f,e)],null)}))};Gwa=function(a){return b9($CLJS.jf.h(function(b){var c=$CLJS.I(b,0,null);b=$CLJS.I(b,1,null);c=$CLJS.Ah.h(h9(c));c=$CLJS.E.g(c,$CLJS.ZU)?$CLJS.YD:c;b=$CLJS.E.g(c,$CLJS.YD)?$CLJS.Ah.h(b):b;return new $CLJS.P(null,2,5,$CLJS.Q,[c,b],null)}),a)};
Hwa=function(a){var b=$CLJS.QG(function(d){d=$CLJS.$8(d,"type");var e=new $CLJS.bh(null,new $CLJS.h(null,2,["internal",null,"external",null],null),null);return e.h?e.h(d):e.call(null,d)},a);if($CLJS.n(b)){var c=$CLJS.Ah.h($CLJS.$8(b,"type"));return $CLJS.hl.l($CLJS.H([new $CLJS.h(null,2,[$CLJS.$i,$CLJS.$8(b,"id"),$CLJS.T,$CLJS.$8(b,"name")],null),function(){var d=c instanceof $CLJS.M?c.S:null;switch(d){case "external":return new $CLJS.h(null,2,[$CLJS.Fj,$CLJS.HK,$CLJS.rK,$CLJS.$8(b,"human_readable_field_id")],
null);case "internal":return new $CLJS.h(null,1,[$CLJS.Fj,$CLJS.BK],null);default:throw Error(["No matching clause: ",$CLJS.p.h(d)].join(""));}}()]))}return null};j9=function(a){return $CLJS.Sk.g(i9.g($CLJS.FF,$CLJS.N),a)};
Iwa=function(a,b){var c=i9.g($CLJS.SM,new $CLJS.h(null,1,[c9,!1],null)),d=i9.g($CLJS.SM,$CLJS.N);return $CLJS.hl.l($CLJS.H([$CLJS.R.j(function(){var e=$CLJS.$8($CLJS.$8(a,"tables"),["card__",$CLJS.p.h(b)].join(""));return c.h?c.h(e):c.call(null,e)}(),$CLJS.$i,b),function(){var e=$CLJS.$8($CLJS.$8(a,"questions"),$CLJS.p.h(b));var f=$CLJS.$8(e,"_card");e=$CLJS.n(f)?f:e;return d.h?d.h(e):d.call(null,e)}()]))};Jwa=new $CLJS.M(null,"plain-object","plain-object",-943264281);
$CLJS.k9=new $CLJS.M(null,"cards","cards",169174038);Kwa=new $CLJS.M(null,"binning-info","binning-info",-2117036653);l9=new $CLJS.M("metabase.lib.js.metadata","field-values","metabase.lib.js.metadata/field-values",-88384962);Lwa=new $CLJS.M(null,"db","db",993250759);$CLJS.m9=new $CLJS.M(null,"metrics","metrics",394093469);Mwa=new $CLJS.M(null,"fks","fks",2041987076);$CLJS.n9=new $CLJS.M(null,"segments","segments",1937535949);c9=new $CLJS.M(null,"use-plain-object?","use-plain-object?",-1174595475);
o9=new $CLJS.M("metabase.lib.js.metadata","dimension","metabase.lib.js.metadata/dimension",2004773191);Nwa=new $CLJS.M(null,"metadata","metadata",1799301597);$CLJS.p9=new $CLJS.M(null,"tables","tables",1334623052);q9=new $CLJS.M(null,"dimension-options","dimension-options",2122379680);Owa=new $CLJS.M(null,"schema-name","schema-name",1666725119);Pwa=new $CLJS.M(null,"_comesFromEndpoint","_comesFromEndpoint",-1390455588);Qwa=new $CLJS.M(null,"default-dimension-option","default-dimension-option",149055958);
$CLJS.r9=new $CLJS.M(null,"databases","databases",2040134125);var h9=$CLJS.Qh($CLJS.EA),d9,Rwa=$CLJS.Ye($CLJS.N),Swa=$CLJS.Ye($CLJS.N),Twa=$CLJS.Ye($CLJS.N),Uwa=$CLJS.Ye($CLJS.N),Vwa=$CLJS.J.j($CLJS.N,$CLJS.ij,$CLJS.Wh.o?$CLJS.Wh.o():$CLJS.Wh.call(null)),u9,kxa,lxa,mxa,nxa,oxa;d9=new $CLJS.ei($CLJS.Gh.g("metabase.lib.js.metadata","excluded-keys"),$CLJS.Ah,Vwa,Rwa,Swa,Twa,Uwa);d9.m(null,$CLJS.di,function(){return null});
var f9,Wwa=$CLJS.Ye($CLJS.N),Xwa=$CLJS.Ye($CLJS.N),Ywa=$CLJS.Ye($CLJS.N),Zwa=$CLJS.Ye($CLJS.N),$wa=$CLJS.J.j($CLJS.N,$CLJS.ij,$CLJS.Wh.o?$CLJS.Wh.o():$CLJS.Wh.call(null));f9=new $CLJS.ei($CLJS.Gh.g("metabase.lib.js.metadata","parse-field-fn"),$CLJS.Ah,$wa,Wwa,Xwa,Ywa,Zwa);f9.m(null,$CLJS.di,function(){return null});var s9,axa=$CLJS.Ye($CLJS.N),bxa=$CLJS.Ye($CLJS.N),cxa=$CLJS.Ye($CLJS.N),dxa=$CLJS.Ye($CLJS.N),exa=$CLJS.J.j($CLJS.N,$CLJS.ij,$CLJS.Wh.o?$CLJS.Wh.o():$CLJS.Wh.call(null));
s9=new $CLJS.ei($CLJS.Gh.g("metabase.lib.js.metadata","lib-type"),$CLJS.Ah,exa,axa,bxa,cxa,dxa);var g9,fxa=$CLJS.Ye($CLJS.N),gxa=$CLJS.Ye($CLJS.N),hxa=$CLJS.Ye($CLJS.N),ixa=$CLJS.Ye($CLJS.N),jxa=$CLJS.J.j($CLJS.N,$CLJS.ij,$CLJS.Wh.o?$CLJS.Wh.o():$CLJS.Wh.call(null));g9=new $CLJS.ei($CLJS.Gh.g("metabase.lib.js.metadata","rename-key-fn"),$CLJS.Td,jxa,fxa,gxa,hxa,ixa);g9.m(null,$CLJS.di,function(){return null});
var i9=function(){var a=$CLJS.Ye($CLJS.N),b=$CLJS.Ye($CLJS.N),c=$CLJS.Ye($CLJS.N),d=$CLJS.Ye($CLJS.N),e=$CLJS.J.j($CLJS.N,$CLJS.ij,$CLJS.Wh.o?$CLJS.Wh.o():$CLJS.Wh.call(null));return new $CLJS.ei($CLJS.Gh.g("metabase.lib.js.metadata","parse-object-fn*"),function(f){return f},e,a,b,c,d)}();
i9.m(null,$CLJS.di,function(a,b){var c=Fwa(a),d=s9.h(a);return function(e){try{var f=$CLJS.R.j(a9(c,e,b),$CLJS.Fj,d),k=$CLJS.lA($CLJS.by);$CLJS.n($CLJS.kA("metabase.lib.js.metadata",k))&&$CLJS.jA("metabase.lib.js.metadata",k,$CLJS.RV("Parsed metadata %s %s\n%s",$CLJS.H([a,$CLJS.$i.h(f),$CLJS.SV(f)])),null);return f}catch(l){if(l instanceof Error)return f=l,k=$CLJS.lA($CLJS.cy),$CLJS.n($CLJS.kA("metabase.lib.js.metadata",k))&&(f instanceof Error?$CLJS.jA("metabase.lib.js.metadata",k,$CLJS.RV("Error parsing %s %s: %s",
$CLJS.H([a,$CLJS.Nh.l($CLJS.H([e])),$CLJS.PU(f)])),f):$CLJS.jA("metabase.lib.js.metadata",k,$CLJS.RV(f,$CLJS.H(["Error parsing %s %s: %s",a,$CLJS.Nh.l($CLJS.H([e])),$CLJS.PU(f)])),null)),null;throw l;}}});$CLJS.t9=function(){var a=$CLJS.Ye($CLJS.N),b=$CLJS.Ye($CLJS.N),c=$CLJS.Ye($CLJS.N),d=$CLJS.Ye($CLJS.N),e=$CLJS.J.j($CLJS.N,$CLJS.ij,$CLJS.Wh.o?$CLJS.Wh.o():$CLJS.Wh.call(null));return new $CLJS.ei($CLJS.Gh.g("metabase.lib.js.metadata","parse-objects"),function(f){return $CLJS.Ah.h(f)},e,a,b,c,d)}();
kxa=$CLJS.Ye($CLJS.N);lxa=$CLJS.Ye($CLJS.N);mxa=$CLJS.Ye($CLJS.N);nxa=$CLJS.Ye($CLJS.N);oxa=$CLJS.J.j($CLJS.N,$CLJS.ij,$CLJS.Wh.o?$CLJS.Wh.o():$CLJS.Wh.call(null));u9=new $CLJS.ei($CLJS.Gh.g("metabase.lib.js.metadata","parse-objects-default-key"),$CLJS.Ah,oxa,kxa,lxa,mxa,nxa);
$CLJS.t9.m(null,$CLJS.di,function(a,b){var c=i9.g(a,$CLJS.N);return b9($CLJS.jf.h(function(d){var e=$CLJS.I(d,0,null),f=$CLJS.I(d,1,null);return new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.QV(e),new $CLJS.Kh(function(){return c.h?c.h(f):c.call(null,f)})],null)}),$CLJS.$8(b,u9.h(a)))});s9.m(null,$CLJS.qP,function(){return $CLJS.DK});d9.m(null,$CLJS.qP,function(){return new $CLJS.bh(null,new $CLJS.h(null,2,[$CLJS.mE,null,$CLJS.p9,null],null),null)});
f9.m(null,$CLJS.qP,function(){return function(a,b){switch(a instanceof $CLJS.M?a.S:null){case "dbms-version":return $CLJS.mz(b,$CLJS.H([$CLJS.Ai,!0]));case "features":return $CLJS.fg.j($CLJS.ch,$CLJS.jf.h($CLJS.Ah),b);case "native-permissions":return $CLJS.Ah.h(b);default:return b}}});u9.m(null,$CLJS.qP,function(){return"databases"});s9.m(null,$CLJS.kW,function(){return $CLJS.oK});
d9.m(null,$CLJS.kW,function(){return new $CLJS.bh(null,new $CLJS.h(null,5,[q9,null,$CLJS.mE,null,$CLJS.n9,null,$CLJS.qP,null,$CLJS.m9,null],null),null)});f9.m(null,$CLJS.kW,function(){return function(a,b){switch(a instanceof $CLJS.M?a.S:null){case "entity-type":return $CLJS.Ah.h(b);case "field-order":return $CLJS.Ah.h(b);case "initial-sync-status":return $CLJS.Ah.h(b);case "visibility-type":return $CLJS.Ah.h(b);default:return b}}});
$CLJS.t9.m(null,$CLJS.kW,function(a,b){var c=i9.g(a,$CLJS.N);return b9($CLJS.Qk.g($CLJS.Rk.h(function(d){var e=$CLJS.I(d,0,null);$CLJS.I(d,1,null);return 0==e.lastIndexOf("card__",0)}),$CLJS.jf.h(function(d){var e=$CLJS.I(d,0,null),f=$CLJS.I(d,1,null);return new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.QV(e),new $CLJS.Kh(function(){return c.h?c.h(f):c.call(null,f)})],null)})),$CLJS.$8(b,"tables"))});s9.m(null,$CLJS.FF,function(){return $CLJS.gK});
d9.m(null,$CLJS.FF,function(){return new $CLJS.bh(null,new $CLJS.h(null,6,[q9,null,$CLJS.kW,null,Qwa,null,$CLJS.qP,null,Pwa,null,$CLJS.m9,null],null),null)});g9.m(null,$CLJS.FF,function(){return new $CLJS.h(null,6,[$CLJS.Az,$CLJS.iK,$CLJS.ti,$CLJS.fW,$CLJS.wR,$CLJS.MD,Kwa,$CLJS.lW,$CLJS.dL,o9,$CLJS.ws,l9],null)});
f9.m(null,$CLJS.FF,function(){return function(a,b){switch(a instanceof $CLJS.M?a.S:null){case "base-type":return $CLJS.Ah.h(b);case "coercion-strategy":return $CLJS.Ah.h(b);case "effective-type":return $CLJS.Ah.h(b);case "fingerprint":return $CLJS.xd(b)?$CLJS.SU(b):$CLJS.mz(b,$CLJS.H([$CLJS.Ai,!0]));case "has-field-values":return $CLJS.Ah.h(b);case "lib/source":switch(b){case "aggregation":return $CLJS.IK;case "breakout":return $CLJS.zK;default:return $CLJS.Ah.g("source",b)}case "metabase.lib.field/temporal-unit":return $CLJS.Ah.h(b);
case "semantic-type":return $CLJS.Ah.h(b);case "visibility-type":return $CLJS.Ah.h(b);case "id":return $CLJS.zd(b)?$CLJS.hd(b):b;case "metabase.lib.field/binning":return Gwa(b);case "metabase.lib.js.metadata/field-values":return $CLJS.E.g($CLJS.$8(b,"type"),"full")?new $CLJS.h(null,2,[$CLJS.ws,$CLJS.nz($CLJS.$8(b,"values")),$CLJS.nK,$CLJS.nz($CLJS.$8(b,"human_readable_values"))],null):null;case "metabase.lib.js.metadata/dimension":return Hwa(b);default:return b}}});
i9.m(null,$CLJS.FF,function(a,b){var c=function(){var d=$CLJS.IH(i9,$CLJS.di);return d.g?d.g(a,b):d.call(null,a,b)}();return function(d){d=c.h?c.h(d):c.call(null,d);var e=$CLJS.O(d);d=$CLJS.J.g(e,o9);var f=$CLJS.O(d),k=$CLJS.J.g(f,$CLJS.Fj),l=$CLJS.J.g(e,l9);return $CLJS.Ik.l(function(){switch(k instanceof $CLJS.M?k.S:null){case "metadata.column.remapping/external":return $CLJS.R.j(e,$CLJS.vK,f);case "metadata.column.remapping/internal":return $CLJS.R.j(e,$CLJS.CK,$CLJS.hl.l($CLJS.H([f,l])));default:return e}}(),
o9,$CLJS.H([l9]))}});
$CLJS.t9.m(null,$CLJS.FF,function(a,b){var c=i9.g(a,$CLJS.N),d=$CLJS.$8(b,"fields");return b9($CLJS.Yl(function(e){var f=$CLJS.I(e,0,null),k=$CLJS.I(e,1,null);e=function(){var l=$CLJS.QV(f);if($CLJS.n(l))return l;l=$CLJS.oh(/card__\d+:(\d+)/,f);if($CLJS.n(l)){$CLJS.I(l,0,null);l=$CLJS.I(l,1,null);var m=null==$CLJS.$8(d,l);return m?$CLJS.QV(l):m}return null}();return $CLJS.n(e)?new $CLJS.P(null,2,5,$CLJS.Q,[e,new $CLJS.Kh(function(){return c.h?c.h(k):c.call(null,k)})],null):null}),d)});
s9.m(null,$CLJS.SM,function(){return $CLJS.GK});d9.m(null,$CLJS.SM,function(){return new $CLJS.bh(null,new $CLJS.h(null,11,[q9,null,$CLJS.nk,null,Mwa,null,$CLJS.kW,null,Lwa,null,Jwa,null,$CLJS.n9,null,$CLJS.qP,null,Nwa,null,$CLJS.m9,null,Owa,null],null),null)});
f9.m(null,$CLJS.SM,function(){return function(a,b){switch(a instanceof $CLJS.M?a.S:null){case "result-metadata":return $CLJS.n($CLJS.$z($CLJS.wd,$CLJS.Sa)(b))?j9(b):$CLJS.mz(b,$CLJS.H([$CLJS.Ai,!0]));case "fields":return j9(b);case "visibility-type":return $CLJS.Ah.h(b);case "dataset-query":return $CLJS.mz(b,$CLJS.H([$CLJS.Ai,!0]));case "dataset":return b;default:return b}}});
$CLJS.t9.m(null,$CLJS.SM,function(a,b){return $CLJS.fg.j($CLJS.N,$CLJS.jf.h(function(c){return new $CLJS.P(null,2,5,$CLJS.Q,[c,new $CLJS.Kh(function(){return Iwa(b,c)})],null)}),$CLJS.fg.j($CLJS.fg.j($CLJS.ch,$CLJS.Yl($CLJS.cW),$CLJS.Ba($CLJS.$8(b,"tables"))),$CLJS.jf.h($CLJS.QV),$CLJS.Ba($CLJS.$8(b,"questions"))))});s9.m(null,$CLJS.AF,function(){return $CLJS.mK});d9.m(null,$CLJS.AF,function(){return new $CLJS.bh(null,new $CLJS.h(null,2,[$CLJS.kW,null,$CLJS.qP,null],null),null)});
f9.m(null,$CLJS.AF,function(){return function(a,b){return b}});u9.m(null,$CLJS.AF,function(){return"metrics"});s9.m(null,$CLJS.cF,function(){return $CLJS.kK});d9.m(null,$CLJS.cF,function(){return new $CLJS.bh(null,new $CLJS.h(null,2,[$CLJS.kW,null,$CLJS.qP,null],null),null)});f9.m(null,$CLJS.cF,function(){return function(a,b){return b}});u9.m(null,$CLJS.cF,function(){return"segments"});$CLJS.v9=i9.g($CLJS.FF,$CLJS.N);