var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.string.js");require("./medley.core.js");require("./metabase.lib.common.js");require("./metabase.lib.hierarchy.js");require("./metabase.lib.metadata.js");require("./metabase.lib.metadata.calculation.js");require("./metabase.lib.options.js");require("./metabase.lib.ref.js");require("./metabase.lib.schema.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.expression.js");require("./metabase.lib.schema.temporal_bucketing.js");require("./metabase.lib.temporal_bucket.js");require("./metabase.lib.util.js");require("./metabase.shared.util.i18n.js");require("./metabase.types.js");require("./metabase.util.js");require("./metabase.util.malli.js");
'use strict';var D0,xoa,yoa,E0,F0;$CLJS.B0=function(a,b){return $CLJS.gc($CLJS.eb(function(c,d){return $CLJS.n(a.h?a.h(d):a.call(null,d))?$CLJS.fc(c,d):c},$CLJS.ec($CLJS.Df),b))};D0=function(a){return $CLJS.n(C0)?["(",$CLJS.p.h(a),")"].join(""):a};xoa=function(a,b,c){var d=$CLJS.bW(a,b);a=$CLJS.n0.j(a,b,d);var e=$CLJS.AA(c);return $CLJS.Se(function(f){return $CLJS.E.g($CLJS.AA($CLJS.T.h(f)),e)},a)};
yoa=function(a,b){var c=$CLJS.Tk.v(a,$CLJS.vP,$CLJS.oD($CLJS.ce,$CLJS.Df),b);return $CLJS.zd($CLJS.mE.h(a))?$CLJS.Tk.v(c,$CLJS.mE,$CLJS.ce,$CLJS.MV(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.QA,$CLJS.N,$CLJS.VV(b)],null))):c};E0=function(a,b,c){var d=$CLJS.VV(c);return $CLJS.R.l($CLJS.B_.j(a,b,c),$CLJS.iK,$CLJS.cZ,$CLJS.H([$CLJS.T,d,$CLJS.ID,d]))};F0=new $CLJS.M("metabase.lib.expression","infix-operator","metabase.lib.expression/infix-operator",246733613);
$CLJS.G0=new $CLJS.M("lib","source-uuid","lib/source-uuid",1828897581);var H0=function(){function a(d,e,f){var k=$CLJS.bW(d,e);k=$CLJS.QG($CLJS.Qk.g($CLJS.dh([f]),$CLJS.VV),$CLJS.vP.h(k));if($CLJS.n(k))return k;throw $CLJS.ii($CLJS.DD("No expression named {0}",$CLJS.H([$CLJS.Nh.l($CLJS.H([f]))])),new $CLJS.h(null,3,[$CLJS.wR,f,$CLJS.aF,d,$CLJS.aL,e],null));}function b(d,e){return c.j?c.j(d,-1,e):c.call(null,d,-1,e)}var c=null;c=function(d,e,f){switch(arguments.length){case 2:return b.call(this,d,e);case 3:return a.call(this,d,e,f)}throw Error("Invalid arity: "+arguments.length);
};c.g=b;c.j=a;return c}();$CLJS.g0.m(null,$CLJS.QA,function(a,b,c){$CLJS.I(c,0,null);$CLJS.I(c,1,null);c=$CLJS.I(c,2,null);c=H0.j(a,b,c);return $CLJS.h0.j(a,b,c)});$CLJS.i0.m(null,$CLJS.QA,function(a,b,c){$CLJS.I(c,0,null);var d=$CLJS.I(c,1,null),e=$CLJS.I(c,2,null);return new $CLJS.h(null,7,[$CLJS.Fj,$CLJS.gK,$CLJS.G0,$CLJS.UD.h(d),$CLJS.T,e,$CLJS.MD,e,$CLJS.ID,$CLJS.D_.j(a,b,c),$CLJS.TA,$CLJS.h0.j(a,b,c),$CLJS.iK,$CLJS.cZ],null)});$CLJS.c0.m(null,$CLJS.LA,function(a,b,c){return $CLJS.p.h(c)});
$CLJS.c0.m(null,$CLJS.JA,function(a,b,c){return $CLJS.p.h(c)});$CLJS.c0.m(null,$CLJS.KA,function(a,b,c){return['"',$CLJS.p.h(c),'"'].join("")});$CLJS.c0.m(null,$CLJS.ZA,function(a,b,c){return $CLJS.p.h(c)});$CLJS.c0.m(null,$CLJS.QA,function(a,b,c){$CLJS.I(c,0,null);$CLJS.I(c,1,null);return $CLJS.I(c,2,null)});$CLJS.d0.m(null,$CLJS.QA,function(a,b,c){$CLJS.I(c,0,null);$CLJS.I(c,1,null);return $CLJS.I(c,2,null)});
for(var C0=!1,zoa=new $CLJS.h(null,4,[$CLJS.qr,"+",$CLJS.Ds,"-",$CLJS.rr,"×",$CLJS.aG,"÷"],null),I0=$CLJS.y(new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.qr,$CLJS.Ds,$CLJS.aG,$CLJS.rr],null)),J0=null,K0=0,L0=0;;)if(L0<K0){var Aoa=J0.X(null,L0);$CLJS.LE(Aoa,F0);L0+=1}else{var M0=$CLJS.y(I0);if(M0){var N0=M0;if($CLJS.Ad(N0)){var O0=$CLJS.lc(N0),Boa=$CLJS.mc(N0),Coa=O0,Doa=$CLJS.D(O0);I0=Boa;J0=Coa;K0=Doa}else{var Eoa=$CLJS.z(N0);$CLJS.LE(Eoa,F0);I0=$CLJS.B(N0);J0=null;K0=0}L0=0}else break}
$CLJS.c0.m(null,F0,function(a,b,c){var d=$CLJS.y(c);c=$CLJS.z(d);d=$CLJS.B(d);$CLJS.z(d);d=$CLJS.B(d);c=$CLJS.J.g(zoa,c);a:{var e=C0;C0=!0;try{var f=$CLJS.Ms([" ",$CLJS.hh(c)," "].join(""),$CLJS.jf.g($CLJS.Bt($CLJS.D_,a,b),d));break a}finally{C0=e}f=void 0}return D0(f)});$CLJS.d0.m(null,F0,function(){return"expression"});
$CLJS.g0.m(null,$CLJS.WG,function(a,b,c){c=$CLJS.y(c);$CLJS.z(c);c=$CLJS.B(c);$CLJS.z(c);var d=$CLJS.B(c);return $CLJS.Qd($CLJS.NE,function(){return function k(f){return new $CLJS.oe(null,function(){for(;;){var l=$CLJS.y(f);if(l){if($CLJS.Ad(l)){var m=$CLJS.lc(l),t=$CLJS.D(m),u=$CLJS.re(t);a:for(var v=0;;)if(v<t){var x=$CLJS.kd(m,v);x=$CLJS.h0.j(a,b,x);u.add(x);v+=1}else{m=!0;break a}return m?$CLJS.ue($CLJS.we(u),k($CLJS.mc(l))):$CLJS.ue($CLJS.we(u),null)}u=$CLJS.z(l);return $CLJS.fe($CLJS.h0.j(a,
b,u),k($CLJS.Lc(l)))}return null}},null,null)}(d)}())});$CLJS.c0.m(null,$CLJS.DI,function(a,b,c,d){$CLJS.I(c,0,null);$CLJS.I(c,1,null);var e=$CLJS.I(c,2,null),f=$CLJS.I(c,3,null);c=$CLJS.I(c,4,null);a=$CLJS.p.h($CLJS.D_.v(a,b,e,d));b=$CLJS.p;d=b.h;c=$CLJS.v0.g(f,c).toLowerCase();0<f?f=$CLJS.fa.j?$CLJS.fa.j("+ %d %s",f,c):$CLJS.fa.call(null,"+ %d %s",f,c):(f=$CLJS.Zz(f),f=$CLJS.fa.j?$CLJS.fa.j("- %d %s",f,c):$CLJS.fa.call(null,"- %d %s",f,c));f=D0(f);return[a," ",d.call(b,f)].join("")});
$CLJS.d0.m(null,$CLJS.DI,function(a,b,c){$CLJS.I(c,0,null);$CLJS.I(c,1,null);var d=$CLJS.I(c,2,null),e=$CLJS.I(c,3,null);c=$CLJS.I(c,4,null);a=$CLJS.p.h($CLJS.e0.j(a,b,d));b=$CLJS.p;d=b.h;c=$CLJS.v0.g(e,c).toLowerCase();0<e?e=$CLJS.fa.j?$CLJS.fa.j("plus_%s_%s",e,c):$CLJS.fa.call(null,"plus_%s_%s",e,c):(e=$CLJS.Zz(e),e=$CLJS.fa.j?$CLJS.fa.j("minus_%d_%s",e,c):$CLJS.fa.call(null,"minus_%d_%s",e,c));return[a,"_",d.call(b,e)].join("")});
$CLJS.c0.m(null,$CLJS.IF,function(a,b,c,d){$CLJS.I(c,0,null);$CLJS.I(c,1,null);var e=$CLJS.I(c,2,null);$CLJS.I(c,3,null);return $CLJS.D_.v(a,b,e,d)});$CLJS.d0.m(null,$CLJS.IF,function(a,b,c){$CLJS.I(c,0,null);$CLJS.I(c,1,null);var d=$CLJS.I(c,2,null);$CLJS.I(c,3,null);return $CLJS.e0.j(a,b,d)});
$CLJS.Foa=function(){function a(d,e,f,k){e=$CLJS.n(e)?e:-1;if($CLJS.n(xoa(d,e,f)))throw $CLJS.ii("Expression name conflicts with a column in the same query stage",new $CLJS.h(null,1,[$CLJS.wR,f],null));return $CLJS.nW.l(d,e,yoa,$CLJS.H([$CLJS.WV($CLJS.OV.h(k),f)]))}function b(d,e,f){return c.v?c.v(d,-1,e,f):c.call(null,d,-1,e,f)}var c=null;c=function(d,e,f,k){switch(arguments.length){case 3:return b.call(this,d,e,f);case 4:return a.call(this,d,e,f,k)}throw Error("Invalid arity: "+arguments.length);
};c.j=b;c.v=a;return c}();$CLJS.Goa=function(){function a(d,e){var f=$CLJS.Ge($CLJS.vP.h($CLJS.bW(d,e)));return null==f?null:$CLJS.Sk.g($CLJS.Bt(E0,d,e),f)}function b(d){return c.g?c.g(d,-1):c.call(null,d,-1)}var c=null;c=function(d,e){switch(arguments.length){case 1:return b.call(this,d);case 2:return a.call(this,d,e)}throw Error("Invalid arity: "+arguments.length);};c.h=b;c.g=a;return c}();
$CLJS.P0=function(){function a(d,e){return $CLJS.Ge($CLJS.vP.h($CLJS.bW(d,e)))}function b(d){return c.g?c.g(d,-1):c.call(null,d,-1)}var c=null;c=function(d,e){switch(arguments.length){case 1:return b.call(this,d);case 2:return a.call(this,d,e)}throw Error("Invalid arity: "+arguments.length);};c.h=b;c.g=a;return c}();$CLJS.LV.m(null,$CLJS.QA,function(a){return a});
$CLJS.Hoa=function(){function a(d,e,f){var k=$CLJS.fg.g($CLJS.N,$CLJS.em(function(m,t){return new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.VV(t),m],null)},$CLJS.P0.g(d,e))),l=$CLJS.bW(d,e);d=$CLJS.n0.j(d,e,l);return $CLJS.Ge($CLJS.B0(function(m){return $CLJS.Ua(f)||$CLJS.Ok.g($CLJS.iK.h(m),$CLJS.cZ)||$CLJS.J.g(k,$CLJS.T.h(m))<f},d))}function b(d,e){return c.j?c.j(d,-1,e):c.call(null,d,-1,e)}var c=null;c=function(d,e,f){switch(arguments.length){case 2:return b.call(this,d,e);case 3:return a.call(this,d,e,f)}throw Error("Invalid arity: "+
arguments.length);};c.g=b;c.j=a;return c}();$CLJS.Ioa=function(){function a(d,e,f){return $CLJS.NV(E0(d,e,H0.j(d,e,f)))}function b(d,e){return c.j?c.j(d,-1,e):c.call(null,d,-1,e)}var c=null;c=function(d,e,f){switch(arguments.length){case 2:return b.call(this,d,e);case 3:return a.call(this,d,e,f)}throw Error("Invalid arity: "+arguments.length);};c.g=b;c.j=a;return c}();