var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.set.js");require("./malli.core.js");require("./malli.error.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.expression.temporal.js");require("./metabase.lib.schema.id.js");require("./metabase.lib.schema.literal.js");require("./metabase.mbql.schema.helpers.js");require("./metabase.mbql.schema.macros.js");require("./metabase.shared.util.i18n.js");require("./metabase.util.malli.registry.js");
'use strict';var nL,oL,pL,sia,tia,tL,uia,wL,xL,yL,zL,via,AL,BL,DL,EL,qL,FL,wia,GL,xia,HL,IL,JL,yia,KL,LL,zia,ML,Aia,NL,OL,Bia,QL,Cia,Dia,Eia,SL,TL,UL,VL,WL,XL,YL,ZL,Fia,$L,aM,bM,dM,eM,Gia,fM,gM,hM,iM,Hia,jM,kM,lM,mM,Iia,nM,oM,pM,qM,Jia,rM,Kia,sM,tM,uM,Lia,vM,wM,xM,yM,AM,BM,Mia,CM,DM,Nia,EM,FM,GM,HM,IM,Oia,JM,Pia,KM,LM,NM,Qia,OM,PM,QM,Ria,TM,UM,Sia,Tia,VM,XM,YM,ZM,Uia,bN,cN,Via,Wia,Xia,dN,eN,fN,gN,hN,iN,Yia,jN,Zia,kN,lN,nN,pN,qN,rN,sN,$ia,aja,uN,bja,cja,vN,wN,xN,yN,zN,dja,AN,eja,fja,BN,CN,DN,EN,FN,
gja,hja,ija,GN,jja,HN,kja,JN,KN,lja,MN,NN,ON,PN,mja,SN,nja,oja,TN,UN,VN,pja,WN,XN,YN,ZN,qja,aO,bO,cO,dO,eO,rja,fO,gO,sja,hO,iO,jO,kO,lO,mO,oO,tja,pO,uja,qO,rO,sO,vja,wja,xja,uO,vO,wO,yja,xO,yO,zO,AO,zja,Aja,CO,Bja,Cja,DO,EO,FO,GO,HO,Dja,IO,JO,KO,LO,Eja,MO,NO,OO,PO,RO,Fja,SO,TO,Gja,UO,VO,WO,Hja,XO,Ija,ZO,Jja,$O,aP,bP,cP,dP,eP,fP,Kja,Lja,Mja,gP,Nja,hP,iP,Oja,Pja,jP,kP,lP,Qja,mP,Rja,nP,Sja,Tja,oP,Uja,Vja,Wja,rP,sP,Xja,tP,uP,wP,Yja,xP,Zja,yP,zP,AP,$ja,aka,BP,CP,DP,EP,bka,GP,HP,cka,IP,JP,KP,LP,MP,NP,OP,
PP,dka,QP,RP,eka,SP,fka,TP,UP,VP,gka,WP,XP,hka,ika,YP,ZP,$P,jka,kka,aQ,lka,mka,nka,cQ,oka,eQ,pka,qka,fQ,gQ,rka,ska,tka,hQ,iQ,jQ,kQ,lQ,mQ,nQ,uka,oQ,pQ,vka,qQ,wka,xka,yka,rQ,sQ,tQ,zka,uQ,vQ,Aka,wQ,Bka,xQ,Cka,Dka,zQ,AQ,BQ,CQ,DQ,Eka,EQ,FQ,Fka,GQ,HQ,IQ,JQ,KQ,MQ,NQ,OQ,Gka,PQ,QQ,SQ,TQ,UQ,VQ,WQ,XQ,YQ,ZQ,$Q,aR,Hka,bR,cR,dR,eR,fR,Ika,gR,hR,iR,jR,Jka,lR,Kka,Lka,nR,Mka,oR,Nka,pR,Oka,Pka,qR,Qka,rR,tR,uR,Rka,Ska,yR,zR,Tka,Uka,AR,BR,CR,DR,ER,Vka,FR,GR,IR,JR,Wka;
$CLJS.mL=function(a){var b=$CLJS.lo.g(a,null),c=$CLJS.HE(b,$CLJS.gs,function(d){return $CLJS.qn(d,$CLJS.Df)});return function(){function d(k,l,m){return(l=$CLJS.y(c.j?c.j(k,l,m):c.call(null,k,l,m)))?new $CLJS.h(null,3,[$CLJS.nk,b,$CLJS.Ej,k,$CLJS.ms,l],null):null}function e(k){return f.j(k,$CLJS.Df,$CLJS.Df)}var f=null;f=function(k,l,m){switch(arguments.length){case 1:return e.call(this,k);case 3:return d.call(this,k,l,m)}throw Error("Invalid arity: "+arguments.length);};f.h=e;f.j=d;return f}()};
nL=function(a,b){return $CLJS.xd(a)?$CLJS.J.g(a,b):a};oL=function(a,b,c,d){d=$CLJS.n(d)?d:$CLJS.tn($CLJS.lo.g($CLJS.nk.h(a),null));if($CLJS.n(b)){var e=nL($CLJS.it.h(b),c);$CLJS.n(e)?(e=$CLJS.gq(e,d),a=e.g?e.g(a,d):e.call(null,a,d)):a=null;return $CLJS.n(a)?a:nL($CLJS.Ys.h(b),c)}return null};pL=function(a,b){return $CLJS.vd(a)||$CLJS.Kl(a)?$CLJS.J.g(a,b):$CLJS.wd(a)?$CLJS.J.g($CLJS.Ef(a),b):null};
sia=function(a,b){a=$CLJS.O(a);var c=$CLJS.J.g(a,$CLJS.nk),d=$CLJS.J.g(a,$CLJS.nj);b=$CLJS.O(b);var e=$CLJS.J.j(b,$CLJS.ms,$CLJS.kca),f=$CLJS.J.j(b,$CLJS.fca,!0),k=$CLJS.J.g(b,$CLJS.jt),l=$CLJS.J.j(b,$CLJS.gt,$CLJS.et),m=oL(a,$CLJS.iq.h(c),k,b);if($CLJS.n(m))return m;m=oL(a,$CLJS.xs.h(c),k,b);if($CLJS.n(m))return m;m=oL(a,e.h?e.h(d):e.call(null,d),k,b);if($CLJS.n(m))return m;m=oL(a,function(){var t=$CLJS.Xn.h(c);return e.h?e.h(t):e.call(null,t)}(),k,b);if($CLJS.n(m))return m;m=oL(a,$CLJS.iq.h(c),
l,b);if($CLJS.n(m))return m;m=oL(a,$CLJS.xs.h(c),l,b);if($CLJS.n(m))return m;d=oL(a,e.h?e.h(d):e.call(null,d),l,b);if($CLJS.n(d))return d;d=oL(a,function(){var t=$CLJS.Xn.h(c);return e.h?e.h(t):e.call(null,t)}(),l,b);if($CLJS.n(d))return d;k=$CLJS.n(f)?oL(a,e.h?e.h($CLJS.ct):e.call(null,$CLJS.ct),k,b):f;return $CLJS.n(k)?k:$CLJS.n(f)?oL(a,e.h?e.h($CLJS.ct):e.call(null,$CLJS.ct),l,b):f};
tia=function(a,b,c){a=$CLJS.Q;var d=$CLJS.fg.g;var e=$CLJS.ol.h(b);var f=$CLJS.O(b);var k=$CLJS.J.g(f,$CLJS.nk),l=$CLJS.O(c);f=$CLJS.J.g(l,$CLJS.jt);l=$CLJS.J.j(l,$CLJS.gt,$CLJS.et);k=$CLJS.iq.h(k);f=nL($CLJS.ft.h(k),f);f=$CLJS.n(f)?f:nL($CLJS.ft.h(k),l);d=d.call($CLJS.fg,e,f);return new $CLJS.P(null,2,5,a,[d,sia(b,c)],null)};
$CLJS.rL=function(a){var b=$CLJS.O(a),c=$CLJS.J.g(b,$CLJS.Ej);a=$CLJS.J.g(b,$CLJS.ms);var d=$CLJS.O(null),e=$CLJS.J.j(d,$CLJS.gca,$CLJS.Ri),f=$CLJS.J.j(d,$CLJS.ica,tia);return $CLJS.n(a)?$CLJS.eb(function(k,l){var m=f.j?f.j(b,l,d):f.call(null,b,l,d),t=$CLJS.I(m,0,null);m=$CLJS.I(m,1,null);l=$CLJS.R.j(l,$CLJS.Ri,m);l=e.h?e.h(l):e.call(null,l);return qL(k,c,t,l)},null,a):null};
tL=function(a,b,c){var d=$CLJS.bA($CLJS.q($CLJS.xD),new $CLJS.P(null,2,5,$CLJS.Q,[a,b],null));if($CLJS.n(d))return d;c=c.o?c.o():c.call(null);$CLJS.Ph.v($CLJS.xD,$CLJS.sL,new $CLJS.P(null,2,5,$CLJS.Q,[a,b],null),c);return c};$CLJS.uL=function(a){return tL($CLJS.yk,a,function(){return $CLJS.IE.h(a)})};uia=function(){var a=vL;return tL($CLJS.gs,a,function(){var b=$CLJS.IE.h(a),c=$CLJS.mL(a);return function(d){return $CLJS.n(b.h?b.h(d):b.call(null,d))?null:c.h?c.h(d):c.call(null,d)}})};
wL=function(a){return new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.nk,$CLJS.ne(a)?new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,a],null):a],null)};xL=function(a){if($CLJS.zd(a)){var b=$CLJS.I(a,0,null),c=$CLJS.I(a,1,null);switch(b instanceof $CLJS.M?b.S:null){case "optional":return new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.sr,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Lr,wL(c)],null)],null);case "rest":return new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.rr,wL(c)],null);default:return wL(a)}}else return wL(a)};
yL=function(a,b){var c=$CLJS.zd(b);return c?(c=$CLJS.z(b)instanceof $CLJS.M)?$CLJS.ud(a)?(b=$CLJS.z(b),a=$CLJS.gh(a),a.h?a.h(b):a.call(null,b)):$CLJS.E.g(a,$CLJS.z(b)):c:c};
zL=function(a,b){return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Tr,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Si,new $CLJS.h(null,1,[$CLJS.Ys,["not a ",$CLJS.p.h(a)," clause"].join("")],null),$CLJS.We(yL,a)],null),$CLJS.fg.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Dr,new $CLJS.P(null,2,5,$CLJS.Q,["tag",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.or,a],null)],null)],null),function(){return function e(d){return new $CLJS.oe(null,function(){for(;;){var f=$CLJS.y(d);if(f){if($CLJS.Ad(f)){var k=$CLJS.lc(f),l=$CLJS.D(k),m=$CLJS.re(l);
a:for(var t=0;;)if(t<l){var u=$CLJS.kd(k,t),v=$CLJS.I(u,0,null);u=$CLJS.I(u,1,null);v=new $CLJS.P(null,2,5,$CLJS.Q,[v,xL(u)],null);m.add(v);t+=1}else{k=!0;break a}return k?$CLJS.ue($CLJS.we(m),e($CLJS.mc(f))):$CLJS.ue($CLJS.we(m),null)}k=$CLJS.z(f);m=$CLJS.I(k,0,null);k=$CLJS.I(k,1,null);return $CLJS.fe(new $CLJS.P(null,2,5,$CLJS.Q,[m,xL(k)],null),e($CLJS.Lc(f)))}return null}},null,null)}($CLJS.As(2,2,b))}())],null)};via=function(a){return $CLJS.zd(a)&&$CLJS.z(a)instanceof $CLJS.M?$CLJS.z(a):null};
AL=function(a){return $CLJS.fg.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Sr,new $CLJS.h(null,2,[$CLJS.Pi,via,$CLJS.Ys,["valid instance of one of these MBQL clauses: ",$CLJS.Ms(", ",$CLJS.jf.g($CLJS.z,a))].join("")],null)],null),function(){return function d(c){return new $CLJS.oe(null,function(){for(;;){var e=$CLJS.y(c);if(e){if($CLJS.Ad(e)){var f=$CLJS.lc(e),k=$CLJS.D(f),l=$CLJS.re(k);a:for(var m=0;;)if(m<k){var t=$CLJS.kd(f,m),u=$CLJS.I(t,0,null);t=$CLJS.I(t,1,null);u=new $CLJS.P(null,2,5,$CLJS.Q,[u,
$CLJS.ne(t)?new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,t],null):t],null);l.add(u);m+=1}else{f=!0;break a}return f?$CLJS.ue($CLJS.we(l),d($CLJS.mc(e))):$CLJS.ue($CLJS.we(l),null)}f=$CLJS.z(e);l=$CLJS.I(f,0,null);f=$CLJS.I(f,1,null);return $CLJS.fe(new $CLJS.P(null,2,5,$CLJS.Q,[l,$CLJS.ne(f)?new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,f],null):f],null),d($CLJS.Lc(e)))}return null}},null,null)}(a)}())};
BL=function(a){return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Tr,a,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,$CLJS.lL],null)],null)};$CLJS.CL={};DL={};EL={};$CLJS.sL=function sL(a,b,c){var e=$CLJS.y(b);b=$CLJS.z(e);var f=$CLJS.B(e);if(f){e=$CLJS.R.j;var k=$CLJS.J.g(a,b);c=sL.j?sL.j(k,f,c):sL.call(null,k,f,c);a=e.call($CLJS.R,a,b,c)}else a=$CLJS.R.j(a,b,c);return a};
qL=function qL(a,b,c,d){var f=$CLJS.y(c);c=$CLJS.z(f);var k=$CLJS.B(f),l=pL(b,c);f=$CLJS.n(a)?a:$CLJS.wd(b)?$CLJS.Df:$CLJS.yd(b)?$CLJS.N:$CLJS.jd(b);return $CLJS.n($CLJS.n(c)?$CLJS.dt.h($CLJS.pd(f)):c)?a:$CLJS.n(c)?(b=pL(f,c),d=qL.v?qL.v(b,l,k,d):qL.call(null,b,l,k,d),$CLJS.Pl(c)&&$CLJS.wd(f)&&c>$CLJS.D(f)&&(b=$CLJS.ef(c-$CLJS.D(f),null),b=$CLJS.hf.g(f,b),f=null==f||$CLJS.Dd(f)?b:$CLJS.fg.g($CLJS.jd(f),b)),null==f||$CLJS.Kl(f)?$CLJS.R.j(f,c,d):$CLJS.vd(f)?$CLJS.ce.g(f,d):$CLJS.Te.g($CLJS.U,$CLJS.R.j($CLJS.Ef(f),
c,d))):$CLJS.xd(a)?(c=new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.hca],null),qL.v?qL.v(f,b,c,d):qL.call(null,f,b,c,d)):$CLJS.n($CLJS.dt.h($CLJS.pd(f)))?$CLJS.ce.g(f,d):$CLJS.zd($CLJS.Ge(f))?f:$CLJS.od(new $CLJS.P(null,1,5,$CLJS.Q,[d],null),new $CLJS.h(null,1,[$CLJS.dt,!0],null))};FL=new $CLJS.M("metabase.mbql.schema","aggregation","metabase.mbql.schema/aggregation",-662824303);wia=new $CLJS.r("metabase.mbql.schema","datetime-subtract","metabase.mbql.schema/datetime-subtract",-1242286173,null);
GL=new $CLJS.M("metabase.mbql.schema","ag:var","metabase.mbql.schema/ag:var",-345484364);xia=new $CLJS.r("metabase.mbql.schema","time-interval","metabase.mbql.schema/time-interval",1275783375,null);HL=new $CLJS.M("metabase.mbql.schema","datetime-subtract","metabase.mbql.schema/datetime-subtract",1412149596);IL=new $CLJS.M("metabase.mbql.schema","datetime-add","metabase.mbql.schema/datetime-add",-985876836);JL=new $CLJS.M("metabase.mbql.schema","substring","metabase.mbql.schema/substring",188871083);
yia=new $CLJS.r("metabase.mbql.schema","replace","metabase.mbql.schema/replace",1269362826,null);KL=new $CLJS.M("metabase.mbql.schema","TimeLiteral","metabase.mbql.schema/TimeLiteral",-822346498);LL=new $CLJS.M("metabase.mbql.schema","get-month","metabase.mbql.schema/get-month",549586740);zia=new $CLJS.M(null,"disable-mbql-\x3enative?","disable-mbql-\x3enative?",1103196677);ML=new $CLJS.M("metabase.mbql.schema","StringExpressionArg","metabase.mbql.schema/StringExpressionArg",596241960);
Aia=new $CLJS.M(null,"from","from",1815293044);NL=new $CLJS.M("metabase.mbql.schema","and","metabase.mbql.schema/and",-86675456);OL=new $CLJS.r(null,"does-not-contain","does-not-contain",1365584674,null);Bia=new $CLJS.M(null,"add-default-userland-constraints?","add-default-userland-constraints?",2039156190);$CLJS.PL=new $CLJS.M(null,"snippet-name","snippet-name",819240328);QL=new $CLJS.M("metabase.mbql.schema","not-empty","metabase.mbql.schema/not-empty",560735368);
Cia=new $CLJS.r("metabase.mbql.schema","max","metabase.mbql.schema/max",-1397867614,null);Dia=new $CLJS.r("metabase.mbql.schema","get-day","metabase.mbql.schema/get-day",-1600012951,null);$CLJS.RL=new $CLJS.M(null,"aggregation-options","aggregation-options",-1904917550);Eia=new $CLJS.r("metabase.mbql.schema","cum-count","metabase.mbql.schema/cum-count",596526831,null);SL=new $CLJS.M("location","zip_code","location/zip_code",1641155222);
TL=new $CLJS.r(null,"ExpressionArg","ExpressionArg",1060743736,null);UL=new $CLJS.r(null,"get-hour","get-hour",-2031721710,null);VL=new $CLJS.M("metabase.mbql.schema","dimension","metabase.mbql.schema/dimension",-140332301);WL=new $CLJS.r(null,"ag:var","ag:var",-1804440286,null);XL=new $CLJS.M("metabase.mbql.schema","IntGreaterThanZeroOrNumericExpression","metabase.mbql.schema/IntGreaterThanZeroOrNumericExpression",1485789132);
YL=new $CLJS.M("metabase.mbql.schema","exp","metabase.mbql.schema/exp",-1188751039);ZL=new $CLJS.M("metabase.mbql.schema","time-interval","metabase.mbql.schema/time-interval",-364748152);Fia=new $CLJS.M(null,"lon-max","lon-max",1590224717);$L=new $CLJS.M("metabase.mbql.schema","asc","metabase.mbql.schema/asc",-302489464);aM=new $CLJS.r(null,"stddev","stddev",775056588,null);bM=new $CLJS.M("metabase.mbql.schema","lower","metabase.mbql.schema/lower",-1954451840);
$CLJS.cM=new $CLJS.M(null,"snippet","snippet",953581994);dM=new $CLJS.r("metabase.mbql.schema","case","metabase.mbql.schema/case",2100482976,null);eM=new $CLJS.M(null,"string-expression","string-expression",-1395337766);Gia=new $CLJS.M(null,"lat-field","lat-field",-830652957);fM=new $CLJS.M("metabase.mbql.schema","now","metabase.mbql.schema/now",-975392912);gM=new $CLJS.M("metabase.mbql.schema","avg","metabase.mbql.schema/avg",1123586863);
hM=new $CLJS.M("metabase.mbql.schema","not","metabase.mbql.schema/not",-1753911131);iM=new $CLJS.M("metabase.mbql.schema","StringExpression","metabase.mbql.schema/StringExpression",-670335423);Hia=new $CLJS.r("metabase.mbql.schema","min","metabase.mbql.schema/min",-1534510688,null);jM=new $CLJS.M("location","country","location/country",1666636202);kM=new $CLJS.M("metabase.mbql.schema","trim","metabase.mbql.schema/trim",350521664);
lM=new $CLJS.M("metabase.mbql.schema","Reference","metabase.mbql.schema/Reference",-770092616);mM=new $CLJS.M("metabase.mbql.schema","get-second","metabase.mbql.schema/get-second",-1080110117);Iia=new $CLJS.r("metabase.mbql.schema","count-where","metabase.mbql.schema/count-where",-1224231786,null);nM=new $CLJS.M(null,"unary","unary",-989314568);oM=new $CLJS.M("metabase.mbql.schema","rtrim","metabase.mbql.schema/rtrim",297284410);
pM=new $CLJS.M("metabase.mbql.schema","relative-datetime","metabase.mbql.schema/relative-datetime",324961083);qM=new $CLJS.r(null,"ceil","ceil",-184398425,null);Jia=new $CLJS.M(null,"lon-min","lon-min",-787291357);rM=new $CLJS.r(null,"NonBlankString","NonBlankString",-719244809,null);Kia=new $CLJS.M(null,"match","match",1220059550);sM=new $CLJS.r(null,"count-where","count-where",2025939247,null);tM=new $CLJS.M("metabase.mbql.schema","get-hour","metabase.mbql.schema/get-hour",1844658706);
uM=new $CLJS.M(null,"max-results-bare-rows","max-results-bare-rows",2096475889);Lia=new $CLJS.M(null,"embedded-question","embedded-question",-2146473954);vM=new $CLJS.M("metabase.mbql.schema","WidgetType","metabase.mbql.schema/WidgetType",1831406784);wM=new $CLJS.r(null,"sum","sum",1777518341,null);xM=new $CLJS.M("metabase.mbql.schema","datetime-diff","metabase.mbql.schema/datetime-diff",197727284);yM=new $CLJS.r("metabase.mbql.schema","!\x3d","metabase.mbql.schema/!\x3d",-807630752,null);
$CLJS.zM=new $CLJS.M("date","range","date/range",1647265776);AM=new $CLJS.r(null,"between","between",-1523336493,null);BM=new $CLJS.M(null,"clause-form","clause-form",1820463737);Mia=new $CLJS.r("metabase.mbql.schema","log","metabase.mbql.schema/log",-899459908,null);CM=new $CLJS.M("metabase.mbql.schema","Query","metabase.mbql.schema/Query",1321874752);DM=new $CLJS.r(null,"field","field",338095027,null);Nia=new $CLJS.M(null,"segment-id","segment-id",1810133590);
EM=new $CLJS.M("metabase.mbql.schema","stddev","metabase.mbql.schema/stddev",94927598);FM=new $CLJS.r(null,"not-null","not-null",313812992,null);GM=new $CLJS.M("metabase.mbql.schema","Joins","metabase.mbql.schema/Joins",1376278278);HM=new $CLJS.M("metabase.mbql.schema","ltrim","metabase.mbql.schema/ltrim",-1587636578);IM=new $CLJS.M("metabase.mbql.schema","\x3c\x3d","metabase.mbql.schema/\x3c\x3d",556298105);Oia=new $CLJS.M(null,"template-tag","template-tag",310841038);
JM=new $CLJS.M(null,"invalid","invalid",412869516);Pia=new $CLJS.r("metabase.mbql.schema","datetime-diff","metabase.mbql.schema/datetime-diff",1838258811,null);KM=new $CLJS.M("metabase.mbql.schema","absolute-datetime","metabase.mbql.schema/absolute-datetime",1473793750);LM=new $CLJS.r(null,"regex-match-first","regex-match-first",983231809,null);$CLJS.MM=new $CLJS.M(null,"context","context",-830191113);NM=new $CLJS.r(null,"get-year","get-year",704520253,null);
Qia=new $CLJS.M(null,"format-rows?","format-rows?",992129486);OM=new $CLJS.M("metabase.mbql.schema","\x3e\x3d","metabase.mbql.schema/\x3e\x3d",336705322);PM=new $CLJS.M("metabase.mbql.schema","temporal-extract","metabase.mbql.schema/temporal-extract",829238608);QM=new $CLJS.M("metabase.mbql.schema","variable","metabase.mbql.schema/variable",672451083);$CLJS.RM=new $CLJS.M(null,"parameters","parameters",-1229919748);Ria=new $CLJS.M(null,"json-download","json-download",-971130133);
$CLJS.SM=new $CLJS.M(null,"card","card",-1430355152);TM=new $CLJS.M(null,"string-or-field","string-or-field",-1970678542);UM=new $CLJS.r(null,"!\x3d","!\x3d",-201205829,null);Sia=new $CLJS.r("metabase.mbql.schema","ag:var","metabase.mbql.schema/ag:var",1295047163,null);Tia=new $CLJS.r("metabase.mbql.schema","ceil","metabase.mbql.schema/ceil",-1404628626,null);VM=new $CLJS.r(null,"expression","expression",1842843403,null);$CLJS.WM=new $CLJS.M("date","month-year","date/month-year",1948031290);
XM=new $CLJS.M("metabase.mbql.schema","BooleanExpression","metabase.mbql.schema/BooleanExpression",-2109835363);YM=new $CLJS.r(null,"ends-with","ends-with",1183740516,null);ZM=new $CLJS.M("metabase.mbql.schema","time","metabase.mbql.schema/time",727162193);$CLJS.$M=new $CLJS.M(null,"joins","joins",1033962699);Uia=new $CLJS.M(null,"slug","slug",2029314850);$CLJS.aN=new $CLJS.M(null,"source-field","source-field",933829534);bN=new $CLJS.r(null,"Field","Field",430385967,null);
cN=new $CLJS.r(null,"convert-timezone","convert-timezone",1515728526,null);Via=new $CLJS.r("metabase.mbql.schema","absolute-datetime","metabase.mbql.schema/absolute-datetime",-1180642019,null);Wia=new $CLJS.M(null,"disable-max-results?","disable-max-results?",857693204);Xia=new $CLJS.M(null,"items","items",1031954938);dN=new $CLJS.M(null,"datetime-expression","datetime-expression",391782195);eN=new $CLJS.M("metabase.mbql.schema","coalesce","metabase.mbql.schema/coalesce",1605865100);
fN=new $CLJS.M(null,"more","more",-2058821800);gN=new $CLJS.M(null,"first-clause","first-clause",-20953491);hN=new $CLJS.M("metabase.mbql.schema","sum-where","metabase.mbql.schema/sum-where",-671453147);iN=new $CLJS.r(null,"OrderComparable","OrderComparable",772072595,null);Yia=new $CLJS.r("metabase.mbql.schema","ends-with","metabase.mbql.schema/ends-with",-2117292579,null);jN=new $CLJS.r(null,"contains","contains",-1977535957,null);
Zia=new $CLJS.r("metabase.mbql.schema","floor","metabase.mbql.schema/floor",422926927,null);kN=new $CLJS.r("metabase.mbql.schema","expression","metabase.mbql.schema/expression",370334320,null);lN=new $CLJS.M("number","\x3d","number/\x3d",-2094581309);$CLJS.mN=new $CLJS.M(null,"widget-type","widget-type",1836256899);nN=new $CLJS.M("metabase.mbql.schema","max","metabase.mbql.schema/max",1256568155);$CLJS.oN=new $CLJS.M(null,"fingerprint","fingerprint",598613022);
pN=new $CLJS.r(null,"is-null","is-null",-356519403,null);qN=new $CLJS.M(null,"other-clauses","other-clauses",1570511021);rN=new $CLJS.M("metabase.mbql.schema","is-null","metabase.mbql.schema/is-null",-1003477115);sN=new $CLJS.M("metabase.mbql.schema","upper","metabase.mbql.schema/upper",-646839893);$ia=new $CLJS.M(null,"map-tiles","map-tiles",1961865797);$CLJS.tN=new $CLJS.M(null,"required","required",1807647006);aja=new $CLJS.M(null,"embedded-csv-download","embedded-csv-download",1858870014);
uN=new $CLJS.M(null,"unnamed-aggregation","unnamed-aggregation",-93854280);bja=new $CLJS.M(null,"datetime-x","datetime-x",1519265947);cja=new $CLJS.M(null,"datetime-y","datetime-y",-1666955771);vN=new $CLJS.r(null,"CaseClauses","CaseClauses",-1749071354,null);wN=new $CLJS.M(null,"date-arithmetics","date-arithmetics",-1832808309);xN=new $CLJS.M("string","contains","string/contains",1602423827);yN=new $CLJS.M("metabase.mbql.schema","count-where","metabase.mbql.schema/count-where",1430203983);
zN=new $CLJS.M("metabase.mbql.schema","or","metabase.mbql.schema/or",1154080514);dja=new $CLJS.r("metabase.mbql.schema","metric","metabase.mbql.schema/metric",1030004285,null);AN=new $CLJS.M("string","!\x3d","string/!\x3d",-1083772573);eja=new $CLJS.r(null,"IntGreaterThanZeroOrNumericExpression","IntGreaterThanZeroOrNumericExpression",2098230228,null);fja=new $CLJS.r("metabase.mbql.schema","count","metabase.mbql.schema/count",678066683,null);
BN=new $CLJS.M("metabase.mbql.schema","min","metabase.mbql.schema/min",1119925081);CN=new $CLJS.r("metabase.mbql.schema","field","metabase.mbql.schema/field",1566852856,null);DN=new $CLJS.M("metabase.mbql.schema","convert-timezone","metabase.mbql.schema/convert-timezone",-11600894);EN=new $CLJS.M("metabase.mbql.schema","Join","metabase.mbql.schema/Join",-2100258965);FN=new $CLJS.r(null,"share","share",1051097594,null);
gja=new $CLJS.r("metabase.mbql.schema","distinct","metabase.mbql.schema/distinct",-823840515,null);hja=new $CLJS.r("metabase.mbql.schema","round","metabase.mbql.schema/round",314817788,null);ija=new $CLJS.r("metabase.mbql.schema","get-quarter","metabase.mbql.schema/get-quarter",2007639089,null);GN=new $CLJS.M("metabase.mbql.schema","distinct","metabase.mbql.schema/distinct",1830595254);jja=new $CLJS.M(null,"xlsx-download","xlsx-download",-1622892009);
HN=new $CLJS.M("metabase.mbql.schema","contains","metabase.mbql.schema/contains",-241280695);kja=new $CLJS.r("metabase.mbql.schema","contains","metabase.mbql.schema/contains",1399250832,null);$CLJS.IN=new $CLJS.M(null,"collection","collection",-683361892);JN=new $CLJS.M("metabase.mbql.schema","is-empty","metabase.mbql.schema/is-empty",45949435);KN=new $CLJS.M("metabase.mbql.schema","median","metabase.mbql.schema/median",414562044);
$CLJS.LN=new $CLJS.M("metadata","dataset-metadata","metadata/dataset-metadata",-728376469);lja=new $CLJS.r("metabase.mbql.schema","is-empty","metabase.mbql.schema/is-empty",1686480962,null);MN=new $CLJS.M("number","\x3c\x3d","number/\x3c\x3d",-1499316353);NN=new $CLJS.r(null,"metric","metric",2049329604,null);ON=new $CLJS.r(null,"concat","concat",-467652465,null);PN=new $CLJS.M(null,"variable","variable",-281346492);$CLJS.QN=new $CLJS.M("date","quarter-year","date/quarter-year",-1453950150);
mja=new $CLJS.r(null,"TimeUnit","TimeUnit",1200517789,null);$CLJS.RN=new $CLJS.M("date","relative","date/relative",25987732);SN=new $CLJS.M("location","city","location/city",-1746973325);nja=new $CLJS.M(null,"public-dashboard","public-dashboard",645968405);oja=new $CLJS.r("metabase.mbql.schema","desc","metabase.mbql.schema/desc",-2050113424,null);TN=new $CLJS.M("metabase.mbql.schema","get-day","metabase.mbql.schema/get-day",1054422818);UN=new $CLJS.M("number","\x3e\x3d","number/\x3e\x3d",-1670691032);
VN=new $CLJS.M("number","between","number/between",97700581);pja=new $CLJS.r("metabase.mbql.schema","cum-sum","metabase.mbql.schema/cum-sum",-2078342061,null);WN=new $CLJS.M("metabase.mbql.schema","starts-with","metabase.mbql.schema/starts-with",-576867936);XN=new $CLJS.r(null,"sqrt","sqrt",370479598,null);YN=new $CLJS.M("metabase.mbql.schema","get-minute","metabase.mbql.schema/get-minute",515738880);ZN=new $CLJS.M("metabase.mbql.schema","expression","metabase.mbql.schema/expression",-1270197207);
$CLJS.$N=new $CLJS.M(null,"semantic_type","semantic_type",272485089);qja=new $CLJS.M(null,"metric-id","metric-id",-686486942);aO=new $CLJS.r(null,"*","*",345799209,null);bO=new $CLJS.r(null,"+","+",-740910886,null);cO=new $CLJS.r(null,"-","-",-471816912,null);dO=new $CLJS.r(null,"template-tag","template-tag",1951372565,null);eO=new $CLJS.M(null,"allowed-for","allowed-for",122724334);rja=new $CLJS.M(null,"question","question",-1411720117);fO=new $CLJS.r(null,"asc","asc",1997386096,null);
gO=new $CLJS.M("metabase.mbql.schema","not-null","metabase.mbql.schema/not-null",-391950078);sja=new $CLJS.M(null,"pulse-id","pulse-id",1331432237);hO=new $CLJS.M("metabase.mbql.schema","regex-match-first","metabase.mbql.schema/regex-match-first",277590849);iO=new $CLJS.r(null,"\x3c\x3d","\x3c\x3d",1244895369,null);jO=new $CLJS.r(null,"\x3c","\x3c",993667236,null);kO=new $CLJS.r(null,"\x3e","\x3e",1085014381,null);lO=new $CLJS.r(null,"\x3d","\x3d",-1501502141,null);
mO=new $CLJS.M("metabase.mbql.schema","OrderComparable","metabase.mbql.schema/OrderComparable",1970325905);$CLJS.nO=new $CLJS.M(null,"source-metadata","source-metadata",-477816085);oO=new $CLJS.M("string","ends-with","string/ends-with",302681156);tja=new $CLJS.r("metabase.mbql.schema","get-year","metabase.mbql.schema/get-year",-265030428,null);pO=new $CLJS.r(null,"RelativeDatetimeUnit","RelativeDatetimeUnit",-1157637501,null);uja=new $CLJS.r(null,"MetricID","MetricID",-2128635641,null);
qO=new $CLJS.r(null,"and","and",668631710,null);rO=new $CLJS.r(null,"\x3e\x3d","\x3e\x3d",1016916022,null);sO=new $CLJS.r(null,"round","round",-645002441,null);vja=new $CLJS.M(null,"to","to",192099007);$CLJS.tO=new $CLJS.M("date","single","date/single",1554682003);wja=new $CLJS.M(null,"action-id","action-id",-1727958578);xja=new $CLJS.r("metabase.mbql.schema","not-null","metabase.mbql.schema/not-null",1248581449,null);uO=new $CLJS.r(null,"exp","exp",1378825265,null);
vO=new $CLJS.r(null,"Filter","Filter",-424893332,null);wO=new $CLJS.r(null,"cum-count","cum-count",-323900016,null);yja=new $CLJS.M(null,"dashboard-id","dashboard-id",1965414288);xO=new $CLJS.M("metabase.mbql.schema","percentile","metabase.mbql.schema/percentile",-1731861963);yO=new $CLJS.M("metabase.mbql.schema","abs","metabase.mbql.schema/abs",152348622);zO=new $CLJS.M("metabase.mbql.schema","desc","metabase.mbql.schema/desc",604322345);
AO=new $CLJS.M(null,"numeric-expression","numeric-expression",-1661337235);$CLJS.BO=new $CLJS.M(null,"source-table","source-table",-225307692);zja=new $CLJS.M(null,"embedded-dashboard","embedded-dashboard",-485078014);Aja=new $CLJS.r("metabase.mbql.schema","sqrt","metabase.mbql.schema/sqrt",-824662873,null);CO=new $CLJS.r(null,"floor","floor",-772394748,null);Bja=new $CLJS.M(null,"middleware","middleware",1462115504);
Cja=new $CLJS.r("metabase.mbql.schema","share","metabase.mbql.schema/share",1977664161,null);DO=new $CLJS.M(null,"requires-features","requires-features",-101116256);EO=new $CLJS.M("metabase.mbql.schema","power","metabase.mbql.schema/power",2136530448);FO=new $CLJS.M(null,"clause-name","clause-name",-996419059);GO=new $CLJS.M("metabase.mbql.schema","get-year","metabase.mbql.schema/get-year",-1905561955);HO=new $CLJS.r(null,"now","now",-9994004,null);
Dja=new $CLJS.r("metabase.mbql.schema","datetime-add","metabase.mbql.schema/datetime-add",654654691,null);IO=new $CLJS.r(null,"not","not",1044554643,null);JO=new $CLJS.r(null,"avg","avg",1837937727,null);KO=new $CLJS.r(null,"sum-where","sum-where",-519087341,null);LO=new $CLJS.M(null,"max-results","max-results",-32858165);Eja=new $CLJS.r("metabase.mbql.schema","get-second","metabase.mbql.schema/get-second",560421410,null);MO=new $CLJS.r(null,"case","case",-1510733573,null);
NO=new $CLJS.r(null,"distinct","distinct",-148347594,null);OO=new $CLJS.r(null,"get-second","get-second",-425414791,null);PO=new $CLJS.M("metabase.mbql.schema","ends-with","metabase.mbql.schema/ends-with",537143190);$CLJS.QO=new $CLJS.M(null,"join-alias","join-alias",1454206794);RO=new $CLJS.r(null,"is-empty","is-empty",600228619,null);Fja=new $CLJS.r("metabase.mbql.schema","segment","metabase.mbql.schema/segment",-512121472,null);
SO=new $CLJS.r(null,"relative-datetime","relative-datetime",-1369266491,null);TO=new $CLJS.M("number","!\x3d","number/!\x3d",-673025509);Gja=new $CLJS.M(null,"original","original",-445386197);UO=new $CLJS.M("metabase.mbql.schema","interval","metabase.mbql.schema/interval",-1491935720);VO=new $CLJS.r(null,"abs","abs",1394505050,null);WO=new $CLJS.r(null,"datetime-diff","datetime-diff",-1521323614,null);Hja=new $CLJS.r("metabase.mbql.schema","get-hour","metabase.mbql.schema/get-hour",-809777063,null);
XO=new $CLJS.M("metabase.mbql.schema","cum-count","metabase.mbql.schema/cum-count",-1044004696);Ija=new $CLJS.r(null,"DatetimeDiffUnit","DatetimeDiffUnit",-1310225671,null);$CLJS.YO=new $CLJS.M(null,"date","date",-1463434462);ZO=new $CLJS.M(null,"second-clause","second-clause",-461435645);Jja=new $CLJS.r("metabase.mbql.schema","get-day-of-week","metabase.mbql.schema/get-day-of-week",551389774,null);$O=new $CLJS.r(null,"rtrim","rtrim",979195078,null);
aP=new $CLJS.M("metabase.mbql.schema","sqrt","metabase.mbql.schema/sqrt",1829772896);bP=new $CLJS.M("metabase.mbql.schema","get-day-of-week","metabase.mbql.schema/get-day-of-week",-1089141753);cP=new $CLJS.r("metabase.mbql.schema","or","metabase.mbql.schema/or",-1500355255,null);dP=new $CLJS.M("metabase.mbql.schema","Filter","metabase.mbql.schema/Filter",1210108656);eP=new $CLJS.r(null,"CaseOptions","CaseOptions",1989286806,null);fP=new $CLJS.r(null,"or","or",1876275696,null);
Kja=new $CLJS.r("metabase.mbql.schema","ltrim","metabase.mbql.schema/ltrim",52894949,null);Lja=new $CLJS.M(null,"constraints","constraints",422775616);Mja=new $CLJS.r("metabase.mbql.schema","dimension","metabase.mbql.schema/dimension",1500199226,null);gP=new $CLJS.M("metabase.mbql.schema","Field","metabase.mbql.schema/Field",-551724627);Nja=new $CLJS.M(null,"csv-download","csv-download",2141432084);hP=new $CLJS.M("metabase.mbql.schema","get-quarter","metabase.mbql.schema/get-quarter",367107562);
iP=new $CLJS.r(null,"datetime-add","datetime-add",1850134938,null);Oja=new $CLJS.r("metabase.mbql.schema","percentile","metabase.mbql.schema/percentile",-91330436,null);Pja=new $CLJS.r("metabase.mbql.schema","get-month","metabase.mbql.schema/get-month",-2104849029,null);jP=new $CLJS.r("metabase.mbql.schema","\x3e","metabase.mbql.schema/\x3e",-1619862474,null);kP=new $CLJS.r("metabase.mbql.schema","\x3d","metabase.mbql.schema/\x3d",1850085384,null);
lP=new $CLJS.r("metabase.mbql.schema","\x3c","metabase.mbql.schema/\x3c",-1057391587,null);Qja=new $CLJS.r("metabase.mbql.schema","/","metabase.mbql.schema//",2004134796,null);mP=new $CLJS.r(null,"get-quarter","get-quarter",-1326657176,null);Rja=new $CLJS.r("metabase.mbql.schema","-","metabase.mbql.schema/-",-1440604423,null);nP=new $CLJS.r("metabase.mbql.schema","+","metabase.mbql.schema/+",-1802115711,null);Sja=new $CLJS.r("metabase.mbql.schema","*","metabase.mbql.schema/*",1842546528,null);
Tja=new $CLJS.r("metabase.mbql.schema","time","metabase.mbql.schema/time",-1927273576,null);oP=new $CLJS.r(null,"temporal-extract","temporal-extract",-872749364,null);Uja=new $CLJS.r("metabase.mbql.schema","sum","metabase.mbql.schema/sum",-1589890100,null);Vja=new $CLJS.r("metabase.mbql.schema","between","metabase.mbql.schema/between",-302972630,null);Wja=new $CLJS.M(null,"display_name","display_name",-1494335013);$CLJS.pP=new $CLJS.M(null,"snippet-id","snippet-id",1987785841);
$CLJS.qP=new $CLJS.M(null,"database","database",1849087575);rP=new $CLJS.r(null,"NumericExpressionArgOrInterval","NumericExpressionArgOrInterval",-151709337,null);sP=new $CLJS.r(null,"get-day-of-week","get-day-of-week",-644295017,null);Xja=new $CLJS.r("metabase.mbql.schema","starts-with","metabase.mbql.schema/starts-with",1063663591,null);tP=new $CLJS.M("metabase.mbql.schema","floor","metabase.mbql.schema/floor",-1217604600);
uP=new $CLJS.M("metabase.mbql.schema","NumericExpressionArgOrInterval","metabase.mbql.schema/NumericExpressionArgOrInterval",-596692841);$CLJS.vP=new $CLJS.M(null,"expressions","expressions",255689909);wP=new $CLJS.M("metabase.mbql.schema","ExpressionArg","metabase.mbql.schema/ExpressionArg",372701340);Yja=new $CLJS.M(null,"ad-hoc","ad-hoc",-2033634036);xP=new $CLJS.M("metabase.mbql.schema","does-not-contain","metabase.mbql.schema/does-not-contain",912159158);
Zja=new $CLJS.r("metabase.mbql.schema","concat","metabase.mbql.schema/concat",-580718778,null);yP=new $CLJS.M("metabase.mbql.schema","aggregation-options","metabase.mbql.schema/aggregation-options",-936374135);zP=new $CLJS.M("metabase.mbql.schema","log","metabase.mbql.schema/log",1754975861);AP=new $CLJS.r(null,"absolute-datetime","absolute-datetime",1080191062,null);$ja=new $CLJS.r(null,"TimeIntervalOptions","TimeIntervalOptions",1566864026,null);aka=new $CLJS.M(null,"action","action",-811238024);
BP=new $CLJS.r(null,"get-day","get-day",1768100384,null);CP=new $CLJS.M("metabase.mbql.schema","MBQLQuery","metabase.mbql.schema/MBQLQuery",323823775);DP=new $CLJS.M("metabase.mbql.schema","between","metabase.mbql.schema/between",-1943504157);EP=new $CLJS.r(null,"not-empty","not-empty",2029453590,null);$CLJS.FP=new $CLJS.M(null,"native","native",-613060878);bka=new $CLJS.r("metabase.mbql.schema","variable","metabase.mbql.schema/variable",-1981984686,null);GP=new $CLJS.M(null,"page","page",849072397);
HP=new $CLJS.r(null,"length","length",-2065447907,null);cka=new $CLJS.M(null,"dashboard","dashboard",-631747508);IP=new $CLJS.r(null,"get-week","get-week",752472178,null);JP=new $CLJS.r(null,"get-month","get-month",1271156796,null);KP=new $CLJS.r(null,"dimension","dimension",-2111181571,null);LP=new $CLJS.M(null,"boolean-expression","boolean-expression",-394924008);MP=new $CLJS.M("metabase.mbql.schema","length","metabase.mbql.schema/length",1810214269);
NP=new $CLJS.r(null,"StringFilterOptions","StringFilterOptions",-847398229,null);OP=new $CLJS.M("metabase.mbql.schema","segment","metabase.mbql.schema/segment",2142314297);PP=new $CLJS.r("metabase.mbql.schema","not","metabase.mbql.schema/not",-113379604,null);dka=new $CLJS.r("metabase.mbql.schema","now","metabase.mbql.schema/now",665138615,null);QP=new $CLJS.M("metabase.mbql.schema","case","metabase.mbql.schema/case",459951449);RP=new $CLJS.r(null,"substring","substring",-1513569493,null);
eka=new $CLJS.r("metabase.mbql.schema","avg","metabase.mbql.schema/avg",-1530848906,null);SP=new $CLJS.M(null,"internal","internal",-854870097);fka=new $CLJS.r("metabase.mbql.schema","regex-match-first","metabase.mbql.schema/regex-match-first",1918122376,null);TP=new $CLJS.M("metabase.mbql.schema","DateTimeExpressionArg","metabase.mbql.schema/DateTimeExpressionArg",1033762257);UP=new $CLJS.M("metabase.mbql.schema","TemplateTag","metabase.mbql.schema/TemplateTag",-1837740311);
VP=new $CLJS.M("metabase.mbql.schema","EqualityComparable","metabase.mbql.schema/EqualityComparable",-72675242);gka=new $CLJS.r("metabase.mbql.schema","substring","metabase.mbql.schema/substring",1829402610,null);WP=new $CLJS.M(null,"more-values-or-fields","more-values-or-fields",-886177554);XP=new $CLJS.r(null,"ltrim","ltrim",-1000166486,null);hka=new $CLJS.r("metabase.mbql.schema","rtrim","metabase.mbql.schema/rtrim",1937815937,null);
ika=new $CLJS.r("metabase.mbql.schema","length","metabase.mbql.schema/length",-844221500,null);YP=new $CLJS.M("metabase.mbql.schema","DatetimeExpression","metabase.mbql.schema/DatetimeExpression",1214423543);ZP=new $CLJS.M("metabase.mbql.schema","template-tag","metabase.mbql.schema/template-tag",1296341515);$P=new $CLJS.r("metabase.mbql.schema","coalesce","metabase.mbql.schema/coalesce",-1048570669,null);
jka=new $CLJS.r("metabase.mbql.schema","not-empty","metabase.mbql.schema/not-empty",-2093700401,null);kka=new $CLJS.r("metabase.mbql.schema","lower","metabase.mbql.schema/lower",-313920313,null);aQ=new $CLJS.r(null,"desc","desc",-560950005,null);$CLJS.bQ=new $CLJS.M(null,"template-tags","template-tags",1853115685);lka=new $CLJS.M(null,"public-question","public-question",629369976);mka=new $CLJS.r("metabase.mbql.schema","temporal-extract","metabase.mbql.schema/temporal-extract",-1825197161,null);
nka=new $CLJS.M(null,"binary","binary",-1802232288);cQ=new $CLJS.r(null,"time-interval","time-interval",-1949813754,null);oka=new $CLJS.M(null,"userland-query?","userland-query?",-123699383);$CLJS.dQ=new $CLJS.M(null,"source-query","source-query",198004422);eQ=new $CLJS.M("metabase.mbql.schema","inside","metabase.mbql.schema/inside",-1932527842);pka=new $CLJS.r(null,"SegmentID","SegmentID",-1773652704,null);
qka=new $CLJS.r("metabase.mbql.schema","get-minute","metabase.mbql.schema/get-minute",-2138696889,null);fQ=new $CLJS.M("metabase.mbql.schema","UnnamedAggregation","metabase.mbql.schema/UnnamedAggregation",-196705529);gQ=new $CLJS.M("metabase.mbql.schema","share","metabase.mbql.schema/share",337132634);rka=new $CLJS.M(null,"executed-by","executed-by",-739811161);ska=new $CLJS.r("metabase.mbql.schema","aggregation","metabase.mbql.schema/aggregation",977707224,null);
tka=new $CLJS.r("metabase.mbql.schema","median","metabase.mbql.schema/median",2055093571,null);hQ=new $CLJS.M(null,"amount","amount",364489504);iQ=new $CLJS.r(null,"percentile","percentile",1039342775,null);jQ=new $CLJS.M("metabase.mbql.schema","metric","metabase.mbql.schema/metric",-610527242);kQ=new $CLJS.r(null,"metabase.mbql.schema","metabase.mbql.schema",-408168902,null);lQ=new $CLJS.M("metabase.mbql.schema","ceil","metabase.mbql.schema/ceil",1249807143);
mQ=new $CLJS.r(null,"NumericExpressionArg","NumericExpressionArg",-1537445195,null);nQ=new $CLJS.r(null,"trim","trim",-1880116002,null);uka=new $CLJS.r(null,"RawTimeLiteral","RawTimeLiteral",-2137224789,null);oQ=new $CLJS.r(null,"coalesce","coalesce",-1999813740,null);pQ=new $CLJS.M("metabase.mbql.schema","sum","metabase.mbql.schema/sum",1064545669);vka=new $CLJS.M("metabase.mbql.schema","DateTimeValue","metabase.mbql.schema/DateTimeValue",1983295553);
qQ=new $CLJS.M("string","\x3d","string/\x3d",983744235);wka=new $CLJS.M(null,"skip-results-metadata?","skip-results-metadata?",251010463);xka=new $CLJS.r("metabase.mbql.schema","asc","metabase.mbql.schema/asc",1338042063,null);yka=new $CLJS.r("metabase.mbql.schema","inside","metabase.mbql.schema/inside",-291996315,null);rQ=new $CLJS.r(null,"StringExpressionArg","StringExpressionArg",-1131357258,null);sQ=new $CLJS.M(null,"value-or-field","value-or-field",-1387286309);
tQ=new $CLJS.r(null,"FieldOrExpressionDef","FieldOrExpressionDef",-788269541,null);zka=new $CLJS.M(null,"lat-min","lat-min",1630784161);uQ=new $CLJS.r(null,"ArithmeticDateTimeUnit","ArithmeticDateTimeUnit",-838492507,null);vQ=new $CLJS.r(null,"inside","inside",-681932758,null);Aka=new $CLJS.r("metabase.mbql.schema","stddev","metabase.mbql.schema/stddev",1735459125,null);wQ=new $CLJS.M("metabase.mbql.schema","NumericExpression","metabase.mbql.schema/NumericExpression",1794540739);
Bka=new $CLJS.r("metabase.mbql.schema","convert-timezone","metabase.mbql.schema/convert-timezone",1628930633,null);xQ=new $CLJS.r(null,"starts-with","starts-with",2007034536,null);Cka=new $CLJS.M(null,"process-viz-settings?","process-viz-settings?",-173592315);Dka=new $CLJS.r("metabase.mbql.schema","power","metabase.mbql.schema/power",-517905321,null);$CLJS.yQ=new $CLJS.M(null,"card-id","card-id",-1770060179);zQ=new $CLJS.M(null,"variadic","variadic",882626057);
AQ=new $CLJS.r(null,"upper","upper",1886775433,null);BQ=new $CLJS.M(null,"field-or-expression","field-or-expression",-1409494368);CQ=new $CLJS.M("metabase.mbql.schema","Aggregation","metabase.mbql.schema/Aggregation",746170880);DQ=new $CLJS.r(null,"optional","optional",-600484260,null);Eka=new $CLJS.r("metabase.mbql.schema","exp","metabase.mbql.schema/exp",451780488,null);EQ=new $CLJS.M("metabase.mbql.schema","FieldOptions","metabase.mbql.schema/FieldOptions",-2115604229);
FQ=new $CLJS.M(null,"sugar","sugar",-73788488);Fka=new $CLJS.M(null,"lat-max","lat-max",841568226);GQ=new $CLJS.r(null,"power","power",702679448,null);HQ=new $CLJS.r("metabase.mbql.schema","and","metabase.mbql.schema/and",1553856071,null);IQ=new $CLJS.M("metabase.mbql.schema","replace","metabase.mbql.schema/replace",-371168701);JQ=new $CLJS.M("metabase.mbql.schema","NumericExpressionArg","metabase.mbql.schema/NumericExpressionArg",-1965874261);
KQ=new $CLJS.M("metabase.mbql.schema","Fields","metabase.mbql.schema/Fields",1872461942);MQ=new $CLJS.r(null,"median","median",-2084869638,null);NQ=new $CLJS.M("metabase.mbql.schema","ParameterType","metabase.mbql.schema/ParameterType",1268586893);OQ=new $CLJS.r(null,"cum-sum","cum-sum",-589533420,null);Gka=new $CLJS.r("metabase.mbql.schema","trim","metabase.mbql.schema/trim",1991053191,null);PQ=new $CLJS.M(null,"y","y",-1757859776);QQ=new $CLJS.r(null,"aggregation","aggregation",-1056959073,null);
$CLJS.RQ=new $CLJS.M(null,"binning","binning",1709835866);SQ=new $CLJS.r(null,"TimezoneId","TimezoneId",-1406242397,null);TQ=new $CLJS.M("metabase.mbql.schema","\x3e","metabase.mbql.schema/\x3e",1034573295);UQ=new $CLJS.M("metabase.mbql.schema","\x3c","metabase.mbql.schema/\x3c",1597044182);VQ=new $CLJS.M("metabase.mbql.schema","\x3d","metabase.mbql.schema/\x3d",209553857);WQ=new $CLJS.r(null,"Reference","Reference",2024574086,null);XQ=new $CLJS.M(null,"b","b",1482224470);
YQ=new $CLJS.M(null,"a","a",-2123407586);ZQ=new $CLJS.M("metabase.mbql.schema","!\x3d","metabase.mbql.schema/!\x3d",1846805017);$Q=new $CLJS.r(null,"replace","replace",853943757,null);aR=new $CLJS.M("metabase.mbql.schema","cum-sum","metabase.mbql.schema/cum-sum",576093708);Hka=new $CLJS.r("metabase.mbql.schema","upper","metabase.mbql.schema/upper",993691634,null);bR=new $CLJS.M("metabase.mbql.schema","/","metabase.mbql.schema//",363603269);
cR=new $CLJS.M("metabase.mbql.schema","-","metabase.mbql.schema/-",1213831346);dR=new $CLJS.M("metabase.mbql.schema","*","metabase.mbql.schema/*",202015001);eR=new $CLJS.M("metabase.mbql.schema","+","metabase.mbql.schema/+",852320058);fR=new $CLJS.r(null,"segment","segment",675610331,null);Ika=new $CLJS.r("metabase.mbql.schema","get-week","metabase.mbql.schema/get-week",1436319181,null);gR=new $CLJS.M("metabase.mbql.schema","field","metabase.mbql.schema/field",-73678671);
hR=new $CLJS.r("metabase.mbql.schema","\x3e\x3d","metabase.mbql.schema/\x3e\x3d",1977236849,null);iR=new $CLJS.M("metabase.mbql.schema","count","metabase.mbql.schema/count",-962464844);jR=new $CLJS.M("string","starts-with","string/starts-with",1266861170);Jka=new $CLJS.r(null,"TemporalExtractUnit","TemporalExtractUnit",1691102108,null);$CLJS.kR=new $CLJS.M(null,"order-by","order-by",1527318070);lR=new $CLJS.M(null,"advanced-math-expressions","advanced-math-expressions",1076581757);
Kka=new $CLJS.r("metabase.mbql.schema","is-null","metabase.mbql.schema/is-null",637054412,null);Lka=new $CLJS.M("alias","escaped-\x3eoriginal","alias/escaped-\x3eoriginal",-1334024182);$CLJS.mR=new $CLJS.M(null,"condition","condition",1668437652);nR=new $CLJS.M("string","does-not-contain","string/does-not-contain",-1536178964);Mka=new $CLJS.M(null,"report-timezone","report-timezone",-2053796389);oR=new $CLJS.r("metabase.mbql.schema","\x3c\x3d","metabase.mbql.schema/\x3c\x3d",-2098137664,null);
Nka=new $CLJS.M(null,"card-name","card-name",-2035606807);pR=new $CLJS.M("metabase.mbql.schema","value","metabase.mbql.schema/value",772933314);Oka=new $CLJS.r("metabase.mbql.schema","sum-where","metabase.mbql.schema/sum-where",969078380,null);Pka=new $CLJS.r("metabase.mbql.schema","does-not-contain","metabase.mbql.schema/does-not-contain",-1742276611,null);qR=new $CLJS.r(null,"log","log",45015523,null);Qka=new $CLJS.r("metabase.mbql.schema","abs","metabase.mbql.schema/abs",1792880149,null);
rR=new $CLJS.r(null,"time","time",-1268547887,null);$CLJS.sR=new $CLJS.M(null,"database_type","database_type",-54700895);tR=new $CLJS.r(null,"EqualityComparable","EqualityComparable",-2076258858,null);uR=new $CLJS.r(null,"get-minute","get-minute",-1186349426,null);$CLJS.vR=new $CLJS.M(null,"clause","clause",1479668060);$CLJS.wR=new $CLJS.M(null,"expression-name","expression-name",-1798450709);$CLJS.xR=new $CLJS.M("date","all-options","date/all-options",549325958);
Rka=new $CLJS.r("metabase.mbql.schema","relative-datetime","metabase.mbql.schema/relative-datetime",1965492610,null);Ska=new $CLJS.M(null,"embedded-json-download","embedded-json-download",-1980927033);yR=new $CLJS.M("location","state","location/state",-114378652);zR=new $CLJS.r(null,"datetime-subtract","datetime-subtract",1915839224,null);Tka=new $CLJS.M(null,"aggregation-clause-index","aggregation-clause-index",-1510292261);Uka=new $CLJS.M(null,"lon-field","lon-field",517872067);
AR=new $CLJS.M("metabase.mbql.schema","TemporalLiteral","metabase.mbql.schema/TemporalLiteral",-904081026);BR=new $CLJS.r(null,"DateTimeExpressionArg","DateTimeExpressionArg",-1507653823,null);CR=new $CLJS.M(null,"numeric","numeric",-1495594714);DR=new $CLJS.r(null,"variable","variable",1359185035,null);ER=new $CLJS.r(null,"lower","lower",-1534114948,null);Vka=new $CLJS.M(null,"embedded-xlsx-download","embedded-xlsx-download",-1105087722);
FR=new $CLJS.M("metabase.mbql.schema","concat","metabase.mbql.schema/concat",2073716991);GR=new $CLJS.M("metabase.mbql.schema","get-week","metabase.mbql.schema/get-week",-204212346);$CLJS.HR=new $CLJS.M(null,"limit","limit",-1355822363);IR=new $CLJS.M("metabase.mbql.schema","round","metabase.mbql.schema/round",-1325713739);JR=new $CLJS.r(null,"ExtractWeekMode","ExtractWeekMode",397218079,null);Wka=new $CLJS.M(null,"pulse","pulse",-244494476);var KR=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,$CLJS.ND],null),LR=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,$CLJS.WD],null),MR=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,$CLJS.JD],null),NR=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,$CLJS.FD],null),OR=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,$CLJS.RD],null),PR=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,$CLJS.FK],null),Xka=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,$CLJS.sK],null),Yka=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,$CLJS.lK],null),QR=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,
$CLJS.bI],null),RR=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,$CLJS.fI],null),SR=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,$CLJS.XH],null),TR=new $CLJS.bh(null,new $CLJS.h(null,12,[$CLJS.qi,null,$CLJS.zt,null,$CLJS.pj,null,$CLJS.di,null,$CLJS.Hi,null,$CLJS.yj,null,$CLJS.mk,null,$CLJS.Hj,null,$CLJS.ki,null,$CLJS.Gj,null,$CLJS.ri,null,$CLJS.Bi,null],null),null),UR=new $CLJS.bh(null,new $CLJS.h(null,7,[$CLJS.rk,null,$CLJS.pG,null,$CLJS.di,null,$CLJS.xi,null,$CLJS.sG,null,$CLJS.jj,null,$CLJS.Vi,null],null),null),
VR=$CLJS.At.g(TR,UR),Zka=$CLJS.fg.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Jr,new $CLJS.h(null,1,[$CLJS.Ys,"date bucketing unit"],null)],null),TR),$ka=$CLJS.fg.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Jr,new $CLJS.h(null,1,[$CLJS.Ys,"time bucketing unit"],null)],null),UR),WR=$CLJS.fg.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Jr,new $CLJS.h(null,1,[$CLJS.Ys,"datetime bucketing unit"],null)],null),VR),YR=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,$CLJS.GI],null),ala=new $CLJS.P(null,13,5,$CLJS.Q,[$CLJS.Jr,new $CLJS.h(null,
1,[$CLJS.Ys,"temporal extract unit"],null),$CLJS.tG,$CLJS.Bi,$CLJS.mk,$CLJS.rI,$CLJS.MI,$CLJS.NI,$CLJS.Hj,$CLJS.Hi,$CLJS.xi,$CLJS.jj,$CLJS.qG],null),bla=new $CLJS.P(null,10,5,$CLJS.Q,[$CLJS.Jr,new $CLJS.h(null,1,[$CLJS.Ys,"datetime-diff unit"],null),$CLJS.pG,$CLJS.Vi,$CLJS.rk,$CLJS.zt,$CLJS.pj,$CLJS.yj,$CLJS.qi,$CLJS.ki],null),ZR=new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.Jr,new $CLJS.h(null,1,[$CLJS.Ys,"temporal-extract week extraction mode"],null),$CLJS.lI,$CLJS.uI,$CLJS.zI],null),$R=new $CLJS.P(null,
10,5,$CLJS.Q,[$CLJS.Jr,new $CLJS.h(null,1,[$CLJS.Ys,"relative-datetime unit"],null),$CLJS.di,$CLJS.Vi,$CLJS.rk,$CLJS.zt,$CLJS.pj,$CLJS.yj,$CLJS.qi,$CLJS.ki],null),gS,dla,oS,pS,qS,rS,sS,tS,uS,fU,fla,gU,gla,hla,hU,ila,jla,kla;$CLJS.X(pM,zL($CLJS.vI,$CLJS.H(["n",new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Mr,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.or,$CLJS.tv],null),$CLJS.Yr],null),"unit",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.fs,$R],null)])));var aS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,pM],null);
$CLJS.X(UO,zL($CLJS.tI,$CLJS.H(["n",$CLJS.Yr,"unit",$R])));var cla=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,UO],null);
$CLJS.X(KM,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.Sr,new $CLJS.h(null,2,[$CLJS.Ys,"valid :absolute-datetime clause",$CLJS.Pi,function(a){if($CLJS.Ua(yL($CLJS.pI,a)))a=JM;else{a=$CLJS.hd(a);var b=$CLJS.uL(QR);a=b.h?b.h(a):b.call(null,a);a=$CLJS.n(a)?$CLJS.YO:$CLJS.wI}return a}],null),new $CLJS.P(null,2,5,$CLJS.Q,[JM,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Si,new $CLJS.h(null,1,[$CLJS.Ys,"not an :absolute-datetime clause"],null),$CLJS.Ve(!1)],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.YO,zL($CLJS.pI,
$CLJS.H(["date",QR,"unit",Zka]))],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.wI,zL($CLJS.pI,$CLJS.H(["datetime",RR,"unit",WR]))],null)],null));var bS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,KM],null);$CLJS.X(ZM,zL($CLJS.fy,$CLJS.H(["time",SR,"unit",$ka])));var cS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,ZM],null),dS=new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.Mr,new $CLJS.h(null,1,[$CLJS.Ys,"date or datetime literal"],null),bS,RR,QR],null);
$CLJS.X(KL,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.Mr,new $CLJS.h(null,1,[$CLJS.Ys,"time literal"],null),cS,SR],null));$CLJS.X(AR,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.Mr,new $CLJS.h(null,1,[$CLJS.Ys,"temporal literal"],null),dS,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,KL],null)],null));var eS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,AR],null);
$CLJS.X(vka,AL($CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=FO.h($CLJS.pd("undefined"!==typeof $CLJS.CL&&"undefined"!==typeof DL&&"undefined"!==typeof EL&&"undefined"!==typeof bS?new $CLJS.Gc(function(){return bS},$CLJS.od(Via,new $CLJS.h(null,1,[$CLJS.ck,!0],null)),$CLJS.Jg([$CLJS.Xi,$CLJS.T,$CLJS.qk,$CLJS.wi,FO,$CLJS.Ui,SP,$CLJS.oj,$CLJS.zk,$CLJS.vi,$CLJS.kk,$CLJS.pk],[$CLJS.od(kQ,new $CLJS.h(null,1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),AP,"metabase/mbql/schema.cljc",69,$CLJS.pI,1,!0,168,168,$CLJS.Kc,"Schema for an `:absolute-datetime` clause.",$CLJS.n(bS)?bS.H:null])):null));return $CLJS.n(a)?a:AP}(),bS],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=FO.h($CLJS.pd("undefined"!==typeof $CLJS.CL&&"undefined"!==typeof DL&&"undefined"!==typeof EL&&"undefined"!==typeof aS?new $CLJS.Gc(function(){return aS},$CLJS.od(Rka,new $CLJS.h(null,1,[$CLJS.ck,!0],null)),$CLJS.Jg([$CLJS.Xi,$CLJS.T,$CLJS.qk,$CLJS.wi,FO,$CLJS.Ui,$CLJS.oj,
$CLJS.zk,BM,$CLJS.vi,$CLJS.kk,$CLJS.pk],[$CLJS.od(kQ,new $CLJS.h(null,1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),SO,"metabase/mbql/schema.cljc",29,$CLJS.vI,1,131,131,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.vI,$CLJS.sk,$CLJS.U($CLJS.Rj,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Mr,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.or,$CLJS.tv],null),$CLJS.Yr],null)),$CLJS.ti,$CLJS.U($CLJS.Rj,$CLJS.U(DQ,pO))],null),$CLJS.Kc,"Schema for a valid relative-datetime clause.",
$CLJS.n(aS)?aS.H:null])):null));return $CLJS.n(a)?a:SO}(),aS],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=FO.h($CLJS.pd("undefined"!==typeof $CLJS.CL&&"undefined"!==typeof DL&&"undefined"!==typeof EL&&"undefined"!==typeof cS?new $CLJS.Gc(function(){return cS},$CLJS.od(Tja,new $CLJS.h(null,1,[$CLJS.ck,!0],null)),$CLJS.Jg([$CLJS.Xi,$CLJS.T,$CLJS.qk,$CLJS.wi,FO,$CLJS.Ui,SP,$CLJS.oj,$CLJS.zk,BM,$CLJS.vi,$CLJS.kk,$CLJS.pk],[$CLJS.od(kQ,new $CLJS.h(null,1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),rR,"metabase/mbql/schema.cljc",27,$CLJS.fy,1,!0,175,175,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.fy,$CLJS.fy,$CLJS.U($CLJS.Rj,uka),$CLJS.ti,$CLJS.U($CLJS.Rj,mja)],null),$CLJS.Kc,"Schema for a valid time clause.",$CLJS.n(cS)?cS.H:null])):null));return $CLJS.n(a)?a:rR}(),cS],null)])));
$CLJS.X(pR,zL($CLJS.Ej,$CLJS.H(["value",$CLJS.mi,"type-info",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Lr,new $CLJS.P(null,6,5,$CLJS.Q,[$CLJS.ik,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.sR,new $CLJS.h(null,1,[$CLJS.fs,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Lr,KR],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.zC,new $CLJS.h(null,1,[$CLJS.fs,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Lr,LR],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.$N,new $CLJS.h(null,1,[$CLJS.fs,!0],null),new $CLJS.P(null,
2,5,$CLJS.Q,[$CLJS.Lr,MR],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ti,new $CLJS.h(null,1,[$CLJS.fs,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Lr,WR],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.T,new $CLJS.h(null,1,[$CLJS.fs,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Lr,KR],null)],null)],null)],null)])));var fS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,pR],null);$CLJS.X(ZN,zL($CLJS.QA,$CLJS.H(["expression-name",KR,"options",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.fs,$CLJS.ik],null)])));
gS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,ZN],null);
dla=function(a){return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Tr,a,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Si,new $CLJS.h(null,1,[$CLJS.Ys,"You must specify :num-bins when using the :num-bins strategy."],null),function(b){var c=$CLJS.O(b);b=$CLJS.J.g(c,$CLJS.YD);c=$CLJS.J.g(c,$CLJS.GD);return $CLJS.E.g(b,$CLJS.GD)?c:!0}],null)],null)}(function(a){return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Tr,a,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Si,new $CLJS.h(null,1,[$CLJS.Ys,"You must specify :bin-width when using the :bin-width strategy."],
null),function(b){var c=$CLJS.O(b);b=$CLJS.J.g(c,$CLJS.YD);c=$CLJS.J.g(c,$CLJS.XD);return $CLJS.E.g(b,$CLJS.XD)?c:!0}],null)],null)}(new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.ik,new $CLJS.h(null,1,[$CLJS.Ys,"binning options"],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.YD,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.Jr,new $CLJS.h(null,1,[$CLJS.Ys,"binning strategy"],null),$CLJS.GD,$CLJS.XD,$CLJS.di],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.GD,new $CLJS.h(null,1,[$CLJS.fs,!0],null),NR],null),new $CLJS.P(null,
3,5,$CLJS.Q,[$CLJS.XD,new $CLJS.h(null,1,[$CLJS.fs,!0],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Tr,$CLJS.Cl,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Si,new $CLJS.h(null,1,[$CLJS.Ys,"bin width must be \x3e\x3d 0."],null),$CLJS.Ue($CLJS.Xl)],null)],null)],null)],null)));$CLJS.hS=function hS(a){switch(arguments.length){case 1:return hS.h(arguments[0]);case 2:return hS.g(arguments[0],arguments[1]);default:throw Error(["Invalid arity: ",$CLJS.p.h(arguments.length)].join(""));}};
$CLJS.hS.h=function(a){var b=$CLJS.O(a);a=$CLJS.J.g(b,$CLJS.TA);b=$CLJS.J.g(b,$CLJS.LF);return $CLJS.hS.g(a,b)};$CLJS.hS.g=function(a,b){a=$CLJS.n($CLJS.n(b)?a:b)?$CLJS.MB(a,$CLJS.mC)?TR:$CLJS.MB(a,$CLJS.lD)?UR:$CLJS.MB(a,$CLJS.MC)?VR:null:null;return $CLJS.n(a)?$CLJS.Hd(a,b):!0};$CLJS.hS.A=2;
$CLJS.X(EQ,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Tr,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Tr,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.ik,new $CLJS.h(null,1,[$CLJS.Ys,"field options"],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.TA,new $CLJS.h(null,1,[$CLJS.fs,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Lr,LR],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.aN,new $CLJS.h(null,1,[$CLJS.fs,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Lr,PR],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.LF,new $CLJS.h(null,
1,[$CLJS.fs,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Lr,WR],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.QO,new $CLJS.h(null,1,[$CLJS.fs,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Lr,KR],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.RQ,new $CLJS.h(null,1,[$CLJS.fs,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Lr,dla],null)],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Si,new $CLJS.h(null,1,[$CLJS.Ys,"Invalid :temporal-unit for the specified :base-type."],null),$CLJS.hS],null)],
null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Si,new $CLJS.h(null,1,[$CLJS.Ys,"Found :binning keys at the top level of :field options. binning-related options belong under the :binning key."],null),$CLJS.Ue($CLJS.YD)],null)],null));
$CLJS.X(gR,function(a){return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Tr,a,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Si,new $CLJS.h(null,1,[$CLJS.Ys,":field clauses using a string field name must specify :base-type."],null),function(b){$CLJS.I(b,0,null);var c=$CLJS.I(b,1,null);b=$CLJS.I(b,2,null);b=$CLJS.O(b);b=$CLJS.J.g(b,$CLJS.TA);return"string"===typeof c?b:!0}],null)],null)}(zL($CLJS.FF,$CLJS.H(["id-or-name",new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Mr,PR,KR],null),"options",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Lr,
new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,EQ],null)],null)]))));var iS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,gR],null);
$CLJS.X(gP,AL($CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=FO.h($CLJS.pd("undefined"!==typeof $CLJS.CL&&"undefined"!==typeof DL&&"undefined"!==typeof EL&&"undefined"!==typeof gS?new $CLJS.Gc(function(){return gS},$CLJS.od(kN,new $CLJS.h(null,1,[$CLJS.ck,!0],null)),$CLJS.Jg([DO,$CLJS.Xi,$CLJS.T,$CLJS.qk,$CLJS.wi,FO,$CLJS.Ui,$CLJS.oj,$CLJS.zk,BM,$CLJS.vi,$CLJS.kk,$CLJS.pk],[new $CLJS.bh(null,new $CLJS.h(null,1,[$CLJS.vP,"null"],null),null),$CLJS.od(kQ,new $CLJS.h(null,1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),VM,"metabase/mbql/schema.cljc",60,$CLJS.QA,1,249,249,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.QA,$CLJS.wR,$CLJS.U($CLJS.Rj,rM),$CLJS.gj,$CLJS.U($CLJS.Rj,$CLJS.U(DQ,$CLJS.ik))],null),$CLJS.Kc,"Schema for a valid expression clause.",$CLJS.n(gS)?gS.H:null])):null));return $CLJS.n(a)?a:VM}(),gS],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=FO.h($CLJS.pd("undefined"!==typeof $CLJS.CL&&"undefined"!==typeof DL&&"undefined"!==typeof EL&&"undefined"!==typeof iS?new $CLJS.Gc(function(){return iS},
$CLJS.od(CN,new $CLJS.h(null,1,[$CLJS.ck,!0],null)),$CLJS.Jg([$CLJS.Kq,$CLJS.Xi,$CLJS.T,$CLJS.qk,$CLJS.wi,FO,$CLJS.Ui,$CLJS.oj,$CLJS.zk,$CLJS.vi,$CLJS.kk,$CLJS.pk],["0.39.0",$CLJS.od(kQ,new $CLJS.h(null,1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),DM,"metabase/mbql/schema.cljc",51,$CLJS.FF,1,382,382,$CLJS.Kc,"Schema for a `:field` clause.",$CLJS.n(iS)?iS.H:null])):null));return $CLJS.n(a)?a:DM}(),iS],null)])));
$CLJS.jS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,gP],null);$CLJS.X(FL,zL($CLJS.uF,$CLJS.H(["aggregation-clause-index",$CLJS.Yr,"options",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.fs,$CLJS.ik],null)])));var kS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,FL],null);
$CLJS.X(lM,AL($CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=FO.h($CLJS.pd("undefined"!==typeof $CLJS.CL&&"undefined"!==typeof DL&&"undefined"!==typeof EL&&"undefined"!==typeof kS?new $CLJS.Gc(function(){return kS},$CLJS.od(ska,new $CLJS.h(null,1,[$CLJS.ck,!0],null)),$CLJS.Jg([$CLJS.Xi,$CLJS.T,$CLJS.qk,$CLJS.wi,FO,$CLJS.Ui,$CLJS.oj,$CLJS.zk,BM,$CLJS.vi,$CLJS.kk,$CLJS.pk],[$CLJS.od(kQ,new $CLJS.h(null,1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),QQ,"metabase/mbql/schema.cljc",23,$CLJS.uF,1,418,418,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.uF,Tka,$CLJS.U($CLJS.Rj,$CLJS.Yr),$CLJS.gj,$CLJS.U($CLJS.Rj,$CLJS.U(DQ,$CLJS.ik))],null),$CLJS.Kc,"Schema for a valid aggregation clause.",$CLJS.n(kS)?kS.H:null])):null));return $CLJS.n(a)?a:QQ}(),kS],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=FO.h($CLJS.pd("undefined"!==typeof $CLJS.CL&&"undefined"!==typeof DL&&"undefined"!==typeof EL&&"undefined"!==typeof gS?new $CLJS.Gc(function(){return gS},
$CLJS.od(kN,new $CLJS.h(null,1,[$CLJS.ck,!0],null)),$CLJS.Jg([DO,$CLJS.Xi,$CLJS.T,$CLJS.qk,$CLJS.wi,FO,$CLJS.Ui,$CLJS.oj,$CLJS.zk,BM,$CLJS.vi,$CLJS.kk,$CLJS.pk],[new $CLJS.bh(null,new $CLJS.h(null,1,[$CLJS.vP,"null"],null),null),$CLJS.od(kQ,new $CLJS.h(null,1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),VM,"metabase/mbql/schema.cljc",60,$CLJS.QA,1,249,249,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.QA,$CLJS.wR,$CLJS.U($CLJS.Rj,rM),
$CLJS.gj,$CLJS.U($CLJS.Rj,$CLJS.U(DQ,$CLJS.ik))],null),$CLJS.Kc,"Schema for a valid expression clause.",$CLJS.n(gS)?gS.H:null])):null));return $CLJS.n(a)?a:VM}(),gS],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=FO.h($CLJS.pd("undefined"!==typeof $CLJS.CL&&"undefined"!==typeof DL&&"undefined"!==typeof EL&&"undefined"!==typeof iS?new $CLJS.Gc(function(){return iS},$CLJS.od(CN,new $CLJS.h(null,1,[$CLJS.ck,!0],null)),$CLJS.Jg([$CLJS.Kq,$CLJS.Xi,$CLJS.T,$CLJS.qk,$CLJS.wi,FO,$CLJS.Ui,$CLJS.oj,$CLJS.zk,
$CLJS.vi,$CLJS.kk,$CLJS.pk],["0.39.0",$CLJS.od(kQ,new $CLJS.h(null,1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),DM,"metabase/mbql/schema.cljc",51,$CLJS.FF,1,382,382,$CLJS.Kc,"Schema for a `:field` clause.",$CLJS.n(iS)?iS.H:null])):null));return $CLJS.n(a)?a:DM}(),iS],null)])));
var lS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,lM],null),mS=new $CLJS.bh(null,new $CLJS.h(null,11,[$CLJS.Os,null,$CLJS.YF,null,$CLJS.XE,null,$CLJS.dG,null,$CLJS.dF,null,$CLJS.IF,null,$CLJS.nF,null,$CLJS.Us,null,$CLJS.WF,null,$CLJS.HF,null,$CLJS.eG,null],null),null),nS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,iM],null);
$CLJS.X(ML,new $CLJS.P(null,6,5,$CLJS.Q,[$CLJS.Sr,new $CLJS.h(null,1,[$CLJS.Pi,function(a){return"string"===typeof a?$CLJS.ak:$CLJS.n(yL(mS,a))?eM:$CLJS.n(yL($CLJS.Ej,a))?$CLJS.Ej:$CLJS.Qs}],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ak,$CLJS.ak],null),new $CLJS.P(null,2,5,$CLJS.Q,[eM,nS],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ej,fS],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Qs,$CLJS.jS],null)],null));oS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,ML],null);
pS=new $CLJS.bh(null,new $CLJS.h(null,26,[$CLJS.$E,null,$CLJS.LI,null,$CLJS.rr,null,$CLJS.HI,null,$CLJS.EI,null,$CLJS.sF,null,$CLJS.Ds,null,$CLJS.WE,null,$CLJS.II,null,$CLJS.BI,null,$CLJS.IF,null,$CLJS.aG,null,$CLJS.FI,null,$CLJS.KF,null,$CLJS.bF,null,$CLJS.nI,null,$CLJS.qr,null,$CLJS.eF,null,$CLJS.nF,null,$CLJS.hI,null,$CLJS.px,null,$CLJS.oI,null,$CLJS.AI,null,$CLJS.sI,null,$CLJS.cG,null,$CLJS.DF,null],null),null);
qS=new $CLJS.bh(null,new $CLJS.h(null,9,[$CLJS.nr,null,$CLJS.hr,null,$CLJS.Mr,null,$CLJS.Or,null,$CLJS.jr,null,$CLJS.XF,null,$CLJS.Tr,null,$CLJS.or,null,$CLJS.lr,null],null),null);rS=new $CLJS.bh(null,new $CLJS.h(null,17,[$CLJS.Rn,null,$CLJS.VF,null,$CLJS.OF,null,$CLJS.GF,null,$CLJS.$F,null,$CLJS.bG,null,$CLJS.jF,null,$CLJS.qF,null,$CLJS.RL,null,$CLJS.kF,null,$CLJS.TF,null,$CLJS.MF,null,$CLJS.jk,null,$CLJS.rx,null,$CLJS.zF,null,$CLJS.AF,null,$CLJS.SF,null],null),null);
sS=new $CLJS.bh(null,new $CLJS.h(null,5,[$CLJS.xI,null,$CLJS.jI,null,$CLJS.JI,null,$CLJS.qr,null,$CLJS.DI,null],null),null);tS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,wQ],null);uS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,XM],null);$CLJS.vS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,YP],null);$CLJS.wS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,CQ],null);
$CLJS.X(JQ,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.Sr,new $CLJS.h(null,2,[$CLJS.Ys,"numeric expression argument",$CLJS.Pi,function(a){return"number"===typeof a?$CLJS.tt:$CLJS.n(yL(pS,a))?AO:$CLJS.n(yL(rS,a))?$CLJS.uF:$CLJS.n(yL($CLJS.Ej,a))?$CLJS.Ej:$CLJS.FF}],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.tt,$CLJS.Cl],null),new $CLJS.P(null,2,5,$CLJS.Q,[AO,tS],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.uF,$CLJS.wS],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ej,fS],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.FF,
$CLJS.jS],null)],null));var xS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,JQ],null);
$CLJS.X(TP,new $CLJS.P(null,6,5,$CLJS.Q,[$CLJS.Sr,new $CLJS.h(null,2,[$CLJS.Ys,"datetime expression argument",$CLJS.Pi,function(a){return $CLJS.n(yL(rS,a))?$CLJS.uF:$CLJS.n(yL($CLJS.Ej,a))?$CLJS.Ej:$CLJS.n(yL(sS,a))?dN:$CLJS.Qs}],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.uF,$CLJS.wS],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ej,fS],null),new $CLJS.P(null,2,5,$CLJS.Q,[dN,$CLJS.vS],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Qs,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Mr,dS,$CLJS.jS],null)],null)],null));
var yS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,TP],null);
$CLJS.X(wP,new $CLJS.P(null,11,5,$CLJS.Q,[$CLJS.Sr,new $CLJS.h(null,2,[$CLJS.Ys,"expression argument",$CLJS.Pi,function(a){return"number"===typeof a?$CLJS.tt:$CLJS.Cd(a)?$CLJS.bs:$CLJS.n(yL(qS,a))?LP:$CLJS.n(yL(pS,a))?AO:$CLJS.n(yL(sS,a))?dN:"string"===typeof a?$CLJS.ak:$CLJS.n(yL(mS,a))?eM:$CLJS.n(yL($CLJS.Ej,a))?$CLJS.Ej:$CLJS.Qs}],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.tt,$CLJS.Cl],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.bs,$CLJS.bs],null),new $CLJS.P(null,2,5,$CLJS.Q,[LP,uS],null),new $CLJS.P(null,
2,5,$CLJS.Q,[AO,tS],null),new $CLJS.P(null,2,5,$CLJS.Q,[dN,$CLJS.vS],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ak,$CLJS.ak],null),new $CLJS.P(null,2,5,$CLJS.Q,[eM,nS],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ej,fS],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Qs,$CLJS.jS],null)],null));var zS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,wP],null);$CLJS.X(uP,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.Mr,new $CLJS.h(null,1,[$CLJS.Ys,"numeric expression arg or interval"],null),cla,xS],null));
var AS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,uP],null);$CLJS.X(XL,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.Sr,new $CLJS.h(null,2,[$CLJS.Ys,"int greater than zero or numeric expression",$CLJS.Pi,function(a){return"number"===typeof a?$CLJS.tt:$CLJS.Qs}],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.tt,NR],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Qs,tS],null)],null));var ela=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,XL],null);
$CLJS.X(eN,zL($CLJS.IF,$CLJS.H(["a",zS,"b",zS,"more",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Qi,zS],null)])));var BS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,eN],null);$CLJS.X(JL,zL($CLJS.XE,$CLJS.H(["s",oS,"start",ela,"length",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.fs,xS],null)])));var CS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,JL],null);$CLJS.X(MP,zL($CLJS.px,$CLJS.H(["s",oS])));var DS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,MP],null);$CLJS.X(kM,zL($CLJS.WF,$CLJS.H(["s",oS])));
var ES=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,kM],null);$CLJS.X(oM,zL($CLJS.eG,$CLJS.H(["s",oS])));var FS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,oM],null);$CLJS.X(HM,zL($CLJS.YF,$CLJS.H(["s",oS])));var GS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,HM],null);$CLJS.X(sN,zL($CLJS.Os,$CLJS.H(["s",oS])));var HS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,sN],null);$CLJS.X(bM,zL($CLJS.Us,$CLJS.H(["s",oS])));var IS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,bM],null);
$CLJS.X(IQ,zL($CLJS.dG,$CLJS.H(["s",oS,"match",$CLJS.ak,"replacement",$CLJS.ak])));var JS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,IQ],null);$CLJS.X(FR,zL($CLJS.dF,$CLJS.H(["a",oS,"b",oS,"more",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Qi,oS],null)])));var KS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,FR],null);$CLJS.X(hO,zL($CLJS.HF,$CLJS.H(["s",oS,"pattern",$CLJS.ak])));var LS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,hO],null);
$CLJS.X(eR,zL($CLJS.qr,$CLJS.H(["x",AS,"y",AS,"more",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Qi,AS],null)])));var MS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,eR],null);$CLJS.X(cR,zL($CLJS.Ds,$CLJS.H(["x",xS,"y",AS,"more",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Qi,AS],null)])));var NS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,cR],null);$CLJS.X(bR,zL($CLJS.aG,$CLJS.H(["x",xS,"y",xS,"more",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Qi,xS],null)])));var OS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,bR],null);
$CLJS.X(dR,zL($CLJS.rr,$CLJS.H(["x",xS,"y",xS,"more",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Qi,xS],null)])));var PS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,dR],null);$CLJS.X(tP,zL($CLJS.DF,$CLJS.H(["x",xS])));var QS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,tP],null);$CLJS.X(lQ,zL($CLJS.$E,$CLJS.H(["x",xS])));var RS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,lQ],null);$CLJS.X(IR,zL($CLJS.KF,$CLJS.H(["x",xS])));var SS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,IR],null);$CLJS.X(yO,zL($CLJS.eF,$CLJS.H(["x",xS])));
var TS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,yO],null);$CLJS.X(EO,zL($CLJS.bF,$CLJS.H(["x",xS,"y",xS])));var US=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,EO],null);$CLJS.X(aP,zL($CLJS.sF,$CLJS.H(["x",xS])));var VS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,aP],null);$CLJS.X(YL,zL($CLJS.WE,$CLJS.H(["x",xS])));var WS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,YL],null);$CLJS.X(zP,zL($CLJS.cG,$CLJS.H(["x",xS])));var XS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,zP],null);
$CLJS.X(xM,zL($CLJS.sI,$CLJS.H(["datetime-x",yS,"datetime-y",yS,"unit",bla])));var YS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,xM],null);$CLJS.X(PM,zL($CLJS.HI,$CLJS.H(["datetime",yS,"unit",ala,"mode",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.fs,ZR],null)])));var ZS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,PM],null);$CLJS.X(GO,zL($CLJS.oI,$CLJS.H(["date",yS])));var $S=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,GO],null);$CLJS.X(hP,zL($CLJS.LI,$CLJS.H(["date",yS])));
var aT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,hP],null);$CLJS.X(LL,zL($CLJS.hI,$CLJS.H(["date",yS])));var bT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,LL],null);$CLJS.X(GR,zL($CLJS.BI,$CLJS.H(["date",yS,"mode",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.fs,ZR],null)])));var cT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,GR],null);$CLJS.X(TN,zL($CLJS.AI,$CLJS.H(["date",yS])));var dT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,TN],null);$CLJS.X(bP,zL($CLJS.FI,$CLJS.H(["date",yS])));
var eT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,bP],null);$CLJS.X(tM,zL($CLJS.II,$CLJS.H(["datetime",yS])));var fT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,tM],null);$CLJS.X(YN,zL($CLJS.EI,$CLJS.H(["datetime",yS])));var gT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,YN],null);$CLJS.X(mM,zL($CLJS.nI,$CLJS.H(["datetime",yS])));var hT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,mM],null);$CLJS.X(DN,zL($CLJS.jI,$CLJS.H(["datetime",yS,"to",YR,"from",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.fs,YR],null)])));
var iT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,DN],null),jT=new $CLJS.P(null,11,5,$CLJS.Q,[$CLJS.Jr,new $CLJS.h(null,1,[$CLJS.Ys,"datetime arithmetic unit"],null),$CLJS.sG,$CLJS.pG,$CLJS.Vi,$CLJS.rk,$CLJS.zt,$CLJS.pj,$CLJS.yj,$CLJS.qi,$CLJS.ki],null);$CLJS.X(IL,zL($CLJS.DI,$CLJS.H(["datetime",yS,"amount",xS,"unit",jT])));var kT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,IL],null);
$CLJS.X(fM,function(a){for(var b=[],c=arguments.length,d=0;;)if(d<c)b.push(arguments[d]),d+=1;else break;return zL(arguments[0],1<b.length?new $CLJS.w(b.slice(1),0,null):null)}($CLJS.xI));var lT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,fM],null);$CLJS.X(HL,zL($CLJS.JI,$CLJS.H(["datetime",yS,"amount",xS,"unit",jT])));var mT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,HL],null);
$CLJS.X(YP,AL($CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=FO.h($CLJS.pd("undefined"!==typeof $CLJS.CL&&"undefined"!==typeof DL&&"undefined"!==typeof EL&&"undefined"!==typeof MS?new $CLJS.Gc(function(){return MS},$CLJS.od(nP,new $CLJS.h(null,1,[$CLJS.ck,!0],null)),$CLJS.Jg([DO,$CLJS.Xi,$CLJS.T,$CLJS.qk,$CLJS.wi,FO,$CLJS.Ui,$CLJS.oj,$CLJS.zk,BM,$CLJS.vi,$CLJS.kk,$CLJS.pk],[new $CLJS.bh(null,new $CLJS.h(null,1,[$CLJS.vP,"null"],null),null),$CLJS.od(kQ,new $CLJS.h(null,1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),bO,"metabase/mbql/schema.cljc",51,$CLJS.qr,1,612,612,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.qr,$CLJS.VD,$CLJS.U($CLJS.Rj,rP),PQ,$CLJS.U($CLJS.Rj,rP),fN,$CLJS.U($CLJS.Rj,$CLJS.U($CLJS.Ni,rP))],null),$CLJS.Kc,"Schema for a valid + clause.",$CLJS.n(MS)?MS.H:null])):null));return $CLJS.n(a)?a:bO}(),MS],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=FO.h($CLJS.pd("undefined"!==typeof $CLJS.CL&&"undefined"!==typeof DL&&"undefined"!==typeof EL&&"undefined"!==typeof kT?new $CLJS.Gc(function(){return kT},
$CLJS.od(Dja,new $CLJS.h(null,1,[$CLJS.ck,!0],null)),$CLJS.Jg([DO,$CLJS.Xi,$CLJS.T,$CLJS.qk,$CLJS.wi,FO,$CLJS.Ui,$CLJS.oj,$CLJS.zk,BM,$CLJS.vi,$CLJS.kk,$CLJS.pk],[new $CLJS.bh(null,new $CLJS.h(null,1,[wN,"null"],null),null),$CLJS.od(kQ,new $CLJS.h(null,1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),iP,"metabase/mbql/schema.cljc",67,$CLJS.DI,1,703,703,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.DI,$CLJS.wI,$CLJS.U($CLJS.Rj,BR),hQ,$CLJS.U($CLJS.Rj,
mQ),$CLJS.ti,$CLJS.U($CLJS.Rj,uQ)],null),$CLJS.Kc,"Schema for a valid datetime-add clause.",$CLJS.n(kT)?kT.H:null])):null));return $CLJS.n(a)?a:iP}(),kT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=FO.h($CLJS.pd("undefined"!==typeof $CLJS.CL&&"undefined"!==typeof DL&&"undefined"!==typeof EL&&"undefined"!==typeof mT?new $CLJS.Gc(function(){return mT},$CLJS.od(wia,new $CLJS.h(null,1,[$CLJS.ck,!0],null)),$CLJS.Jg([DO,$CLJS.Xi,$CLJS.T,$CLJS.qk,$CLJS.wi,FO,$CLJS.Ui,$CLJS.oj,$CLJS.zk,BM,$CLJS.vi,
$CLJS.kk,$CLJS.pk],[new $CLJS.bh(null,new $CLJS.h(null,1,[wN,"null"],null),null),$CLJS.od(kQ,new $CLJS.h(null,1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),zR,"metabase/mbql/schema.cljc",72,$CLJS.JI,1,710,710,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.JI,$CLJS.wI,$CLJS.U($CLJS.Rj,BR),hQ,$CLJS.U($CLJS.Rj,mQ),$CLJS.ti,$CLJS.U($CLJS.Rj,uQ)],null),$CLJS.Kc,"Schema for a valid datetime-subtract clause.",$CLJS.n(mT)?mT.H:null])):null));
return $CLJS.n(a)?a:zR}(),mT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=FO.h($CLJS.pd("undefined"!==typeof $CLJS.CL&&"undefined"!==typeof DL&&"undefined"!==typeof EL&&"undefined"!==typeof iT?new $CLJS.Gc(function(){return iT},$CLJS.od(Bka,new $CLJS.h(null,1,[$CLJS.ck,!0],null)),$CLJS.Jg([DO,$CLJS.Xi,$CLJS.T,$CLJS.qk,$CLJS.wi,FO,$CLJS.Ui,$CLJS.oj,$CLJS.zk,BM,$CLJS.vi,$CLJS.kk,$CLJS.pk],[new $CLJS.bh(null,new $CLJS.h(null,1,[$CLJS.jI,"null"],null),null),$CLJS.od(kQ,new $CLJS.h(null,1,[$CLJS.kk,
"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),cN,"metabase/mbql/schema.cljc",71,$CLJS.jI,1,695,695,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.jI,$CLJS.wI,$CLJS.U($CLJS.Rj,BR),vja,$CLJS.U($CLJS.Rj,SQ),Aia,$CLJS.U($CLJS.Rj,$CLJS.U(DQ,SQ))],null),$CLJS.Kc,"Schema for a valid convert-timezone clause.",$CLJS.n(iT)?iT.H:null])):null));return $CLJS.n(a)?a:cN}(),iT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=FO.h($CLJS.pd("undefined"!==typeof $CLJS.CL&&
"undefined"!==typeof DL&&"undefined"!==typeof EL&&"undefined"!==typeof lT?new $CLJS.Gc(function(){return lT},$CLJS.od(dka,new $CLJS.h(null,1,[$CLJS.ck,!0],null)),$CLJS.Jg([DO,$CLJS.Xi,$CLJS.T,$CLJS.qk,$CLJS.wi,FO,$CLJS.Ui,$CLJS.oj,$CLJS.zk,BM,$CLJS.vi,$CLJS.kk,$CLJS.pk],[new $CLJS.bh(null,new $CLJS.h(null,1,[$CLJS.xI,"null"],null),null),$CLJS.od(kQ,new $CLJS.h(null,1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),HO,"metabase/mbql/schema.cljc",
45,$CLJS.xI,1,708,708,new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.xI],null),$CLJS.Kc,"Schema for a valid now clause.",$CLJS.n(lT)?lT.H:null])):null));return $CLJS.n(a)?a:HO}(),lT],null)])));$CLJS.nT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,dP],null);$CLJS.X(NL,zL($CLJS.Tr,$CLJS.H(["first-clause",$CLJS.nT,"second-clause",$CLJS.nT,"other-clauses",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Qi,$CLJS.nT],null)])));var oT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,NL],null);
$CLJS.X(zN,zL($CLJS.Mr,$CLJS.H(["first-clause",$CLJS.nT,"second-clause",$CLJS.nT,"other-clauses",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Qi,$CLJS.nT],null)])));var pT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,zN],null);$CLJS.X(hM,zL($CLJS.Or,$CLJS.H(["clause",$CLJS.nT])));
var qT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,hM],null),rT=new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.Sr,new $CLJS.h(null,3,[$CLJS.Ys,":field or :expression reference or :relative-datetime",$CLJS.it,$CLJS.Ve(":field or :expression reference or :relative-datetime"),$CLJS.Pi,function(a){return $CLJS.n(yL($CLJS.vI,a))?$CLJS.vI:$CLJS.Qs}],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.vI,aS],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Qs,$CLJS.jS],null)],null);
$CLJS.X(VP,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Lr,new $CLJS.h(null,1,[$CLJS.Ys,"equality comparable"],null),new $CLJS.P(null,8,5,$CLJS.Q,[$CLJS.Mr,$CLJS.bs,$CLJS.Cl,$CLJS.ak,eS,rT,zS,fS],null)],null));var sT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,VP],null);
$CLJS.X(mO,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.Sr,new $CLJS.h(null,2,[$CLJS.Ys,"order comparable",$CLJS.Pi,function(a){return $CLJS.n(yL($CLJS.Ej,a))?$CLJS.Ej:$CLJS.Qs}],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ej,fS],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Qs,new $CLJS.P(null,6,5,$CLJS.Q,[$CLJS.Mr,$CLJS.Cl,$CLJS.ak,eS,zS,rT],null)],null)],null));var tT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,mO],null);
$CLJS.X(VQ,zL($CLJS.or,$CLJS.H(["field",sT,"value-or-field",sT,"more-values-or-fields",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Qi,sT],null)])));var uT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,VQ],null);$CLJS.X(ZQ,zL($CLJS.XF,$CLJS.H(["field",sT,"value-or-field",sT,"more-values-or-fields",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Qi,sT],null)])));var vT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,ZQ],null);$CLJS.X(UQ,zL($CLJS.lr,$CLJS.H(["field",tT,"value-or-field",tT])));
var wT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,UQ],null);$CLJS.X(TQ,zL($CLJS.hr,$CLJS.H(["field",tT,"value-or-field",tT])));var xT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,TQ],null);$CLJS.X(IM,zL($CLJS.nr,$CLJS.H(["field",tT,"value-or-field",tT])));var yT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,IM],null);$CLJS.X(OM,zL($CLJS.jr,$CLJS.H(["field",tT,"value-or-field",tT])));var zT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,OM],null);$CLJS.X(DP,zL($CLJS.gF,$CLJS.H(["field",tT,"min",tT,"max",tT])));
var AT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,DP],null);$CLJS.X(eQ,zL($CLJS.mF,$CLJS.H(["lat-field",tT,"lon-field",tT,"lat-max",tT,"lon-min",tT,"lat-min",tT,"lon-max",tT])));var BT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,eQ],null);$CLJS.X(rN,zL($CLJS.fF,$CLJS.H(["field",$CLJS.jS])));var CT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,rN],null);$CLJS.X(gO,zL($CLJS.rF,$CLJS.H(["field",$CLJS.jS])));var DT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,gO],null);$CLJS.X(JN,zL($CLJS.hF,$CLJS.H(["field",$CLJS.jS])));
var ET=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,JN],null);$CLJS.X(QL,zL($CLJS.oF,$CLJS.H(["field",$CLJS.jS])));var FT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,QL],null),GT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ik,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.NF,new $CLJS.h(null,1,[$CLJS.fs,!0],null),$CLJS.bs],null)],null);$CLJS.X(WN,zL($CLJS.wF,$CLJS.H(["field",oS,"string-or-field",oS,"options",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.fs,GT],null)])));var HT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,WN],null);
$CLJS.X(PO,zL($CLJS.QF,$CLJS.H(["field",oS,"string-or-field",oS,"options",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.fs,GT],null)])));var IT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,PO],null);$CLJS.X(HN,zL($CLJS.EF,$CLJS.H(["field",oS,"string-or-field",oS,"options",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.fs,GT],null)])));var JT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,HN],null);$CLJS.X(xP,zL($CLJS.xF,$CLJS.H(["field",oS,"string-or-field",oS,"options",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.fs,GT],null)])));
var KT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,xP],null);$CLJS.X(ZL,zL($CLJS.RF,$CLJS.H(["field",$CLJS.jS,"n",new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Mr,$CLJS.Yr,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.Jr,$CLJS.tv,$CLJS.vx,$CLJS.lF],null)],null),"unit",$R,"options",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.fs,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ik,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.nl,new $CLJS.h(null,1,[$CLJS.fs,!0],null),$CLJS.bs],null)],null)],null)])));var LT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,ZL],null);
$CLJS.X(OP,zL($CLJS.cF,$CLJS.H(["segment-id",new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Mr,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,$CLJS.jK],null),KR],null)])));var MT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,OP],null);
$CLJS.X(XM,AL($CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=FO.h($CLJS.pd("undefined"!==typeof $CLJS.CL&&"undefined"!==typeof DL&&"undefined"!==typeof EL&&"undefined"!==typeof oT?new $CLJS.Gc(function(){return oT},$CLJS.od(HQ,new $CLJS.h(null,1,[$CLJS.ck,!0],null)),$CLJS.Jg([$CLJS.Xi,$CLJS.T,$CLJS.qk,$CLJS.wi,FO,$CLJS.Ui,$CLJS.oj,$CLJS.zk,BM,$CLJS.vi,$CLJS.kk,$CLJS.pk],[$CLJS.od(kQ,new $CLJS.h(null,1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),qO,"metabase/mbql/schema.cljc",15,$CLJS.Tr,1,724,724,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.Tr,gN,$CLJS.U($CLJS.Rj,vO),ZO,$CLJS.U($CLJS.Rj,vO),qN,$CLJS.U($CLJS.Rj,$CLJS.U($CLJS.Ni,vO))],null),$CLJS.Kc,"Schema for a valid and clause.",$CLJS.n(oT)?oT.H:null])):null));return $CLJS.n(a)?a:qO}(),oT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=FO.h($CLJS.pd("undefined"!==typeof $CLJS.CL&&"undefined"!==typeof DL&&"undefined"!==typeof EL&&"undefined"!==typeof pT?new $CLJS.Gc(function(){return pT},
$CLJS.od(cP,new $CLJS.h(null,1,[$CLJS.ck,!0],null)),$CLJS.Jg([$CLJS.Xi,$CLJS.T,$CLJS.qk,$CLJS.wi,FO,$CLJS.Ui,$CLJS.oj,$CLJS.zk,BM,$CLJS.vi,$CLJS.kk,$CLJS.pk],[$CLJS.od(kQ,new $CLJS.h(null,1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),fP,"metabase/mbql/schema.cljc",14,$CLJS.Mr,1,729,729,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.Mr,gN,$CLJS.U($CLJS.Rj,vO),ZO,$CLJS.U($CLJS.Rj,vO),qN,$CLJS.U($CLJS.Rj,$CLJS.U($CLJS.Ni,vO))],null),$CLJS.Kc,
"Schema for a valid or clause.",$CLJS.n(pT)?pT.H:null])):null));return $CLJS.n(a)?a:fP}(),pT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=FO.h($CLJS.pd("undefined"!==typeof $CLJS.CL&&"undefined"!==typeof DL&&"undefined"!==typeof EL&&"undefined"!==typeof qT?new $CLJS.Gc(function(){return qT},$CLJS.od(PP,new $CLJS.h(null,1,[$CLJS.ck,!0],null)),$CLJS.Jg([$CLJS.Xi,$CLJS.T,$CLJS.qk,$CLJS.wi,FO,$CLJS.Ui,$CLJS.oj,$CLJS.zk,BM,$CLJS.vi,$CLJS.kk,$CLJS.pk],[$CLJS.od(kQ,new $CLJS.h(null,1,[$CLJS.kk,
"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),IO,"metabase/mbql/schema.cljc",15,$CLJS.Or,1,734,734,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Or,$CLJS.vR,$CLJS.U($CLJS.Rj,vO)],null),$CLJS.Kc,"Schema for a valid not clause.",$CLJS.n(qT)?qT.H:null])):null));return $CLJS.n(a)?a:IO}(),qT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=FO.h($CLJS.pd("undefined"!==typeof $CLJS.CL&&"undefined"!==typeof DL&&"undefined"!==typeof EL&&"undefined"!==
typeof wT?new $CLJS.Gc(function(){return wT},$CLJS.od(lP,new $CLJS.h(null,1,[$CLJS.ck,!0],null)),$CLJS.Jg([$CLJS.Xi,$CLJS.T,$CLJS.qk,$CLJS.wi,FO,$CLJS.Ui,$CLJS.oj,$CLJS.zk,BM,$CLJS.vi,$CLJS.kk,$CLJS.pk],[$CLJS.od(kQ,new $CLJS.h(null,1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),jO,"metabase/mbql/schema.cljc",13,$CLJS.lr,1,798,798,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.lr,$CLJS.FF,$CLJS.U($CLJS.Rj,iN),sQ,$CLJS.U($CLJS.Rj,iN)],
null),$CLJS.Kc,"Schema for a valid \x3c clause.",$CLJS.n(wT)?wT.H:null])):null));return $CLJS.n(a)?a:jO}(),wT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=FO.h($CLJS.pd("undefined"!==typeof $CLJS.CL&&"undefined"!==typeof DL&&"undefined"!==typeof EL&&"undefined"!==typeof yT?new $CLJS.Gc(function(){return yT},$CLJS.od(oR,new $CLJS.h(null,1,[$CLJS.ck,!0],null)),$CLJS.Jg([$CLJS.Xi,$CLJS.T,$CLJS.qk,$CLJS.wi,FO,$CLJS.Ui,$CLJS.oj,$CLJS.zk,BM,$CLJS.vi,$CLJS.kk,$CLJS.pk],[$CLJS.od(kQ,new $CLJS.h(null,
1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),iO,"metabase/mbql/schema.cljc",14,$CLJS.nr,1,800,800,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.nr,$CLJS.FF,$CLJS.U($CLJS.Rj,iN),sQ,$CLJS.U($CLJS.Rj,iN)],null),$CLJS.Kc,"Schema for a valid \x3c\x3d clause.",$CLJS.n(yT)?yT.H:null])):null));return $CLJS.n(a)?a:iO}(),yT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=FO.h($CLJS.pd("undefined"!==typeof $CLJS.CL&&"undefined"!==typeof DL&&
"undefined"!==typeof EL&&"undefined"!==typeof xT?new $CLJS.Gc(function(){return xT},$CLJS.od(jP,new $CLJS.h(null,1,[$CLJS.ck,!0],null)),$CLJS.Jg([$CLJS.Xi,$CLJS.T,$CLJS.qk,$CLJS.wi,FO,$CLJS.Ui,$CLJS.oj,$CLJS.zk,BM,$CLJS.vi,$CLJS.kk,$CLJS.pk],[$CLJS.od(kQ,new $CLJS.h(null,1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),kO,"metabase/mbql/schema.cljc",13,$CLJS.hr,1,799,799,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.hr,$CLJS.FF,$CLJS.U($CLJS.Rj,
iN),sQ,$CLJS.U($CLJS.Rj,iN)],null),$CLJS.Kc,"Schema for a valid \x3e clause.",$CLJS.n(xT)?xT.H:null])):null));return $CLJS.n(a)?a:kO}(),xT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=FO.h($CLJS.pd("undefined"!==typeof $CLJS.CL&&"undefined"!==typeof DL&&"undefined"!==typeof EL&&"undefined"!==typeof zT?new $CLJS.Gc(function(){return zT},$CLJS.od(hR,new $CLJS.h(null,1,[$CLJS.ck,!0],null)),$CLJS.Jg([$CLJS.Xi,$CLJS.T,$CLJS.qk,$CLJS.wi,FO,$CLJS.Ui,$CLJS.oj,$CLJS.zk,BM,$CLJS.vi,$CLJS.kk,$CLJS.pk],
[$CLJS.od(kQ,new $CLJS.h(null,1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),rO,"metabase/mbql/schema.cljc",14,$CLJS.jr,1,801,801,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.jr,$CLJS.FF,$CLJS.U($CLJS.Rj,iN),sQ,$CLJS.U($CLJS.Rj,iN)],null),$CLJS.Kc,"Schema for a valid \x3e\x3d clause.",$CLJS.n(zT)?zT.H:null])):null));return $CLJS.n(a)?a:rO}(),zT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=FO.h($CLJS.pd("undefined"!==typeof $CLJS.CL&&
"undefined"!==typeof DL&&"undefined"!==typeof EL&&"undefined"!==typeof uT?new $CLJS.Gc(function(){return uT},$CLJS.od(kP,new $CLJS.h(null,1,[$CLJS.ck,!0],null)),$CLJS.Jg([$CLJS.Xi,$CLJS.T,$CLJS.qk,$CLJS.wi,FO,$CLJS.Ui,$CLJS.oj,$CLJS.zk,BM,$CLJS.vi,$CLJS.kk,$CLJS.pk],[$CLJS.od(kQ,new $CLJS.h(null,1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),lO,"metabase/mbql/schema.cljc",13,$CLJS.or,1,795,795,new $CLJS.P(null,7,5,$CLJS.Q,
[$CLJS.or,$CLJS.FF,$CLJS.U($CLJS.Rj,tR),sQ,$CLJS.U($CLJS.Rj,tR),WP,$CLJS.U($CLJS.Rj,$CLJS.U($CLJS.Ni,tR))],null),$CLJS.Kc,"Schema for a valid \x3d clause.",$CLJS.n(uT)?uT.H:null])):null));return $CLJS.n(a)?a:lO}(),uT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=FO.h($CLJS.pd("undefined"!==typeof $CLJS.CL&&"undefined"!==typeof DL&&"undefined"!==typeof EL&&"undefined"!==typeof vT?new $CLJS.Gc(function(){return vT},$CLJS.od(yM,new $CLJS.h(null,1,[$CLJS.ck,!0],null)),$CLJS.Jg([$CLJS.Xi,$CLJS.T,
$CLJS.qk,$CLJS.wi,FO,$CLJS.Ui,$CLJS.oj,$CLJS.zk,BM,$CLJS.vi,$CLJS.kk,$CLJS.pk],[$CLJS.od(kQ,new $CLJS.h(null,1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),UM,"metabase/mbql/schema.cljc",14,$CLJS.XF,1,796,796,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.XF,$CLJS.FF,$CLJS.U($CLJS.Rj,tR),sQ,$CLJS.U($CLJS.Rj,tR),WP,$CLJS.U($CLJS.Rj,$CLJS.U($CLJS.Ni,tR))],null),$CLJS.Kc,"Schema for a valid !\x3d clause.",$CLJS.n(vT)?vT.H:null])):null));
return $CLJS.n(a)?a:UM}(),vT],null)])));
$CLJS.X(dP,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.Sr,new $CLJS.h(null,2,[$CLJS.Ys,"valid filter expression",$CLJS.Pi,function(a){return $CLJS.n(yL(sS,a))?$CLJS.wI:$CLJS.n(yL(pS,a))?CR:$CLJS.n(yL(mS,a))?$CLJS.ak:$CLJS.n(yL(qS,a))?$CLJS.bs:$CLJS.Qs}],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.wI,$CLJS.vS],null),new $CLJS.P(null,2,5,$CLJS.Q,[CR,tS],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ak,nS],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.bs,uS],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Qs,AL($CLJS.H([new $CLJS.P(null,
2,5,$CLJS.Q,[function(){var a=FO.h($CLJS.pd("undefined"!==typeof $CLJS.CL&&"undefined"!==typeof DL&&"undefined"!==typeof EL&&"undefined"!==typeof oT?new $CLJS.Gc(function(){return oT},$CLJS.od(HQ,new $CLJS.h(null,1,[$CLJS.ck,!0],null)),$CLJS.Jg([$CLJS.Xi,$CLJS.T,$CLJS.qk,$CLJS.wi,FO,$CLJS.Ui,$CLJS.oj,$CLJS.zk,BM,$CLJS.vi,$CLJS.kk,$CLJS.pk],[$CLJS.od(kQ,new $CLJS.h(null,1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),qO,"metabase/mbql/schema.cljc",
15,$CLJS.Tr,1,724,724,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.Tr,gN,$CLJS.U($CLJS.Rj,vO),ZO,$CLJS.U($CLJS.Rj,vO),qN,$CLJS.U($CLJS.Rj,$CLJS.U($CLJS.Ni,vO))],null),$CLJS.Kc,"Schema for a valid and clause.",$CLJS.n(oT)?oT.H:null])):null));return $CLJS.n(a)?a:qO}(),oT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=FO.h($CLJS.pd("undefined"!==typeof $CLJS.CL&&"undefined"!==typeof DL&&"undefined"!==typeof EL&&"undefined"!==typeof pT?new $CLJS.Gc(function(){return pT},$CLJS.od(cP,new $CLJS.h(null,1,[$CLJS.ck,
!0],null)),$CLJS.Jg([$CLJS.Xi,$CLJS.T,$CLJS.qk,$CLJS.wi,FO,$CLJS.Ui,$CLJS.oj,$CLJS.zk,BM,$CLJS.vi,$CLJS.kk,$CLJS.pk],[$CLJS.od(kQ,new $CLJS.h(null,1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),fP,"metabase/mbql/schema.cljc",14,$CLJS.Mr,1,729,729,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.Mr,gN,$CLJS.U($CLJS.Rj,vO),ZO,$CLJS.U($CLJS.Rj,vO),qN,$CLJS.U($CLJS.Rj,$CLJS.U($CLJS.Ni,vO))],null),$CLJS.Kc,"Schema for a valid or clause.",$CLJS.n(pT)?
pT.H:null])):null));return $CLJS.n(a)?a:fP}(),pT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=FO.h($CLJS.pd("undefined"!==typeof $CLJS.CL&&"undefined"!==typeof DL&&"undefined"!==typeof EL&&"undefined"!==typeof qT?new $CLJS.Gc(function(){return qT},$CLJS.od(PP,new $CLJS.h(null,1,[$CLJS.ck,!0],null)),$CLJS.Jg([$CLJS.Xi,$CLJS.T,$CLJS.qk,$CLJS.wi,FO,$CLJS.Ui,$CLJS.oj,$CLJS.zk,BM,$CLJS.vi,$CLJS.kk,$CLJS.pk],[$CLJS.od(kQ,new $CLJS.h(null,1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),IO,"metabase/mbql/schema.cljc",15,$CLJS.Or,1,734,734,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Or,$CLJS.vR,$CLJS.U($CLJS.Rj,vO)],null),$CLJS.Kc,"Schema for a valid not clause.",$CLJS.n(qT)?qT.H:null])):null));return $CLJS.n(a)?a:IO}(),qT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=FO.h($CLJS.pd("undefined"!==typeof $CLJS.CL&&"undefined"!==typeof DL&&"undefined"!==typeof EL&&"undefined"!==typeof uT?new $CLJS.Gc(function(){return uT},$CLJS.od(kP,new $CLJS.h(null,1,[$CLJS.ck,!0],null)),$CLJS.Jg([$CLJS.Xi,
$CLJS.T,$CLJS.qk,$CLJS.wi,FO,$CLJS.Ui,$CLJS.oj,$CLJS.zk,BM,$CLJS.vi,$CLJS.kk,$CLJS.pk],[$CLJS.od(kQ,new $CLJS.h(null,1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),lO,"metabase/mbql/schema.cljc",13,$CLJS.or,1,795,795,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.or,$CLJS.FF,$CLJS.U($CLJS.Rj,tR),sQ,$CLJS.U($CLJS.Rj,tR),WP,$CLJS.U($CLJS.Rj,$CLJS.U($CLJS.Ni,tR))],null),$CLJS.Kc,"Schema for a valid \x3d clause.",$CLJS.n(uT)?uT.H:null])):
null));return $CLJS.n(a)?a:lO}(),uT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=FO.h($CLJS.pd("undefined"!==typeof $CLJS.CL&&"undefined"!==typeof DL&&"undefined"!==typeof EL&&"undefined"!==typeof vT?new $CLJS.Gc(function(){return vT},$CLJS.od(yM,new $CLJS.h(null,1,[$CLJS.ck,!0],null)),$CLJS.Jg([$CLJS.Xi,$CLJS.T,$CLJS.qk,$CLJS.wi,FO,$CLJS.Ui,$CLJS.oj,$CLJS.zk,BM,$CLJS.vi,$CLJS.kk,$CLJS.pk],[$CLJS.od(kQ,new $CLJS.h(null,1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),UM,"metabase/mbql/schema.cljc",14,$CLJS.XF,1,796,796,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.XF,$CLJS.FF,$CLJS.U($CLJS.Rj,tR),sQ,$CLJS.U($CLJS.Rj,tR),WP,$CLJS.U($CLJS.Rj,$CLJS.U($CLJS.Ni,tR))],null),$CLJS.Kc,"Schema for a valid !\x3d clause.",$CLJS.n(vT)?vT.H:null])):null));return $CLJS.n(a)?a:UM}(),vT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=FO.h($CLJS.pd("undefined"!==typeof $CLJS.CL&&"undefined"!==typeof DL&&"undefined"!==typeof EL&&"undefined"!==typeof wT?new $CLJS.Gc(function(){return wT},
$CLJS.od(lP,new $CLJS.h(null,1,[$CLJS.ck,!0],null)),$CLJS.Jg([$CLJS.Xi,$CLJS.T,$CLJS.qk,$CLJS.wi,FO,$CLJS.Ui,$CLJS.oj,$CLJS.zk,BM,$CLJS.vi,$CLJS.kk,$CLJS.pk],[$CLJS.od(kQ,new $CLJS.h(null,1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),jO,"metabase/mbql/schema.cljc",13,$CLJS.lr,1,798,798,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.lr,$CLJS.FF,$CLJS.U($CLJS.Rj,iN),sQ,$CLJS.U($CLJS.Rj,iN)],null),$CLJS.Kc,"Schema for a valid \x3c clause.",
$CLJS.n(wT)?wT.H:null])):null));return $CLJS.n(a)?a:jO}(),wT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=FO.h($CLJS.pd("undefined"!==typeof $CLJS.CL&&"undefined"!==typeof DL&&"undefined"!==typeof EL&&"undefined"!==typeof xT?new $CLJS.Gc(function(){return xT},$CLJS.od(jP,new $CLJS.h(null,1,[$CLJS.ck,!0],null)),$CLJS.Jg([$CLJS.Xi,$CLJS.T,$CLJS.qk,$CLJS.wi,FO,$CLJS.Ui,$CLJS.oj,$CLJS.zk,BM,$CLJS.vi,$CLJS.kk,$CLJS.pk],[$CLJS.od(kQ,new $CLJS.h(null,1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),kO,"metabase/mbql/schema.cljc",13,$CLJS.hr,1,799,799,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.hr,$CLJS.FF,$CLJS.U($CLJS.Rj,iN),sQ,$CLJS.U($CLJS.Rj,iN)],null),$CLJS.Kc,"Schema for a valid \x3e clause.",$CLJS.n(xT)?xT.H:null])):null));return $CLJS.n(a)?a:kO}(),xT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=FO.h($CLJS.pd("undefined"!==typeof $CLJS.CL&&"undefined"!==typeof DL&&"undefined"!==typeof EL&&"undefined"!==typeof yT?new $CLJS.Gc(function(){return yT},$CLJS.od(oR,new $CLJS.h(null,
1,[$CLJS.ck,!0],null)),$CLJS.Jg([$CLJS.Xi,$CLJS.T,$CLJS.qk,$CLJS.wi,FO,$CLJS.Ui,$CLJS.oj,$CLJS.zk,BM,$CLJS.vi,$CLJS.kk,$CLJS.pk],[$CLJS.od(kQ,new $CLJS.h(null,1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),iO,"metabase/mbql/schema.cljc",14,$CLJS.nr,1,800,800,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.nr,$CLJS.FF,$CLJS.U($CLJS.Rj,iN),sQ,$CLJS.U($CLJS.Rj,iN)],null),$CLJS.Kc,"Schema for a valid \x3c\x3d clause.",$CLJS.n(yT)?yT.H:null])):
null));return $CLJS.n(a)?a:iO}(),yT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=FO.h($CLJS.pd("undefined"!==typeof $CLJS.CL&&"undefined"!==typeof DL&&"undefined"!==typeof EL&&"undefined"!==typeof zT?new $CLJS.Gc(function(){return zT},$CLJS.od(hR,new $CLJS.h(null,1,[$CLJS.ck,!0],null)),$CLJS.Jg([$CLJS.Xi,$CLJS.T,$CLJS.qk,$CLJS.wi,FO,$CLJS.Ui,$CLJS.oj,$CLJS.zk,BM,$CLJS.vi,$CLJS.kk,$CLJS.pk],[$CLJS.od(kQ,new $CLJS.h(null,1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),rO,"metabase/mbql/schema.cljc",14,$CLJS.jr,1,801,801,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.jr,$CLJS.FF,$CLJS.U($CLJS.Rj,iN),sQ,$CLJS.U($CLJS.Rj,iN)],null),$CLJS.Kc,"Schema for a valid \x3e\x3d clause.",$CLJS.n(zT)?zT.H:null])):null));return $CLJS.n(a)?a:rO}(),zT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=FO.h($CLJS.pd("undefined"!==typeof $CLJS.CL&&"undefined"!==typeof DL&&"undefined"!==typeof EL&&"undefined"!==typeof AT?new $CLJS.Gc(function(){return AT},$CLJS.od(Vja,new $CLJS.h(null,
1,[$CLJS.ck,!0],null)),$CLJS.Jg([$CLJS.Xi,$CLJS.T,$CLJS.qk,$CLJS.wi,FO,$CLJS.Ui,$CLJS.oj,$CLJS.zk,BM,$CLJS.vi,$CLJS.kk,$CLJS.pk],[$CLJS.od(kQ,new $CLJS.h(null,1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),AM,"metabase/mbql/schema.cljc",19,$CLJS.gF,1,804,804,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.gF,$CLJS.FF,$CLJS.U($CLJS.Rj,iN),$CLJS.Rn,$CLJS.U($CLJS.Rj,iN),$CLJS.jk,$CLJS.U($CLJS.Rj,iN)],null),$CLJS.Kc,"Schema for a valid between clause.",
$CLJS.n(AT)?AT.H:null])):null));return $CLJS.n(a)?a:AM}(),AT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=FO.h($CLJS.pd("undefined"!==typeof $CLJS.CL&&"undefined"!==typeof DL&&"undefined"!==typeof EL&&"undefined"!==typeof HT?new $CLJS.Gc(function(){return HT},$CLJS.od(Xja,new $CLJS.h(null,1,[$CLJS.ck,!0],null)),$CLJS.Jg([$CLJS.Xi,$CLJS.T,$CLJS.qk,$CLJS.wi,FO,$CLJS.Ui,$CLJS.oj,$CLJS.zk,BM,$CLJS.vi,$CLJS.kk,$CLJS.pk],[$CLJS.od(kQ,new $CLJS.h(null,1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),xQ,"metabase/mbql/schema.cljc",23,$CLJS.wF,1,829,829,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.wF,$CLJS.FF,$CLJS.U($CLJS.Rj,rQ),TM,$CLJS.U($CLJS.Rj,rQ),$CLJS.gj,$CLJS.U($CLJS.Rj,$CLJS.U(DQ,NP))],null),$CLJS.Kc,"Schema for a valid starts-with clause.",$CLJS.n(HT)?HT.H:null])):null));return $CLJS.n(a)?a:xQ}(),HT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=FO.h($CLJS.pd("undefined"!==typeof $CLJS.CL&&"undefined"!==typeof DL&&"undefined"!==typeof EL&&"undefined"!==typeof IT?new $CLJS.Gc(function(){return IT},
$CLJS.od(Yia,new $CLJS.h(null,1,[$CLJS.ck,!0],null)),$CLJS.Jg([$CLJS.Xi,$CLJS.T,$CLJS.qk,$CLJS.wi,FO,$CLJS.Ui,$CLJS.oj,$CLJS.zk,BM,$CLJS.vi,$CLJS.kk,$CLJS.pk],[$CLJS.od(kQ,new $CLJS.h(null,1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),YM,"metabase/mbql/schema.cljc",21,$CLJS.QF,1,830,830,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.QF,$CLJS.FF,$CLJS.U($CLJS.Rj,rQ),TM,$CLJS.U($CLJS.Rj,rQ),$CLJS.gj,$CLJS.U($CLJS.Rj,$CLJS.U(DQ,NP))],null),
$CLJS.Kc,"Schema for a valid ends-with clause.",$CLJS.n(IT)?IT.H:null])):null));return $CLJS.n(a)?a:YM}(),IT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=FO.h($CLJS.pd("undefined"!==typeof $CLJS.CL&&"undefined"!==typeof DL&&"undefined"!==typeof EL&&"undefined"!==typeof JT?new $CLJS.Gc(function(){return JT},$CLJS.od(kja,new $CLJS.h(null,1,[$CLJS.ck,!0],null)),$CLJS.Jg([$CLJS.Xi,$CLJS.T,$CLJS.qk,$CLJS.wi,FO,$CLJS.Ui,$CLJS.oj,$CLJS.zk,BM,$CLJS.vi,$CLJS.kk,$CLJS.pk],[$CLJS.od(kQ,new $CLJS.h(null,
1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),jN,"metabase/mbql/schema.cljc",20,$CLJS.EF,1,831,831,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.EF,$CLJS.FF,$CLJS.U($CLJS.Rj,rQ),TM,$CLJS.U($CLJS.Rj,rQ),$CLJS.gj,$CLJS.U($CLJS.Rj,$CLJS.U(DQ,NP))],null),$CLJS.Kc,"Schema for a valid contains clause.",$CLJS.n(JT)?JT.H:null])):null));return $CLJS.n(a)?a:jN}(),JT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=FO.h($CLJS.pd("undefined"!==
typeof $CLJS.CL&&"undefined"!==typeof DL&&"undefined"!==typeof EL&&"undefined"!==typeof KT?new $CLJS.Gc(function(){return KT},$CLJS.od(Pka,new $CLJS.h(null,1,[$CLJS.ck,!0],null)),$CLJS.Jg([$CLJS.Xi,$CLJS.T,$CLJS.qk,$CLJS.wi,FO,$CLJS.Ui,$CLJS.oj,$CLJS.zk,FQ,BM,$CLJS.vi,$CLJS.kk,$CLJS.pk],[$CLJS.od(kQ,new $CLJS.h(null,1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),OL,"metabase/mbql/schema.cljc",36,$CLJS.xF,1,834,834,!0,new $CLJS.P(null,
7,5,$CLJS.Q,[$CLJS.xF,$CLJS.FF,$CLJS.U($CLJS.Rj,rQ),TM,$CLJS.U($CLJS.Rj,rQ),$CLJS.gj,$CLJS.U($CLJS.Rj,$CLJS.U(DQ,NP))],null),$CLJS.Kc,"Schema for a valid does-not-contain clause.",$CLJS.n(KT)?KT.H:null])):null));return $CLJS.n(a)?a:OL}(),KT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=FO.h($CLJS.pd("undefined"!==typeof $CLJS.CL&&"undefined"!==typeof DL&&"undefined"!==typeof EL&&"undefined"!==typeof BT?new $CLJS.Gc(function(){return BT},$CLJS.od(yka,new $CLJS.h(null,1,[$CLJS.ck,!0],null)),
$CLJS.Jg([$CLJS.Xi,$CLJS.T,$CLJS.qk,$CLJS.wi,FO,$CLJS.Ui,$CLJS.oj,$CLJS.zk,FQ,BM,$CLJS.vi,$CLJS.kk,$CLJS.pk],[$CLJS.od(kQ,new $CLJS.h(null,1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),vQ,"metabase/mbql/schema.cljc",26,$CLJS.mF,1,807,807,!0,new $CLJS.P(null,13,5,$CLJS.Q,[$CLJS.mF,Gia,$CLJS.U($CLJS.Rj,iN),Uka,$CLJS.U($CLJS.Rj,iN),Fka,$CLJS.U($CLJS.Rj,iN),Jia,$CLJS.U($CLJS.Rj,iN),zka,$CLJS.U($CLJS.Rj,iN),Fia,$CLJS.U($CLJS.Rj,
iN)],null),$CLJS.Kc,"Schema for a valid inside clause.",$CLJS.n(BT)?BT.H:null])):null));return $CLJS.n(a)?a:vQ}(),BT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=FO.h($CLJS.pd("undefined"!==typeof $CLJS.CL&&"undefined"!==typeof DL&&"undefined"!==typeof EL&&"undefined"!==typeof ET?new $CLJS.Gc(function(){return ET},$CLJS.od(lja,new $CLJS.h(null,1,[$CLJS.ck,!0],null)),$CLJS.Jg([$CLJS.Xi,$CLJS.T,$CLJS.qk,$CLJS.wi,FO,$CLJS.Ui,$CLJS.oj,$CLJS.zk,FQ,BM,$CLJS.vi,$CLJS.kk,$CLJS.pk],[$CLJS.od(kQ,new $CLJS.h(null,
1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),RO,"metabase/mbql/schema.cljc",28,$CLJS.hF,1,821,821,!0,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.hF,$CLJS.FF,$CLJS.U($CLJS.Rj,bN)],null),$CLJS.Kc,"Schema for a valid is-empty clause.",$CLJS.n(ET)?ET.H:null])):null));return $CLJS.n(a)?a:RO}(),ET],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=FO.h($CLJS.pd("undefined"!==typeof $CLJS.CL&&"undefined"!==typeof DL&&"undefined"!==typeof EL&&
"undefined"!==typeof FT?new $CLJS.Gc(function(){return FT},$CLJS.od(jka,new $CLJS.h(null,1,[$CLJS.ck,!0],null)),$CLJS.Jg([$CLJS.Xi,$CLJS.T,$CLJS.qk,$CLJS.wi,FO,$CLJS.Ui,$CLJS.oj,$CLJS.zk,FQ,BM,$CLJS.vi,$CLJS.kk,$CLJS.pk],[$CLJS.od(kQ,new $CLJS.h(null,1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),EP,"metabase/mbql/schema.cljc",29,$CLJS.oF,1,822,822,!0,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.oF,$CLJS.FF,$CLJS.U($CLJS.Rj,bN)],null),
$CLJS.Kc,"Schema for a valid not-empty clause.",$CLJS.n(FT)?FT.H:null])):null));return $CLJS.n(a)?a:EP}(),FT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=FO.h($CLJS.pd("undefined"!==typeof $CLJS.CL&&"undefined"!==typeof DL&&"undefined"!==typeof EL&&"undefined"!==typeof CT?new $CLJS.Gc(function(){return CT},$CLJS.od(Kka,new $CLJS.h(null,1,[$CLJS.ck,!0],null)),$CLJS.Jg([$CLJS.Xi,$CLJS.T,$CLJS.qk,$CLJS.wi,FO,$CLJS.Ui,$CLJS.oj,$CLJS.zk,FQ,BM,$CLJS.vi,$CLJS.kk,$CLJS.pk],[$CLJS.od(kQ,new $CLJS.h(null,
1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),pN,"metabase/mbql/schema.cljc",27,$CLJS.fF,1,816,816,!0,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.fF,$CLJS.FF,$CLJS.U($CLJS.Rj,bN)],null),$CLJS.Kc,"Schema for a valid is-null clause.",$CLJS.n(CT)?CT.H:null])):null));return $CLJS.n(a)?a:pN}(),CT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=FO.h($CLJS.pd("undefined"!==typeof $CLJS.CL&&"undefined"!==typeof DL&&"undefined"!==typeof EL&&
"undefined"!==typeof DT?new $CLJS.Gc(function(){return DT},$CLJS.od(xja,new $CLJS.h(null,1,[$CLJS.ck,!0],null)),$CLJS.Jg([$CLJS.Xi,$CLJS.T,$CLJS.qk,$CLJS.wi,FO,$CLJS.Ui,$CLJS.oj,$CLJS.zk,FQ,BM,$CLJS.vi,$CLJS.kk,$CLJS.pk],[$CLJS.od(kQ,new $CLJS.h(null,1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),FM,"metabase/mbql/schema.cljc",28,$CLJS.rF,1,817,817,!0,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.rF,$CLJS.FF,$CLJS.U($CLJS.Rj,bN)],null),
$CLJS.Kc,"Schema for a valid not-null clause.",$CLJS.n(DT)?DT.H:null])):null));return $CLJS.n(a)?a:FM}(),DT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=FO.h($CLJS.pd("undefined"!==typeof $CLJS.CL&&"undefined"!==typeof DL&&"undefined"!==typeof EL&&"undefined"!==typeof LT?new $CLJS.Gc(function(){return LT},$CLJS.od(xia,new $CLJS.h(null,1,[$CLJS.ck,!0],null)),$CLJS.Jg([$CLJS.Xi,$CLJS.T,$CLJS.qk,$CLJS.wi,FO,$CLJS.Ui,$CLJS.oj,$CLJS.zk,FQ,BM,$CLJS.vi,$CLJS.kk,$CLJS.pk],[$CLJS.od(kQ,new $CLJS.h(null,
1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),cQ,"metabase/mbql/schema.cljc",33,$CLJS.RF,1,856,856,!0,new $CLJS.P(null,9,5,$CLJS.Q,[$CLJS.RF,$CLJS.FF,$CLJS.U($CLJS.Rj,bN),$CLJS.sk,$CLJS.U($CLJS.Rj,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Mr,$CLJS.Yr,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.Jr,$CLJS.tv,$CLJS.vx,$CLJS.lF],null)],null)),$CLJS.ti,$CLJS.U($CLJS.Rj,pO),$CLJS.gj,$CLJS.U($CLJS.Rj,$CLJS.U(DQ,$ja))],null),$CLJS.Kc,"Schema for a valid time-interval clause.",
$CLJS.n(LT)?LT.H:null])):null));return $CLJS.n(a)?a:cQ}(),LT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=FO.h($CLJS.pd("undefined"!==typeof $CLJS.CL&&"undefined"!==typeof DL&&"undefined"!==typeof EL&&"undefined"!==typeof MT?new $CLJS.Gc(function(){return MT},$CLJS.od(Fja,new $CLJS.h(null,1,[$CLJS.ck,!0],null)),$CLJS.Jg([$CLJS.Xi,$CLJS.T,$CLJS.qk,$CLJS.wi,FO,$CLJS.Ui,$CLJS.oj,$CLJS.zk,FQ,BM,$CLJS.vi,$CLJS.kk,$CLJS.pk],[$CLJS.od(kQ,new $CLJS.h(null,1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),fR,"metabase/mbql/schema.cljc",27,$CLJS.cF,1,872,872,!0,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.cF,Nia,$CLJS.U($CLJS.Rj,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Mr,pka,rM],null))],null),$CLJS.Kc,"Schema for a valid segment clause.",$CLJS.n(MT)?MT.H:null])):null));return $CLJS.n(a)?a:fR}(),MT],null)]))],null)],null));
$CLJS.X(QP,zL($CLJS.nF,$CLJS.H(["clauses",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Lr,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Aj,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.Pr,new $CLJS.h(null,1,[$CLJS.Ys,":case subclause"],null),$CLJS.nT,zS],null)],null)],null),"options",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.fs,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ik,new $CLJS.h(null,1,[$CLJS.Ys,":case options"],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.di,new $CLJS.h(null,1,[$CLJS.fs,!0],null),zS],null)],null)],null)])));
var NT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,QP],null);
$CLJS.X(wQ,AL($CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=FO.h($CLJS.pd("undefined"!==typeof $CLJS.CL&&"undefined"!==typeof DL&&"undefined"!==typeof EL&&"undefined"!==typeof MS?new $CLJS.Gc(function(){return MS},$CLJS.od(nP,new $CLJS.h(null,1,[$CLJS.ck,!0],null)),$CLJS.Jg([DO,$CLJS.Xi,$CLJS.T,$CLJS.qk,$CLJS.wi,FO,$CLJS.Ui,$CLJS.oj,$CLJS.zk,BM,$CLJS.vi,$CLJS.kk,$CLJS.pk],[new $CLJS.bh(null,new $CLJS.h(null,1,[$CLJS.vP,"null"],null),null),$CLJS.od(kQ,new $CLJS.h(null,1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),bO,"metabase/mbql/schema.cljc",51,$CLJS.qr,1,612,612,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.qr,$CLJS.VD,$CLJS.U($CLJS.Rj,rP),PQ,$CLJS.U($CLJS.Rj,rP),fN,$CLJS.U($CLJS.Rj,$CLJS.U($CLJS.Ni,rP))],null),$CLJS.Kc,"Schema for a valid + clause.",$CLJS.n(MS)?MS.H:null])):null));return $CLJS.n(a)?a:bO}(),MS],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=FO.h($CLJS.pd("undefined"!==typeof $CLJS.CL&&"undefined"!==typeof DL&&"undefined"!==typeof EL&&"undefined"!==typeof NS?new $CLJS.Gc(function(){return NS},
$CLJS.od(Rja,new $CLJS.h(null,1,[$CLJS.ck,!0],null)),$CLJS.Jg([DO,$CLJS.Xi,$CLJS.T,$CLJS.qk,$CLJS.wi,FO,$CLJS.Ui,$CLJS.oj,$CLJS.zk,BM,$CLJS.vi,$CLJS.kk,$CLJS.pk],[new $CLJS.bh(null,new $CLJS.h(null,1,[$CLJS.vP,"null"],null),null),$CLJS.od(kQ,new $CLJS.h(null,1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),cO,"metabase/mbql/schema.cljc",51,$CLJS.Ds,1,615,615,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.Ds,$CLJS.VD,$CLJS.U($CLJS.Rj,mQ),
PQ,$CLJS.U($CLJS.Rj,rP),fN,$CLJS.U($CLJS.Rj,$CLJS.U($CLJS.Ni,rP))],null),$CLJS.Kc,"Schema for a valid - clause.",$CLJS.n(NS)?NS.H:null])):null));return $CLJS.n(a)?a:cO}(),NS],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=FO.h($CLJS.pd("undefined"!==typeof $CLJS.CL&&"undefined"!==typeof DL&&"undefined"!==typeof EL?new $CLJS.Gc(function(){return OS},$CLJS.od(Qja,new $CLJS.h(null,1,[$CLJS.ck,!0],null)),$CLJS.Jg([DO,$CLJS.Xi,$CLJS.T,$CLJS.qk,FO,$CLJS.Ui,$CLJS.oj,BM,$CLJS.vi,$CLJS.kk,$CLJS.pk],
[new $CLJS.bh(null,new $CLJS.h(null,1,[$CLJS.vP,"null"],null),null),$CLJS.od(kQ,new $CLJS.h(null,1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),$CLJS.Ez,"metabase/mbql/schema.cljc",$CLJS.aG,1,618,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.aG,$CLJS.VD,$CLJS.U($CLJS.Rj,mQ),PQ,$CLJS.U($CLJS.Rj,mQ),fN,$CLJS.U($CLJS.Rj,$CLJS.U($CLJS.Ni,mQ))],null),$CLJS.Kc,"Schema for a valid / clause.",$CLJS.n(OS)?OS.H:null])):null));return $CLJS.n(a)?
a:$CLJS.Ez}(),OS],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=FO.h($CLJS.pd("undefined"!==typeof $CLJS.CL&&"undefined"!==typeof DL&&"undefined"!==typeof EL&&"undefined"!==typeof PS?new $CLJS.Gc(function(){return PS},$CLJS.od(Sja,new $CLJS.h(null,1,[$CLJS.ck,!0],null)),$CLJS.Jg([DO,$CLJS.Xi,$CLJS.T,$CLJS.qk,$CLJS.wi,FO,$CLJS.Ui,$CLJS.oj,$CLJS.zk,BM,$CLJS.vi,$CLJS.kk,$CLJS.pk],[new $CLJS.bh(null,new $CLJS.h(null,1,[$CLJS.vP,"null"],null),null),$CLJS.od(kQ,new $CLJS.h(null,1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),aO,"metabase/mbql/schema.cljc",51,$CLJS.rr,1,620,620,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.rr,$CLJS.VD,$CLJS.U($CLJS.Rj,mQ),PQ,$CLJS.U($CLJS.Rj,mQ),fN,$CLJS.U($CLJS.Rj,$CLJS.U($CLJS.Ni,mQ))],null),$CLJS.Kc,"Schema for a valid * clause.",$CLJS.n(PS)?PS.H:null])):null));return $CLJS.n(a)?a:aO}(),PS],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=FO.h($CLJS.pd("undefined"!==typeof $CLJS.CL&&"undefined"!==typeof DL&&"undefined"!==typeof EL&&"undefined"!==typeof BS?new $CLJS.Gc(function(){return BS},
$CLJS.od($P,new $CLJS.h(null,1,[$CLJS.ck,!0],null)),$CLJS.Jg([DO,$CLJS.Xi,$CLJS.T,$CLJS.qk,$CLJS.wi,FO,$CLJS.Ui,$CLJS.oj,$CLJS.zk,BM,$CLJS.vi,$CLJS.kk,$CLJS.pk],[new $CLJS.bh(null,new $CLJS.h(null,1,[$CLJS.vP,"null"],null),null),$CLJS.od(kQ,new $CLJS.h(null,1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),oQ,"metabase/mbql/schema.cljc",58,$CLJS.IF,1,579,579,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.IF,YQ,$CLJS.U($CLJS.Rj,TL),XQ,$CLJS.U($CLJS.Rj,
TL),fN,$CLJS.U($CLJS.Rj,$CLJS.U($CLJS.Ni,TL))],null),$CLJS.Kc,"Schema for a valid coalesce clause.",$CLJS.n(BS)?BS.H:null])):null));return $CLJS.n(a)?a:oQ}(),BS],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=FO.h($CLJS.pd("undefined"!==typeof $CLJS.CL&&"undefined"!==typeof DL&&"undefined"!==typeof EL&&"undefined"!==typeof DS?new $CLJS.Gc(function(){return DS},$CLJS.od(ika,new $CLJS.h(null,1,[$CLJS.ck,!0],null)),$CLJS.Jg([DO,$CLJS.Xi,$CLJS.T,$CLJS.qk,$CLJS.wi,FO,$CLJS.Ui,$CLJS.oj,$CLJS.zk,BM,
$CLJS.vi,$CLJS.kk,$CLJS.pk],[new $CLJS.bh(null,new $CLJS.h(null,1,[$CLJS.vP,"null"],null),null),$CLJS.od(kQ,new $CLJS.h(null,1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),HP,"metabase/mbql/schema.cljc",56,$CLJS.px,1,585,585,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.px,$CLJS.Mx,$CLJS.U($CLJS.Rj,rQ)],null),$CLJS.Kc,"Schema for a valid length clause.",$CLJS.n(DS)?DS.H:null])):null));return $CLJS.n(a)?a:HP}(),DS],null),new $CLJS.P(null,
2,5,$CLJS.Q,[function(){var a=FO.h($CLJS.pd("undefined"!==typeof $CLJS.CL&&"undefined"!==typeof DL&&"undefined"!==typeof EL&&"undefined"!==typeof QS?new $CLJS.Gc(function(){return QS},$CLJS.od(Zia,new $CLJS.h(null,1,[$CLJS.ck,!0],null)),$CLJS.Jg([DO,$CLJS.Xi,$CLJS.T,$CLJS.qk,$CLJS.wi,FO,$CLJS.Ui,$CLJS.oj,$CLJS.zk,BM,$CLJS.vi,$CLJS.kk,$CLJS.pk],[new $CLJS.bh(null,new $CLJS.h(null,1,[$CLJS.vP,"null"],null),null),$CLJS.od(kQ,new $CLJS.h(null,1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),CO,"metabase/mbql/schema.cljc",55,$CLJS.DF,1,622,622,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.DF,$CLJS.VD,$CLJS.U($CLJS.Rj,mQ)],null),$CLJS.Kc,"Schema for a valid floor clause.",$CLJS.n(QS)?QS.H:null])):null));return $CLJS.n(a)?a:CO}(),QS],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=FO.h($CLJS.pd("undefined"!==typeof $CLJS.CL&&"undefined"!==typeof DL&&"undefined"!==typeof EL&&"undefined"!==typeof RS?new $CLJS.Gc(function(){return RS},$CLJS.od(Tia,new $CLJS.h(null,1,[$CLJS.ck,!0],null)),
$CLJS.Jg([DO,$CLJS.Xi,$CLJS.T,$CLJS.qk,$CLJS.wi,FO,$CLJS.Ui,$CLJS.oj,$CLJS.zk,BM,$CLJS.vi,$CLJS.kk,$CLJS.pk],[new $CLJS.bh(null,new $CLJS.h(null,1,[$CLJS.vP,"null"],null),null),$CLJS.od(kQ,new $CLJS.h(null,1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),qM,"metabase/mbql/schema.cljc",54,$CLJS.$E,1,625,625,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.$E,$CLJS.VD,$CLJS.U($CLJS.Rj,mQ)],null),$CLJS.Kc,"Schema for a valid ceil clause.",$CLJS.n(RS)?
RS.H:null])):null));return $CLJS.n(a)?a:qM}(),RS],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=FO.h($CLJS.pd("undefined"!==typeof $CLJS.CL&&"undefined"!==typeof DL&&"undefined"!==typeof EL&&"undefined"!==typeof SS?new $CLJS.Gc(function(){return SS},$CLJS.od(hja,new $CLJS.h(null,1,[$CLJS.ck,!0],null)),$CLJS.Jg([DO,$CLJS.Xi,$CLJS.T,$CLJS.qk,$CLJS.wi,FO,$CLJS.Ui,$CLJS.oj,$CLJS.zk,BM,$CLJS.vi,$CLJS.kk,$CLJS.pk],[new $CLJS.bh(null,new $CLJS.h(null,1,[$CLJS.vP,"null"],null),null),$CLJS.od(kQ,new $CLJS.h(null,
1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),sO,"metabase/mbql/schema.cljc",55,$CLJS.KF,1,628,628,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.KF,$CLJS.VD,$CLJS.U($CLJS.Rj,mQ)],null),$CLJS.Kc,"Schema for a valid round clause.",$CLJS.n(SS)?SS.H:null])):null));return $CLJS.n(a)?a:sO}(),SS],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=FO.h($CLJS.pd("undefined"!==typeof $CLJS.CL&&"undefined"!==typeof DL&&"undefined"!==typeof EL&&
"undefined"!==typeof TS?new $CLJS.Gc(function(){return TS},$CLJS.od(Qka,new $CLJS.h(null,1,[$CLJS.ck,!0],null)),$CLJS.Jg([DO,$CLJS.Xi,$CLJS.T,$CLJS.qk,$CLJS.wi,FO,$CLJS.Ui,$CLJS.oj,$CLJS.zk,BM,$CLJS.vi,$CLJS.kk,$CLJS.pk],[new $CLJS.bh(null,new $CLJS.h(null,1,[$CLJS.vP,"null"],null),null),$CLJS.od(kQ,new $CLJS.h(null,1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),VO,"metabase/mbql/schema.cljc",53,$CLJS.eF,1,631,631,new $CLJS.P(null,
3,5,$CLJS.Q,[$CLJS.eF,$CLJS.VD,$CLJS.U($CLJS.Rj,mQ)],null),$CLJS.Kc,"Schema for a valid abs clause.",$CLJS.n(TS)?TS.H:null])):null));return $CLJS.n(a)?a:VO}(),TS],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=FO.h($CLJS.pd("undefined"!==typeof $CLJS.CL&&"undefined"!==typeof DL&&"undefined"!==typeof EL&&"undefined"!==typeof US?new $CLJS.Gc(function(){return US},$CLJS.od(Dka,new $CLJS.h(null,1,[$CLJS.ck,!0],null)),$CLJS.Jg([DO,$CLJS.Xi,$CLJS.T,$CLJS.qk,$CLJS.wi,FO,$CLJS.Ui,$CLJS.oj,$CLJS.zk,
BM,$CLJS.vi,$CLJS.kk,$CLJS.pk],[new $CLJS.bh(null,new $CLJS.h(null,1,[lR,"null"],null),null),$CLJS.od(kQ,new $CLJS.h(null,1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),GQ,"metabase/mbql/schema.cljc",69,$CLJS.bF,1,634,634,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.bF,$CLJS.VD,$CLJS.U($CLJS.Rj,mQ),PQ,$CLJS.U($CLJS.Rj,mQ)],null),$CLJS.Kc,"Schema for a valid power clause.",$CLJS.n(US)?US.H:null])):null));return $CLJS.n(a)?a:GQ}(),US],
null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=FO.h($CLJS.pd("undefined"!==typeof $CLJS.CL&&"undefined"!==typeof DL&&"undefined"!==typeof EL&&"undefined"!==typeof VS?new $CLJS.Gc(function(){return VS},$CLJS.od(Aja,new $CLJS.h(null,1,[$CLJS.ck,!0],null)),$CLJS.Jg([DO,$CLJS.Xi,$CLJS.T,$CLJS.qk,$CLJS.wi,FO,$CLJS.Ui,$CLJS.oj,$CLJS.zk,BM,$CLJS.vi,$CLJS.kk,$CLJS.pk],[new $CLJS.bh(null,new $CLJS.h(null,1,[lR,"null"],null),null),$CLJS.od(kQ,new $CLJS.h(null,1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),XN,"metabase/mbql/schema.cljc",68,$CLJS.sF,1,637,637,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.sF,$CLJS.VD,$CLJS.U($CLJS.Rj,mQ)],null),$CLJS.Kc,"Schema for a valid sqrt clause.",$CLJS.n(VS)?VS.H:null])):null));return $CLJS.n(a)?a:XN}(),VS],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=FO.h($CLJS.pd("undefined"!==typeof $CLJS.CL&&"undefined"!==typeof DL&&"undefined"!==typeof EL&&"undefined"!==typeof WS?new $CLJS.Gc(function(){return WS},$CLJS.od(Eka,new $CLJS.h(null,1,[$CLJS.ck,!0],null)),
$CLJS.Jg([DO,$CLJS.Xi,$CLJS.T,$CLJS.qk,$CLJS.wi,FO,$CLJS.Ui,$CLJS.oj,$CLJS.zk,BM,$CLJS.vi,$CLJS.kk,$CLJS.pk],[new $CLJS.bh(null,new $CLJS.h(null,1,[lR,"null"],null),null),$CLJS.od(kQ,new $CLJS.h(null,1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),uO,"metabase/mbql/schema.cljc",67,$CLJS.WE,1,640,640,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.WE,$CLJS.VD,$CLJS.U($CLJS.Rj,mQ)],null),$CLJS.Kc,"Schema for a valid exp clause.",$CLJS.n(WS)?
WS.H:null])):null));return $CLJS.n(a)?a:uO}(),WS],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=FO.h($CLJS.pd("undefined"!==typeof $CLJS.CL&&"undefined"!==typeof DL&&"undefined"!==typeof EL&&"undefined"!==typeof XS?new $CLJS.Gc(function(){return XS},$CLJS.od(Mia,new $CLJS.h(null,1,[$CLJS.ck,!0],null)),$CLJS.Jg([DO,$CLJS.Xi,$CLJS.T,$CLJS.qk,$CLJS.wi,FO,$CLJS.Ui,$CLJS.oj,$CLJS.zk,BM,$CLJS.vi,$CLJS.kk,$CLJS.pk],[new $CLJS.bh(null,new $CLJS.h(null,1,[lR,"null"],null),null),$CLJS.od(kQ,new $CLJS.h(null,
1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),qR,"metabase/mbql/schema.cljc",67,$CLJS.cG,1,643,643,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.cG,$CLJS.VD,$CLJS.U($CLJS.Rj,mQ)],null),$CLJS.Kc,"Schema for a valid log clause.",$CLJS.n(XS)?XS.H:null])):null));return $CLJS.n(a)?a:qR}(),XS],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=FO.h($CLJS.pd("undefined"!==typeof $CLJS.CL&&"undefined"!==typeof DL&&"undefined"!==typeof EL&&
"undefined"!==typeof NT?new $CLJS.Gc(function(){return NT},$CLJS.od(dM,new $CLJS.h(null,1,[$CLJS.ck,!0],null)),$CLJS.Jg([DO,$CLJS.Xi,$CLJS.T,$CLJS.qk,$CLJS.wi,FO,$CLJS.Ui,$CLJS.oj,$CLJS.zk,BM,$CLJS.vi,$CLJS.kk,$CLJS.pk],[new $CLJS.bh(null,new $CLJS.h(null,1,[$CLJS.ZE,"null"],null),null),$CLJS.od(kQ,new $CLJS.h(null,1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),MO,"metabase/mbql/schema.cljc",61,$CLJS.nF,1,909,909,new $CLJS.P(null,
5,5,$CLJS.Q,[$CLJS.nF,$CLJS.Cw,$CLJS.U($CLJS.Rj,vN),$CLJS.gj,$CLJS.U($CLJS.Rj,$CLJS.U(DQ,eP))],null),$CLJS.Kc,"Schema for a valid case clause.",$CLJS.n(NT)?NT.H:null])):null));return $CLJS.n(a)?a:MO}(),NT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=FO.h($CLJS.pd("undefined"!==typeof $CLJS.CL&&"undefined"!==typeof DL&&"undefined"!==typeof EL&&"undefined"!==typeof YS?new $CLJS.Gc(function(){return YS},$CLJS.od(Pia,new $CLJS.h(null,1,[$CLJS.ck,!0],null)),$CLJS.Jg([DO,$CLJS.Xi,$CLJS.T,$CLJS.qk,
$CLJS.wi,FO,$CLJS.Ui,$CLJS.oj,$CLJS.zk,BM,$CLJS.vi,$CLJS.kk,$CLJS.pk],[new $CLJS.bh(null,new $CLJS.h(null,1,[$CLJS.sI,"null"],null),null),$CLJS.od(kQ,new $CLJS.h(null,1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),WO,"metabase/mbql/schema.cljc",65,$CLJS.sI,1,656,656,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.sI,bja,$CLJS.U($CLJS.Rj,BR),cja,$CLJS.U($CLJS.Rj,BR),$CLJS.ti,$CLJS.U($CLJS.Rj,Ija)],null),$CLJS.Kc,"Schema for a valid datetime-diff clause.",
$CLJS.n(YS)?YS.H:null])):null));return $CLJS.n(a)?a:WO}(),YS],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=FO.h($CLJS.pd("undefined"!==typeof $CLJS.CL&&"undefined"!==typeof DL&&"undefined"!==typeof EL&&"undefined"!==typeof ZS?new $CLJS.Gc(function(){return ZS},$CLJS.od(mka,new $CLJS.h(null,1,[$CLJS.ck,!0],null)),$CLJS.Jg([DO,$CLJS.Xi,$CLJS.T,$CLJS.qk,$CLJS.wi,FO,$CLJS.Ui,$CLJS.oj,$CLJS.zk,BM,$CLJS.vi,$CLJS.kk,$CLJS.pk],[new $CLJS.bh(null,new $CLJS.h(null,1,[$CLJS.HI,"null"],null),null),$CLJS.od(kQ,
new $CLJS.h(null,1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),oP,"metabase/mbql/schema.cljc",71,$CLJS.HI,1,661,661,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.HI,$CLJS.wI,$CLJS.U($CLJS.Rj,BR),$CLJS.ti,$CLJS.U($CLJS.Rj,Jka),$CLJS.zi,$CLJS.U($CLJS.Rj,$CLJS.U(DQ,JR))],null),$CLJS.Kc,"Schema for a valid temporal-extract clause.",$CLJS.n(ZS)?ZS.H:null])):null));return $CLJS.n(a)?a:oP}(),ZS],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=
FO.h($CLJS.pd("undefined"!==typeof $CLJS.CL&&"undefined"!==typeof DL&&"undefined"!==typeof EL&&"undefined"!==typeof $S?new $CLJS.Gc(function(){return $S},$CLJS.od(tja,new $CLJS.h(null,1,[$CLJS.ck,!0],null)),$CLJS.Jg([DO,$CLJS.Xi,$CLJS.T,$CLJS.qk,$CLJS.wi,FO,$CLJS.Ui,$CLJS.oj,$CLJS.zk,FQ,BM,$CLJS.vi,$CLJS.kk,$CLJS.pk],[new $CLJS.bh(null,new $CLJS.h(null,1,[$CLJS.HI,"null"],null),null),$CLJS.od(kQ,new $CLJS.h(null,1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),NM,"metabase/mbql/schema.cljc",71,$CLJS.oI,1,667,667,!0,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.oI,$CLJS.YO,$CLJS.U($CLJS.Rj,BR)],null),$CLJS.Kc,"Schema for a valid get-year clause.",$CLJS.n($S)?$S.H:null])):null));return $CLJS.n(a)?a:NM}(),$S],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=FO.h($CLJS.pd("undefined"!==typeof $CLJS.CL&&"undefined"!==typeof DL&&"undefined"!==typeof EL&&"undefined"!==typeof aT?new $CLJS.Gc(function(){return aT},$CLJS.od(ija,new $CLJS.h(null,1,[$CLJS.ck,!0],
null)),$CLJS.Jg([DO,$CLJS.Xi,$CLJS.T,$CLJS.qk,$CLJS.wi,FO,$CLJS.Ui,$CLJS.oj,$CLJS.zk,FQ,BM,$CLJS.vi,$CLJS.kk,$CLJS.pk],[new $CLJS.bh(null,new $CLJS.h(null,1,[$CLJS.HI,"null"],null),null),$CLJS.od(kQ,new $CLJS.h(null,1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),mP,"metabase/mbql/schema.cljc",74,$CLJS.LI,1,670,670,!0,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.LI,$CLJS.YO,$CLJS.U($CLJS.Rj,BR)],null),$CLJS.Kc,"Schema for a valid get-quarter clause.",
$CLJS.n(aT)?aT.H:null])):null));return $CLJS.n(a)?a:mP}(),aT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=FO.h($CLJS.pd("undefined"!==typeof $CLJS.CL&&"undefined"!==typeof DL&&"undefined"!==typeof EL&&"undefined"!==typeof bT?new $CLJS.Gc(function(){return bT},$CLJS.od(Pja,new $CLJS.h(null,1,[$CLJS.ck,!0],null)),$CLJS.Jg([DO,$CLJS.Xi,$CLJS.T,$CLJS.qk,$CLJS.wi,FO,$CLJS.Ui,$CLJS.oj,$CLJS.zk,FQ,BM,$CLJS.vi,$CLJS.kk,$CLJS.pk],[new $CLJS.bh(null,new $CLJS.h(null,1,[$CLJS.HI,"null"],null),null),
$CLJS.od(kQ,new $CLJS.h(null,1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),JP,"metabase/mbql/schema.cljc",72,$CLJS.hI,1,673,673,!0,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.hI,$CLJS.YO,$CLJS.U($CLJS.Rj,BR)],null),$CLJS.Kc,"Schema for a valid get-month clause.",$CLJS.n(bT)?bT.H:null])):null));return $CLJS.n(a)?a:JP}(),bT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=FO.h($CLJS.pd("undefined"!==typeof $CLJS.CL&&"undefined"!==
typeof DL&&"undefined"!==typeof EL&&"undefined"!==typeof cT?new $CLJS.Gc(function(){return cT},$CLJS.od(Ika,new $CLJS.h(null,1,[$CLJS.ck,!0],null)),$CLJS.Jg([DO,$CLJS.Xi,$CLJS.T,$CLJS.qk,$CLJS.wi,FO,$CLJS.Ui,$CLJS.oj,$CLJS.zk,FQ,BM,$CLJS.vi,$CLJS.kk,$CLJS.pk],[new $CLJS.bh(null,new $CLJS.h(null,1,[$CLJS.HI,"null"],null),null),$CLJS.od(kQ,new $CLJS.h(null,1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),IP,"metabase/mbql/schema.cljc",
71,$CLJS.BI,1,676,676,!0,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.BI,$CLJS.YO,$CLJS.U($CLJS.Rj,BR),$CLJS.zi,$CLJS.U($CLJS.Rj,$CLJS.U(DQ,JR))],null),$CLJS.Kc,"Schema for a valid get-week clause.",$CLJS.n(cT)?cT.H:null])):null));return $CLJS.n(a)?a:IP}(),cT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=FO.h($CLJS.pd("undefined"!==typeof $CLJS.CL&&"undefined"!==typeof DL&&"undefined"!==typeof EL&&"undefined"!==typeof dT?new $CLJS.Gc(function(){return dT},$CLJS.od(Dia,new $CLJS.h(null,1,[$CLJS.ck,
!0],null)),$CLJS.Jg([DO,$CLJS.Xi,$CLJS.T,$CLJS.qk,$CLJS.wi,FO,$CLJS.Ui,$CLJS.oj,$CLJS.zk,FQ,BM,$CLJS.vi,$CLJS.kk,$CLJS.pk],[new $CLJS.bh(null,new $CLJS.h(null,1,[$CLJS.HI,"null"],null),null),$CLJS.od(kQ,new $CLJS.h(null,1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),BP,"metabase/mbql/schema.cljc",70,$CLJS.AI,1,680,680,!0,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.AI,$CLJS.YO,$CLJS.U($CLJS.Rj,BR)],null),$CLJS.Kc,"Schema for a valid get-day clause.",
$CLJS.n(dT)?dT.H:null])):null));return $CLJS.n(a)?a:BP}(),dT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=FO.h($CLJS.pd("undefined"!==typeof $CLJS.CL&&"undefined"!==typeof DL&&"undefined"!==typeof EL&&"undefined"!==typeof eT?new $CLJS.Gc(function(){return eT},$CLJS.od(Jja,new $CLJS.h(null,1,[$CLJS.ck,!0],null)),$CLJS.Jg([DO,$CLJS.Xi,$CLJS.T,$CLJS.qk,$CLJS.wi,FO,$CLJS.Ui,$CLJS.oj,$CLJS.zk,FQ,BM,$CLJS.vi,$CLJS.kk,$CLJS.pk],[new $CLJS.bh(null,new $CLJS.h(null,1,[$CLJS.HI,"null"],null),null),
$CLJS.od(kQ,new $CLJS.h(null,1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),sP,"metabase/mbql/schema.cljc",78,$CLJS.FI,1,683,683,!0,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.FI,$CLJS.YO,$CLJS.U($CLJS.Rj,BR)],null),$CLJS.Kc,"Schema for a valid get-day-of-week clause.",$CLJS.n(eT)?eT.H:null])):null));return $CLJS.n(a)?a:sP}(),eT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=FO.h($CLJS.pd("undefined"!==typeof $CLJS.CL&&"undefined"!==
typeof DL&&"undefined"!==typeof EL&&"undefined"!==typeof fT?new $CLJS.Gc(function(){return fT},$CLJS.od(Hja,new $CLJS.h(null,1,[$CLJS.ck,!0],null)),$CLJS.Jg([DO,$CLJS.Xi,$CLJS.T,$CLJS.qk,$CLJS.wi,FO,$CLJS.Ui,$CLJS.oj,$CLJS.zk,FQ,BM,$CLJS.vi,$CLJS.kk,$CLJS.pk],[new $CLJS.bh(null,new $CLJS.h(null,1,[$CLJS.HI,"null"],null),null),$CLJS.od(kQ,new $CLJS.h(null,1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),UL,"metabase/mbql/schema.cljc",
71,$CLJS.II,1,686,686,!0,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.II,$CLJS.wI,$CLJS.U($CLJS.Rj,BR)],null),$CLJS.Kc,"Schema for a valid get-hour clause.",$CLJS.n(fT)?fT.H:null])):null));return $CLJS.n(a)?a:UL}(),fT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=FO.h($CLJS.pd("undefined"!==typeof $CLJS.CL&&"undefined"!==typeof DL&&"undefined"!==typeof EL&&"undefined"!==typeof gT?new $CLJS.Gc(function(){return gT},$CLJS.od(qka,new $CLJS.h(null,1,[$CLJS.ck,!0],null)),$CLJS.Jg([DO,$CLJS.Xi,$CLJS.T,$CLJS.qk,
$CLJS.wi,FO,$CLJS.Ui,$CLJS.oj,$CLJS.zk,FQ,BM,$CLJS.vi,$CLJS.kk,$CLJS.pk],[new $CLJS.bh(null,new $CLJS.h(null,1,[$CLJS.HI,"null"],null),null),$CLJS.od(kQ,new $CLJS.h(null,1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),uR,"metabase/mbql/schema.cljc",73,$CLJS.EI,1,689,689,!0,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.EI,$CLJS.wI,$CLJS.U($CLJS.Rj,BR)],null),$CLJS.Kc,"Schema for a valid get-minute clause.",$CLJS.n(gT)?gT.H:null])):null));
return $CLJS.n(a)?a:uR}(),gT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=FO.h($CLJS.pd("undefined"!==typeof $CLJS.CL&&"undefined"!==typeof DL&&"undefined"!==typeof EL&&"undefined"!==typeof hT?new $CLJS.Gc(function(){return hT},$CLJS.od(Eja,new $CLJS.h(null,1,[$CLJS.ck,!0],null)),$CLJS.Jg([DO,$CLJS.Xi,$CLJS.T,$CLJS.qk,$CLJS.wi,FO,$CLJS.Ui,$CLJS.oj,$CLJS.zk,FQ,BM,$CLJS.vi,$CLJS.kk,$CLJS.pk],[new $CLJS.bh(null,new $CLJS.h(null,1,[$CLJS.HI,"null"],null),null),$CLJS.od(kQ,new $CLJS.h(null,1,
[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),OO,"metabase/mbql/schema.cljc",73,$CLJS.nI,1,692,692,!0,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.nI,$CLJS.wI,$CLJS.U($CLJS.Rj,BR)],null),$CLJS.Kc,"Schema for a valid get-second clause.",$CLJS.n(hT)?hT.H:null])):null));return $CLJS.n(a)?a:OO}(),hT],null)])));
$CLJS.X(iM,AL($CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=FO.h($CLJS.pd("undefined"!==typeof $CLJS.CL&&"undefined"!==typeof DL&&"undefined"!==typeof EL&&"undefined"!==typeof CS?new $CLJS.Gc(function(){return CS},$CLJS.od(gka,new $CLJS.h(null,1,[$CLJS.ck,!0],null)),$CLJS.Jg([DO,$CLJS.Xi,$CLJS.T,$CLJS.qk,$CLJS.wi,FO,$CLJS.Ui,$CLJS.oj,$CLJS.zk,BM,$CLJS.vi,$CLJS.kk,$CLJS.pk],[new $CLJS.bh(null,new $CLJS.h(null,1,[$CLJS.vP,"null"],null),null),$CLJS.od(kQ,new $CLJS.h(null,1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),RP,"metabase/mbql/schema.cljc",59,$CLJS.XE,1,582,582,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.XE,$CLJS.Mx,$CLJS.U($CLJS.Rj,rQ),$CLJS.Zu,$CLJS.U($CLJS.Rj,eja),$CLJS.px,$CLJS.U($CLJS.Rj,$CLJS.U(DQ,mQ))],null),$CLJS.Kc,"Schema for a valid substring clause.",$CLJS.n(CS)?CS.H:null])):null));return $CLJS.n(a)?a:RP}(),CS],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=FO.h($CLJS.pd("undefined"!==typeof $CLJS.CL&&"undefined"!==typeof DL&&"undefined"!==typeof EL&&"undefined"!==typeof ES?new $CLJS.Gc(function(){return ES},
$CLJS.od(Gka,new $CLJS.h(null,1,[$CLJS.ck,!0],null)),$CLJS.Jg([DO,$CLJS.Xi,$CLJS.T,$CLJS.qk,$CLJS.wi,FO,$CLJS.Ui,$CLJS.oj,$CLJS.zk,BM,$CLJS.vi,$CLJS.kk,$CLJS.pk],[new $CLJS.bh(null,new $CLJS.h(null,1,[$CLJS.vP,"null"],null),null),$CLJS.od(kQ,new $CLJS.h(null,1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),nQ,"metabase/mbql/schema.cljc",54,$CLJS.WF,1,588,588,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.WF,$CLJS.Mx,$CLJS.U($CLJS.Rj,rQ)],
null),$CLJS.Kc,"Schema for a valid trim clause.",$CLJS.n(ES)?ES.H:null])):null));return $CLJS.n(a)?a:nQ}(),ES],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=FO.h($CLJS.pd("undefined"!==typeof $CLJS.CL&&"undefined"!==typeof DL&&"undefined"!==typeof EL&&"undefined"!==typeof GS?new $CLJS.Gc(function(){return GS},$CLJS.od(Kja,new $CLJS.h(null,1,[$CLJS.ck,!0],null)),$CLJS.Jg([DO,$CLJS.Xi,$CLJS.T,$CLJS.qk,$CLJS.wi,FO,$CLJS.Ui,$CLJS.oj,$CLJS.zk,BM,$CLJS.vi,$CLJS.kk,$CLJS.pk],[new $CLJS.bh(null,new $CLJS.h(null,
1,[$CLJS.vP,"null"],null),null),$CLJS.od(kQ,new $CLJS.h(null,1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),XP,"metabase/mbql/schema.cljc",55,$CLJS.YF,1,594,594,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.YF,$CLJS.Mx,$CLJS.U($CLJS.Rj,rQ)],null),$CLJS.Kc,"Schema for a valid ltrim clause.",$CLJS.n(GS)?GS.H:null])):null));return $CLJS.n(a)?a:XP}(),GS],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=FO.h($CLJS.pd("undefined"!==typeof $CLJS.CL&&
"undefined"!==typeof DL&&"undefined"!==typeof EL&&"undefined"!==typeof FS?new $CLJS.Gc(function(){return FS},$CLJS.od(hka,new $CLJS.h(null,1,[$CLJS.ck,!0],null)),$CLJS.Jg([DO,$CLJS.Xi,$CLJS.T,$CLJS.qk,$CLJS.wi,FO,$CLJS.Ui,$CLJS.oj,$CLJS.zk,BM,$CLJS.vi,$CLJS.kk,$CLJS.pk],[new $CLJS.bh(null,new $CLJS.h(null,1,[$CLJS.vP,"null"],null),null),$CLJS.od(kQ,new $CLJS.h(null,1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),$O,"metabase/mbql/schema.cljc",
55,$CLJS.eG,1,591,591,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.eG,$CLJS.Mx,$CLJS.U($CLJS.Rj,rQ)],null),$CLJS.Kc,"Schema for a valid rtrim clause.",$CLJS.n(FS)?FS.H:null])):null));return $CLJS.n(a)?a:$O}(),FS],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=FO.h($CLJS.pd("undefined"!==typeof $CLJS.CL&&"undefined"!==typeof DL&&"undefined"!==typeof EL&&"undefined"!==typeof JS?new $CLJS.Gc(function(){return JS},$CLJS.od(yia,new $CLJS.h(null,1,[$CLJS.ck,!0],null)),$CLJS.Jg([DO,$CLJS.Xi,$CLJS.T,$CLJS.qk,
$CLJS.wi,FO,$CLJS.Ui,$CLJS.oj,$CLJS.zk,BM,$CLJS.vi,$CLJS.kk,$CLJS.pk],[new $CLJS.bh(null,new $CLJS.h(null,1,[$CLJS.vP,"null"],null),null),$CLJS.od(kQ,new $CLJS.h(null,1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),$Q,"metabase/mbql/schema.cljc",57,$CLJS.dG,1,603,603,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.dG,$CLJS.Mx,$CLJS.U($CLJS.Rj,rQ),Kia,$CLJS.U($CLJS.Rj,$CLJS.ak),$CLJS.Lz,$CLJS.U($CLJS.Rj,$CLJS.ak)],null),$CLJS.Kc,"Schema for a valid replace clause.",
$CLJS.n(JS)?JS.H:null])):null));return $CLJS.n(a)?a:$Q}(),JS],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=FO.h($CLJS.pd("undefined"!==typeof $CLJS.CL&&"undefined"!==typeof DL&&"undefined"!==typeof EL&&"undefined"!==typeof IS?new $CLJS.Gc(function(){return IS},$CLJS.od(kka,new $CLJS.h(null,1,[$CLJS.ck,!0],null)),$CLJS.Jg([DO,$CLJS.Xi,$CLJS.T,$CLJS.qk,$CLJS.wi,FO,$CLJS.Ui,$CLJS.oj,$CLJS.zk,BM,$CLJS.vi,$CLJS.kk,$CLJS.pk],[new $CLJS.bh(null,new $CLJS.h(null,1,[$CLJS.vP,"null"],null),null),$CLJS.od(kQ,
new $CLJS.h(null,1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),ER,"metabase/mbql/schema.cljc",55,$CLJS.Us,1,600,600,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Us,$CLJS.Mx,$CLJS.U($CLJS.Rj,rQ)],null),$CLJS.Kc,"Schema for a valid lower clause.",$CLJS.n(IS)?IS.H:null])):null));return $CLJS.n(a)?a:ER}(),IS],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=FO.h($CLJS.pd("undefined"!==typeof $CLJS.CL&&"undefined"!==typeof DL&&"undefined"!==
typeof EL&&"undefined"!==typeof HS?new $CLJS.Gc(function(){return HS},$CLJS.od(Hka,new $CLJS.h(null,1,[$CLJS.ck,!0],null)),$CLJS.Jg([DO,$CLJS.Xi,$CLJS.T,$CLJS.qk,$CLJS.wi,FO,$CLJS.Ui,$CLJS.oj,$CLJS.zk,BM,$CLJS.vi,$CLJS.kk,$CLJS.pk],[new $CLJS.bh(null,new $CLJS.h(null,1,[$CLJS.vP,"null"],null),null),$CLJS.od(kQ,new $CLJS.h(null,1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),AQ,"metabase/mbql/schema.cljc",55,$CLJS.Os,1,597,597,
new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Os,$CLJS.Mx,$CLJS.U($CLJS.Rj,rQ)],null),$CLJS.Kc,"Schema for a valid upper clause.",$CLJS.n(HS)?HS.H:null])):null));return $CLJS.n(a)?a:AQ}(),HS],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=FO.h($CLJS.pd("undefined"!==typeof $CLJS.CL&&"undefined"!==typeof DL&&"undefined"!==typeof EL&&"undefined"!==typeof KS?new $CLJS.Gc(function(){return KS},$CLJS.od(Zja,new $CLJS.h(null,1,[$CLJS.ck,!0],null)),$CLJS.Jg([DO,$CLJS.Xi,$CLJS.T,$CLJS.qk,$CLJS.wi,FO,$CLJS.Ui,
$CLJS.oj,$CLJS.zk,BM,$CLJS.vi,$CLJS.kk,$CLJS.pk],[new $CLJS.bh(null,new $CLJS.h(null,1,[$CLJS.vP,"null"],null),null),$CLJS.od(kQ,new $CLJS.h(null,1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),ON,"metabase/mbql/schema.cljc",56,$CLJS.dF,1,606,606,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.dF,YQ,$CLJS.U($CLJS.Rj,rQ),XQ,$CLJS.U($CLJS.Rj,rQ),fN,$CLJS.U($CLJS.Rj,$CLJS.U($CLJS.Ni,rQ))],null),$CLJS.Kc,"Schema for a valid concat clause.",
$CLJS.n(KS)?KS.H:null])):null));return $CLJS.n(a)?a:ON}(),KS],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=FO.h($CLJS.pd("undefined"!==typeof $CLJS.CL&&"undefined"!==typeof DL&&"undefined"!==typeof EL&&"undefined"!==typeof LS?new $CLJS.Gc(function(){return LS},$CLJS.od(fka,new $CLJS.h(null,1,[$CLJS.ck,!0],null)),$CLJS.Jg([DO,$CLJS.Xi,$CLJS.T,$CLJS.qk,$CLJS.wi,FO,$CLJS.Ui,$CLJS.oj,$CLJS.zk,BM,$CLJS.vi,$CLJS.kk,$CLJS.pk],[new $CLJS.bh(null,new $CLJS.h(null,2,[$CLJS.vP,"null",$CLJS.Kea,"null"],
null),null),$CLJS.od(kQ,new $CLJS.h(null,1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),LM,"metabase/mbql/schema.cljc",74,$CLJS.HF,1,609,609,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.HF,$CLJS.Mx,$CLJS.U($CLJS.Rj,rQ),$CLJS.Kz,$CLJS.U($CLJS.Rj,$CLJS.ak)],null),$CLJS.Kc,"Schema for a valid regex-match-first clause.",$CLJS.n(LS)?LS.H:null])):null));return $CLJS.n(a)?a:LM}(),LS],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=FO.h($CLJS.pd("undefined"!==
typeof $CLJS.CL&&"undefined"!==typeof DL&&"undefined"!==typeof EL&&"undefined"!==typeof BS?new $CLJS.Gc(function(){return BS},$CLJS.od($P,new $CLJS.h(null,1,[$CLJS.ck,!0],null)),$CLJS.Jg([DO,$CLJS.Xi,$CLJS.T,$CLJS.qk,$CLJS.wi,FO,$CLJS.Ui,$CLJS.oj,$CLJS.zk,BM,$CLJS.vi,$CLJS.kk,$CLJS.pk],[new $CLJS.bh(null,new $CLJS.h(null,1,[$CLJS.vP,"null"],null),null),$CLJS.od(kQ,new $CLJS.h(null,1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),
oQ,"metabase/mbql/schema.cljc",58,$CLJS.IF,1,579,579,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.IF,YQ,$CLJS.U($CLJS.Rj,TL),XQ,$CLJS.U($CLJS.Rj,TL),fN,$CLJS.U($CLJS.Rj,$CLJS.U($CLJS.Ni,TL))],null),$CLJS.Kc,"Schema for a valid coalesce clause.",$CLJS.n(BS)?BS.H:null])):null));return $CLJS.n(a)?a:oQ}(),BS],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=FO.h($CLJS.pd("undefined"!==typeof $CLJS.CL&&"undefined"!==typeof DL&&"undefined"!==typeof EL&&"undefined"!==typeof NT?new $CLJS.Gc(function(){return NT},
$CLJS.od(dM,new $CLJS.h(null,1,[$CLJS.ck,!0],null)),$CLJS.Jg([DO,$CLJS.Xi,$CLJS.T,$CLJS.qk,$CLJS.wi,FO,$CLJS.Ui,$CLJS.oj,$CLJS.zk,BM,$CLJS.vi,$CLJS.kk,$CLJS.pk],[new $CLJS.bh(null,new $CLJS.h(null,1,[$CLJS.ZE,"null"],null),null),$CLJS.od(kQ,new $CLJS.h(null,1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),MO,"metabase/mbql/schema.cljc",61,$CLJS.nF,1,909,909,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.nF,$CLJS.Cw,$CLJS.U($CLJS.Rj,vN),
$CLJS.gj,$CLJS.U($CLJS.Rj,$CLJS.U(DQ,eP))],null),$CLJS.Kc,"Schema for a valid case clause.",$CLJS.n(NT)?NT.H:null])):null));return $CLJS.n(a)?a:MO}(),NT],null)])));
$CLJS.OT=new $CLJS.P(null,8,5,$CLJS.Q,[$CLJS.Sr,new $CLJS.h(null,2,[$CLJS.Ys,":field or :expression reference or expression",$CLJS.Pi,function(a){return $CLJS.n(yL(pS,a))?CR:$CLJS.n(yL(mS,a))?$CLJS.ak:$CLJS.n(yL(qS,a))?$CLJS.bs:$CLJS.n(yL(sS,a))?$CLJS.wI:$CLJS.n(yL($CLJS.nF,a))?$CLJS.nF:$CLJS.Qs}],null),new $CLJS.P(null,2,5,$CLJS.Q,[CR,tS],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ak,nS],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.bs,uS],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.wI,$CLJS.vS],null),
new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.nF,NT],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Qs,$CLJS.jS],null)],null);$CLJS.X(iR,zL($CLJS.rx,$CLJS.H(["field",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.fs,$CLJS.jS],null)])));var PT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,iR],null);$CLJS.X(XO,zL($CLJS.GF,$CLJS.H(["field",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.fs,$CLJS.jS],null)])));var QT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,XO],null);$CLJS.X(gM,zL($CLJS.zF,$CLJS.H(["field-or-expression",$CLJS.OT])));
var RT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,gM],null);$CLJS.X(aR,zL($CLJS.bG,$CLJS.H(["field-or-expression",$CLJS.OT])));var ST=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,aR],null);$CLJS.X(GN,zL($CLJS.jF,$CLJS.H(["field-or-expression",$CLJS.OT])));var TT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,GN],null);$CLJS.X(pQ,zL($CLJS.SF,$CLJS.H(["field-or-expression",$CLJS.OT])));var UT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,pQ],null);$CLJS.X(BN,zL($CLJS.Rn,$CLJS.H(["field-or-expression",$CLJS.OT])));
var VT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,BN],null);$CLJS.X(nN,zL($CLJS.jk,$CLJS.H(["field-or-expression",$CLJS.OT])));var WT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,nN],null);$CLJS.X(hN,zL($CLJS.$F,$CLJS.H(["field-or-expression",$CLJS.OT,"pred",$CLJS.nT])));var XT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,hN],null);$CLJS.X(yN,zL($CLJS.OF,$CLJS.H(["pred",$CLJS.nT])));var YT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,yN],null);$CLJS.X(gQ,zL($CLJS.MF,$CLJS.H(["pred",$CLJS.nT])));
var ZT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,gQ],null);$CLJS.X(EM,zL($CLJS.VF,$CLJS.H(["field-or-expression",$CLJS.OT])));var $T=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,EM],null);$CLJS.X(GL,zL($CLJS.kF,$CLJS.H(["field-or-expression",$CLJS.OT])));var aU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,GL],null);$CLJS.X(KN,zL($CLJS.TF,$CLJS.H(["field-or-expression",$CLJS.OT])));var bU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,KN],null);$CLJS.X(xO,zL($CLJS.qF,$CLJS.H(["field-or-expression",$CLJS.OT,"percentile",xS])));
var cU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,xO],null);$CLJS.X(jQ,zL($CLJS.AF,$CLJS.H(["metric-id",new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Mr,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,$CLJS.hK],null),KR],null)])));var dU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,jQ],null);
$CLJS.X(fQ,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.Sr,new $CLJS.h(null,2,[$CLJS.Ys,"unnamed aggregation clause or numeric expression",$CLJS.Pi,function(a){return $CLJS.n(yL(pS,a))?AO:$CLJS.Qs}],null),new $CLJS.P(null,2,5,$CLJS.Q,[AO,tS],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Qs,AL($CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=FO.h($CLJS.pd("undefined"!==typeof $CLJS.CL&&"undefined"!==typeof DL&&"undefined"!==typeof EL&&"undefined"!==typeof RT?new $CLJS.Gc(function(){return RT},$CLJS.od(eka,
new $CLJS.h(null,1,[$CLJS.ck,!0],null)),$CLJS.Jg([DO,$CLJS.Xi,$CLJS.T,$CLJS.qk,$CLJS.wi,FO,$CLJS.Ui,$CLJS.oj,$CLJS.zk,BM,$CLJS.vi,$CLJS.kk,$CLJS.pk],[new $CLJS.bh(null,new $CLJS.h(null,1,[$CLJS.ZE,"null"],null),null),$CLJS.od(kQ,new $CLJS.h(null,1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),JO,"metabase/mbql/schema.cljc",60,$CLJS.zF,1,957,957,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.zF,BQ,$CLJS.U($CLJS.Rj,tQ)],null),$CLJS.Kc,"Schema for a valid avg clause.",
$CLJS.n(RT)?RT.H:null])):null));return $CLJS.n(a)?a:JO}(),RT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=FO.h($CLJS.pd("undefined"!==typeof $CLJS.CL&&"undefined"!==typeof DL&&"undefined"!==typeof EL&&"undefined"!==typeof ST?new $CLJS.Gc(function(){return ST},$CLJS.od(pja,new $CLJS.h(null,1,[$CLJS.ck,!0],null)),$CLJS.Jg([DO,$CLJS.Xi,$CLJS.T,$CLJS.qk,$CLJS.wi,FO,$CLJS.Ui,$CLJS.oj,$CLJS.zk,BM,$CLJS.vi,$CLJS.kk,$CLJS.pk],[new $CLJS.bh(null,new $CLJS.h(null,1,[$CLJS.ZE,"null"],null),null),$CLJS.od(kQ,
new $CLJS.h(null,1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),OQ,"metabase/mbql/schema.cljc",64,$CLJS.bG,1,958,958,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.bG,BQ,$CLJS.U($CLJS.Rj,tQ)],null),$CLJS.Kc,"Schema for a valid cum-sum clause.",$CLJS.n(ST)?ST.H:null])):null));return $CLJS.n(a)?a:OQ}(),ST],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=FO.h($CLJS.pd("undefined"!==typeof $CLJS.CL&&"undefined"!==typeof DL&&"undefined"!==
typeof EL&&"undefined"!==typeof TT?new $CLJS.Gc(function(){return TT},$CLJS.od(gja,new $CLJS.h(null,1,[$CLJS.ck,!0],null)),$CLJS.Jg([DO,$CLJS.Xi,$CLJS.T,$CLJS.qk,$CLJS.wi,FO,$CLJS.Ui,$CLJS.oj,$CLJS.zk,BM,$CLJS.vi,$CLJS.kk,$CLJS.pk],[new $CLJS.bh(null,new $CLJS.h(null,1,[$CLJS.ZE,"null"],null),null),$CLJS.od(kQ,new $CLJS.h(null,1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),NO,"metabase/mbql/schema.cljc",65,$CLJS.jF,1,959,959,
new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.jF,BQ,$CLJS.U($CLJS.Rj,tQ)],null),$CLJS.Kc,"Schema for a valid distinct clause.",$CLJS.n(TT)?TT.H:null])):null));return $CLJS.n(a)?a:NO}(),TT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=FO.h($CLJS.pd("undefined"!==typeof $CLJS.CL&&"undefined"!==typeof DL&&"undefined"!==typeof EL&&"undefined"!==typeof $T?new $CLJS.Gc(function(){return $T},$CLJS.od(Aka,new $CLJS.h(null,1,[$CLJS.ck,!0],null)),$CLJS.Jg([DO,$CLJS.Xi,$CLJS.T,$CLJS.qk,$CLJS.wi,FO,$CLJS.Ui,$CLJS.oj,
$CLJS.zk,BM,$CLJS.vi,$CLJS.kk,$CLJS.pk],[new $CLJS.bh(null,new $CLJS.h(null,1,[$CLJS.PF,"null"],null),null),$CLJS.od(kQ,new $CLJS.h(null,1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),aM,"metabase/mbql/schema.cljc",76,$CLJS.VF,1,973,973,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.VF,BQ,$CLJS.U($CLJS.Rj,tQ)],null),$CLJS.Kc,"Schema for a valid stddev clause.",$CLJS.n($T)?$T.H:null])):null));return $CLJS.n(a)?a:aM}(),$T],null),new $CLJS.P(null,
2,5,$CLJS.Q,[function(){var a=FO.h($CLJS.pd("undefined"!==typeof $CLJS.CL&&"undefined"!==typeof DL&&"undefined"!==typeof EL&&"undefined"!==typeof UT?new $CLJS.Gc(function(){return UT},$CLJS.od(Uja,new $CLJS.h(null,1,[$CLJS.ck,!0],null)),$CLJS.Jg([DO,$CLJS.Xi,$CLJS.T,$CLJS.qk,$CLJS.wi,FO,$CLJS.Ui,$CLJS.oj,$CLJS.zk,BM,$CLJS.vi,$CLJS.kk,$CLJS.pk],[new $CLJS.bh(null,new $CLJS.h(null,1,[$CLJS.ZE,"null"],null),null),$CLJS.od(kQ,new $CLJS.h(null,1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),wM,"metabase/mbql/schema.cljc",60,$CLJS.SF,1,960,960,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.SF,BQ,$CLJS.U($CLJS.Rj,tQ)],null),$CLJS.Kc,"Schema for a valid sum clause.",$CLJS.n(UT)?UT.H:null])):null));return $CLJS.n(a)?a:wM}(),UT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=FO.h($CLJS.pd("undefined"!==typeof $CLJS.CL&&"undefined"!==typeof DL&&"undefined"!==typeof EL&&"undefined"!==typeof VT?new $CLJS.Gc(function(){return VT},$CLJS.od(Hia,new $CLJS.h(null,1,[$CLJS.ck,!0],null)),$CLJS.Jg([DO,
$CLJS.Xi,$CLJS.T,$CLJS.qk,$CLJS.wi,FO,$CLJS.Ui,$CLJS.oj,$CLJS.zk,BM,$CLJS.vi,$CLJS.kk,$CLJS.pk],[new $CLJS.bh(null,new $CLJS.h(null,1,[$CLJS.ZE,"null"],null),null),$CLJS.od(kQ,new $CLJS.h(null,1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),$CLJS.qs,"metabase/mbql/schema.cljc",60,$CLJS.Rn,1,961,961,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Rn,BQ,$CLJS.U($CLJS.Rj,tQ)],null),$CLJS.Kc,"Schema for a valid min clause.",$CLJS.n(VT)?VT.H:
null])):null));return $CLJS.n(a)?a:$CLJS.qs}(),VT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=FO.h($CLJS.pd("undefined"!==typeof $CLJS.CL&&"undefined"!==typeof DL&&"undefined"!==typeof EL&&"undefined"!==typeof WT?new $CLJS.Gc(function(){return WT},$CLJS.od(Cia,new $CLJS.h(null,1,[$CLJS.ck,!0],null)),$CLJS.Jg([DO,$CLJS.Xi,$CLJS.T,$CLJS.qk,$CLJS.wi,FO,$CLJS.Ui,$CLJS.oj,$CLJS.zk,BM,$CLJS.vi,$CLJS.kk,$CLJS.pk],[new $CLJS.bh(null,new $CLJS.h(null,1,[$CLJS.ZE,"null"],null),null),$CLJS.od(kQ,new $CLJS.h(null,
1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),$CLJS.us,"metabase/mbql/schema.cljc",60,$CLJS.jk,1,962,962,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.jk,BQ,$CLJS.U($CLJS.Rj,tQ)],null),$CLJS.Kc,"Schema for a valid max clause.",$CLJS.n(WT)?WT.H:null])):null));return $CLJS.n(a)?a:$CLJS.us}(),WT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=FO.h($CLJS.pd("undefined"!==typeof $CLJS.CL&&"undefined"!==typeof DL&&"undefined"!==typeof EL&&
"undefined"!==typeof dU?new $CLJS.Gc(function(){return dU},$CLJS.od(dja,new $CLJS.h(null,1,[$CLJS.ck,!0],null)),$CLJS.Jg([$CLJS.Xi,$CLJS.T,$CLJS.qk,$CLJS.wi,FO,$CLJS.Ui,$CLJS.oj,$CLJS.zk,BM,$CLJS.vi,$CLJS.kk,$CLJS.pk],[$CLJS.od(kQ,new $CLJS.h(null,1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),NN,"metabase/mbql/schema.cljc",18,$CLJS.AF,1,994,994,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.AF,qja,$CLJS.U($CLJS.Rj,new $CLJS.P(null,3,
5,$CLJS.Q,[$CLJS.Mr,uja,rM],null))],null),$CLJS.Kc,"Schema for a valid metric clause.",$CLJS.n(dU)?dU.H:null])):null));return $CLJS.n(a)?a:NN}(),dU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=FO.h($CLJS.pd("undefined"!==typeof $CLJS.CL&&"undefined"!==typeof DL&&"undefined"!==typeof EL&&"undefined"!==typeof ZT?new $CLJS.Gc(function(){return ZT},$CLJS.od(Cja,new $CLJS.h(null,1,[$CLJS.ck,!0],null)),$CLJS.Jg([DO,$CLJS.Xi,$CLJS.T,$CLJS.qk,$CLJS.wi,FO,$CLJS.Ui,$CLJS.oj,$CLJS.zk,BM,$CLJS.vi,$CLJS.kk,
$CLJS.pk],[new $CLJS.bh(null,new $CLJS.h(null,1,[$CLJS.ZE,"null"],null),null),$CLJS.od(kQ,new $CLJS.h(null,1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),FN,"metabase/mbql/schema.cljc",62,$CLJS.MF,1,970,970,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.MF,$CLJS.fo,$CLJS.U($CLJS.Rj,vO)],null),$CLJS.Kc,"Schema for a valid share clause.",$CLJS.n(ZT)?ZT.H:null])):null));return $CLJS.n(a)?a:FN}(),ZT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=
FO.h($CLJS.pd("undefined"!==typeof $CLJS.CL&&"undefined"!==typeof DL&&"undefined"!==typeof EL&&"undefined"!==typeof YT?new $CLJS.Gc(function(){return YT},$CLJS.od(Iia,new $CLJS.h(null,1,[$CLJS.ck,!0],null)),$CLJS.Jg([DO,$CLJS.Xi,$CLJS.T,$CLJS.qk,$CLJS.wi,FO,$CLJS.Ui,$CLJS.oj,$CLJS.zk,BM,$CLJS.vi,$CLJS.kk,$CLJS.pk],[new $CLJS.bh(null,new $CLJS.h(null,1,[$CLJS.ZE,"null"],null),null),$CLJS.od(kQ,new $CLJS.h(null,1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),sM,"metabase/mbql/schema.cljc",68,$CLJS.OF,1,967,967,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.OF,$CLJS.fo,$CLJS.U($CLJS.Rj,vO)],null),$CLJS.Kc,"Schema for a valid count-where clause.",$CLJS.n(YT)?YT.H:null])):null));return $CLJS.n(a)?a:sM}(),YT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=FO.h($CLJS.pd("undefined"!==typeof $CLJS.CL&&"undefined"!==typeof DL&&"undefined"!==typeof EL&&"undefined"!==typeof XT?new $CLJS.Gc(function(){return XT},$CLJS.od(Oka,new $CLJS.h(null,1,[$CLJS.ck,!0],
null)),$CLJS.Jg([DO,$CLJS.Xi,$CLJS.T,$CLJS.qk,$CLJS.wi,FO,$CLJS.Ui,$CLJS.oj,$CLJS.zk,BM,$CLJS.vi,$CLJS.kk,$CLJS.pk],[new $CLJS.bh(null,new $CLJS.h(null,1,[$CLJS.ZE,"null"],null),null),$CLJS.od(kQ,new $CLJS.h(null,1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),KO,"metabase/mbql/schema.cljc",66,$CLJS.$F,1,964,964,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.$F,BQ,$CLJS.U($CLJS.Rj,tQ),$CLJS.fo,$CLJS.U($CLJS.Rj,vO)],null),$CLJS.Kc,"Schema for a valid sum-where clause.",
$CLJS.n(XT)?XT.H:null])):null));return $CLJS.n(a)?a:KO}(),XT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=FO.h($CLJS.pd("undefined"!==typeof $CLJS.CL&&"undefined"!==typeof DL&&"undefined"!==typeof EL&&"undefined"!==typeof NT?new $CLJS.Gc(function(){return NT},$CLJS.od(dM,new $CLJS.h(null,1,[$CLJS.ck,!0],null)),$CLJS.Jg([DO,$CLJS.Xi,$CLJS.T,$CLJS.qk,$CLJS.wi,FO,$CLJS.Ui,$CLJS.oj,$CLJS.zk,BM,$CLJS.vi,$CLJS.kk,$CLJS.pk],[new $CLJS.bh(null,new $CLJS.h(null,1,[$CLJS.ZE,"null"],null),null),$CLJS.od(kQ,
new $CLJS.h(null,1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),MO,"metabase/mbql/schema.cljc",61,$CLJS.nF,1,909,909,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.nF,$CLJS.Cw,$CLJS.U($CLJS.Rj,vN),$CLJS.gj,$CLJS.U($CLJS.Rj,$CLJS.U(DQ,eP))],null),$CLJS.Kc,"Schema for a valid case clause.",$CLJS.n(NT)?NT.H:null])):null));return $CLJS.n(a)?a:MO}(),NT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=FO.h($CLJS.pd("undefined"!==typeof $CLJS.CL&&
"undefined"!==typeof DL&&"undefined"!==typeof EL&&"undefined"!==typeof bU?new $CLJS.Gc(function(){return bU},$CLJS.od(tka,new $CLJS.h(null,1,[$CLJS.ck,!0],null)),$CLJS.Jg([DO,$CLJS.Xi,$CLJS.T,$CLJS.qk,$CLJS.wi,FO,$CLJS.Ui,$CLJS.oj,$CLJS.zk,BM,$CLJS.vi,$CLJS.kk,$CLJS.pk],[new $CLJS.bh(null,new $CLJS.h(null,1,[$CLJS.BF,"null"],null),null),$CLJS.od(kQ,new $CLJS.h(null,1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),MQ,"metabase/mbql/schema.cljc",
68,$CLJS.TF,1,979,979,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.TF,BQ,$CLJS.U($CLJS.Rj,tQ)],null),$CLJS.Kc,"Schema for a valid median clause.",$CLJS.n(bU)?bU.H:null])):null));return $CLJS.n(a)?a:MQ}(),bU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=FO.h($CLJS.pd("undefined"!==typeof $CLJS.CL&&"undefined"!==typeof DL&&"undefined"!==typeof EL&&"undefined"!==typeof cU?new $CLJS.Gc(function(){return cU},$CLJS.od(Oja,new $CLJS.h(null,1,[$CLJS.ck,!0],null)),$CLJS.Jg([DO,$CLJS.Xi,$CLJS.T,$CLJS.qk,$CLJS.wi,
FO,$CLJS.Ui,$CLJS.oj,$CLJS.zk,BM,$CLJS.vi,$CLJS.kk,$CLJS.pk],[new $CLJS.bh(null,new $CLJS.h(null,1,[$CLJS.BF,"null"],null),null),$CLJS.od(kQ,new $CLJS.h(null,1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),iQ,"metabase/mbql/schema.cljc",72,$CLJS.qF,1,982,982,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.qF,BQ,$CLJS.U($CLJS.Rj,tQ),$CLJS.qF,$CLJS.U($CLJS.Rj,mQ)],null),$CLJS.Kc,"Schema for a valid percentile clause.",$CLJS.n(cU)?cU.H:null])):
null));return $CLJS.n(a)?a:iQ}(),cU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=FO.h($CLJS.pd("undefined"!==typeof $CLJS.CL&&"undefined"!==typeof DL&&"undefined"!==typeof EL&&"undefined"!==typeof aU?new $CLJS.Gc(function(){return aU},$CLJS.od(Sia,new $CLJS.h(null,1,[$CLJS.ck,!0],null)),$CLJS.Jg([$CLJS.Xi,$CLJS.T,$CLJS.qk,$CLJS.wi,FO,$CLJS.Ui,$CLJS.oj,$CLJS.zk,BM,$CLJS.vi,$CLJS.kk,$CLJS.pk],[$CLJS.od(kQ,new $CLJS.h(null,1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),WL,"metabase/mbql/schema.cljc",77,$CLJS.kF,1,976,976,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.kF,BQ,$CLJS.U($CLJS.Rj,tQ)],null),$CLJS.Kc,"Schema for a valid var clause.",$CLJS.n(aU)?aU.H:null])):null));return $CLJS.n(a)?a:WL}(),aU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=FO.h($CLJS.pd("undefined"!==typeof $CLJS.CL&&"undefined"!==typeof DL&&"undefined"!==typeof EL&&"undefined"!==typeof QT?new $CLJS.Gc(function(){return QT},$CLJS.od(Eia,new $CLJS.h(null,1,[$CLJS.ck,!0],null)),$CLJS.Jg([DO,
$CLJS.Xi,$CLJS.T,$CLJS.qk,$CLJS.wi,FO,$CLJS.Ui,$CLJS.oj,$CLJS.zk,FQ,BM,$CLJS.vi,$CLJS.kk,$CLJS.pk],[new $CLJS.bh(null,new $CLJS.h(null,1,[$CLJS.ZE,"null"],null),null),$CLJS.od(kQ,new $CLJS.h(null,1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),wO,"metabase/mbql/schema.cljc",74,$CLJS.GF,1,947,947,!0,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.GF,$CLJS.FF,$CLJS.U($CLJS.Rj,$CLJS.U(DQ,bN))],null),$CLJS.Kc,"Schema for a valid cum-count clause.",
$CLJS.n(QT)?QT.H:null])):null));return $CLJS.n(a)?a:wO}(),QT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=FO.h($CLJS.pd("undefined"!==typeof $CLJS.CL&&"undefined"!==typeof DL&&"undefined"!==typeof EL&&"undefined"!==typeof PT?new $CLJS.Gc(function(){return PT},$CLJS.od(fja,new $CLJS.h(null,1,[$CLJS.ck,!0],null)),$CLJS.Jg([DO,$CLJS.Xi,$CLJS.T,$CLJS.qk,$CLJS.wi,FO,$CLJS.Ui,$CLJS.oj,$CLJS.zk,FQ,BM,$CLJS.vi,$CLJS.kk,$CLJS.pk],[new $CLJS.bh(null,new $CLJS.h(null,1,[$CLJS.ZE,"null"],null),null),
$CLJS.od(kQ,new $CLJS.h(null,1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),$CLJS.yi,"metabase/mbql/schema.cljc",70,$CLJS.rx,1,946,946,!0,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.rx,$CLJS.FF,$CLJS.U($CLJS.Rj,$CLJS.U(DQ,bN))],null),$CLJS.Kc,"Schema for a valid count clause.",$CLJS.n(PT)?PT.H:null])):null));return $CLJS.n(a)?a:$CLJS.yi}(),PT],null)]))],null)],null));
$CLJS.X(yP,zL($CLJS.RL,$CLJS.H(["aggregation",fQ,"options",new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.ik,new $CLJS.h(null,1,[$CLJS.Ys,":aggregation-options options"],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.T,new $CLJS.h(null,1,[$CLJS.fs,!0],null),KR],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ID,new $CLJS.h(null,1,[$CLJS.fs,!0],null),KR],null)],null)])));
$CLJS.X(CQ,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.Sr,new $CLJS.h(null,2,[$CLJS.Ys,"aggregation clause or numeric expression",$CLJS.Pi,function(a){return $CLJS.n(yL($CLJS.RL,a))?$CLJS.RL:uN}],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.RL,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,yP],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[uN,fQ],null)],null));$CLJS.X($L,zL($CLJS.CF,$CLJS.H(["field",lS])));var eU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,$L],null);$CLJS.X(zO,zL($CLJS.vF,$CLJS.H(["field",lS])));
fU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,zO],null);
fla=AL($CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=FO.h($CLJS.pd("undefined"!==typeof $CLJS.CL&&"undefined"!==typeof DL&&"undefined"!==typeof EL&&"undefined"!==typeof eU?new $CLJS.Gc(function(){return eU},$CLJS.od(xka,new $CLJS.h(null,1,[$CLJS.ck,!0],null)),$CLJS.Jg([$CLJS.Xi,$CLJS.T,$CLJS.qk,$CLJS.wi,FO,$CLJS.Ui,$CLJS.oj,$CLJS.zk,BM,$CLJS.vi,$CLJS.kk,$CLJS.pk],[$CLJS.od(kQ,new $CLJS.h(null,1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),
fO,"metabase/mbql/schema.cljc",15,$CLJS.CF,1,1049,1049,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.CF,$CLJS.FF,$CLJS.U($CLJS.Rj,WQ)],null),$CLJS.Kc,"Schema for a valid asc clause.",$CLJS.n(eU)?eU.H:null])):null));return $CLJS.n(a)?a:fO}(),eU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=FO.h($CLJS.pd("undefined"!==typeof $CLJS.CL&&"undefined"!==typeof DL&&"undefined"!==typeof EL&&"undefined"!==typeof fU?new $CLJS.Gc(function(){return fU},$CLJS.od(oja,new $CLJS.h(null,1,[$CLJS.ck,!0],null)),$CLJS.Jg([$CLJS.Xi,
$CLJS.T,$CLJS.qk,$CLJS.wi,FO,$CLJS.Ui,$CLJS.oj,$CLJS.zk,BM,$CLJS.vi,$CLJS.kk,$CLJS.pk],[$CLJS.od(kQ,new $CLJS.h(null,1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),aQ,"metabase/mbql/schema.cljc",16,$CLJS.vF,1,1050,1050,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.vF,$CLJS.FF,$CLJS.U($CLJS.Rj,WQ)],null),$CLJS.Kc,"Schema for a valid desc clause.",$CLJS.n(fU)?fU.H:null])):null));return $CLJS.n(a)?a:aQ}(),fU],null)]));
gU=new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.ik,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.nj,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.Jr,$CLJS.cM,$CLJS.SM,$CLJS.YE,$CLJS.tt,$CLJS.XA,$CLJS.YO],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.T,KR],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ID,KR],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.$i,new $CLJS.h(null,1,[$CLJS.fs,!0],null),KR],null)],null);
gla=new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Xs,gU,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.ik,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.nj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.or,$CLJS.cM],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.PL,KR],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.pP,NR],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.qP,new $CLJS.h(null,1,[$CLJS.fs,!0],null),NR],null)],null)],null);
hla=new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Xs,gU,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ik,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.nj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.or,$CLJS.SM],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.yQ,NR],null)],null)],null);
hU=new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Xs,gU,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ik,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.di,new $CLJS.h(null,1,[$CLJS.fs,!0],null),$CLJS.mi],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.tN,new $CLJS.h(null,1,[$CLJS.fs,!0],null),$CLJS.bs],null)],null)],null);ila=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,NQ],null);
jla=new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Xs,hU,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.ik,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.nj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.or,$CLJS.YE],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.YE,iS],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.mN,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,vM],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.gj,new $CLJS.h(null,1,[$CLJS.fs,!0],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Rr,$CLJS.Yi,$CLJS.mi],null)],null)],null)],null);
$CLJS.iU=new $CLJS.bh(null,new $CLJS.h(null,4,[$CLJS.YO,null,$CLJS.tt,null,$CLJS.bs,null,$CLJS.XA,null],null),null);kla=$CLJS.fg.g(new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.Jr],null),$CLJS.iU);
$CLJS.X(UP,new $CLJS.P(null,6,5,$CLJS.Q,[$CLJS.Sr,new $CLJS.h(null,1,[$CLJS.Pi,$CLJS.nj],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.YE,jla],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.cM,gla],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.SM,hla],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Eo,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Xs,hU,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ik,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.nj,kla],null)],null)],null)],null)],null));
var jU=new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ik,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.bQ,new $CLJS.h(null,1,[$CLJS.fs,!0],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Tr,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Rr,KR,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,UP],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Si,new $CLJS.h(null,1,[$CLJS.Ys,"keys in template tag map must match the :name of their values"],null),function(a){return $CLJS.Re(function(b){var c=$CLJS.I(b,0,null);b=$CLJS.I(b,1,null);return $CLJS.E.g(c,
$CLJS.T.h(b))},a)}],null)],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.IN,new $CLJS.h(null,1,[$CLJS.fs,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Lr,KR],null)],null)],null),lla=new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Xs,jU,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ik,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.aF,$CLJS.mi],null)],null)],null),kU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,CP],null),lU=new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.Sr,new $CLJS.h(null,1,[$CLJS.Pi,function(a){return $CLJS.n($CLJS.BD($CLJS.xd,
$CLJS.FP)(a))?$CLJS.FP:$CLJS.HD}],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.FP,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Xs,jU,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ik,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.FP,$CLJS.mi],null)],null)],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.HD,kU],null)],null),mU=new $CLJS.P(null,6,5,$CLJS.Q,[$CLJS.ik,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.T,KR],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.zC,LR],null),new $CLJS.P(null,2,5,$CLJS.Q,[Wja,KR],null),new $CLJS.P(null,3,5,$CLJS.Q,
[$CLJS.$N,new $CLJS.h(null,1,[$CLJS.fs,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Lr,MR],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.oN,new $CLJS.h(null,1,[$CLJS.fs,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Lr,$CLJS.ik],null)],null)],null),nU=new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Mr,Yka,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Nr,new $CLJS.h(null,1,[$CLJS.Ys,"'card__\x3cid\x3e' string Table ID"],null),/^card__[1-9]\d*$/],null)],null),mla=$CLJS.fg.g(new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.Jr],null),
new $CLJS.bh(null,new $CLJS.h(null,4,[$CLJS.CE,null,$CLJS.lE,null,$CLJS.vE,null,$CLJS.zE,null],null),null)),oU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,KQ],null);
$CLJS.X(EN,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Tr,new $CLJS.P(null,9,5,$CLJS.Q,[$CLJS.ik,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.BO,new $CLJS.h(null,1,[$CLJS.fs,!0],null),nU],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.dQ,new $CLJS.h(null,1,[$CLJS.fs,!0],null),lU],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.mR,$CLJS.nT],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.YD,new $CLJS.h(null,1,[$CLJS.fs,!0],null),mla],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.mE,new $CLJS.h(null,1,[$CLJS.fs,!0],null),new $CLJS.P(null,
4,5,$CLJS.Q,[$CLJS.Mr,new $CLJS.h(null,1,[$CLJS.Ys,"Valid join `:fields`: `:all`, `:none`, or a sequence of `:field` clauses that have `:join-alias`."],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Jr,$CLJS.Xx,$CLJS.Fz],null),oU],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.qE,new $CLJS.h(null,1,[$CLJS.fs,!0],null),KR],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.wK,new $CLJS.h(null,1,[$CLJS.fs,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Lr,PR],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.nO,new $CLJS.h(null,
1,[$CLJS.fs,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Lr,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Aj,mU],null)],null)],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Si,new $CLJS.h(null,1,[$CLJS.Ys,"Joins must have either a `source-table` or `source-query`, but not both."],null),$CLJS.BD($CLJS.$z($CLJS.BO,$CLJS.dQ),$CLJS.Ue($CLJS.BD($CLJS.BO,$CLJS.dQ)))],null)],null));var nla=$CLJS.Q,pU;var qU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Aj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,EN],null)],null);
if($CLJS.wd(qU)&&$CLJS.E.g($CLJS.z(qU),$CLJS.Aj)){var rU=$CLJS.y(qU);$CLJS.z(rU);var sU=$CLJS.B(rU),ola=$CLJS.xd($CLJS.z(sU))?sU:$CLJS.fe(null,sU),tU=$CLJS.y(ola),pla=$CLJS.z(tU),qla=$CLJS.B(tU);pU=$CLJS.fg.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Aj,$CLJS.R.j(pla,$CLJS.Rn,1)],null),qla)}else pU=new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Tr,qU,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Si,new $CLJS.h(null,1,[$CLJS.Ys,"non-empty"],null),$CLJS.y],null)],null);
$CLJS.X(GM,new $CLJS.P(null,3,5,nla,[$CLJS.Tr,pU,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Si,new $CLJS.h(null,1,[$CLJS.Ys,"All join aliases must be unique."],null),function(a){return $CLJS.kL($CLJS.mf($CLJS.Xa,$CLJS.jf.g($CLJS.qE,a)))}],null)],null));var rla=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,GM],null);
$CLJS.X(KQ,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.nk,new $CLJS.h(null,1,[$CLJS.Ys,"Distinct, non-empty sequence of Field clauses"],null),BL(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Aj,new $CLJS.h(null,1,[$CLJS.Rn,1],null),$CLJS.jS],null))],null));
$CLJS.X(CP,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.Tr,new $CLJS.P(null,13,5,$CLJS.Q,[$CLJS.ik,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.dQ,new $CLJS.h(null,1,[$CLJS.fs,!0],null),lU],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.BO,new $CLJS.h(null,1,[$CLJS.fs,!0],null),nU],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.uF,new $CLJS.h(null,1,[$CLJS.fs,!0],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Aj,new $CLJS.h(null,1,[$CLJS.Rn,1],null),$CLJS.wS],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.tF,new $CLJS.h(null,1,
[$CLJS.fs,!0],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Aj,new $CLJS.h(null,1,[$CLJS.Rn,1],null),$CLJS.jS],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.vP,new $CLJS.h(null,1,[$CLJS.fs,!0],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Rr,KR,$CLJS.OT],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.mE,new $CLJS.h(null,1,[$CLJS.fs,!0],null),oU],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.iF,new $CLJS.h(null,1,[$CLJS.fs,!0],null),$CLJS.nT],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.HR,new $CLJS.h(null,
1,[$CLJS.fs,!0],null),OR],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.kR,new $CLJS.h(null,1,[$CLJS.fs,!0],null),BL(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Aj,new $CLJS.h(null,1,[$CLJS.Rn,1],null),fla],null))],null),new $CLJS.P(null,3,5,$CLJS.Q,[GP,new $CLJS.h(null,1,[$CLJS.fs,!0],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ik,new $CLJS.P(null,2,5,$CLJS.Q,[GP,NR],null),new $CLJS.P(null,2,5,$CLJS.Q,[Xia,NR],null)],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.$M,new $CLJS.h(null,1,[$CLJS.fs,!0],null),rla],
null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.nO,new $CLJS.h(null,1,[$CLJS.fs,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Lr,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Aj,mU],null)],null)],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Si,new $CLJS.h(null,1,[$CLJS.Ys,"Query must specify either `:source-table` or `:source-query`, but not both."],null),function(a){return $CLJS.E.g(1,$CLJS.D($CLJS.fm(a,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.dQ,$CLJS.BO],null))))}],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Si,
new $CLJS.h(null,1,[$CLJS.Ys,"Fields specified in `:breakout` should not be specified in `:fields`; this is implied."],null),function(a){var b=$CLJS.O(a);a=$CLJS.J.g(b,$CLJS.tF);b=$CLJS.J.g(b,$CLJS.mE);return $CLJS.td($CLJS.Ht.g($CLJS.gh(a),$CLJS.gh(b)))}],null)],null));
$CLJS.uU=$CLJS.Jg([$CLJS.ZF,$CLJS.YO,AN,lN,oO,yR,VN,$CLJS.xR,$CLJS.tt,UN,jM,qQ,nR,$CLJS.zM,jR,xN,$CLJS.tO,SN,$CLJS.$i,$CLJS.RN,SL,$CLJS.WM,$CLJS.QN,TO,$CLJS.bs,$CLJS.XA,MN],[new $CLJS.h(null,1,[eO,new $CLJS.bh(null,new $CLJS.h(null,5,[$CLJS.ZF,null,$CLJS.YO,null,$CLJS.tt,null,$CLJS.bs,null,$CLJS.XA,null],null),null)],null),new $CLJS.h(null,2,[$CLJS.nj,$CLJS.YO,eO,new $CLJS.bh(null,new $CLJS.h(null,5,[$CLJS.ZF,null,$CLJS.YO,null,$CLJS.xR,null,$CLJS.tO,null,$CLJS.$i,null],null),null)],null),new $CLJS.h(null,
3,[$CLJS.nj,$CLJS.ak,$CLJS.QD,zQ,eO,new $CLJS.bh(null,new $CLJS.h(null,1,[AN,null],null),null)],null),new $CLJS.h(null,3,[$CLJS.nj,CR,$CLJS.QD,zQ,eO,new $CLJS.bh(null,new $CLJS.h(null,5,[$CLJS.ZF,null,lN,null,$CLJS.tt,null,$CLJS.$i,null,SL,null],null),null)],null),new $CLJS.h(null,3,[$CLJS.nj,$CLJS.ak,$CLJS.QD,nM,eO,new $CLJS.bh(null,new $CLJS.h(null,1,[oO,null],null),null)],null),new $CLJS.h(null,1,[eO,new $CLJS.bh(null,new $CLJS.h(null,1,[yR,null],null),null)],null),new $CLJS.h(null,3,[$CLJS.nj,
CR,$CLJS.QD,nka,eO,new $CLJS.bh(null,new $CLJS.h(null,1,[VN,null],null),null)],null),new $CLJS.h(null,2,[$CLJS.nj,$CLJS.YO,eO,new $CLJS.bh(null,new $CLJS.h(null,1,[$CLJS.xR,null],null),null)],null),new $CLJS.h(null,2,[$CLJS.nj,CR,eO,new $CLJS.bh(null,new $CLJS.h(null,5,[$CLJS.ZF,null,lN,null,$CLJS.tt,null,$CLJS.$i,null,SL,null],null),null)],null),new $CLJS.h(null,3,[$CLJS.nj,CR,$CLJS.QD,nM,eO,new $CLJS.bh(null,new $CLJS.h(null,1,[UN,null],null),null)],null),new $CLJS.h(null,1,[eO,new $CLJS.bh(null,
new $CLJS.h(null,1,[jM,null],null),null)],null),new $CLJS.h(null,3,[$CLJS.nj,$CLJS.ak,$CLJS.QD,zQ,eO,new $CLJS.bh(null,new $CLJS.h(null,8,[$CLJS.ZF,null,yR,null,jM,null,qQ,null,SN,null,$CLJS.$i,null,SL,null,$CLJS.XA,null],null),null)],null),new $CLJS.h(null,3,[$CLJS.nj,$CLJS.ak,$CLJS.QD,nM,eO,new $CLJS.bh(null,new $CLJS.h(null,1,[nR,null],null),null)],null),new $CLJS.h(null,2,[$CLJS.nj,$CLJS.YO,eO,new $CLJS.bh(null,new $CLJS.h(null,2,[$CLJS.xR,null,$CLJS.zM,null],null),null)],null),new $CLJS.h(null,
3,[$CLJS.nj,$CLJS.ak,$CLJS.QD,nM,eO,new $CLJS.bh(null,new $CLJS.h(null,1,[jR,null],null),null)],null),new $CLJS.h(null,3,[$CLJS.nj,$CLJS.ak,$CLJS.QD,nM,eO,new $CLJS.bh(null,new $CLJS.h(null,1,[xN,null],null),null)],null),new $CLJS.h(null,2,[$CLJS.nj,$CLJS.YO,eO,new $CLJS.bh(null,new $CLJS.h(null,5,[$CLJS.ZF,null,$CLJS.YO,null,$CLJS.xR,null,$CLJS.tO,null,$CLJS.$i,null],null),null)],null),new $CLJS.h(null,1,[eO,new $CLJS.bh(null,new $CLJS.h(null,1,[SN,null],null),null)],null),new $CLJS.h(null,1,[eO,
new $CLJS.bh(null,new $CLJS.h(null,1,[$CLJS.$i,null],null),null)],null),new $CLJS.h(null,2,[$CLJS.nj,$CLJS.YO,eO,new $CLJS.bh(null,new $CLJS.h(null,2,[$CLJS.xR,null,$CLJS.RN,null],null),null)],null),new $CLJS.h(null,1,[eO,new $CLJS.bh(null,new $CLJS.h(null,1,[SL,null],null),null)],null),new $CLJS.h(null,2,[$CLJS.nj,$CLJS.YO,eO,new $CLJS.bh(null,new $CLJS.h(null,2,[$CLJS.xR,null,$CLJS.WM,null],null),null)],null),new $CLJS.h(null,2,[$CLJS.nj,$CLJS.YO,eO,new $CLJS.bh(null,new $CLJS.h(null,2,[$CLJS.xR,
null,$CLJS.QN,null],null),null)],null),new $CLJS.h(null,3,[$CLJS.nj,CR,$CLJS.QD,zQ,eO,new $CLJS.bh(null,new $CLJS.h(null,1,[TO,null],null),null)],null),new $CLJS.h(null,2,[$CLJS.nj,$CLJS.bs,eO,new $CLJS.bh(null,new $CLJS.h(null,3,[$CLJS.ZF,null,$CLJS.$i,null,$CLJS.bs,null],null),null)],null),new $CLJS.h(null,2,[$CLJS.nj,$CLJS.ak,eO,new $CLJS.bh(null,new $CLJS.h(null,8,[$CLJS.ZF,null,yR,null,jM,null,qQ,null,SN,null,$CLJS.$i,null,SL,null,$CLJS.XA,null],null),null)],null),new $CLJS.h(null,3,[$CLJS.nj,
CR,$CLJS.QD,nM,eO,new $CLJS.bh(null,new $CLJS.h(null,1,[MN,null],null),null)],null)]);$CLJS.X(NQ,$CLJS.fg.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Jr,new $CLJS.h(null,1,[$CLJS.Ys,"valid parameter type"],null)],null),$CLJS.Yg($CLJS.uU)));$CLJS.X(vM,$CLJS.fg.g(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Jr,new $CLJS.h(null,1,[$CLJS.Ys,"valid template tag widget type"],null),$CLJS.Fz],null),$CLJS.Yg($CLJS.uU)));
$CLJS.X(ZP,zL(Oia,$CLJS.H(["tag-name",new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Mr,KR,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ik,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.$i,KR],null)],null)],null)])));var vU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,ZP],null);$CLJS.X(VL,zL($CLJS.YE,$CLJS.H(["target",new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Mr,$CLJS.jS,vU],null)])));var wU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,VL],null);$CLJS.X(QM,zL(PN,$CLJS.H(["target",vU])));
var xU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,QM],null),sla=new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Mr,$CLJS.jS,AL($CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=FO.h($CLJS.pd("undefined"!==typeof $CLJS.CL&&"undefined"!==typeof DL&&"undefined"!==typeof EL&&"undefined"!==typeof wU?new $CLJS.Gc(function(){return wU},$CLJS.od(Mja,new $CLJS.h(null,1,[$CLJS.ck,!0],null)),$CLJS.Jg([$CLJS.Xi,$CLJS.T,$CLJS.qk,$CLJS.wi,FO,$CLJS.Ui,$CLJS.oj,$CLJS.zk,BM,$CLJS.vi,$CLJS.kk,$CLJS.pk],[$CLJS.od(kQ,new $CLJS.h(null,
1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),KP,"metabase/mbql/schema.cljc",21,$CLJS.YE,1,1612,1612,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.YE,$CLJS.OI,$CLJS.U($CLJS.Rj,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Mr,bN,dO],null))],null),$CLJS.Kc,"Schema for a valid dimension clause.",$CLJS.n(wU)?wU.H:null])):null));return $CLJS.n(a)?a:KP}(),wU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=FO.h($CLJS.pd("undefined"!==typeof $CLJS.CL&&
"undefined"!==typeof DL&&"undefined"!==typeof EL&&"undefined"!==typeof xU?new $CLJS.Gc(function(){return xU},$CLJS.od(bka,new $CLJS.h(null,1,[$CLJS.ck,!0],null)),$CLJS.Jg([$CLJS.Xi,$CLJS.T,$CLJS.qk,$CLJS.wi,FO,$CLJS.Ui,$CLJS.oj,$CLJS.zk,BM,$CLJS.vi,$CLJS.kk,$CLJS.pk],[$CLJS.od(kQ,new $CLJS.h(null,1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),DR,"metabase/mbql/schema.cljc",20,PN,1,1615,1615,new $CLJS.P(null,3,5,$CLJS.Q,[PN,
$CLJS.OI,$CLJS.U($CLJS.Rj,dO)],null),$CLJS.Kc,"Schema for a valid variable clause.",$CLJS.n(xU)?xU.H:null])):null));return $CLJS.n(a)?a:DR}(),xU],null)]))],null),vL=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,CM],null);
$CLJS.X(CM,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Tr,function(a){return new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.Tr,a,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Si,new $CLJS.h(null,1,[$CLJS.Ys,"Query must specify either `:native` or `:query`, but not both."],null),$CLJS.BD($CLJS.$z($CLJS.FP,$CLJS.aF),$CLJS.Ue($CLJS.BD($CLJS.FP,$CLJS.aF)))],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Si,new $CLJS.h(null,1,[$CLJS.Ys,"Native queries must specify `:native`; MBQL queries must specify `:query`."],null),function(b){var c=
$CLJS.O(b);b=$CLJS.J.g(c,$CLJS.FP);var d=$CLJS.J.g(c,$CLJS.aF);c=$CLJS.J.g(c,$CLJS.nj);c=c instanceof $CLJS.M?c.S:null;switch(c){case "native":return b;case "query":return d;default:throw Error(["No matching clause: ",$CLJS.p.h(c)].join(""));}}],null)],null)}(new $CLJS.P(null,10,5,$CLJS.Q,[$CLJS.ik,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.qP,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.Mr,new $CLJS.h(null,1,[$CLJS.Ys,"valid Database ID"],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,$CLJS.AK],null),new $CLJS.P(null,
2,5,$CLJS.Q,[$CLJS.V,$CLJS.tK],null)],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.nj,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Jr,$CLJS.aF,$CLJS.FP],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.FP,new $CLJS.h(null,1,[$CLJS.fs,!0],null),lla],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.aF,new $CLJS.h(null,1,[$CLJS.fs,!0],null),kU],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.RM,new $CLJS.h(null,1,[$CLJS.fs,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Lr,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Aj,new $CLJS.P(null,
8,5,$CLJS.Q,[$CLJS.ik,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.nj,ila],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.$i,new $CLJS.h(null,1,[$CLJS.fs,!0],null),KR],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.OI,new $CLJS.h(null,1,[$CLJS.fs,!0],null),sla],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Ej,new $CLJS.h(null,1,[$CLJS.fs,!0],null),$CLJS.mi],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.T,new $CLJS.h(null,1,[$CLJS.fs,!0],null),KR],null),new $CLJS.P(null,3,5,$CLJS.Q,[Uia,new $CLJS.h(null,1,[$CLJS.fs,!0],null),
KR],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.di,new $CLJS.h(null,1,[$CLJS.fs,!0],null),$CLJS.mi],null)],null)],null)],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.yK,new $CLJS.h(null,1,[$CLJS.fs,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Lr,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ik,new $CLJS.P(null,3,5,$CLJS.Q,[Mka,new $CLJS.h(null,1,[$CLJS.fs,!0],null),YR],null)],null)],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[Lja,new $CLJS.h(null,1,[$CLJS.fs,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Lr,
new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Tr,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ik,new $CLJS.P(null,3,5,$CLJS.Q,[LO,new $CLJS.h(null,1,[$CLJS.fs,!0],null),OR],null),new $CLJS.P(null,3,5,$CLJS.Q,[uM,new $CLJS.h(null,1,[$CLJS.fs,!0],null),OR],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Si,new $CLJS.h(null,1,[$CLJS.Ys,"max-results-bare-rows must be less or equal to than max-results"],null),function(a){var b=$CLJS.O(a);a=$CLJS.J.g(b,LO);b=$CLJS.J.g(b,uM);return $CLJS.Ua($CLJS.n(a)?b:a)?!0:a>=b}],null)],
null)],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[Bja,new $CLJS.h(null,1,[$CLJS.fs,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Lr,new $CLJS.P(null,8,5,$CLJS.Q,[$CLJS.ik,new $CLJS.P(null,3,5,$CLJS.Q,[wka,new $CLJS.h(null,1,[$CLJS.fs,!0],null),$CLJS.bs],null),new $CLJS.P(null,3,5,$CLJS.Q,[Qia,new $CLJS.h(null,1,[$CLJS.fs,!0],null),$CLJS.bs],null),new $CLJS.P(null,3,5,$CLJS.Q,[zia,new $CLJS.h(null,1,[$CLJS.fs,!0],null),$CLJS.bs],null),new $CLJS.P(null,3,5,$CLJS.Q,[Wia,new $CLJS.h(null,1,[$CLJS.fs,
!0],null),$CLJS.bs],null),new $CLJS.P(null,3,5,$CLJS.Q,[oka,new $CLJS.h(null,1,[$CLJS.fs,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Lr,$CLJS.bs],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[Bia,new $CLJS.h(null,1,[$CLJS.fs,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Lr,$CLJS.bs],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[Cka,new $CLJS.h(null,1,[$CLJS.fs,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Lr,$CLJS.bs],null)],null)],null)],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.gy,new $CLJS.h(null,
1,[$CLJS.fs,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Lr,new $CLJS.P(null,11,5,$CLJS.Q,[$CLJS.ik,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.MM,new $CLJS.h(null,1,[$CLJS.fs,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Lr,new $CLJS.P(null,18,5,$CLJS.Q,[$CLJS.Jr,aka,Yja,$CLJS.IN,$ia,Wka,cka,rja,Nja,jja,Ria,nja,lka,zja,Lia,aja,Vka,Ska],null)],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[rka,new $CLJS.h(null,1,[$CLJS.fs,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Lr,NR],null)],null),new $CLJS.P(null,3,5,
$CLJS.Q,[wja,new $CLJS.h(null,1,[$CLJS.fs,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Lr,NR],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.yQ,new $CLJS.h(null,1,[$CLJS.fs,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Lr,Xka],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[Nka,new $CLJS.h(null,1,[$CLJS.fs,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Lr,KR],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[yja,new $CLJS.h(null,1,[$CLJS.fs,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Lr,NR],null)],null),
new $CLJS.P(null,3,5,$CLJS.Q,[Lka,new $CLJS.h(null,1,[$CLJS.fs,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Lr,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Rr,$CLJS.mi,$CLJS.mi],null)],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[sja,new $CLJS.h(null,1,[$CLJS.fs,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Lr,NR],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.LN,new $CLJS.h(null,1,[$CLJS.fs,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Lr,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Aj,new $CLJS.P(null,3,5,$CLJS.Q,
[$CLJS.Rr,$CLJS.mi,$CLJS.mi],null)],null)],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.fK,new $CLJS.h(null,1,[$CLJS.fs,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Lr,$CLJS.mi],null)],null)],null)],null)],null)],null)),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Si,new $CLJS.h(null,1,[$CLJS.Ys,"`:source-metadata` should be added in the same level as `:source-query` (i.e., the 'inner' MBQL query.)"],null),$CLJS.Ue($CLJS.nO)],null)],null));var yU=$CLJS.uL(vL);
(function(){var a=uia();return function(b){if($CLJS.n(yU.h?yU.h(b):yU.call(null,b)))return b;b=a.h?a.h(b):a.call(null,b);var c=$CLJS.rL(b);throw $CLJS.ii($CLJS.DD("Invalid query: {0}",$CLJS.H([$CLJS.Nh.l($CLJS.H([c]))])),new $CLJS.h(null,2,[$CLJS.cy,c,Gja,b],null));}})();