var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.string.js");require("./metabase.lib.dispatch.js");require("./metabase.lib.hierarchy.js");require("./metabase.lib.metadata.calculation.js");require("./metabase.lib.schema.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.temporal_bucketing.js");require("./metabase.shared.util.i18n.js");require("./metabase.shared.util.time.js");require("./metabase.util.js");require("./metabase.util.malli.js");
'use strict';var poa,qoa,roa,soa,toa,y0;
$CLJS.v0=function(){function a(e,f){if($CLJS.Ua(f))return"";e=$CLJS.Zz(e);var k=$CLJS.Ah.h(f);switch(k instanceof $CLJS.M?k.S:null){case "default":return $CLJS.VU("Default period","Default periods",e);case "millisecond":return $CLJS.VU("Millisecond","Milliseconds",e);case "second":return $CLJS.VU("Second","Seconds",e);case "minute":return $CLJS.VU("Minute","Minutes",e);case "hour":return $CLJS.VU("Hour","Hours",e);case "day":return $CLJS.VU("Day","Days",e);case "week":return $CLJS.VU("Week","Weeks",
e);case "month":return $CLJS.VU("Month","Months",e);case "quarter":return $CLJS.VU("Quarter","Quarters",e);case "year":return $CLJS.VU("Year","Years",e);case "minute-of-hour":return $CLJS.VU("Minute of hour","Minutes of hour",e);case "hour-of-day":return $CLJS.VU("Hour of day","Hours of day",e);case "day-of-week":return $CLJS.VU("Day of week","Days of week",e);case "day-of-month":return $CLJS.VU("Day of month","Days of month",e);case "day-of-year":return $CLJS.VU("Day of year","Days of year",e);case "week-of-year":return $CLJS.VU("Week of year",
"Weeks of year",e);case "month-of-year":return $CLJS.VU("Month of year","Months of year",e);case "quarter-of-year":return $CLJS.VU("Quarter of year","Quarters of year",e);default:return f=$CLJS.rt($CLJS.hh(f),/-/),e=$CLJS.y(f),f=$CLJS.z(e),e=$CLJS.B(e),$CLJS.Ms(" ",$CLJS.fe($CLJS.Wz(f),e))}}function b(e){return d.g?d.g(1,e):d.call(null,1,e)}function c(){return d.g?d.g(1,null):d.call(null,1,null)}var d=null;d=function(e,f){switch(arguments.length){case 0:return c.call(this);case 1:return b.call(this,
e);case 2:return a.call(this,e,f)}throw Error("Invalid arity: "+arguments.length);};d.o=c;d.h=b;d.g=a;return d}();$CLJS.w0=function(){var a=$CLJS.Ye($CLJS.N),b=$CLJS.Ye($CLJS.N),c=$CLJS.Ye($CLJS.N),d=$CLJS.Ye($CLJS.N),e=$CLJS.J.j(new $CLJS.h(null,1,[$CLJS.ij,$CLJS.zB],null),$CLJS.ij,$CLJS.Wh.o?$CLJS.Wh.o():$CLJS.Wh.call(null));return new $CLJS.ei($CLJS.Gh.g("metabase.lib.temporal-bucket","with-temporal-bucket-method"),function(f){return $CLJS.xB(f)},e,a,b,c,d)}();poa=$CLJS.Ye($CLJS.N);qoa=$CLJS.Ye($CLJS.N);
roa=$CLJS.Ye($CLJS.N);soa=$CLJS.Ye($CLJS.N);toa=$CLJS.J.j(new $CLJS.h(null,1,[$CLJS.ij,$CLJS.zB],null),$CLJS.ij,$CLJS.Wh.o?$CLJS.Wh.o():$CLJS.Wh.call(null));$CLJS.x0=new $CLJS.ei($CLJS.Gh.g("metabase.lib.temporal-bucket","temporal-bucket-method"),$CLJS.xB,toa,poa,qoa,roa,soa);$CLJS.x0.m(null,$CLJS.di,function(){return null});$CLJS.x0.m(null,$CLJS.yG,function(a){return $CLJS.ti.h(a)});y0=new $CLJS.bh(null,new $CLJS.h(null,4,[$CLJS.pG,null,$CLJS.sG,null,$CLJS.tG,null,$CLJS.qG,null],null),null);
$CLJS.uoa=$CLJS.fg.j($CLJS.Df,$CLJS.Qk.g($CLJS.Rk.h(y0),$CLJS.jf.h(function(a){var b=new $CLJS.h(null,2,[$CLJS.Fj,$CLJS.yG,$CLJS.ti,a],null);return $CLJS.E.g(a,$CLJS.rk)?$CLJS.R.j(b,$CLJS.di,!0):b})),$CLJS.KG);$CLJS.voa=$CLJS.Sk.g(function(a){var b=new $CLJS.h(null,2,[$CLJS.Fj,$CLJS.yG,$CLJS.ti,a],null);return $CLJS.E.g(a,$CLJS.zt)?$CLJS.R.j(b,$CLJS.di,!0):b},$CLJS.FG);
$CLJS.woa=$CLJS.fg.j($CLJS.Df,$CLJS.Qk.g($CLJS.Rk.h(y0),$CLJS.jf.h(function(a){var b=new $CLJS.h(null,2,[$CLJS.Fj,$CLJS.yG,$CLJS.ti,a],null);return $CLJS.E.g(a,$CLJS.zt)?$CLJS.R.j(b,$CLJS.di,!0):b})),$CLJS.LG);$CLJS.c0.m(null,$CLJS.yG,function(a,b,c){a=$CLJS.O(c);a=$CLJS.J.g(a,$CLJS.ti);return $CLJS.v0.h(a)});
$CLJS.j0.m(null,$CLJS.yG,function(a,b,c){return $CLJS.hl.l($CLJS.H([new $CLJS.h(null,2,[$CLJS.ID,$CLJS.D_.j(a,b,c),$CLJS.a0,$CLJS.zA($CLJS.x0.h(c))],null),$CLJS.fm(c,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.di,$CLJS.L_],null))]))});
$CLJS.z0=function(){var a=$CLJS.Ye($CLJS.N),b=$CLJS.Ye($CLJS.N),c=$CLJS.Ye($CLJS.N),d=$CLJS.Ye($CLJS.N),e=$CLJS.J.j(new $CLJS.h(null,1,[$CLJS.ij,$CLJS.zB],null),$CLJS.ij,$CLJS.Wh.o?$CLJS.Wh.o():$CLJS.Wh.call(null));return new $CLJS.ei($CLJS.Gh.g("metabase.lib.temporal-bucket","available-temporal-buckets-method"),function(f,k,l){return $CLJS.xB(l)},e,a,b,c,d)}();$CLJS.z0.m(null,$CLJS.di,function(){return $CLJS.ch});
$CLJS.A0=function(){function a(d,e,f){return $CLJS.z0.j(d,e,f)}function b(d,e){return c.j?c.j(d,-1,e):c.call(null,d,-1,e)}var c=null;c=function(d,e,f){switch(arguments.length){case 2:return b.call(this,d,e);case 3:return a.call(this,d,e,f)}throw Error("Invalid arity: "+arguments.length);};c.g=b;c.j=a;return c}();