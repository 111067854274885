var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./medley.core.js");require("./metabase.lib.common.js");require("./metabase.lib.dispatch.js");require("./metabase.lib.equality.js");require("./metabase.lib.hierarchy.js");require("./metabase.lib.metadata.js");require("./metabase.lib.metadata.calculation.js");require("./metabase.lib.options.js");require("./metabase.lib.ref.js");require("./metabase.lib.schema.js");require("./metabase.lib.schema.aggregation.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.temporal_bucket.js");require("./metabase.lib.types.isa.js");require("./metabase.lib.util.js");require("./metabase.shared.util.i18n.js");require("./metabase.util.js");require("./metabase.util.malli.js");
'use strict';var L3,N3,O3,P3,Eqa;L3=function(a,b,c){var d=$CLJS.bW(a,b);d=$CLJS.O(d);d=$CLJS.J.g(d,$CLJS.uF);d=$CLJS.QG($CLJS.Qk.j($CLJS.dh([c]),$CLJS.UD,$CLJS.hd),d);if(!$CLJS.n(d))throw $CLJS.ii($CLJS.DD("No aggregation with uuid {0}",$CLJS.H([c])),new $CLJS.h(null,3,[$CLJS.as,c,$CLJS.aF,a,$CLJS.aL,b],null));return d};$CLJS.M3=function(a){return $CLJS.u1($CLJS.SF,new $CLJS.P(null,1,5,$CLJS.Q,[a],null))};
N3=new $CLJS.M("metabase.lib.aggregation","unary-aggregation","metabase.lib.aggregation/unary-aggregation",1064133592);O3=new $CLJS.M("metabase.lib.aggregation","count-aggregation","metabase.lib.aggregation/count-aggregation",-21314018);P3=new $CLJS.M("metabase.lib.aggregation","aggregation","metabase.lib.aggregation/aggregation",1701573815);Eqa=new $CLJS.M(null,"aggregation-operator","aggregation-operator",-1978700834);$CLJS.f0.m(null,$CLJS.uF,function(a,b){var c=$CLJS.Ge($CLJS.uF.h($CLJS.bW(a,b)));return $CLJS.n(c)?$CLJS.w1($CLJS.eE("and"),function(){return function f(e){return new $CLJS.oe(null,function(){for(;;){var k=$CLJS.y(e);if(k){if($CLJS.Ad(k)){var l=$CLJS.lc(k),m=$CLJS.D(l),t=$CLJS.re(m);a:for(var u=0;;)if(u<m){var v=$CLJS.kd(l,u);v=$CLJS.D_.v(a,b,v,$CLJS.E_);t.add(v);u+=1}else{l=!0;break a}return l?$CLJS.ue($CLJS.we(t),f($CLJS.mc(k))):$CLJS.ue($CLJS.we(t),null)}t=$CLJS.z(k);return $CLJS.fe($CLJS.D_.v(a,
b,t,$CLJS.E_),f($CLJS.Lc(k)))}return null}},null,null)}(c)}()):null});$CLJS.i0.m(null,$CLJS.uF,function(a,b,c){$CLJS.I(c,0,null);var d=$CLJS.I(c,1,null),e=$CLJS.O(d);d=$CLJS.J.g(e,$CLJS.TA);e=$CLJS.J.g(e,$CLJS.Gi);c=$CLJS.I(c,2,null);c=L3(a,b,c);return $CLJS.hl.l($CLJS.H([$CLJS.B_.j(a,b,c),new $CLJS.h(null,2,[$CLJS.iK,$CLJS.IK,$CLJS.G0,$CLJS.UD.h($CLJS.hd(c))],null),$CLJS.n(d)?new $CLJS.h(null,1,[$CLJS.TA,d],null):null,$CLJS.n(e)?new $CLJS.h(null,1,[$CLJS.Gi,e],null):null]))});
$CLJS.c0.m(null,$CLJS.uF,function(a,b,c,d){$CLJS.I(c,0,null);$CLJS.I(c,1,null);c=$CLJS.I(c,2,null);return $CLJS.D_.v(a,b,L3(a,b,c),d)});$CLJS.LE(O3,P3);
for(var Q3=$CLJS.y(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.rx,$CLJS.GF],null)),R3=null,S3=0,T3=0;;)if(T3<S3){var Fqa=R3.X(null,T3);$CLJS.LE(Fqa,O3);T3+=1}else{var U3=$CLJS.y(Q3);if(U3){var V3=U3;if($CLJS.Ad(V3)){var W3=$CLJS.lc(V3),Gqa=$CLJS.mc(V3),Hqa=W3,Iqa=$CLJS.D(W3);Q3=Gqa;R3=Hqa;S3=Iqa}else{var Jqa=$CLJS.z(V3);$CLJS.LE(Jqa,O3);Q3=$CLJS.B(V3);R3=null;S3=0}T3=0}else break}
$CLJS.c0.m(null,O3,function(a,b,c,d){var e=$CLJS.I(c,0,null);$CLJS.I(c,1,null);c=$CLJS.I(c,2,null);if($CLJS.n(c))switch(a=$CLJS.D_.v(a,b,c,d),e=e instanceof $CLJS.M?e.S:null,e){case "count":return $CLJS.DD("Count of {0}",$CLJS.H([a]));case "cum-count":return $CLJS.DD("Cumulative count of {0}",$CLJS.H([a]));default:throw Error(["No matching clause: ",$CLJS.p.h(e)].join(""));}else switch(e=e instanceof $CLJS.M?e.S:null,e){case "count":return $CLJS.eE("Count");case "cum-count":return $CLJS.eE("Cumulative count");
default:throw Error(["No matching clause: ",$CLJS.p.h(e)].join(""));}});$CLJS.d0.m(null,O3,function(a,b,c){a=$CLJS.I(c,0,null);a=a instanceof $CLJS.M?a.S:null;switch(a){case "count":return"count";case "cum-count":return"cum_count";default:throw Error(["No matching clause: ",$CLJS.p.h(a)].join(""));}});$CLJS.i0.m(null,O3,function(a,b,c){var d=$CLJS.R.j,e=$CLJS.IH($CLJS.i0,P3);a=e.j?e.j(a,b,c):e.call(null,a,b,c);return d.call($CLJS.R,a,$CLJS.Wj,$CLJS.nC)});$CLJS.c0.m(null,$CLJS.nF,function(){return $CLJS.eE("Case")});
$CLJS.d0.m(null,$CLJS.nF,function(){return"case"});$CLJS.LE(N3,P3);for(var X3=$CLJS.y(new $CLJS.P(null,9,5,$CLJS.Q,[$CLJS.zF,$CLJS.bG,$CLJS.jF,$CLJS.jk,$CLJS.TF,$CLJS.Rn,$CLJS.VF,$CLJS.SF,$CLJS.kF],null)),Y3=null,Z3=0,$3=0;;)if($3<Z3){var Kqa=Y3.X(null,$3);$CLJS.LE(Kqa,N3);$3+=1}else{var a4=$CLJS.y(X3);if(a4){var b4=a4;if($CLJS.Ad(b4)){var c4=$CLJS.lc(b4),Lqa=$CLJS.mc(b4),Mqa=c4,Nqa=$CLJS.D(c4);X3=Lqa;Y3=Mqa;Z3=Nqa}else{var Oqa=$CLJS.z(b4);$CLJS.LE(Oqa,N3);X3=$CLJS.B(b4);Y3=null;Z3=0}$3=0}else break}
$CLJS.d0.m(null,N3,function(a,b,c){a=$CLJS.I(c,0,null);$CLJS.I(c,1,null);$CLJS.I(c,2,null);c=a instanceof $CLJS.M?a.S:null;switch(c){case "avg":return"avg";case "cum-sum":return"sum";case "distinct":return"count";case "max":return"max";case "median":return"median";case "min":return"min";case "stddev":return"stddev";case "sum":return"sum";case "var":return"var";default:throw Error(["No matching clause: ",$CLJS.p.h(c)].join(""));}});
$CLJS.c0.m(null,N3,function(a,b,c,d){var e=$CLJS.I(c,0,null);$CLJS.I(c,1,null);c=$CLJS.I(c,2,null);a=$CLJS.D_.v(a,b,c,d);e=e instanceof $CLJS.M?e.S:null;switch(e){case "avg":return $CLJS.DD("Average of {0}",$CLJS.H([a]));case "cum-sum":return $CLJS.DD("Cumulative sum of {0}",$CLJS.H([a]));case "distinct":return $CLJS.DD("Distinct values of {0}",$CLJS.H([a]));case "max":return $CLJS.DD("Max of {0}",$CLJS.H([a]));case "median":return $CLJS.DD("Median of {0}",$CLJS.H([a]));case "min":return $CLJS.DD("Min of {0}",
$CLJS.H([a]));case "stddev":return $CLJS.DD("Standard deviation of {0}",$CLJS.H([a]));case "sum":return $CLJS.DD("Sum of {0}",$CLJS.H([a]));case "var":return $CLJS.DD("Variance of {0}",$CLJS.H([a]));default:throw Error(["No matching clause: ",$CLJS.p.h(e)].join(""));}});$CLJS.c0.m(null,$CLJS.qF,function(a,b,c,d){$CLJS.I(c,0,null);$CLJS.I(c,1,null);var e=$CLJS.I(c,2,null);c=$CLJS.I(c,3,null);return $CLJS.DD("{0}th percentile of {1}",$CLJS.H([c,$CLJS.D_.v(a,b,e,d)]))});$CLJS.d0.m(null,$CLJS.qF,function(){return"percentile"});
$CLJS.LE($CLJS.qF,P3);$CLJS.c0.m(null,$CLJS.$F,function(a,b,c,d){$CLJS.I(c,0,null);$CLJS.I(c,1,null);var e=$CLJS.I(c,2,null);$CLJS.I(c,3,null);return $CLJS.DD("Sum of {0} matching condition",$CLJS.H([$CLJS.D_.v(a,b,e,d)]))});$CLJS.d0.m(null,$CLJS.$F,function(a,b,c){$CLJS.I(c,0,null);$CLJS.I(c,1,null);var d=$CLJS.I(c,2,null);$CLJS.I(c,3,null);return["sum_where_",$CLJS.p.h($CLJS.e0.j(a,b,d))].join("")});$CLJS.LE($CLJS.$F,P3);$CLJS.c0.m(null,$CLJS.MF,function(){return $CLJS.eE("Share of rows matching condition")});
$CLJS.d0.m(null,$CLJS.MF,function(){return"share"});$CLJS.LE($CLJS.MF,P3);$CLJS.c0.m(null,$CLJS.OF,function(){return $CLJS.eE("Count of rows matching condition")});$CLJS.d0.m(null,$CLJS.OF,function(){return"count-where"});$CLJS.LE($CLJS.OF,P3);
$CLJS.i0.m(null,P3,function(a,b,c){$CLJS.I(c,0,null);$CLJS.I(c,1,null);var d=$CLJS.I(c,2,null),e=$CLJS.hl.l;d=$CLJS.n(d)?$CLJS.fm($CLJS.B_.j(a,b,d),new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.yK],null)):null;var f=$CLJS.IH($CLJS.i0,$CLJS.di);a=f.j?f.j(a,b,c):f.call(null,a,b,c);return e.call($CLJS.hl,$CLJS.H([d,a]))});
$CLJS.Pqa=function(){function a(d){return $CLJS.u1($CLJS.rx,new $CLJS.P(null,1,5,$CLJS.Q,[d],null))}function b(){return $CLJS.u1($CLJS.rx,$CLJS.Df)}var c=null;c=function(d){switch(arguments.length){case 0:return b.call(this);case 1:return a.call(this,d)}throw Error("Invalid arity: "+arguments.length);};c.o=b;c.h=a;return c}();$CLJS.LV.m(null,$CLJS.uF,function(a){return a});
$CLJS.d4=function(){function a(d,e,f){for(;;)if($CLJS.E.g($CLJS.xB(f),$CLJS.mK))f=$CLJS.NV(f);else return $CLJS.y1(d,e,$CLJS.uF,f)}function b(d,e){return c.j?c.j(d,-1,e):c.call(null,d,-1,e)}var c=null;c=function(d,e,f){switch(arguments.length){case 2:return b.call(this,d,e);case 3:return a.call(this,d,e,f)}throw Error("Invalid arity: "+arguments.length);};c.g=b;c.j=a;return c}();
$CLJS.e4=function(){function a(d,e){return $CLJS.Ge($CLJS.uF.h($CLJS.bW(d,e)))}function b(d){return c.g?c.g(d,-1):c.call(null,d,-1)}var c=null;c=function(d,e){switch(arguments.length){case 1:return b.call(this,d);case 2:return a.call(this,d,e)}throw Error("Invalid arity: "+arguments.length);};c.h=b;c.g=a;return c}();
$CLJS.f4=function(){function a(d,e){var f=$CLJS.Ge($CLJS.uF.h($CLJS.bW(d,e)));return null==f?null:$CLJS.fg.j($CLJS.Df,$CLJS.jf.h(function(k){var l=$CLJS.B_.j(d,e,k),m=$CLJS.R.l,t=$CLJS.TA.h(l);return m.call($CLJS.R,$CLJS.t1(l,$CLJS.Gi,$CLJS.n(t)?t:$CLJS.Bj),$CLJS.iK,$CLJS.IK,$CLJS.H([$CLJS.G0,$CLJS.UD.h($CLJS.hd(k))]))}),f)}function b(d){return c.g?c.g(d,-1):c.call(null,d,-1)}var c=null;c=function(d,e){switch(arguments.length){case 1:return b.call(this,d);case 2:return a.call(this,d,e)}throw Error("Invalid arity: "+
arguments.length);};c.h=b;c.g=a;return c}();$CLJS.c0.m(null,$CLJS.nG,function(a,b,c){a=$CLJS.O(c);a=$CLJS.J.g(a,$CLJS.jG);return $CLJS.ID.h(a.o?a.o():a.call(null))});$CLJS.j0.m(null,$CLJS.nG,function(a,b,c){var d=$CLJS.O(c);a=$CLJS.J.g(d,$CLJS.yF);b=$CLJS.J.g(d,$CLJS.jG);c=$CLJS.J.g(d,$CLJS.iG);d=$CLJS.J.g(d,$CLJS.jZ);a=$CLJS.R.l(b.o?b.o():b.call(null),$CLJS.a0,$CLJS.zA(a),$CLJS.H([$CLJS.Z_,c]));return null!=d?$CLJS.R.j(a,$CLJS.L_,d):a});
$CLJS.Qqa=function(){function a(d,e){var f=function(){var m=$CLJS.Gz.h($CLJS.Z0(d));return $CLJS.n(m)?m:$CLJS.ch}(),k=$CLJS.bW(d,e),l=$CLJS.n0.j(d,e,k);return $CLJS.Ge($CLJS.fg.j($CLJS.Df,$CLJS.Qk.j($CLJS.lf(function(m){m=$CLJS.lG.h(m);var t=null==m;return t?t:f.h?f.h(m):f.call(null,m)}),$CLJS.Yl(function(m){m=$CLJS.O(m);var t=$CLJS.J.g(m,$CLJS.iG),u=$CLJS.J.g(m,$CLJS.gG);if($CLJS.Ua(t))return m;if($CLJS.E.g(u,$CLJS.mi))return $CLJS.R.j(m,$CLJS.ZV,l);t=$CLJS.Ge($CLJS.B0(function(v){return $CLJS.WZ(u,
v)},l));return $CLJS.n(t)?$CLJS.R.j(m,$CLJS.ZV,t):null}),$CLJS.jf.h(function(m){return $CLJS.R.j(m,$CLJS.Fj,$CLJS.nG)})),$CLJS.oG))}function b(d){return c.g?c.g(d,-1):c.call(null,d,-1)}var c=null;c=function(d,e){switch(arguments.length){case 1:return b.call(this,d);case 2:return a.call(this,d,e)}throw Error("Invalid arity: "+arguments.length);};c.h=b;c.g=a;return c}();
$CLJS.Rqa=function(){function a(d,e){return $CLJS.MV(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.yF.h(d),$CLJS.N,$CLJS.OV.h(e)],null))}function b(d){if($CLJS.Ua($CLJS.iG.h(d)))return $CLJS.MV(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.yF.h(d),$CLJS.N],null));var e=$CLJS.yF.h(d);e=$CLJS.fa.g?$CLJS.fa.g("aggregation operator %s requires an argument",e):$CLJS.fa.call(null,"aggregation operator %s requires an argument",e);throw $CLJS.ii(e,new $CLJS.h(null,1,[Eqa,d],null));}var c=null;c=function(d,e){switch(arguments.length){case 1:return b.call(this,
d);case 2:return a.call(this,d,e)}throw Error("Invalid arity: "+arguments.length);};c.h=b;c.g=a;return c}();