var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./metabase.lib.schema.aggregation.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.expression.js");require("./metabase.lib.schema.expression.arithmetic.js");require("./metabase.lib.schema.expression.conditional.js");require("./metabase.lib.schema.expression.string.js");require("./metabase.lib.schema.expression.temporal.js");require("./metabase.lib.schema.filter.js");require("./metabase.lib.schema.id.js");require("./metabase.lib.schema.join.js");require("./metabase.lib.schema.literal.js");require("./metabase.lib.schema.order_by.js");require("./metabase.lib.schema.ref.js");require("./metabase.lib.schema.template_tag.js");require("./metabase.lib.schema.util.js");require("./metabase.mbql.util.js");require("./metabase.mbql.util.match.js");require("./metabase.util.malli.registry.js");
'use strict';var eV,gV,hV,Cla,lV,mV,nV,Ela,oV,pV,qV,sV,tV,uV,vV,wV,xV,zV,AV,BV,CV,DV,GV,HV,Dla;eV=function(a){return $CLJS.fg.j($CLJS.N,$CLJS.Rk.h(function(b){var c=$CLJS.I(b,0,null);$CLJS.I(b,1,null);return $CLJS.ne(c)}),a)};
$CLJS.fV=function(a){var b=2>$CLJS.D(a);return b?b:$CLJS.Te.g($CLJS.SB,function(){return function e(d){return new $CLJS.oe(null,function(){for(;;){var f=$CLJS.y(d);if(f){var k=f;if($CLJS.Ad(k)){var l=$CLJS.lc(k),m=$CLJS.D(l),t=$CLJS.re(m);return function(){for(var v=0;;)if(v<m){var x=$CLJS.kd(l,v);$CLJS.ve(t,$CLJS.cE(x,function(){return function(A){return $CLJS.Ik.l(eV(A),$CLJS.TA,$CLJS.H([$CLJS.Gi]))}}(v,x,l,m,t,k,f,b)));v+=1}else return!0}()?$CLJS.ue($CLJS.we(t),e($CLJS.mc(k))):$CLJS.ue($CLJS.we(t),
null)}var u=$CLJS.z(k);return $CLJS.fe($CLJS.cE(u,function(){return function(v){return $CLJS.Ik.l(eV(v),$CLJS.TA,$CLJS.H([$CLJS.Gi]))}}(u,k,f,b)),e($CLJS.Lc(k)))}return null}},null,null)}(a)}())};
gV=function(a,b){a=new $CLJS.P(null,1,5,$CLJS.Q,[new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Df,a],null)],null);for(var c=$CLJS.Df;;){var d=$CLJS.qd(a);if($CLJS.n(d)){var e=d,f=$CLJS.I(e,0,null),k=$CLJS.I(e,1,null),l=e,m=$CLJS.sd(a);a=function(t,u,v,x,A,C,G,K){return function(S){return $CLJS.fg.j(v,$CLJS.jf.h(function(W,Z,ha,ua,Ta){return function(Cb){var Oa=$CLJS.I(Cb,0,null);Cb=$CLJS.I(Cb,1,null);return new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ce.g(Ta,Oa),Cb],null)}}(t,u,v,x,A,C,G,K)),S)}}(a,c,m,e,f,k,l,d);
$CLJS.n(b.h?b.h(k):b.call(null,k))?(a=m,c=$CLJS.ce.g(c,l)):a=$CLJS.xd(k)?a(k):$CLJS.wd(k)?a($CLJS.em($CLJS.es,k)):m}else return c}};hV=function(a,b,c){return $CLJS.zd(c)&&$CLJS.E.g(a,$CLJS.z(c))&&!$CLJS.Hd(b,$CLJS.J.g(c,2))};$CLJS.iV=function(a){var b=$CLJS.fg.j($CLJS.ch,$CLJS.jf.h($CLJS.Qk.g($CLJS.MD,$CLJS.hd)),$CLJS.vP.h(a));return gV($CLJS.Ik.l(a,$CLJS.$M,$CLJS.H([$CLJS.KU])),function(c){return hV($CLJS.QA,b,c)})};
$CLJS.jV=function(a){var b=$CLJS.fg.j($CLJS.ch,$CLJS.jf.h($CLJS.Qk.g($CLJS.UD,$CLJS.hd)),$CLJS.uF.h(a));return gV($CLJS.Ik.l(a,$CLJS.$M,$CLJS.H([$CLJS.KU])),function(c){return hV($CLJS.uF,b,c)})};Cla=function(a){function b(d){return $CLJS.kf(c,$CLJS.H([$CLJS.$M.h(d)]))}function c(d){return $CLJS.fe($CLJS.qE.h(d),$CLJS.kf(b,$CLJS.H([$CLJS.PK.h(d)])))}return $CLJS.n($CLJS.kV.h(a))?$CLJS.Ve(!0):$CLJS.gh(b(a))};
lV=function(a){if($CLJS.wd(a))for(var b=$CLJS.Ve(!1),c=$CLJS.y(a),d=$CLJS.z(c),e=$CLJS.B(c),f=b,k=0,l=a;;){var m=f,t=k,u=l,v=$CLJS.y(u),x=$CLJS.z(v),A=$CLJS.B(v),C=x,G=A,K=$CLJS.$z(m,Cla(C));f=$CLJS.z($CLJS.y($CLJS.mf($CLJS.Xa,function(S,W,Z,ha,ua,Ta){return function gb(Oa,Wa){try{if($CLJS.zd(Wa)&&3===$CLJS.D(Wa))try{var Va=$CLJS.F(Wa,0);if($CLJS.ie(Va,$CLJS.FF))try{var ob=$CLJS.F(Wa,1);if($CLJS.n($CLJS.QO.h(ob)))try{var Ja=$CLJS.F(Wa,1);if(null!=Ja?Ja.C&256||$CLJS.Bc===Ja.lf||(Ja.C?0:$CLJS.$a($CLJS.wb,
Ja)):$CLJS.$a($CLJS.wb,Ja))try{var cc=$CLJS.J.j(Ja,$CLJS.QO,$CLJS.$U);if($CLJS.n($CLJS.Ue(ha)(cc))){var $d=$CLJS.J.g(Ja,$CLJS.QO);$CLJS.F(Wa,2);return new $CLJS.P(null,1,5,$CLJS.Q,[["Invalid :field reference in stage ",$CLJS.p.h(Ta),": no join named ",$CLJS.Nh.l($CLJS.H([$d]))].join("")],null)}throw $CLJS.Y;}catch(rd){if(rd instanceof Error){var Id=rd;if(Id===$CLJS.Y)throw $CLJS.Y;throw Id;}throw rd;}else throw $CLJS.Y;}catch(rd){if(rd instanceof Error){Id=rd;if(Id===$CLJS.Y)throw $CLJS.Y;throw Id;
}throw rd;}else throw $CLJS.Y;}catch(rd){if(rd instanceof Error){Id=rd;if(Id===$CLJS.Y)throw $CLJS.Y;throw Id;}throw rd;}else throw $CLJS.Y;}catch(rd){if(rd instanceof Error){Id=rd;if(Id===$CLJS.Y)throw $CLJS.Y;throw Id;}throw rd;}else throw $CLJS.Y;}catch(rd){if(rd instanceof Error){Id=rd;if(Id===$CLJS.Y)return $CLJS.XU(gb,Oa,Wa);throw Id;}throw rd;}}}(f,k,l,K,m,t,u,v,x,A,C,G,b,0,a,a,c,d,e,d,e)($CLJS.Df,$CLJS.Ik.l(C,$CLJS.$M,$CLJS.H([Dla]))))));if($CLJS.n(f))return f;if($CLJS.y(G))k=t+1,f=K,l=G;
else return null}else return null};mV=new $CLJS.M("metabase.lib.schema","breakouts","metabase.lib.schema/breakouts",1354104361);nV=new $CLJS.r("metabase.lib.schema.util","distinct-refs?","metabase.lib.schema.util/distinct-refs?",-262561159,null);Ela=new $CLJS.M("metabase.lib.schema","stage","metabase.lib.schema/stage",1626908550);oV=new $CLJS.r(null,"distinct-refs?","distinct-refs?",-2065255505,null);
pV=new $CLJS.M("metabase.lib.schema","stage.mbql.with-source-table","metabase.lib.schema/stage.mbql.with-source-table",-2119049176);qV=new $CLJS.M("metabase.lib.schema","stage.mbql.with-source-card","metabase.lib.schema/stage.mbql.with-source-card",-1292215799);$CLJS.rV=new $CLJS.M("mbql.stage","mbql","mbql.stage/mbql",1578747798);sV=new $CLJS.M("metabase.lib.schema","stage.additional","metabase.lib.schema/stage.additional",1347605157);
tV=new $CLJS.M("metabase.lib.schema","stage.mbql.with-source","metabase.lib.schema/stage.mbql.with-source",1947556064);uV=new $CLJS.r(null,"refs","refs",80480079,null);vV=new $CLJS.M("metabase.lib.schema","stage.native","metabase.lib.schema/stage.native",-1206212320);wV=new $CLJS.M("metabase.lib.schema","stage.mbql.without-source","metabase.lib.schema/stage.mbql.without-source",1507072886);xV=new $CLJS.M("metabase.lib.schema","stage.type","metabase.lib.schema/stage.type",1362996639);
$CLJS.yV=new $CLJS.M("mbql.stage","native","mbql.stage/native",359422194);zV=new $CLJS.M("metabase.lib.schema","filters","metabase.lib.schema/filters",889389881);AV=new $CLJS.M("metabase.lib.schema","breakout","metabase.lib.schema/breakout",-921298600);BV=new $CLJS.M("metabase.lib.schema","fields","metabase.lib.schema/fields",-2010307316);CV=new $CLJS.M("metabase.lib.schema","filterable","metabase.lib.schema/filterable",-1640076631);
DV=new $CLJS.M("metabase.lib.schema","stage.valid-refs","metabase.lib.schema/stage.valid-refs",-383625241);$CLJS.EV=new $CLJS.M("mbql","query","mbql/query",-1285688662);$CLJS.kV=new $CLJS.M(null,"source-card","source-card",-1580820390);$CLJS.FV=new $CLJS.M(null,"filters","filters",974726919);GV=new $CLJS.M("metabase.lib.schema","stages.valid-refs","metabase.lib.schema/stages.valid-refs",-193458245);HV=new $CLJS.M("metabase.lib.schema","stage.initial","metabase.lib.schema/stage.initial",-1483255395);
$CLJS.IV=new $CLJS.M("metabase.lib.schema","stage.mbql","metabase.lib.schema/stage.mbql",1051351388);Dla=new $CLJS.M("stage","metadata","stage/metadata",1707239131);$CLJS.X(vV,new $CLJS.P(null,6,5,$CLJS.Q,[$CLJS.ik,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Fj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.or,$CLJS.yV],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.FP,$CLJS.Xa],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ps,new $CLJS.h(null,1,[$CLJS.fs,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Aj,$CLJS.El],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.IN,new $CLJS.h(null,1,[$CLJS.fs,!0],null),$CLJS.ND],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.bQ,new $CLJS.h(null,1,[$CLJS.fs,
!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,$CLJS.CU],null)],null)],null));$CLJS.X(AV,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,$CLJS.JF],null));
$CLJS.X(mV,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Tr,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Aj,new $CLJS.h(null,1,[$CLJS.Rn,1],null),AV],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Si,new $CLJS.h(null,1,[$CLJS.Ys,"Breakouts must be distinct"],null),new $CLJS.Gc(function(){return $CLJS.fV},nV,$CLJS.Jg([$CLJS.Xi,$CLJS.T,$CLJS.qk,$CLJS.wi,$CLJS.Ui,$CLJS.oj,$CLJS.zk,$CLJS.vi,$CLJS.kk,$CLJS.pk],[$CLJS.OU,oV,"metabase/lib/schema/util.cljc",21,1,64,64,$CLJS.U(new $CLJS.P(null,1,5,$CLJS.Q,[uV],null)),"Is a sequence of `refs` distinct for the purposes of appearing in `:fields` or `:breakouts` (ignoring keys that\n  aren't important such as namespaced keys and type info)?",
$CLJS.n($CLJS.fV)?$CLJS.fV.H:null]))],null)],null));
$CLJS.X(BV,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Tr,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Aj,new $CLJS.h(null,1,[$CLJS.Rn,1],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,$CLJS.JF],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Si,new $CLJS.h(null,1,[$CLJS.Ys,":fields must be distinct"],null),new $CLJS.Gc(function(){return $CLJS.fV},nV,$CLJS.Jg([$CLJS.Xi,$CLJS.T,$CLJS.qk,$CLJS.wi,$CLJS.Ui,$CLJS.oj,$CLJS.zk,$CLJS.vi,$CLJS.kk,$CLJS.pk],[$CLJS.OU,oV,"metabase/lib/schema/util.cljc",21,1,64,64,$CLJS.U(new $CLJS.P(null,
1,5,$CLJS.Q,[uV],null)),"Is a sequence of `refs` distinct for the purposes of appearing in `:fields` or `:breakouts` (ignoring keys that\n  aren't important such as namespaced keys and type info)?",$CLJS.n($CLJS.fV)?$CLJS.fV.H:null]))],null)],null));$CLJS.X(CV,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Mr,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,$CLJS.rE],null),new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.Pr,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.or,$CLJS.cF],null),$CLJS.ik,$CLJS.ak],null)],null));
$CLJS.X(zV,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Aj,new $CLJS.h(null,1,[$CLJS.Rn,1],null),CV],null));
var JV=$CLJS.$z(function(a){a=$CLJS.z($CLJS.iV(a));if($CLJS.n(a)){var b=$CLJS.bA(a,new $CLJS.P(null,2,5,$CLJS.Q,[1,2],null));return $CLJS.n(b)?["Invalid :expression reference: no expression named ",$CLJS.Nh.l($CLJS.H([b]))].join(""):["Invalid :expression reference: ",$CLJS.p.h($CLJS.J.g(a,1))].join("")}return null},function(a){a=$CLJS.z($CLJS.jV(a));if($CLJS.n(a)){var b=$CLJS.bA(a,new $CLJS.P(null,2,5,$CLJS.Q,[1,2],null));return $CLJS.n(b)?["Invalid :aggregation reference: no aggregation with uuid ",
$CLJS.p.h(b)].join(""):["Invalid :aggregation reference: ",$CLJS.p.h($CLJS.J.g(a,1))].join("")}return null});$CLJS.X(DV,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Si,new $CLJS.h(null,2,[$CLJS.Ys,"Valid references for a single query stage",$CLJS.it,function(a){a=$CLJS.O(a);a=$CLJS.J.g(a,$CLJS.Ej);return JV(a)}],null),$CLJS.Ue(JV)],null));
$CLJS.X($CLJS.IV,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.Tr,new $CLJS.P(null,11,5,$CLJS.Q,[$CLJS.ik,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Fj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.or,$CLJS.rV],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.$M,new $CLJS.h(null,1,[$CLJS.fs,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,$CLJS.NK],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.vP,new $CLJS.h(null,1,[$CLJS.fs,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,$CLJS.oE],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,
[$CLJS.tF,new $CLJS.h(null,1,[$CLJS.fs,!0],null),mV],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.uF,new $CLJS.h(null,1,[$CLJS.fs,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,$CLJS.hG],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.mE,new $CLJS.h(null,1,[$CLJS.fs,!0],null),BV],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.FV,new $CLJS.h(null,1,[$CLJS.fs,!0],null),zV],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.kR,new $CLJS.h(null,1,[$CLJS.fs,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,$CLJS.VK],null)],
null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.BO,new $CLJS.h(null,1,[$CLJS.fs,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,$CLJS.lK],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.kV,new $CLJS.h(null,1,[$CLJS.fs,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,$CLJS.sK],null)],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Si,new $CLJS.h(null,1,[$CLJS.Ys,":source-query is not allowed in pMBQL queries."],null),function(a){return!$CLJS.Hd(a,$CLJS.dQ)}],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Si,
new $CLJS.h(null,1,[$CLJS.Ys,"A query cannot have both a :source-table and a :source-card."],null),$CLJS.Ue($CLJS.BD($CLJS.BO,$CLJS.kV))],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,DV],null)],null));$CLJS.X(pV,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Xs,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,$CLJS.IV],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ik,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.BO,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,$CLJS.lK],null)],null)],null)],null));
$CLJS.X(qV,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Xs,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,$CLJS.IV],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ik,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.kV,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,$CLJS.sK],null)],null)],null)],null));$CLJS.X(tV,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Mr,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,pV],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,qV],null)],null));
$CLJS.X(wV,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Tr,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,$CLJS.IV],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Si,new $CLJS.h(null,1,[$CLJS.Ys,"Only the initial stage of a query can have a :source-table or :source-card."],null),$CLJS.Ue($CLJS.$z($CLJS.BO,$CLJS.kV))],null)],null));$CLJS.X(xV,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Jr,$CLJS.yV,$CLJS.rV],null));
$CLJS.X(Ela,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Tr,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ik,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Fj,xV],null)],null),new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.Sr,new $CLJS.h(null,1,[$CLJS.Pi,$CLJS.Fj],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.yV,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,vV],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.rV,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,$CLJS.IV],null)],null)],null)],null));
$CLJS.X(HV,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Tr,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ik,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Fj,xV],null)],null),new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.Sr,new $CLJS.h(null,1,[$CLJS.Pi,$CLJS.Fj],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.yV,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,vV],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.rV,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,tV],null)],null)],null)],null));$CLJS.X(sV,wV);
$CLJS.X(GV,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Si,new $CLJS.h(null,2,[$CLJS.Ys,"Valid references for all query stages",$CLJS.it,function(a){a=$CLJS.O(a);a=$CLJS.J.g(a,$CLJS.Ej);return lV.h?lV.h(a):lV.call(null,a)}],null),$CLJS.Ue(lV)],null));
$CLJS.X($CLJS.LK,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Tr,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Ar,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.nk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,HV],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.rr,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.nk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,sV],null)],null)],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,GV],null)],null));
$CLJS.X($CLJS.gL,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Tr,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.ik,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Fj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.or,$CLJS.EV],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.qP,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Mr,$CLJS.tK,$CLJS.AK],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.PK,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,$CLJS.LK],null)],null)],null),$CLJS.vla],null));