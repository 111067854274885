var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.set.js");require("./malli.core.js");require("./metabase.lib.hierarchy.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.expression.js");require("./metabase.lib.schema.literal.js");require("./metabase.lib.schema.mbql_clause.js");require("./metabase.lib.schema.temporal_bucketing.js");require("./metabase.shared.util.internal.time_common.js");require("./metabase.util.malli.registry.js");require("./shadow.js.shim.module$moment.js");require("./shadow.js.shim.module$moment_timezone.js");
'use strict';var iI,kI,mI,yI,CI,KI;$CLJS.hI=new $CLJS.M(null,"get-month","get-month",-369374731);iI=new $CLJS.M("metabase.lib.schema.expression.temporal","absolute-datetime.options","metabase.lib.schema.expression.temporal/absolute-datetime.options",-925669633);$CLJS.jI=new $CLJS.M(null,"convert-timezone","convert-timezone",-124803001);kI=new $CLJS.M("metabase.lib.schema.expression.temporal","temporal-extract.unit","metabase.lib.schema.expression.temporal/temporal-extract.unit",339336486);
$CLJS.lI=new $CLJS.M(null,"iso","iso",-1366207543);mI=new $CLJS.M("metabase.lib.schema.expression.temporal","absolute-datetime.base-type","metabase.lib.schema.expression.temporal/absolute-datetime.base-type",-904703365);$CLJS.nI=new $CLJS.M(null,"get-second","get-second",-2065946318);$CLJS.oI=new $CLJS.M(null,"get-year","get-year",-936011274);$CLJS.pI=new $CLJS.M(null,"absolute-datetime","absolute-datetime",-560340465);
$CLJS.qI=new $CLJS.M("lib.type-of","type-is-temporal-type-of-first-arg","lib.type-of/type-is-temporal-type-of-first-arg",76272405);$CLJS.rI=new $CLJS.M(null,"week-of-year-iso","week-of-year-iso",-1827744293);$CLJS.sI=new $CLJS.M(null,"datetime-diff","datetime-diff",1133112155);$CLJS.tI=new $CLJS.M(null,"interval","interval",1708495417);$CLJS.uI=new $CLJS.M(null,"us","us",746429226);$CLJS.vI=new $CLJS.M(null,"relative-datetime","relative-datetime",1285169278);
$CLJS.wI=new $CLJS.M(null,"datetime","datetime",494675702);$CLJS.xI=new $CLJS.M(null,"now","now",-1650525531);yI=new $CLJS.M("metabase.lib.schema.expression.temporal","temporal-extract.week-mode","metabase.lib.schema.expression.temporal/temporal-extract.week-mode",430590916);$CLJS.zI=new $CLJS.M(null,"instance","instance",-2121349050);$CLJS.AI=new $CLJS.M(null,"get-day","get-day",127568857);$CLJS.BI=new $CLJS.M(null,"get-week","get-week",-888059349);
CI=new $CLJS.M("metabase.lib.schema.expression.temporal","relative-datetime.amount","metabase.lib.schema.expression.temporal/relative-datetime.amount",7216442);$CLJS.DI=new $CLJS.M(null,"datetime-add","datetime-add",209603411);$CLJS.EI=new $CLJS.M(null,"get-minute","get-minute",1468086343);$CLJS.FI=new $CLJS.M(null,"get-day-of-week","get-day-of-week",2010140752);$CLJS.GI=new $CLJS.M("metabase.lib.schema.expression.temporal","timezone-id","metabase.lib.schema.expression.temporal/timezone-id",-1909635091);
$CLJS.HI=new $CLJS.M(null,"temporal-extract","temporal-extract",1781686405);$CLJS.II=new $CLJS.M(null,"get-hour","get-hour",622714059);$CLJS.JI=new $CLJS.M(null,"datetime-subtract","datetime-subtract",275307697);KI=new $CLJS.M("metabase.lib.schema.expression.temporal","get-week-mode","metabase.lib.schema.expression.temporal/get-week-mode",1489967652);$CLJS.LI=new $CLJS.M(null,"get-quarter","get-quarter",1327778593);$CLJS.MI=new $CLJS.M(null,"week-of-year-us","week-of-year-us",849423511);
$CLJS.NI=new $CLJS.M(null,"week-of-year-instance","week-of-year-instance",2047974624);$CLJS.OI=new $CLJS.M(null,"target","target",253001721);$CLJS.SE($CLJS.tI,$CLJS.H([$CLJS.Ds,$CLJS.xC,$CLJS.Yr,$CLJS.xG]));$CLJS.gE.m(null,$CLJS.qI,function(a){$CLJS.I(a,0,null);$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);a=$CLJS.hE(a);return $CLJS.vd(a)?(a=$CLJS.Ht.g(a,new $CLJS.bh(null,new $CLJS.h(null,2,[$CLJS.mC,null,$CLJS.MC,null],null),null)),$CLJS.E.g($CLJS.D(a),1)?$CLJS.z(a):a):a});$CLJS.LE($CLJS.qI,$CLJS.xE);
for(var PI=$CLJS.y(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.DI,$CLJS.JI],null)),QI=null,RI=0,SI=0;;)if(SI<RI){var TI=QI.X(null,SI);$CLJS.SE(TI,$CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,$CLJS.pE],null),$CLJS.Yr,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,$CLJS.xG],null)]));$CLJS.LE(TI,$CLJS.qI);SI+=1}else{var UI=$CLJS.y(PI);if(UI){var VI=UI;if($CLJS.Ad(VI)){var WI=$CLJS.lc(VI),Cha=$CLJS.mc(VI),Dha=WI,Eha=$CLJS.D(WI);PI=Cha;QI=Dha;RI=Eha}else{var XI=$CLJS.z(VI);$CLJS.SE(XI,$CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,
[$CLJS.V,$CLJS.pE],null),$CLJS.Yr,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,$CLJS.xG],null)]));$CLJS.LE(XI,$CLJS.qI);PI=$CLJS.B(VI);QI=null;RI=0}SI=0}else break}
for(var YI=$CLJS.y(new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.oI,$CLJS.hI,$CLJS.AI,$CLJS.II,$CLJS.EI,$CLJS.nI,$CLJS.LI],null)),ZI=null,$I=0,aJ=0;;)if(aJ<$I){var Fha=ZI.X(null,aJ);$CLJS.SE(Fha,$CLJS.H([$CLJS.Ds,$CLJS.xj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.nk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,$CLJS.pE],null)],null)]));aJ+=1}else{var bJ=$CLJS.y(YI);if(bJ){var cJ=bJ;if($CLJS.Ad(cJ)){var dJ=$CLJS.lc(cJ),Gha=$CLJS.mc(cJ),Hha=dJ,Iha=$CLJS.D(dJ);YI=Gha;ZI=Hha;$I=Iha}else{var Jha=$CLJS.z(cJ);$CLJS.SE(Jha,$CLJS.H([$CLJS.Ds,
$CLJS.xj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.nk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,$CLJS.pE],null)],null)]));YI=$CLJS.B(cJ);ZI=null;$I=0}aJ=0}else break}$CLJS.SE($CLJS.sI,$CLJS.H([$CLJS.Ds,$CLJS.xj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.nk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,$CLJS.pE],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.nk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,$CLJS.pE],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,$CLJS.uG],null)]));
for(var eJ=$CLJS.y(new $CLJS.bh(null,new $CLJS.h(null,8,[$CLJS.LI,null,$CLJS.EI,null,$CLJS.II,null,$CLJS.FI,null,$CLJS.nI,null,$CLJS.hI,null,$CLJS.oI,null,$CLJS.AI,null],null),null)),fJ=null,gJ=0,hJ=0;;)if(hJ<gJ){var Kha=fJ.X(null,hJ);$CLJS.SE(Kha,$CLJS.H([$CLJS.Ds,$CLJS.xj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.nk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,$CLJS.pE],null)],null)]));hJ+=1}else{var iJ=$CLJS.y(eJ);if(iJ){var jJ=iJ;if($CLJS.Ad(jJ)){var kJ=$CLJS.lc(jJ),Lha=$CLJS.mc(jJ),Mha=kJ,Nha=$CLJS.D(kJ);
eJ=Lha;fJ=Mha;gJ=Nha}else{var Oha=$CLJS.z(jJ);$CLJS.SE(Oha,$CLJS.H([$CLJS.Ds,$CLJS.xj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.nk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,$CLJS.pE],null)],null)]));eJ=$CLJS.B(jJ);fJ=null;gJ=0}hJ=0}else break}$CLJS.X(KI,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.Jr,$CLJS.lI,$CLJS.uI,$CLJS.zI],null));
$CLJS.UE($CLJS.BI,$CLJS.H([$CLJS.Ds,$CLJS.xj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.wI,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.nk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,$CLJS.pE],null)],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.zi,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.sr,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.nk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,KI],null)],null)],null)],null)]));
$CLJS.X($CLJS.GI,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Tr,$CLJS.ND,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Mr,$CLJS.fg.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Jr,new $CLJS.h(null,2,[$CLJS.Ys,"valid timezone ID",$CLJS.it,function(a){a=$CLJS.O(a);a=$CLJS.J.g(a,$CLJS.Ej);return["invalid timezone ID: ",$CLJS.Nh.l($CLJS.H([a]))].join("")}],null)],null),$CLJS.cA($CLJS.Jd,$CLJS.gI.tz.names())),$CLJS.YH],null)],null));
$CLJS.UE($CLJS.jI,$CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.wI,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.nk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,$CLJS.pE],null)],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.OI,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.nk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,$CLJS.GI],null)],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Az,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.sr,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.nk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,$CLJS.GI],null)],null)],null)],
null)]));$CLJS.LE($CLJS.jI,$CLJS.qI);$CLJS.SE($CLJS.xI,$CLJS.H([$CLJS.Ds,$CLJS.pC]));$CLJS.X(mI,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Tr,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,$CLJS.WD],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Si,new $CLJS.h(null,1,[$CLJS.Ys,":absolute-datetime base-type must derive from :type/Date or :type/DateTime"],null),function(a){return $CLJS.Se(function(b){return $CLJS.MB(a,b)},new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.mC,$CLJS.MC],null))}],null)],null));
$CLJS.X(iI,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Xs,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,$CLJS.SD],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ik,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.TA,new $CLJS.h(null,1,[$CLJS.fs,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,mI],null)],null)],null)],null));
$CLJS.fG.g($CLJS.pI,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.Ar,new $CLJS.h(null,1,[$CLJS.Ys,"valid :absolute-datetime clause"],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.or,$CLJS.pI],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.nk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,iI],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Cr,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.Ar,new $CLJS.h(null,1,[$CLJS.Ys,":absolute-datetime literal and unit for :type/Date"],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.nk,new $CLJS.P(null,
4,5,$CLJS.Q,[$CLJS.Mr,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,$CLJS.bI],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,$CLJS.dI],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,$CLJS.WH],null)],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.nk,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Mr,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.or,$CLJS.di],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,$CLJS.wG],null)],null)],null)],null),new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.Ar,new $CLJS.h(null,1,[$CLJS.Ys,":absolute-datetime literal and unit for :type/DateTime"],
null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.nk,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Mr,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.or,$CLJS.tv],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,$CLJS.fI],null)],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.nk,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Mr,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.or,$CLJS.di],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,$CLJS.rG],null)],null)],null)],null)],null)],null));
$CLJS.gE.m(null,$CLJS.pI,function(a){$CLJS.I(a,0,null);$CLJS.I(a,1,null);var b=$CLJS.I(a,2,null);a=$CLJS.I(a,3,null);a=$CLJS.E.g(b,$CLJS.tv)?$CLJS.E.g(a,$CLJS.di)?$CLJS.MC:$CLJS.n($CLJS.KE($CLJS.wG,a))?$CLJS.mC:$CLJS.MC:null;if($CLJS.n(a))return a;a="string"===typeof b?$CLJS.n($CLJS.oh($CLJS.TH,b))?$CLJS.mC:$CLJS.n($CLJS.oh($CLJS.UH,b))?$CLJS.mC:null:null;if($CLJS.n(a))return a;b=$CLJS.hE(b);b=$CLJS.vd(b)?$CLJS.fg.j($CLJS.ch,$CLJS.lf(function(c){return $CLJS.MB(c,$CLJS.Xj)}),b):b;return $CLJS.vd(b)&&
$CLJS.E.g($CLJS.D(b),1)?$CLJS.z(b):b});$CLJS.X(CI,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Mr,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.or,$CLJS.tv],null),$CLJS.Yr],null));
$CLJS.UE($CLJS.vI,$CLJS.H([$CLJS.Ds,$CLJS.MC,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.sk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.nk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,CI],null)],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ti,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.sr,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.nk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,$CLJS.xG],null)],null)],null)],null)]));
$CLJS.SE($CLJS.fy,$CLJS.H([$CLJS.Ds,$CLJS.lD,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.nk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,$CLJS.BE],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,$CLJS.vG],null)]));$CLJS.X(kI,new $CLJS.P(null,12,5,$CLJS.Q,[$CLJS.Jr,$CLJS.tG,$CLJS.Bi,$CLJS.mk,$CLJS.rI,$CLJS.MI,$CLJS.NI,$CLJS.Hj,$CLJS.Hi,$CLJS.xi,$CLJS.jj,$CLJS.qG],null));$CLJS.X(yI,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.Jr,$CLJS.lI,$CLJS.uI,$CLJS.zI],null));
$CLJS.UE($CLJS.HI,$CLJS.H([$CLJS.Ds,$CLJS.xj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.wI,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.nk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,$CLJS.pE],null)],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ti,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.nk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,kI],null)],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.zi,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.sr,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.nk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,yI],null)],null)],
null)],null)]));