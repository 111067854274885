var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./metabase.lib.convert.js");require("./metabase.lib.metadata.js");require("./metabase.lib.metadata.calculation.js");require("./metabase.lib.query.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.id.js");require("./metabase.lib.schema.metadata.js");require("./metabase.lib.util.js");require("./metabase.shared.util.i18n.js");require("./metabase.util.js");require("./metabase.util.humanization.js");require("./metabase.util.malli.js");
'use strict';$CLJS.h1=function(a,b){var c=$CLJS.cW(b);return $CLJS.n(c)?$CLJS.y_(a,c):$CLJS.w_(a,b)};$CLJS.i1=function(a){var b=$CLJS.xB(a);b=b instanceof $CLJS.M?b.S:null;switch(b){case "dispatch-type/nil":return null;case "field":return $CLJS.QO.h($CLJS.aE(a));case "metadata/column":return $CLJS.yZ.h(a);case "mbql/join":return $CLJS.qE.h(a);default:throw Error(["No matching clause: ",$CLJS.p.h(b)].join(""));}};
$CLJS.j1=function(a,b){return $CLJS.fa.j?$CLJS.fa.j("%s__%s",a,b):$CLJS.fa.call(null,"%s__%s",a,b)};$CLJS.k1=function(a,b){var c=$CLJS.i1(b);if($CLJS.n(c))a=c;else a:{var d=$CLJS.O(b);c=$CLJS.J.g(d,$CLJS.wK);d=$CLJS.J.g(d,$CLJS.xK);if($CLJS.n($CLJS.n(c)?d:c)&&(d=$CLJS.h1(a,d),$CLJS.n(d))){d=$CLJS.T.h(d);a=$CLJS.T.h($CLJS.$0(a,c));a=$CLJS.fa.j?$CLJS.fa.j("%s__via__%s",d,a):$CLJS.fa.call(null,"%s__via__%s",d,a);break a}a=null}return $CLJS.n(a)?$CLJS.j1(a,$CLJS.T.h(b)):$CLJS.T.h(b)};
$CLJS.l1=function(a,b,c,d){var e=$CLJS.fg.j($CLJS.ch,$CLJS.jf.h($CLJS.xK),c);return $CLJS.fg.j($CLJS.Df,$CLJS.Qk.l($CLJS.lf($CLJS.uK),$CLJS.jf.h(function(f){f=$CLJS.O(f);var k=$CLJS.J.g(f,$CLJS.$i),l=$CLJS.J.g(f,$CLJS.uK);return $CLJS.R.l($CLJS.$0(a,l),$CLJS.T_,k,$CLJS.H([$CLJS.U_,$CLJS.yZ.h(f)]))}),$CLJS.Rk.h(function(f){return $CLJS.Hd(e,$CLJS.xK.h(f))}),$CLJS.H([$CLJS.CD(function(f){f=$CLJS.O(f);var k=$CLJS.J.g(f,$CLJS.xK),l=$CLJS.J.g(f,$CLJS.T_),m=$CLJS.J.g(f,$CLJS.U_);f=$CLJS.w_(a,k);return function v(u){return new $CLJS.oe(null,
function(){for(;;){var x=$CLJS.y(u);if(x){if($CLJS.Ad(x)){var A=$CLJS.lc(x),C=$CLJS.D(A),G=$CLJS.re(C);return function(){for(var W=0;;)if(W<C){var Z=$CLJS.kd(A,W),ha=$CLJS.R.l(Z,$CLJS.wK,l,$CLJS.H([$CLJS.Q_,m,$CLJS.iK,$CLJS.eZ,$CLJS.UY,$CLJS.T.h(Z)]));Z=G;var ua=$CLJS.R,Ta=ua.j,Cb=ha;ha=$CLJS.k1(a,ha);ha=d.h?d.h(ha):d.call(null,ha);ua=Ta.call(ua,Cb,$CLJS.iZ,ha);Z.add(ua);W+=1}else return!0}()?$CLJS.ue($CLJS.we(G),v($CLJS.mc(x))):$CLJS.ue($CLJS.we(G),null)}var K=$CLJS.z(x),S=$CLJS.R.l(K,$CLJS.wK,l,
$CLJS.H([$CLJS.Q_,m,$CLJS.iK,$CLJS.eZ,$CLJS.UY,$CLJS.T.h(K)]));return $CLJS.fe($CLJS.R.j(S,$CLJS.iZ,function(){var W=$CLJS.k1(a,S);return d.h?d.h(W):d.call(null,W)}()),v($CLJS.Lc(x)))}return null}},null,null)}($CLJS.m0.v(a,b,f,new $CLJS.h(null,2,[$CLJS.Y_,d,$CLJS.S_,!1],null)))})])),c)};$CLJS.m1=function(a,b){return $CLJS.c1.g(a,b)};$CLJS.n1=function(a){return $CLJS.DD("Question {0}",$CLJS.H([$CLJS.Nh.l($CLJS.H([a]))]))};
$CLJS.o1=function(a,b){var c=$CLJS.mE.h(b);$CLJS.n(c)||(c=$CLJS.oZ.h(b),$CLJS.n(c)||(c=$CLJS.nZ.h(b),c=null!=c?$CLJS.l0.h($CLJS.m1(a,$CLJS.vY.h(c))):null));return $CLJS.n(c)?(c=$CLJS.Ge($CLJS.xd(c)?$CLJS.ZV.h(c):$CLJS.wd(c)?c:null),$CLJS.n(c)?$CLJS.Sk.g($CLJS.Bt(Roa,a,b),c):null):null};$CLJS.p1=new $CLJS.M("metabase.lib.card","force-broken-id-refs","metabase.lib.card/force-broken-id-refs",-868945458);$CLJS.q1=new $CLJS.M(null,"field-ref","field-ref",-1699303428);$CLJS.c0.m(null,$CLJS.GK,function(a,b,c){return $CLJS.$z($CLJS.ID,$CLJS.T)(c)});$CLJS.i0.m(null,$CLJS.GK,function(a,b,c){a=$CLJS.O(c);b=$CLJS.J.g(a,$CLJS.T);c=$CLJS.J.g(a,$CLJS.ID);return $CLJS.Ua(c)?$CLJS.R.j(a,$CLJS.ID,$CLJS.g1.g($CLJS.f1,b)):a});
$CLJS.m0.m(null,$CLJS.GK,function(a,b,c,d){c=$CLJS.O(c);var e=$CLJS.J.g(c,$CLJS.mE),f=$CLJS.J.g(c,$CLJS.oZ);d=$CLJS.O(d);var k=$CLJS.J.g(d,$CLJS.S_),l=$CLJS.J.g(d,$CLJS.Y_);return $CLJS.hf.g($CLJS.l0.v(a,b,c,d),$CLJS.n(k)?$CLJS.l1(a,b,$CLJS.hf.g(e,f),l):null)});$CLJS.f0.m(null,$CLJS.kV,function(a,b){var c=$CLJS.bW(a,b);c=$CLJS.O(c);c=$CLJS.J.g(c,$CLJS.kV);if($CLJS.n(c)){var d=$CLJS.y_(a,c);a=$CLJS.n(d)?$CLJS.D_.v(a,b,d,$CLJS.E_):null;return $CLJS.n(a)?a:$CLJS.n1(c)}return null});
var Roa=function(){function a(d,e,f){var k=$CLJS.Ik.g($CLJS.dA(f,$CLJS.EA),$CLJS.q1);return $CLJS.hl.l($CLJS.H([new $CLJS.h(null,2,[$CLJS.TA,$CLJS.Bj,$CLJS.Fj,$CLJS.gK],null),function(){var l=$CLJS.$i.h(k);if($CLJS.n(l))try{return $CLJS.$0(d,l)}catch(m){return null}else return null}(),k,new $CLJS.h(null,3,[$CLJS.Fj,$CLJS.gK,$CLJS.iK,$CLJS.lZ,$CLJS.UY,$CLJS.$z($CLJS.UY,$CLJS.T)(k)],null),$CLJS.n(e)?new $CLJS.h(null,1,[$CLJS.vZ,$CLJS.W0(e)],null):null,$CLJS.n($CLJS.n(!0)?$CLJS.Ua(e)||$CLJS.Ua($CLJS.aZ.h($CLJS.y_(d,
$CLJS.W0(e)))):!0)?new $CLJS.h(null,1,[$CLJS.p1,!0],null):null]))}function b(d,e){return c.j?c.j(d,null,e):c.call(null,d,null,e)}var c=null;c=function(d,e,f){switch(arguments.length){case 2:return b.call(this,d,e);case 3:return a.call(this,d,e,f)}throw Error("Invalid arity: "+arguments.length);};c.g=b;c.j=a;return c}();
$CLJS.k0.m(null,$CLJS.GK,function(a,b,c,d){b=$CLJS.O(d);var e=$CLJS.J.g(b,$CLJS.Y_);return $CLJS.Sk.g(function(f){var k=$CLJS.KB($CLJS.iZ,$CLJS.UY,$CLJS.T)(f);return $CLJS.R.j(f,$CLJS.iZ,e.h?e.h(k):e.call(null,k))},$CLJS.o1(a,c))});