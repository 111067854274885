var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.string.js");require("./metabase.types.js");require("./metabase.util.malli.registry.js");
'use strict';var Ofa,KD,OD,ZD;
$CLJS.BD=function(a,b){return function(){function c(l,m,t){return $CLJS.Ed(function(){var u=a.h?a.h(l):a.call(null,l);return $CLJS.n(u)?(u=a.h?a.h(m):a.call(null,m),$CLJS.n(u)?(u=a.h?a.h(t):a.call(null,t),$CLJS.n(u)?(u=b.h?b.h(l):b.call(null,l),$CLJS.n(u)?(u=b.h?b.h(m):b.call(null,m),$CLJS.n(u)?b.h?b.h(t):b.call(null,t):u):u):u):u):u}())}function d(l,m){return $CLJS.Ed(function(){var t=a.h?a.h(l):a.call(null,l);return $CLJS.n(t)?(t=a.h?a.h(m):a.call(null,m),$CLJS.n(t)?(t=b.h?b.h(l):b.call(null,l),
$CLJS.n(t)?b.h?b.h(m):b.call(null,m):t):t):t}())}function e(l){var m=a.h?a.h(l):a.call(null,l);l=$CLJS.n(m)?b.h?b.h(l):b.call(null,l):m;return $CLJS.Ed(l)}var f=null,k=function(){function l(t,u,v,x){var A=null;if(3<arguments.length){A=0;for(var C=Array(arguments.length-3);A<C.length;)C[A]=arguments[A+3],++A;A=new $CLJS.w(C,0,null)}return m.call(this,t,u,v,A)}function m(t,u,v,x){return $CLJS.Ed(function(){var A=f.j(t,u,v);return $CLJS.n(A)?$CLJS.Re(function(C){var G=a.h?a.h(C):a.call(null,C);return $CLJS.n(G)?
b.h?b.h(C):b.call(null,C):G},x):A}())}l.A=3;l.B=function(t){var u=$CLJS.z(t);t=$CLJS.B(t);var v=$CLJS.z(t);t=$CLJS.B(t);var x=$CLJS.z(t);t=$CLJS.Lc(t);return m(u,v,x,t)};l.l=m;return l}();f=function(l,m,t,u){switch(arguments.length){case 0:return!0;case 1:return e.call(this,l);case 2:return d.call(this,l,m);case 3:return c.call(this,l,m,t);default:var v=null;if(3<arguments.length){v=0;for(var x=Array(arguments.length-3);v<x.length;)x[v]=arguments[v+3],++v;v=new $CLJS.w(x,0,null)}return k.l(l,m,t,
v)}throw Error("Invalid arity: "+arguments.length);};f.A=3;f.B=k.B;f.o=function(){return!0};f.h=e;f.g=d;f.j=c;f.l=k.l;return f}()};$CLJS.CD=function(a){return $CLJS.Qk.g($CLJS.jf.h(a),$CLJS.LB)};$CLJS.DD=function(a,b){a=$CLJS.rt($CLJS.fA(a,/''/,"'"),/\{\d+\}/);return $CLJS.Te.j($CLJS.kt.t,$CLJS.kl(a),$CLJS.kl(b))};$CLJS.ED=new $CLJS.M("lib","metadata","lib/metadata",1798917220);$CLJS.FD=new $CLJS.M("metabase.lib.schema.common","positive-int","metabase.lib.schema.common/positive-int",-733946956);
$CLJS.GD=new $CLJS.M(null,"num-bins","num-bins",847672055);$CLJS.HD=new $CLJS.M(null,"mbql","mbql",69346710);$CLJS.ID=new $CLJS.M(null,"display-name","display-name",694513143);Ofa=new $CLJS.M("metabase.lib.schema.common","external-op","metabase.lib.schema.common/external-op",1918684841);$CLJS.JD=new $CLJS.M("metabase.lib.schema.common","semantic-or-relation-type","metabase.lib.schema.common/semantic-or-relation-type",-158604052);
KD=new $CLJS.M("metabase.lib.schema.common","relation-type","metabase.lib.schema.common/relation-type",1412287664);$CLJS.LD=new $CLJS.M("lib","external-op","lib/external-op",-1470923877);$CLJS.MD=new $CLJS.M("lib","expression-name","lib/expression-name",-1799326590);$CLJS.ND=new $CLJS.M("metabase.lib.schema.common","non-blank-string","metabase.lib.schema.common/non-blank-string",-2032366619);OD=new $CLJS.M("metabase.lib.schema.common","semantic-type","metabase.lib.schema.common/semantic-type",-1623124128);
$CLJS.PD=new $CLJS.M(null,"database-type","database-type",-426840562);$CLJS.QD=new $CLJS.M(null,"operator","operator",-1860875338);$CLJS.RD=new $CLJS.M("metabase.lib.schema.common","int-greater-than-or-equal-to-zero","metabase.lib.schema.common/int-greater-than-or-equal-to-zero",-1646435681);$CLJS.q4=new $CLJS.M("metabase.lib.schema.binning","bin-width","metabase.lib.schema.binning/bin-width",538632740);
$CLJS.SD=new $CLJS.M("metabase.lib.schema.common","options","metabase.lib.schema.common/options",-1078359294);$CLJS.TD=new $CLJS.M("metabase.lib.schema.common","positive-number","metabase.lib.schema.common/positive-number",2061626826);$CLJS.UD=new $CLJS.M("lib","uuid","lib/uuid",-2145250720);$CLJS.VD=new $CLJS.M(null,"x","x",2099068185);$CLJS.WD=new $CLJS.M("metabase.lib.schema.common","base-type","metabase.lib.schema.common/base-type",999524206);
$CLJS.XD=new $CLJS.M(null,"bin-width","bin-width",1377922579);$CLJS.YD=new $CLJS.M(null,"strategy","strategy",-1471631918);ZD=new $CLJS.M("metabase.lib.schema.common","uuid","metabase.lib.schema.common/uuid",1172938098);$CLJS.X($CLJS.ND,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Tr,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ak,new $CLJS.h(null,1,[$CLJS.Rn,1],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Si,new $CLJS.h(null,1,[$CLJS.Ys,"non-blank string"],null),$CLJS.Ue($CLJS.gA)],null)],null));$CLJS.X($CLJS.RD,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Yr,new $CLJS.h(null,1,[$CLJS.Rn,0],null)],null));$CLJS.X($CLJS.FD,$CLJS.Ql);
$CLJS.X($CLJS.TD,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Si,new $CLJS.h(null,1,[$CLJS.Ys,"positive number"],null),$CLJS.BD($CLJS.Cl,$CLJS.Vl)],null));$CLJS.X(ZD,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ak,new $CLJS.h(null,2,[$CLJS.Rn,36,$CLJS.jk,36],null)],null));
$CLJS.X(OD,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Si,new $CLJS.h(null,2,[$CLJS.Ys,"valid semantic type",$CLJS.it,function(a){a=$CLJS.O(a);a=$CLJS.J.g(a,$CLJS.Ej);return["Not a valid semantic type: ",$CLJS.Nh.l($CLJS.H([a]))].join("")}],null),function(a){return $CLJS.MB(a,$CLJS.tj)}],null));
$CLJS.X(KD,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Si,new $CLJS.h(null,2,[$CLJS.Ys,"valid relation type",$CLJS.it,function(a){a=$CLJS.O(a);a=$CLJS.J.g(a,$CLJS.Ej);return["Not a valid relation type: ",$CLJS.Nh.l($CLJS.H([a]))].join("")}],null),function(a){return $CLJS.MB(a,$CLJS.oi)}],null));$CLJS.X($CLJS.JD,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Mr,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,OD],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,KD],null)],null));
$CLJS.X($CLJS.WD,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Si,new $CLJS.h(null,2,[$CLJS.Ys,"valid base type",$CLJS.it,function(a){a=$CLJS.O(a);a=$CLJS.J.g(a,$CLJS.Ej);return["Not a valid base type: ",$CLJS.Nh.l($CLJS.H([a]))].join("")}],null),function(a){return $CLJS.MB(a,$CLJS.Bj)}],null));
$CLJS.X($CLJS.SD,new $CLJS.P(null,8,5,$CLJS.Q,[$CLJS.ik,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.UD,ZD],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.TA,new $CLJS.h(null,1,[$CLJS.fs,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Lr,$CLJS.WD],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Gi,new $CLJS.h(null,1,[$CLJS.fs,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Lr,$CLJS.WD],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Wj,new $CLJS.h(null,1,[$CLJS.fs,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Lr,
$CLJS.JD],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.PD,new $CLJS.h(null,1,[$CLJS.fs,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Lr,$CLJS.ND],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.T,new $CLJS.h(null,1,[$CLJS.fs,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Lr,$CLJS.ND],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ID,new $CLJS.h(null,1,[$CLJS.fs,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Lr,$CLJS.ND],null)],null)],null));
$CLJS.X(Ofa,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.ik,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Fj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.or,$CLJS.LD],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.QD,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Mr,$CLJS.ak,$CLJS.Yi],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ps,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Aj,$CLJS.mi],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.gj,new $CLJS.h(null,1,[$CLJS.fs,!0],null),$CLJS.SD],null)],null));