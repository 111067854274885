var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.set.js");
'use strict';var Ct,tca,uca,Ft,vca,It,Jt;
$CLJS.Bt=function(a,b,c){return function(){function d(t,u,v){return a.N?a.N(b,c,t,u,v):a.call(null,b,c,t,u,v)}function e(t,u){return a.v?a.v(b,c,t,u):a.call(null,b,c,t,u)}function f(t){return a.j?a.j(b,c,t):a.call(null,b,c,t)}function k(){return a.g?a.g(b,c):a.call(null,b,c)}var l=null,m=function(){function t(v,x,A,C){var G=null;if(3<arguments.length){G=0;for(var K=Array(arguments.length-3);G<K.length;)K[G]=arguments[G+3],++G;G=new $CLJS.w(K,0,null)}return u.call(this,v,x,A,G)}function u(v,x,A,C){return $CLJS.Te.l(a,
b,c,v,x,$CLJS.H([A,C]))}t.A=3;t.B=function(v){var x=$CLJS.z(v);v=$CLJS.B(v);var A=$CLJS.z(v);v=$CLJS.B(v);var C=$CLJS.z(v);v=$CLJS.Lc(v);return u(x,A,C,v)};t.l=u;return t}();l=function(t,u,v,x){switch(arguments.length){case 0:return k.call(this);case 1:return f.call(this,t);case 2:return e.call(this,t,u);case 3:return d.call(this,t,u,v);default:var A=null;if(3<arguments.length){A=0;for(var C=Array(arguments.length-3);A<C.length;)C[A]=arguments[A+3],++A;A=new $CLJS.w(C,0,null)}return m.l(t,u,v,A)}throw Error("Invalid arity: "+
arguments.length);};l.A=3;l.B=m.B;l.o=k;l.h=f;l.g=e;l.j=d;l.l=m.l;return l}()};Ct=function(a,b){return $CLJS.E.g(a,b)?new $CLJS.P(null,3,5,$CLJS.Q,[null,null,a],null):new $CLJS.P(null,3,5,$CLJS.Q,[a,b,null],null)};tca=function(a){return $CLJS.y(a)?$CLJS.eb(function(b,c){var d=$CLJS.I(c,0,null);c=$CLJS.I(c,1,null);return $CLJS.R.j(b,d,c)},$CLJS.Ef($CLJS.ef($CLJS.Te.g($CLJS.Mk,$CLJS.Yg(a)),null)),a):null};
uca=function(a,b,c){var d=$CLJS.J.g(a,c),e=$CLJS.J.g(b,c),f=$CLJS.Dt(d,e),k=$CLJS.I(f,0,null),l=$CLJS.I(f,1,null);f=$CLJS.I(f,2,null);a=$CLJS.Hd(a,c);b=$CLJS.Hd(b,c);d=a&&b&&(null!=f||null==d&&null==e);return new $CLJS.P(null,3,5,$CLJS.Q,[!a||null==k&&d?null:$CLJS.Fe([c,k]),!b||null==l&&d?null:$CLJS.Fe([c,l]),d?$CLJS.Fe([c,f]):null],null)};
Ft=function(a,b){var c=$CLJS.jf.g,d=Et.j,e=$CLJS.zd(a)?a:$CLJS.Ef(a),f=$CLJS.zd(b)?b:$CLJS.Ef(b);a=$CLJS.D(a);b=$CLJS.D(b);return $CLJS.Ef(c.call($CLJS.jf,tca,d.call(Et,e,f,$CLJS.mh(0,a>b?a:b))))};vca=function(a,b){return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Ge($CLJS.Gt.g(a,b)),$CLJS.Ge($CLJS.Gt.g(b,a)),$CLJS.Ge($CLJS.Ht.g(a,b))],null)};
It=function(a){if(null!=a&&null!=a.hh)a=a.hh(a);else{var b=It[$CLJS.wa(null==a?null:a)];if(null!=b)a=b.h?b.h(a):b.call(null,a);else if(b=It._,null!=b)a=b.h?b.h(a):b.call(null,a);else throw $CLJS.bb("EqualityPartition.equality-partition",a);}return a};Jt=function(a,b){if(null!=a&&null!=a.gh)a=a.gh(a,b);else{var c=Jt[$CLJS.wa(null==a?null:a)];if(null!=c)a=c.g?c.g(a,b):c.call(null,a,b);else if(c=Jt._,null!=c)a=c.g?c.g(a,b):c.call(null,a,b);else throw $CLJS.bb("Diff.diff-similar",a);}return a};
$CLJS.Dt=function(a,b){return $CLJS.E.g(a,b)?new $CLJS.P(null,3,5,$CLJS.Q,[null,null,a],null):$CLJS.E.g(It(a),It(b))?Jt(a,b):Ct(a,b)};$CLJS.Ht=function Ht(a){switch(arguments.length){case 1:return Ht.h(arguments[0]);case 2:return Ht.g(arguments[0],arguments[1]);default:for(var c=[],d=arguments.length,e=0;;)if(e<d)c.push(arguments[e]),e+=1;else break;return Ht.l(arguments[0],arguments[1],new $CLJS.w(c.slice(2),0,null))}};$CLJS.Ht.h=function(a){return a};
$CLJS.Ht.g=function(a,b){for(;;)if($CLJS.D(b)<$CLJS.D(a)){var c=a;a=b;b=c}else return $CLJS.eb(function(d,e){return function(f,k){return $CLJS.Hd(e,k)?f:$CLJS.Jk.g(f,k)}}(a,b),a,a)};$CLJS.Ht.l=function(a,b,c){a=$CLJS.yt(function(d){return-$CLJS.D(d)},$CLJS.ce.l(c,b,$CLJS.H([a])));return $CLJS.eb($CLJS.Ht,$CLJS.z(a),$CLJS.Lc(a))};$CLJS.Ht.B=function(a){var b=$CLJS.z(a),c=$CLJS.B(a);a=$CLJS.z(c);c=$CLJS.B(c);return this.l(b,a,c)};$CLJS.Ht.A=2;
$CLJS.Gt=function Gt(a){switch(arguments.length){case 1:return Gt.h(arguments[0]);case 2:return Gt.g(arguments[0],arguments[1]);default:for(var c=[],d=arguments.length,e=0;;)if(e<d)c.push(arguments[e]),e+=1;else break;return Gt.l(arguments[0],arguments[1],new $CLJS.w(c.slice(2),0,null))}};$CLJS.Gt.h=function(a){return a};$CLJS.Gt.g=function(a,b){return $CLJS.D(a)<$CLJS.D(b)?$CLJS.eb(function(c,d){return $CLJS.Hd(b,d)?$CLJS.Jk.g(c,d):c},a,a):$CLJS.eb($CLJS.Jk,a,b)};
$CLJS.Gt.l=function(a,b,c){return $CLJS.eb($CLJS.Gt,a,$CLJS.ce.g(c,b))};$CLJS.Gt.B=function(a){var b=$CLJS.z(a),c=$CLJS.B(a);a=$CLJS.z(c);c=$CLJS.B(c);return this.l(b,a,c)};$CLJS.Gt.A=2;var Kt=new $CLJS.M(null,"atom","atom",-397043653);var Et=function Et(a){switch(arguments.length){case 2:return Et.g(arguments[0],arguments[1]);case 3:return Et.j(arguments[0],arguments[1],arguments[2]);default:throw Error(["Invalid arity: ",$CLJS.p.h(arguments.length)].join(""));}};Et.g=function(a,b){return Et.j(a,b,$CLJS.At.g($CLJS.Yg(a),$CLJS.Yg(b)))};Et.j=function(a,b,c){return $CLJS.eb(function(d,e){return $CLJS.nh($CLJS.jf.j($CLJS.hl,d,e))},new $CLJS.P(null,3,5,$CLJS.Q,[null,null,null],null),$CLJS.jf.g($CLJS.Bt(uca,a,b),c))};Et.A=3;
It["null"]=function(){return Kt};It.string=function(){return Kt};It.number=function(){return Kt};It.array=function(){return $CLJS.Aj};It["function"]=function(){return Kt};It["boolean"]=function(){return Kt};
It._=function(a){return(null!=a?a.C&1024||$CLJS.Bc===a.Vg||(a.C?0:$CLJS.$a($CLJS.Bb,a)):$CLJS.$a($CLJS.Bb,a))?$CLJS.ik:(null!=a?a.C&4096||$CLJS.Bc===a.Zg||(a.C?0:$CLJS.$a($CLJS.Gb,a)):$CLJS.$a($CLJS.Gb,a))?$CLJS.aj:(null!=a?a.C&16777216||$CLJS.Bc===a.pf||(a.C?0:$CLJS.$a($CLJS.Wb,a)):$CLJS.$a($CLJS.Wb,a))?$CLJS.Aj:Kt};Jt["null"]=function(a,b){return Ct(a,b)};Jt.string=function(a,b){return Ct(a,b)};Jt.number=function(a,b){return Ct(a,b)};Jt.array=function(a,b){return Ft(a,b)};
Jt["function"]=function(a,b){return Ct(a,b)};Jt["boolean"]=function(a,b){return Ct(a,b)};Jt._=function(a,b){var c=function(){var d=It(a);d=d instanceof $CLJS.M?d.S:null;switch(d){case "atom":return Ct;case "set":return vca;case "sequential":return Ft;case "map":return Et;default:throw Error(["No matching clause: ",$CLJS.p.h(d)].join(""));}}();return c.g?c.g(a,b):c.call(null,a,b)};