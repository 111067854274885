var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./medley.core.js");require("./metabase.lib.aggregation.js");require("./metabase.lib.binning.js");require("./metabase.lib.convert.js");require("./metabase.lib.drill_thru.common.js");require("./metabase.lib.filter.js");require("./metabase.lib.metadata.js");require("./metabase.lib.metadata.calculation.js");require("./metabase.lib.options.js");require("./metabase.lib.schema.js");require("./metabase.lib.schema.drill_thru.js");require("./metabase.lib.schema.metadata.js");require("./metabase.lib.temporal_bucket.js");require("./metabase.lib.types.isa.js");require("./metabase.lib.underlying.js");require("./metabase.lib.util.js");require("./metabase.util.malli.js");
'use strict';var l7,n7,p7,ksa,r7,s7,lsa;l7=function(a){if("string"===typeof a)return $CLJS.oh(/[\x00-\x20]*[+-]?NaN[\x00-\x20]*/,a)?NaN:$CLJS.oh(/[\x00-\x20]*[+-]?(Infinity|((\d+\.?\d*|\.\d+)([eE][+-]?\d+)?)[dDfF]?)[\x00-\x20]*/,a)?parseFloat(a):null;throw Error($CLJS.km(a));};$CLJS.m7=function(a){return $CLJS.Ya(a)?$CLJS.mz(a,$CLJS.H([$CLJS.Ai,!0])):a};
n7=function(a,b){if(null!=a&&null!=a.yg)a=a.yg(a,b);else{var c=n7[$CLJS.wa(null==a?null:a)];if(null!=c)a=c.g?c.g(a,b):c.call(null,a,b);else if(c=n7._,null!=c)a=c.g?c.g(a,b):c.call(null,a,b);else throw $CLJS.bb("MetadataProvider.setting",a);}return a};$CLJS.o7=function(a,b){return n7($CLJS.v_(a),b)};p7=function(a,b,c){a=(b-a)/c;if(null!=a)a=Math.ceil(a);else throw $CLJS.ii("Unexpected Null passed to ceil",new $CLJS.h(null,1,[$CLJS.Si,"ceil"],null));a=$CLJS.Vd(a);return 1<a?a:1};
ksa=function(a,b,c){var d=l7(((b-a)/c).toFixed(5)),e=Math.pow(10,0===d?0:Math.floor(Math.log($CLJS.Zz(d))/Math.log(10)));return $CLJS.Se(function(f){f*=e;return f>=d?f:null},$CLJS.Zqa)};
$CLJS.q7=function(a,b,c){var d=$CLJS.V6(b);if($CLJS.n(d)){var e=$CLJS.YD.h(d);e=e instanceof $CLJS.M?e.S:null;switch(e){case "num-bins":e=$CLJS.bA(b,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.oN,$CLJS.nj,$CLJS.Qj],null));if($CLJS.n(e)){a=$CLJS.O(e);e=$CLJS.J.g(a,$CLJS.Rn);var f=$CLJS.J.g(a,$CLJS.jk);a=$CLJS.O(d);a=$CLJS.J.g(a,$CLJS.GD);a=ksa(e,f,a);return new $CLJS.h(null,3,[$CLJS.XD,a,$CLJS.cL,c,$CLJS.fL,c+a],null)}return null;case "bin-width":return a=$CLJS.O(d),a=$CLJS.J.g(a,$CLJS.XD),new $CLJS.h(null,
3,[$CLJS.XD,a,$CLJS.cL,c,$CLJS.fL,c+a],null);case "default":e=$CLJS.bA(b,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.oN,$CLJS.nj,$CLJS.Qj],null));if($CLJS.n(e)){d=$CLJS.O(e);e=$CLJS.J.g(d,$CLJS.Rn);f=$CLJS.J.g(d,$CLJS.jk);a:switch(d=e,e=f,f=$CLJS.di instanceof $CLJS.M?$CLJS.di.S:null,f){case "num-bins":a=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.GD,new $CLJS.h(null,2,[$CLJS.GD,null,$CLJS.XD,l7(((e-d)/0).toFixed(5))],null)],null);break a;case "bin-width":a=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.XD,new $CLJS.h(null,
2,[$CLJS.XD,null,$CLJS.GD,p7(d,e,null)],null)],null);break a;case "default":$CLJS.k_(b)?(a=$CLJS.o7(a,$CLJS.Yqa),a=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.XD,new $CLJS.h(null,2,[$CLJS.XD,a,$CLJS.GD,p7(d,e,a)],null)],null)):(a=$CLJS.o7(a,$CLJS.Xqa),a=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.GD,new $CLJS.h(null,2,[$CLJS.GD,a,$CLJS.XD,l7(((e-d)/a).toFixed(5))],null)],null));break a;default:throw Error(["No matching clause: ",$CLJS.p.h(f)].join(""));}if($CLJS.n(a))return $CLJS.I(a,0,null),a=$CLJS.I(a,1,null),
a=$CLJS.O(a),a=$CLJS.J.g(a,$CLJS.XD),new $CLJS.h(null,3,[$CLJS.XD,a,$CLJS.cL,c,$CLJS.fL,c+a],null)}return null;default:throw Error(["No matching clause: ",$CLJS.p.h(e)].join(""));}}else return null};r7=function(a){var b=$CLJS.Gz.h($CLJS.Z0(a));b=b.h?b.h($CLJS.j7):b.call(null,$CLJS.j7);if($CLJS.n(b))a:for(b=a;;)if($CLJS.td($CLJS.e4.g(b,-1))&&$CLJS.td($CLJS.w5.g(b,-1))){if(b=$CLJS.Tk.j(b,$CLJS.PK,$CLJS.sd),!$CLJS.y($CLJS.PK.h(b))){b=null;break a}}else break a;else b=null;return $CLJS.n(b)?b:a};
s7=function(a,b){var c=r7(a);if($CLJS.E.g(a,c))return b;for(;;){if($CLJS.E.g(a,c))return $CLJS.WU(b,new $CLJS.h(null,2,[$CLJS.fW,$CLJS.k7,$CLJS.lW,$CLJS.jsa],null));var d=$CLJS.l0,e=d.j,f=a;var k=a;var l=$CLJS.v1(k,-1);k=$CLJS.n(l)?$CLJS.bW(k,l):null;d=e.call(d,f,-2,k);b=$CLJS.H1.v(a,-2,$CLJS.NV(b),d);if($CLJS.n(b))a=$CLJS.Tk.j(a,$CLJS.PK,$CLJS.sd);else return null}};
lsa=function(a,b,c){var d=function(){var e=$CLJS.n($CLJS.V6(b))?function(){var f=$CLJS.T4(b,null);if(null!=c){var k=$CLJS.q7(a,b,c);if($CLJS.n(k)){var l=$CLJS.O(k);k=$CLJS.J.g(l,$CLJS.cL);l=$CLJS.J.g(l,$CLJS.fL);return new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.u5(f,k),$CLJS.t5(f,l)],null)}return null}return new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.u1($CLJS.fF,new $CLJS.P(null,1,5,$CLJS.Q,[f],null))],null)}():null;if($CLJS.n(e))return e;e=function(){var f=$CLJS.k7.h(b);return $CLJS.n(f)?$CLJS.d3(b,f):b}();return new $CLJS.P(null,
1,5,$CLJS.Q,[(0,$CLJS.Y2)(e,c)],null)}();return $CLJS.eb(function(e,f){return $CLJS.Z2.j(e,-1,f)},a,d)};
$CLJS.t7=function(a,b){b=$CLJS.O(b);var c=$CLJS.J.g(b,$CLJS.L5),d=$CLJS.J.g(b,$CLJS.dL);b=$CLJS.nW.l(a,-1,$CLJS.Ik,$CLJS.H([$CLJS.uF,$CLJS.tF,$CLJS.kR,$CLJS.HR,$CLJS.mE]));b=$CLJS.eb(function(f,k){var l=$CLJS.O(k);k=$CLJS.J.g(l,$CLJS.Ui);l=$CLJS.J.g(l,$CLJS.Ej);return lsa(f,k,l)},b,function(){return function l(k){return new $CLJS.oe(null,function(){for(var m=k;;)if(m=$CLJS.y(m)){if($CLJS.Ad(m)){var t=$CLJS.lc(m),u=$CLJS.D(t),v=$CLJS.re(u);a:for(var x=0;;)if(x<u){var A=$CLJS.kd(t,x);$CLJS.Ok.g($CLJS.iK.h($CLJS.Ui.h(A)),
$CLJS.IK)&&v.add(A);x+=1}else{t=!0;break a}return t?$CLJS.ue($CLJS.we(v),l($CLJS.mc(m))):$CLJS.ue($CLJS.we(v),null)}v=$CLJS.z(m);if($CLJS.Ok.g($CLJS.iK.h($CLJS.Ui.h(v)),$CLJS.IK))return $CLJS.fe(v,l($CLJS.Lc(m)));m=$CLJS.Lc(m)}else return null},null,null)}(d)}());var e=function(){var f=$CLJS.id(c);return $CLJS.n(f)?$CLJS.QG(function(k){return $CLJS.E.g($CLJS.b5(k),f)},$CLJS.e4.g(a,-1)):null}();return $CLJS.eb(function(f,k){return $CLJS.Z2.j(f,-1,k)},b,$CLJS.n(e)?function(){var f=$CLJS.z(e);switch(f instanceof
$CLJS.M?f.S:null){case "sum-where":case "count-where":case "share":return new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.id(e)],null);case "metric":return $CLJS.$2.g($CLJS.R.j($CLJS.vY.h((0,$CLJS.$X)($CLJS.t1($CLJS.m7(new $CLJS.h(null,2,[$CLJS.nj,$CLJS.aF,$CLJS.aF,$CLJS.m7($CLJS.dZ.h($CLJS.z_(a,$CLJS.id(e))))],null)),$CLJS.nj,$CLJS.aF))),$CLJS.qP,$CLJS.qP.h(a)),-1);default:return null}}():null)};$CLJS.E5.m(null,$CLJS.D6,function(a,b,c){b=$CLJS.O(c);a=$CLJS.J.g(b,$CLJS.x6);b=$CLJS.J.g(b,$CLJS.kZ);return new $CLJS.h(null,3,[$CLJS.nj,$CLJS.D6,$CLJS.x6,a,$CLJS.kZ,b],null)});
$CLJS.D5.m(null,$CLJS.D6,function(){function a(c,d,e,f){var k=null;if(3<arguments.length){k=0;for(var l=Array(arguments.length-3);k<l.length;)l[k]=arguments[k+3],++k;k=new $CLJS.w(l,0,null)}return b.call(this,c,d,e,k)}function b(c,d,e){return $CLJS.t7(r7(c),$CLJS.Tk.j(e,$CLJS.dL,function(f){return function m(l){return new $CLJS.oe(null,function(){for(;;){var t=$CLJS.y(l);if(t){var u=t;if($CLJS.Ad(u)){var v=$CLJS.lc(u),x=$CLJS.D(v),A=$CLJS.re(x);return function(){for(var G=0;;)if(G<x){var K=$CLJS.kd(v,
G);$CLJS.ve(A,$CLJS.Tk.j(K,$CLJS.Ui,function(){return function(S){return s7(c,S)}}(G,K,v,x,A,u,t)));G+=1}else return!0}()?$CLJS.ue($CLJS.we(A),m($CLJS.mc(u))):$CLJS.ue($CLJS.we(A),null)}var C=$CLJS.z(u);return $CLJS.fe($CLJS.Tk.j(C,$CLJS.Ui,function(){return function(G){return s7(c,G)}}(C,u,t)),m($CLJS.Lc(u)))}return null}},null,null)}(f)}))}a.A=3;a.B=function(c){var d=$CLJS.z(c);c=$CLJS.B(c);var e=$CLJS.z(c);c=$CLJS.B(c);var f=$CLJS.z(c);c=$CLJS.Lc(c);return b(d,e,f,c)};a.l=b;return a}());