var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./metabase.lib.drill_thru.automatic_insights.js");require("./metabase.lib.drill_thru.column_filter.js");require("./metabase.lib.drill_thru.common.js");require("./metabase.lib.drill_thru.distribution.js");require("./metabase.lib.drill_thru.fk_details.js");require("./metabase.lib.drill_thru.fk_filter.js");require("./metabase.lib.drill_thru.object_details.js");require("./metabase.lib.drill_thru.pivot.js");require("./metabase.lib.drill_thru.pk.js");require("./metabase.lib.drill_thru.quick_filter.js");require("./metabase.lib.drill_thru.sort.js");require("./metabase.lib.drill_thru.summarize_column.js");require("./metabase.lib.drill_thru.summarize_column_by_time.js");require("./metabase.lib.drill_thru.underlying_records.js");require("./metabase.lib.drill_thru.zoom.js");require("./metabase.lib.drill_thru.zoom_in_bins.js");require("./metabase.lib.drill_thru.zoom_in_geographic.js");require("./metabase.lib.drill_thru.zoom_in_timeseries.js");require("./metabase.lib.metadata.js");require("./metabase.lib.metadata.calculation.js");require("./metabase.lib.schema.js");require("./metabase.lib.schema.drill_thru.js");require("./metabase.util.js");require("./metabase.util.log.js");require("./metabase.util.malli.js");
'use strict';var Hsa,U7,V7,W7,Y7,Z7,$7,Isa,a8,Jsa,Ksa,b8,c8,Lsa,d8,e8,Msa,f8,Nsa,Osa,g8,h8,i8,j8,Psa,k8,Qsa,Rsa,Ssa,Tsa,l8,Usa,Vsa,m8,Wsa,Xsa,Ysa,Zsa,$sa,n8,ata,bta,cta,dta,eta,fta,gta,o8,hta,ita,jta,kta,lta,mta,nta,ota,pta,qta,rta,sta,tta,uta,vta,wta,xta,p8,q8,yta,zta,Ata,r8,Bta,Cta,Dta,Eta,s8,Fta,Gta,Hta,t8,Ita,Jta,Kta,Lta,Mta;$CLJS.T7=function(a){return $CLJS.gc($CLJS.eb(function(b,c){return $CLJS.ig.j(b,c,$CLJS.J.j(b,c,0)+1)},$CLJS.ec($CLJS.N),a))};
Hsa=function(a){var b=$CLJS.bW(a,0),c=$CLJS.O(b),d=$CLJS.J.g(c,$CLJS.BO),e=$CLJS.J.g(c,$CLJS.kV);return $CLJS.Ed(function(){var f=$CLJS.Z0(a);if($CLJS.n(f)){f=$CLJS.n(d)?$CLJS.w_(a,d):d;if($CLJS.n(f))return f;f=$CLJS.n(e)?$CLJS.y_(a,e):e;return $CLJS.n(f)?f:$CLJS.E.g($CLJS.Fj.h(c),$CLJS.yV)}return f}())};U7=function(a){var b=$CLJS.q_(a);return $CLJS.n(b)?$CLJS.mf($CLJS.d_,$CLJS.x_(a,b)):$CLJS.Df};
V7=function(a,b,c){c=$CLJS.O(c);var d=$CLJS.J.g(c,$CLJS.Ui),e=$CLJS.J.g(c,$CLJS.L5),f=$CLJS.J.g(c,$CLJS.dL),k=$CLJS.J.g(c,$CLJS.Ej);return $CLJS.n(function(){var l=$CLJS.B5(a,b);return l?(l=$CLJS.Ge(f),$CLJS.n(l)?(l=$CLJS.n(d)?null!=k:d,l=$CLJS.n(l)?l:null==d&&null==k,$CLJS.n(l)?$CLJS.Ua($CLJS.n(d)?$CLJS.e_(d):d):l):l):l}())?new $CLJS.h(null,6,[$CLJS.Fj,$CLJS.jL,$CLJS.nj,$CLJS.D6,$CLJS.x6,"number"!==typeof k||0>k?2:k,$CLJS.kZ,function(){var l=null==a?null:$CLJS.q_(a);l=null==l?null:$CLJS.w_(a,l);
$CLJS.n(l)||(l=null==a?null:$CLJS.x1(a),l=null==l?null:$CLJS.y_(a,l));return $CLJS.n(l)?$CLJS.D_.j(a,b,l):null}(),$CLJS.dL,f,$CLJS.L5,e],null):null};
W7=function(a,b,c){c=$CLJS.O(c);var d=$CLJS.J.g(c,$CLJS.Ui),e=$CLJS.J.g(c,$CLJS.L5),f=$CLJS.J.g(c,$CLJS.dL),k=$CLJS.J.g(c,$CLJS.Ej);return $CLJS.n(function(){var l=$CLJS.B5(a,b);return l?(l=$CLJS.Ua(d)||null!=k)?(l=$CLJS.o7(a,$CLJS.msa),$CLJS.n(l)?$CLJS.Ge(f):l):l:l}())?new $CLJS.h(null,4,[$CLJS.Fj,$CLJS.jL,$CLJS.nj,$CLJS.h6,$CLJS.L5,e,$CLJS.dL,f],null):null};$CLJS.X7=function(a){return $CLJS.Tk.v(a,$CLJS.PK,$CLJS.ce,new $CLJS.h(null,1,[$CLJS.Fj,$CLJS.rV],null))};
Y7=function(a,b,c){var d=$CLJS.e2(a,$CLJS.aW(a,b)),e=$CLJS.Ok.g($CLJS.iK.h(c),$CLJS.IK)?new $CLJS.h(null,2,[$CLJS.aF,a,$CLJS.aL,b],null):$CLJS.n(d)?new $CLJS.h(null,2,[$CLJS.aF,a,$CLJS.aL,d],null):new $CLJS.h(null,2,[$CLJS.aF,$CLJS.X7(a),$CLJS.aL,-1],null),f=$CLJS.a3.g($CLJS.aF.h(e),$CLJS.aL.h(e));a=function(){var k=$CLJS.H1.v($CLJS.aF.h(e),$CLJS.aL.h(e),$CLJS.NV(c),f);if($CLJS.n(k))return k;k=$CLJS.G0.h(c);return $CLJS.n(k)?$CLJS.QG(function(l){return $CLJS.E.g($CLJS.G0.h(l),$CLJS.G0.h(c))},f):k}();
return $CLJS.R.j(e,$CLJS.Ui,a)};Z7=function(a,b,c){var d=$CLJS.O(c);c=$CLJS.J.g(d,$CLJS.Ui);d=$CLJS.J.g(d,$CLJS.Ej);var e=$CLJS.B5(a,b);d=e?$CLJS.n(c)?null==d&&!$CLJS.e_(c):c:e;return $CLJS.n(d)?(d=$CLJS.XZ(c)?null:$CLJS.R.j($CLJS.z($CLJS.q2(c)),$CLJS.Fj,$CLJS.lJ),$CLJS.hl.l($CLJS.H([new $CLJS.h(null,3,[$CLJS.Fj,$CLJS.jL,$CLJS.nj,$CLJS.b6,$CLJS.$5,d],null),Y7(a,b,c)]))):null};
$7=function(a,b,c){var d=$CLJS.O(c);c=$CLJS.J.g(d,$CLJS.Ui);d=$CLJS.J.g(d,$CLJS.Ej);var e=$CLJS.B5(a,b);a=e?$CLJS.n(c)?null==d&&$CLJS.Ok.g($CLJS.iK.h(c),$CLJS.IK)&&!$CLJS.d_(c)&&!$CLJS.e_(c)&&!$CLJS.n_(c)&&!$CLJS.b_(c)&&$CLJS.Ua($CLJS.y($CLJS.z5.j(a,b,c))):c:e;return $CLJS.n(a)?new $CLJS.h(null,3,[$CLJS.Fj,$CLJS.jL,$CLJS.nj,$CLJS.B6,$CLJS.Ui,c],null):null};
Isa=function(a,b,c){c=$CLJS.O(c);b=$CLJS.J.g(c,$CLJS.Ui);c=$CLJS.J.g(c,$CLJS.Ej);return $CLJS.c_(b)&&null!=c&&$CLJS.Ok.g(c,$CLJS.iL)?new $CLJS.h(null,5,[$CLJS.Fj,$CLJS.jL,$CLJS.nj,$CLJS.A6,$CLJS.Ui,b,$CLJS.M5,c,$CLJS.F5,1<$CLJS.D(U7(a))],null):null};
a8=function(a,b,c){var d=$CLJS.O(c);c=$CLJS.J.g(d,$CLJS.Ui);d=$CLJS.J.g(d,$CLJS.Ej);if($CLJS.n($CLJS.n(c)?null!=d&&$CLJS.Ok.g(d,$CLJS.iL)&&$CLJS.B5(a,b)&&!$CLJS.d_(c)&&$CLJS.c_(c):c)){var e=null==a?null:$CLJS.q_(a);e=null==e?null:$CLJS.w_(a,e);$CLJS.n(e)||(e=null==a?null:$CLJS.x1(a),e=null==e?null:$CLJS.y_(a,e));return new $CLJS.h(null,5,[$CLJS.Fj,$CLJS.jL,$CLJS.nj,$CLJS.f6,$CLJS.iF,$CLJS.MV(new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.or,$CLJS.N,$CLJS.NV(c),d],null)),$CLJS.pF,$CLJS.D_.v(a,b,c,$CLJS.E_),$CLJS.kZ,
$CLJS.D_.j(a,0,e)],null)}return null};
Jsa=function(a,b,c){var d=$CLJS.O(c),e=$CLJS.J.g(d,$CLJS.Ui),f=$CLJS.J.g(d,$CLJS.Ej),k=$CLJS.J.g(d,$CLJS.K5);if(null!=f&&$CLJS.B5(a,b)&&1<$CLJS.D(U7(a))&&!$CLJS.c_(e)){if($CLJS.d_(e))return null!=f&&$CLJS.Ok.g(f,$CLJS.iL)?new $CLJS.h(null,3,[$CLJS.Fj,$CLJS.jL,$CLJS.nj,$CLJS.p6,$CLJS.dL,new $CLJS.P(null,1,5,$CLJS.Q,[new $CLJS.h(null,2,[$CLJS.Ui,e,$CLJS.Ej,f],null)],null)],null):null;var l=U7(a);a=function(){return function u(t){return new $CLJS.oe(null,function(){for(var v=t;;){var x=$CLJS.y(v);if(x){var A=
x;if($CLJS.Ad(A)){var C=$CLJS.lc(A),G=$CLJS.D(C),K=$CLJS.re(G);return function(){for(var Z=0;;)if(Z<G){var ha=$CLJS.kd(C,Z),ua=$CLJS.Ej.h($CLJS.QG(function(Ta,Cb,Oa){return function(Wa){return $CLJS.E.g($CLJS.T.h($CLJS.Ui.h(Wa)),$CLJS.T.h(Oa))}}(Z,v,ha,C,G,K,A,x,l,c,d,d,e,f,k),k));$CLJS.n(ua)&&$CLJS.ve(K,new $CLJS.h(null,2,[$CLJS.Ui,ha,$CLJS.Ej,ua],null));Z+=1}else return!0}()?$CLJS.ue($CLJS.we(K),u($CLJS.mc(A))):$CLJS.ue($CLJS.we(K),null)}var S=$CLJS.z(A),W=$CLJS.Ej.h($CLJS.QG(function(Z,ha){return function(ua){return $CLJS.E.g($CLJS.T.h($CLJS.Ui.h(ua)),
$CLJS.T.h(ha))}}(v,S,A,x,l,c,d,d,e,f,k),k));if($CLJS.n(W))return $CLJS.fe(new $CLJS.h(null,2,[$CLJS.Ui,S,$CLJS.Ej,W],null),u($CLJS.Lc(A)));v=$CLJS.Lc(A)}else return null}},null,null)}(l)}();return $CLJS.y(a)?new $CLJS.h(null,3,[$CLJS.Fj,$CLJS.jL,$CLJS.nj,$CLJS.p6,$CLJS.dL,$CLJS.Ef($CLJS.d1(function(m){return $CLJS.bA(m,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ui,$CLJS.$i],null))},a))],null):null}return null};
Ksa=function(a,b,c){var d=$CLJS.O(c);b=$CLJS.J.g(d,$CLJS.Ui);c=$CLJS.J.g(d,$CLJS.Ej);d=$CLJS.J.g(d,$CLJS.K5);if(null==c||1<$CLJS.D(U7(a)))return null;if($CLJS.d_(b))return $CLJS.E.g(c,$CLJS.iL)?null:new $CLJS.h(null,5,[$CLJS.Fj,$CLJS.jL,$CLJS.nj,$CLJS.S6,$CLJS.Ui,b,$CLJS.M5,c,$CLJS.F5,!1],null);a=U7(a);var e=$CLJS.I(a,0,null);a=$CLJS.Ej.h($CLJS.QG(function(f){return $CLJS.E.g($CLJS.T.h($CLJS.Ui.h(f)),$CLJS.T.h(e))},d));return $CLJS.n(a)?new $CLJS.h(null,5,[$CLJS.Fj,$CLJS.jL,$CLJS.nj,$CLJS.S6,$CLJS.Ui,
e,$CLJS.M5,a,$CLJS.F5,!1],null):null};b8=function(a,b,c){return $CLJS.Se(function(d){return d.j?d.j(a,b,c):d.call(null,a,b,c)},new $CLJS.P(null,3,5,$CLJS.Q,[Isa,Jsa,Ksa],null))};c8=function(a,b,c,d){var e=$CLJS.O(c);c=$CLJS.J.g(e,$CLJS.Ui);e=$CLJS.J.g(e,$CLJS.Ej);var f=$CLJS.B5(a,b);c=f?$CLJS.n(c)?null!=e&&$CLJS.E.g($CLJS.iK.h(c),$CLJS.IK):c:f;return $CLJS.n(c)?$CLJS.mf(d,$CLJS.y5.g(a,b)):null};
Lsa=function(a,b){var c=$CLJS.T7($CLJS.jf.g(function(d){d=$CLJS.B_.j(a,b,d);return $CLJS.XZ(d)?$CLJS.YO:$CLJS.g_(d)?$CLJS.B7:$CLJS.a_(d)?$CLJS.ZF:null},$CLJS.w5.g(a,b)));return $CLJS.E.g(new $CLJS.h(null,1,[$CLJS.YO,1],null),c)?new $CLJS.bh(null,new $CLJS.h(null,2,[$CLJS.ZF,null,$CLJS.W5,null],null),null):$CLJS.E.g(new $CLJS.h(null,2,[$CLJS.YO,1,$CLJS.ZF,1],null),c)?new $CLJS.bh(null,new $CLJS.h(null,2,[$CLJS.ZF,null,$CLJS.W5,null],null),null):$CLJS.E.g(new $CLJS.h(null,1,[$CLJS.B7,1],null),c)?new $CLJS.bh(null,
new $CLJS.h(null,2,[$CLJS.ZF,null,$CLJS.fy,null],null),null):$CLJS.E.g($CLJS.N,c)?new $CLJS.bh(null,new $CLJS.h(null,3,[$CLJS.ZF,null,$CLJS.fy,null,$CLJS.W5,null],null),null):$CLJS.E.g(new $CLJS.h(null,1,[$CLJS.ZF,1],null),c)?new $CLJS.bh(null,new $CLJS.h(null,2,[$CLJS.ZF,null,$CLJS.fy,null],null),null):$CLJS.E.g(new $CLJS.h(null,1,[$CLJS.ZF,2],null),c)?new $CLJS.bh(null,new $CLJS.h(null,2,[$CLJS.ZF,null,$CLJS.fy,null],null),null):$CLJS.ch};
d8=function(a,b,c){var d=$CLJS.O(c),e=$CLJS.J.g(d,$CLJS.Ui);c=$CLJS.J.g(d,$CLJS.dL);var f=$CLJS.J.g(d,$CLJS.Ej);if($CLJS.n(function(){var m=$CLJS.B5(a,b);return m?$CLJS.n(e)?null!=f&&$CLJS.E.g($CLJS.iK.h(e),$CLJS.IK)&&0<$CLJS.D($CLJS.e4.g(a,b)):e:m}())){var k=Lsa(a,b),l=$CLJS.fg.g($CLJS.N,function(){return function u(t){return new $CLJS.oe(null,function(){for(var v=t;;)if(v=$CLJS.y(v)){if($CLJS.Ad(v)){var x=$CLJS.lc(v),A=$CLJS.D(x),C=$CLJS.re(A);a:for(var G=0;;)if(G<A){var K=$CLJS.kd(x,G),S=$CLJS.J.g($CLJS.C7,
K);S=c8(a,b,d,S);$CLJS.n($CLJS.Ge(S))&&C.add(new $CLJS.P(null,2,5,$CLJS.Q,[K,S],null));G+=1}else{x=!0;break a}return x?$CLJS.ue($CLJS.we(C),u($CLJS.mc(v))):$CLJS.ue($CLJS.we(C),null)}C=$CLJS.z(v);x=$CLJS.J.g($CLJS.C7,C);x=c8(a,b,d,x);if($CLJS.n($CLJS.Ge(x)))return $CLJS.fe(new $CLJS.P(null,2,5,$CLJS.Q,[C,x],null),u($CLJS.Lc(v)));v=$CLJS.Lc(v)}else return null},null,null)}(k)}());return $CLJS.td(l)?null:new $CLJS.h(null,4,[$CLJS.Fj,$CLJS.jL,$CLJS.nj,$CLJS.K6,$CLJS.dL,c,$CLJS.S5,l],null)}return null};
e8=function(a,b){return $CLJS.MV($CLJS.fg.g(new $CLJS.P(null,2,5,$CLJS.Q,[a,$CLJS.N],null),b))};
Msa=function(a,b){var c=$CLJS.NV(a);if($CLJS.e_(a))return $CLJS.Df;if($CLJS.E.g(b,$CLJS.iL))return new $CLJS.P(null,2,5,$CLJS.Q,[new $CLJS.h(null,2,[$CLJS.T,"\x3d",$CLJS.iF,e8($CLJS.fF,$CLJS.H([c]))],null),new $CLJS.h(null,2,[$CLJS.T,"≠",$CLJS.iF,e8($CLJS.rF,$CLJS.H([c]))],null)],null);if($CLJS.YZ(a)||$CLJS.XZ(a))return a=function f(e){return new $CLJS.oe(null,function(){for(;;){var k=$CLJS.y(e);if(k){if($CLJS.Ad(k)){var l=$CLJS.lc(k),m=$CLJS.D(l),t=$CLJS.re(m);a:for(var u=0;;)if(u<m){var v=$CLJS.kd(l,
u),x=$CLJS.I(v,0,null);v=$CLJS.I(v,1,null);x=new $CLJS.h(null,2,[$CLJS.T,v,$CLJS.iF,e8(x,$CLJS.H([c,b]))],null);t.add(x);u+=1}else{l=!0;break a}return l?$CLJS.ue($CLJS.we(t),f($CLJS.mc(k))):$CLJS.ue($CLJS.we(t),null)}l=$CLJS.z(k);t=$CLJS.I(l,0,null);l=$CLJS.I(l,1,null);return $CLJS.fe(new $CLJS.h(null,2,[$CLJS.T,l,$CLJS.iF,e8(t,$CLJS.H([c,b]))],null),f($CLJS.Lc(k)))}return null}},null,null)},a(new $CLJS.P(null,4,5,$CLJS.Q,[new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.lr,"\x3c"],null),new $CLJS.P(null,2,5,
$CLJS.Q,[$CLJS.hr,"\x3e"],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.or,"\x3d"],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.XF,"≠"],null)],null));if($CLJS.ZZ(a))return a=function f(e){return new $CLJS.oe(null,function(){for(;;){var k=$CLJS.y(e);if(k){if($CLJS.Ad(k)){var l=$CLJS.lc(k),m=$CLJS.D(l),t=$CLJS.re(m);a:for(var u=0;;)if(u<m){var v=$CLJS.kd(l,u),x=$CLJS.I(v,0,null);v=$CLJS.I(v,1,null);x=new $CLJS.h(null,2,[$CLJS.T,v,$CLJS.iF,e8(x,$CLJS.H([c,b]))],null);t.add(x);u+=1}else{l=!0;break a}return l?
$CLJS.ue($CLJS.we(t),f($CLJS.mc(k))):$CLJS.ue($CLJS.we(t),null)}l=$CLJS.z(k);t=$CLJS.I(l,0,null);l=$CLJS.I(l,1,null);return $CLJS.fe(new $CLJS.h(null,2,[$CLJS.T,l,$CLJS.iF,e8(t,$CLJS.H([c,b]))],null),f($CLJS.Lc(k)))}return null}},null,null)},a(new $CLJS.P(null,4,5,$CLJS.Q,[new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.or,"\x3d"],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.XF,"≠"],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.EF,"contains"],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.xF,"does-not-contain"],null)],null));
a=function f(e){return new $CLJS.oe(null,function(){for(;;){var k=$CLJS.y(e);if(k){if($CLJS.Ad(k)){var l=$CLJS.lc(k),m=$CLJS.D(l),t=$CLJS.re(m);a:for(var u=0;;)if(u<m){var v=$CLJS.kd(l,u),x=$CLJS.I(v,0,null);v=$CLJS.I(v,1,null);x=new $CLJS.h(null,2,[$CLJS.T,v,$CLJS.iF,e8(x,$CLJS.H([c,b]))],null);t.add(x);u+=1}else{l=!0;break a}return l?$CLJS.ue($CLJS.we(t),f($CLJS.mc(k))):$CLJS.ue($CLJS.we(t),null)}l=$CLJS.z(k);t=$CLJS.I(l,0,null);l=$CLJS.I(l,1,null);return $CLJS.fe(new $CLJS.h(null,2,[$CLJS.T,l,
$CLJS.iF,e8(t,$CLJS.H([c,b]))],null),f($CLJS.Lc(k)))}return null}},null,null)};return a(new $CLJS.P(null,2,5,$CLJS.Q,[new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.or,"\x3d"],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.XF,"≠"],null)],null))};
f8=function(a,b,c){var d=$CLJS.O(c);c=$CLJS.J.g(d,$CLJS.Ui);d=$CLJS.J.g(d,$CLJS.Ej);var e=(e=$CLJS.B5(a,b))?$CLJS.n(c)?null!=d&&!$CLJS.d_(c)&&!$CLJS.c_(c):c:e;return $CLJS.n(e)?(a=Y7(a,b,c),$CLJS.hl.l($CLJS.H([new $CLJS.h(null,4,[$CLJS.Fj,$CLJS.jL,$CLJS.nj,$CLJS.J5,$CLJS.ZK,Msa($CLJS.Ui.h(a),d),$CLJS.Ej,d],null),a]))):null};Nsa=function(a,b,c){return $CLJS.H1.v(a,b,$CLJS.NV(c),$CLJS.I7.g(a,b))};
Osa=function(a,b,c){a=$CLJS.K7(a,b,c);return $CLJS.n(a)?(b=$CLJS.I(a,0,null),$CLJS.I(a,1,null),$CLJS.I(a,2,null),b):null};
g8=function(a,b,c){c=$CLJS.O(c);var d=$CLJS.J.g(c,$CLJS.Ui),e=$CLJS.J.g(c,$CLJS.Ej);if($CLJS.n(function(){var k=$CLJS.B5(a,b);return k?$CLJS.n(d)?null==e&&!$CLJS.e_(d):d:k}())&&$CLJS.n(Nsa(a,b,d))){var f=Osa(a,b,d);return new $CLJS.h(null,4,[$CLJS.Fj,$CLJS.jL,$CLJS.nj,$CLJS.l6,$CLJS.Ui,d,$CLJS.o6,function(){switch(f instanceof $CLJS.M?f.S:null){case "asc":return new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.vF],null);case "desc":return new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.CF],null);default:return new $CLJS.P(null,
2,5,$CLJS.Q,[$CLJS.CF,$CLJS.vF],null)}}()],null)}return null};
h8=function(a,b,c){var d=$CLJS.O(c);c=$CLJS.J.g(d,$CLJS.Ui);d=$CLJS.J.g(d,$CLJS.Ej);var e=$CLJS.B5(a,b);a=e?$CLJS.n(c)?null==d&&!$CLJS.e_(c)&&$CLJS.Ok.g($CLJS.iK.h(c),$CLJS.IK)&&$CLJS.Ua($CLJS.y($CLJS.z5.j(a,b,c))):c:e;return $CLJS.n(a)?(a=$CLJS.hf.g(new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.jF],null),$CLJS.$Z(c)?new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.SF,$CLJS.zF],null):null),new $CLJS.h(null,4,[$CLJS.Fj,$CLJS.jL,$CLJS.nj,$CLJS.m6,$CLJS.Ui,c,$CLJS.r6,a],null)):null};
i8=function(a,b,c){var d=$CLJS.O(c);c=$CLJS.J.g(d,$CLJS.Ui);d=$CLJS.J.g(d,$CLJS.Ej);var e=$CLJS.B5(a,b);d=e?$CLJS.n(c)?null==d&&!$CLJS.e_(c)&&$CLJS.$Z(c)&&$CLJS.Ok.g($CLJS.iK.h(c),$CLJS.IK):c:e;if($CLJS.n(d)&&(d=$CLJS.QG($CLJS.XZ,$CLJS.y5.g(a,b)),$CLJS.n(d)&&(e=$CLJS.QG($CLJS.di,$CLJS.A0.j(a,b,d)),$CLJS.n(e)))){var f=$CLJS.d3(d,e);return $CLJS.n($CLJS.fV($CLJS.jf.g($CLJS.NV,$CLJS.fe(f,$CLJS.w5.g(a,b)))))?new $CLJS.h(null,5,[$CLJS.Fj,$CLJS.jL,$CLJS.nj,$CLJS.H6,$CLJS.Ui,c,$CLJS.tF,d,$CLJS.ti,$CLJS.x0.h(e)],
null):null}return null};
j8=function(a,b,c){var d=$CLJS.O(c);c=$CLJS.J.g(d,$CLJS.Ui);d=$CLJS.J.g(d,$CLJS.Ej);if($CLJS.n($CLJS.n(c)?d:c))if(b=$CLJS.z($CLJS.z5.j(a,b,c)),$CLJS.n(b))if(b=$CLJS.V6(b),$CLJS.n(b))if(a=$CLJS.q7(a,c,d),$CLJS.n(a)){var e=$CLJS.O(a);a=$CLJS.J.g(e,$CLJS.cL);var f=$CLJS.J.g(e,$CLJS.fL);e=$CLJS.J.g(e,$CLJS.XD);var k=$CLJS.YD.h(b);k=k instanceof $CLJS.M?k.S:null;switch(k){case "num-bins":case "default":return new $CLJS.h(null,6,[$CLJS.Fj,$CLJS.jL,$CLJS.nj,$CLJS.q6,$CLJS.Ui,c,$CLJS.cL,d,$CLJS.fL,d+e,$CLJS.i6,
new $CLJS.h(null,1,[$CLJS.YD,$CLJS.di],null)],null);case "bin-width":return new $CLJS.h(null,6,[$CLJS.Fj,$CLJS.jL,$CLJS.nj,$CLJS.q6,$CLJS.Ui,c,$CLJS.cL,a,$CLJS.fL,f,$CLJS.i6,$CLJS.Tk.j(b,$CLJS.XD,function(l){return l/10})],null);default:throw Error(["No matching clause: ",$CLJS.p.h(k)].join(""));}}else return null;else return null;else return null;else return null};
Psa=function(a,b,c){c=$CLJS.O(c);var d=$CLJS.J.g(c,$CLJS.K5);b=$CLJS.n0.j(a,b,$CLJS.bW(a,b));a=$CLJS.QG($CLJS.l_,b);if($CLJS.n(a)&&(b=$CLJS.QG($CLJS.m_,b),$CLJS.n(b))){var e=function(f){return $CLJS.Se(function(k){var l=$CLJS.Ui.h(k);l=$CLJS.n($CLJS.$i.h(f))?$CLJS.E.g($CLJS.$i.h(f),$CLJS.$i.h(l)):$CLJS.E.g($CLJS.iZ.h(f),$CLJS.iZ.h(l));return $CLJS.n(l)?$CLJS.Ej.h(k):null},d)};return $CLJS.R.l(c,$CLJS.O7,a,$CLJS.H([$CLJS.P7,b,$CLJS.Q7,e(a),$CLJS.N7,e(b)]))}return null};
k8=function(a,b){var c=$CLJS.O(a);a=$CLJS.J.g(c,$CLJS.Ui);var d=$CLJS.J.g(c,$CLJS.Ej),e=$CLJS.J.g(c,$CLJS.O7);c=$CLJS.J.g(c,$CLJS.P7);return $CLJS.n(d)?new $CLJS.h(null,7,[$CLJS.Fj,$CLJS.jL,$CLJS.nj,$CLJS.O6,$CLJS.Q5,$CLJS.d6,$CLJS.Ui,a,$CLJS.Ej,d,$CLJS.g6,new $CLJS.h(null,2,[$CLJS.Ui,e,$CLJS.XD,b],null),$CLJS.e6,new $CLJS.h(null,2,[$CLJS.Ui,c,$CLJS.XD,b],null)],null):null};Qsa=function(a){a=$CLJS.O(a);var b=$CLJS.J.g(a,$CLJS.Ui);return $CLJS.n(null==b?null:$CLJS.j_(b))?k8(a,10):null};
Rsa=function(a){a=$CLJS.O(a);var b=$CLJS.J.g(a,$CLJS.Ui);return $CLJS.n(null==b?null:$CLJS.i_(b))?k8(a,1):null};Ssa=function(a){a=$CLJS.O(a);var b=$CLJS.J.g(a,$CLJS.Ui);return $CLJS.n(null==b?null:$CLJS.h_(b))?k8(a,.1):null};
Tsa=function(a,b){var c=$CLJS.O(b);b=$CLJS.J.g(c,$CLJS.O7);var d=$CLJS.J.g(c,$CLJS.P7),e=$CLJS.J.g(c,$CLJS.Q7);c=$CLJS.J.g(c,$CLJS.N7);if($CLJS.n($CLJS.n(e)?c:e)){var f=$CLJS.q7(a,b,e);if($CLJS.n(f)&&(f=$CLJS.O(f),f=$CLJS.J.g(f,$CLJS.XD),a=$CLJS.q7(a,d,c),$CLJS.n(a))){a=$CLJS.O(a);a=$CLJS.J.g(a,$CLJS.XD);var k=20<=f&&20<=a?new $CLJS.P(null,2,5,$CLJS.Q,[10,10],null):new $CLJS.P(null,2,5,$CLJS.Q,[f/10,a/10],null),l=$CLJS.I(k,0,null);k=$CLJS.I(k,1,null);return new $CLJS.h(null,5,[$CLJS.Fj,$CLJS.jL,$CLJS.nj,
$CLJS.O6,$CLJS.Q5,$CLJS.H5,$CLJS.g6,new $CLJS.h(null,4,[$CLJS.Ui,b,$CLJS.XD,l,$CLJS.Rn,e,$CLJS.jk,e+f],null),$CLJS.e6,new $CLJS.h(null,4,[$CLJS.Ui,d,$CLJS.XD,k,$CLJS.Rn,c,$CLJS.jk,c+a],null)],null)}}return null};l8=function(a,b,c){c=$CLJS.O(c);var d=$CLJS.J.g(c,$CLJS.Ej);if($CLJS.n(d)){var e=Psa(a,b,c);return $CLJS.n(e)?$CLJS.Se(function(f){return f.h?f.h(e):f.call(null,e)},new $CLJS.P(null,4,5,$CLJS.Q,[Qsa,Rsa,Ssa,$CLJS.We(Tsa,a)],null)):null}return null};
Usa=function(a,b,c){return $CLJS.z(function(){return function f(e){return new $CLJS.oe(null,function(){for(var k=e;;){var l=$CLJS.y(k);if(l){var m=l,t=$CLJS.z(m);if($CLJS.n(function(){var u=$CLJS.eY(t,$CLJS.FF);return u?$CLJS.m2(t):u}())&&(l=$CLJS.y(function(u,v,x,A){return function K(G){return new $CLJS.oe(null,function(S,W){return function(){for(var Z=G;;)if(Z=$CLJS.y(Z)){if($CLJS.Ad(Z)){var ha=$CLJS.lc(Z),ua=$CLJS.D(ha),Ta=$CLJS.re(ua);return function(){for(var gb=0;;)if(gb<ua){var Va=$CLJS.kd(ha,
gb),ob=$CLJS.O(Va);Va=ob;ob=$CLJS.J.g(ob,$CLJS.Ui);var Ja=$CLJS.H1.g(W,new $CLJS.P(null,1,5,$CLJS.Q,[ob],null));ob=$CLJS.n(Ja)?$CLJS.E.g($CLJS.m2(W),$CLJS.m2(ob)):Ja;$CLJS.n(ob)&&(Va=$CLJS.R.j(Va,$CLJS.L5,W),Ta.add(Va));gb+=1}else return!0}()?$CLJS.ue($CLJS.we(Ta),K($CLJS.mc(Z))):$CLJS.ue($CLJS.we(Ta),null)}var Cb=$CLJS.z(Z),Oa=Cb=$CLJS.O(Cb),Wa=$CLJS.J.g(Cb,$CLJS.Ui);if($CLJS.n(function(){var gb=$CLJS.H1.g(W,new $CLJS.P(null,1,5,$CLJS.Q,[Wa],null));return $CLJS.n(gb)?$CLJS.E.g($CLJS.m2(W),$CLJS.m2(Wa)):
gb}()))return $CLJS.fe($CLJS.R.j(Oa,$CLJS.L5,W),K($CLJS.Lc(Z)));Z=$CLJS.Lc(Z)}else return null}}(u,v,x,A),null,null)}}(k,t,m,l)(c))))return $CLJS.hf.g(l,f($CLJS.Lc(k)));k=$CLJS.Lc(k)}else return null}},null,null)}($CLJS.w5.g(a,b))}())};
Vsa=function(a){a=a instanceof $CLJS.M?a.S:null;switch(a){case "quarter":return $CLJS.eE("See this year by quarter");case "month":return $CLJS.eE("See this quarter by month");case "week":return $CLJS.eE("See this month by week");case "day":return $CLJS.eE("See this week by day");case "hour":return $CLJS.eE("See this day by hour");case "minute":return $CLJS.eE("See this hour by minute");default:throw Error(["No matching clause: ",$CLJS.p.h(a)].join(""));}};
m8=function(a,b,c){c=$CLJS.O(c);c=$CLJS.J.g(c,$CLJS.dL);var d=(d=$CLJS.B5(a,b))?$CLJS.Ge(c):d;return $CLJS.n(d)&&(a=Usa(a,b,c),$CLJS.n(a)&&(a=$CLJS.O(a),b=$CLJS.J.g(a,$CLJS.Ej),$CLJS.n(b)))?(b=$CLJS.Ui.h(a),b=$CLJS.x0.h(b),b=$CLJS.n(b)?$CLJS.Hd($CLJS.gh($CLJS.R7),b)?$CLJS.S7.h?$CLJS.S7.h(b):$CLJS.S7.call(null,b):null:null,$CLJS.n(b)?new $CLJS.h(null,5,[$CLJS.Fj,$CLJS.jL,$CLJS.ID,Vsa(b),$CLJS.nj,$CLJS.M6,$CLJS.YE,a,$CLJS.G6,b],null):null):null};
Wsa=function(a){var b=$CLJS.O(a),c=$CLJS.J.g(b,$CLJS.dL);return $CLJS.Ge(function(){return function f(e){return new $CLJS.oe(null,function(){for(;;){var k=$CLJS.y(e);if(k){if($CLJS.Ad(k)){var l=$CLJS.lc(k),m=$CLJS.D(l),t=$CLJS.re(m);a:for(var u=0;;)if(u<m){var v=$CLJS.kd(l,u);v=$CLJS.hl.l($CLJS.H([b,v]));t.add(v);u+=1}else{l=!0;break a}return l?$CLJS.ue($CLJS.we(t),f($CLJS.mc(k))):$CLJS.ue($CLJS.we(t),null)}t=$CLJS.z(k);return $CLJS.fe($CLJS.hl.l($CLJS.H([b,t])),f($CLJS.Lc(k)))}return null}},null,
null)}(c)}())};Xsa=new $CLJS.r(null,"metabase.lib.drill-thru.pivot","metabase.lib.drill-thru.pivot",109208756,null);Ysa=new $CLJS.r(null,"metabase.lib.drill-thru.zoom-in-bins","metabase.lib.drill-thru.zoom-in-bins",477390405,null);Zsa=new $CLJS.r("metabase.lib.drill-thru.zoom-in-bins","zoom-in-binning-drill","metabase.lib.drill-thru.zoom-in-bins/zoom-in-binning-drill",-862934837,null);
$sa=new $CLJS.r(null,"metabase.lib.drill-thru.summarize-column-by-time","metabase.lib.drill-thru.summarize-column-by-time",-369504986,null);n8=new $CLJS.r(null,"stage-number","stage-number",-112198111,null);ata=new $CLJS.r(null,"underlying-records-drill","underlying-records-drill",713740052,null);bta=new $CLJS.r("metabase.lib.drill-thru.zoom-in-timeseries","zoom-in-timeseries-drill","metabase.lib.drill-thru.zoom-in-timeseries/zoom-in-timeseries-drill",313862423,null);
cta=new $CLJS.r(null,"zoom-in-timeseries-drill","zoom-in-timeseries-drill",-1304664195,null);dta=new $CLJS.r(null,"distribution-drill","distribution-drill",-898205041,null);eta=new $CLJS.r(null,"sort-drill","sort-drill",600387798,null);fta=new $CLJS.M(null,"drill","drill",-1704624793);gta=new $CLJS.r("metabase.lib.drill-thru.quick-filter","quick-filter-drill","metabase.lib.drill-thru.quick-filter/quick-filter-drill",457872114,null);o8=new $CLJS.r(null,"_context","_context",-1238791322,null);
hta=new $CLJS.r(null,"metabase.lib.drill-thru.distribution","metabase.lib.drill-thru.distribution",-514507852,null);ita=new $CLJS.r(null,"zoom-in-binning-drill","zoom-in-binning-drill",1550598377,null);jta=new $CLJS.r(null,"metabase.lib.drill-thru.object-details","metabase.lib.drill-thru.object-details",1788168194,null);kta=new $CLJS.r(null,"automatic-insights-drill","automatic-insights-drill",-538289251,null);
lta=new $CLJS.r("metabase.lib.drill-thru.zoom-in-geographic","zoom-in-geographic-drill","metabase.lib.drill-thru.zoom-in-geographic/zoom-in-geographic-drill",754461586,null);mta=new $CLJS.r("metabase.lib.drill-thru.distribution","distribution-drill","metabase.lib.drill-thru.distribution/distribution-drill",-2103693273,null);nta=new $CLJS.r(null,"fk-filter-drill","fk-filter-drill",-1258190784,null);
ota=new $CLJS.r(null,"metabase.lib.drill-thru.summarize-column","metabase.lib.drill-thru.summarize-column",490384430,null);pta=new $CLJS.r(null,"pivot-drill","pivot-drill",-1794856768,null);qta=new $CLJS.r(null,"metabase.lib.drill-thru.quick-filter","metabase.lib.drill-thru.quick-filter",671398986,null);rta=new $CLJS.r(null,"metabase.lib.drill-thru.underlying-records","metabase.lib.drill-thru.underlying-records",-827008688,null);
sta=new $CLJS.r("metabase.lib.drill-thru.summarize-column-by-time","summarize-column-by-time-drill","metabase.lib.drill-thru.summarize-column-by-time/summarize-column-by-time-drill",391970943,null);tta=new $CLJS.r(null,"summarize-column-drill","summarize-column-drill",-1961542613,null);uta=new $CLJS.r(null,"column-filter-drill","column-filter-drill",-1088669153,null);
vta=new $CLJS.r("metabase.lib.drill-thru.underlying-records","underlying-records-drill","metabase.lib.drill-thru.underlying-records/underlying-records-drill",1176519262,null);wta=new $CLJS.r(null,"metabase.lib.drill-thru.sort","metabase.lib.drill-thru.sort",961185366,null);xta=new $CLJS.r(null,"metabase.lib.drill-thru.zoom-in-geographic","metabase.lib.drill-thru.zoom-in-geographic",-1562146002,null);p8=new $CLJS.r(null,"dimensions","dimensions",1385713430,null);
q8=new $CLJS.M(null,"return-drills-for-dimensions?","return-drills-for-dimensions?",-1759804348);yta=new $CLJS.r("metabase.lib.drill-thru.column-filter","column-filter-drill","metabase.lib.drill-thru.column-filter/column-filter-drill",-1130871894,null);zta=new $CLJS.r(null,"object-detail-drill","object-detail-drill",-613919053,null);Ata=new $CLJS.r(null,"metabase.lib.drill-thru.column-filter","metabase.lib.drill-thru.column-filter",23504270,null);
r8=new $CLJS.r(null,"context","context",810340414,null);Bta=new $CLJS.r(null,"zoom-in-geographic-drill","zoom-in-geographic-drill",312914971,null);Cta=new $CLJS.r("metabase.lib.drill-thru.automatic-insights","automatic-insights-drill","metabase.lib.drill-thru.automatic-insights/automatic-insights-drill",1471807986,null);Dta=new $CLJS.r(null,"summarize-column-by-time-drill","summarize-column-by-time-drill",1117784387,null);
Eta=new $CLJS.r("metabase.lib.drill-thru.object-details","object-detail-drill","metabase.lib.drill-thru.object-details/object-detail-drill",-1376576725,null);s8=new $CLJS.r(null,"column-ref","column-ref",-636247393,null);Fta=new $CLJS.r("metabase.lib.drill-thru.pivot","pivot-drill","metabase.lib.drill-thru.pivot/pivot-drill",1004065694,null);Gta=new $CLJS.r(null,"metabase.lib.drill-thru.fk-filter","metabase.lib.drill-thru.fk-filter",-1939155669,null);
Hta=new $CLJS.r("metabase.lib.drill-thru.summarize-column","summarize-column-drill","metabase.lib.drill-thru.summarize-column/summarize-column-drill",-894275347,null);t8=new $CLJS.r(null,"query","query",352022017,null);Ita=new $CLJS.r(null,"metabase.lib.drill-thru.zoom-in-timeseries","metabase.lib.drill-thru.zoom-in-timeseries",105846904,null);Jta=new $CLJS.r(null,"metabase.lib.drill-thru.automatic-insights","metabase.lib.drill-thru.automatic-insights",912989182,null);
Kta=new $CLJS.r("metabase.lib.drill-thru.fk-filter","fk-filter-drill","metabase.lib.drill-thru.fk-filter/fk-filter-drill",-716335012,null);Lta=new $CLJS.r("metabase.lib.drill-thru.sort","sort-drill","metabase.lib.drill-thru.sort/sort-drill",2019212116,null);Mta=new $CLJS.r(null,"quick-filter-drill","quick-filter-drill",534264366,null);var Nta;$CLJS.j0.m(null,$CLJS.jL,function(a,b,c){return $CLJS.E5.j(a,b,c)});
Nta=new $CLJS.P(null,14,5,$CLJS.Q,[new $CLJS.h(null,2,[$CLJS.vs,new $CLJS.Gc(function(){return W7},Cta,$CLJS.Jg([$CLJS.nk,$CLJS.Xi,$CLJS.T,$CLJS.qk,$CLJS.wi,$CLJS.Ui,$CLJS.oj,$CLJS.zk,$CLJS.vi,$CLJS.kk,$CLJS.pk],[new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Yn,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.Ar,$CLJS.gL,$CLJS.Yr,$CLJS.V5],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Lr,$CLJS.k6],null)],null),Jta,kta,"metabase/lib/drill_thru/automatic_insights.cljc",34,1,11,11,$CLJS.U(new $CLJS.P(null,3,5,$CLJS.Q,[t8,n8,new $CLJS.h(null,
1,[$CLJS.Ro,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.zz,s8,p8,$CLJS.Wi],null)],null)],null)),'Inputs: [query :- :metabase.lib.schema/query stage-number :- :int {:keys [column column-ref dimensions value]} :- :metabase.lib.schema.drill-thru/context]\n  Return: [:maybe :metabase.lib.schema.drill-thru/drill-thru]\n          \n\n  Automatic insights appears:\n  - When clicking on a value with a breakout - eg. a point in a time series, a cell of a table, a bar or pie slice\n  - Or when clicking a pivot cell, with a value but no column.\n  - Or when clicking a chart legend, in which case there\'s no column or value set.\n  - There must be at least 1 breakout\n  - X-rays must be enabled (check the settings)\n\n  There are two forms: X-ray, and "Compare to the rest". This is a simple user choice and does not need extra data.',
$CLJS.n(W7)?W7.H:null])),q8,!1],null),new $CLJS.h(null,2,[$CLJS.vs,new $CLJS.Gc(function(){return Z7},yta,$CLJS.Jg([$CLJS.nk,$CLJS.Xi,$CLJS.T,$CLJS.qk,$CLJS.wi,$CLJS.Ui,$CLJS.oj,$CLJS.zk,$CLJS.vi,$CLJS.kk,$CLJS.pk],[new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Yn,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.Ar,$CLJS.gL,$CLJS.Yr,$CLJS.V5],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Lr,$CLJS.Y5],null)],null),Ata,uta,"metabase/lib/drill_thru/column_filter.cljc",29,1,81,81,$CLJS.U(new $CLJS.P(null,3,5,$CLJS.Q,[t8,n8,new $CLJS.h(null,
1,[$CLJS.Ro,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.zz,$CLJS.Wi],null)],null)],null)),'Inputs: [query :- :metabase.lib.schema/query stage-number :- :int {:keys [column value]} :- :metabase.lib.schema.drill-thru/context]\n  Return: [:maybe :metabase.lib.schema.drill-thru/drill-thru.column-filter]\n          \n\n  Filtering at the column level, based on its type. Displays a submenu of eg. "Today", "This Week", etc. for date\n  columns.\n\n  Note that if the clicked column is an aggregation, filtering by it will require a new stage. Therefore this drill\n  returns a possibly-updated `:query` and `:stage-number` along with a `:column` referencing that later stage.',
$CLJS.n(Z7)?Z7.H:null])),q8,!0],null),new $CLJS.h(null,2,[$CLJS.vs,new $CLJS.Gc(function(){return $7},mta,$CLJS.Jg([$CLJS.nk,$CLJS.Xi,$CLJS.T,$CLJS.qk,$CLJS.wi,$CLJS.Ui,$CLJS.oj,$CLJS.zk,$CLJS.vi,$CLJS.kk,$CLJS.pk],[new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Yn,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.Ar,$CLJS.gL,$CLJS.Yr,$CLJS.V5],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Lr,$CLJS.P5],null)],null),hta,dta,"metabase/lib/drill_thru/distribution.cljc",28,1,42,42,$CLJS.U(new $CLJS.P(null,3,5,$CLJS.Q,[t8,n8,new $CLJS.h(null,
1,[$CLJS.Ro,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.zz,$CLJS.Wi],null)],null)],null)),"Inputs: [query :- :metabase.lib.schema/query stage-number :- :int {:keys [column value]} :- :metabase.lib.schema.drill-thru/context]\n  Return: [:maybe :metabase.lib.schema.drill-thru/drill-thru.distribution]\n          \n\n  Select a column and see a histogram of how many rows fall into an automatic set of bins/buckets.\n  - For dates, breaks out by month by default.\n  - For numeric values, by an auto-selected set of bins\n  - For strings, by each distinct value (which might be \x3d the number of rows)",
$CLJS.n($7)?$7.H:null])),q8,!0],null),new $CLJS.h(null,2,[$CLJS.vs,new $CLJS.Gc(function(){return a8},Kta,$CLJS.Jg([$CLJS.nk,$CLJS.Xi,$CLJS.T,$CLJS.qk,$CLJS.wi,$CLJS.Ui,$CLJS.oj,$CLJS.zk,$CLJS.vi,$CLJS.kk,$CLJS.pk],[new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Yn,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.Ar,$CLJS.gL,$CLJS.Yr,$CLJS.V5],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Lr,$CLJS.E6],null)],null),Gta,nta,"metabase/lib/drill_thru/fk_filter.cljc",25,1,36,36,$CLJS.U(new $CLJS.P(null,3,5,$CLJS.Q,[t8,n8,new $CLJS.h(null,
2,[$CLJS.Ro,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.zz,$CLJS.Wi],null),$CLJS.Bs,o8],null)],null)),"Inputs: [query :- :metabase.lib.schema/query stage-number :- :int {:keys [column value], :as _context} :- :metabase.lib.schema.drill-thru/context]\n  Return: [:maybe :metabase.lib.schema.drill-thru/drill-thru.fk-filter]\n          \n\n  When clicking on a foreign key value, filter this query by that column.\n\n  This has the same effect as the `\x3d` filter on a generic field (ie. not a key), but renders differently.\n\n  Contrast [[metabase.lib.drill-thru.object-details/object-detail-drill]], which shows the details of the foreign\n  object.",
$CLJS.n(a8)?a8.H:null])),q8,!1],null),new $CLJS.h(null,2,[$CLJS.vs,new $CLJS.Gc(function(){return b8},Eta,$CLJS.Jg([$CLJS.nk,$CLJS.Xi,$CLJS.T,$CLJS.qk,$CLJS.wi,$CLJS.Ui,$CLJS.oj,$CLJS.zk,$CLJS.vi,$CLJS.kk,$CLJS.pk],[new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Yn,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.Ar,$CLJS.gL,$CLJS.Yr,$CLJS.V5],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Lr,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.Mr,$CLJS.I5,$CLJS.F6,$CLJS.u6],null)],null)],null),jta,zta,"metabase/lib/drill_thru/object_details.cljc",
29,1,10,10,$CLJS.U(new $CLJS.P(null,3,5,$CLJS.Q,[t8,n8,r8],null)),"Inputs: [query :- :metabase.lib.schema/query stage-number :- :int context :- :metabase.lib.schema.drill-thru/context]\n  Return: [:maybe\n           [:or\n            :metabase.lib.schema.drill-thru/drill-thru.pk\n            :metabase.lib.schema.drill-thru/drill-thru.zoom\n            :metabase.lib.schema.drill-thru/drill-thru.fk-details]]\n          \n\n  When clicking a foreign key or primary key value, drill through to the details for that specific object.\n\n  Contrast [[metabase.lib.drill-thru.fk-filter/fk-filter-drill]], which filters this query to only those rows with a\n  specific value for a FK column.",
$CLJS.n(b8)?b8.H:null])),q8,!1],null),new $CLJS.h(null,2,[$CLJS.vs,new $CLJS.Gc(function(){return d8},Fta,$CLJS.Jg([$CLJS.nk,$CLJS.Xi,$CLJS.T,$CLJS.qk,$CLJS.wi,$CLJS.Ui,$CLJS.oj,$CLJS.zk,$CLJS.vi,$CLJS.kk,$CLJS.pk],[new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Yn,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.Ar,$CLJS.gL,$CLJS.Yr,$CLJS.V5],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Lr,$CLJS.T5],null)],null),Xsa,pta,"metabase/lib/drill_thru/pivot.cljc",21,1,120,120,$CLJS.U(new $CLJS.P(null,3,5,$CLJS.Q,[t8,n8,new $CLJS.h(null,
2,[$CLJS.Ro,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.zz,p8,$CLJS.Wi],null),$CLJS.Bs,r8],null)],null)),"Inputs: [query :- :metabase.lib.schema/query stage-number :- :int {:keys [column dimensions value], :as context} :- :metabase.lib.schema.drill-thru/context]\n  Return: [:maybe :metabase.lib.schema.drill-thru/drill-thru.pivot]\n          \n\n  Return all possible pivoting options on the given column and value.\n\n  See `:pivots` key, which holds a map `{t [breakouts...]}` where `t` is `:category`, `:location`, or `:time`.\n  If a key is missing, there are no breakouts of that kind.",
$CLJS.n(d8)?d8.H:null])),q8,!1],null),new $CLJS.h(null,2,[$CLJS.vs,new $CLJS.Gc(function(){return f8},gta,$CLJS.Jg([$CLJS.nk,$CLJS.Xi,$CLJS.T,$CLJS.qk,$CLJS.wi,$CLJS.Ui,$CLJS.oj,$CLJS.zk,$CLJS.vi,$CLJS.kk,$CLJS.pk],[new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Yn,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.Ar,$CLJS.gL,$CLJS.Yr,$CLJS.V5],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Lr,$CLJS.v6],null)],null),qta,Mta,"metabase/lib/drill_thru/quick_filter.cljc",28,1,90,90,$CLJS.U(new $CLJS.P(null,3,5,$CLJS.Q,[t8,n8,new $CLJS.h(null,
2,[$CLJS.Ro,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.zz,$CLJS.Wi],null),$CLJS.Bs,o8],null)],null)),"Inputs: [query :- :metabase.lib.schema/query stage-number :- :int {:keys [column value], :as _context} :- :metabase.lib.schema.drill-thru/context]\n  Return: [:maybe :metabase.lib.schema.drill-thru/drill-thru.quick-filter]\n          \n\n  Filter the current query based on the value clicked.\n\n  The options vary depending on the type of the field:\n  - `:is-null` and `:not-null` for a `NULL` value;\n  - `:\x3d` and `:!\x3d` for everything else;\n  - plus `:\x3c` and `:\x3e` for numeric and date columns.\n\n  Note that this returns a single `::drill-thru` value with 1 or more `:operators`; these are rendered as a set of small\n  buttons in a single row of the drop-down.",
$CLJS.n(f8)?f8.H:null])),q8,!1],null),new $CLJS.h(null,2,[$CLJS.vs,new $CLJS.Gc(function(){return g8},Lta,$CLJS.Jg([$CLJS.nk,$CLJS.Xi,$CLJS.T,$CLJS.qk,$CLJS.wi,$CLJS.Ui,$CLJS.oj,$CLJS.zk,$CLJS.vi,$CLJS.kk,$CLJS.pk],[new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Yn,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.Ar,$CLJS.gL,$CLJS.Yr,$CLJS.V5],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Lr,$CLJS.P6],null)],null),wta,eta,"metabase/lib/drill_thru/sort.cljc",20,1,53,53,$CLJS.U(new $CLJS.P(null,3,5,$CLJS.Q,[t8,n8,new $CLJS.h(null,
2,[$CLJS.Ro,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.zz,$CLJS.Wi],null),$CLJS.Bs,o8],null)],null)),"Inputs: [query :- :metabase.lib.schema/query stage-number :- :int {:keys [column value], :as _context} :- :metabase.lib.schema.drill-thru/context]\n  Return: [:maybe :metabase.lib.schema.drill-thru/drill-thru.sort]\n          \n\n  Sorting on a clicked column.",$CLJS.n(g8)?g8.H:null])),q8,!0],null),new $CLJS.h(null,2,[$CLJS.vs,new $CLJS.Gc(function(){return h8},Hta,$CLJS.Jg([$CLJS.nk,$CLJS.Xi,$CLJS.T,$CLJS.qk,
$CLJS.wi,$CLJS.Ui,$CLJS.oj,$CLJS.zk,$CLJS.vi,$CLJS.kk,$CLJS.pk],[new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Yn,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.Ar,$CLJS.gL,$CLJS.Yr,$CLJS.V5],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Lr,$CLJS.a6],null)],null),ota,tta,"metabase/lib/drill_thru/summarize_column.cljc",32,1,31,31,$CLJS.U(new $CLJS.P(null,3,5,$CLJS.Q,[t8,n8,new $CLJS.h(null,1,[$CLJS.Ro,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.zz,$CLJS.Wi],null)],null)],null)),"Inputs: [query :- :metabase.lib.schema/query stage-number :- :int {:keys [column value]} :- :metabase.lib.schema.drill-thru/context]\n  Return: [:maybe :metabase.lib.schema.drill-thru/drill-thru.summarize-column]\n          \n\n  A set of possible aggregations that can summarize this column: distinct values, sum, average.\n  Separate from [[summarize-column-by-time-drill]] which breaks out a column over time.",
$CLJS.n(h8)?h8.H:null])),q8,!0],null),new $CLJS.h(null,2,[$CLJS.vs,new $CLJS.Gc(function(){return i8},sta,$CLJS.Jg([$CLJS.nk,$CLJS.Xi,$CLJS.T,$CLJS.qk,$CLJS.wi,$CLJS.Ui,$CLJS.oj,$CLJS.zk,$CLJS.vi,$CLJS.kk,$CLJS.pk],[new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Yn,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.Ar,$CLJS.gL,$CLJS.Yr,$CLJS.V5],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Lr,$CLJS.Z5],null)],null),$sa,Dta,"metabase/lib/drill_thru/summarize_column_by_time.cljc",40,1,40,40,$CLJS.U(new $CLJS.P(null,3,5,$CLJS.Q,
[t8,n8,new $CLJS.h(null,1,[$CLJS.Ro,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.zz,$CLJS.Wi],null)],null)],null)),"Inputs: [query :- :metabase.lib.schema/query stage-number :- :int {:keys [column value]} :- :metabase.lib.schema.drill-thru/context]\n  Return: [:maybe :metabase.lib.schema.drill-thru/drill-thru.summarize-column-by-time]\n          \n\n  A breakout summarizing a column over time.\n  Separate from single-value [[summarize-column-drill]] for sum, average, and distinct value count.",$CLJS.n(i8)?
i8.H:null])),q8,!0],null),new $CLJS.h(null,2,[$CLJS.vs,new $CLJS.Gc(function(){return V7},vta,$CLJS.Jg([$CLJS.nk,$CLJS.Xi,$CLJS.T,$CLJS.qk,$CLJS.wi,$CLJS.Ui,$CLJS.oj,$CLJS.zk,$CLJS.vi,$CLJS.kk,$CLJS.pk],[new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Yn,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.Ar,$CLJS.gL,$CLJS.Yr,$CLJS.V5],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Lr,$CLJS.N6],null)],null),rta,ata,"metabase/lib/drill_thru/underlying_records.cljc",34,1,51,51,$CLJS.U(new $CLJS.P(null,3,5,$CLJS.Q,[t8,n8,new $CLJS.h(null,
2,[$CLJS.Ro,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.zz,s8,p8,$CLJS.Wi],null),$CLJS.Bs,o8],null)],null)),"Inputs: [query :- :metabase.lib.schema/query stage-number :- :int {:keys [column column-ref dimensions value], :as _context} :- :metabase.lib.schema.drill-thru/context]\n  Return: [:maybe :metabase.lib.schema.drill-thru/drill-thru.underlying-records]\n          \n\n  When clicking on a particular broken-out group, offer a look at the details of all the rows that went into this\n  bucket. Eg. distribution of People by State, then click New York and see the table of all People filtered by\n  `STATE \x3d 'New York'`.\n\n  There is another quite different case: clicking the legend of a chart with multiple bars or lines broken out by\n  category. Then `column` is nil!",
$CLJS.n(V7)?V7.H:null])),q8,!1],null),new $CLJS.h(null,2,[$CLJS.vs,new $CLJS.Gc(function(){return m8},bta,$CLJS.Jg([$CLJS.nk,$CLJS.Xi,$CLJS.T,$CLJS.qk,$CLJS.wi,$CLJS.Ui,$CLJS.oj,$CLJS.zk,$CLJS.vi,$CLJS.kk,$CLJS.pk],[new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Yn,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.Ar,$CLJS.gL,$CLJS.Yr,$CLJS.V5],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Lr,$CLJS.I6],null)],null),Ita,cta,"metabase/lib/drill_thru/zoom_in_timeseries.cljc",34,1,89,89,$CLJS.U(new $CLJS.P(null,3,5,$CLJS.Q,[t8,n8,
new $CLJS.h(null,2,[$CLJS.Ro,new $CLJS.P(null,1,5,$CLJS.Q,[p8],null),$CLJS.Bs,o8],null)],null)),"Inputs: [query :- :metabase.lib.schema/query stage-number :- :int {:keys [dimensions], :as _context} :- :metabase.lib.schema.drill-thru/context]\n  Return: [:maybe :metabase.lib.schema.drill-thru/drill-thru.zoom-in.timeseries]\n          \n\n  Zooms in on some window, showing it in finer detail.\n\n  For example: The month of a year, days or weeks of a quarter, smaller lat/long regions, etc.\n\n  This is different from the `:drill-thru/zoom` type, which is for showing the details of a single object.",
$CLJS.n(m8)?m8.H:null])),q8,!1],null),new $CLJS.h(null,2,[$CLJS.vs,new $CLJS.Gc(function(){return l8},lta,$CLJS.Jg([$CLJS.nk,$CLJS.Xi,$CLJS.T,$CLJS.qk,$CLJS.wi,$CLJS.Ui,$CLJS.oj,$CLJS.zk,$CLJS.vi,$CLJS.kk,$CLJS.pk],[new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Yn,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.Ar,$CLJS.gL,$CLJS.Yr,$CLJS.V5],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Lr,$CLJS.J6],null)],null),xta,Bta,"metabase/lib/drill_thru/zoom_in_geographic.cljc",34,1,182,182,$CLJS.U(new $CLJS.P(null,3,5,$CLJS.Q,[t8,
n8,new $CLJS.h(null,2,[$CLJS.Ro,new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.Wi],null),$CLJS.Bs,r8],null)],null)),"Inputs: [query :- :metabase.lib.schema/query stage-number :- :int {:keys [value], :as context} :- :metabase.lib.schema.drill-thru/context]\n  Return: [:maybe :metabase.lib.schema.drill-thru/drill-thru.zoom-in.geographic]\n          \n\n  Return a `:drill-thru/zoom-in.geographic` drill if appropriate. See docstring\n  for [[metabase.lib.drill-thru.zoom-in-geographic]] for more information on what circumstances this is returned in\n  and what it means to apply this drill.",
$CLJS.n(l8)?l8.H:null])),q8,!0],null),new $CLJS.h(null,2,[$CLJS.vs,new $CLJS.Gc(function(){return j8},Zsa,$CLJS.Jg([$CLJS.nk,$CLJS.Xi,$CLJS.T,$CLJS.qk,$CLJS.wi,$CLJS.Ui,$CLJS.oj,$CLJS.zk,$CLJS.vi,$CLJS.kk,$CLJS.pk],[new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Yn,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.Ar,$CLJS.gL,$CLJS.Yr,$CLJS.V5],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Lr,$CLJS.L6],null)],null),Ysa,ita,"metabase/lib/drill_thru/zoom_in_bins.cljc",31,1,86,86,$CLJS.U(new $CLJS.P(null,3,5,$CLJS.Q,[t8,n8,new $CLJS.h(null,
2,[$CLJS.Ro,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.zz,$CLJS.Wi],null),$CLJS.Bs,o8],null)],null)),"Inputs: [query :- :metabase.lib.schema/query stage-number :- :int {:keys [column value], :as _context} :- :metabase.lib.schema.drill-thru/context]\n  Return: [:maybe :metabase.lib.schema.drill-thru/drill-thru.zoom-in.binning]\n          \n\n  Return a drill thru that 'zooms in' on a breakout that uses `:binning` if applicable.\n  See [[metabase.lib.drill-thru.zoom-in-bins]] docstring for more information.",
$CLJS.n(j8)?j8.H:null])),q8,!0],null)],null);
$CLJS.Ota=function(){function a(d,e,f){try{return $CLJS.fg.g($CLJS.Df,Hsa(d)?function(){var l=Wsa(f);return function u(t){return new $CLJS.oe(null,function(){for(var v=t;;){var x=$CLJS.y(v);if(x){var A=x,C=$CLJS.z(A),G=$CLJS.O(C),K=$CLJS.J.g(G,$CLJS.vs),S=$CLJS.J.g(G,q8);if(x=$CLJS.y(function(W,Z,ha,ua,Ta,Cb,Oa,Wa){return function ob(Va){return new $CLJS.oe(null,function(Ja,cc,$d,Id){return function(){for(var rd=Va;;)if(rd=$CLJS.y(rd)){if($CLJS.Ad(rd)){var Al=$CLJS.lc(rd),e9=$CLJS.D(Al),sp=$CLJS.re(e9);
a:for(var LQ=0;;)if(LQ<e9){var FB=$CLJS.kd(Al,LQ);FB=Id.j?Id.j(d,e,FB):Id.call(null,d,e,FB);$CLJS.n(FB)&&sp.add(FB);LQ+=1}else{Al=!0;break a}return Al?$CLJS.ue($CLJS.we(sp),ob($CLJS.mc(rd))):$CLJS.ue($CLJS.we(sp),null)}sp=$CLJS.z(rd);sp=Id.j?Id.j(d,e,sp):Id.call(null,d,e,sp);if($CLJS.n(sp))return $CLJS.fe(sp,ob($CLJS.Lc(rd)));rd=$CLJS.Lc(rd)}else return null}}(W,Z,ha,ua,Ta,Cb,Oa,Wa),null,null)}}(v,C,G,K,S,A,x,l)($CLJS.n(function(){var W=S;return $CLJS.n(W)?l:W}())?l:new $CLJS.P(null,1,5,$CLJS.Q,[f],
null))))return $CLJS.hf.g(x,u($CLJS.Lc(v)));v=$CLJS.Lc(v)}else return null}},null,null)}(Nta)}():null)}catch(l){var k=l;throw $CLJS.hi(["Error getting available drill thrus for query: ",$CLJS.p.h($CLJS.PU(k))].join(""),new $CLJS.h(null,3,[$CLJS.aF,d,$CLJS.aL,e,$CLJS.MM,f],null),k);}}function b(d,e){return c.j?c.j(d,-1,e):c.call(null,d,-1,e)}var c=null;c=function(d,e,f){switch(arguments.length){case 2:return b.call(this,d,e);case 3:return a.call(this,d,e,f)}throw Error("Invalid arity: "+arguments.length);
};c.g=b;c.j=a;return c}();
$CLJS.Pta=function(){function a(d,e){return b.j?b.j(d,-1,e):b.call(null,d,-1,e)}var b=null,c=function(){function d(f,k,l,m){var t=null;if(3<arguments.length){t=0;for(var u=Array(arguments.length-3);t<u.length;)u[t]=arguments[t+3],++t;t=new $CLJS.w(u,0,null)}return e.call(this,f,k,l,t)}function e(f,k,l,m){var t=$CLJS.lA($CLJS.by);$CLJS.n($CLJS.kA("metabase.lib.drill-thru",t))&&$CLJS.jA("metabase.lib.drill-thru",t,$CLJS.RV("Applying drill thru: %s",$CLJS.H([$CLJS.SV(new $CLJS.h(null,4,[$CLJS.aF,f,$CLJS.aL,
k,fta,l,$CLJS.ps,m],null))])),null);return $CLJS.Te.N($CLJS.D5,f,k,l,m)}d.A=3;d.B=function(f){var k=$CLJS.z(f);f=$CLJS.B(f);var l=$CLJS.z(f);f=$CLJS.B(f);var m=$CLJS.z(f);f=$CLJS.Lc(f);return e(k,l,m,f)};d.l=e;return d}();b=function(d,e,f,k){switch(arguments.length){case 2:return a.call(this,d,e);default:var l=null;if(3<arguments.length){l=0;for(var m=Array(arguments.length-3);l<m.length;)m[l]=arguments[l+3],++l;l=new $CLJS.w(m,0,null)}return c.l(d,e,f,l)}throw Error("Invalid arity: "+arguments.length);
};b.A=3;b.B=c.B;b.g=a;b.l=c.l;return b}();