var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./malli.core.js");require("./medley.core.js");require("./metabase.lib.convert.js");require("./metabase.lib.dispatch.js");require("./metabase.lib.expression.js");require("./metabase.lib.hierarchy.js");require("./metabase.lib.metadata.js");require("./metabase.lib.metadata.calculation.js");require("./metabase.lib.normalize.js");require("./metabase.lib.schema.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.id.js");require("./metabase.lib.util.js");require("./metabase.mbql.util.js");require("./metabase.util.js");require("./metabase.util.malli.js");
'use strict';var X0,Y0;$CLJS.U0=function(a,b){return $CLJS.D(a)<=$CLJS.D(b)&&$CLJS.Re(function(c){return $CLJS.Hd(b,c)},a)};$CLJS.V0=function(a,b){return $CLJS.iA($CLJS.Ue(a),b)};$CLJS.W0=function(a){var b;a:for(b=a;;)if($CLJS.xd(b))b=$CLJS.$i.h(b);else{b=$CLJS.Fd(b)?b:null;break a}if($CLJS.n(b))return b;throw Error($CLJS.DD("Not something with an ID: {0}",$CLJS.H([$CLJS.Nh.l($CLJS.H([a]))])));};
X0=function(a){if(null!=a&&null!=a.rg)a=a.rg(a);else{var b=X0[$CLJS.wa(null==a?null:a)];if(null!=b)a=b.h?b.h(a):b.call(null,a);else if(b=X0._,null!=b)a=b.h?b.h(a):b.call(null,a);else throw $CLJS.bb("MetadataProvider.database",a);}return a};Y0=function(a,b){if(null!=a&&null!=a.sg)a=a.sg(a,b);else{var c=Y0[$CLJS.wa(null==a?null:a)];if(null!=c)a=c.g?c.g(a,b):c.call(null,a,b);else if(c=Y0._,null!=c)a=c.g?c.g(a,b):c.call(null,a,b);else throw $CLJS.bb("MetadataProvider.field",a);}return a};$CLJS.Z0=function(a){return X0($CLJS.v_(a))};
$CLJS.$0=function(a,b){return Y0($CLJS.v_(a),b)};$CLJS.T0.m(null,$CLJS.EV,function(a){return $CLJS.S0(a,new $CLJS.h(null,2,[$CLJS.nj,$CLJS.Ah,$CLJS.PK,$CLJS.We($CLJS.Sk,$CLJS.T0)],null))});$CLJS.i0.m(null,$CLJS.EV,function(){throw $CLJS.ii("You can't calculate a metadata map for a query! Use lib.metadata.calculation/returned-columns-method instead.",$CLJS.N);});$CLJS.k0.m(null,$CLJS.EV,function(a,b,c,d){return $CLJS.l0.v(a,b,$CLJS.bW(c,b),d)});$CLJS.c0.m(null,$CLJS.EV,function(a,b,c,d){return $CLJS.D_.v(a,b,$CLJS.bW(c,b),d)});
$CLJS.a1=function(){var a=$CLJS.Ye($CLJS.N),b=$CLJS.Ye($CLJS.N),c=$CLJS.Ye($CLJS.N),d=$CLJS.Ye($CLJS.N),e=$CLJS.J.j($CLJS.N,$CLJS.ij,$CLJS.Wh.o?$CLJS.Wh.o():$CLJS.Wh.call(null));return new $CLJS.ei($CLJS.Gh.g("metabase.lib.query","can-run-method"),function(f){return $CLJS.Fj.h($CLJS.bW(f,0))},e,a,b,c,d)}();$CLJS.a1.m(null,$CLJS.di,function(){return!0});
$CLJS.b1=function(){function a(d,e,f){return new $CLJS.h(null,4,[$CLJS.Fj,$CLJS.EV,$CLJS.ED,$CLJS.v_(e),$CLJS.qP,d,$CLJS.PK,f],null)}function b(d,e){var f=$CLJS.$i.h($CLJS.Z0(d));return c.j?c.j(f,d,e):c.call(null,f,d,e)}var c=null;c=function(d,e,f){switch(arguments.length){case 2:return b.call(this,d,e);case 3:return a.call(this,d,e,f)}throw Error("Invalid arity: "+arguments.length);};c.g=b;c.j=a;return c}();
$CLJS.c1=function(){var a=$CLJS.Ye($CLJS.N),b=$CLJS.Ye($CLJS.N),c=$CLJS.Ye($CLJS.N),d=$CLJS.Ye($CLJS.N),e=$CLJS.J.j(new $CLJS.h(null,1,[$CLJS.ij,$CLJS.zB],null),$CLJS.ij,$CLJS.Wh.o?$CLJS.Wh.o():$CLJS.Wh.call(null));return new $CLJS.ei($CLJS.Gh.g("metabase.lib.query","query-method"),function(f,k){return $CLJS.xB(k)},e,a,b,c,d)}();$CLJS.c1.m(null,$CLJS.PA,function(a,b){b=$CLJS.vY.h(b);return $CLJS.b1.g(a,$CLJS.PK.h(b))});
$CLJS.c1.m(null,$CLJS.EV,function(a,b){var c=$CLJS.O(b);b=$CLJS.J.g(c,$CLJS.rY);var d=$CLJS.v_(a),e=$CLJS.Ik.g($CLJS.R.j(c,$CLJS.ED,d),$CLJS.rY);a=$CLJS.PK.h(e);return $CLJS.n(b)?$CLJS.R.j(e,$CLJS.PK,$CLJS.fg.j($CLJS.Df,$CLJS.jf.h(function(f){var k=$CLJS.I(f,0,null);f=$CLJS.I(f,1,null);return function u(m,t){try{if($CLJS.zd(t)&&3===$CLJS.D(t))try{var v=$CLJS.F(t,0);if($CLJS.ie(v,$CLJS.FF))try{var x=$CLJS.F(t,1);if($CLJS.n($CLJS.Ue($CLJS.$z($CLJS.TA,$CLJS.Gi))(x)))try{var A=$CLJS.F(t,2);if($CLJS.n($CLJS.BD($CLJS.Cl,
$CLJS.Vl)(A))){var C=$CLJS.F(t,2),G=$CLJS.F(t,1),K=$CLJS.fm($CLJS.$0(d,C),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.TA,$CLJS.Gi],null));return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.FF,$CLJS.hl.l($CLJS.H([K,G])),C],null)}throw $CLJS.Y;}catch(ha){if(ha instanceof Error){var S=ha;if(S===$CLJS.Y)throw $CLJS.Y;throw S;}throw ha;}else throw $CLJS.Y;}catch(ha){if(ha instanceof Error){S=ha;if(S===$CLJS.Y)throw $CLJS.Y;throw S;}throw ha;}else throw $CLJS.Y;}catch(ha){if(ha instanceof Error)if(S=ha,S===$CLJS.Y)try{if(v=
$CLJS.F(t,0),$CLJS.ie(v,$CLJS.QA))try{x=$CLJS.F(t,1);if($CLJS.n($CLJS.Ue($CLJS.$z($CLJS.TA,$CLJS.Gi))(x))){G=$CLJS.F(t,1);var W=$CLJS.F(t,2);K=function(){try{return $CLJS.V0(function(ua){return $CLJS.E.g($CLJS.Bj,ua)},$CLJS.fm($CLJS.hd($CLJS.Ioa.j(e,k,W)),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.TA,$CLJS.Gi],null)))}catch(ua){return null}}();return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.QA,$CLJS.hl.l($CLJS.H([K,G])),W],null)}throw $CLJS.Y;}catch(ua){if(ua instanceof Error){var Z=ua;if(Z===$CLJS.Y)throw $CLJS.Y;
throw Z;}throw ua;}else throw $CLJS.Y;}catch(ua){if(ua instanceof Error){Z=ua;if(Z===$CLJS.Y)throw $CLJS.Y;throw Z;}throw ua;}else throw S;else throw ha;}else throw $CLJS.Y;}catch(ha){if(ha instanceof Error){S=ha;if(S===$CLJS.Y)return $CLJS.wW(u,m,t);throw S;}throw ha;}}($CLJS.Df,f)}),$CLJS.em($CLJS.es,a))):e});$CLJS.c1.m(null,$CLJS.oK,function(a,b){return $CLJS.b1.g(a,new $CLJS.P(null,1,5,$CLJS.Q,[new $CLJS.h(null,2,[$CLJS.Fj,$CLJS.rV,$CLJS.BO,$CLJS.W0(b)],null)],null))});
$CLJS.c1.m(null,$CLJS.GK,function(a,b){return $CLJS.b1.g(a,new $CLJS.P(null,1,5,$CLJS.Q,[new $CLJS.h(null,2,[$CLJS.Fj,$CLJS.rV,$CLJS.kV,$CLJS.W0(b)],null)],null))});