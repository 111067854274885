var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.string.js");require("./medley.core.js");require("./metabase.lib.aggregation.js");require("./metabase.lib.binning.js");require("./metabase.lib.card.js");require("./metabase.lib.convert.js");require("./metabase.lib.dispatch.js");require("./metabase.lib.equality.js");require("./metabase.lib.expression.js");require("./metabase.lib.join.js");require("./metabase.lib.join.util.js");require("./metabase.lib.metadata.js");require("./metabase.lib.metadata.calculation.js");require("./metabase.lib.normalize.js");require("./metabase.lib.options.js");require("./metabase.lib.ref.js");require("./metabase.lib.remove_replace.js");require("./metabase.lib.schema.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.id.js");require("./metabase.lib.schema.metadata.js");require("./metabase.lib.schema.temporal_bucketing.js");require("./metabase.lib.temporal_bucket.js");require("./metabase.lib.types.isa.js");require("./metabase.lib.util.js");require("./metabase.shared.util.i18n.js");require("./metabase.shared.util.time.js");require("./metabase.util.js");require("./metabase.util.humanization.js");require("./metabase.util.log.js");require("./metabase.util.malli.js");require("./metabase.util.malli.registry.js");
'use strict';var U6,W6,X6,Zra,$ra,asa,$6,a7,c7,d7,bsa,csa,f7,g7,dsa,esa;U6=function(a){var b=new $CLJS.h(null,3,[$CLJS.UD,$CLJS.p.h($CLJS.dE()),$CLJS.TA,$CLJS.TA.h(a),$CLJS.Gi,$CLJS.$z($CLJS.Gi,$CLJS.TA)(a)],null);return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.QA,b,$CLJS.$z($CLJS.MD,$CLJS.T)(a)],null)};$CLJS.V6=function(a){return $CLJS.Y4.h(a)};W6=function(a){return $CLJS.R.j(a,$CLJS.Fj,$CLJS.o4)};
X6=function(a,b){return $CLJS.E.g($CLJS.HD.h(a),$CLJS.fm(b,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.YD,$CLJS.GD,$CLJS.XD],null)))};Zra=function(a){return $CLJS.S0(a,new $CLJS.h(null,1,[$CLJS.YD,$CLJS.Ah],null))};
$ra=function(a,b){var c=$CLJS.Se(function(e){return $CLJS.QG(function(f){return $CLJS.E.g($CLJS.J.g(f,e),a)},b)},new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.iZ,$CLJS.T],null));if($CLJS.n(c))return c;c=$CLJS.lA($CLJS.ey);if($CLJS.n($CLJS.kA("metabase.lib.field",c))){var d=$CLJS.DD("Invalid :field clause: column {0} does not exist. Found: {1}",$CLJS.H([$CLJS.Nh.l($CLJS.H([a])),$CLJS.Nh.l($CLJS.H([$CLJS.Sk.g($CLJS.iZ,b)]))]));d instanceof Error?$CLJS.jA("metabase.lib.field",c,$CLJS.aw(),d):$CLJS.jA("metabase.lib.field",
c,$CLJS.aw.l($CLJS.H([d])),null)}return null};
asa=function(a,b,c){if($CLJS.n(Y6))return null;var d=Y6;Y6=!0;try{var e=$CLJS.v1(a,b),f=$CLJS.n(e)?$CLJS.bW(a,e):$CLJS.bW(a,b),k=function(){var m=$CLJS.Z6.h(f);if($CLJS.n(m))return m;m=$CLJS.bA(f,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.KU,$CLJS.ZV],null));if($CLJS.n(m))return m;m=$CLJS.n(function(){var u=$CLJS.kV.h(f);if($CLJS.n(u))return u;u=$CLJS.BO.h(f);if($CLJS.n(u))return u;u=$CLJS.vP.h(f);return $CLJS.n(u)?u:$CLJS.mE.h(f)}())?$CLJS.n0.j(a,b,f):null;if($CLJS.n(m))return m;m=$CLJS.lA($CLJS.ey);if($CLJS.n($CLJS.kA("metabase.lib.field",
m))){var t=$CLJS.DD("Cannot resolve column {0}: stage has no metadata",$CLJS.H([$CLJS.Nh.l($CLJS.H([c]))]));return t instanceof Error?$CLJS.jA("metabase.lib.field",m,$CLJS.aw(),t):$CLJS.jA("metabase.lib.field",m,$CLJS.aw.l($CLJS.H([t])),null)}return null}(),l=function(){var m=$CLJS.y(k);return m?$ra(c,k):m}();return $CLJS.n(l)?$CLJS.n(e)?$CLJS.R.j($CLJS.R.j($CLJS.g3($CLJS.Ik.l(l,$CLJS.$i,$CLJS.H([$CLJS.xK,$CLJS.lW,$CLJS.fW])),null),$CLJS.T,function(){var m=$CLJS.iZ.h(l);return $CLJS.n(m)?m:$CLJS.T.h(l)}()),
$CLJS.iK,$CLJS.VY):l:null}finally{Y6=d}};
$6=function(a,b,c){$CLJS.I(c,0,null);var d=$CLJS.I(c,1,null),e=$CLJS.O(d);d=$CLJS.J.g(e,$CLJS.QO);var f=$CLJS.I(c,2,null);c=$CLJS.hl.l($CLJS.H([function(){var k=$CLJS.TA.h(e);return $CLJS.n(k)?new $CLJS.h(null,1,[$CLJS.TA,k],null):null}(),function(){var k=$CLJS.$z($CLJS.Gi,$CLJS.TA)(e);return $CLJS.n(k)?new $CLJS.h(null,1,[$CLJS.Gi,k],null):null}(),function(){var k=$CLJS.RQ.h(e);return $CLJS.n(k)?new $CLJS.h(null,1,[$CLJS.lW,k],null):null}(),function(){var k=$CLJS.LF.h(e);return $CLJS.n(k)?new $CLJS.h(null,
1,[$CLJS.fW,k],null):null}(),$CLJS.Fd(f)?function(){var k=$CLJS.B1(a,b,f);return $CLJS.n(k)?k:new $CLJS.h(null,2,[$CLJS.Fj,$CLJS.gK,$CLJS.T,$CLJS.p.h(f)],null)}():$CLJS.n(d)?new $CLJS.h(null,2,[$CLJS.Fj,$CLJS.gK,$CLJS.T,$CLJS.p.h(f)],null):function(){var k=asa(a,b,f);return $CLJS.n(k)?k:new $CLJS.h(null,2,[$CLJS.Fj,$CLJS.gK,$CLJS.T,$CLJS.p.h(f)],null)}()]));return $CLJS.n(d)?$CLJS.g3(c,d):c};
a7=function(a){a=$CLJS.O(a);var b=$CLJS.J.g(a,$CLJS.fW);return $CLJS.n($CLJS.n(b)?$CLJS.Hd($CLJS.NG,b):b)?$CLJS.xj:$CLJS.$z($CLJS.Gi,$CLJS.TA)(a)};
$CLJS.b7=function(a,b,c,d){$CLJS.I(d,0,null);var e=$CLJS.I(d,1,null),f=$CLJS.O(e),k=$CLJS.J.g(f,$CLJS.UD),l=$CLJS.J.g(f,$CLJS.TA),m=$CLJS.J.g(f,$CLJS.RQ),t=$CLJS.J.g(f,$CLJS.Gi);e=$CLJS.J.g(f,$CLJS.QO);var u=$CLJS.J.g(f,$CLJS.aN),v=$CLJS.J.g(f,$CLJS.LF),x=$CLJS.hl.l;k=new $CLJS.h(null,2,[$CLJS.Fj,$CLJS.gK,$CLJS.G0,k],null);f=$CLJS.ID.h(f);a=$CLJS.n(f)?f:$CLJS.D_.j(a,b,d);c=x.call($CLJS.hl,$CLJS.H([k,c,new $CLJS.h(null,1,[$CLJS.ID,a],null)]));c=$CLJS.n(t)?$CLJS.R.j(c,$CLJS.Gi,t):c;l=$CLJS.n(l)?$CLJS.R.j(c,
$CLJS.TA,l):c;v=$CLJS.n(v)?$CLJS.R.j(l,$CLJS.fW,v):l;m=$CLJS.n(m)?$CLJS.R.j(v,$CLJS.lW,m):v;u=$CLJS.n(u)?$CLJS.R.j(m,$CLJS.wK,u):m;return $CLJS.n(e)?$CLJS.g3(u,e):u};c7=function(a,b,c){return $CLJS.n($CLJS.Se(function(d){return $CLJS.E.g($CLJS.ti.h(d),c)},a))?$CLJS.Sk.g(function(d){var e=$CLJS.Hd(d,b)?$CLJS.Ik.l(d,d,$CLJS.H([b])):d;return $CLJS.E.g($CLJS.ti.h(d),c)?$CLJS.R.j(e,b,!0):e},a):a};
d7=function(a){var b=$CLJS.n($CLJS.p1.h(a))?null:function(){var d=$CLJS.iK.h(a),e=new $CLJS.bh(null,new $CLJS.h(null,3,[$CLJS.lZ,null,$CLJS.sZ,null,$CLJS.VY,null],null),null);return e.h?e.h(d):e.call(null,d)}(),c=$CLJS.hl.l($CLJS.H([new $CLJS.h(null,3,[$CLJS.UD,$CLJS.p.h($CLJS.dE()),$CLJS.TA,$CLJS.TA.h(a),$CLJS.Gi,a7(a)],null),function(){var d=$CLJS.i1(a);return $CLJS.n(d)?new $CLJS.h(null,1,[$CLJS.QO,d],null):null}(),function(){var d=$CLJS.fW.h(a);return $CLJS.n(d)?new $CLJS.h(null,1,[$CLJS.LF,d],
null):null}(),function(){var d=$CLJS.jW.h(a);return $CLJS.n(d)?new $CLJS.h(null,1,[$CLJS.jW,d],null):null}(),function(){var d=$CLJS.lW.h(a);return $CLJS.n(d)?new $CLJS.h(null,1,[$CLJS.RQ,d],null):null}(),function(){var d=$CLJS.wK.h(a);return $CLJS.n(d)?new $CLJS.h(null,1,[$CLJS.aN,d],null):null}()]));b=($CLJS.n(b)?$CLJS.$z($CLJS.iZ,$CLJS.T):$CLJS.$z($CLJS.$i,$CLJS.T))(a);return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.FF,c,b],null)};
bsa=function(a,b){return $CLJS.mf(function(c){return $CLJS.E.g($CLJS.iK.h(c),$CLJS.cZ)},$CLJS.n0.v(a,b,$CLJS.bW(a,b),new $CLJS.h(null,3,[$CLJS.W_,!1,$CLJS.b0,!0,$CLJS.S_,!1],null)))};csa=new $CLJS.M("metabase.lib.field","field-values-search-info","metabase.lib.field/field-values-search-info",-468454179);$CLJS.e7=new $CLJS.M(null,"search-field-id","search-field-id",-699326724);
f7=new $CLJS.M("metabase.lib.field","field-values-search-info.has-field-values","metabase.lib.field/field-values-search-info.has-field-values",1753198226);g7=new $CLJS.M(null,"parent-id","parent-id",-1400729131);$CLJS.Z6=new $CLJS.M("metabase.lib.stage","cached-metadata","metabase.lib.stage/cached-metadata",-1406479151);dsa=new $CLJS.M(null,"earliest","earliest",-1928154382);esa=new $CLJS.M(null,"latest","latest",24323665);var hsa;$CLJS.T0.m(null,$CLJS.FF,function(a){var b=$CLJS.I(a,0,null),c=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Ah.h(b),$CLJS.S0(c,new $CLJS.h(null,2,[$CLJS.LF,$CLJS.Ah,$CLJS.RQ,Zra],null)),a],null)});var Y6=!1,h7=function h7(a,b){var d=$CLJS.$0(a,g7.h(b));a=$CLJS.n(g7.h(d))?h7.g?h7.g(a,d):h7.call(null,a,d):d;a=$CLJS.O(a);var e=$CLJS.J.g(a,$CLJS.T);return $CLJS.Tk.j(b,$CLJS.T,function(f){return[$CLJS.p.h(e),".",$CLJS.p.h(f)].join("")})};
$CLJS.g0.m(null,$CLJS.gK,function(a,b,c){return a7(c)});$CLJS.g0.m(null,$CLJS.FF,function(a,b,c){$CLJS.I(c,0,null);var d=$CLJS.I(c,1,null);d=$CLJS.O(d);d=$CLJS.J.g(d,$CLJS.LF);$CLJS.I(c,2,null);c=$6(a,b,c);c=$CLJS.n(d)?$CLJS.R.j(c,$CLJS.fW,d):c;return $CLJS.h0.j(a,b,c)});$CLJS.i0.m(null,$CLJS.gK,function(a,b,c){a=$CLJS.O(c);b=$CLJS.J.g(a,$CLJS.T);return $CLJS.R.j(a,$CLJS.T,b)});$CLJS.i0.m(null,$CLJS.FF,function(a,b,c){var d=$6(a,b,c);b=$CLJS.b7(a,b,d,c);return $CLJS.n(g7.h(b))?h7(a,b):b});
$CLJS.c0.m(null,$CLJS.gK,function(a,b,c,d){c=$CLJS.O(c);var e=$CLJS.J.g(c,$CLJS.ID),f=$CLJS.J.g(c,$CLJS.T),k=$CLJS.J.g(c,$CLJS.ti),l=$CLJS.J.g(c,$CLJS.lW),m=$CLJS.J.g(c,$CLJS.wZ),t=$CLJS.J.g(c,$CLJS.wK),u=$CLJS.J.g(c,$CLJS.xK);e=$CLJS.n(e)?e:"string"===typeof f?$CLJS.g1.g($CLJS.f1,f):$CLJS.p.h(f);$CLJS.E.g(d,$CLJS.E_)&&-1==e.indexOf(" → ")?($CLJS.n(t)?(t=$CLJS.$0(a,t),$CLJS.n(t)?a=(0,$CLJS.ma)($CLJS.fA($CLJS.ID.h($CLJS.G_.j(a,b,t)),$CLJS.oW,"")):(u=$CLJS.h1(a,u),a=$CLJS.D_.v(a,b,u,d))):a=null,m=$CLJS.n(a)?
a:$CLJS.n(m)?m:$CLJS.i1(c)):m=null;m=$CLJS.n(m)?[$CLJS.p.h(m)," → ",$CLJS.p.h(e)].join(""):e;return $CLJS.n(k)?(c=$CLJS.CA($CLJS.fA($CLJS.hh(k),"-"," ")),$CLJS.fa.j?$CLJS.fa.j("%s: %s",m,c):$CLJS.fa.call(null,"%s: %s",m,c)):$CLJS.n(l)?(c=$CLJS.V4(l,c),$CLJS.fa.j?$CLJS.fa.j("%s: %s",m,c):$CLJS.fa.call(null,"%s: %s",m,c)):m});
$CLJS.c0.m(null,$CLJS.FF,function(a,b,c,d){$CLJS.I(c,0,null);var e=$CLJS.I(c,1,null),f=$CLJS.O(e);e=$CLJS.J.g(f,$CLJS.RQ);var k=$CLJS.J.g(f,$CLJS.QO),l=$CLJS.J.g(f,$CLJS.LF);f=$CLJS.J.g(f,$CLJS.aN);$CLJS.I(c,2,null);c=$6(a,b,c);c=$CLJS.n(k)?$CLJS.R.j(c,$CLJS.wZ,k):c;l=$CLJS.n(l)?$CLJS.R.j(c,$CLJS.ti,l):c;e=$CLJS.n(e)?$CLJS.R.j(l,$CLJS.lW,e):l;e=$CLJS.n(f)?$CLJS.R.j(e,$CLJS.wK,f):e;return $CLJS.n(e)?$CLJS.D_.v(a,b,e,d):$CLJS.eE("[Unknown Field]")});
$CLJS.d0.m(null,$CLJS.gK,function(a,b,c){a=$CLJS.O(c);return $CLJS.J.g(a,$CLJS.T)});$CLJS.d0.m(null,$CLJS.FF,function(a,b,c){$CLJS.I(c,0,null);$CLJS.I(c,1,null);c=$6(a,b,c);return $CLJS.n(c)?$CLJS.e0.j(a,b,c):"unknown_field"});
$CLJS.j0.m(null,$CLJS.gK,function(a,b,c){return $CLJS.hl.l($CLJS.H([function(){var d=$CLJS.IH($CLJS.j0,$CLJS.di);return d.j?d.j(a,b,c):d.call(null,a,b,c)}(),$CLJS.E.g($CLJS.iK.h(c),$CLJS.lZ)?function(){var d=$CLJS.vZ.h(c);return $CLJS.n(d)?(d=$CLJS.y_(a,d),$CLJS.n(d)?new $CLJS.h(null,1,[$CLJS.kW,new $CLJS.h(null,2,[$CLJS.T,$CLJS.T.h(d),$CLJS.ID,$CLJS.T.h(d)],null)],null):null):null}():null]))});$CLJS.x0.m(null,$CLJS.FF,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);$CLJS.I(a,2,null);return $CLJS.LF.h(b)});
$CLJS.x0.m(null,$CLJS.gK,function(a){return $CLJS.fW.h(a)});
$CLJS.w0.m(null,$CLJS.FF,function(a,b){$CLJS.I(a,0,null);var c=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);if($CLJS.n(b)){var d=$CLJS.Hd($CLJS.NG,b),e=$CLJS.KB($CLJS.jW,$CLJS.Gi,$CLJS.TA)(c);c=$CLJS.R.l(c,$CLJS.LF,b,$CLJS.H([$CLJS.Gi,d?$CLJS.xj:e,$CLJS.jW,e]));return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.FF,c,a],null)}b=$CLJS.jW.h(c);c=$CLJS.n(b)?$CLJS.Ik.g($CLJS.R.j(c,$CLJS.Gi,b),$CLJS.jW):c;c=$CLJS.Ik.g(c,$CLJS.LF);return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.FF,c,a],null)});
$CLJS.w0.m(null,$CLJS.gK,function(a,b){return $CLJS.n(b)?$CLJS.R.l(a,$CLJS.fW,b,$CLJS.H([$CLJS.jW,$CLJS.KB($CLJS.jW,$CLJS.Gi,$CLJS.TA)(a)])):$CLJS.Ik.l(a,$CLJS.fW,$CLJS.H([$CLJS.jW]))});$CLJS.z0.m(null,$CLJS.FF,function(a,b,c){return $CLJS.A0.j(a,b,$6(a,b,c))});
$CLJS.z0.m(null,$CLJS.gK,function(a,b,c){if($CLJS.Ok.g($CLJS.iK.h(c),$CLJS.cZ)){a=$CLJS.$z($CLJS.Gi,$CLJS.TA)(c);b=null==c?null:$CLJS.oN.h(c);if(null==b)var d=null;else try{var e=$CLJS.MC.h($CLJS.nj.h(b));if($CLJS.n(e)){var f=$CLJS.O(e),k=$CLJS.J.g(f,dsa),l=$CLJS.J.g(f,esa),m=$CLJS.ooa.l($CLJS.H([$CLJS.u0.h(k),$CLJS.u0.h(l)]));d=isNaN(m)?null:$CLJS.n($CLJS.ir.g?$CLJS.ir.g(1,m):$CLJS.ir.call(null,1,m))?$CLJS.Vi:$CLJS.n($CLJS.ir.g?$CLJS.ir.g(31,m):$CLJS.ir.call(null,31,m))?$CLJS.zt:$CLJS.n($CLJS.ir.g?
$CLJS.ir.g(365,m):$CLJS.ir.call(null,365,m))?$CLJS.pj:$CLJS.yj}else d=null}catch(t){if(t instanceof Error)d=null;else throw t;}e=$CLJS.MB(a,$CLJS.MC)?$CLJS.woa:$CLJS.MB(a,$CLJS.mC)?$CLJS.voa:$CLJS.MB(a,$CLJS.lD)?$CLJS.uoa:$CLJS.Df;d=$CLJS.n(d)?c7(e,$CLJS.di,d):e;return $CLJS.n($CLJS.fW.h(c))?c7(d,$CLJS.L_,$CLJS.fW.h(c)):d}return $CLJS.Df});
$CLJS.Y4.m(null,$CLJS.FF,function(a){var b=null==a?null:$CLJS.aE(a);b=null==b?null:$CLJS.RQ.h(b);return null==b?null:$CLJS.R.l(b,$CLJS.Fj,$CLJS.X4,$CLJS.H([$CLJS.W4,function(c,d){return $6(c,d,a)}]))});$CLJS.Y4.m(null,$CLJS.gK,function(a){var b=null==a?null:$CLJS.lW.h(a);return null==b?null:$CLJS.R.l(b,$CLJS.Fj,$CLJS.X4,$CLJS.H([$CLJS.W4,$CLJS.Ve(a)]))});$CLJS.S4.m(null,$CLJS.FF,function(a,b){return $CLJS.bE(a,$CLJS.$D,$CLJS.H([$CLJS.RQ,b]))});
$CLJS.S4.m(null,$CLJS.gK,function(a,b){return $CLJS.$D(a,$CLJS.lW,b)});$CLJS.Z4.m(null,$CLJS.FF,function(a,b,c){return $CLJS.$4.j(a,b,$6(a,b,c))});
$CLJS.Z4.m(null,$CLJS.gK,function(a,b,c){b=$CLJS.O(c);c=$CLJS.J.g(b,$CLJS.Gi);var d=$CLJS.J.g(b,$CLJS.oN),e=$CLJS.J.g(b,$CLJS.Wj);if($CLJS.Ok.g($CLJS.iK.h(b),$CLJS.cZ)){var f=function(){var m=null==a?null:$CLJS.Z0(a);m=null==m?null:$CLJS.Gz.h(m);return null==m?null:$CLJS.Hd(m,$CLJS.RQ)}(),k=$CLJS.bA(d,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.nj,$CLJS.Qj],null)),l=$CLJS.V6(b);return function u(t){return new $CLJS.oe(null,function(){for(;;){var v=$CLJS.y(t);if(v){if($CLJS.Ad(v)){var x=$CLJS.lc(v),A=$CLJS.D(x),
C=$CLJS.re(A);return function(){for(var K=0;;)if(K<A){var S=$CLJS.kd(x,K),W=C,Z=S;S=X6(S,l)?$CLJS.R.j(Z,$CLJS.L_,!0):Z;W.add(S);K+=1}else return!0}()?$CLJS.ue($CLJS.we(C),u($CLJS.mc(v))):$CLJS.ue($CLJS.we(C),null)}var G=$CLJS.z(v);return $CLJS.fe(function(){var K=G;return X6(G,l)?$CLJS.R.j(K,$CLJS.L_,!0):K}(),u($CLJS.Lc(v)))}return null}},null,null)}($CLJS.Ua(function(){if($CLJS.n(f)){var t=$CLJS.Rn.h(k);return $CLJS.n(t)?$CLJS.jk.h(k):t}return f}())?null:$CLJS.MB(e,$CLJS.zj)?$CLJS.Sk.g(W6,new $CLJS.P(null,
5,5,$CLJS.Q,[$CLJS.U4(),new $CLJS.h(null,2,[$CLJS.ID,$CLJS.eE("Bin every 0.1 degrees"),$CLJS.HD,new $CLJS.h(null,2,[$CLJS.YD,$CLJS.XD,$CLJS.XD,.1],null)],null),new $CLJS.h(null,2,[$CLJS.ID,$CLJS.eE("Bin every 1 degree"),$CLJS.HD,new $CLJS.h(null,2,[$CLJS.YD,$CLJS.XD,$CLJS.XD,1],null)],null),new $CLJS.h(null,2,[$CLJS.ID,$CLJS.eE("Bin every 10 degrees"),$CLJS.HD,new $CLJS.h(null,2,[$CLJS.YD,$CLJS.XD,$CLJS.XD,10],null)],null),new $CLJS.h(null,2,[$CLJS.ID,$CLJS.eE("Bin every 20 degrees"),$CLJS.HD,new $CLJS.h(null,
2,[$CLJS.YD,$CLJS.XD,$CLJS.XD,20],null)],null)],null)):$CLJS.MB(c,$CLJS.Qj)&&!$CLJS.MB(e,$CLJS.oi)?$CLJS.Sk.g(W6,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.U4(),new $CLJS.h(null,2,[$CLJS.ID,$CLJS.eE("10 bins"),$CLJS.HD,new $CLJS.h(null,2,[$CLJS.YD,$CLJS.GD,$CLJS.GD,10],null)],null),new $CLJS.h(null,2,[$CLJS.ID,$CLJS.eE("50 bins"),$CLJS.HD,new $CLJS.h(null,2,[$CLJS.YD,$CLJS.GD,$CLJS.GD,50],null)],null),new $CLJS.h(null,2,[$CLJS.ID,$CLJS.eE("100 bins"),$CLJS.HD,new $CLJS.h(null,2,[$CLJS.YD,$CLJS.GD,$CLJS.GD,
100],null)],null)],null)):null)}return $CLJS.Df});$CLJS.LV.m(null,$CLJS.FF,function(a){return a});
$CLJS.LV.m(null,$CLJS.gK,function(a){a=$CLJS.O(a);var b=$CLJS.J.g(a,$CLJS.iK);switch(b instanceof $CLJS.M?b.S:null){case "source/aggregations":return b=new $CLJS.h(null,2,[$CLJS.UD,$CLJS.p.h($CLJS.dE()),$CLJS.Gi,$CLJS.$z($CLJS.Gi,$CLJS.TA)(a)],null),a=$CLJS.G0.h(a),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.uF,b,a],null);case "source/expressions":return U6(a);case "source/fields":case "source/breakouts":return $CLJS.n($CLJS.MD.h(a))?U6(a):d7(a);default:return d7(a)}});
$CLJS.fsa=function(){function a(e,f,k){k=$CLJS.Ge($CLJS.Sk.g($CLJS.NV,k));var l=bsa(e,f),m=$CLJS.fg.j($CLJS.ch,$CLJS.Yl(function(t){return $CLJS.H1.v(e,f,t,l)}),$CLJS.n(k)?k:$CLJS.Df);m=$CLJS.Rk.g(m,l);k=$CLJS.n(k)?$CLJS.fg.j(k,$CLJS.jf.h($CLJS.NV),m):null;return $CLJS.nW.l(e,f,$CLJS.$D,$CLJS.H([$CLJS.mE,k]))}function b(e,f){return d.j?d.j(e,-1,f):d.call(null,e,-1,f)}function c(e){return function(f,k){return d.j?d.j(f,k,e):d.call(null,f,k,e)}}var d=null;d=function(e,f,k){switch(arguments.length){case 1:return c.call(this,
e);case 2:return b.call(this,e,f);case 3:return a.call(this,e,f,k)}throw Error("Invalid arity: "+arguments.length);};d.h=c;d.g=b;d.j=a;return d}();$CLJS.i7=function(){function a(d,e){return $CLJS.mE.h($CLJS.bW(d,e))}function b(d){return c.g?c.g(d,-1):c.call(null,d,-1)}var c=null;c=function(d,e){switch(arguments.length){case 1:return b.call(this,d);case 2:return a.call(this,d,e)}throw Error("Invalid arity: "+arguments.length);};c.h=b;c.g=a;return c}();
$CLJS.gsa=function(){function a(d,e){var f=$CLJS.n0.v(d,e,$CLJS.bW(d,e),new $CLJS.h(null,3,[$CLJS.W_,!1,$CLJS.b0,!1,$CLJS.S_,!1],null)),k=$CLJS.i7.g(d,e);return $CLJS.td(k)?$CLJS.Sk.g(function(l){return $CLJS.R.j(l,$CLJS.jZ,!0)},f):$CLJS.e3(d,e,f,k)}function b(d){return c.g?c.g(d,-1):c.call(null,d,-1)}var c=null;c=function(d,e){switch(arguments.length){case 1:return b.call(this,d);case 2:return a.call(this,d,e)}throw Error("Invalid arity: "+arguments.length);};c.h=b;c.g=a;return c}();
hsa=function(){function a(d,e,f){var k=$CLJS.bW(d,e),l=$CLJS.E.g($CLJS.xB(f),$CLJS.uF)?$CLJS.l0:$CLJS.n0;k=l.j?l.j(d,e,k):l.call(null,d,e,k);return $CLJS.H1.v(d,e,f,k)}function b(d,e){return c.j?c.j(d,-1,e):c.call(null,d,-1,e)}var c=null;c=function(d,e,f){switch(arguments.length){case 2:return b.call(this,d,e);case 3:return a.call(this,d,e,f)}throw Error("Invalid arity: "+arguments.length);};c.g=b;c.j=a;return c}();
$CLJS.isa=function(){function a(d,e,f){f=$CLJS.RY.j(d,e,f);return hsa.j(d,e,f)}function b(d,e){return c.j?c.j(d,-1,e):c.call(null,d,-1,e)}var c=null;c=function(d,e,f){switch(arguments.length){case 2:return b.call(this,d,e);case 3:return a.call(this,d,e,f)}throw Error("Invalid arity: "+arguments.length);};c.g=b;c.j=a;return c}();$CLJS.X(f7,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.Jr,$CLJS.Dj,$CLJS.YY,$CLJS.Fz],null));
$CLJS.X(csa,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.ik,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.rK,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Lr,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,$CLJS.FK],null)],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.e7,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Lr,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,$CLJS.FK],null)],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.rZ,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,f7],null)],null)],null));