var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./malli.core.js");require("./malli.registry.js");require("./malli.util.js");
'use strict';var pD,qD,rD,Nfa,sD,uD,vD;
$CLJS.oD=function(a,b){return function(){function c(l,m,t){l=null==l?b:l;return a.j?a.j(l,m,t):a.call(null,l,m,t)}function d(l,m){l=null==l?b:l;return a.g?a.g(l,m):a.call(null,l,m)}function e(l){l=null==l?b:l;return a.h?a.h(l):a.call(null,l)}var f=null,k=function(){function l(t,u,v,x){var A=null;if(3<arguments.length){A=0;for(var C=Array(arguments.length-3);A<C.length;)C[A]=arguments[A+3],++A;A=new $CLJS.w(C,0,null)}return m.call(this,t,u,v,A)}function m(t,u,v,x){return $CLJS.Te.N(a,null==t?b:t,u,
v,x)}l.A=3;l.B=function(t){var u=$CLJS.z(t);t=$CLJS.B(t);var v=$CLJS.z(t);t=$CLJS.B(t);var x=$CLJS.z(t);t=$CLJS.Lc(t);return m(u,v,x,t)};l.l=m;return l}();f=function(l,m,t,u){switch(arguments.length){case 1:return e.call(this,l);case 2:return d.call(this,l,m);case 3:return c.call(this,l,m,t);default:var v=null;if(3<arguments.length){v=0;for(var x=Array(arguments.length-3);v<x.length;)x[v]=arguments[v+3],++v;v=new $CLJS.w(x,0,null)}return k.l(l,m,t,v)}throw Error("Invalid arity: "+arguments.length);
};f.A=3;f.B=k.B;f.h=e;f.g=d;f.j=c;f.l=k.l;return f}()};pD=function(a,b){this.db=a;this.Bh=b;this.C=393216;this.I=0};qD=function(a){for(var b=null;;){var c;a=$CLJS.lo.g(a,b);a=c=$CLJS.Kn(a)?$CLJS.En(a):a;if(!$CLJS.Kn(c))return a}};rD=function(a){$CLJS.I(a,0,null);a=$CLJS.I(a,1,null);return!($CLJS.xd(a)&&!0===$CLJS.fs.h(a))};
Nfa=function(a,b,c){var d=qD($CLJS.lo.g(a,c));return $CLJS.eq($CLJS.vn(d),$CLJS.sn(d),function(){var e=$CLJS.un(d);return b.h?b.h(e):b.call(null,e)}(),function(){var e=$CLJS.tn($CLJS.lo.g(d,null));return $CLJS.n(e)?e:c}())};sD=function(a){return function(b,c,d){var e=$CLJS.y(c);b=$CLJS.z(e);e=$CLJS.B(e);c=$CLJS.Sk.g(function(f){return $CLJS.lo.g(f,d)},c);return new $CLJS.P(null,3,5,$CLJS.Q,[c,$CLJS.Sk.g($CLJS.ys,c),$CLJS.eb(function(f,k){return a.j?a.j(f,k,d):a.call(null,f,k,d)},b,e)],null)}};
$CLJS.tD=function(a,b,c,d,e,f,k,l,m,t,u,v,x,A,C,G,K,S,W){this.form=a;this.options=b;this.forms=c;this.U=d;this.Ue=e;this.Xd=f;this.za=k;this.children=l;this.min=m;this.Eb=t;this.Ri=u;this.Rb=v;this.parent=x;this.type=A;this.qe=C;this.cache=G;this.max=K;this.Me=S;this.ii=W;this.C=393216;this.I=0};uD=function(a,b,c,d,e,f,k,l,m){this.Ue=a;this.qe=b;this.type=c;this.min=d;this.max=e;this.Xd=f;this.Eb=k;this.Rb=l;this.Me=m;this.C=393216;this.I=0};
vD=function(a){var b=$CLJS.O(a),c=$CLJS.J.g(b,$CLJS.nj),d=$CLJS.J.g(b,$CLJS.Rn),e=$CLJS.J.g(b,$CLJS.jk),f=$CLJS.J.g(b,$CLJS.$s),k=$CLJS.J.g(b,$CLJS.Gp),l=$CLJS.J.g(b,$CLJS.Si);return new uD(a,b,c,d,e,f,k,l,new $CLJS.h(null,1,[$CLJS.nj,$CLJS.kp],null))};$CLJS.X=function(a,b){$CLJS.Ph.v(wD,$CLJS.R,a,b);$CLJS.Ze($CLJS.xD,$CLJS.N);return null};pD.prototype.P=function(a,b){return new pD(this.db,b)};pD.prototype.O=function(){return this.Bh};pD.prototype.rd=$CLJS.Bc;
pD.prototype.bd=function(a,b){return $CLJS.fn($CLJS.hn($CLJS.q(this.db)),b)};var yD=function yD(a){switch(arguments.length){case 2:return yD.g(arguments[0],arguments[1]);case 3:return yD.j(arguments[0],arguments[1],arguments[2]);default:throw Error(["Invalid arity: ",$CLJS.p.h(arguments.length)].join(""));}};yD.g=function(a,b){return yD.j(a,b,null)};
yD.j=function(a,b,c){function d(v,x){return $CLJS.E.g($CLJS.ik,v)?new $CLJS.P(null,2,5,$CLJS.Q,[null,x],null):$CLJS.hf.g(new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.iq.h(x)],null),$CLJS.jq.h(x))}function e(v,x){return $CLJS.n($CLJS.n(v)?x:v)?$CLJS.hl.l($CLJS.H([v,x])):$CLJS.n(v)?v:x}a=$CLJS.n(a)?qD($CLJS.lo.g(a,c)):null;b=$CLJS.n(b)?qD($CLJS.lo.g(b,c)):null;var f=$CLJS.n(a)?$CLJS.Xn.h(a):null,k=$CLJS.n(b)?$CLJS.Xn.h(b):null,l=$CLJS.O(c),m=$CLJS.J.j(l,$CLJS.Ws,function(v,x){return x}),t=$CLJS.J.j(l,$CLJS.Zs,
function(v,x){return x});if(null==a)return b;if(null==b)return a;if($CLJS.Ua(function(){var v=new $CLJS.bh(null,new $CLJS.h(null,2,[$CLJS.Tr,null,$CLJS.ik,null],null),null);v=v.h?v.h(f):v.call(null,f);return $CLJS.n(v)?(v=new $CLJS.bh(null,new $CLJS.h(null,2,[$CLJS.Tr,null,$CLJS.ik,null],null),null),v.h?v.h(k):v.call(null,k)):v}()))return m.j?m.j(a,b,c):m.call(null,a,b,c);if($CLJS.E.g(f,$CLJS.ik)&&$CLJS.E.g(k,$CLJS.ik)){l=e($CLJS.sn(a),$CLJS.sn(b));var u=$CLJS.Ye($CLJS.ch);a=$CLJS.eb(function(v,x){var A=
$CLJS.I(x,0,null);if($CLJS.n(function(){var C=$CLJS.q(u);return C.h?C.h(A):C.call(null,A)}()))return $CLJS.eb(function(C,G){var K=$CLJS.I(G,0,null),S=$CLJS.ce.g;if($CLJS.E.g(K,A)){K=$CLJS.I(G,0,null);var W=$CLJS.I(G,1,null),Z=$CLJS.I(G,2,null);$CLJS.I(x,0,null);var ha=$CLJS.I(x,1,null),ua=$CLJS.I(x,2,null);G=rD(G);var Ta=rD(x);G=t.g?t.g(G,Ta):t.call(null,G,Ta);W=$CLJS.hl.l($CLJS.H([W,ha]));ua=new $CLJS.P(null,3,5,$CLJS.Q,[K,$CLJS.R.j(W,$CLJS.fs,$CLJS.Ua(G)),yD.j?yD.j(Z,ua,c):yD.call(null,Z,ua,c)],
null);K=$CLJS.I(ua,0,null);Z=$CLJS.I(ua,1,null);ua=$CLJS.I(ua,2,null);K=$CLJS.Ua(ua)?new $CLJS.P(null,2,5,$CLJS.Q,[K,Z],null):$CLJS.n($CLJS.n(Z)?!1===$CLJS.fs.h(Z)&&$CLJS.E.g(1,$CLJS.D(Z)):Z)?new $CLJS.P(null,2,5,$CLJS.Q,[K,ua],null):$CLJS.Ua($CLJS.y(Z))?new $CLJS.P(null,2,5,$CLJS.Q,[K,ua],null):!1===$CLJS.fs.h(Z)?new $CLJS.P(null,3,5,$CLJS.Q,[K,$CLJS.Ik.g(Z,$CLJS.fs),ua],null):new $CLJS.P(null,3,5,$CLJS.Q,[K,Z,ua],null)}else K=G;return S.call($CLJS.ce,C,K)},$CLJS.Df,v);$CLJS.Ph.j(u,$CLJS.ce,A);return $CLJS.ce.g(v,
x)},$CLJS.Df,$CLJS.fg.g($CLJS.un(a),$CLJS.un(b)));return $CLJS.eq($CLJS.ik,l,a,c)}return function(v,x){var A=$CLJS.y(v);v=$CLJS.z(A);var C=$CLJS.B(A);A=$CLJS.z(C);C=$CLJS.B(C);var G=$CLJS.y(x);x=$CLJS.z(G);var K=$CLJS.B(G);G=$CLJS.z(K);K=$CLJS.B(K);return $CLJS.eq($CLJS.Tr,e(v,x),$CLJS.hf.l(new $CLJS.P(null,1,5,$CLJS.Q,[yD.j(A,G,c)],null),C,$CLJS.H([K])),c)}(d(f,a),d(k,b))};yD.A=3;
var zD=function zD(a){switch(arguments.length){case 2:return zD.g(arguments[0],arguments[1]);case 3:return zD.j(arguments[0],arguments[1],arguments[2]);default:throw Error(["Invalid arity: ",$CLJS.p.h(arguments.length)].join(""));}};zD.g=function(a,b){return zD.j(a,b,null)};
zD.j=function(a,b,c){return yD.j(a,b,$CLJS.Tk.j($CLJS.Tk.j(c,$CLJS.Ws,$CLJS.oD($CLJS.Td,function(d,e,f){var k=$CLJS.E.g($CLJS.ys.g(d,null),$CLJS.ys.g(e,null));return $CLJS.n(k)?d:$CLJS.lo.g(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Mr,d,e],null),f)})),$CLJS.Zs,$CLJS.oD($CLJS.Td,function(d,e){return $CLJS.n(d)?e:d})))};zD.A=3;
var AD=function AD(a){switch(arguments.length){case 2:return AD.g(arguments[0],arguments[1]);case 3:return AD.j(arguments[0],arguments[1],arguments[2]);default:throw Error(["Invalid arity: ",$CLJS.p.h(arguments.length)].join(""));}};AD.g=function(a,b){return AD.j(a,b,null)};AD.j=function(a,b,c){var d=$CLJS.gh(b);return Nfa(a,function(e){return $CLJS.mf(function(f){f=$CLJS.I(f,0,null);return d.h?d.h(f):d.call(null,f)},e)},c)};AD.A=3;$CLJS.g=$CLJS.tD.prototype;
$CLJS.g.P=function(a,b){return new $CLJS.tD(this.form,this.options,this.forms,this.U,this.Ue,this.Xd,this.za,this.children,this.min,this.Eb,this.Ri,this.Rb,this.parent,this.type,this.qe,this.cache,this.max,this.Me,b)};$CLJS.g.O=function(){return this.ii};$CLJS.g.Ya=$CLJS.Bc;$CLJS.g.ya=function(){return $CLJS.pn(this.za)};$CLJS.g.La=function(){return this.options};$CLJS.g.Ta=function(a,b,c,d){return $CLJS.Ko(this,new $CLJS.P(null,1,5,$CLJS.Q,[this.za],null),b,c,d)};$CLJS.g.$a=function(){return this.U};
$CLJS.g.va=function(){return this.children};$CLJS.g.Sa=function(){return $CLJS.q(this.form)};$CLJS.g.Ra=function(a,b){return $CLJS.qn(this.za,b)};$CLJS.g.Za=function(){return this.parent};$CLJS.g.Wa=$CLJS.Bc;$CLJS.g.ia=$CLJS.ta(5);$CLJS.g.ie=$CLJS.Bc;$CLJS.g.Kd=function(){return this.za};$CLJS.g=uD.prototype;$CLJS.g.P=function(a,b){return new uD(this.Ue,this.qe,this.type,this.min,this.max,this.Xd,this.Eb,this.Rb,b)};$CLJS.g.O=function(){return this.Me};$CLJS.g.Xa=$CLJS.Bc;$CLJS.g.Pa=function(){return this.type};
$CLJS.g.Qa=function(){return this.Eb};$CLJS.g.Oa=function(a,b,c,d){var e=this;$CLJS.Sn(e.type,b,c,e.min,e.max);a=function(){var m=$CLJS.Ef(c);return e.Rb.j?e.Rb.j(b,m,d):e.Rb.call(null,b,m,d)}();var f=$CLJS.I(a,0,null),k=$CLJS.I(a,1,null),l=$CLJS.I(a,2,null);return new $CLJS.tD(new $CLJS.Kh(function(){return $CLJS.qo(e.type,b,k,d)}),d,k,b,e.Ue,e.Xd,l,f,e.min,e.Eb,a,e.Rb,this,e.type,e.qe,$CLJS.po(),e.max,e.Me,new $CLJS.h(null,1,[$CLJS.nj,$CLJS.Kp],null))};
$CLJS.zG=new $CLJS.M("metabase.lib.schema.temporal-bucketing","unit","metabase.lib.schema.temporal-bucketing/unit",1808010880);var wD;$CLJS.xD=$CLJS.Ye($CLJS.N);
wD=$CLJS.Ye($CLJS.hl.l($CLJS.H([$CLJS.ds(),new $CLJS.h(null,3,[$CLJS.Xs,vD(new $CLJS.h(null,2,[$CLJS.nj,$CLJS.Xs,$CLJS.Si,sD(yD)],null)),$CLJS.Vs,vD(new $CLJS.h(null,2,[$CLJS.nj,$CLJS.Vs,$CLJS.Si,sD(zD)],null)),$CLJS.at,vD(new $CLJS.h(null,5,[$CLJS.nj,$CLJS.at,$CLJS.$s,1,$CLJS.Rn,2,$CLJS.jk,2,$CLJS.Si,function(a,b,c){return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Tk.j(b,0,function(d){return $CLJS.lo.g(d,c)}),$CLJS.Tk.j(b,0,function(d){return $CLJS.ys.g(d,c)}),$CLJS.Te.g(AD,$CLJS.ce.g(b,c))],null)}],null))],
null)])));$CLJS.Ze($CLJS.wl,$CLJS.hn(new pD(wD,$CLJS.N)));