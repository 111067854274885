var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./shadow.js.shim.module$crc_32.js");require("./clojure.set.js");require("./clojure.string.js");require("./medley.core.js");require("./metabase.lib.common.js");require("./metabase.lib.dispatch.js");require("./metabase.lib.hierarchy.js");require("./metabase.lib.options.js");require("./metabase.lib.schema.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.expression.js");require("./metabase.lib.schema.id.js");require("./metabase.lib.schema.ref.js");require("./metabase.mbql.util.js");require("./metabase.shared.util.i18n.js");require("./metabase.util.js");require("./metabase.util.malli.js");
'use strict';var PV,Pla,Qla,XV,Rla,Sla,Ula,TV,Vla,Tla;PV=function(a){return a+1};$CLJS.QV=function(a){if("string"===typeof a){var b=$CLJS.oh(/[+-]?\d+/,a);return $CLJS.n(b)?(a=parseInt(a),a<=Number.MAX_SAFE_INTEGER&&a>=Number.MIN_SAFE_INTEGER?a:null):b}throw Error($CLJS.km(a));};
Pla=function(a){var b=new $CLJS.Ea,c=$CLJS.Yt;$CLJS.Yt=new $CLJS.sc(b);try{var d=$CLJS.Yt,e=$CLJS.Ua($CLJS.fv(d)),f=$CLJS.Yt;$CLJS.Yt=e?$CLJS.Xu(d):d;try{d=$CLJS.hv;$CLJS.hv=!0;try{$CLJS.jv(a)}finally{$CLJS.hv=d}$CLJS.E.g(0,$CLJS.eu($CLJS.Yt,$CLJS.gu))||$CLJS.ac($CLJS.Yt,"\n");$CLJS.du()}finally{$CLJS.Yt=f}$CLJS.vh($CLJS.p.h(b))}finally{$CLJS.Yt=c}};$CLJS.RV=function(a,b){return $CLJS.Te.j($CLJS.fa,a,b)};
$CLJS.SV=function(a){var b=$CLJS.Uu;$CLJS.Uu=120;try{var c=new $CLJS.Ea,d=$CLJS.Pa,e=$CLJS.Qa;$CLJS.Pa=!0;$CLJS.Qa=function(f){return c.append(f)};try{Pla(a)}finally{$CLJS.Qa=e,$CLJS.Pa=d}return $CLJS.p.h(c)}finally{$CLJS.Uu=b}};
Qla=function(a){a=$CLJS.O(a);var b=$CLJS.J.j(a,$CLJS.aV,$CLJS.Td),c=$CLJS.J.j(a,$CLJS.YU,function(f,k){return[$CLJS.p.h(f),"_",$CLJS.p.h(k)].join("")}),d=$CLJS.Ye($CLJS.N),e=$CLJS.Ye($CLJS.N);return function(){function f(m,t){for(;;){var u=b.h?b.h(t):b.call(null,t),v=function(){var A=new $CLJS.P(null,2,5,$CLJS.Q,[m,u],null),C=$CLJS.q(d);return C.h?C.h(A):C.call(null,A)}();if($CLJS.n(v))return v;var x=$CLJS.J.g($CLJS.Ph.v(e,$CLJS.Tk,u,$CLJS.oD(PV,0)),u);if($CLJS.E.g(x,1))return $CLJS.Ph.v(d,$CLJS.R,
new $CLJS.P(null,2,5,$CLJS.Q,[m,u],null),t),t;t=function(){var A=t,C=$CLJS.p.h(x);return c.g?c.g(A,C):c.call(null,A,C)}()}}function k(m){var t=l,u=t.g;null==TV&&(TV=$CLJS.Ye(0));var v=$CLJS.Gh.h([$CLJS.p.h("G__"),$CLJS.p.h($CLJS.Ph.g(TV,PV))].join(""));return u.call(t,v,m)}var l=null;l=function(m,t){switch(arguments.length){case 1:return k.call(this,m);case 2:return f.call(this,m,t)}throw Error("Invalid arity: "+arguments.length);};l.h=k;l.g=f;return l}()};
$CLJS.UV=function(a){var b=$CLJS.zd(a);return b?(b=$CLJS.z(a)instanceof $CLJS.M)?(a=$CLJS.J.g(a,1),$CLJS.xd(a)&&$CLJS.Hd(a,$CLJS.UD)):b:b};$CLJS.VV=function(a){return $CLJS.UV(a)?$CLJS.MD.h($CLJS.aE(a)):null};$CLJS.WV=function(a,b){return $CLJS.cE($CLJS.UV(a)?a:new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Ej,new $CLJS.h(null,2,[$CLJS.UD,$CLJS.p.h($CLJS.dE()),$CLJS.Gi,$CLJS.hE(a)],null),a],null),function(c){return $CLJS.Ik.l($CLJS.R.j(c,$CLJS.MD,b),$CLJS.T,$CLJS.H([$CLJS.ID]))})};
XV=function(a,b,c){var d=$CLJS.Hd(a,b)?$CLJS.Tk.j(a,b,function(e){return $CLJS.zd(e)&&$CLJS.E.g($CLJS.z(e),$CLJS.Tr)?$CLJS.Ef($CLJS.cf(1,e)):new $CLJS.P(null,1,5,$CLJS.Q,[e],null)}):a;return $CLJS.Hd(a,b)?$CLJS.WU(d,$CLJS.Fe([b,c])):d};Rla=function(a){var b=$CLJS.fm(a,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.BO,$CLJS.dQ],null));b=YV.h?YV.h(b):YV.call(null,b);return $CLJS.MV($CLJS.R.l(XV($CLJS.Ik.l(a,$CLJS.BO,$CLJS.H([$CLJS.dQ])),$CLJS.mR,$CLJS.SK),$CLJS.Fj,$CLJS.KK,$CLJS.H([$CLJS.PK,b])))};
Sla=function(a){return $CLJS.Sk.g(Rla,a)};Ula=function(a){return $CLJS.n(a)?$CLJS.R.j($CLJS.Tk.j($CLJS.Nl(a)?new $CLJS.h(null,1,[$CLJS.ZV,a],null):a,$CLJS.ZV,function(b){return $CLJS.Sk.g(function(c){return $CLJS.R.j($CLJS.dA(c,$CLJS.EA),$CLJS.Fj,$CLJS.gK)},b)}),$CLJS.Fj,Tla):null};
$CLJS.$V=function(a){if($CLJS.E.g($CLJS.Fj.h(a),$CLJS.EV))return a;var b=$CLJS.nj.h(a);b=b instanceof $CLJS.M?b.S:null;switch(b){case "native":return $CLJS.hl.l($CLJS.H([new $CLJS.h(null,2,[$CLJS.Fj,$CLJS.EV,$CLJS.PK,new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.hl.l($CLJS.H([new $CLJS.h(null,1,[$CLJS.Fj,$CLJS.yV],null),$CLJS.WU($CLJS.FP.h(a),new $CLJS.h(null,1,[$CLJS.aF,$CLJS.FP],null))]))],null)],null),$CLJS.Ik.l(a,$CLJS.nj,$CLJS.H([$CLJS.FP]))]));case "query":return $CLJS.hl.l($CLJS.H([new $CLJS.h(null,
2,[$CLJS.Fj,$CLJS.EV,$CLJS.PK,YV($CLJS.aF.h(a))],null),$CLJS.Ik.l(a,$CLJS.nj,$CLJS.H([$CLJS.aF]))]));default:throw Error(["No matching clause: ",$CLJS.p.h(b)].join(""));}};$CLJS.aW=function(a,b){a=$CLJS.O(a);a=$CLJS.J.g(a,$CLJS.PK);var c=0>b?$CLJS.D(a)+b:b;if(c>=$CLJS.D(a)||0>c)throw $CLJS.ii($CLJS.DD("Stage {0} does not exist",$CLJS.H([b])),new $CLJS.h(null,1,[Vla,$CLJS.D(a)],null));return c};
$CLJS.bW=function(a,b){a=$CLJS.$V(a);a=$CLJS.O(a);var c=$CLJS.J.g(a,$CLJS.PK);return $CLJS.J.g($CLJS.Ef(c),$CLJS.aW(a,b))};$CLJS.cW=function(a){return"string"===typeof a&&(a=$CLJS.ph(/^card__(\d+)$/,a),$CLJS.n(a))?($CLJS.I(a,0,null),a=$CLJS.I(a,1,null),$CLJS.QV(a)):null};$CLJS.eW=function(){return $CLJS.Qk.g(dW,Qla($CLJS.H([$CLJS.aV,$CLJS.AA,$CLJS.YU,function(a,b){return dW.h([$CLJS.p.h(a),"_",$CLJS.p.h(b)].join(""))}])))};TV=null;
$CLJS.fW=new $CLJS.M("metabase.lib.field","temporal-unit","metabase.lib.field/temporal-unit",602312727);$CLJS.gW=new $CLJS.M("metabase.lib.util","cannot-remove-final-join-condition","metabase.lib.util/cannot-remove-final-join-condition",-470911224);$CLJS.hW=new $CLJS.M(null,"join","join",-758861890);$CLJS.iW=new $CLJS.M(null,"stage","stage",1843544772);Vla=new $CLJS.M(null,"num-stages","num-stages",1426797535);$CLJS.ZV=new $CLJS.M(null,"columns","columns",1998437288);
$CLJS.jW=new $CLJS.M("metabase.lib.field","original-effective-type","metabase.lib.field/original-effective-type",34164372);$CLJS.kW=new $CLJS.M(null,"table","table",-564943036);Tla=new $CLJS.M("metadata","results","metadata/results",330329298);$CLJS.lW=new $CLJS.M("metabase.lib.field","binning","metabase.lib.field/binning",-222751823);var Wla,Xla,Yla,Zla,$la,YV,dW;Wla=$CLJS.Ye($CLJS.N);Xla=$CLJS.Ye($CLJS.N);Yla=$CLJS.Ye($CLJS.N);Zla=$CLJS.Ye($CLJS.N);$la=$CLJS.J.j(new $CLJS.h(null,1,[$CLJS.ij,$CLJS.zB],null),$CLJS.ij,$CLJS.Wh.o?$CLJS.Wh.o():$CLJS.Wh.call(null));$CLJS.mW=new $CLJS.ei($CLJS.Gh.g("metabase.lib.util","custom-name-method"),$CLJS.xB,$la,Wla,Xla,Yla,Zla);$CLJS.mW.m(null,$CLJS.di,function(a){return $CLJS.UV(a)?$CLJS.$z($CLJS.ID,$CLJS.MD)($CLJS.aE(a)):null});
YV=function YV(a){a=$CLJS.O(a);var c=$CLJS.J.g(a,$CLJS.dQ),d=$CLJS.J.g(a,$CLJS.nO);c=$CLJS.n(c)?YV.h?YV.h(c):YV.call(null,c):$CLJS.Df;d=Ula(d);var e=$CLJS.D(c)-1;c=$CLJS.n($CLJS.n(d)?!(0>e):d)?$CLJS.sL(c,new $CLJS.P(null,2,5,$CLJS.Q,[e,$CLJS.KU],null),d):c;d=$CLJS.n($CLJS.FP.h(a))?$CLJS.yV:$CLJS.rV;a=$CLJS.hl.l($CLJS.H([new $CLJS.h(null,1,[$CLJS.Fj,d],null),$CLJS.Ik.l(a,$CLJS.dQ,$CLJS.H([$CLJS.nO]))]));a=$CLJS.y($CLJS.$M.h(a))?$CLJS.Tk.j(a,$CLJS.$M,Sla):a;a=XV(a,$CLJS.iF,$CLJS.FV);return $CLJS.ce.g(c,
a)};
$CLJS.nW=function(){function a(c,d,e,f){var k=null;if(3<arguments.length){k=0;for(var l=Array(arguments.length-3);k<l.length;)l[k]=arguments[k+3],++k;k=new $CLJS.w(l,0,null)}return b.call(this,c,d,e,k)}function b(c,d,e,f){c=$CLJS.$V(c);c=$CLJS.O(c);var k=$CLJS.J.g(c,$CLJS.PK);d=$CLJS.aW(c,d);e=$CLJS.Te.N($CLJS.Tk,$CLJS.Ef(k),d,e,f);return $CLJS.R.j(c,$CLJS.PK,e)}a.A=3;a.B=function(c){var d=$CLJS.z(c);c=$CLJS.B(c);var e=$CLJS.z(c);c=$CLJS.B(c);var f=$CLJS.z(c);c=$CLJS.Lc(c);return b(d,e,f,c)};a.l=
b;return a}();
dW=function(){function a(d,e){if((new TextEncoder).encode(d).length<=e)return d;var f;a:for(f=($CLJS.KV.str(d,0)>>>0).toString(16);;)if(8>$CLJS.D(f))f=["0",$CLJS.p.h(f)].join("");else break a;e=new Uint8Array(e-9);e=(new TextEncoder).encodeInto(d,e);return[d.substring(0,e.read),"_",$CLJS.p.h(f)].join("")}function b(d){return c.g?c.g(d,60):c.call(null,d,60)}var c=null;c=function(d,e){switch(arguments.length){case 1:return b.call(this,d);case 2:return a.call(this,d,e)}throw Error("Invalid arity: "+arguments.length);
};c.h=b;c.g=a;return c}();$CLJS.oW=RegExp(" id$","i");