var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./metabase.lib.card.js");require("./metabase.lib.equality.js");require("./metabase.lib.join.js");require("./metabase.lib.join.util.js");require("./metabase.lib.metadata.js");require("./metabase.lib.metadata.calculation.js");require("./metabase.lib.options.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.id.js");require("./metabase.lib.util.js");require("./metabase.util.malli.js");
'use strict';var D3,yqa,G3,H3,I3;$CLJS.C3=function(a,b,c){var d=$CLJS.bW(a,b);d=$CLJS.O(d);d=$CLJS.J.g(d,$CLJS.$M);var e=$CLJS.QG(function(f){return $CLJS.E.g($CLJS.qE.h(f),c)},d);if($CLJS.n(e))return e;throw $CLJS.ii($CLJS.DD("No join named {0}, found: {1}",$CLJS.H([$CLJS.Nh.l($CLJS.H([c])),$CLJS.Nh.l($CLJS.H([$CLJS.Sk.g($CLJS.qE,d)]))])),new $CLJS.h(null,3,[$CLJS.QO,c,$CLJS.aF,a,$CLJS.aL,b],null));};D3=new $CLJS.M("metabase.lib.column-group","group-type","metabase.lib.column-group/group-type",6276454);
$CLJS.E3=new $CLJS.M("metadata","column-group","metadata/column-group",-902258024);yqa=new $CLJS.M(null,"fk-reference-name","fk-reference-name",74295339);$CLJS.F3=new $CLJS.M("metabase.lib.column-group","columns","metabase.lib.column-group/columns",1229087729);G3=new $CLJS.M("group-type","main","group-type/main",-938346573);H3=new $CLJS.M("group-type","join.implicit","group-type/join.implicit",-684408229);I3=new $CLJS.M("group-type","join.explicit","group-type/join.explicit",-627022785);var zqa,Aqa,Bqa,Cqa,Dqa;$CLJS.i0.m(null,$CLJS.E3,function(a,b,c){return c});var J3=function(){var a=$CLJS.Ye($CLJS.N),b=$CLJS.Ye($CLJS.N),c=$CLJS.Ye($CLJS.N),d=$CLJS.Ye($CLJS.N),e=$CLJS.J.j($CLJS.N,$CLJS.ij,$CLJS.Wh.o?$CLJS.Wh.o():$CLJS.Wh.call(null));return new $CLJS.ei($CLJS.Gh.g("metabase.lib.column-group","display-info-for-group-method"),function(f,k,l){return D3.h(l)},e,a,b,c,d)}();
J3.m(null,G3,function(a,b){return $CLJS.hl.l($CLJS.H([function(){var c=$CLJS.bW(a,b),d=function(){var e=$CLJS.BO.h(c);e=null==e?null:$CLJS.w_(a,e);return $CLJS.n(e)?$CLJS.G_.j(a,b,e):null}();if($CLJS.n(d))return d;d=function(){var e=$CLJS.kV.h(c);e=null==e?null:$CLJS.y_(a,e);return $CLJS.n(e)?$CLJS.G_.j(a,b,e):null}();if($CLJS.n(d))return d;d=$CLJS.B($CLJS.PK.h(a))?new $CLJS.h(null,1,[$CLJS.ID,""],null):null;return $CLJS.n(d)?d:new $CLJS.h(null,1,[$CLJS.ID,$CLJS.D_.j(a,b,c)],null)}(),new $CLJS.h(null,
2,[$CLJS.I_,!1,$CLJS.K_,!1],null)]))});
J3.m(null,I3,function(a,b,c){c=$CLJS.O(c);var d=$CLJS.J.g(c,$CLJS.QO),e=$CLJS.J.g(c,$CLJS.xK),f=$CLJS.J.g(c,$CLJS.yQ);return $CLJS.hl.l($CLJS.H([function(){var k=$CLJS.n(d)?function(){var l=$CLJS.C3(a,b,d);return $CLJS.n(l)?$CLJS.G_.j(a,b,l):null}():null;if($CLJS.n(k))return k;k=$CLJS.n(e)?function(){var l=$CLJS.w_(a,e);return $CLJS.n(l)?$CLJS.G_.j(a,b,l):null}():null;return $CLJS.n(k)?k:$CLJS.n(f)?(k=$CLJS.y_(a,f),$CLJS.n(k)?$CLJS.G_.j(a,b,k):new $CLJS.h(null,1,[$CLJS.ID,$CLJS.n1(f)],null)):null}(),
new $CLJS.h(null,2,[$CLJS.I_,!0,$CLJS.K_,!1],null)]))});
J3.m(null,H3,function(a,b,c){c=$CLJS.O(c);var d=$CLJS.J.g(c,$CLJS.wK);c=$CLJS.hl.l;if("string"===typeof d){var e=$CLJS.bW(a,b);e=null==e?null:$CLJS.n0.j(a,b,e);d=null==e?null:$CLJS.H1.v(a,b,$CLJS.MV(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.FF,new $CLJS.h(null,1,[$CLJS.TA,$CLJS.Bj],null),d],null)),e);d=null==d?null:$CLJS.$i.h(d);d=null==d?null:$CLJS.$0(a,d)}else d=$CLJS.$0(a,d);$CLJS.n(d)?(a=$CLJS.G_.j(a,b,d),a=$CLJS.R.j(a,yqa,(0,$CLJS.ma)($CLJS.fA($CLJS.ID.h(a),$CLJS.oW,"")))):a=null;return c.call($CLJS.hl,
$CLJS.H([a,new $CLJS.h(null,2,[$CLJS.I_,!1,$CLJS.K_,!0],null)]))});$CLJS.j0.m(null,$CLJS.E3,function(a,b,c){return J3.j(a,b,c)});zqa=$CLJS.Ye($CLJS.N);Aqa=$CLJS.Ye($CLJS.N);Bqa=$CLJS.Ye($CLJS.N);Cqa=$CLJS.Ye($CLJS.N);Dqa=$CLJS.J.j($CLJS.N,$CLJS.ij,$CLJS.Wh.o?$CLJS.Wh.o():$CLJS.Wh.call(null));$CLJS.K3=new $CLJS.ei($CLJS.Gh.g("metabase.lib.column-group","column-group-info-method"),$CLJS.iK,Dqa,zqa,Aqa,Bqa,Cqa);
$CLJS.K3.m(null,$CLJS.eZ,function(a){return new $CLJS.h(null,3,[D3,H3,$CLJS.wK,$CLJS.wK.h(a),$CLJS.Q_,$CLJS.Q_.h(a)],null)});
$CLJS.K3.m(null,$CLJS.fZ,function(a){var b=$CLJS.O(a),c=$CLJS.J.g(b,$CLJS.xK),d=$CLJS.J.g(b,$CLJS.vZ);a=$CLJS.hl.l;var e=new $CLJS.h(null,1,[D3,I3],null);b=$CLJS.i1(b);b=$CLJS.n(b)?new $CLJS.h(null,1,[$CLJS.QO,b],null):null;$CLJS.n(b)?c=b:(d=$CLJS.n(d)?new $CLJS.h(null,1,[$CLJS.yQ,d],null):null,c=$CLJS.n(d)?d:$CLJS.n(c)?new $CLJS.h(null,1,[$CLJS.xK,c],null):null);return a.call($CLJS.hl,$CLJS.H([e,c]))});$CLJS.K3.m(null,$CLJS.di,function(){return new $CLJS.h(null,1,[D3,G3],null)});