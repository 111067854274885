var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.set.js");require("./metabase.util.malli.registry.js");
'use strict';var mga,nga,oga,pga,qga,rga,sga;$CLJS.pG=new $CLJS.M(null,"second","second",-444702010);$CLJS.qG=new $CLJS.M(null,"second-of-minute","second-of-minute",222734326);$CLJS.rG=new $CLJS.M("metabase.lib.schema.temporal-bucketing","unit.date-time","metabase.lib.schema.temporal-bucketing/unit.date-time",1924841408);$CLJS.sG=new $CLJS.M(null,"millisecond","millisecond",-540123566);$CLJS.tG=new $CLJS.M(null,"year-of-era","year-of-era",682445876);
mga=new $CLJS.M("metabase.lib.schema.temporal-bucketing","unit.date-time.extract","metabase.lib.schema.temporal-bucketing/unit.date-time.extract",1230935001);nga=new $CLJS.M("metabase.lib.schema.temporal-bucketing","unit.time","metabase.lib.schema.temporal-bucketing/unit.time",713582593);$CLJS.uG=new $CLJS.M("metabase.lib.schema.temporal-bucketing","unit.date-time.truncate","metabase.lib.schema.temporal-bucketing/unit.date-time.truncate",686665771);
$CLJS.vG=new $CLJS.M("metabase.lib.schema.temporal-bucketing","unit.time.interval","metabase.lib.schema.temporal-bucketing/unit.time.interval",-718964325);$CLJS.wG=new $CLJS.M("metabase.lib.schema.temporal-bucketing","unit.date","metabase.lib.schema.temporal-bucketing/unit.date",1876937510);oga=new $CLJS.M("metabase.lib.schema.temporal-bucketing","unit.time.extract","metabase.lib.schema.temporal-bucketing/unit.time.extract",-456342719);
pga=new $CLJS.M("metabase.lib.schema.temporal-bucketing","unit.date.truncate","metabase.lib.schema.temporal-bucketing/unit.date.truncate",1199064479);qga=new $CLJS.M("metabase.lib.schema.temporal-bucketing","unit.date.extract","metabase.lib.schema.temporal-bucketing/unit.date.extract",1595055425);rga=new $CLJS.M("metabase.lib.schema.temporal-bucketing","option","metabase.lib.schema.temporal-bucketing/option",-1756789401);
$CLJS.xG=new $CLJS.M("metabase.lib.schema.temporal-bucketing","unit.date-time.interval","metabase.lib.schema.temporal-bucketing/unit.date-time.interval",659301368);$CLJS.yG=new $CLJS.M("option","temporal-bucketing","option/temporal-bucketing",-108914562);$CLJS.AG=new $CLJS.M("metabase.lib.schema.temporal-bucketing","unit.date.interval","metabase.lib.schema.temporal-bucketing/unit.date.interval",-1463811525);
sga=new $CLJS.M("metabase.lib.schema.temporal-bucketing","unit.time.truncate","metabase.lib.schema.temporal-bucketing/unit.time.truncate",-948820335);var BG=new $CLJS.P(null,8,5,$CLJS.Q,[$CLJS.Hi,$CLJS.Hj,$CLJS.Gj,$CLJS.ri,$CLJS.mk,$CLJS.Bi,$CLJS.ki,$CLJS.tG],null),CG=$CLJS.gh(BG),tga,uga,vga;$CLJS.X(qga,$CLJS.fg.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Jr,new $CLJS.h(null,1,[$CLJS.Ys,"Valid date extraction unit"],null)],null),CG));var DG=new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.zt,$CLJS.pj,$CLJS.yj,$CLJS.qi,$CLJS.ki],null),EG=$CLJS.gh(DG);
$CLJS.X(pga,$CLJS.fg.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Jr,new $CLJS.h(null,1,[$CLJS.Ys,"Valid date truncation unit"],null)],null),EG));$CLJS.FG=$CLJS.fg.j($CLJS.Df,$CLJS.jl.o(),$CLJS.hf.g(DG,BG));tga=$CLJS.gh($CLJS.FG);$CLJS.X($CLJS.wG,$CLJS.fg.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Jr,new $CLJS.h(null,1,[$CLJS.Ys,"Valid date bucketing unit"],null)],null),tga));var GG=new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.qG,$CLJS.jj,$CLJS.xi],null),HG=$CLJS.gh(GG);
$CLJS.X(oga,$CLJS.fg.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Jr,new $CLJS.h(null,1,[$CLJS.Ys,"Valid time extraction unit"],null)],null),HG));var IG=new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.sG,$CLJS.pG,$CLJS.Vi,$CLJS.rk],null),JG=$CLJS.gh(IG);$CLJS.X(sga,$CLJS.fg.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Jr,new $CLJS.h(null,1,[$CLJS.Ys,"Valid time truncation unit"],null)],null),JG));$CLJS.KG=$CLJS.fg.j($CLJS.Df,$CLJS.jl.o(),$CLJS.hf.g(IG,GG));uga=$CLJS.gh($CLJS.KG);
$CLJS.X(nga,$CLJS.fg.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Jr,new $CLJS.h(null,1,[$CLJS.Ys,"Valid time bucketing unit"],null)],null),uga));$CLJS.LG=$CLJS.fg.j($CLJS.Df,$CLJS.jl.o(),$CLJS.hf.l(IG,DG,$CLJS.H([GG,BG])));vga=$CLJS.gh($CLJS.LG);$CLJS.X($CLJS.rG,$CLJS.fg.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Jr,new $CLJS.h(null,1,[$CLJS.Ys,"Valid datetime bucketing unit"],null)],null),$CLJS.LG));var wga=$CLJS.ce.g(vga,$CLJS.di);
$CLJS.X($CLJS.zG,$CLJS.fg.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Jr,new $CLJS.h(null,1,[$CLJS.Ys,"Valid temporal bucketing unit"],null)],null),wga));$CLJS.MG=$CLJS.At.g(EG,JG);$CLJS.X($CLJS.uG,$CLJS.fg.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Jr,new $CLJS.h(null,1,[$CLJS.Ys,"Valid datetime truncation unit"],null)],null),$CLJS.MG));$CLJS.NG=$CLJS.At.g(CG,HG);$CLJS.X(mga,$CLJS.fg.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Jr,new $CLJS.h(null,1,[$CLJS.Ys,"Valid datetime extraction unit"],null)],null),$CLJS.NG));
var OG=$CLJS.ce.g(EG,$CLJS.ki);$CLJS.X($CLJS.AG,$CLJS.fg.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Jr,new $CLJS.h(null,1,[$CLJS.Ys,"Valid date interval unit"],null)],null),OG));$CLJS.X($CLJS.vG,$CLJS.fg.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Jr,new $CLJS.h(null,1,[$CLJS.Ys,"Valid time interval unit"],null)],null),JG));var xga=$CLJS.At.g(OG,JG);$CLJS.X($CLJS.xG,$CLJS.fg.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Jr,new $CLJS.h(null,1,[$CLJS.Ys,"Valid datetime interval unit"],null)],null),xga));
$CLJS.X(rga,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.ik,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Fj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.or,$CLJS.yG],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ti,$CLJS.zG],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.di,new $CLJS.h(null,1,[$CLJS.fs,!0],null),$CLJS.bs],null)],null));