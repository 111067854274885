var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./metabase.lib.aggregation.js");require("./metabase.lib.breakout.js");require("./metabase.lib.dispatch.js");require("./metabase.lib.equality.js");require("./metabase.lib.hierarchy.js");require("./metabase.lib.metadata.js");require("./metabase.lib.metadata.calculation.js");require("./metabase.lib.options.js");require("./metabase.lib.ref.js");require("./metabase.lib.schema.js");require("./metabase.lib.schema.expression.js");require("./metabase.lib.schema.order_by.js");require("./metabase.lib.util.js");require("./metabase.mbql.util.match.js");require("./metabase.shared.util.i18n.js");require("./metabase.util.malli.js");
'use strict';var ysa=function(a){a=$CLJS.O(a);var b=$CLJS.J.g(a,$CLJS.TA);return $CLJS.Se(function(c){return $CLJS.MB(b,c)},$CLJS.FE)},D7=new $CLJS.M("metabase.lib.order-by","order-by-clause","metabase.lib.order-by/order-by-clause",774979647);$CLJS.LE($CLJS.CF,D7);$CLJS.LE($CLJS.vF,D7);
$CLJS.f0.m(null,$CLJS.kR,function(a,b){var c=$CLJS.Ge($CLJS.kR.h($CLJS.bW(a,b)));return $CLJS.n(c)?$CLJS.DD("Sorted by {0}",$CLJS.H([$CLJS.w1($CLJS.eE("and"),function(){return function f(e){return new $CLJS.oe(null,function(){for(;;){var k=$CLJS.y(e);if(k){if($CLJS.Ad(k)){var l=$CLJS.lc(k),m=$CLJS.D(l),t=$CLJS.re(m);a:for(var u=0;;)if(u<m){var v=$CLJS.kd(l,u);v=$CLJS.D_.v(a,b,v,$CLJS.E_);t.add(v);u+=1}else{l=!0;break a}return l?$CLJS.ue($CLJS.we(t),f($CLJS.mc(k))):$CLJS.ue($CLJS.we(t),null)}t=$CLJS.z(k);
return $CLJS.fe($CLJS.D_.v(a,b,t,$CLJS.E_),f($CLJS.Lc(k)))}return null}},null,null)}(c)}())])):null});$CLJS.c0.m(null,D7,function(a,b,c,d){var e=$CLJS.I(c,0,null);$CLJS.I(c,1,null);c=$CLJS.I(c,2,null);a=$CLJS.D_.v(a,b,c,d);e=e instanceof $CLJS.M?e.S:null;switch(e){case "asc":return $CLJS.DD("{0} ascending",$CLJS.H([a]));case "desc":return $CLJS.DD("{0} descending",$CLJS.H([a]));default:throw Error(["No matching clause: ",$CLJS.p.h(e)].join(""));}});
$CLJS.j0.m(null,D7,function(a,b,c){var d=$CLJS.I(c,0,null);$CLJS.I(c,1,null);c=$CLJS.I(c,2,null);return $CLJS.R.j($CLJS.G_.j(a,b,c),$CLJS.R_,d)});var E7,zsa=$CLJS.Ye($CLJS.N),Asa=$CLJS.Ye($CLJS.N),Bsa=$CLJS.Ye($CLJS.N),Csa=$CLJS.Ye($CLJS.N),Dsa=$CLJS.J.j(new $CLJS.h(null,1,[$CLJS.ij,$CLJS.zB],null),$CLJS.ij,$CLJS.Wh.o?$CLJS.Wh.o():$CLJS.Wh.call(null));E7=new $CLJS.ei($CLJS.Gh.g("metabase.lib.order-by","order-by-clause-method"),$CLJS.xB,Dsa,zsa,Asa,Bsa,Csa);E7.m(null,D7,function(a){return $CLJS.MV(a)});
E7.m(null,$CLJS.di,function(a){if(null==a)throw $CLJS.ii($CLJS.eE("Can''t order by nil"),$CLJS.N);return $CLJS.MV(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.CF,$CLJS.NV(a)],null))});
$CLJS.F7=function(){function a(d,e){d=E7.h(d);e=$CLJS.n(e)?e:$CLJS.CF;return $CLJS.R.j($CLJS.Ef(d),0,e)}function b(d){return c.g?c.g(d,$CLJS.CF):c.call(null,d,$CLJS.CF)}var c=null;c=function(d,e){switch(arguments.length){case 1:return b.call(this,d);case 2:return a.call(this,d,e)}throw Error("Invalid arity: "+arguments.length);};c.h=b;c.g=a;return c}();
$CLJS.G7=function(){function a(e,f,k,l){f=$CLJS.n(f)?f:-1;var m=function(){var t=E7.h(k);return $CLJS.n(l)?$CLJS.R.j($CLJS.Ef(t),0,l):t}();return $CLJS.nW.l(e,f,$CLJS.Tk,$CLJS.H([$CLJS.kR,function(t){return $CLJS.ce.g($CLJS.Ef(t),m)}]))}function b(e,f,k){return d.v?d.v(e,-1,f,k):d.call(null,e,-1,f,k)}function c(e,f){return d.v?d.v(e,-1,f,null):d.call(null,e,-1,f,null)}var d=null;d=function(e,f,k,l){switch(arguments.length){case 2:return c.call(this,e,f);case 3:return b.call(this,e,f,k);case 4:return a.call(this,
e,f,k,l)}throw Error("Invalid arity: "+arguments.length);};d.g=c;d.j=b;d.v=a;return d}();$CLJS.H7=function(){function a(d,e){return $CLJS.Ge($CLJS.J.g($CLJS.bW(d,e),$CLJS.kR))}function b(d){return c.g?c.g(d,-1):c.call(null,d,-1)}var c=null;c=function(d,e){switch(arguments.length){case 1:return b.call(this,d);case 2:return a.call(this,d,e)}throw Error("Invalid arity: "+arguments.length);};c.h=b;c.g=a;return c}();
$CLJS.I7=function(){function a(d,e){var f=$CLJS.Ge($CLJS.x5.g(d,e)),k=$CLJS.Ge($CLJS.f4.g(d,e));f=$CLJS.n($CLJS.n(f)?f:k)?$CLJS.hf.g(f,k):function(){var t=$CLJS.bW(d,e);return $CLJS.n0.v(d,e,t,new $CLJS.h(null,1,[$CLJS.$_,!1],null))}();var l=$CLJS.mf(ysa,f);f=$CLJS.jf.g(function(t){$CLJS.I(t,0,null);$CLJS.I(t,1,null);return $CLJS.I(t,2,null)},$CLJS.H7.g(d,e));if($CLJS.td(l))return null;if($CLJS.td(f))return $CLJS.Ef(l);var m=$CLJS.fg.j($CLJS.N,$CLJS.Qk.g($CLJS.jf.h($CLJS.NV),$CLJS.cY(function(t,u){u=
$CLJS.H1.v(d,e,u,l);return $CLJS.n(u)?new $CLJS.P(null,2,5,$CLJS.Q,[u,t],null):null})),f);return $CLJS.Sk.g(function(t){var u=m.h?m.h(t):m.call(null,t);return $CLJS.n(u)?$CLJS.R.j(t,$CLJS.N_,u):t},l)}function b(d){return c.g?c.g(d,-1):c.call(null,d,-1)}var c=null;c=function(d,e){switch(arguments.length){case 1:return b.call(this,d);case 2:return a.call(this,d,e)}throw Error("Invalid arity: "+arguments.length);};c.h=b;c.g=a;return c}();
$CLJS.J7=new $CLJS.h(null,2,[$CLJS.CF,$CLJS.vF,$CLJS.vF,$CLJS.CF],null);