var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.string.js");require("./metabase.lib.schema.common.js");require("./metabase.mbql.predicates.js");require("./metabase.mbql.schema.js");require("./metabase.mbql.schema.helpers.js");require("./metabase.mbql.util.match.js");require("./metabase.shared.util.i18n.js");require("./metabase.util.log.js");require("./metabase.util.malli.js");
'use strict';var UU;$CLJS.PU=function(a){return a instanceof Error?a.message:null};
$CLJS.QU=function(a,b,c){if($CLJS.ae(c)){var d=$CLJS.Te.g($CLJS.U,$CLJS.jf.g(a,c));return b.h?b.h(d):b.call(null,d)}return $CLJS.Af(c)?(d=new $CLJS.Zf(function(){var e=$CLJS.Eb(c);return a.h?a.h(e):a.call(null,e)}(),function(){var e=$CLJS.Fb(c);return a.h?a.h(e):a.call(null,e)}()),b.h?b.h(d):b.call(null,d)):$CLJS.Dd(c)?(d=$CLJS.nh($CLJS.jf.g(a,c)),b.h?b.h(d):b.call(null,d)):$CLJS.yd(c)?(d=$CLJS.eb(function(e,f){return $CLJS.ce.g(e,a.h?a.h(f):a.call(null,f))},c,c),b.h?b.h(d):b.call(null,d)):$CLJS.ud(c)?
(d=$CLJS.fg.g($CLJS.jd(c),$CLJS.jf.g(a,c)),b.h?b.h(d):b.call(null,d)):b.h?b.h(c):b.call(null,c)};$CLJS.SU=function(a){function b(c){var d=$CLJS.I(c,0,null);c=$CLJS.I(c,1,null);return"string"===typeof d?new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ah.h(d),c],null):new $CLJS.P(null,2,5,$CLJS.Q,[d,c],null)}return $CLJS.RU(function(c){return $CLJS.xd(c)?$CLJS.fg.g($CLJS.N,$CLJS.jf.g(b,c)):c},a)};
$CLJS.TU=function(a){var b=new $CLJS.Ea;for(a=$CLJS.y(a);;)if(null!=a)b=b.append($CLJS.p.h($CLJS.z(a))),a=$CLJS.B(a);else return b.toString()};$CLJS.VU=function(a,b,c){a=$CLJS.fA(a,/''/,"'");var d=$CLJS.rt(a,UU);d=$CLJS.E.g($CLJS.D(d),1)?new $CLJS.P(null,2,5,$CLJS.Q,[a,""],null):d;a=$CLJS.ph(/.*\{0\}.*/,a);return $CLJS.kt.ngettext($CLJS.kt.msgid($CLJS.kl(d),$CLJS.n(a)?c:""),$CLJS.fA($CLJS.fA(b,/''/,"'"),UU,$CLJS.p.h(c)),c)};
$CLJS.WU=function(a,b){return $CLJS.eb(function(c,d){var e=$CLJS.I(d,0,null);d=$CLJS.I(d,1,null);return $CLJS.Hd(a,e)?$CLJS.R.j(c,d,$CLJS.J.g(a,e)):c},$CLJS.Te.j($CLJS.Ik,a,$CLJS.Yg(b)),b)};$CLJS.XU=function(a,b,c){return $CLJS.xd(c)?$CLJS.kf(function(d){var e=$CLJS.I(d,0,null);d=$CLJS.I(d,1,null);e=$CLJS.ce.g(b,e);return a.g?a.g(e,d):a.call(null,e,d)},$CLJS.H([c])):$CLJS.wd(c)?$CLJS.kf($CLJS.We(a,$CLJS.z(c)instanceof $CLJS.M?$CLJS.ce.g(b,$CLJS.z(c)):b),$CLJS.H([c])):null};
$CLJS.RU=function RU(a,b){return $CLJS.QU($CLJS.We(RU,a),a,b)};UU=/\{0\}/;$CLJS.YU=new $CLJS.M(null,"unique-alias-fn","unique-alias-fn",32555420);$CLJS.ZU=new $CLJS.M(null,"binning-strategy","binning-strategy",2063329158);$CLJS.$U=new $CLJS.M("clojure.core.match","not-found","clojure.core.match/not-found",1553053780);$CLJS.aV=new $CLJS.M(null,"name-key-fn","name-key-fn",-1634839199);var wla=$CLJS.Jg([new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.LI,null],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.oI,null],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.BI,$CLJS.uI],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.BI,null],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.II,null],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.AI,null],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.EI,null],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.hI,null],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.FI,null],null),new $CLJS.P(null,2,5,
$CLJS.Q,[$CLJS.BI,$CLJS.lI],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.BI,$CLJS.zI],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.nI,null],null)],[$CLJS.Bi,$CLJS.tG,$CLJS.MI,$CLJS.rI,$CLJS.xi,$CLJS.Hj,$CLJS.jj,$CLJS.mk,$CLJS.Hi,$CLJS.rI,$CLJS.NI,$CLJS.qG]),cV;$CLJS.gh($CLJS.jf.g($CLJS.z,$CLJS.Yg(wla)));var bV,xla=$CLJS.Ye($CLJS.N),yla=$CLJS.Ye($CLJS.N),zla=$CLJS.Ye($CLJS.N),Ala=$CLJS.Ye($CLJS.N),Bla=$CLJS.J.j($CLJS.N,$CLJS.ij,$CLJS.Wh.o?$CLJS.Wh.o():$CLJS.Wh.call(null));
bV=new $CLJS.ei($CLJS.Gh.g("metabase.mbql.util","negate*"),$CLJS.z,Bla,xla,yla,zla,Ala);bV.m(null,$CLJS.Or,function(a){$CLJS.I(a,0,null);return $CLJS.I(a,1,null)});bV.m(null,$CLJS.Tr,function(a){a=$CLJS.y(a);$CLJS.z(a);a=$CLJS.B(a);return $CLJS.fg.g(new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.Mr],null),$CLJS.jf.g(bV,a))});bV.m(null,$CLJS.Mr,function(a){a=$CLJS.y(a);$CLJS.z(a);a=$CLJS.B(a);return $CLJS.fg.g(new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.Tr],null),$CLJS.jf.g(bV,a))});
bV.m(null,$CLJS.or,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.XF,b,a],null)});bV.m(null,$CLJS.XF,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.or,b,a],null)});bV.m(null,$CLJS.hr,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.nr,b,a],null)});
bV.m(null,$CLJS.lr,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.jr,b,a],null)});bV.m(null,$CLJS.jr,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.lr,b,a],null)});bV.m(null,$CLJS.nr,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.hr,b,a],null)});
bV.m(null,$CLJS.gF,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null),c=$CLJS.I(a,2,null);a=$CLJS.I(a,3,null);return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Mr,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.lr,b,c],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.hr,b,a],null)],null)});bV.m(null,$CLJS.EF,function(a){return new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Or,a],null)});bV.m(null,$CLJS.wF,function(a){return new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Or,a],null)});
bV.m(null,$CLJS.QF,function(a){return new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Or,a],null)});
cV=function cV(a){return $CLJS.xd(a)?$CLJS.Ge($CLJS.fg.g($CLJS.N,function(){return function e(d){return new $CLJS.oe(null,function(){for(var f=d;;)if(f=$CLJS.y(f)){if($CLJS.Ad(f)){var k=$CLJS.lc(f),l=$CLJS.D(k),m=$CLJS.re(l);a:for(var t=0;;)if(t<l){var u=$CLJS.kd(k,t),v=$CLJS.I(u,0,null);u=$CLJS.I(u,1,null);u=cV.h?cV.h(u):cV.call(null,u);null!=u&&m.add(new $CLJS.P(null,2,5,$CLJS.Q,[v,u],null));t+=1}else{k=!0;break a}return k?$CLJS.ue($CLJS.we(m),e($CLJS.mc(f))):$CLJS.ue($CLJS.we(m),null)}k=$CLJS.z(f);
m=$CLJS.I(k,0,null);k=$CLJS.I(k,1,null);k=cV.h?cV.h(k):cV.call(null,k);if(null!=k)return $CLJS.fe(new $CLJS.P(null,2,5,$CLJS.Q,[m,k],null),e($CLJS.Lc(f)));f=$CLJS.Lc(f)}else return null},null,null)}(a)}())):$CLJS.wd(a)?$CLJS.Ge($CLJS.fg.g($CLJS.jd(a),$CLJS.mf($CLJS.Xa,$CLJS.jf.g(cV,a)))):a};
$CLJS.dV=function(){function a(c,d,e){var f=null;if(2<arguments.length){f=0;for(var k=Array(arguments.length-2);f<k.length;)k[f]=arguments[f+2],++f;f=new $CLJS.w(k,0,null)}return b.call(this,c,d,f)}function b(c,d,e){var f=$CLJS.I(c,0,null),k=$CLJS.I(c,1,null);c=$CLJS.I(c,2,null);d=$CLJS.Ge(cV($CLJS.Te.j(d,c,e)));return $CLJS.n(d)?new $CLJS.P(null,3,5,$CLJS.Q,[f,k,d],null):$CLJS.E.g(f,$CLJS.FF)?new $CLJS.P(null,3,5,$CLJS.Q,[f,k,null],null):new $CLJS.P(null,2,5,$CLJS.Q,[f,k],null)}a.A=2;a.B=function(c){var d=
$CLJS.z(c);c=$CLJS.B(c);var e=$CLJS.z(c);c=$CLJS.Lc(c);return b(d,e,c)};a.l=b;return a}();