var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./metabase.lib.hierarchy.js");require("./metabase.lib.schema.expression.js");require("./metabase.lib.schema.mbql_clause.js");require("./metabase.shared.util.i18n.js");require("./metabase.util.malli.registry.js");
'use strict';var Xfa,Yfa,Zfa,$fa,aga,bga,cga,kG,dga,ega,fga,gga,hga,iga,jga,kga,mG,lga;$CLJS.gG=new $CLJS.M(null,"supported-field","supported-field",-2061545519);Xfa=new $CLJS.M("mbql.clause","distinct","mbql.clause/distinct",843025748);$CLJS.hG=new $CLJS.M("metabase.lib.schema.aggregation","aggregations","metabase.lib.schema.aggregation/aggregations",-203649172);Yfa=new $CLJS.M("mbql.clause","count-where","mbql.clause/count-where",2010666417);
Zfa=new $CLJS.M("mbql.clause","sum-where","mbql.clause/sum-where",-1599647617);$CLJS.iG=new $CLJS.M(null,"requires-column?","requires-column?",1623131448);$fa=new $CLJS.M("metabase.lib.schema.aggregation","operator","metabase.lib.schema.aggregation/operator",2070038764);$CLJS.jG=new $CLJS.M(null,"display-info","display-info",-816930907);aga=new $CLJS.M("mbql.clause","max","mbql.clause/max",1615385533);bga=new $CLJS.M("mbql.clause","cum-count","mbql.clause/cum-count",-1408720642);
cga=new $CLJS.M("mbql.clause","median","mbql.clause/median",-1026386338);kG=new $CLJS.M("metabase.lib.schema.aggregation","percentile.percentile","metabase.lib.schema.aggregation/percentile.percentile",-144637711);dga=new $CLJS.M("mbql.clause","min","mbql.clause/min",2074448531);ega=new $CLJS.M("mbql.clause","percentile","mbql.clause/percentile",2064253651);fga=new $CLJS.M("mbql.clause","stddev","mbql.clause/stddev",659381076);gga=new $CLJS.M("mbql.clause","var","mbql.clause/var",809434920);
hga=new $CLJS.M("mbql.clause","share","mbql.clause/share",-37921592);iga=new $CLJS.M("mbql.clause","count","mbql.clause/count",-454225910);jga=new $CLJS.M("mbql.clause","cum-sum","mbql.clause/cum-sum",468410018);$CLJS.lG=new $CLJS.M(null,"driver-feature","driver-feature",1905324248);kga=new $CLJS.M("mbql.clause","avg","mbql.clause/avg",1826863173);mG=new $CLJS.M("metabase.lib.schema.aggregation","aggregation","metabase.lib.schema.aggregation/aggregation",-736862750);
$CLJS.nG=new $CLJS.M("operator","aggregation","operator/aggregation",-1961510924);lga=new $CLJS.M("mbql.clause","sum","mbql.clause/sum",1691012107);$CLJS.UE($CLJS.rx,$CLJS.H([$CLJS.Ds,$CLJS.xj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.QA,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.sr,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.nk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,$CLJS.sE],null)],null)],null)],null)]));$CLJS.UE($CLJS.GF,$CLJS.H([$CLJS.Ds,$CLJS.xj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.QA,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.sr,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.nk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,$CLJS.sE],null)],null)],null)],null)]));
$CLJS.SE($CLJS.zF,$CLJS.H([$CLJS.Ds,$CLJS.iD,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.nk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,$CLJS.DE],null)],null)]));$CLJS.SE($CLJS.jF,$CLJS.H([$CLJS.Ds,$CLJS.xj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.nk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,$CLJS.sE],null)],null)]));$CLJS.SE($CLJS.OF,$CLJS.H([$CLJS.Ds,$CLJS.xj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.nk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,$CLJS.rE],null)],null)]));
$CLJS.SE($CLJS.jk,$CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.nk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,$CLJS.yE],null)],null)]));$CLJS.LE($CLJS.jk,$CLJS.xE);$CLJS.SE($CLJS.TF,$CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.nk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,$CLJS.DE],null)],null)]));$CLJS.LE($CLJS.TF,$CLJS.xE);$CLJS.SE($CLJS.Rn,$CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.nk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,$CLJS.yE],null)],null)]));$CLJS.LE($CLJS.Rn,$CLJS.xE);
$CLJS.X(kG,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.Tr,new $CLJS.h(null,1,[$CLJS.Ys,"valid percentile"],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,$CLJS.DE],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Si,new $CLJS.h(null,1,[$CLJS.Ys,"percentile must be between zero and one"],null),function(a){return 0<=a&&1>=a}],null)],null));$CLJS.SE($CLJS.qF,$CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,$CLJS.DE],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,kG],null)]));$CLJS.LE($CLJS.qF,$CLJS.xE);
$CLJS.SE($CLJS.MF,$CLJS.H([$CLJS.Ds,$CLJS.iD,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.nk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,$CLJS.rE],null)],null)]));$CLJS.SE($CLJS.VF,$CLJS.H([$CLJS.Ds,$CLJS.iD,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.nk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,$CLJS.DE],null)],null)]));$CLJS.SE($CLJS.SF,$CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.nk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,$CLJS.DE],null)],null)]));
$CLJS.SE($CLJS.bG,$CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.nk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,$CLJS.DE],null)],null)]));$CLJS.LE($CLJS.SF,$CLJS.xE);$CLJS.LE($CLJS.bG,$CLJS.xE);$CLJS.SE($CLJS.$F,$CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.nk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,$CLJS.DE],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.nk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,$CLJS.rE],null)],null)]));$CLJS.LE($CLJS.$F,$CLJS.xE);
$CLJS.SE($CLJS.kF,$CLJS.H([$CLJS.Ds,$CLJS.iD,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.nk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,$CLJS.DE],null)],null)]));$CLJS.X(mG,new $CLJS.P(null,17,5,$CLJS.Q,[$CLJS.Mr,kga,iga,bga,Yfa,Xfa,aga,cga,dga,ega,hga,fga,lga,jga,Zfa,gga,$CLJS.El],null));$CLJS.X($CLJS.hG,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Aj,new $CLJS.h(null,1,[$CLJS.Rn,1],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,mG],null)],null));
$CLJS.oG=new $CLJS.P(null,10,5,$CLJS.Q,[new $CLJS.h(null,4,[$CLJS.yF,$CLJS.rx,$CLJS.iG,!1,$CLJS.lG,$CLJS.ZE,$CLJS.jG,function(){return new $CLJS.h(null,3,[$CLJS.ID,$CLJS.eE("Count of rows"),$CLJS.pF,$CLJS.eE("Count"),$CLJS.lt,$CLJS.eE("Total number of rows in the answer.")],null)}],null),new $CLJS.h(null,5,[$CLJS.yF,$CLJS.SF,$CLJS.gG,$CLJS.gk,$CLJS.iG,!0,$CLJS.lG,$CLJS.ZE,$CLJS.jG,function(){return new $CLJS.h(null,3,[$CLJS.ID,$CLJS.eE("Sum of ..."),$CLJS.pF,$CLJS.eE("Sum"),$CLJS.lt,$CLJS.eE("Sum of all the values of a column.")],
null)}],null),new $CLJS.h(null,5,[$CLJS.yF,$CLJS.zF,$CLJS.gG,$CLJS.gk,$CLJS.iG,!0,$CLJS.lG,$CLJS.ZE,$CLJS.jG,function(){return new $CLJS.h(null,3,[$CLJS.ID,$CLJS.eE("Average of ..."),$CLJS.pF,$CLJS.eE("Average"),$CLJS.lt,$CLJS.eE("Average of all the values of a column")],null)}],null),new $CLJS.h(null,5,[$CLJS.yF,$CLJS.TF,$CLJS.gG,$CLJS.gk,$CLJS.iG,!0,$CLJS.lG,$CLJS.BF,$CLJS.jG,function(){return new $CLJS.h(null,3,[$CLJS.ID,$CLJS.eE("Median of ..."),$CLJS.pF,$CLJS.eE("Median"),$CLJS.lt,$CLJS.eE("Median of all the values of a column")],
null)}],null),new $CLJS.h(null,5,[$CLJS.yF,$CLJS.jF,$CLJS.gG,$CLJS.mi,$CLJS.iG,!0,$CLJS.lG,$CLJS.ZE,$CLJS.jG,function(){return new $CLJS.h(null,3,[$CLJS.ID,$CLJS.eE("Number of distinct values of ..."),$CLJS.pF,$CLJS.eE("Distinct values"),$CLJS.lt,$CLJS.eE("Number of unique values of a column among all the rows in the answer.")],null)}],null),new $CLJS.h(null,5,[$CLJS.yF,$CLJS.bG,$CLJS.gG,$CLJS.gk,$CLJS.iG,!0,$CLJS.lG,$CLJS.ZE,$CLJS.jG,function(){return new $CLJS.h(null,3,[$CLJS.ID,$CLJS.eE("Cumulative sum of ..."),
$CLJS.pF,$CLJS.eE("Sum"),$CLJS.lt,$CLJS.eE("Additive sum of all the values of a column.\ne.x. total revenue over time.")],null)}],null),new $CLJS.h(null,4,[$CLJS.yF,$CLJS.GF,$CLJS.iG,!1,$CLJS.lG,$CLJS.ZE,$CLJS.jG,function(){return new $CLJS.h(null,3,[$CLJS.ID,$CLJS.eE("Cumulative count of rows"),$CLJS.pF,$CLJS.eE("Count"),$CLJS.lt,$CLJS.eE("Additive count of the number of rows.\ne.x. total number of sales over time.")],null)}],null),new $CLJS.h(null,5,[$CLJS.yF,$CLJS.VF,$CLJS.gG,$CLJS.gk,$CLJS.iG,
!0,$CLJS.lG,$CLJS.PF,$CLJS.jG,function(){return new $CLJS.h(null,3,[$CLJS.ID,$CLJS.eE("Standard deviation of ..."),$CLJS.pF,$CLJS.eE("SD"),$CLJS.lt,$CLJS.eE("Number which expresses how much the values of a column vary among all rows in the answer.")],null)}],null),new $CLJS.h(null,5,[$CLJS.yF,$CLJS.Rn,$CLJS.gG,$CLJS.Zi,$CLJS.iG,!0,$CLJS.lG,$CLJS.ZE,$CLJS.jG,function(){return new $CLJS.h(null,3,[$CLJS.ID,$CLJS.eE("Minimum of ..."),$CLJS.pF,$CLJS.eE("Min"),$CLJS.lt,$CLJS.eE("Minimum value of a column")],
null)}],null),new $CLJS.h(null,5,[$CLJS.yF,$CLJS.jk,$CLJS.gG,$CLJS.Zi,$CLJS.iG,!0,$CLJS.lG,$CLJS.ZE,$CLJS.jG,function(){return new $CLJS.h(null,3,[$CLJS.ID,$CLJS.eE("Maximum of ..."),$CLJS.pF,$CLJS.eE("Max"),$CLJS.lt,$CLJS.eE("Maximum value of a column")],null)}],null)],null);
$CLJS.X($fa,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.ik,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Fj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.or,$CLJS.nG],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.yF,$CLJS.fg.j(new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.Jr],null),$CLJS.jf.h($CLJS.yF),$CLJS.oG)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.gG,new $CLJS.h(null,1,[$CLJS.fs,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Lr,$CLJS.Yi],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.iG,$CLJS.bs],null),new $CLJS.P(null,2,5,
$CLJS.Q,[$CLJS.lG,$CLJS.Yi],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.jG,$CLJS.md],null)],null));