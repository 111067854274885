var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./metabase.lib.metadata.protocols.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.id.js");require("./metabase.util.malli.registry.js");
'use strict';var SY,Fna,Gna,Hna,Ina,ZY,Jna,Kna,$Y,Lna,bZ,Mna,Nna,Ona,gZ,hZ,Pna,Qna,pZ,Rna,Sna,Tna,uZ,Una;SY=function(){};$CLJS.TY=function(a){return null!=a?$CLJS.Bc===a.li?!0:a.Vc?!1:$CLJS.$a(SY,a):$CLJS.$a(SY,a)};Fna=new $CLJS.r(null,"metabase.lib.metadata.protocols","metabase.lib.metadata.protocols",128660233,null);$CLJS.UY=new $CLJS.M("lib","source-column-alias","lib/source-column-alias",1494821386);
Gna=new $CLJS.M("metabase.lib.schema.metadata","database","metabase.lib.schema.metadata/database",270611124);Hna=new $CLJS.M("lib","persisted-info","lib/persisted-info",-118569625);$CLJS.VY=new $CLJS.M("source","previous-stage","source/previous-stage",1962610878);$CLJS.WY=new $CLJS.M(null,"database-id","database-id",1883826326);Ina=new $CLJS.r("metabase.lib.metadata.protocols","metadata-provider?","metabase.lib.metadata.protocols/metadata-provider?",-472923865,null);
$CLJS.XY=new $CLJS.M("source","table-defaults","source/table-defaults",909975194);$CLJS.YY=new $CLJS.M(null,"search","search",1564939822);ZY=new $CLJS.M("metabase.lib.schema.metadata","column.remapping.internal","metabase.lib.schema.metadata/column.remapping.internal",-285151559);Jna=new $CLJS.M("metabase.lib.schema.metadata","metadata-providerable","metabase.lib.schema.metadata/metadata-providerable",1520216538);Kna=new $CLJS.M(null,"dbms-version","dbms-version",-69238036);
$Y=new $CLJS.M("metabase.lib.schema.metadata","column.has-field-values","metabase.lib.schema.metadata/column.has-field-values",-1383672167);Lna=new $CLJS.M(null,"field-name","field-name",1300687948);$CLJS.aZ=new $CLJS.M(null,"dataset","dataset",1159262238);bZ=new $CLJS.M("metabase.lib.schema.metadata","persisted-info","metabase.lib.schema.metadata/persisted-info",1457716669);Mna=new $CLJS.M(null,"field-definitions","field-definitions",1650155116);
$CLJS.cZ=new $CLJS.M("source","expressions","source/expressions",-458367840);$CLJS.dZ=new $CLJS.M(null,"definition","definition",-1198729982);Nna=new $CLJS.M(null,"state","state",-1988618099);$CLJS.eZ=new $CLJS.M("source","implicitly-joinable","source/implicitly-joinable",-729101197);Ona=new $CLJS.M(null,"is-audit","is-audit",327058420);$CLJS.fZ=new $CLJS.M("source","joins","source/joins",1225821486);
gZ=new $CLJS.M("metabase.lib.schema.metadata","column-source","metabase.lib.schema.metadata/column-source",1071627828);hZ=new $CLJS.M("metabase.lib.schema.metadata","metadata-provider","metabase.lib.schema.metadata/metadata-provider",-1514728827);$CLJS.iZ=new $CLJS.M("lib","desired-column-alias","lib/desired-column-alias",-1998967402);$CLJS.jZ=new $CLJS.M(null,"selected?","selected?",-742502788);Pna=new $CLJS.M("metabase.lib.schema.metadata","segment","metabase.lib.schema.metadata/segment",-393440267);
Qna=new $CLJS.M("metabase.lib.schema.metadata","metric","metabase.lib.schema.metadata/metric",1984974682);$CLJS.kZ=new $CLJS.M(null,"table-name","table-name",-2117866341);$CLJS.lZ=new $CLJS.M("source","card","source/card",-139977973);$CLJS.mZ=new $CLJS.M(null,"active","active",1895962068);$CLJS.nZ=new $CLJS.M(null,"dataset-query","dataset-query",1851066427);$CLJS.oZ=new $CLJS.M(null,"result-metadata","result-metadata",2017934672);
pZ=new $CLJS.M("metabase.lib.schema.metadata","column.remapping.external","metabase.lib.schema.metadata/column.remapping.external",304054297);Rna=new $CLJS.M(null,"details","details",1956795411);$CLJS.qZ=new $CLJS.M("source","fields","source/fields",-649667981);$CLJS.rZ=new $CLJS.M(null,"has-field-values","has-field-values",626648377);Sna=new $CLJS.M("metabase.lib.schema.metadata","card","metabase.lib.schema.metadata/card",147726027);$CLJS.sZ=new $CLJS.M("source","native","source/native",-1444604147);
Tna=new $CLJS.M("metabase.lib.schema.metadata","table","metabase.lib.schema.metadata/table",-2128230385);$CLJS.tZ=new $CLJS.M(null,"auto-list","auto-list",314197731);uZ=new $CLJS.M("metabase.lib.schema.metadata","persisted-info.definition","metabase.lib.schema.metadata/persisted-info.definition",496848961);$CLJS.vZ=new $CLJS.M("lib","card-id","lib/card-id",-1770167062);Una=new $CLJS.r(null,"metadata-provider?","metadata-provider?",1669871524,null);
$CLJS.wZ=new $CLJS.M(null,"source-alias","source-alias",1652088724);$CLJS.xZ=new $CLJS.M(null,"engine","engine",1459054265);$CLJS.X(gZ,new $CLJS.P(null,11,5,$CLJS.Q,[$CLJS.Jr,$CLJS.lZ,$CLJS.sZ,$CLJS.VY,$CLJS.XY,$CLJS.qZ,$CLJS.IK,$CLJS.zK,$CLJS.fZ,$CLJS.cZ,$CLJS.eZ],null));$CLJS.X($Y,$CLJS.fg.g(new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.Jr],null),$CLJS.cA($CLJS.Jd,new $CLJS.bh(null,new $CLJS.h(null,4,[$CLJS.tZ,null,$CLJS.YY,null,$CLJS.Dj,null,$CLJS.Fz,null],null),null))));
$CLJS.X(pZ,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.ik,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Fj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.or,$CLJS.HK],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.$i,$CLJS.qK],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.T,$CLJS.ND],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.rK,$CLJS.FK],null)],null));
$CLJS.X(ZY,new $CLJS.P(null,6,5,$CLJS.Q,[$CLJS.ik,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Fj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.or,$CLJS.BK],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.$i,$CLJS.qK],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.T,$CLJS.ND],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ws,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Aj,$CLJS.mi],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.nK,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Aj,$CLJS.mi],null)],null)],null));
$CLJS.X($CLJS.pK,new $CLJS.P(null,20,5,$CLJS.Q,[$CLJS.ik,new $CLJS.h(null,1,[$CLJS.Ys,"Valid column metadata"],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Fj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.or,$CLJS.gK],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.T,$CLJS.ak],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.TA,$CLJS.WD],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.$i,new $CLJS.h(null,1,[$CLJS.fs,!0],null),$CLJS.FK],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ID,new $CLJS.h(null,1,[$CLJS.fs,!0],null),new $CLJS.P(null,
2,5,$CLJS.Q,[$CLJS.Lr,$CLJS.ak],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Gi,new $CLJS.h(null,1,[$CLJS.fs,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Lr,$CLJS.WD],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.wK,new $CLJS.h(null,1,[$CLJS.fs,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Lr,$CLJS.FK],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.uK,new $CLJS.h(null,1,[$CLJS.fs,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Lr,$CLJS.FK],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.wZ,
new $CLJS.h(null,1,[$CLJS.fs,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Lr,$CLJS.ND],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.MD,new $CLJS.h(null,1,[$CLJS.fs,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Lr,$CLJS.ND],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.iK,new $CLJS.h(null,1,[$CLJS.fs,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,gZ],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.vZ,new $CLJS.h(null,1,[$CLJS.fs,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Lr,$CLJS.sK],
null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.UY,new $CLJS.h(null,1,[$CLJS.fs,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Lr,$CLJS.ND],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.iZ,new $CLJS.h(null,1,[$CLJS.fs,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Lr,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ak,new $CLJS.h(null,2,[$CLJS.Rn,1,$CLJS.jk,60],null)],null)],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.jZ,new $CLJS.h(null,1,[$CLJS.fs,!0],null),$CLJS.bs],null),new $CLJS.P(null,3,5,$CLJS.Q,
[$CLJS.rZ,new $CLJS.h(null,1,[$CLJS.fs,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Lr,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,$Y],null)],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.vK,new $CLJS.h(null,1,[$CLJS.fs,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Lr,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,pZ],null)],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.CK,new $CLJS.h(null,1,[$CLJS.fs,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Lr,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,ZY],null)],null)],
null)],null));$CLJS.X(uZ,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ik,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.kZ,$CLJS.ND],null),new $CLJS.P(null,2,5,$CLJS.Q,[Mna,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Lr,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Aj,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ik,new $CLJS.P(null,2,5,$CLJS.Q,[Lna,$CLJS.ND],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.TA,$CLJS.WD],null)],null)],null)],null)],null)],null));
$CLJS.X(bZ,new $CLJS.P(null,6,5,$CLJS.Q,[$CLJS.ik,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.mZ,$CLJS.bs],null),new $CLJS.P(null,2,5,$CLJS.Q,[Nna,$CLJS.ND],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.kZ,$CLJS.ND],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.dZ,new $CLJS.h(null,1,[$CLJS.fs,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Lr,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,uZ],null)],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.fK,new $CLJS.h(null,1,[$CLJS.fs,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Lr,
$CLJS.ND],null)],null)],null));
$CLJS.X(Sna,new $CLJS.P(null,11,5,$CLJS.Q,[$CLJS.ik,new $CLJS.h(null,1,[$CLJS.Ys,"Valid Card metadata"],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Fj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.or,$CLJS.GK],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.$i,$CLJS.sK],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.T,$CLJS.ND],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.WY,$CLJS.tK],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.nZ,new $CLJS.h(null,1,[$CLJS.fs,!0],null),$CLJS.ik],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.oZ,
new $CLJS.h(null,1,[$CLJS.fs,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Lr,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Aj,$CLJS.ik],null)],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.aZ,new $CLJS.h(null,1,[$CLJS.fs,!0],null),$CLJS.bs],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.xK,new $CLJS.h(null,1,[$CLJS.fs,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Lr,$CLJS.lK],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[Hna,new $CLJS.h(null,1,[$CLJS.fs,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Lr,new $CLJS.P(null,
2,5,$CLJS.Q,[$CLJS.V,bZ],null)],null)],null)],null));
$CLJS.X(Pna,new $CLJS.P(null,8,5,$CLJS.Q,[$CLJS.ik,new $CLJS.h(null,1,[$CLJS.Ys,"Valid Segment metadata"],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Fj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.or,$CLJS.kK],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.$i,$CLJS.jK],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.T,$CLJS.ND],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.xK,$CLJS.lK],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.dZ,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Lr,$CLJS.ik],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,
[$CLJS.lt,new $CLJS.h(null,1,[$CLJS.fs,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Lr,$CLJS.ND],null)],null)],null));
$CLJS.X(Qna,new $CLJS.P(null,8,5,$CLJS.Q,[$CLJS.ik,new $CLJS.h(null,1,[$CLJS.Ys,"Valid Metric metadata"],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Fj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.or,$CLJS.mK],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.$i,$CLJS.hK],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.T,$CLJS.ND],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.xK,$CLJS.lK],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.dZ,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Lr,$CLJS.ik],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,
[$CLJS.lt,new $CLJS.h(null,1,[$CLJS.fs,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Lr,$CLJS.ND],null)],null)],null));
$CLJS.X(Tna,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.ik,new $CLJS.h(null,1,[$CLJS.Ys,"Valid Table metadata"],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Fj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.or,$CLJS.oK],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.$i,$CLJS.lK],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.T,$CLJS.ND],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ID,new $CLJS.h(null,1,[$CLJS.fs,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Lr,$CLJS.ND],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.nk,new $CLJS.h(null,
1,[$CLJS.fs,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Lr,$CLJS.ND],null)],null)],null));
$CLJS.X(Gna,new $CLJS.P(null,10,5,$CLJS.Q,[$CLJS.ik,new $CLJS.h(null,1,[$CLJS.Ys,"Valid Database metadata"],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Fj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.or,$CLJS.DK],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.$i,$CLJS.tK],null),new $CLJS.P(null,3,5,$CLJS.Q,[Kna,new $CLJS.h(null,1,[$CLJS.fs,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Lr,$CLJS.ik],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[Rna,new $CLJS.h(null,1,[$CLJS.fs,!0],null),$CLJS.ik],null),new $CLJS.P(null,
3,5,$CLJS.Q,[$CLJS.xZ,new $CLJS.h(null,1,[$CLJS.fs,!0],null),$CLJS.Yi],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Gz,new $CLJS.h(null,1,[$CLJS.fs,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.aj,$CLJS.Yi],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[Ona,new $CLJS.h(null,1,[$CLJS.fs,!0],null),$CLJS.bs],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.yK,new $CLJS.h(null,1,[$CLJS.fs,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Lr,$CLJS.ik],null)],null)],null));
$CLJS.X(hZ,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Si,new $CLJS.h(null,1,[$CLJS.Ys,"Valid MetadataProvider"],null),new $CLJS.Gc(function(){return $CLJS.TY},Ina,$CLJS.Jg([$CLJS.Xi,$CLJS.T,$CLJS.qk,$CLJS.wi,$CLJS.Ui,$CLJS.oj,$CLJS.zk,$CLJS.vi,$CLJS.kk,$CLJS.pk],[Fna,Una,"metabase/lib/metadata/protocols.cljc",25,1,78,78,$CLJS.U(new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.mq],null)),"Whether `x` is a valid [[MetadataProvider]].",$CLJS.n($CLJS.TY)?$CLJS.TY.H:null]))],null));
$CLJS.X(Jna,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Mr,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,hZ],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ik,new $CLJS.h(null,1,[$CLJS.Ys,"map with a MetadataProvider in the key :lib/metadata (i.e. a query)"],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ED,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,hZ],null)],null)],null)],null));