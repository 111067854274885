var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./metabase.lib.dispatch.js");require("./metabase.lib.hierarchy.js");require("./metabase.lib.schema.common.js");require("./metabase.shared.util.i18n.js");require("./metabase.types.js");require("./metabase.util.malli.js");require("./metabase.util.malli.registry.js");
'use strict';var fE,kE,Pfa,Qfa,Rfa,Sfa,Tfa,Ufa;
$CLJS.dE=function(){function a(){return Math.floor(16*Math.random()).toString(16)}var b=(8|3&Math.floor(16*Math.random())).toString(16);return new $CLJS.Hz([$CLJS.p.h(a()),$CLJS.p.h(a()),$CLJS.p.h(a()),$CLJS.p.h(a()),$CLJS.p.h(a()),$CLJS.p.h(a()),$CLJS.p.h(a()),$CLJS.p.h(a()),"-",$CLJS.p.h(a()),$CLJS.p.h(a()),$CLJS.p.h(a()),$CLJS.p.h(a()),"-4",$CLJS.p.h(a()),$CLJS.p.h(a()),$CLJS.p.h(a()),"-",$CLJS.p.h(b),$CLJS.p.h(a()),$CLJS.p.h(a()),$CLJS.p.h(a()),"-",$CLJS.p.h(a()),$CLJS.p.h(a()),$CLJS.p.h(a()),
$CLJS.p.h(a()),$CLJS.p.h(a()),$CLJS.p.h(a()),$CLJS.p.h(a()),$CLJS.p.h(a()),$CLJS.p.h(a()),$CLJS.p.h(a()),$CLJS.p.h(a()),$CLJS.p.h(a())].join("").toLowerCase())};$CLJS.eE=function(a){for(var b=[],c=arguments.length,d=0;;)if(d<c)b.push(arguments[d]),d+=1;else break;return $CLJS.DD(arguments[0],1<b.length?new $CLJS.w(b.slice(1),0,null):null)};fE=function(a){return $CLJS.zd(a)&&$CLJS.z(a)instanceof $CLJS.M};
$CLJS.hE=function(a){var b=function(){var c=fE(a);return c?(c=$CLJS.xd($CLJS.hd(a)))?(c=$CLJS.Gi.h($CLJS.hd(a)),$CLJS.n(c)?c:$CLJS.TA.h($CLJS.hd(a))):c:c}();return $CLJS.n(b)?b:$CLJS.gE.h(a)};
kE=function(a,b){return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Tr,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Mr,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Si,new $CLJS.h(null,2,[$CLJS.Ys,"valid MBQL clause",$CLJS.it,function(c){c=$CLJS.O(c);c=$CLJS.J.g(c,$CLJS.Ej);return["invalid MBQL clause: ",$CLJS.Nh.l($CLJS.H([c]))].join("")}],null),$CLJS.Ue(fE)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,$CLJS.iE],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Si,new $CLJS.h(null,1,[$CLJS.Ys,b],null),function(c){c=$CLJS.hE(c);
return jE(c,a)}],null)],null)};$CLJS.lE=new $CLJS.M(null,"right-join","right-join",-56349359);Pfa=new $CLJS.M("metabase.lib.schema.expression","datetime","metabase.lib.schema.expression/datetime",-51960022);Qfa=new $CLJS.M("metabase.lib.schema.expression","base-type","metabase.lib.schema.expression/base-type",578343967);Rfa=new $CLJS.M("metabase.lib.schema.expression","non-integer-real","metabase.lib.schema.expression/non-integer-real",-1855491016);$CLJS.mE=new $CLJS.M(null,"fields","fields",-1932066230);
$CLJS.nE=new $CLJS.M(null,"expr","expr",745722291);$CLJS.oE=new $CLJS.M("metabase.lib.schema.expression","expressions","metabase.lib.schema.expression/expressions",790922225);$CLJS.pE=new $CLJS.M("metabase.lib.schema.expression","temporal","metabase.lib.schema.expression/temporal",320116754);$CLJS.qE=new $CLJS.M(null,"alias","alias",-2039751630);$CLJS.rE=new $CLJS.M("metabase.lib.schema.expression","boolean","metabase.lib.schema.expression/boolean",-1396506592);
$CLJS.sE=new $CLJS.M("metabase.lib.schema.expression","expression","metabase.lib.schema.expression/expression",-1389098704);$CLJS.tE=new $CLJS.M("metabase.lib.schema.expression","type.unknown","metabase.lib.schema.expression/type.unknown",-98159107);$CLJS.uE=new $CLJS.M("mbql.clause","field","mbql.clause/field",1497292735);Sfa=new $CLJS.M("metabase.lib.schema.expression","date","metabase.lib.schema.expression/date",1943847782);$CLJS.vE=new $CLJS.M(null,"left-join","left-join",-672831855);
$CLJS.wE=new $CLJS.M("metabase.lib.schema.expression","equality-comparable","metabase.lib.schema.expression/equality-comparable",-658449046);$CLJS.xE=new $CLJS.M("lib.type-of","type-is-type-of-first-arg","lib.type-of/type-is-type-of-first-arg",-317600808);$CLJS.yE=new $CLJS.M("metabase.lib.schema.expression","orderable","metabase.lib.schema.expression/orderable",-1555566130);Tfa=new $CLJS.r("metabase.lib.schema.expression","type-of","metabase.lib.schema.expression/type-of",-1625245814,null);
Ufa=new $CLJS.M("metabase.lib.schema.expression","time","metabase.lib.schema.expression/time",865466446);$CLJS.zE=new $CLJS.M(null,"inner-join","inner-join",659431740);$CLJS.AE=new $CLJS.M("metabase.lib.schema.expression","emptyable","metabase.lib.schema.expression/emptyable",941775581);$CLJS.iE=new $CLJS.M("metabase.lib.schema.mbql-clause","clause","metabase.lib.schema.mbql-clause/clause",955279388);
$CLJS.BE=new $CLJS.M("metabase.lib.schema.expression","string","metabase.lib.schema.expression/string",1750008170);$CLJS.CE=new $CLJS.M(null,"full-join","full-join",1305476385);$CLJS.DE=new $CLJS.M("metabase.lib.schema.expression","number","metabase.lib.schema.expression/number",779948930);$CLJS.EE=new $CLJS.M("metabase.lib.schema.expression","integer","metabase.lib.schema.expression/integer",2060430870);$CLJS.gE=function(){var a=$CLJS.Ye($CLJS.N),b=$CLJS.Ye($CLJS.N),c=$CLJS.Ye($CLJS.N),d=$CLJS.Ye($CLJS.N),e=$CLJS.J.j(new $CLJS.h(null,1,[$CLJS.ij,$CLJS.zB],null),$CLJS.ij,$CLJS.Wh.o?$CLJS.Wh.o():$CLJS.Wh.call(null));return new $CLJS.ei($CLJS.Gh.g("metabase.lib.schema.expression","type-of-method"),function(f){var k=$CLJS.xB(f);return $CLJS.E.g(k,$CLJS.SA)?$CLJS.ab(f):k},e,a,b,c,d)}();
$CLJS.X(Qfa,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Mr,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.or,$CLJS.tE],null),$CLJS.WD],null));$CLJS.gE.m(null,$CLJS.di,function(a){throw $CLJS.ii($CLJS.DD("{0}: Don''t know how to determine the type of {1}",$CLJS.H([Tfa,$CLJS.Nh.l($CLJS.H([a]))])),new $CLJS.h(null,1,[$CLJS.nE,a],null));});$CLJS.gE.m(null,$CLJS.xE,function(a){$CLJS.I(a,0,null);$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);return $CLJS.hE(a)});
var jE=function jE(a,b){return $CLJS.vd(a)?$CLJS.Se(function(d){return jE.g?jE.g(d,b):jE.call(null,d,b)},a):$CLJS.vd(b)?$CLJS.Se(function(d){return jE.g?jE.g(a,d):jE.call(null,a,d)},b):$CLJS.E.g(a,$CLJS.tE)?!0:$CLJS.MB(a,b)};$CLJS.X($CLJS.rE,kE($CLJS.Mj,"expression returning a boolean"));$CLJS.X($CLJS.BE,kE($CLJS.ek,"expression returning a string"));$CLJS.X($CLJS.EE,kE($CLJS.xj,"expression returning an integer"));$CLJS.X(Rfa,kE($CLJS.iD,"expression returning a non-integer real number"));
$CLJS.X($CLJS.DE,kE($CLJS.Qj,"expression returning a number"));$CLJS.X(Sfa,kE($CLJS.mC,"expression returning a date"));$CLJS.X(Ufa,kE($CLJS.lD,"expression returning a time"));$CLJS.X(Pfa,kE($CLJS.MC,"expression returning a date time"));$CLJS.X($CLJS.pE,kE($CLJS.Xj,"expression returning a date, time, or date time"));$CLJS.FE=new $CLJS.bh(null,new $CLJS.h(null,4,[$CLJS.Qj,null,$CLJS.ek,null,$CLJS.Xj,null,$CLJS.Mj,null],null),null);$CLJS.X($CLJS.yE,kE($CLJS.FE,"an expression that can be compared with :\x3e or :\x3c"));
var Vfa=new $CLJS.bh(null,new $CLJS.h(null,8,[$CLJS.WB,null,$CLJS.Qj,null,$CLJS.ek,null,$CLJS.CC,null,$CLJS.Xj,null,$CLJS.Bj,null,$CLJS.Mj,null,$CLJS.gD,null],null),null);$CLJS.X($CLJS.AE,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Mr,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,$CLJS.BE],null),kE($CLJS.CC,"expression returning a BSONID")],null));$CLJS.X($CLJS.wE,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Lr,kE(Vfa,"an expression that can appear in :\x3d or :!\x3d")],null));
$CLJS.X($CLJS.sE,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Lr,kE($CLJS.Bj,"any type of expression")],null));
$CLJS.X($CLJS.oE,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Aj,new $CLJS.h(null,1,[$CLJS.Rn,1],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Tr,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,$CLJS.sE],null),new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.Ar,$CLJS.mi,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ik,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.MD,$CLJS.ak],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.rr,$CLJS.mi],null)],null)],null)],null));