var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./malli.core.js");require("./medley.core.js");require("./metabase.lib.hierarchy.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.expression.js");require("./metabase.lib.schema.mbql_clause.js");require("./metabase.lib.schema.temporal_bucketing.js");require("./metabase.types.js");require("./metabase.util.malli.registry.js");
'use strict';var RG,SG,yga,UG,zga,Aga,Bga,VG,TG;$CLJS.PG=function(a,b){return $CLJS.gc($CLJS.eb(function(c,d){var e=a.h?a.h(d):a.call(null,d);return $CLJS.ig.j(c,e,$CLJS.ce.g($CLJS.J.j(c,e,$CLJS.Df),d))},$CLJS.ec($CLJS.N),b))};$CLJS.QG=function(a,b){return $CLJS.eb(function(c,d){return $CLJS.n(a.h?a.h(d):a.call(null,d))?$CLJS.Sc(d):null},null,b)};
RG=function(a,b){$CLJS.I(a,0,null);$CLJS.I(a,1,null);$CLJS.I(a,2,null);a=$CLJS.I(a,3,null);b=$CLJS.MB(b,$CLJS.mC)?$CLJS.AG:$CLJS.MB(b,$CLJS.lD)?$CLJS.vG:$CLJS.MB(b,$CLJS.MC)?$CLJS.xG:null;return $CLJS.n(b)?$CLJS.KE(b,a):!0};
SG=function(a){a=$CLJS.y(a);$CLJS.z(a);a=$CLJS.B(a);$CLJS.z(a);a=$CLJS.B(a);a=$CLJS.PG(function(d){return $CLJS.MB($CLJS.hE(d),$CLJS.xC)},a);var b=$CLJS.O(a);a=$CLJS.J.g(b,!1);b=$CLJS.J.g(b,!0);if($CLJS.Ok.g($CLJS.D(a),1))return"Temporal arithmetic expression must contain exactly one non-interval value";if(1>$CLJS.D(b))return"Temporal arithmetic expression must contain at least one :interval";var c=$CLJS.hE($CLJS.z(a));return $CLJS.Se(function(d){$CLJS.I(d,0,null);$CLJS.I(d,1,null);$CLJS.I(d,2,null);
var e=$CLJS.I(d,3,null);return $CLJS.n(RG(d,c))?null:["Cannot add a ",$CLJS.p.h(e)," interval to a ",$CLJS.p.h(c)," expression"].join("")},b)};
yga=function(a){return new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.Tr,new $CLJS.h(null,1,[$CLJS.Ys,[$CLJS.p.h(a)," clause with a temporal expression and one or more :interval clauses"].join("")],null),new $CLJS.P(null,6,5,$CLJS.Q,[$CLJS.Ar,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.or,a],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.nk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,$CLJS.SD],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.yr,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.nk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,TG],null)],
null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.nk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,$CLJS.pE],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.yr,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.nk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,TG],null)],null)],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Si,new $CLJS.h(null,1,[$CLJS.it,function(b){b=$CLJS.O(b);b=$CLJS.J.g(b,$CLJS.Ej);return["Invalid ",$CLJS.p.h(a)," clause: ",$CLJS.p.h(SG(b))].join("")}],null),$CLJS.Ue(SG)],null)],null)};
UG=function(a){var b=$CLJS.Q,c=yga(a);a=new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.Ar,new $CLJS.h(null,1,[$CLJS.Ys,[$CLJS.p.h(a)," clause with numeric args"].join("")],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.or,a],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.nk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,$CLJS.SD],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.yr,new $CLJS.h(null,1,[$CLJS.Rn,2],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.nk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,$CLJS.DE],null)],null)],null)],null);
return new $CLJS.P(null,3,5,b,[$CLJS.Mr,c,a],null)};zga=function(a){return $CLJS.Qd($CLJS.NE,$CLJS.jf.g(function(b){var c=$CLJS.hE(b),d=$CLJS.MB(c,$CLJS.tE);b=d?$CLJS.KE($CLJS.JF,b):d;return $CLJS.n(b)?$CLJS.Qj:c},a))};Aga=function(a){a=$CLJS.QG(function(b){return!$CLJS.MB(b,$CLJS.xC)},$CLJS.jf.g($CLJS.hE,a));return $CLJS.MB(a,$CLJS.tE)?$CLJS.Xj:a};Bga=function(a){return $CLJS.n($CLJS.Se(function(b){return $CLJS.MB($CLJS.hE(b),$CLJS.xC)},a))?Aga(a):zga(a)};
VG=new $CLJS.M("metabase.lib.schema.expression.arithmetic","args.numbers","metabase.lib.schema.expression.arithmetic/args.numbers",-179216621);$CLJS.WG=new $CLJS.M("lib.type-of","type-is-type-of-arithmetic-args","lib.type-of/type-is-type-of-arithmetic-args",-626871615);TG=new $CLJS.M("mbql.clause","interval","mbql.clause/interval",-990446354);$CLJS.X(new $CLJS.M("metabase.lib.schema.expression.arithmetic","args.temporal","metabase.lib.schema.expression.arithmetic/args.temporal",1338604923),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Tr,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Dr,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.nE,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.nk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,$CLJS.pE],null)],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[new $CLJS.M(null,"intervals","intervals",2096054013),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.qr,new $CLJS.P(null,
2,5,$CLJS.Q,[$CLJS.V,TG],null)],null)],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Si,new $CLJS.h(null,1,[$CLJS.Ys,"Temporal arithmetic expression with valid interval units for the expression type"],null),function(a){var b=$CLJS.y(a);a=$CLJS.z(b);b=$CLJS.B(b);var c=$CLJS.hE(a);return $CLJS.Re(function(d){return RG(d,c)},b)}],null)],null));
$CLJS.X(VG,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.yr,new $CLJS.h(null,1,[$CLJS.Rn,2],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.nk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,$CLJS.DE],null)],null)],null));$CLJS.fG.g($CLJS.qr,UG($CLJS.qr));$CLJS.fG.g($CLJS.Ds,UG($CLJS.Ds));$CLJS.UE($CLJS.rr,$CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ps,VG],null)]));$CLJS.UE($CLJS.aG,$CLJS.H([$CLJS.Ds,$CLJS.iD,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ps,VG],null)]));
for(var XG=$CLJS.y(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.qr,$CLJS.Ds,$CLJS.rr],null)),YG=null,ZG=0,$G=0;;)if($G<ZG){var Cga=YG.X(null,$G);$CLJS.LE(Cga,$CLJS.WG);$G+=1}else{var aH=$CLJS.y(XG);if(aH){var bH=aH;if($CLJS.Ad(bH)){var cH=$CLJS.lc(bH),Dga=$CLJS.mc(bH),Ega=cH,Fga=$CLJS.D(cH);XG=Dga;YG=Ega;ZG=Fga}else{var Gga=$CLJS.z(bH);$CLJS.LE(Gga,$CLJS.WG);XG=$CLJS.B(bH);YG=null;ZG=0}$G=0}else break}$CLJS.gE.m(null,$CLJS.WG,function(a){a=$CLJS.y(a);$CLJS.z(a);a=$CLJS.B(a);$CLJS.z(a);a=$CLJS.B(a);return Bga(a)});
$CLJS.SE($CLJS.eF,$CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.nk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,$CLJS.DE],null)],null)]));$CLJS.LE($CLJS.eF,$CLJS.xE);
for(var dH=$CLJS.y(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.cG,$CLJS.WE,$CLJS.sF],null)),eH=null,fH=0,gH=0;;)if(gH<fH){var Hga=eH.X(null,gH);$CLJS.SE(Hga,$CLJS.H([$CLJS.Ds,$CLJS.iD,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.nk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,$CLJS.DE],null)],null)]));gH+=1}else{var hH=$CLJS.y(dH);if(hH){var iH=hH;if($CLJS.Ad(iH)){var jH=$CLJS.lc(iH),Iga=$CLJS.mc(iH),Jga=jH,Kga=$CLJS.D(jH);dH=Iga;eH=Jga;fH=Kga}else{var Lga=$CLJS.z(iH);$CLJS.SE(Lga,$CLJS.H([$CLJS.Ds,$CLJS.iD,new $CLJS.P(null,
2,5,$CLJS.Q,[$CLJS.nk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,$CLJS.DE],null)],null)]));dH=$CLJS.B(iH);eH=null;fH=0}gH=0}else break}
for(var kH=$CLJS.y(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.$E,$CLJS.DF,$CLJS.KF],null)),lH=null,mH=0,nH=0;;)if(nH<mH){var Mga=lH.X(null,nH);$CLJS.SE(Mga,$CLJS.H([$CLJS.Ds,$CLJS.xj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.nk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,$CLJS.DE],null)],null)]));nH+=1}else{var oH=$CLJS.y(kH);if(oH){var pH=oH;if($CLJS.Ad(pH)){var qH=$CLJS.lc(pH),Nga=$CLJS.mc(pH),Oga=qH,Pga=$CLJS.D(qH);kH=Nga;lH=Oga;mH=Pga}else{var Qga=$CLJS.z(pH);$CLJS.SE(Qga,$CLJS.H([$CLJS.Ds,$CLJS.xj,new $CLJS.P(null,
2,5,$CLJS.Q,[$CLJS.nk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,$CLJS.DE],null)],null)]));kH=$CLJS.B(pH);lH=null;mH=0}nH=0}else break}$CLJS.SE($CLJS.bF,$CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.nk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,$CLJS.DE],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.nk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,$CLJS.DE],null)],null)]));
$CLJS.gE.m(null,$CLJS.bF,function(a){$CLJS.I(a,0,null);$CLJS.I(a,1,null);var b=$CLJS.I(a,2,null);a=$CLJS.I(a,3,null);return $CLJS.MB($CLJS.hE(b),$CLJS.xj)&&$CLJS.MB($CLJS.hE(a),$CLJS.xj)?$CLJS.xj:$CLJS.iD});