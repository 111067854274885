var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.string.js");require("./metabase.lib.equality.js");require("./metabase.lib.metadata.calculation.js");require("./metabase.lib.ref.js");require("./metabase.lib.remove_replace.js");require("./metabase.lib.schema.js");require("./metabase.lib.schema.expression.js");require("./metabase.lib.schema.metadata.js");require("./metabase.lib.schema.ref.js");require("./metabase.lib.temporal_bucket.js");require("./metabase.lib.util.js");require("./metabase.shared.util.i18n.js");require("./metabase.util.malli.js");
'use strict';var Nra,Ora;
Nra=function(a,b){return function f(d,e){return new $CLJS.oe(null,function(){var k=$CLJS.y(e);if(k){if($CLJS.Ad(k)){for(var l=$CLJS.lc(k),m=$CLJS.D(l),t=$CLJS.re(m),u=0;;)if(u<m){var v=function(){var x=d+u,A=$CLJS.kd(l,u);return a.g?a.g(x,A):a.call(null,x,A)}();null!=v&&t.add(v);u+=1}else break;return $CLJS.ue($CLJS.we(t),f(d+m,$CLJS.mc(k)))}m=function(){var x=$CLJS.z(k);return a.g?a.g(d,x):a.call(null,d,x)}();return null==m?f(d+1,$CLJS.Lc(k)):$CLJS.fe(m,f(d+1,$CLJS.Lc(k)))}return null},null,null)}(0,
b)};$CLJS.t5=function(a,b){return $CLJS.u1($CLJS.lr,new $CLJS.P(null,2,5,$CLJS.Q,[a,b],null))};$CLJS.u5=function(a,b){return $CLJS.u1($CLJS.jr,new $CLJS.P(null,2,5,$CLJS.Q,[a,b],null))};Ora=new $CLJS.M(null,"same-temporal-bucket?","same-temporal-bucket?",2110106995);$CLJS.f0.m(null,$CLJS.tF,function(a,b){var c=$CLJS.Ge($CLJS.tF.h($CLJS.bW(a,b)));return $CLJS.n(c)?$CLJS.DD("Grouped by {0}",$CLJS.H([$CLJS.Ms([" ",$CLJS.p.h($CLJS.eE("and"))," "].join(""),function(){return function f(e){return new $CLJS.oe(null,function(){for(;;){var k=$CLJS.y(e);if(k){if($CLJS.Ad(k)){var l=$CLJS.lc(k),m=$CLJS.D(l),t=$CLJS.re(m);a:for(var u=0;;)if(u<m){var v=$CLJS.kd(l,u);v=$CLJS.D_.v(a,b,v,$CLJS.E_);t.add(v);u+=1}else{l=!0;break a}return l?$CLJS.ue($CLJS.we(t),f($CLJS.mc(k))):$CLJS.ue($CLJS.we(t),
null)}t=$CLJS.z(k);return $CLJS.fe($CLJS.D_.v(a,b,t,$CLJS.E_),f($CLJS.Lc(k)))}return null}},null,null)}(c)}())])):null});$CLJS.v5=function(){function a(d,e,f){f=$CLJS.NV($CLJS.md(f)?f.g?f.g(d,e):f.call(null,d,e):f);return $CLJS.y1(d,e,$CLJS.tF,f)}function b(d,e){return c.j?c.j(d,-1,e):c.call(null,d,-1,e)}var c=null;c=function(d,e,f){switch(arguments.length){case 2:return b.call(this,d,e);case 3:return a.call(this,d,e,f)}throw Error("Invalid arity: "+arguments.length);};c.g=b;c.j=a;return c}();
$CLJS.w5=function(){function a(d,e){return $CLJS.Ge($CLJS.tF.h($CLJS.bW(d,e)))}function b(d){return c.g?c.g(d,-1):c.call(null,d,-1)}var c=null;c=function(d,e){switch(arguments.length){case 1:return b.call(this,d);case 2:return a.call(this,d,e)}throw Error("Invalid arity: "+arguments.length);};c.h=b;c.g=a;return c}();
$CLJS.x5=function(){function a(d,e){var f=$CLJS.w5.g(d,e);return null==f?null:$CLJS.Sk.g(function(k){return $CLJS.R.j($CLJS.B_.j(d,e,k),$CLJS.iK,$CLJS.zK)},f)}function b(d){return c.g?c.g(d,-1):c.call(null,d,-1)}var c=null;c=function(d,e){switch(arguments.length){case 1:return b.call(this,d);case 2:return a.call(this,d,e)}throw Error("Invalid arity: "+arguments.length);};c.h=b;c.g=a;return c}();
$CLJS.y5=function(){function a(d,e){var f=function(){var l=$CLJS.bW(d,e);return $CLJS.n0.v(d,e,l,new $CLJS.h(null,1,[$CLJS.$_,!1],null))}();if($CLJS.y(f)){var k=$CLJS.fg.g($CLJS.N,Nra(function(l,m){m=$CLJS.H1.N(d,e,m,f,new $CLJS.h(null,1,[$CLJS.E1,!0],null));return $CLJS.n(m)?new $CLJS.P(null,2,5,$CLJS.Q,[m,l],null):null},function(){var l=$CLJS.w5.g(d,e);return $CLJS.n(l)?l:$CLJS.Df}()));return $CLJS.Sk.g(function(l){var m=k.h?k.h(l):k.call(null,l);return $CLJS.n(m)?$CLJS.R.j(l,$CLJS.M_,m):l},f)}return null}
function b(d){return c.g?c.g(d,-1):c.call(null,d,-1)}var c=null;c=function(d,e){switch(arguments.length){case 1:return b.call(this,d);case 2:return a.call(this,d,e)}throw Error("Invalid arity: "+arguments.length);};c.h=b;c.g=a;return c}();
$CLJS.z5=function(){function a(d,e,f,k){k=$CLJS.O(k);var l=$CLJS.J.g(k,Ora);return $CLJS.Ge($CLJS.fg.j($CLJS.Df,$CLJS.lf(function(m){var t=$CLJS.H1.N(d,e,m,new $CLJS.P(null,1,5,$CLJS.Q,[f],null),new $CLJS.h(null,1,[$CLJS.E1,!0],null));return $CLJS.n(t)?$CLJS.n(l)?$CLJS.E.g($CLJS.m2(m),$CLJS.m2(f)):!0:t}),$CLJS.w5.g(d,e)))}function b(d,e,f){return c.v?c.v(d,e,f,null):c.call(null,d,e,f,null)}var c=null;c=function(d,e,f,k){switch(arguments.length){case 3:return b.call(this,d,e,f);case 4:return a.call(this,
d,e,f,k)}throw Error("Invalid arity: "+arguments.length);};c.j=b;c.v=a;return c}();$CLJS.A5=function(){function a(d,e,f){return $CLJS.eb(function(k,l){return $CLJS.p5.j(k,e,l)},d,$CLJS.z5.j(d,e,f))}function b(d,e){return c.j?c.j(d,-1,e):c.call(null,d,-1,e)}var c=null;c=function(d,e,f){switch(arguments.length){case 2:return b.call(this,d,e);case 3:return a.call(this,d,e,f)}throw Error("Invalid arity: "+arguments.length);};c.g=b;c.j=a;return c}();