var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.string.js");require("./medley.core.js");require("./metabase.lib.aggregation.js");require("./metabase.lib.binning.js");require("./metabase.lib.breakout.js");require("./metabase.lib.expression.js");require("./metabase.lib.field.js");require("./metabase.lib.hierarchy.js");require("./metabase.lib.join.js");require("./metabase.lib.join.util.js");require("./metabase.lib.metadata.js");require("./metabase.lib.metadata.calculation.js");require("./metabase.lib.normalize.js");require("./metabase.lib.schema.js");require("./metabase.lib.schema.id.js");require("./metabase.lib.temporal_bucket.js");require("./metabase.lib.util.js");require("./metabase.shared.util.i18n.js");require("./metabase.util.js");require("./metabase.util.malli.js");
'use strict';var nsa,osa,u7,psa,qsa,rsa,ssa,tsa,usa,vsa,v7,w7,x7,wsa,y7;
nsa=function(a){return function(b){var c=$CLJS.af($CLJS.ch);return function(){function d(l,m){var t=a.h?a.h(m):a.call(null,m);if($CLJS.Hd($CLJS.q(c),t))return l;c.Cd(null,$CLJS.ce.g(c.Qb(null),t));return b.g?b.g(l,m):b.call(null,l,m)}function e(l){return b.h?b.h(l):b.call(null,l)}function f(){return b.o?b.o():b.call(null)}var k=null;k=function(l,m){switch(arguments.length){case 0:return f.call(this);case 1:return e.call(this,l);case 2:return d.call(this,l,m)}throw Error("Invalid arity: "+arguments.length);
};k.o=f;k.h=e;k.g=d;return k}()}};osa=function(a,b,c){return $CLJS.fg.j($CLJS.Df,$CLJS.CD(function(d){return $CLJS.n0.v(a,b,d,new $CLJS.h(null,2,[$CLJS.Y_,c,$CLJS.S_,!1],null))}),$CLJS.$M.h($CLJS.bW(a,b)))};u7=function(a,b,c){return $CLJS.fg.j($CLJS.Df,$CLJS.CD(function(d){return $CLJS.l0.v(a,b,d,c)}),$CLJS.$M.h($CLJS.bW(a,b)))};$CLJS.x3=function(a,b){return $CLJS.eb(function(c,d){return $CLJS.nW.l(c,d,$CLJS.R,$CLJS.H([$CLJS.Z6,$CLJS.l0.j(c,d,$CLJS.bW(c,d))]))},a,$CLJS.Ut(0,$CLJS.aW(a,b)))};
psa=function(a,b){a=$CLJS.bW(a,b);a=$CLJS.O(a);var c=$CLJS.J.g(a,$CLJS.Fj),d=$CLJS.J.g(a,$CLJS.kV);b=$CLJS.Z6.h(a);if($CLJS.n(b))return b;var e=$CLJS.KU.h(a);if($CLJS.n(e)&&$CLJS.n(function(){var k=$CLJS.E.g(c,$CLJS.yV);return k?k:d}())){var f=function(){var k=c instanceof $CLJS.M?c.S:null;switch(k){case "mbql.stage/native":return $CLJS.sZ;case "mbql.stage/mbql":return $CLJS.lZ;default:throw Error(["No matching clause: ",$CLJS.p.h(k)].join(""));}}();return $CLJS.Ge(function(){return function m(l){return new $CLJS.oe(null,
function(){for(;;){var t=$CLJS.y(l);if(t){if($CLJS.Ad(t)){var u=$CLJS.lc(t),v=$CLJS.D(u),x=$CLJS.re(v);a:for(var A=0;;)if(A<v){var C=$CLJS.kd(u,A);C=$CLJS.hl.l($CLJS.H([new $CLJS.h(null,2,[$CLJS.UY,$CLJS.T.h(C),$CLJS.iZ,$CLJS.T.h(C)],null),C,new $CLJS.h(null,1,[$CLJS.iK,f],null)]));x.add(C);A+=1}else{u=!0;break a}return u?$CLJS.ue($CLJS.we(x),m($CLJS.mc(t))):$CLJS.ue($CLJS.we(x),null)}x=$CLJS.z(t);return $CLJS.fe($CLJS.hl.l($CLJS.H([new $CLJS.h(null,2,[$CLJS.UY,$CLJS.T.h(x),$CLJS.iZ,$CLJS.T.h(x)],
null),x,new $CLJS.h(null,1,[$CLJS.iK,f],null)])),m($CLJS.Lc(t)))}return null}},null,null)}($CLJS.ZV.h(e))}())}return null};
qsa=function(a,b,c){return $CLJS.Ge(function(){return function f(e){return new $CLJS.oe(null,function(){for(;;){var k=$CLJS.y(e);if(k){if($CLJS.Ad(k)){var l=$CLJS.lc(k),m=$CLJS.D(l),t=$CLJS.re(m);return function(){for(var v=0;;)if(v<m){var x=$CLJS.kd(l,v),A=t,C=$CLJS.R,G=C.l,K=x,S=$CLJS.$z($CLJS.UY,$CLJS.T)(x);x=$CLJS.k1(a,x);x=c.h?c.h(x):c.call(null,x);C=G.call(C,K,$CLJS.iK,$CLJS.zK,$CLJS.H([$CLJS.UY,S,$CLJS.iZ,x]));A.add(C);v+=1}else return!0}()?$CLJS.ue($CLJS.we(t),f($CLJS.mc(k))):$CLJS.ue($CLJS.we(t),
null)}var u=$CLJS.z(k);return $CLJS.fe($CLJS.R.l(u,$CLJS.iK,$CLJS.zK,$CLJS.H([$CLJS.UY,$CLJS.$z($CLJS.UY,$CLJS.T)(u),$CLJS.iZ,function(){var v=$CLJS.k1(a,u);return c.h?c.h(v):c.call(null,v)}()])),f($CLJS.Lc(k)))}return null}},null,null)}($CLJS.x5.g(a,b))}())};
rsa=function(a,b,c){return $CLJS.Ge(function(){return function f(e){return new $CLJS.oe(null,function(){for(;;){var k=$CLJS.y(e);if(k){if($CLJS.Ad(k)){var l=$CLJS.lc(k),m=$CLJS.D(l),t=$CLJS.re(m);return function(){for(var v=0;;)if(v<m){var x=$CLJS.kd(l,v),A=t,C=$CLJS.R,G=C.l,K=x,S=$CLJS.T.h(x);x=$CLJS.T.h(x);x=c.h?c.h(x):c.call(null,x);C=G.call(C,K,$CLJS.iK,$CLJS.IK,$CLJS.H([$CLJS.UY,S,$CLJS.iZ,x]));A.add(C);v+=1}else return!0}()?$CLJS.ue($CLJS.we(t),f($CLJS.mc(k))):$CLJS.ue($CLJS.we(t),null)}var u=
$CLJS.z(k);return $CLJS.fe($CLJS.R.l(u,$CLJS.iK,$CLJS.IK,$CLJS.H([$CLJS.UY,$CLJS.T.h(u),$CLJS.iZ,function(){var v=$CLJS.T.h(u);return c.h?c.h(v):c.call(null,v)}()])),f($CLJS.Lc(k)))}return null}},null,null)}($CLJS.f4.g(a,b))}())};
ssa=function(a,b,c){var d=$CLJS.bW(a,b);if($CLJS.n(d)){d=$CLJS.O(d);var e=$CLJS.J.g(d,$CLJS.mE);return $CLJS.Ge(function(){return function l(k){return new $CLJS.oe(null,function(){for(;;){var m=$CLJS.y(k);if(m){if($CLJS.Ad(m)){var t=$CLJS.lc(m),u=$CLJS.D(t),v=$CLJS.re(u);return function(){for(var K=0;;)if(K<u){var S=$CLJS.kd(t,K),W=$CLJS.I(S,0,null),Z=function(){var ua=W;ua=ua instanceof $CLJS.M?ua.S:null;switch(ua){case "field":return $CLJS.qZ;case "expression":return $CLJS.cZ;default:throw Error(["No matching clause: ",
$CLJS.p.h(ua)].join(""));}}(),ha=$CLJS.B_.j(a,b,S);$CLJS.ve(v,$CLJS.R.l(ha,$CLJS.iK,Z,$CLJS.H([$CLJS.UY,$CLJS.e0.j(a,b,ha),$CLJS.iZ,function(){var ua=$CLJS.k1(a,ha);return c.h?c.h(ua):c.call(null,ua)}()])));K+=1}else return!0}()?$CLJS.ue($CLJS.we(v),l($CLJS.mc(m))):$CLJS.ue($CLJS.we(v),null)}var x=$CLJS.z(m),A=$CLJS.I(x,0,null),C=function(){var K=A;K=K instanceof $CLJS.M?K.S:null;switch(K){case "field":return $CLJS.qZ;case "expression":return $CLJS.cZ;default:throw Error(["No matching clause: ",$CLJS.p.h(K)].join(""));
}}(),G=$CLJS.B_.j(a,b,x);return $CLJS.fe($CLJS.R.l(G,$CLJS.iK,C,$CLJS.H([$CLJS.UY,$CLJS.e0.j(a,b,G),$CLJS.iZ,function(){var K=$CLJS.k1(a,G);return c.h?c.h(K):c.call(null,K)}()])),l($CLJS.Lc(m)))}return null}},null,null)}(e)}())}return null};tsa=function(a,b,c){return $CLJS.Ge($CLJS.fg.j($CLJS.Df,$CLJS.CD(function(d){return d.j?d.j(a,b,c):d.call(null,a,b,c)}),new $CLJS.P(null,2,5,$CLJS.Q,[qsa,rsa],null)))};
usa=function(a,b,c){var d=$CLJS.v1(a,b);return $CLJS.n(d)?$CLJS.Ge(function(){return function k(f){return new $CLJS.oe(null,function(){for(;;){var l=$CLJS.y(f);if(l){if($CLJS.Ad(l)){var m=$CLJS.lc(l),t=$CLJS.D(m),u=$CLJS.re(t);return function(){for(var A=0;;)if(A<t){var C=$CLJS.kd(m,A);var G=$CLJS.$z($CLJS.iZ,$CLJS.UY)(C);G=$CLJS.n(G)?G:$CLJS.e0.j(a,b,C);C=$CLJS.Ik.l($CLJS.hl.l($CLJS.H([C,new $CLJS.h(null,3,[$CLJS.iK,$CLJS.VY,$CLJS.UY,G,$CLJS.iZ,c.h?c.h(G):c.call(null,G)],null),$CLJS.n($CLJS.p1.h(C))?
$CLJS.fm(C,new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.p1],null)):null])),$CLJS.fW,$CLJS.H([$CLJS.MD]));u.add(C);A+=1}else return!0}()?$CLJS.ue($CLJS.we(u),k($CLJS.mc(l))):$CLJS.ue($CLJS.we(u),null)}var v=$CLJS.z(l),x=function(){var A=$CLJS.$z($CLJS.iZ,$CLJS.UY)(v);return $CLJS.n(A)?A:$CLJS.e0.j(a,b,v)}();return $CLJS.fe($CLJS.Ik.l($CLJS.hl.l($CLJS.H([v,new $CLJS.h(null,3,[$CLJS.iK,$CLJS.VY,$CLJS.UY,x,$CLJS.iZ,c.h?c.h(x):c.call(null,x)],null),$CLJS.n($CLJS.p1.h(v))?$CLJS.fm(v,new $CLJS.P(null,1,5,$CLJS.Q,
[$CLJS.p1],null)):null])),$CLJS.fW,$CLJS.H([$CLJS.MD])),k($CLJS.Lc(l)))}return null}},null,null)}($CLJS.l0.j(a,d,$CLJS.bW(a,d)))}()):null};vsa=function(a,b,c,d){return $CLJS.n(c)?(c=$CLJS.y_(a,c),$CLJS.n(c)?$CLJS.Ge($CLJS.n0.v(a,b,c,d)):null):null};
v7=function(a,b,c){return $CLJS.Ge(function(){return function f(e){return new $CLJS.oe(null,function(){for(;;){var k=$CLJS.y(e);if(k){if($CLJS.Ad(k)){var l=$CLJS.lc(k),m=$CLJS.D(l),t=$CLJS.re(m);return function(){for(var v=0;;)if(v<m){var x=$CLJS.kd(l,v),A=t;var C=$CLJS.TA.h(x);var G=$CLJS.R.l,K=x,S=$CLJS.T.h(x);x=$CLJS.T.h(x);x=c.h?c.h(x):c.call(null,x);C=$CLJS.t1(G.call($CLJS.R,K,$CLJS.iK,$CLJS.cZ,$CLJS.H([$CLJS.UY,S,$CLJS.iZ,x])),$CLJS.Gi,$CLJS.n(C)?C:$CLJS.Bj);A.add(C);v+=1}else return!0}()?$CLJS.ue($CLJS.we(t),
f($CLJS.mc(k))):$CLJS.ue($CLJS.we(t),null)}var u=$CLJS.z(k);return $CLJS.fe(function(){var v=$CLJS.TA.h(u),x=$CLJS.R.l,A=u,C=$CLJS.T.h(u);var G=$CLJS.T.h(u);G=c.h?c.h(G):c.call(null,G);return $CLJS.t1(x.call($CLJS.R,A,$CLJS.iK,$CLJS.cZ,$CLJS.H([$CLJS.UY,C,$CLJS.iZ,G])),$CLJS.Gi,$CLJS.n(v)?v:$CLJS.Bj)}(),f($CLJS.Lc(k)))}return null}},null,null)}($CLJS.Goa.g(a,b))}())};
w7=function(a,b,c){var d=$CLJS.O(c),e=$CLJS.J.g(d,$CLJS.Y_);return $CLJS.Sk.g(function(f){return $CLJS.Ik.l(f,$CLJS.yZ,$CLJS.H([$CLJS.fW,$CLJS.lW,$CLJS.wK]))},function(){var f=usa(a,b,e);if($CLJS.n(f))return f;f=$CLJS.bW(a,b);f=$CLJS.O(f);var k=$CLJS.J.g(f,$CLJS.BO),l=$CLJS.J.g(f,$CLJS.kV),m=$CLJS.n(k)?function(){var t=$CLJS.w_(a,k);return $CLJS.n0.v(a,b,t,d)}():null;if($CLJS.n(m))return m;l=$CLJS.n(l)?vsa(a,b,l,$CLJS.R.j(d,$CLJS.S_,!1)):null;return $CLJS.n(l)?l:function v(u){return new $CLJS.oe(null,
function(){for(;;){var x=$CLJS.y(u);if(x){if($CLJS.Ad(x)){var A=$CLJS.lc(x),C=$CLJS.D(A),G=$CLJS.re(C);return function(){for(var S=0;;)if(S<C){var W=$CLJS.kd(A,S),Z=G,ha=$CLJS.R,ua=ha.l,Ta=W,Cb=$CLJS.T.h(W);W=$CLJS.T.h(W);W=e.h?e.h(W):e.call(null,W);ha=ua.call(ha,Ta,$CLJS.iK,$CLJS.sZ,$CLJS.H([$CLJS.UY,Cb,$CLJS.iZ,W]));Z.add(ha);S+=1}else return!0}()?$CLJS.ue($CLJS.we(G),v($CLJS.mc(x))):$CLJS.ue($CLJS.we(G),null)}var K=$CLJS.z(x);return $CLJS.fe($CLJS.R.l(K,$CLJS.iK,$CLJS.sZ,$CLJS.H([$CLJS.UY,$CLJS.T.h(K),
$CLJS.iZ,function(){var S=$CLJS.T.h(K);return e.h?e.h(S):e.call(null,S)}()])),v($CLJS.Lc(x)))}return null}},null,null)}($CLJS.ZV.h($CLJS.KU.h(f)))}())};
x7=function(a,b){var c=$CLJS.I(a,0,null);$CLJS.I(a,1,null);var d=$CLJS.I(a,2,null);switch(c instanceof $CLJS.M?c.S:null){case "field":if("number"===typeof d||"string"===typeof d)return $CLJS.E.g(d,$CLJS.$i.h(b));throw $CLJS.ii("unknown type of :field ref in lib.stage/ref-to?",new $CLJS.h(null,2,[$CLJS.vR,a,$CLJS.Ui,b],null));case "expression":return $CLJS.E.g(d,$CLJS.T.h(b));default:throw $CLJS.ii("unknown clause in lib.stage/ref-to?",new $CLJS.h(null,2,[$CLJS.vR,a,$CLJS.Ui,b],null));}};
wsa=function(a,b,c){var d=$CLJS.tF.h($CLJS.bW(a,b));return $CLJS.n(d)?function k(f){return new $CLJS.oe(null,function(){for(;;){var l=$CLJS.y(f);if(l){var m=l;if($CLJS.Ad(m)){var t=$CLJS.lc(m),u=$CLJS.D(t),v=$CLJS.re(u);return function(){for(var A=0;;)if(A<u){var C=$CLJS.kd(t,A);$CLJS.ve(v,function(){var G=$CLJS.QG(function(W,Z){return function(ha){return x7(ha,Z)}}(A,C,t,u,v,m,l,d,d),d);if($CLJS.n(G)){var K=$CLJS.V6(G);G=$CLJS.m2(G);G=$CLJS.O(G);G=$CLJS.J.g(G,$CLJS.ti);var S=C;K=$CLJS.n(K)?$CLJS.T4(S,
K):S;return $CLJS.n(G)?$CLJS.d3(K,G):K}return C}());A+=1}else return!0}()?$CLJS.ue($CLJS.we(v),k($CLJS.mc(m))):$CLJS.ue($CLJS.we(v),null)}var x=$CLJS.z(m);return $CLJS.fe(function(){var A=$CLJS.QG(function(K){return function(S){return x7(S,K)}}(x,m,l,d,d),d);if($CLJS.n(A)){var C=$CLJS.V6(A);A=$CLJS.m2(A);A=$CLJS.O(A);A=$CLJS.J.g(A,$CLJS.ti);var G=x;C=$CLJS.n(C)?$CLJS.T4(G,C):G;return $CLJS.n(A)?$CLJS.d3(C,A):C}return x}(),k($CLJS.Lc(m)))}return null}},null,null)}(c):c};
y7=new $CLJS.M("metabase.lib.stage","stage","metabase.lib.stage/stage",1448689281);$CLJS.lqa={};$CLJS.LE($CLJS.rV,y7);$CLJS.LE($CLJS.yV,y7);$CLJS.T0.m(null,$CLJS.rV,function(a){return $CLJS.S0(a,new $CLJS.h(null,2,[$CLJS.uF,$CLJS.We($CLJS.Sk,$CLJS.T0),$CLJS.FV,$CLJS.We($CLJS.Sk,$CLJS.T0)],null))});$CLJS.i0.m(null,y7,function(){throw $CLJS.ii("You can't calculate a metadata map for a stage! Use lib.metadata.calculation/returned-columns-method instead.",$CLJS.N);});
$CLJS.m0.m(null,y7,function(a,b,c,d){d=$CLJS.O(d);c=$CLJS.J.g(d,$CLJS.Y_);var e=$CLJS.J.g(d,$CLJS.S_);a=$CLJS.x3(a,b);var f=$CLJS.O(d);var k=$CLJS.J.g(f,$CLJS.Y_),l=$CLJS.J.g(f,$CLJS.W_),m=$CLJS.J.g(f,$CLJS.b0);f=$CLJS.hf.l(w7(a,b,f),$CLJS.n(m)?v7(a,b,k):null,$CLJS.H([$CLJS.n(l)?osa(a,b,k):null]));k=$CLJS.hf.g;d=$CLJS.n(e)?(e=$CLJS.Ua($CLJS.kV.h($CLJS.bW(a,b))))?e:$CLJS.$_.h(d):e;return wsa(a,b,k.call($CLJS.hf,f,$CLJS.n(d)?$CLJS.l1(a,b,f,c):null))});
$CLJS.k0.m(null,y7,function(a,b,c,d){c=$CLJS.O(d);d=$CLJS.J.g(c,$CLJS.Y_);var e=psa(a,b);if($CLJS.n(e))return e;a=$CLJS.x3(a,b);e=tsa(a,b,d);var f=ssa(a,b,d);return $CLJS.n(e)?$CLJS.fg.g(e,f):$CLJS.n(f)?($CLJS.nh(f),$CLJS.fg.j($CLJS.Df,nsa(function(k){return $CLJS.Ik.l(k,$CLJS.wZ,$CLJS.H([$CLJS.iK,$CLJS.G0,$CLJS.iZ]))}),$CLJS.hf.g(f,u7(a,b,c)))):$CLJS.hf.l(w7(a,b,new $CLJS.h(null,2,[$CLJS.S_,!1,$CLJS.Y_,d],null)),v7(a,b,d),$CLJS.H([u7(a,b,c)]))});$CLJS.c0.m(null,$CLJS.yV,function(){return $CLJS.eE("Native query")});
var z7=new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.BO,$CLJS.kV,$CLJS.$M],null),A7=new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.uF,$CLJS.tF,$CLJS.FV,$CLJS.kR,$CLJS.HR],null);
$CLJS.c0.m(null,$CLJS.rV,function(a,b,c,d){var e=$CLJS.x3(a,b);a=$CLJS.Ge(function(){var f=$CLJS.fg.j($CLJS.N,$CLJS.Qk.g($CLJS.LB,$CLJS.jf.h(function(l){return new $CLJS.P(null,2,5,$CLJS.Q,[l,$CLJS.coa.j(e,b,l)],null)})),new $CLJS.P(null,2,5,$CLJS.Q,[z7,A7],null)),k=$CLJS.Ms(" + ",$CLJS.Rk.g($CLJS.gA,$CLJS.jf.g(f,z7)));f=$CLJS.jf.g(f,A7);return $CLJS.Ms(", ",$CLJS.Rk.g($CLJS.gA,$CLJS.fe(k,f)))}());if($CLJS.n(a))return a;a=$CLJS.v1(e,b);return $CLJS.n(a)?$CLJS.D_.v(e,a,$CLJS.bW(e,a),d):null});