var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.string.js");require("./clojure.walk.js");require("./medley.core.js");require("./metabase.lib.cache.js");require("./metabase.lib.convert.js");require("./metabase.lib.core.js");require("./metabase.lib.equality.js");require("./metabase.lib.field.js");require("./metabase.lib.join.js");require("./metabase.lib.js.metadata.js");require("./metabase.lib.metadata.js");require("./metabase.lib.metadata.calculation.js");require("./metabase.lib.metadata.protocols.js");require("./metabase.lib.order_by.js");require("./metabase.lib.stage.js");require("./metabase.lib.util.js");require("./metabase.mbql.js.js");require("./metabase.mbql.normalize.js");require("./metabase.shared.util.time.js");require("./metabase.util.js");require("./metabase.util.log.js");require("./metabase.util.memoize.js");
'use strict';var pxa=function(a,b){const c={};for(const d in a)b.call(void 0,a[d],d,a)&&(c[d]=a[d]);return c},qxa=function(a,b){const c={};for(const d in a)c[d]=b.call(void 0,a[d],d,a);return c},x9=function(a){var b=$CLJS.O(a);a=$CLJS.J.g(b,$CLJS.TA);b=$CLJS.J.g(b,$CLJS.Gi);a=$CLJS.n(b)?b:a;return $CLJS.MB(a,$CLJS.ek)||$CLJS.MB(a,$CLJS.qj)},rxa=function(a,b,c,d){var e=$CLJS.fg.j($CLJS.N,$CLJS.hm(function(f,k){return new $CLJS.P(null,2,5,$CLJS.Q,[k,f],null)}),d);return function l(k){return new $CLJS.oe(null,
function(){for(;;){var m=$CLJS.y(k);if(m){if($CLJS.Ad(m)){var t=$CLJS.lc(m),u=$CLJS.D(t),v=$CLJS.re(u);a:for(var x=0;;)if(x<u){var A=$CLJS.kd(t,x);A=$CLJS.H1.v(a,b,A,d);A=$CLJS.J.j(e,A,-1);v.add(A);x+=1}else{t=!0;break a}return t?$CLJS.ue($CLJS.we(v),l($CLJS.mc(m))):$CLJS.ue($CLJS.we(v),null)}v=$CLJS.z(m);v=$CLJS.H1.v(a,b,v,d);return $CLJS.fe($CLJS.J.j(e,v,-1),l($CLJS.Lc(m)))}return null}},null,null)}(c)},y9=function(a,b){return new $CLJS.Kh(function(){try{return $CLJS.t9.g(a,b)}catch(e){if(e instanceof
Error){var c=e,d=$CLJS.lA($CLJS.cy);$CLJS.n($CLJS.kA("metabase.lib.js.metadata",d))&&(c instanceof Error?$CLJS.jA("metabase.lib.js.metadata",d,$CLJS.RV("Error parsing %s objects: %s",$CLJS.H([a,$CLJS.PU(c)])),c):$CLJS.jA("metabase.lib.js.metadata",d,$CLJS.RV(c,$CLJS.H(["Error parsing %s objects: %s",a,$CLJS.PU(c)])),null));return null}throw e;}})},sxa=function(a,b){return function e(d){return new $CLJS.oe(null,function(){for(var f=d;;)if(f=$CLJS.y(f)){if($CLJS.Ad(f)){var k=$CLJS.lc(f),l=$CLJS.D(k),
m=$CLJS.re(l);return function(){for(var x=0;;)if(x<l){var A=$CLJS.kd(k,x);$CLJS.I(A,0,null);var C=$CLJS.I(A,1,null),G=function(){var K=C;return null==K?null:$CLJS.q(K)}();$CLJS.n(function(){var K=G;return $CLJS.n(K)?$CLJS.E.g($CLJS.xK.h(G),b):K}())&&m.add(G);x+=1}else return!0}()?$CLJS.ue($CLJS.we(m),e($CLJS.mc(f))):$CLJS.ue($CLJS.we(m),null)}var t=$CLJS.z(f);$CLJS.I(t,0,null);var u=$CLJS.I(t,1,null),v=function(){var x=u;return null==x?null:$CLJS.q(x)}();if($CLJS.n(function(){var x=v;return $CLJS.n(x)?
$CLJS.E.g($CLJS.xK.h(v),b):x}()))return $CLJS.fe(v,e($CLJS.Lc(f)));f=$CLJS.Lc(f)}else return null},null,null)}(function(){var d=null==a?null:$CLJS.mE.h(a);return null==d?null:$CLJS.q(d)}())},txa=function(a,b){return function e(d){return new $CLJS.oe(null,function(){for(var f=d;;)if(f=$CLJS.y(f)){if($CLJS.Ad(f)){var k=$CLJS.lc(f),l=$CLJS.D(k),m=$CLJS.re(l);return function(){for(var x=0;;)if(x<l){var A=$CLJS.kd(k,x);$CLJS.I(A,0,null);var C=$CLJS.I(A,1,null),G=function(){var K=C;return null==K?null:
$CLJS.q(K)}();$CLJS.n(function(){var K=G;return $CLJS.n(K)?$CLJS.E.g($CLJS.xK.h(G),b):K}())&&m.add(G);x+=1}else return!0}()?$CLJS.ue($CLJS.we(m),e($CLJS.mc(f))):$CLJS.ue($CLJS.we(m),null)}var t=$CLJS.z(f);$CLJS.I(t,0,null);var u=$CLJS.I(t,1,null),v=function(){var x=u;return null==x?null:$CLJS.q(x)}();if($CLJS.n(function(){var x=v;return $CLJS.n(x)?$CLJS.E.g($CLJS.xK.h(v),b):x}()))return $CLJS.fe(v,e($CLJS.Lc(f)));f=$CLJS.Lc(f)}else return null},null,null)}(function(){var d=null==a?null:$CLJS.m9.h(a);
return null==d?null:$CLJS.q(d)}())},uxa=function(a,b){return function e(d){return new $CLJS.oe(null,function(){for(var f=d;;)if(f=$CLJS.y(f)){if($CLJS.Ad(f)){var k=$CLJS.lc(f),l=$CLJS.D(k),m=$CLJS.re(l);return function(){for(var x=0;;)if(x<l){var A=$CLJS.kd(k,x);$CLJS.I(A,0,null);var C=$CLJS.I(A,1,null),G=function(){var K=C;return null==K?null:$CLJS.q(K)}();$CLJS.n(function(){var K=G;return $CLJS.n(K)?$CLJS.E.g($CLJS.xK.h(G),b):K}())&&m.add(G);x+=1}else return!0}()?$CLJS.ue($CLJS.we(m),e($CLJS.mc(f))):
$CLJS.ue($CLJS.we(m),null)}var t=$CLJS.z(f);$CLJS.I(t,0,null);var u=$CLJS.I(t,1,null),v=function(){var x=u;return null==x?null:$CLJS.q(x)}();if($CLJS.n(function(){var x=v;return $CLJS.n(x)?$CLJS.E.g($CLJS.xK.h(v),b):x}()))return $CLJS.fe(v,e($CLJS.Lc(f)));f=$CLJS.Lc(f)}else return null},null,null)}(function(){var d=null==a?null:$CLJS.n9.h(a);return null==d?null:$CLJS.q(d)}())},z9=function(a,b,c,d){this.eg=a;this.Eg=b;this.metadata=c;this.ki=d;this.C=393216;this.I=0},vxa=function(a){return $CLJS.Ya(a)?
pxa(a,function(b){return void 0!==b}):a},A9=function(a){return $CLJS.Ls($CLJS.nz(qxa(a,function(b){return vxa(b)})),function(b){return $CLJS.Tk.j($CLJS.dA(b,$CLJS.Ah),$CLJS.nj,$CLJS.Ah)})},B9=function(a,b){if($CLJS.TY(b))a=b;else{var c=new $CLJS.h(null,6,[$CLJS.r9,y9($CLJS.qP,b),$CLJS.p9,y9($CLJS.kW,b),$CLJS.mE,y9($CLJS.FF,b),$CLJS.k9,y9($CLJS.SM,b),$CLJS.m9,y9($CLJS.AF,b),$CLJS.n9,y9($CLJS.cF,b)],null),d=$CLJS.lA($CLJS.by);$CLJS.n($CLJS.kA("metabase.lib.js.metadata",d))&&$CLJS.jA("metabase.lib.js.metadata",
d,$CLJS.aw.l($CLJS.H(["Created metadata provider for metadata"])),null);a=new z9(a,b,c,$CLJS.N)}return a},C9=function(a){a=$CLJS.zA(a);const b=a.length-1;a=0<=b&&a.indexOf("?",b)==b?["is-",$CLJS.fA(a,/\?$/,"")].join(""):a;return $CLJS.gB.h?$CLJS.gB.h(a):$CLJS.gB.call(null,a)},F9=function(a){return $CLJS.xd(a)?D9.h?D9.h(a):D9.call(null,a):$CLJS.wd(a)?E9.h?E9.h(a):E9.call(null,a):a instanceof $CLJS.M?$CLJS.zA(a):a},G9=function(a,b){return $CLJS.rW($CLJS.rW((0,$CLJS.$X)($CLJS.w9($CLJS.nz(a))),$CLJS.FP,
function(c){return $CLJS.hl.l($CLJS.H([new $CLJS.h(null,1,[$CLJS.bQ,$CLJS.N],null),c]))}),$CLJS.aF,function(c){var d=function(){var e=$CLJS.mE.h(c);return $CLJS.n(e)?e:function l(k){return new $CLJS.oe(null,function(){for(;;){var m=$CLJS.y(k);if(m){if($CLJS.Ad(m)){var t=$CLJS.lc(m),u=$CLJS.D(t),v=$CLJS.re(u);a:for(var x=0;;)if(x<u){var A=$CLJS.kd(t,x);v.add(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.FF,A,null],null));x+=1}else{t=!0;break a}return t?$CLJS.ue($CLJS.we(v),l($CLJS.mc(m))):$CLJS.ue($CLJS.we(v),
null)}v=$CLJS.z(m);return $CLJS.fe(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.FF,v,null],null),l($CLJS.Lc(m)))}return null}},null,null)}(b)}();return $CLJS.R.j(c,$CLJS.mE,$CLJS.T7(d))})},wxa=function(a){return $CLJS.n(function(){var b=$CLJS.z(a),c=new $CLJS.bh(null,new $CLJS.h(null,2,[$CLJS.cF,null,$CLJS.AF,null],null),null);return c.h?c.h(b):c.call(null,b)}())?$CLJS.Xk.j(a,0,2):$CLJS.Tk.v(a,2,$CLJS.Ls,function(b){return $CLJS.ne(b)?$CLJS.zA(b):b})},H9=function(a){return $CLJS.vY.h($CLJS.Tk.j($CLJS.mz(a,
$CLJS.H([$CLJS.Ai,!0])),0,$CLJS.Ah))},xxa=function(a){var b=$CLJS.Ya(a)?a:null;return $CLJS.n(b)?(a=$CLJS.v9.h?$CLJS.v9.h(b):$CLJS.v9.call(null,b),$CLJS.E.g($CLJS.iK.h(a),$CLJS.IK)?(b=H9(b.field_ref),$CLJS.R.j(a,$CLJS.G0,$CLJS.id(b))):a):a},I9=function(a,b){var c=null==a?null:$CLJS.z(a);c=null==c?null:$CLJS.E.g(c,$CLJS.uF);return $CLJS.n(c)?$CLJS.R.j(b,$CLJS.G0,$CLJS.id(a)):b},J9=function(a){return function(b){var c=function(){var e=a.h?a.h(b):a.call(null,b);return $CLJS.v9.h?$CLJS.v9.h(e):$CLJS.v9.call(null,
e)}(),d=function(){var e=$CLJS.q1.h(c);return $CLJS.n(e)?H9(e):null}();return new $CLJS.h(null,3,[$CLJS.Ui,I9(d,c),$CLJS.L5,d,$CLJS.Ej,b.value],null)}};$CLJS.g=z9.prototype;$CLJS.g.P=function(a,b){return new z9(this.eg,this.Eg,this.metadata,b)};$CLJS.g.O=function(){return this.ki};$CLJS.g.li=$CLJS.Bc;$CLJS.g.ug=function(a,b){a=this.metadata;a=null==a?null:$CLJS.m9.h(a);a=null==a?null:$CLJS.q(a);b=null==a?null:$CLJS.J.g(a,b);return null==b?null:$CLJS.q(b)};
$CLJS.g.vg=function(a,b){return txa(this.metadata,b)};$CLJS.g.zg=function(a,b){a=this.metadata;a=null==a?null:$CLJS.p9.h(a);a=null==a?null:$CLJS.q(a);b=null==a?null:$CLJS.J.g(a,b);return null==b?null:$CLJS.q(b)};$CLJS.g.tg=function(a,b){return sxa(this.metadata,b)};$CLJS.g.sg=function(a,b){a=this.metadata;a=null==a?null:$CLJS.mE.h(a);a=null==a?null:$CLJS.q(a);b=null==a?null:$CLJS.J.g(a,b);return null==b?null:$CLJS.q(b)};$CLJS.g.xg=function(a,b){return uxa(this.metadata,b)};
$CLJS.g.yg=function(a,b){return $CLJS.$8($CLJS.$8(this.Eg,"settings"),$CLJS.hh(b))};$CLJS.g.qg=function(a,b){a=this.metadata;a=null==a?null:$CLJS.k9.h(a);a=null==a?null:$CLJS.q(a);b=null==a?null:$CLJS.J.g(a,b);return null==b?null:$CLJS.q(b)};$CLJS.g.wg=function(a,b){a=this.metadata;a=null==a?null:$CLJS.n9.h(a);a=null==a?null:$CLJS.q(a);b=null==a?null:$CLJS.J.g(a,b);return null==b?null:$CLJS.q(b)};
$CLJS.g.rg=function(){var a=this.metadata,b=this.eg;a=null==a?null:$CLJS.r9.h(a);a=null==a?null:$CLJS.q(a);b=null==a?null:$CLJS.J.g(a,b);return null==b?null:$CLJS.q(b)};var K9=function K9(a){switch(arguments.length){case 1:return K9.h(arguments[0]);case 2:return K9.g(arguments[0],arguments[1]);default:throw Error(["Invalid arity: ",$CLJS.p.h(arguments.length)].join(""));}};$CLJS.za("metabase.lib.js.extract_template_tags",K9);K9.h=function(a){return K9.g(a,$CLJS.N)};K9.g=function(a,b){return $CLJS.kl($CLJS.lwa.l($CLJS.H([a,A9(b)])))};K9.A=2;
var L9=function L9(a){switch(arguments.length){case 2:return L9.g(arguments[0],arguments[1]);case 3:return L9.j(arguments[0],arguments[1],arguments[2]);default:throw Error(["Invalid arity: ",$CLJS.p.h(arguments.length)].join(""));}};$CLJS.za("metabase.lib.js.query",L9);L9.g=function(a,b){return $CLJS.X8.l($CLJS.H([a,b]))};
L9.j=function(a,b,c){c=$CLJS.vY.h((0,$CLJS.$X)($CLJS.t1($CLJS.m7(c),$CLJS.nj,$CLJS.aF)));var d=$CLJS.lA($CLJS.by);$CLJS.n($CLJS.kA("metabase.lib.js",d))&&$CLJS.jA("metabase.lib.js",d,$CLJS.RV("query map: %s",$CLJS.H([$CLJS.Nh.l($CLJS.H([c]))])),null);return $CLJS.X8.l($CLJS.H([B9(a,b),c]))};L9.A=3;
var M9=function M9(a){return $CLJS.ne(a)?[$CLJS.je(a),"/",$CLJS.hh(a)].join(""):$CLJS.xd(a)?$CLJS.Ls(a,M9):$CLJS.wd(a)?$CLJS.jf.g(M9,a):a},D9=$CLJS.yA(function(a){return $CLJS.eb(function(b,c){var d=$CLJS.I(c,0,null);c=$CLJS.I(c,1,null);d=C9(d);c=F9.h?F9.h(c):F9.call(null,c);b[d]=c;return b},{},a)}),E9=$CLJS.yA(function(a){return $CLJS.xe($CLJS.jf.g(F9,a))}),N9=function N9(a){switch(arguments.length){case 1:return N9.h(arguments[0]);case 2:return N9.g(arguments[0],arguments[1]);default:throw Error(["Invalid arity: ",
$CLJS.p.h(arguments.length)].join(""));}};$CLJS.za("metabase.lib.js.order_by_clause",N9);N9.h=function(a){return N9.g(a,$CLJS.CF)};N9.g=function(a,b){return $CLJS.owa.l($CLJS.H([$CLJS.W8.l($CLJS.H([$CLJS.mz(a,$CLJS.H([$CLJS.Ai,!0]))])),$CLJS.Ah.h(b)]))};N9.A=2;var O9=function O9(a){switch(arguments.length){case 2:return O9.g(arguments[0],arguments[1]);case 3:return O9.j(arguments[0],arguments[1],arguments[2]);default:throw Error(["Invalid arity: ",$CLJS.p.h(arguments.length)].join(""));}};
$CLJS.za("metabase.lib.js.available_binning_strategies",O9);O9.g=function(a,b){return $CLJS.xe($CLJS.V8.l($CLJS.H([a,b])))};O9.j=function(a,b,c){return $CLJS.xe($CLJS.V8.l($CLJS.H([a,b,c])))};O9.A=3;var P9=function P9(a){switch(arguments.length){case 2:return P9.g(arguments[0],arguments[1]);case 3:return P9.j(arguments[0],arguments[1],arguments[2]);default:throw Error(["Invalid arity: ",$CLJS.p.h(arguments.length)].join(""));}};$CLJS.za("metabase.lib.js.available_temporal_buckets",P9);
P9.g=function(a,b){return $CLJS.xe($CLJS.Z8.l($CLJS.H([a,b])))};P9.j=function(a,b,c){return $CLJS.xe($CLJS.Z8.l($CLJS.H([a,b,c])))};P9.A=3;var Q9=function Q9(a){switch(arguments.length){case 2:return Q9.g(arguments[0],arguments[1]);case 3:return Q9.j(arguments[0],arguments[1],arguments[2]);default:throw Error(["Invalid arity: ",$CLJS.p.h(arguments.length)].join(""));}};$CLJS.za("metabase.lib.js.query_EQ_",Q9);Q9.g=function(a,b){return Q9.j(a,b,null)};
Q9.j=function(a,b,c){a=G9(a,c);b=G9(b,c);return $CLJS.E.g(a,b)};Q9.A=3;var R9=function R9(a){switch(arguments.length){case 1:return R9.h(arguments[0]);case 2:return R9.g(arguments[0],arguments[1]);default:throw Error(["Invalid arity: ",$CLJS.p.h(arguments.length)].join(""));}};$CLJS.za("metabase.lib.js.aggregation_clause",R9);R9.h=function(a){return $CLJS.T8.l($CLJS.H([a]))};R9.g=function(a,b){return $CLJS.T8.l($CLJS.H([a,b]))};R9.A=2;
var S9=function S9(a){for(var c=[],d=arguments.length,e=0;;)if(e<d)c.push(arguments[e]),e+=1;else break;return S9.l(arguments[0],arguments[1],2<c.length?new $CLJS.w(c.slice(2),0,null):null)};$CLJS.za("metabase.lib.js.filter_clause",S9);S9.l=function(a,b,c){return $CLJS.Te.v($CLJS.Dva,a,b,c)};S9.A=2;S9.B=function(a){var b=$CLJS.z(a),c=$CLJS.B(a);a=$CLJS.z(c);c=$CLJS.B(c);return this.l(b,a,c)};
var T9=function T9(a){switch(arguments.length){case 2:return T9.g(arguments[0],arguments[1]);case 3:return T9.j(arguments[0],arguments[1],arguments[2]);default:throw Error(["Invalid arity: ",$CLJS.p.h(arguments.length)].join(""));}};$CLJS.za("metabase.lib.js.expressionable_columns",T9);T9.g=function(a,b){return T9.g(a,b)};T9.j=function(a,b,c){return $CLJS.xe($CLJS.mva.l($CLJS.H([a,b,c])))};T9.A=3;
var U9=function U9(a){switch(arguments.length){case 3:return U9.j(arguments[0],arguments[1],arguments[2]);case 4:return U9.v(arguments[0],arguments[1],arguments[2],arguments[3]);default:throw Error(["Invalid arity: ",$CLJS.p.h(arguments.length)].join(""));}};$CLJS.za("metabase.lib.js.with_different_database",U9);U9.j=function(a,b,c){return U9.v(a,b,c,null)};U9.v=function(a,b,c,d){return $CLJS.jwa.l($CLJS.H([a,B9(b,c),$CLJS.mz(d,$CLJS.H([$CLJS.Ai,!0]))]))};U9.A=4;
var yxa=J9(function(a){return a.col}),zxa=J9(function(a){return a.column}),V9=function V9(a){for(var c=[],d=arguments.length,e=0;;)if(e<d)c.push(arguments[e]),e+=1;else break;return V9.l(arguments[0],arguments[1],arguments[2],3<c.length?new $CLJS.w(c.slice(3),0,null):null)};$CLJS.za("metabase.lib.js.drill_thru",V9);V9.l=function(a,b,c,d){return $CLJS.Te.N($CLJS.fva,a,b,c,d)};V9.A=3;
V9.B=function(a){var b=$CLJS.z(a),c=$CLJS.B(a);a=$CLJS.z(c);var d=$CLJS.B(c);c=$CLJS.z(d);d=$CLJS.B(d);return this.l(b,a,c,d)};
module.exports={returned_columns:function(a,b){var c=$CLJS.bW(a,b);return $CLJS.xe($CLJS.jf.g(function(d){return $CLJS.R.j(d,$CLJS.jZ,!0)},$CLJS.l0.j(a,b,c)))},legacy_expression_for_expression_clause:function(a,b,c){b=$CLJS.U8.l($CLJS.H([a,b]));a=$CLJS.fg.j($CLJS.N,$CLJS.hm(function(k,l){$CLJS.I(l,0,null);l=$CLJS.I(l,1,null);l=$CLJS.O(l);l=$CLJS.J.g(l,$CLJS.UD);return new $CLJS.P(null,2,5,$CLJS.Q,[k,l],null)}),b);b=$CLJS.fg.j($CLJS.N,$CLJS.hm(function(k,l){$CLJS.I(l,0,null);l=$CLJS.I(l,1,null);l=
$CLJS.O(l);l=$CLJS.J.g(l,$CLJS.UD);return new $CLJS.P(null,2,5,$CLJS.Q,[l,k],null)}),b);var d=$CLJS.uY,e=$CLJS.tY;$CLJS.uY=a;$CLJS.tY=b;try{var f=$CLJS.kY.h(c);return $CLJS.kl($CLJS.zd(f)&&$CLJS.E.g($CLJS.z(f),$CLJS.RL)?$CLJS.J.g(f,1):f)}finally{$CLJS.tY=e,$CLJS.uY=d}},drop_stage_if_empty:function(a){return $CLJS.zwa.l($CLJS.H([a]))},extract_template_tags:K9,engine:function(a){return $CLJS.hh($CLJS.ewa.l($CLJS.H([a])))},field_id:function(a){return $CLJS.sva.l($CLJS.H([a]))},filter_operator:function(a,
b,c){return $CLJS.Eva.l($CLJS.H([a,b,c]))},binning:function(a){return $CLJS.Wua.l($CLJS.H([a]))},query:L9,columns_group_columns:function(a){return $CLJS.xe($CLJS.ava.l($CLJS.H([a])))},join_fields:function(a){a=$CLJS.Pva.l($CLJS.H([a]));return a instanceof $CLJS.M?$CLJS.zA(a):$CLJS.xe(a)},aggregate:function(a,b,c){return $CLJS.Sua.l($CLJS.H([a,b,$CLJS.mz(c,$CLJS.H([$CLJS.Ai,!0]))]))},legacy_card_or_table_id:function(a){return $CLJS.tva.l($CLJS.H([a]))},join_conditions:function(a){return $CLJS.xe($CLJS.Ova.l($CLJS.H([a])))},
query_EQ_:Q9,temporal_bucket:function(a){return $CLJS.Dwa.l($CLJS.H([a]))},available_drill_thrus:function(a,b,c,d,e,f){var k=$CLJS.U8.l($CLJS.H([a,b])),l=$CLJS.fg.j($CLJS.N,$CLJS.hm(function(v,x){$CLJS.I(x,0,null);x=$CLJS.I(x,1,null);x=$CLJS.O(x);x=$CLJS.J.g(x,$CLJS.UD);return new $CLJS.P(null,2,5,$CLJS.Q,[v,x],null)}),k);k=$CLJS.fg.j($CLJS.N,$CLJS.hm(function(v,x){$CLJS.I(x,0,null);x=$CLJS.I(x,1,null);x=$CLJS.O(x);x=$CLJS.J.g(x,$CLJS.UD);return new $CLJS.P(null,2,5,$CLJS.Q,[x,v],null)}),k);var m=
$CLJS.uY,t=$CLJS.tY;$CLJS.uY=l;$CLJS.tY=k;try{var u=function(){var v=$CLJS.n(c)?c.field_ref:c;return $CLJS.n(v)?H9(v):null}();return $CLJS.xe($CLJS.eva.l($CLJS.H([a,b,$CLJS.hl.l($CLJS.H([new $CLJS.h(null,3,[$CLJS.Ui,$CLJS.n(c)?I9(u,$CLJS.v9.h?$CLJS.v9.h(c):$CLJS.v9.call(null,c)):null,$CLJS.L5,u,$CLJS.Ej,void 0===d?null:null==d?$CLJS.iL:d],null),$CLJS.n(e)?new $CLJS.h(null,1,[$CLJS.K5,$CLJS.Sk.g(yxa,e)],null):null,$CLJS.n($CLJS.Ge(f))?new $CLJS.h(null,1,[$CLJS.dL,$CLJS.Sk.g(zxa,f)],null):null]))])))}finally{$CLJS.tY=
t,$CLJS.uY=m}},remove_clause:function(a,b,c){return $CLJS.swa.l($CLJS.H([a,b,$CLJS.W8.l($CLJS.H([$CLJS.mz(c,$CLJS.H([$CLJS.Ai,!0]))]))]))},source_table_or_card_id:function(a){var b=$CLJS.q_(a);if($CLJS.n(b))return b;a=$CLJS.x1(a);return null==a?null:["card__",$CLJS.p.h(a)].join("")},legacy_ref:function(a){return $CLJS.kl(wxa($CLJS.kY.h($CLJS.Y8.l($CLJS.H([a])))))},replace_clause:function(a,b,c,d){return $CLJS.vwa.l($CLJS.H([a,b,$CLJS.W8.l($CLJS.H([$CLJS.mz(c,$CLJS.H([$CLJS.Ai,!0]))])),$CLJS.W8.l($CLJS.H([$CLJS.mz(d,
$CLJS.H([$CLJS.Ai,!0]))]))]))},drop_stage:function(a){return $CLJS.ywa.l($CLJS.H([a]))},join_lhs_display_name:function(a,b,c,d){return $CLJS.Qva.l($CLJS.H([a,b,c,d]))},add_field:function(a,b,c){return $CLJS.rva.l($CLJS.H([a,b,c]))},pivot_types:function(a){return $CLJS.xe($CLJS.jf.g($CLJS.hh,$CLJS.hva.l($CLJS.H([a]))))},native_extras:function(a){return $CLJS.kl($CLJS.hwa.l($CLJS.H([a])))},available_temporal_buckets:P9,with_binning:function(a,b){return $CLJS.Xua.l($CLJS.H([a,b]))},append_stage:function(a){return $CLJS.xwa.l($CLJS.H([a]))},
join:function(a,b,c){return $CLJS.Iva.l($CLJS.H([a,b,c]))},aggregations:function(a,b){return $CLJS.xe($CLJS.U8.l($CLJS.H([a,b])))},drill_thru:V9,raw_native_query:function(a){return $CLJS.bwa.l($CLJS.H([a]))},filterable_column_operators:function(a){return $CLJS.xe($CLJS.Cva.l($CLJS.H([a])))},with_temporal_bucket:function(a,b){return $CLJS.Ewa.l($CLJS.H([a,b]))},with_join_conditions:function(a,b){return $CLJS.Xva.l($CLJS.H([a,$CLJS.mz(b,$CLJS.H([$CLJS.Ai,!0]))]))},available_metrics:function(a){return $CLJS.xe($CLJS.$va.l($CLJS.H([a])))},
with_different_database:U9,with_fields:function(a,b,c){return $CLJS.yva.l($CLJS.H([a,b,c]))},is_column_metadata:function(a){return $CLJS.xd(a)&&$CLJS.E.g($CLJS.gK,$CLJS.Fj.h(a))},filter_args_display_name:function(a,b,c){return $CLJS.qva.l($CLJS.H([a,b,c]))},join_clause:function(a,b){return $CLJS.Jva.l($CLJS.H([a,b]))},aggregation_clause:R9,external_op:function(a){a=$CLJS.cva.l($CLJS.H([a]));var b=$CLJS.O(a);a=$CLJS.J.g(b,$CLJS.QD);var c=$CLJS.J.g(b,$CLJS.gj);b=$CLJS.J.g(b,$CLJS.ps);return{operator:a,
options:$CLJS.kl(c),args:$CLJS.xe(b)}},with_join_fields:function(a,b){return $CLJS.Vva.l($CLJS.H([a,"string"===typeof b?$CLJS.Ah.h(b):b]))},find_visible_column_for_legacy_ref:function(a,b,c){return $CLJS.wva.l($CLJS.H([a,b,c]))},visible_columns:function(a,b){var c=$CLJS.bW(a,b),d=$CLJS.n0.j(a,b,c);c=$CLJS.l0.j(a,b,c);return $CLJS.xe($CLJS.e3(a,b,d,c))},available_binning_strategies:O9,find_column_indexes_from_legacy_refs:function(a,b,c,d){var e=$CLJS.uF.h($CLJS.bW(a,b)),f=$CLJS.fg.j($CLJS.N,$CLJS.hm(function(u,
v){$CLJS.I(v,0,null);v=$CLJS.I(v,1,null);v=$CLJS.O(v);v=$CLJS.J.g(v,$CLJS.UD);return new $CLJS.P(null,2,5,$CLJS.Q,[u,v],null)}),e);e=$CLJS.fg.j($CLJS.N,$CLJS.hm(function(u,v){$CLJS.I(v,0,null);v=$CLJS.I(v,1,null);v=$CLJS.O(v);v=$CLJS.J.g(v,$CLJS.UD);return new $CLJS.P(null,2,5,$CLJS.Q,[v,u],null)}),e);var k=$CLJS.uY,l=$CLJS.tY;$CLJS.uY=f;$CLJS.tY=e;try{var m=$CLJS.Sk.g(xxa,c),t=$CLJS.jf.g(H9,d);return $CLJS.xe(rxa(a,b,t,m))}finally{$CLJS.tY=l,$CLJS.uY=k}},expression:function(a,b,c,d){return $CLJS.kva.l($CLJS.H([a,
b,c,d]))},describe_relative_datetime:function(a,b){a="string"===typeof a?$CLJS.Ah.h(a):a;b="string"===typeof b?$CLJS.Ah.h(b):b;return $CLJS.Cwa.l($CLJS.H([a,b]))},order_bys:function(a,b){return $CLJS.xe($CLJS.pwa.l($CLJS.H([a,b])))},display_info:function(a,b,c){return $CLJS.A_($CLJS.Ah.g("display-info-outer",["stage-",$CLJS.p.h(b)].join("")),c,function(d){return F9($CLJS.Yva.l($CLJS.H([$CLJS.x3(a,b),b,d])))})},fieldable_columns:function(a,b){return $CLJS.xe($CLJS.uva.l($CLJS.H([a,b])))},template_tags:function(a){return $CLJS.kl($CLJS.dwa.l($CLJS.H([a])))},
selected_aggregation_operators:function(a,b){return $CLJS.xe($CLJS.Vua.l($CLJS.H([$CLJS.y(a),b])))},order_by_clause:N9,filterable_columns:function(a,b){return $CLJS.xe($CLJS.Bva.l($CLJS.H([a,b])))},join_condition_rhs_columns:function(a,b,c,d,e){return $CLJS.xe($CLJS.Mva.l($CLJS.H([a,b,c,d,e])))},join_condition_update_temporal_bucketing:function(a,b,c,d){return $CLJS.Nva.l($CLJS.H([a,b,c,d]))},filters:function(a,b){return $CLJS.xe($CLJS.Ava.l($CLJS.H([a,b])))},join_condition_lhs_columns:function(a,
b,c,d,e){return $CLJS.xe($CLJS.Kva.l($CLJS.H([a,b,c,d,e])))},table_or_card_metadata:function(a,b){return $CLJS.h1(a,b)},with_expression_name:function(a,b){return $CLJS.nva.l($CLJS.H([a,b]))},orderable_columns:function(a,b){return $CLJS.xe($CLJS.I7.g(a,b))},find_filter_for_legacy_filter:function(a,b,c){return $CLJS.Fva.l($CLJS.H([a,b,$CLJS.mz(c,$CLJS.H([$CLJS.Ai,!0]))]))},suggestedName:function(a){return $CLJS.Zva.l($CLJS.H([a]))},find_column_for_legacy_ref:function(a,b,c,d){return $CLJS.iva.l($CLJS.H([a,
b,c,d]))},has_write_permission:function(a){return $CLJS.kwa.l($CLJS.H([a]))},metadataProvider:B9,filter_clause:S9,fields:function(a,b){return $CLJS.xe($CLJS.vva.l($CLJS.H([a,b])))},available_aggregation_operators:function(a,b){return $CLJS.xe($CLJS.Uua.l($CLJS.H([a,b])))},breakouts:function(a,b){return $CLJS.xe($CLJS.$ua.l($CLJS.H([a,b])))},joinable_columns:function(a,b,c){return $CLJS.xe($CLJS.Sva.l($CLJS.H([a,b,c])))},group_columns:function(a){return $CLJS.xe($CLJS.bva.l($CLJS.H([a])))},describe_temporal_unit:function(a,
b){b="string"===typeof b?$CLJS.Ah.h(b):b;return $CLJS.Awa.l($CLJS.H([a,b]))},joins:function(a,b){return $CLJS.xe($CLJS.Tva.l($CLJS.H([a,b])))},native_query:function(a,b,c){return $CLJS.awa.l($CLJS.H([B9(a,b),c]))},available_join_strategies:function(a,b){return $CLJS.xe($CLJS.Hva.l($CLJS.H([a,b])))},legacy_query:function(a){return $CLJS.kl.l(M9($CLJS.kY.h(a)),$CLJS.H([$CLJS.wk,$CLJS.zA]))},stage_count:function(a){return $CLJS.qwa.l($CLJS.H([a]))},expression_clause:function(a,b,c){return $CLJS.ova.l($CLJS.H([$CLJS.Ah.h(a),
b,$CLJS.mz(c,$CLJS.H([$CLJS.Ai,!0]))]))},find_matching_column:function(a,b,c,d){return $CLJS.jva.l($CLJS.H([a,b,c,d]))},with_native_extras:function(a,b){return $CLJS.iwa.l($CLJS.H([a,$CLJS.mz(b,$CLJS.H([$CLJS.Ai,!0]))]))},join_strategy:function(a){return $CLJS.Rva.l($CLJS.H([a]))},picker_info:function(a,b){var c=$CLJS.Fj.h(b);switch(c instanceof $CLJS.M?c.S:null){case "metadata/table":return{databaseId:$CLJS.qP.h(a),tableId:$CLJS.$i.h(b)};case "metadata/card":return{databaseId:$CLJS.qP.h(a),tableId:["card__",
$CLJS.p.h($CLJS.$i.h(b))].join(""),cardId:$CLJS.$i.h(b),isModel:$CLJS.aZ.h(b)};default:return a=$CLJS.lA($CLJS.ey),$CLJS.n($CLJS.kA("metabase.lib.js",a))&&$CLJS.jA("metabase.lib.js",a,$CLJS.aw.l($CLJS.H(["Cannot provide picker-info for",$CLJS.Fj.h(b)])),null),null}},expression_parts:function(a,b,c){a=$CLJS.pva.l($CLJS.H([a,b,c]));return $CLJS.RU(function(d){if($CLJS.xd(d)&&$CLJS.E.g($CLJS.u8,$CLJS.Fj.h(d))){var e=$CLJS.O(d);d=$CLJS.J.g(e,$CLJS.QD);var f=$CLJS.J.g(e,$CLJS.gj);e=$CLJS.J.g(e,$CLJS.ps);
return{operator:$CLJS.hh(d),options:$CLJS.kl($CLJS.fm(f,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.NF,$CLJS.nl],null))),args:$CLJS.xe($CLJS.jf.g(function(k){return k instanceof $CLJS.M?$CLJS.zA(k):k},e))}}return d},a)},expression_clause_for_legacy_expression:function(a,b,c){b=$CLJS.U8.l($CLJS.H([a,b]));a=$CLJS.fg.j($CLJS.N,$CLJS.hm(function(l,m){$CLJS.I(m,0,null);m=$CLJS.I(m,1,null);m=$CLJS.O(m);m=$CLJS.J.g(m,$CLJS.UD);return new $CLJS.P(null,2,5,$CLJS.Q,[l,m],null)}),b);b=$CLJS.fg.j($CLJS.N,$CLJS.hm(function(l,
m){$CLJS.I(m,0,null);m=$CLJS.I(m,1,null);m=$CLJS.O(m);m=$CLJS.J.g(m,$CLJS.UD);return new $CLJS.P(null,2,5,$CLJS.Q,[m,l],null)}),b);var d=$CLJS.uY,e=$CLJS.tY;$CLJS.uY=a;$CLJS.tY=b;try{var f=$CLJS.mz(c,$CLJS.H([$CLJS.Ai,!0])),k=$CLJS.z($CLJS.aY(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.aF,$CLJS.uF],null),new $CLJS.P(null,1,5,$CLJS.Q,[f],null)));return $CLJS.vY.h(k)}finally{$CLJS.tY=e,$CLJS.uY=d}},format_relative_date_range:function(a,b,c,d,e){b=$CLJS.Ah.h(b);d=null==d?null:$CLJS.Ah.h(d);e=$CLJS.mz(e,$CLJS.H([$CLJS.Ai,
!0]));return $CLJS.i2((0,$CLJS.gI)(),a,b,c,d,e)},aggregation_operator_columns:function(a){return $CLJS.xe($CLJS.Tua.l($CLJS.H([a])))},find_filterable_column_for_legacy_ref:function(a,b,c){return $CLJS.Gva.l($CLJS.H([a,b,c]))},available_segments:function(a,b){return $CLJS.xe($CLJS.wwa.l($CLJS.H([a,b])))},join_condition_operators:function(a,b,c,d){return $CLJS.xe($CLJS.Lva.l($CLJS.H([a,b,c,d])))},expressionable_columns:T9,filter:function(a,b,c){return $CLJS.zva.l($CLJS.H([a,b,$CLJS.mz(c,$CLJS.H([$CLJS.Ai,
!0]))]))},change_direction:function(a,b){return $CLJS.mwa.l($CLJS.H([a,b]))},pivot_columns_for_type:function(a,b){return $CLJS.xe($CLJS.gva.l($CLJS.H([a,$CLJS.Ah.h(b)])))},filter_drill_details:function(a){var b=$CLJS.O(a);a=$CLJS.J.g(b,$CLJS.aF);var c=$CLJS.J.g(b,$CLJS.Ui),d=$CLJS.J.g(b,$CLJS.aL);b=$CLJS.J.g(b,$CLJS.Ej);return{column:c,query:a,stageIndex:d,value:$CLJS.E.g(b,$CLJS.iL)?null:b}},remove_field:function(a,b,c){return $CLJS.xva.l($CLJS.H([a,b,c]))},with_template_tags:function(a,b){return $CLJS.fwa.l($CLJS.H([a,
A9(b)]))},expressions:function(a,b){return $CLJS.xe($CLJS.lva.l($CLJS.H([a,b])))},suggested_join_conditions:function(a,b,c){return $CLJS.xe($CLJS.Uva.l($CLJS.H([a,b,c])))},with_join_strategy:function(a,b){return $CLJS.Wva.l($CLJS.H([a,b]))},order_by:function(a,b,c,d){return $CLJS.nwa.l($CLJS.H([a,b,c,$CLJS.Ah.h(d)]))},field_values_search_info:function(a,b){var c=$CLJS.Tk.j,d=$CLJS.$i.h(b),e=$CLJS.$i.h;var f=$CLJS.d_(b)?b:null;$CLJS.n(f)?a=f:(f=$CLJS.bA(b,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.vK,$CLJS.rK],
null)),a=$CLJS.n(f)?$CLJS.$0(a,f):null,a=$CLJS.n(a)?a:b);a=x9(a)?a:null;e=e.call($CLJS.$i,a);$CLJS.n(b)?(b=$CLJS.O(b),a=$CLJS.J.g(b,$CLJS.rZ),b=$CLJS.E.g(a,$CLJS.tZ)?$CLJS.Dj:$CLJS.n(a)?a:x9(b)?$CLJS.YY:$CLJS.Fz):b=$CLJS.Fz;return $CLJS.kl($CLJS.dA(c.call($CLJS.Tk,new $CLJS.h(null,3,[$CLJS.rK,d,$CLJS.e7,e,$CLJS.rZ,b],null),$CLJS.rZ,$CLJS.hh),C9))},describe_temporal_interval:function(a,b){a="string"===typeof a?$CLJS.Ah.h(a):a;b="string"===typeof b?$CLJS.Ah.h(b):b;return $CLJS.Bwa.l($CLJS.H([a,b]))},
breakout:function(a,b,c){return $CLJS.Yua.l($CLJS.H([a,b,$CLJS.Y8.l($CLJS.H([c]))]))},database_id:function(a){return $CLJS.dva.l($CLJS.H([a]))},required_native_extras:function(a,b){return $CLJS.xe($CLJS.jf.g($CLJS.zA,$CLJS.gwa.l($CLJS.H([B9(a,b)]))))},remove_join:function(a,b,c){return $CLJS.twa.l($CLJS.H([a,b,c]))},joined_thing:function(a,b){return $CLJS.r3(a,b)},with_native_query:function(a,b){return $CLJS.cwa.l($CLJS.H([a,b]))},breakoutable_columns:function(a,b){return $CLJS.xe($CLJS.Zua.l($CLJS.H([a,
b])))},rename_join:function(a,b,c,d){return $CLJS.uwa.l($CLJS.H([a,b,c,d]))},with_different_table:function(a,b){return $CLJS.rwa.l($CLJS.H([a,b]))}};