var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.data.js");require("./clojure.set.js");require("./clojure.string.js");require("./malli.core.js");require("./malli.error.js");require("./medley.core.js");require("./metabase.lib.dispatch.js");require("./metabase.lib.hierarchy.js");require("./metabase.lib.options.js");require("./metabase.lib.schema.js");require("./metabase.lib.schema.expression.js");require("./metabase.lib.schema.ref.js");require("./metabase.lib.util.js");require("./metabase.mbql.normalize.js");require("./metabase.util.js");require("./metabase.util.log.js");require("./metabase.util.malli.js");
'use strict';var bY,Tma,dY,Uma,Vma,Wma,fY,Yma,Zma,$ma,ana,hY,iY,jY,lY,bna,mY,nY,cna,dna,ena,oY,Xma,pY,qY,fna,sY;bY=function(a,b){var c=$CLJS.y(b);for(a=$CLJS.y($CLJS.cf(a,b));;)if(a)c=$CLJS.B(c),a=$CLJS.B(a);else return c};
Tma=function(){return function(){function a(k,l,m){return new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.nj.j?$CLJS.nj.j(k,l,m):$CLJS.nj.call(null,k,l,m),$CLJS.ol.j?$CLJS.ol.j(k,l,m):$CLJS.ol.call(null,k,l,m)],null)}function b(k,l){return new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.nj.g?$CLJS.nj.g(k,l):$CLJS.nj.call(null,k,l),$CLJS.ol.g?$CLJS.ol.g(k,l):$CLJS.ol.call(null,k,l)],null)}function c(k){return new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.nj.h?$CLJS.nj.h(k):$CLJS.nj.call(null,k),$CLJS.ol.h?$CLJS.ol.h(k):$CLJS.ol.call(null,
k)],null)}function d(){return new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.nj.o?$CLJS.nj.o():$CLJS.nj.call(null),$CLJS.ol.o?$CLJS.ol.o():$CLJS.ol.call(null)],null)}var e=null,f=function(){function k(m,t,u,v){var x=null;if(3<arguments.length){x=0;for(var A=Array(arguments.length-3);x<A.length;)A[x]=arguments[x+3],++x;x=new $CLJS.w(A,0,null)}return l.call(this,m,t,u,x)}function l(m,t,u,v){return new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Te.N($CLJS.nj,m,t,u,v),$CLJS.Te.N($CLJS.ol,m,t,u,v)],null)}k.A=3;k.B=function(m){var t=
$CLJS.z(m);m=$CLJS.B(m);var u=$CLJS.z(m);m=$CLJS.B(m);var v=$CLJS.z(m);m=$CLJS.Lc(m);return l(t,u,v,m)};k.l=l;return k}();e=function(k,l,m,t){switch(arguments.length){case 0:return d.call(this);case 1:return c.call(this,k);case 2:return b.call(this,k,l);case 3:return a.call(this,k,l,m);default:var u=null;if(3<arguments.length){u=0;for(var v=Array(arguments.length-3);u<v.length;)v[u]=arguments[u+3],++u;u=new $CLJS.w(v,0,null)}return f.l(k,l,m,u)}throw Error("Invalid arity: "+arguments.length);};e.A=
3;e.B=f.B;e.o=d;e.h=c;e.g=b;e.j=a;e.l=f.l;return e}()};
$CLJS.cY=function(a){return function(b){var c=$CLJS.af(-1);return function(){function d(l,m){var t=c.Cd(null,c.Qb(null)+1);m=a.g?a.g(t,m):a.call(null,t,m);return null==m?l:b.g?b.g(l,m):b.call(null,l,m)}function e(l){return b.h?b.h(l):b.call(null,l)}function f(){return b.o?b.o():b.call(null)}var k=null;k=function(l,m){switch(arguments.length){case 0:return f.call(this);case 1:return e.call(this,l);case 2:return d.call(this,l,m)}throw Error("Invalid arity: "+arguments.length);};k.o=f;k.h=e;k.g=d;return k}()}};
dY=function(a){return $CLJS.mL($CLJS.IV)(a,$CLJS.Df,$CLJS.Df)};Uma=function(a,b,c){return $CLJS.eb(function(d,e){var f=$CLJS.I(e,0,null);e=$CLJS.I(e,1,null);return null==e?d:$CLJS.R.j(d,f,e)},null==b?a:$CLJS.R.j(a,$CLJS.uF,b),$CLJS.As(2,2,c))};Vma=function(a,b){var c=$CLJS.gm(a,$CLJS.bQ);if($CLJS.n(c)){var d=$CLJS.R.j;c=$CLJS.Fb(c);b=$CLJS.Ls.g?$CLJS.Ls.g(c,b):$CLJS.Ls.call(null,c,b);a=d.call($CLJS.R,a,$CLJS.bQ,b)}return a};Wma=function(a){return $CLJS.hf.g($CLJS.iV(a),$CLJS.jV(a))};
$CLJS.eY=function(a,b){return $CLJS.UV(a)&&$CLJS.E.g($CLJS.z(a),b)};fY=function(a,b,c){var d=new $CLJS.bh(null,new $CLJS.h(null,2,[$CLJS.is,null,$CLJS.tl,null],null),null),e=$CLJS.n(d.h?d.h(b):d.call(null,b))?$CLJS.Wt(2,c):$CLJS.Wt(1,c);b=$CLJS.n(d.h?d.h(b):d.call(null,b))?bY(2,c):bY(1,c);var f=$CLJS.I(b,0,null);return $CLJS.y(e)?$CLJS.UW.j(a,e,function(k){var l=$CLJS.R.j(k,f,null);return $CLJS.zd(k)?$CLJS.fg.j($CLJS.Df,$CLJS.Rk.h($CLJS.Bl),l):$CLJS.xd(k)?$CLJS.iA($CLJS.Xa,l):l}):$CLJS.Ik.g(a,f)};
Yma=function(a){return $CLJS.eb(function(b,c){var d=$CLJS.I(c,0,null);$CLJS.I(c,1,null);return fY(b,Xma,d)},a,Wma(a))};
Zma=function(a){for(var b=0;;){var c=$CLJS.F($CLJS.PK.h(a),b);a:{var d=c;for(var e=$CLJS.Df;;){var f=$CLJS.z($CLJS.jf.g(Tma(),$CLJS.mf($CLJS.Qk.j(gY,$CLJS.z,$CLJS.ol),$CLJS.ms.h(dY(d)))));if($CLJS.n(f)){var k=f;f=$CLJS.I(k,0,null);k=$CLJS.I(k,1,null);var l=fY(d,f,k),m=$CLJS.lA($CLJS.ey);if($CLJS.n($CLJS.kA("metabase.lib.convert",m))){var t=$CLJS.aB.l($CLJS.H([$CLJS.$y,$CLJS.Nh.l($CLJS.H([k]))])),u=$CLJS.aB,v=u.l,x=$CLJS.Nh,A=x.l;var C=f;C=$CLJS.n(C)?C:$CLJS.rL(dY(d));$CLJS.jA("metabase.lib.convert",
m,$CLJS.RV("Clean: Removing bad clause in %s due to error %s:\n%s",$CLJS.H([t,v.call(u,$CLJS.H([$CLJS.$y,A.call(x,$CLJS.H([C]))])),$CLJS.aB.l($CLJS.H([$CLJS.Py,$CLJS.SV($CLJS.z($CLJS.Dt(d,l)))]))])),null)}if($CLJS.E.g(l,d))break a;d=l;e=$CLJS.ce.g(e,new $CLJS.P(null,2,5,$CLJS.Q,[f,k],null))}else break a}}d=Yma(d);if($CLJS.E.g(c,d)){if($CLJS.E.g(b,$CLJS.D($CLJS.PK.h(a))-1))return a;b+=1}else a=$CLJS.Tk.N(a,$CLJS.PK,$CLJS.R,b,d)}};
$ma=function(a){var b=$CLJS.eW();return $CLJS.Sk.g(function(c){return $CLJS.E.g($CLJS.qE.h(c),"__join")?$CLJS.Tk.j(c,$CLJS.qE,b):c},a)};ana=function(a){return"string"===typeof $CLJS.BO.h(a)?$CLJS.Ik.g($CLJS.R.j(a,$CLJS.kV,$CLJS.cW($CLJS.BO.h(a))),$CLJS.BO):a};hY=function(){return $CLJS.Rk.h(function(a){var b=$CLJS.I(a,0,null);$CLJS.I(a,1,null);(a=$CLJS.ne(b))?(b=$CLJS.je(b),b=$CLJS.n(b)?$CLJS.E.g(b,"lib")||0==b.lastIndexOf("metabase.lib.",0):null):b=a;return b})};
iY=function(a){return $CLJS.fg.j($CLJS.N,hY(),a)};jY=function(a){return $CLJS.Ge($CLJS.fg.j($CLJS.N,$CLJS.Qk.g(hY(),$CLJS.Rk.h(function(b){var c=$CLJS.I(b,0,null);$CLJS.I(b,1,null);return $CLJS.E.g(c,$CLJS.Gi)})),a))};
lY=function(a){var b=$CLJS.y(a);a=$CLJS.z(b);var c=$CLJS.B(b);b=$CLJS.z(c);c=$CLJS.B(c);var d=$CLJS.fg.j(new $CLJS.P(null,1,5,$CLJS.Q,[a],null),$CLJS.jf.h($CLJS.kY),c);a=$CLJS.E.g(a,$CLJS.nF)&&$CLJS.B(c)?$CLJS.ce.g($CLJS.sd(d),new $CLJS.h(null,1,[$CLJS.di,$CLJS.qd(d)],null)):d;b=$CLJS.Ge(jY(b));return $CLJS.n(b)?new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.RL,a,b],null):a};bna=function(a){return $CLJS.fg.j($CLJS.Df,$CLJS.Qk.g($CLJS.jf.h(function(b){return $CLJS.dA(b,$CLJS.fB)}),$CLJS.jf.h($CLJS.kY)),$CLJS.ZV.h(a))};
mY=function(a){a=$CLJS.O(a);a=$CLJS.J.g(a,$CLJS.PK);a=$CLJS.z($CLJS.eb(function(b,c){var d=$CLJS.I(b,0,null),e=$CLJS.I(b,1,null);b=$CLJS.Q;var f=$CLJS.kY.h(c);d=$CLJS.n(d)?$CLJS.R.j(f,$CLJS.dQ,d):f;d=$CLJS.n(e)?$CLJS.R.j(d,$CLJS.nO,bna(e)):d;return new $CLJS.P(null,2,5,b,[d,$CLJS.KU.h(c)],null)},null,a));return $CLJS.n($CLJS.FP.h(a))?$CLJS.WU(a,new $CLJS.h(null,1,[$CLJS.FP,$CLJS.aF],null)):a};
nY=function(a,b,c){var d=$CLJS.E.g($CLJS.D($CLJS.J.g(a,b)),1)?$CLJS.rW(a,b,$CLJS.Qk.g($CLJS.kY,$CLJS.z)):a;a=1<$CLJS.D($CLJS.J.g(a,b))?$CLJS.rW(d,b,function(e){return $CLJS.fg.j(new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.Tr],null),$CLJS.jf.h($CLJS.kY),e)}):d;return $CLJS.WU(a,$CLJS.Fe([b,c]))};cna=function(a){var b=$CLJS.kV.h(a);return $CLJS.n(b)?$CLJS.R.j($CLJS.Ik.g(a,$CLJS.kV),$CLJS.BO,["card__",$CLJS.p.h(b)].join("")):a};
dna=new $CLJS.M(null,"legacy-index-\x3epMBQL-uuid","legacy-index-\x3epMBQL-uuid",-192973336);ena=new $CLJS.M(null,"legacy-ref","legacy-ref",1686404700);oY=new $CLJS.M("metabase.lib.convert","not-found","metabase.lib.convert/not-found",967996550);Xma=new $CLJS.M("metabase.lib.schema","invalid-ref","metabase.lib.schema/invalid-ref",-2010445475);pY=new $CLJS.M("metabase.lib.convert","aggregation-or-expression","metabase.lib.convert/aggregation-or-expression",1924029672);
qY=new $CLJS.M("metabase.lib.convert","expression","metabase.lib.convert/expression",1648102782);$CLJS.rY=new $CLJS.M("lib.convert","converted?","lib.convert/converted?",1937433683);fna=new $CLJS.M(null,"m","m",1632677161);sY=new $CLJS.M("metabase.lib.convert","aggregation","metabase.lib.convert/aggregation",108290790);var gY,gna,hna,ina,jna,kna,lna,mna,nna,ona,pna;$CLJS.tY=$CLJS.N;$CLJS.uY=$CLJS.N;gY=new $CLJS.bh(null,new $CLJS.h(null,7,[$CLJS.FV,null,$CLJS.mE,null,$CLJS.$M,null,$CLJS.vP,null,$CLJS.tF,null,$CLJS.kR,null,$CLJS.uF,null],null),null);gna=$CLJS.Ye($CLJS.N);hna=$CLJS.Ye($CLJS.N);ina=$CLJS.Ye($CLJS.N);jna=$CLJS.Ye($CLJS.N);kna=$CLJS.J.j(new $CLJS.h(null,1,[$CLJS.ij,$CLJS.zB],null),$CLJS.ij,$CLJS.Wh.o?$CLJS.Wh.o():$CLJS.Wh.call(null));
$CLJS.vY=new $CLJS.ei($CLJS.Gh.g("metabase.lib.convert","-\x3epMBQL"),$CLJS.xB,kna,gna,hna,ina,jna);$CLJS.vY.m(null,$CLJS.di,function(a){if($CLJS.zd(a)&&$CLJS.z(a)instanceof $CLJS.M){var b=$CLJS.qd(a);var c=$CLJS.xd(b);a=c?$CLJS.sd(a):a;var d=$CLJS.y(a);a=$CLJS.z(d);d=$CLJS.B(d);b=$CLJS.MV($CLJS.fg.j(new $CLJS.P(null,2,5,$CLJS.Q,[a,c?b:$CLJS.N],null),$CLJS.jf.h($CLJS.vY),d))}else b=a;return b});$CLJS.vY.m(null,$CLJS.EV,function(a){return a});
$CLJS.vY.m(null,$CLJS.rV,function(a){var b=$CLJS.vY.h($CLJS.uF.h(a)),c=$CLJS.Ge($CLJS.Sk.g(function(t){var u=$CLJS.I(t,0,null);t=$CLJS.I(t,1,null);return $CLJS.WV($CLJS.vY.h(t),u)},$CLJS.vP.h(a))),d=$CLJS.fg.j($CLJS.N,$CLJS.hm(function(t,u){$CLJS.I(u,0,null);u=$CLJS.I(u,1,null);u=$CLJS.O(u);u=$CLJS.J.g(u,$CLJS.UD);return new $CLJS.P(null,2,5,$CLJS.Q,[t,u],null)}),b),e=$CLJS.fg.j($CLJS.N,$CLJS.hm(function(t,u){$CLJS.I(u,0,null);u=$CLJS.I(u,1,null);u=$CLJS.O(u);u=$CLJS.J.g(u,$CLJS.UD);return new $CLJS.P(null,
2,5,$CLJS.Q,[u,t],null)}),b),f=$CLJS.uY,k=$CLJS.tY;$CLJS.uY=d;$CLJS.tY=e;try{var l=Uma(ana(a),b,$CLJS.H([$CLJS.vP,c])),m=$CLJS.eb(function(t,u){return $CLJS.Ua($CLJS.J.g(t,u))?t:$CLJS.Tk.j(t,u,$CLJS.vY)},l,$CLJS.Jk.l(gY,$CLJS.uF,$CLJS.H([$CLJS.vP])));return $CLJS.n($CLJS.$M.h(m))?$CLJS.Tk.j(m,$CLJS.$M,$ma):m}finally{$CLJS.tY=k,$CLJS.uY=f}});$CLJS.vY.m(null,$CLJS.yV,function(a){return Vma(a,function(b){return $CLJS.rW(b,$CLJS.YE,$CLJS.vY)})});
$CLJS.vY.m(null,$CLJS.KK,function(a){a=$CLJS.Tk.j($CLJS.Tk.j(a,$CLJS.SK,$CLJS.vY),$CLJS.PK,$CLJS.vY);var b=$CLJS.n($CLJS.mE.h(a))?$CLJS.Tk.j(a,$CLJS.mE,function(c){return $CLJS.Nl(c)?$CLJS.Sk.g($CLJS.vY,c):$CLJS.Ah.h(c)}):a;return $CLJS.Ua($CLJS.qE.h(a))?$CLJS.R.j(b,$CLJS.qE,"__join"):b});$CLJS.vY.m(null,$CLJS.NA,function(a){return $CLJS.Sk.g($CLJS.vY,a)});
$CLJS.vY.m(null,$CLJS.PA,function(a){return $CLJS.n($CLJS.nj.h(a))?Zma($CLJS.R.j($CLJS.Tk.j($CLJS.$V(a),$CLJS.PK,function(b){return $CLJS.Sk.g($CLJS.vY,b)}),$CLJS.rY,!0)):$CLJS.Ls(a,$CLJS.vY)});$CLJS.vY.m(null,$CLJS.FF,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);a=$CLJS.xd(b)?new $CLJS.P(null,2,5,$CLJS.Q,[a,b],null):new $CLJS.P(null,2,5,$CLJS.Q,[b,a],null);b=$CLJS.I(a,0,null);a=$CLJS.I(a,1,null);return $CLJS.MV(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.FF,a,b],null))});
$CLJS.vY.m(null,$CLJS.Ej,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);a=$CLJS.WU(a,new $CLJS.h(null,3,[$CLJS.zC,$CLJS.TA,$CLJS.$N,$CLJS.Wj,$CLJS.sR,$CLJS.PD],null));var c=$CLJS.R.j;var d=$CLJS.Gi.h(a);$CLJS.n(d)||(d=$CLJS.TA.h(a),d=$CLJS.n(d)?d:$CLJS.hE(b));a=c.call($CLJS.R,a,$CLJS.Gi,d);return $CLJS.MV(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Ej,a,b],null))});
$CLJS.vY.m(null,$CLJS.nF,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null),c=$CLJS.I(a,2,null);a=$CLJS.di.h(c);b=new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.nF,$CLJS.Ik.g(c,$CLJS.di),$CLJS.Sk.g($CLJS.vY,b)],null);b=$CLJS.MV(b);return null!=a?$CLJS.ce.g(b,$CLJS.vY.h(a)):b});$CLJS.vY.m(null,$CLJS.QA,function(a){var b=$CLJS.I(a,0,null),c=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);return $CLJS.MV(new $CLJS.P(null,3,5,$CLJS.Q,[b,a,c],null))});
$CLJS.vY.m(null,$CLJS.uF,function(a){var b=$CLJS.I(a,0,null),c=$CLJS.I(a,1,null),d=$CLJS.I(a,2,null),e=$CLJS.Q;var f=$CLJS.J.g($CLJS.uY,c);if(!$CLJS.n(f))throw $CLJS.ii(["Error converting :aggregation reference: no aggregation at index ",$CLJS.p.h(c)].join(""),new $CLJS.h(null,1,[$CLJS.vR,a],null));return $CLJS.MV(new $CLJS.P(null,3,5,e,[b,d,f],null))});
$CLJS.vY.m(null,$CLJS.RL,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);b=$CLJS.vY.h(b);var c=$CLJS.y(b);b=$CLJS.z(c);var d=$CLJS.B(c);c=$CLJS.z(d);d=$CLJS.B(d);return $CLJS.fg.g(new $CLJS.P(null,2,5,$CLJS.Q,[b,$CLJS.hl.l($CLJS.H([c,a]))],null),d)});lna=$CLJS.Ye($CLJS.N);mna=$CLJS.Ye($CLJS.N);nna=$CLJS.Ye($CLJS.N);ona=$CLJS.Ye($CLJS.N);pna=$CLJS.J.j(new $CLJS.h(null,1,[$CLJS.ij,$CLJS.zB],null),$CLJS.ij,$CLJS.Wh.o?$CLJS.Wh.o():$CLJS.Wh.call(null));
$CLJS.kY=new $CLJS.ei($CLJS.Gh.g("metabase.lib.convert","-\x3elegacy-MBQL"),$CLJS.xB,pna,lna,mna,nna,ona);
$CLJS.kY.m(null,$CLJS.di,function(a){if($CLJS.zd(a)&&$CLJS.z(a)instanceof $CLJS.M){a=$CLJS.y(a);var b=$CLJS.z(a),c=$CLJS.B(a);a=$CLJS.z(c);var d=$CLJS.B(c);if($CLJS.xd(a)){c=$CLJS.fg.g;b=new $CLJS.P(null,1,5,$CLJS.Q,[b],null);var e=$CLJS.hf.g;d=$CLJS.jf.g($CLJS.kY,d);a=jY(a);a=c.call($CLJS.fg,b,e.call($CLJS.hf,d,$CLJS.n(a)?new $CLJS.P(null,1,5,$CLJS.Q,[a],null):null))}else a=$CLJS.fg.g(new $CLJS.P(null,1,5,$CLJS.Q,[b],null),$CLJS.jf.g($CLJS.kY,$CLJS.fe(a,d)))}else a=$CLJS.xd(a)?$CLJS.Ls(iY(a),$CLJS.kY):
a;return a});for(var wY=$CLJS.y(new $CLJS.P(null,2,5,$CLJS.Q,[sY,qY],null)),xY=null,yY=0,zY=0;;)if(zY<yY){var qna=xY.X(null,zY);$CLJS.LE(qna,pY);zY+=1}else{var AY=$CLJS.y(wY);if(AY){var BY=AY;if($CLJS.Ad(BY)){var CY=$CLJS.lc(BY),rna=$CLJS.mc(BY),sna=CY,tna=$CLJS.D(CY);wY=rna;xY=sna;yY=tna}else{var una=$CLJS.z(BY);$CLJS.LE(una,pY);wY=$CLJS.B(BY);xY=null;yY=0}zY=0}else break}
for(var DY=$CLJS.y(new $CLJS.P(null,12,5,$CLJS.Q,[$CLJS.rx,$CLJS.zF,$CLJS.OF,$CLJS.jF,$CLJS.jk,$CLJS.TF,$CLJS.Rn,$CLJS.qF,$CLJS.MF,$CLJS.VF,$CLJS.SF,$CLJS.$F],null)),EY=null,FY=0,GY=0;;)if(GY<FY){var vna=EY.X(null,GY);$CLJS.LE(vna,sY);GY+=1}else{var HY=$CLJS.y(DY);if(HY){var IY=HY;if($CLJS.Ad(IY)){var JY=$CLJS.lc(IY),wna=$CLJS.mc(IY),xna=JY,yna=$CLJS.D(JY);DY=wna;EY=xna;FY=yna}else{var zna=$CLJS.z(IY);$CLJS.LE(zna,sY);DY=$CLJS.B(IY);EY=null;FY=0}GY=0}else break}
for(var KY=$CLJS.y($CLJS.zf([$CLJS.qr,$CLJS.Ds,$CLJS.rr,$CLJS.aG,$CLJS.nF,$CLJS.IF,$CLJS.eF,$CLJS.cG,$CLJS.WE,$CLJS.sF,$CLJS.$E,$CLJS.DF,$CLJS.KF,$CLJS.bF,$CLJS.tI,$CLJS.vI,$CLJS.fy,$CLJS.pI,$CLJS.xI,$CLJS.jI,$CLJS.BI,$CLJS.oI,$CLJS.hI,$CLJS.AI,$CLJS.II,$CLJS.EI,$CLJS.nI,$CLJS.LI,$CLJS.DI,$CLJS.JI,$CLJS.dF,$CLJS.XE,$CLJS.dG,$CLJS.tH,$CLJS.HF,$CLJS.px,$CLJS.WF,$CLJS.YF,$CLJS.eG,$CLJS.Os,$CLJS.Us],!0)),LY=null,MY=0,NY=0;;)if(NY<MY){var Ana=LY.X(null,NY);$CLJS.LE(Ana,qY);NY+=1}else{var OY=$CLJS.y(KY);
if(OY){var PY=OY;if($CLJS.Ad(PY)){var QY=$CLJS.lc(PY),Bna=$CLJS.mc(PY),Cna=QY,Dna=$CLJS.D(QY);KY=Bna;LY=Cna;MY=Dna}else{var Ena=$CLJS.z(PY);$CLJS.LE(Ena,qY);KY=$CLJS.B(PY);LY=null;MY=0}NY=0}else break}$CLJS.kY.m(null,pY,function(a){return lY(a)});$CLJS.kY.m(null,$CLJS.PA,function(a){return $CLJS.fg.j($CLJS.N,$CLJS.Qk.g(hY(),$CLJS.jf.h(function(b){var c=$CLJS.I(b,0,null);b=$CLJS.I(b,1,null);return new $CLJS.P(null,2,5,$CLJS.Q,[c,$CLJS.kY.h(b)],null)})),a)});
$CLJS.kY.m(null,$CLJS.uF,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null),c=$CLJS.I(a,2,null);if($CLJS.xd(b))try{var d=jY(b);b=$CLJS.Q;var e=$CLJS.tY,f=$CLJS.J.j(e,c,oY);if($CLJS.E.g(f,oY))throw $CLJS.ii(["Unable to find key ",$CLJS.Nh.l($CLJS.H([c]))," in map."].join(""),new $CLJS.h(null,2,[fna,e,$CLJS.vw,c],null));var k=new $CLJS.P(null,2,5,b,[$CLJS.uF,f],null);return $CLJS.n(d)?$CLJS.ce.g(k,d):k}catch(l){throw c=l,d=$CLJS.PU(c),d=$CLJS.fa.g?$CLJS.fa.g("Error converting aggregation reference to pMBQL: %s",
d):$CLJS.fa.call(null,"Error converting aggregation reference to pMBQL: %s",d),$CLJS.hi(d,new $CLJS.h(null,1,[$CLJS.V,a],null),c);}else return a});$CLJS.kY.m(null,$CLJS.NA,function(a){return $CLJS.Sk.g($CLJS.kY,a)});
$CLJS.kY.m(null,$CLJS.FF,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);a=null==b||$CLJS.xd(b)?new $CLJS.P(null,2,5,$CLJS.Q,[b,a],null):new $CLJS.P(null,2,5,$CLJS.Q,[a,b],null);b=$CLJS.I(a,0,null);a=$CLJS.I(a,1,null);return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.FF,$CLJS.kY.h(a),jY(b)],null)});
$CLJS.kY.m(null,$CLJS.Ej,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);b=jY($CLJS.WU(b,new $CLJS.h(null,3,[$CLJS.TA,$CLJS.zC,$CLJS.Wj,$CLJS.$N,$CLJS.PD,$CLJS.sR],null)));return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Ej,a,b],null)});
$CLJS.kY.m(null,$CLJS.KK,function(a){var b=iY(a);a=0==$CLJS.qE.h(a).lastIndexOf("__join",0)?$CLJS.Ik.g(b,$CLJS.qE):b;return $CLJS.hl.l($CLJS.H([$CLJS.Ls($CLJS.Ik.l(a,$CLJS.PK,$CLJS.H([$CLJS.SK])),$CLJS.kY),nY($CLJS.fm(a,new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.SK],null)),$CLJS.SK,$CLJS.mR),mY(a)]))});
$CLJS.kY.m(null,$CLJS.rV,function(a){var b=$CLJS.uF.h(a),c=$CLJS.fg.j($CLJS.N,$CLJS.hm(function(f,k){$CLJS.I(k,0,null);k=$CLJS.I(k,1,null);k=$CLJS.O(k);k=$CLJS.J.g(k,$CLJS.UD);return new $CLJS.P(null,2,5,$CLJS.Q,[f,k],null)}),b);b=$CLJS.fg.j($CLJS.N,$CLJS.hm(function(f,k){$CLJS.I(k,0,null);k=$CLJS.I(k,1,null);k=$CLJS.O(k);k=$CLJS.J.g(k,$CLJS.UD);return new $CLJS.P(null,2,5,$CLJS.Q,[k,f],null)}),b);var d=$CLJS.uY,e=$CLJS.tY;$CLJS.uY=c;$CLJS.tY=b;try{return $CLJS.eb(function(f,k){return $CLJS.rW(f,
k,$CLJS.kY)},nY($CLJS.rW($CLJS.rW(cna(iY(a)),$CLJS.uF,function(f){return $CLJS.Sk.g(lY,f)}),$CLJS.vP,function(f){return $CLJS.fg.g($CLJS.N,function(){return function m(l){return new $CLJS.oe(null,function(){for(;;){var t=$CLJS.y(l);if(t){if($CLJS.Ad(t)){var u=$CLJS.lc(t),v=$CLJS.D(u),x=$CLJS.re(v);a:for(var A=0;;)if(A<v){var C=$CLJS.kd(u,A),G=$CLJS.kY.h(C);C=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.VV(C),$CLJS.E.g($CLJS.Ej,$CLJS.z(G))?$CLJS.hd(G):G],null);x.add(C);A+=1}else{u=!0;break a}return u?$CLJS.ue($CLJS.we(x),
m($CLJS.mc(t))):$CLJS.ue($CLJS.we(x),null)}x=$CLJS.z(t);u=$CLJS.kY.h(x);return $CLJS.fe(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.VV(x),$CLJS.E.g($CLJS.Ej,$CLJS.z(u))?$CLJS.hd(u):u],null),m($CLJS.Lc(t)))}return null}},null,null)}(f)}())}),$CLJS.FV,$CLJS.iF),$CLJS.Jk.l(gY,$CLJS.uF,$CLJS.H([$CLJS.FV,$CLJS.vP])))}finally{$CLJS.tY=e,$CLJS.uY=d}});$CLJS.kY.m(null,$CLJS.yV,function(a){return $CLJS.Ls(iY(a),$CLJS.kY)});
$CLJS.kY.m(null,$CLJS.EV,function(a){try{var b=iY(a),c=$CLJS.RM.h(b),d=mY(b),e=$CLJS.E.g($CLJS.Fj.h($CLJS.id($CLJS.PK.h(a))),$CLJS.yV)?$CLJS.FP:$CLJS.aF;return $CLJS.hl.l($CLJS.H([$CLJS.Ls($CLJS.Ik.l(b,$CLJS.PK,$CLJS.H([$CLJS.RM,$CLJS.rY])),$CLJS.kY),function(){var k=$CLJS.Fe([$CLJS.nj,e,e,d]);return $CLJS.y(c)?$CLJS.R.j(k,$CLJS.RM,c):k}()]))}catch(k){var f=k;throw $CLJS.hi(function(){var l=$CLJS.PU(f);return $CLJS.fa.g?$CLJS.fa.g("Error converting MLv2 query to legacy query: %s",l):$CLJS.fa.call(null,
"Error converting MLv2 query to legacy query: %s",l)}(),new $CLJS.h(null,1,[$CLJS.aF,a],null),f);}});
$CLJS.RY=function(){function a(d,e,f){f=$CLJS.aY(null,$CLJS.mz(f,$CLJS.H([$CLJS.Ai,!0])));var k=$CLJS.bW(d,e);k=$CLJS.O(k);var l=$CLJS.J.g(k,$CLJS.uF);k=$CLJS.fg.j($CLJS.N,$CLJS.hm(function(v,x){$CLJS.I(x,0,null);x=$CLJS.I(x,1,null);x=$CLJS.O(x);x=$CLJS.J.g(x,$CLJS.UD);return new $CLJS.P(null,2,5,$CLJS.Q,[v,x],null)}),l);l=$CLJS.fg.j($CLJS.N,$CLJS.hm(function(v,x){$CLJS.I(x,0,null);x=$CLJS.I(x,1,null);x=$CLJS.O(x);x=$CLJS.J.g(x,$CLJS.UD);return new $CLJS.P(null,2,5,$CLJS.Q,[x,v],null)}),l);var m=
$CLJS.uY,t=$CLJS.tY;$CLJS.uY=k;$CLJS.tY=l;try{try{return $CLJS.vY.h(f)}catch(v){var u=v;throw $CLJS.hi(function(){var x=$CLJS.PU(u);return $CLJS.fa.g?$CLJS.fa.g("Error converting legacy ref to pMBQL: %s",x):$CLJS.fa.call(null,"Error converting legacy ref to pMBQL: %s",x)}(),new $CLJS.h(null,4,[$CLJS.aF,d,$CLJS.aL,e,ena,f,dna,$CLJS.uY],null),u);}}finally{$CLJS.tY=t,$CLJS.uY=m}}function b(d,e){return c.j?c.j(d,-1,e):c.call(null,d,-1,e)}var c=null;c=function(d,e,f){switch(arguments.length){case 2:return b.call(this,
d,e);case 3:return a.call(this,d,e,f)}throw Error("Invalid arity: "+arguments.length);};c.g=b;c.j=a;return c}();