var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./metabase.lib.metadata.js");require("./metabase.lib.metadata.calculation.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.filter.js");require("./metabase.lib.types.isa.js");require("./metabase.shared.util.i18n.js");require("./metabase.util.js");require("./metabase.util.malli.js");
'use strict';var T1=function(a,b){a=a instanceof $CLJS.M?a.S:null;switch(a){case "\x3d":switch(b=b instanceof $CLJS.M?b.S:null,b){case "equal-to":return $CLJS.eE("Equal to");case "default":return $CLJS.eE("Is");default:throw Error(["No matching clause: ",$CLJS.p.h(b)].join(""));}case "!\x3d":switch(b=b instanceof $CLJS.M?b.S:null,b){case "not-equal-to":return $CLJS.eE("Not equal to");case "excludes":return $CLJS.eE("Excludes");case "default":return $CLJS.eE("Is not");default:throw Error(["No matching clause: ",
$CLJS.p.h(b)].join(""));}case "\x3e":switch(b=b instanceof $CLJS.M?b.S:null,b){case "after":return $CLJS.eE("After");case "default":return $CLJS.eE("Greater than");default:throw Error(["No matching clause: ",$CLJS.p.h(b)].join(""));}case "\x3c":switch(b=b instanceof $CLJS.M?b.S:null,b){case "before":return $CLJS.eE("Before");case "default":return $CLJS.eE("Less than");default:throw Error(["No matching clause: ",$CLJS.p.h(b)].join(""));}case "\x3e\x3d":switch(b=b instanceof $CLJS.M?b.S:null,b){case "default":return $CLJS.eE("Greater than or equal to");
default:throw Error(["No matching clause: ",$CLJS.p.h(b)].join(""));}case "\x3c\x3d":switch(b=b instanceof $CLJS.M?b.S:null,b){case "default":return $CLJS.eE("Less than or equal to");default:throw Error(["No matching clause: ",$CLJS.p.h(b)].join(""));}case "between":switch(b=b instanceof $CLJS.M?b.S:null,b){case "default":return $CLJS.eE("Between");default:throw Error(["No matching clause: ",$CLJS.p.h(b)].join(""));}case "is-null":switch(b=b instanceof $CLJS.M?b.S:null,b){case "is-empty":return $CLJS.eE("Is empty");
case "default":return $CLJS.eE("Is null");default:throw Error(["No matching clause: ",$CLJS.p.h(b)].join(""));}case "not-null":switch(b=b instanceof $CLJS.M?b.S:null,b){case "not-empty":return $CLJS.eE("Not empty");case "default":return $CLJS.eE("Not null");default:throw Error(["No matching clause: ",$CLJS.p.h(b)].join(""));}case "is-empty":switch(b=b instanceof $CLJS.M?b.S:null,b){case "default":return $CLJS.eE("Is empty");default:throw Error(["No matching clause: ",$CLJS.p.h(b)].join(""));}case "not-empty":switch(b=
b instanceof $CLJS.M?b.S:null,b){case "default":return $CLJS.eE("Not empty");default:throw Error(["No matching clause: ",$CLJS.p.h(b)].join(""));}case "contains":switch(b=b instanceof $CLJS.M?b.S:null,b){case "default":return $CLJS.eE("Contains");default:throw Error(["No matching clause: ",$CLJS.p.h(b)].join(""));}case "does-not-contain":switch(b=b instanceof $CLJS.M?b.S:null,b){case "default":return $CLJS.eE("Does not contain");default:throw Error(["No matching clause: ",$CLJS.p.h(b)].join(""));
}case "starts-with":switch(b=b instanceof $CLJS.M?b.S:null,b){case "default":return $CLJS.eE("Starts with");default:throw Error(["No matching clause: ",$CLJS.p.h(b)].join(""));}case "ends-with":switch(b=b instanceof $CLJS.M?b.S:null,b){case "default":return $CLJS.eE("Ends with");default:throw Error(["No matching clause: ",$CLJS.p.h(b)].join(""));}case "inside":switch(b=b instanceof $CLJS.M?b.S:null,b){case "default":return $CLJS.eE("Inside");default:throw Error(["No matching clause: ",$CLJS.p.h(b)].join(""));
}default:throw Error(["No matching clause: ",$CLJS.p.h(a)].join(""));}},U1=function(a,b){switch(a instanceof $CLJS.M?a.S:null){case "\x3d":return"\x3d";case "!\x3d":return"≠";case "\x3e":return"\x3e";case "\x3c":return"\x3c";case "\x3e\x3d":return"≥";case "\x3c\x3d":return"≤";default:return T1(a,b)}},epa=new $CLJS.M(null,"after","after",594996914),fpa=new $CLJS.M(null,"equal-to","equal-to",608296653),gpa=new $CLJS.M(null,"excludes","excludes",-1791725945),hpa=new $CLJS.M(null,"not-equal-to","not-equal-to",
-343181655),ipa=new $CLJS.M(null,"before","before",-1633692388);$CLJS.V1=function(){function a(d,e){return new $CLJS.h(null,3,[$CLJS.Fj,$CLJS.lJ,$CLJS.yF,d,$CLJS.mJ,e],null)}function b(d){return c.g?c.g(d,$CLJS.di):c.call(null,d,$CLJS.di)}var c=null;c=function(d,e){switch(arguments.length){case 1:return b.call(this,d);case 2:return a.call(this,d,e)}throw Error("Invalid arity: "+arguments.length);};c.h=b;c.g=a;return c}();
$CLJS.W1=new $CLJS.P(null,9,5,$CLJS.Q,[$CLJS.V1.h($CLJS.or),$CLJS.V1.h($CLJS.XF),$CLJS.V1.h($CLJS.hr),$CLJS.V1.h($CLJS.lr),$CLJS.V1.h($CLJS.gF),$CLJS.V1.h($CLJS.jr),$CLJS.V1.h($CLJS.nr),$CLJS.V1.g($CLJS.fF,$CLJS.hF),$CLJS.V1.g($CLJS.rF,$CLJS.oF)],null);$CLJS.jpa=new $CLJS.P(null,8,5,$CLJS.Q,[$CLJS.V1.h($CLJS.or),$CLJS.V1.h($CLJS.XF),$CLJS.V1.h($CLJS.hF),$CLJS.V1.h($CLJS.oF),$CLJS.V1.h($CLJS.EF),$CLJS.V1.h($CLJS.xF),$CLJS.V1.h($CLJS.wF),$CLJS.V1.h($CLJS.QF)],null);
$CLJS.kpa=new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.V1.g($CLJS.XF,gpa),$CLJS.V1.h($CLJS.or),$CLJS.V1.g($CLJS.lr,ipa),$CLJS.V1.g($CLJS.hr,epa),$CLJS.V1.h($CLJS.gF),$CLJS.V1.g($CLJS.fF,$CLJS.hF),$CLJS.V1.g($CLJS.rF,$CLJS.oF)],null);$CLJS.lpa=new $CLJS.P(null,8,5,$CLJS.Q,[$CLJS.V1.h($CLJS.or),$CLJS.V1.h($CLJS.XF),$CLJS.V1.h($CLJS.mF),$CLJS.V1.h($CLJS.hr),$CLJS.V1.h($CLJS.lr),$CLJS.V1.h($CLJS.gF),$CLJS.V1.h($CLJS.jr),$CLJS.V1.h($CLJS.nr)],null);
$CLJS.mpa=new $CLJS.P(null,9,5,$CLJS.Q,[$CLJS.V1.g($CLJS.or,fpa),$CLJS.V1.g($CLJS.XF,hpa),$CLJS.V1.h($CLJS.hr),$CLJS.V1.h($CLJS.lr),$CLJS.V1.h($CLJS.gF),$CLJS.V1.h($CLJS.jr),$CLJS.V1.h($CLJS.nr),$CLJS.V1.g($CLJS.fF,$CLJS.hF),$CLJS.V1.g($CLJS.rF,$CLJS.oF)],null);
$CLJS.npa=new $CLJS.P(null,10,5,$CLJS.Q,[$CLJS.V1.h($CLJS.or),$CLJS.V1.h($CLJS.XF),$CLJS.V1.h($CLJS.EF),$CLJS.V1.h($CLJS.xF),$CLJS.V1.h($CLJS.fF),$CLJS.V1.h($CLJS.rF),$CLJS.V1.h($CLJS.hF),$CLJS.V1.h($CLJS.oF),$CLJS.V1.h($CLJS.wF),$CLJS.V1.h($CLJS.QF)],null);$CLJS.opa=new $CLJS.P(null,6,5,$CLJS.Q,[$CLJS.V1.h($CLJS.or),$CLJS.V1.h($CLJS.XF),$CLJS.V1.h($CLJS.fF),$CLJS.V1.h($CLJS.rF),$CLJS.V1.h($CLJS.hF),$CLJS.V1.h($CLJS.oF)],null);
$CLJS.ppa=new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.V1.h($CLJS.or),$CLJS.V1.g($CLJS.fF,$CLJS.hF),$CLJS.V1.g($CLJS.rF,$CLJS.oF)],null);$CLJS.qpa=new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.V1.h($CLJS.or),$CLJS.V1.h($CLJS.XF),$CLJS.V1.h($CLJS.fF),$CLJS.V1.h($CLJS.rF)],null);$CLJS.X1=new $CLJS.P(null,6,5,$CLJS.Q,[$CLJS.R.j($CLJS.V1.h($CLJS.or),$CLJS.di,!0),$CLJS.V1.h($CLJS.hr),$CLJS.V1.h($CLJS.lr),$CLJS.V1.h($CLJS.jr),$CLJS.V1.h($CLJS.nr),$CLJS.V1.h($CLJS.XF)],null);
$CLJS.c0.m(null,$CLJS.lJ,function(a,b,c,d){b=$CLJS.O(c);a=$CLJS.J.g(b,$CLJS.yF);b=$CLJS.J.g(b,$CLJS.mJ);d=d instanceof $CLJS.M?d.S:null;switch(d){case "default":return U1(a,b);case "long":return T1(a,b);default:throw Error(["No matching clause: ",$CLJS.p.h(d)].join(""));}});
$CLJS.j0.m(null,$CLJS.lJ,function(a,b,c){c=$CLJS.O(c);a=$CLJS.J.g(c,$CLJS.yF);b=$CLJS.J.g(c,$CLJS.mJ);c=$CLJS.J.g(c,$CLJS.di);a=new $CLJS.h(null,3,[$CLJS.a0,$CLJS.zA(a),$CLJS.ID,U1(a,b),$CLJS.F_,T1(a,b)],null);return $CLJS.n(c)?$CLJS.R.j(a,$CLJS.di,!0):a});