var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.string.js");require("./metabase.lib.dispatch.js");require("./metabase.lib.hierarchy.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.expression.js");require("./metabase.lib.schema.id.js");require("./metabase.lib.schema.mbql_clause.js");require("./metabase.lib.schema.temporal_bucketing.js");require("./metabase.types.js");require("./metabase.util.malli.registry.js");
'use strict';var YK,$K,bL,eL,hL;$CLJS.XK=function(a){return $CLJS.Xh($CLJS.q($CLJS.zB),a,$CLJS.JF)};YK=new $CLJS.M("metabase.lib.schema.ref","aggregation-options","metabase.lib.schema.ref/aggregation-options",-15566487);$CLJS.ZK=new $CLJS.M(null,"operators","operators",-2064102509);$K=new $CLJS.M("metabase.lib.schema.ref","field.options","metabase.lib.schema.ref/field.options",408316031);$CLJS.aL=new $CLJS.M(null,"stage-number","stage-number",-1752729638);
bL=new $CLJS.M("metabase.lib.schema.ref","field.literal","metabase.lib.schema.ref/field.literal",-1228060748);$CLJS.cL=new $CLJS.M(null,"min-value","min-value",-1119123315);$CLJS.dL=new $CLJS.M(null,"dimensions","dimensions",-254818097);eL=new $CLJS.M("metabase.lib.schema.ref","field.id","metabase.lib.schema.ref/field.id",-956546909);$CLJS.fL=new $CLJS.M(null,"max-value","max-value",687805168);$CLJS.gL=new $CLJS.M("metabase.lib.schema","query","metabase.lib.schema/query",-1207387140);
hL=new $CLJS.M("metabase.lib.schema.ref","field.literal.options","metabase.lib.schema.ref/field.literal.options",-860446668);$CLJS.X($K,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Xs,$CLJS.SD,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ik,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.LF,new $CLJS.h(null,1,[$CLJS.fs,!0],null),$CLJS.zG],null)],null)],null));$CLJS.X(hL,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Xs,$K,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ik,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.TA,$CLJS.WD],null)],null)],null));$CLJS.X(bL,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.Pr,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.or,$CLJS.FF],null),hL,$CLJS.ND],null));
$CLJS.X(eL,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.Pr,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.or,$CLJS.FF],null),$K,$CLJS.FK],null));
$CLJS.fG.g($CLJS.FF,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Tr,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.Pr,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.or,$CLJS.FF],null),$K,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Mr,$CLJS.FK,$CLJS.ND],null)],null),new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.Sr,new $CLJS.h(null,2,[$CLJS.Pi,function(a){return $CLJS.wd(a)?($CLJS.I(a,0,null),$CLJS.I(a,1,null),a=$CLJS.I(a,2,null),$CLJS.xB(a)):null},$CLJS.Ys,"Invalid :field clause ID or name: must be a string or integer"],null),new $CLJS.P(null,2,
5,$CLJS.Q,[$CLJS.LA,eL],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.KA,bL],null)],null)],null));$CLJS.LE($CLJS.FF,$CLJS.JF);$CLJS.gE.m(null,$CLJS.FF,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);$CLJS.I(a,2,null);a=$CLJS.$z($CLJS.Gi,$CLJS.TA)(b);return $CLJS.n(a)?a:$CLJS.tE});$CLJS.SE($CLJS.QA,$CLJS.H([$CLJS.ND]));$CLJS.gE.m(null,$CLJS.QA,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);$CLJS.I(a,2,null);a=$CLJS.$z($CLJS.Gi,$CLJS.TA)(b);return $CLJS.n(a)?a:$CLJS.tE});
$CLJS.LE($CLJS.QA,$CLJS.JF);$CLJS.X(YK,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Xs,$CLJS.SD,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ik,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.T,new $CLJS.h(null,1,[$CLJS.fs,!0],null),$CLJS.ND],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ID,new $CLJS.h(null,1,[$CLJS.fs,!0],null),$CLJS.ND],null)],null)],null));$CLJS.fG.g($CLJS.uF,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.Pr,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.or,$CLJS.uF],null),YK,$CLJS.ak],null));
$CLJS.gE.m(null,$CLJS.uF,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);$CLJS.I(a,2,null);a=$CLJS.$z($CLJS.Gi,$CLJS.TA)(b);return $CLJS.n(a)?a:$CLJS.tE});$CLJS.LE($CLJS.uF,$CLJS.JF);$CLJS.SE($CLJS.cF,$CLJS.H([$CLJS.Ds,$CLJS.Mj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.nk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,$CLJS.jK],null)],null)]));$CLJS.LE($CLJS.cF,$CLJS.JF);
$CLJS.SE($CLJS.AF,$CLJS.H([$CLJS.Ds,$CLJS.tE,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.nk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,$CLJS.hK],null)],null)]));$CLJS.LE($CLJS.AF,$CLJS.JF);
$CLJS.X($CLJS.JF,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Tr,$CLJS.iE,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Si,new $CLJS.h(null,1,[$CLJS.it,function(){return["Valid reference, must be one of these clauses: ",$CLJS.Ms(", ",$CLJS.cA($CLJS.Jd,$CLJS.ll.g($CLJS.q($CLJS.zB),$CLJS.JF)))].join("")}],null),function(a){a=$CLJS.I(a,0,null);return $CLJS.XK(a)}],null)],null));