var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./malli.core.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.expression.js");require("./metabase.types.js");require("./metabase.util.malli.js");require("./metabase.util.malli.registry.js");
'use strict';var GE,PE,ME,Wfa;GE=function(a){if(null!=a&&null!=a.ia)a=a.cache;else{var b=GE[$CLJS.wa(null==a?null:a)];if(null!=b)a=b.h?b.h(a):b.call(null,a);else if(b=GE._,null!=b)a=b.h?b.h(a):b.call(null,a);else throw $CLJS.bb("Cached.-cache",a);}return a};
$CLJS.HE=function(a,b,c){if(null!=a&&$CLJS.Bc===a.Wa){var d=GE(a);var e=$CLJS.q(d);e=e.h?e.h(b):e.call(null,b);if($CLJS.n(e))return e;a=$CLJS.Ph.v(d,$CLJS.R,b,c.h?c.h(a):c.call(null,a));return a.h?a.h(b):a.call(null,b)}return c.h?c.h(a):c.call(null,a)};$CLJS.JE=function(a,b,c){a=$CLJS.IE.g(a,c);return a.h?a.h(b):a.call(null,b)};$CLJS.KE=function(a,b){return $CLJS.JE(a,b,null)};$CLJS.LE=function(a,b){$CLJS.Ph.v($CLJS.zB,$CLJS.GA,a,b)};$CLJS.NE=function(a,b){a=ME(a,b);return $CLJS.n(a)?a:$CLJS.Bj};
PE=function(){return $CLJS.fg.j(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Sr,new $CLJS.h(null,2,[$CLJS.Pi,$CLJS.z,$CLJS.it,function(a){a=$CLJS.O(a);a=$CLJS.J.g(a,$CLJS.Ej);return $CLJS.zd(a)?["Invalid ",$CLJS.Nh.l($CLJS.H([$CLJS.z(a)]))," clause: ",$CLJS.Nh.l($CLJS.H([a]))].join(""):"not an MBQL clause"}],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Eo,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Si,new $CLJS.h(null,1,[$CLJS.Ys,"not a known MBQL clause"],null),$CLJS.Ve(!1)],null)],null)],null),$CLJS.jf.h(function(a){return new $CLJS.P(null,
2,5,$CLJS.Q,[a,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,$CLJS.Ah.g("mbql.clause",$CLJS.hh(a))],null)],null)}),$CLJS.q(OE))};$CLJS.SE=function(a,b){$CLJS.Te.v(QE,RE,a,b)};$CLJS.UE=function(a,b){$CLJS.Te.v(QE,TE,a,b)};$CLJS.Zo.prototype.ia=$CLJS.va(26,function(){return this.cache});$CLJS.ap.prototype.ia=$CLJS.va(25,function(){return this.cache});$CLJS.cp.prototype.ia=$CLJS.va(24,function(){return this.cache});$CLJS.ep.prototype.ia=$CLJS.va(23,function(){return this.cache});
$CLJS.gp.prototype.ia=$CLJS.va(22,function(){return this.cache});$CLJS.ip.prototype.ia=$CLJS.va(21,function(){return this.cache});$CLJS.lp.prototype.ia=$CLJS.va(20,function(){return this.cache});$CLJS.np.prototype.ia=$CLJS.va(19,function(){return this.cache});$CLJS.pp.prototype.ia=$CLJS.va(18,function(){return this.cache});$CLJS.rp.prototype.ia=$CLJS.va(17,function(){return this.cache});$CLJS.up.prototype.ia=$CLJS.va(16,function(){return this.cache});$CLJS.wp.prototype.ia=$CLJS.va(15,function(){return this.cache});
$CLJS.yp.prototype.ia=$CLJS.va(14,function(){return this.cache});$CLJS.Ap.prototype.ia=$CLJS.va(13,function(){return this.cache});$CLJS.Cp.prototype.ia=$CLJS.va(12,function(){return this.cache});$CLJS.Ep.prototype.ia=$CLJS.va(11,function(){return this.cache});$CLJS.Hp.prototype.ia=$CLJS.va(10,function(){return this.cache});$CLJS.Mp.prototype.ia=$CLJS.va(9,function(){return this.cache});$CLJS.Op.prototype.ia=$CLJS.va(8,function(){return this.cache});$CLJS.Rp.prototype.ia=$CLJS.va(7,function(){return this.cache});
$CLJS.aq.prototype.ia=$CLJS.va(6,function(){return this.cache});$CLJS.tD.prototype.ia=$CLJS.va(5,function(){return this.cache});$CLJS.IE=function IE(a){switch(arguments.length){case 1:return IE.h(arguments[0]);case 2:return IE.g(arguments[0],arguments[1]);default:throw Error(["Invalid arity: ",$CLJS.p.h(arguments.length)].join(""));}};$CLJS.IE.h=function(a){return $CLJS.IE.g(a,null)};$CLJS.IE.g=function(a,b){return $CLJS.HE($CLJS.lo.g(a,b),$CLJS.yk,$CLJS.pn)};$CLJS.IE.A=2;
$CLJS.VE=function VE(a,b){return $CLJS.Xh($CLJS.mD,a,b)||$CLJS.Ed($CLJS.Se(function(d){return VE.g?VE.g(a,d):VE.call(null,a,d)},$CLJS.ll.h(b)))||$CLJS.Ed($CLJS.Se(function(d){return VE.g?VE.g(d,b):VE.call(null,d,b)},$CLJS.Zh(a)))};
ME=function ME(a,b){return $CLJS.E.g(a,$CLJS.Bj)?null:$CLJS.E.g(b,$CLJS.Bj)?null:$CLJS.VE(a,b)?b:$CLJS.VE(b,a)?a:$CLJS.Se(function(d){return $CLJS.Se(function(e){return $CLJS.E.g(new $CLJS.P(null,2,5,$CLJS.Q,[d,e],null),new $CLJS.P(null,2,5,$CLJS.Q,[a,b],null))?null:ME.g?ME.g(d,e):ME.call(null,d,e)},$CLJS.fe(b,$CLJS.Zh(b)))},$CLJS.fe(a,$CLJS.Zh(a)))};$CLJS.WE=new $CLJS.M(null,"exp","exp",-261706262);$CLJS.XE=new $CLJS.M(null,"substring","substring",1140866276);
$CLJS.YE=new $CLJS.M(null,"dimension","dimension",543254198);$CLJS.ZE=new $CLJS.M(null,"basic-aggregations","basic-aggregations",-1628959228);$CLJS.$E=new $CLJS.M(null,"ceil","ceil",-1824929952);$CLJS.aF=new $CLJS.M(null,"query","query",-1288509510);$CLJS.bF=new $CLJS.M(null,"power","power",-937852079);$CLJS.cF=new $CLJS.M(null,"segment","segment",-964921196);$CLJS.dF=new $CLJS.M(null,"concat","concat",-2108183992);$CLJS.eF=new $CLJS.M(null,"abs","abs",-246026477);
$CLJS.fF=new $CLJS.M(null,"is-null","is-null",-1997050930);$CLJS.gF=new $CLJS.M(null,"between","between",1131099276);$CLJS.hF=new $CLJS.M(null,"is-empty","is-empty",-1040302908);$CLJS.iF=new $CLJS.M(null,"filter","filter",-948537934);$CLJS.jF=new $CLJS.M(null,"distinct","distinct",-1788879121);$CLJS.kF=new $CLJS.M(null,"var","var",-769682797);$CLJS.lF=new $CLJS.M(null,"next","next",-117701485);$CLJS.mF=new $CLJS.M(null,"inside","inside",1972503011);$CLJS.nF=new $CLJS.M(null,"case","case",1143702196);
$CLJS.oF=new $CLJS.M(null,"not-empty","not-empty",388922063);$CLJS.pF=new $CLJS.M(null,"column-name","column-name",551523580);$CLJS.qF=new $CLJS.M(null,"percentile","percentile",-601188752);$CLJS.rF=new $CLJS.M(null,"not-null","not-null",-1326718535);$CLJS.sF=new $CLJS.M(null,"sqrt","sqrt",-1270051929);$CLJS.tF=new $CLJS.M(null,"breakout","breakout",-732419050);$CLJS.uF=new $CLJS.M(null,"aggregation","aggregation",1597476696);$CLJS.vF=new $CLJS.M(null,"desc","desc",2093485764);
$CLJS.wF=new $CLJS.M(null,"starts-with","starts-with",366503009);$CLJS.xF=new $CLJS.M(null,"does-not-contain","does-not-contain",-274946853);$CLJS.yF=new $CLJS.M(null,"short","short",1928760516);$CLJS.zF=new $CLJS.M(null,"avg","avg",197406200);$CLJS.AF=new $CLJS.M(null,"metric","metric",408798077);$CLJS.BF=new $CLJS.M(null,"percentile-aggregations","percentile-aggregations",1241763983);$CLJS.CF=new $CLJS.M(null,"asc","asc",356854569);$CLJS.DF=new $CLJS.M(null,"floor","floor",1882041021);
$CLJS.EF=new $CLJS.M(null,"contains","contains",676899812);$CLJS.FF=new $CLJS.M(null,"field","field",-1302436500);$CLJS.GF=new $CLJS.M(null,"cum-count","cum-count",-1964431543);$CLJS.HF=new $CLJS.M(null,"regex-match-first","regex-match-first",-657299718);$CLJS.IF=new $CLJS.M(null,"coalesce","coalesce",654622029);$CLJS.JF=new $CLJS.M("metabase.lib.schema.ref","ref","metabase.lib.schema.ref/ref",-1124116498);$CLJS.KF=new $CLJS.M(null,"round","round",2009433328);
$CLJS.LF=new $CLJS.M(null,"temporal-unit","temporal-unit",-1892306358);$CLJS.MF=new $CLJS.M(null,"share","share",-589433933);$CLJS.NF=new $CLJS.M(null,"case-sensitive","case-sensitive",-1005239404);$CLJS.OF=new $CLJS.M(null,"count-where","count-where",385407720);$CLJS.PF=new $CLJS.M(null,"standard-deviation-aggregations","standard-deviation-aggregations",-1683333464);$CLJS.QF=new $CLJS.M(null,"ends-with","ends-with",-456791011);$CLJS.RF=new $CLJS.M(null,"time-interval","time-interval",704622015);
$CLJS.SF=new $CLJS.M(null,"sum","sum",136986814);$CLJS.TF=new $CLJS.M(null,"median","median",569566131);Wfa=new $CLJS.M("metabase.lib.schema.mbql-clause","update-schemas","metabase.lib.schema.mbql-clause/update-schemas",-447263953);$CLJS.VF=new $CLJS.M(null,"stddev","stddev",-865474939);$CLJS.WF=new $CLJS.M(null,"trim","trim",774319767);$CLJS.XF=new $CLJS.M(null,"!\x3d","!\x3d",-1841737356);$CLJS.YF=new $CLJS.M(null,"ltrim","ltrim",1654269283);$CLJS.ZF=new $CLJS.M(null,"category","category",-593092832);
$CLJS.$F=new $CLJS.M(null,"sum-where","sum-where",2135348428);$CLJS.aG=new $CLJS.M(null,"/","/",1282502798);$CLJS.bG=new $CLJS.M(null,"cum-sum","cum-sum",2064902349);$CLJS.cG=new $CLJS.M(null,"log","log",-1595516004);$CLJS.dG=new $CLJS.M(null,"replace","replace",-786587770);$CLJS.eG=new $CLJS.M(null,"rtrim","rtrim",-661336449);var OE=$CLJS.Ye($CLJS.ch),TE;$CLJS.X($CLJS.iE,PE());$CLJS.AB(OE,Wfa,function(){return $CLJS.X($CLJS.iE,PE())});
$CLJS.fG=function(){function a(d,e,f,k){c.g?c.g(d,k):c.call(null,d,k);$CLJS.gE.m(null,d,function(){return f});return null}function b(d,e){var f=$CLJS.Ah.g("mbql.clause",$CLJS.hh(d));$CLJS.X(f,e);$CLJS.Hd($CLJS.q(OE),d)||$CLJS.Ph.j(OE,$CLJS.ce,d);return null}var c=null;c=function(d,e,f,k){switch(arguments.length){case 2:return b.call(this,d,e);case 4:return a.call(this,d,e,f,k)}throw Error("Invalid arity: "+arguments.length);};c.g=b;c.v=a;return c}();
TE=function TE(a){for(var c=[],d=arguments.length,e=0;;)if(e<d)c.push(arguments[e]),e+=1;else break;return TE.l(arguments[0],1<c.length?new $CLJS.w(c.slice(1),0,null):null)};
TE.l=function(a,b){return new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.nk,$CLJS.fg.g(new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.Dr,new $CLJS.h(null,1,[$CLJS.Ys,["Valid ",$CLJS.p.h(a)," clause"].join("")],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ij,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.or,a],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.gj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.nk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,$CLJS.SD],null)],null)],null)],null),b)],null)};TE.A=1;
TE.B=function(a){var b=$CLJS.z(a);a=$CLJS.B(a);return this.l(b,a)};var RE=function RE(a){for(var c=[],d=arguments.length,e=0;;)if(e<d)c.push(arguments[e]),e+=1;else break;return RE.l(arguments[0],1<c.length?new $CLJS.w(c.slice(1),0,null):null)};
RE.l=function(a,b){return $CLJS.fg.g(new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.Pr,new $CLJS.h(null,1,[$CLJS.Ys,["Valid ",$CLJS.p.h(a)," clause"].join("")],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.or,a],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,$CLJS.SD],null)],null),b)};RE.A=1;RE.B=function(a){var b=$CLJS.z(a);a=$CLJS.B(a);return this.l(b,a)};
var QE=function QE(a){for(var c=[],d=arguments.length,e=0;;)if(e<d)c.push(arguments[e]),e+=1;else break;return QE.l(arguments[0],arguments[1],2<c.length?new $CLJS.w(c.slice(2),0,null):null)};QE.l=function(a,b,c){c=$CLJS.E.g($CLJS.z(c),$CLJS.Ds)?$CLJS.fe($CLJS.hd(c),$CLJS.cf(2,c)):$CLJS.fe(null,c);var d=$CLJS.y(c);c=$CLJS.z(d);d=$CLJS.B(d);a=$CLJS.Te.j(a,b,d);return $CLJS.n(c)?$CLJS.fG.v(b,$CLJS.Ds,c,a):$CLJS.fG.g(b,a)};QE.A=2;
QE.B=function(a){var b=$CLJS.z(a),c=$CLJS.B(a);a=$CLJS.z(c);c=$CLJS.B(c);return this.l(b,a,c)};