var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.set.js");require("./malli.core.js");require("./medley.core.js");require("./metabase.lib.common.js");require("./metabase.lib.join.js");require("./metabase.lib.join.util.js");require("./metabase.lib.metadata.calculation.js");require("./metabase.lib.options.js");require("./metabase.lib.util.js");require("./metabase.mbql.util.match.js");require("./metabase.types.js");require("./metabase.util.js");require("./metabase.util.malli.js");
'use strict';var a5,zra,Ara,c5,d5,e5,f5,Bra,Cra,i5,g5,h5,j5,Era,l5,Fra,Gra,Hra,m5,Ira,Jra,n5,Kra,k5,Lra,Dra;a5=function(a,b){if(b=$CLJS.y(b)){var c=$CLJS.y(b);b=$CLJS.z(c);c=$CLJS.B(c);return $CLJS.y(c)?(c=a5($CLJS.J.g(a,b),c),$CLJS.td(c)?$CLJS.Ik.g(a,b):$CLJS.R.j(a,b,c)):$CLJS.Ik.g(a,b)}return a};
zra=function(a,b,c,d){return function t(f,k,l,m){var u=$CLJS.y(k);k=$CLJS.z(u);u=$CLJS.B(u);var v=$CLJS.gm(f,k);return $CLJS.n(v)?u?$CLJS.R.j(f,k,t($CLJS.Fb(v),u,l,m)):$CLJS.R.j(f,k,$CLJS.Te.j(l,$CLJS.Fb(v),m)):f}(a,b,c,d)};Ara=function(a){for(var b=[],c=arguments.length,d=0;;)if(d<c)b.push(arguments[d]),d+=1;else break;return zra(arguments[0],arguments[1],arguments[2],3<b.length?new $CLJS.w(b.slice(3),0,null):null)};$CLJS.b5=function(a){return $CLJS.UD.h($CLJS.aE(a))};
c5=function(a,b,c,d){var e=$CLJS.E.g($CLJS.vP,$CLJS.z(b))?$CLJS.WV(d,function(){var f=$CLJS.mW.h(d);return $CLJS.n(f)?f:$CLJS.VV(c)}()):d;return Ara(a,b,function(f){return $CLJS.Ef(function(){return function m(l){return new $CLJS.oe(null,function(){for(;;){var t=$CLJS.y(l);if(t){if($CLJS.Ad(t)){var u=$CLJS.lc(t),v=$CLJS.D(u),x=$CLJS.re(v);a:for(var A=0;;)if(A<v){var C=$CLJS.kd(u,A);C=$CLJS.E.g($CLJS.b5(C),$CLJS.b5(c))?e:C;x.add(C);A+=1}else{u=!0;break a}return u?$CLJS.ue($CLJS.we(x),m($CLJS.mc(t))):
$CLJS.ue($CLJS.we(x),null)}x=$CLJS.z(t);return $CLJS.fe($CLJS.E.g($CLJS.b5(x),$CLJS.b5(c))?e:x,m($CLJS.Lc(t)))}return null}},null,null)}(f)}())})};
d5=function(a,b,c,d){var e=$CLJS.bA(a,b);if($CLJS.n(e)){var f=$CLJS.b5(c),k=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.z(b),$CLJS.id(b)],null);c=$CLJS.I(k,0,null);k=$CLJS.I(k,1,null);e=$CLJS.fg.j($CLJS.Df,$CLJS.Rk.h($CLJS.Qk.g($CLJS.dh([f]),$CLJS.b5)),e);e=$CLJS.E.g(b,new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.mE],null))&&$CLJS.Re(function(l){return $CLJS.eY(l,$CLJS.QA)},e)?null:e;if($CLJS.y(e))return $CLJS.sL(a,b,e);if($CLJS.E.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.$M,$CLJS.SK],null),new $CLJS.P(null,2,5,$CLJS.Q,
[c,k],null)))throw $CLJS.ii($CLJS.eE("Cannot remove the final join condition"),new $CLJS.h(null,5,[$CLJS.cy,$CLJS.gW,$CLJS.SK,$CLJS.bA(a,b),$CLJS.hW,$CLJS.bA(a,$CLJS.sd(b)),$CLJS.aL,d,$CLJS.iW,a],null));return $CLJS.E.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.$M,$CLJS.mE],null),new $CLJS.P(null,2,5,$CLJS.Q,[c,k],null))?$CLJS.UW.v(a,$CLJS.sd(b),$CLJS.Ik,k):a5(a,b)}return a};
e5=function(a,b){var c=$CLJS.B3.g(a,b),d=$CLJS.mh(0,$CLJS.D(c));return $CLJS.hf.l(new $CLJS.P(null,6,5,$CLJS.Q,[new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.kR],null),new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.tF],null),new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.FV],null),new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.mE],null),new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.uF],null),new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.vP],null)],null),function(){return function k(f){return new $CLJS.oe(null,function(){for(var l=f;;)if(l=$CLJS.y(l)){if($CLJS.Ad(l)){var m=
$CLJS.lc(l),t=$CLJS.D(m),u=$CLJS.re(t);a:for(var v=0;;)if(v<t){var x=$CLJS.kd(m,v),A=$CLJS.F(c,x);$CLJS.mE.h(A)instanceof $CLJS.M||u.add(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.$M,x,$CLJS.mE],null));v+=1}else{m=!0;break a}return m?$CLJS.ue($CLJS.we(u),k($CLJS.mc(l))):$CLJS.ue($CLJS.we(u),null)}u=$CLJS.z(l);m=$CLJS.F(c,u);if($CLJS.mE.h(m)instanceof $CLJS.M)l=$CLJS.Lc(l);else return $CLJS.fe(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.$M,u,$CLJS.mE],null),k($CLJS.Lc(l)))}else return null},null,null)}(d)}(),$CLJS.H([function(){return function k(f){return new $CLJS.oe(null,
function(){for(;;){var l=$CLJS.y(f);if(l){if($CLJS.Ad(l)){var m=$CLJS.lc(l),t=$CLJS.D(m),u=$CLJS.re(t);a:for(var v=0;;)if(v<t){var x=$CLJS.kd(m,v);u.add(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.$M,x,$CLJS.SK],null));v+=1}else{m=!0;break a}return m?$CLJS.ue($CLJS.we(u),k($CLJS.mc(l))):$CLJS.ue($CLJS.we(u),null)}u=$CLJS.z(l);return $CLJS.fe(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.$M,u,$CLJS.SK],null),k($CLJS.Lc(l)))}return null}},null,null)}(d)}()]))};
f5=function(a,b,c){var d=$CLJS.I(c,0,null),e=$CLJS.I(c,1,null);e=$CLJS.O(e);var f=$CLJS.J.g(e,$CLJS.LF),k=$CLJS.J.g(e,$CLJS.RQ),l=$CLJS.I(c,2,null);return $CLJS.z($CLJS.QG(function(m){$CLJS.I(m,0,null);m=$CLJS.I(m,1,null);$CLJS.I(m,0,null);$CLJS.I(m,1,null);m=$CLJS.I(m,2,null);return $CLJS.E.g($CLJS.z(m),d)&&$CLJS.E.g($CLJS.LF.h($CLJS.hd(m)),f)&&$CLJS.E.g($CLJS.RQ.h($CLJS.hd(m)),k)&&$CLJS.E.g($CLJS.id(m),l)},$CLJS.em($CLJS.es,$CLJS.kR.h($CLJS.bW(a,b)))))};
Bra=function(a,b,c,d){c=f5(a,b,c);return $CLJS.n(c)?$CLJS.nW.l(a,b,$CLJS.UW,$CLJS.H([new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.kR,c,2,1],null),$CLJS.Qk.g(function(e){return $CLJS.V0($CLJS.Bl,e)},$CLJS.hl),d])):a};Cra=function(a,b,c){c=f5(a,b,c);return $CLJS.n(c)?$CLJS.nW.l(a,b,d5,$CLJS.H([new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.kR],null),$CLJS.bA($CLJS.bW(a,b),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.kR,c],null)),b])):a};
i5=function(a,b,c,d,e,f){var k=$CLJS.nW.l(a,b,f,$CLJS.H([d,e])),l=$CLJS.b5(e);return $CLJS.Ok.g(a,k)?$CLJS.z($CLJS.y($CLJS.mf($CLJS.Xa,function(m,t){try{if($CLJS.zd(t)&&1===$CLJS.D(t))try{var u=$CLJS.F(t,0);if($CLJS.ie(u,$CLJS.vP))return new $CLJS.P(null,1,5,$CLJS.Q,[function(){var K=$CLJS.VV(e);K=g5.W?g5.W(k,b,c,$CLJS.QA,$CLJS.N,K):g5.call(null,k,b,c,$CLJS.QA,$CLJS.N,K);return h5.v?h5.v(K,b,c,l):h5.call(null,K,b,c,l)}()],null);throw $CLJS.Y;}catch(K){if(K instanceof Error)if(m=K,m===$CLJS.Y)try{u=
$CLJS.F(t,0);if($CLJS.ie(u,$CLJS.uF))return new $CLJS.P(null,1,5,$CLJS.Q,[function(){var S=g5.W?g5.W(k,b,c,$CLJS.uF,$CLJS.N,l):g5.call(null,k,b,c,$CLJS.uF,$CLJS.N,l);return h5.v?h5.v(S,b,c,l):h5.call(null,S,b,c,l)}()],null);throw $CLJS.Y;}catch(S){if(S instanceof Error){var v=S;if(v===$CLJS.Y)throw $CLJS.Y;throw v;}throw S;}else throw m;else throw K;}else throw $CLJS.Y;}catch(K){if(K instanceof Error)if(m=K,m===$CLJS.Y)try{if($CLJS.zd(t)&&1===$CLJS.D(t))try{var x=$CLJS.F(t,0);if($CLJS.ie(x,$CLJS.tF))return new $CLJS.P(null,
1,5,$CLJS.Q,[h5.v?h5.v(k,b,c,l):h5.call(null,k,b,c,l)],null);throw $CLJS.Y;}catch(S){if(S instanceof Error)if(v=S,v===$CLJS.Y)try{x=$CLJS.F(t,0);if($CLJS.ie(x,$CLJS.mE))return new $CLJS.P(null,1,5,$CLJS.Q,[h5.v?h5.v(k,b,c,l):h5.call(null,k,b,c,l)],null);throw $CLJS.Y;}catch(W){if(W instanceof Error){var A=W;if(A===$CLJS.Y)throw $CLJS.Y;throw A;}throw W;}else throw v;else throw S;}else throw $CLJS.Y;}catch(S){if(S instanceof Error)if(v=S,v===$CLJS.Y)try{if($CLJS.zd(t)&&3===$CLJS.D(t))try{var C=$CLJS.F(t,
0);if($CLJS.ie(C,$CLJS.$M))try{var G=$CLJS.F(t,2);if($CLJS.ie(G,$CLJS.mE))return new $CLJS.P(null,1,5,$CLJS.Q,[h5.v?h5.v(k,b,c,l):h5.call(null,k,b,c,l)],null);throw $CLJS.Y;}catch(W){if(W instanceof Error){A=W;if(A===$CLJS.Y)throw $CLJS.Y;throw A;}throw W;}else throw $CLJS.Y;}catch(W){if(W instanceof Error){A=W;if(A===$CLJS.Y)throw $CLJS.Y;throw A;}throw W;}else throw $CLJS.Y;}catch(W){if(W instanceof Error){A=W;if(A===$CLJS.Y)return new $CLJS.P(null,1,5,$CLJS.Q,[k],null);throw A;}throw W;}else throw v;
else throw S;}else throw m;else throw K;}}($CLJS.Df,d)))):k};
g5=function(a,b,c,d,e,f){var k=$CLJS.bW(a,b),l=$CLJS.kf(function(m){var t=$CLJS.bA(k,m);return $CLJS.n(t)?$CLJS.dm(function(u){return $CLJS.z($CLJS.y($CLJS.mf($CLJS.Xa,function C(x,A){try{if($CLJS.zd(A)&&3===$CLJS.D(A))try{var G=$CLJS.F(A,0);if($CLJS.E.g(G,d))try{var K=$CLJS.F(A,1);if($CLJS.td(e)||$CLJS.U0($CLJS.gh(e),$CLJS.gh(K)))try{var S=$CLJS.F(A,2);if($CLJS.E.g(S,f))return new $CLJS.P(null,1,5,$CLJS.Q,[new $CLJS.P(null,2,5,$CLJS.Q,[m,u],null)],null);throw $CLJS.Y;}catch(Z){if(Z instanceof Error){var W=
Z;if(W===$CLJS.Y)throw $CLJS.Y;throw W;}throw Z;}else throw $CLJS.Y;}catch(Z){if(Z instanceof Error){W=Z;if(W===$CLJS.Y)throw $CLJS.Y;throw W;}throw Z;}else throw $CLJS.Y;}catch(Z){if(Z instanceof Error){W=Z;if(W===$CLJS.Y)throw $CLJS.Y;throw W;}throw Z;}else throw $CLJS.Y;}catch(Z){if(Z instanceof Error){W=Z;if(W===$CLJS.Y)return $CLJS.XU(C,x,A);throw W;}throw Z;}}($CLJS.Df,u))))},t):null},$CLJS.H([e5(a,b)]));return $CLJS.eb(function(m,t){var u=$CLJS.I(t,0,null);t=$CLJS.I(t,1,null);return i5(m,b,
c,u,t,function(v,x,A){return d5(v,x,A,b)})},a,l)};h5=function(a,b,c,d){b=$CLJS.e2(c,b);if($CLJS.n(b)){var e=$CLJS.bW(c,b);c=$CLJS.Se(function(f){f=$CLJS.O(f);var k=$CLJS.J.g(f,$CLJS.iK),l=$CLJS.J.g(f,$CLJS.G0);return $CLJS.E.g($CLJS.VY,k)&&$CLJS.E.g(d,l)?$CLJS.iZ.h(f):null},$CLJS.n0.j(c,b,e));return $CLJS.n(c)?g5(a,b,a,$CLJS.FF,$CLJS.N,c):a}return a};
j5=function(a,b,c,d,e){var f=$CLJS.OV.h(c),k=$CLJS.bW(a,b);c=$CLJS.QG(function(t){var u=$CLJS.bA(k,t);if($CLJS.n(u)){var v=$CLJS.b5(f);return $CLJS.n($CLJS.Se($CLJS.Qk.j($CLJS.dh([v]),$CLJS.UD,$CLJS.hd),u))?t:null}return null},e5(a,b));var l=(d=$CLJS.E.g($CLJS.dG,d))?$CLJS.OV.h(e):null;e=d?function(t,u,v){return c5(t,u,v,l)}:function(t,u,v){return d5(t,u,v,b)};var m=$CLJS.E.g(new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.tF],null),c);a=d&&m&&$CLJS.E.g($CLJS.z(f),$CLJS.z(l))&&$CLJS.E.g($CLJS.id(f),$CLJS.id(l))?
Bra(a,b,f,$CLJS.fm($CLJS.hd(l),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.RQ,$CLJS.LF],null))):m?Cra(a,b,f):a;return $CLJS.n(c)?i5(a,b,a,c,f,e):a};
Era=function(a,b,c,d){var e=$CLJS.n($CLJS.MD.h($CLJS.aE(c)))?k5:Dra,f=$CLJS.E.g(e,k5)?new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.vP],null):new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.uF],null),k=$CLJS.D_.j(a,b,c);d=$CLJS.E.g(e,k5)?$CLJS.bE(d,$CLJS.Ik,$CLJS.H([$CLJS.ID])):$CLJS.cE(d,function(l){return $CLJS.R.l($CLJS.Ik.g(l,$CLJS.MD),$CLJS.T,k,$CLJS.H([$CLJS.ID,k]))});return $CLJS.nW.l(a,b,c5,$CLJS.H([f,c,d]))};l5=function(a,b){return $CLJS.eY(a,$CLJS.FF)&&$CLJS.E.g($CLJS.i1(a),b)};
Fra=function(a,b,c){return function k(e,f){try{if(l5(f,b))return $CLJS.g3(f,c);throw $CLJS.Y;}catch(m){if(m instanceof Error){var l=m;if(l===$CLJS.Y)return $CLJS.wW(k,e,f);throw l;}throw m;}}($CLJS.Df,a)};
Gra=function(a,b,c){var d=$CLJS.$M.h(a),e=-1<b&&b<$CLJS.D(d)?new $CLJS.P(null,2,5,$CLJS.Q,[b,$CLJS.bA(d,new $CLJS.P(null,2,5,$CLJS.Q,[b,$CLJS.qE],null))],null):null;b=$CLJS.I(e,0,null);e=$CLJS.I(e,1,null);if($CLJS.n($CLJS.n(b)?$CLJS.Ok.g(e,c):b)){var f=$CLJS.eW();$CLJS.c3(f,$CLJS.jf.g($CLJS.qE,d));c=f(c);return Fra($CLJS.sL(a,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.$M,b,$CLJS.qE],null),c),e,c)}return a};
Hra=function(a,b,c){if($CLJS.Fd(c))return c;var d=function(){var e=$CLJS.dh([c]);return"string"===typeof c?$CLJS.Qk.g(e,$CLJS.qE):e}();return $CLJS.Se(function(e){var f=$CLJS.I(e,0,null);e=$CLJS.I(e,1,null);return $CLJS.n(d.h?d.h(e):d.call(null,e))?f:null},$CLJS.em($CLJS.es,$CLJS.$M.h($CLJS.bW(a,b))))};
m5=function(a,b,c,d){b=$CLJS.Gt.g($CLJS.gh($CLJS.n0.j(b,c,$CLJS.bW(b,c))),$CLJS.gh($CLJS.n0.j(a,c,$CLJS.bW(a,c))));return $CLJS.eb(function(e,f){return $CLJS.Te.N(g5,e,c,a,d.h?d.h(f):d.call(null,f))},a,b)};Ira=function(a,b,c){a=m5(a,b,c,function(d){return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.FF,new $CLJS.h(null,1,[$CLJS.QO,$CLJS.yZ.h(d)],null),$CLJS.$i.h(d)],null)});c=$CLJS.e2(a,c);return $CLJS.n(c)?m5(a,b,c,function(d){return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.FF,$CLJS.N,$CLJS.iZ.h(d)],null)}):a};
Jra=function(a,b,c){return $CLJS.Fd(c)?$CLJS.bA($CLJS.bW(a,b),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.$M,c,$CLJS.qE],null)):$CLJS.xd(c)?$CLJS.qE.h(c):c};n5=function(a,b,c,d){var e=Jra(a,b,c);return $CLJS.n(e)?(c=function(){var f=(0,$CLJS.nW)(a,b,function(k){var l=$CLJS.$M.h(k);l=d.g?d.g(l,e):d.call(null,l,e);return $CLJS.$D(k,$CLJS.$M,l)});return(0,$CLJS.nW)(f,b,function(k){return $CLJS.rW(k,$CLJS.$M,function(l){return $CLJS.Sk.g(function(m){return $CLJS.o3(f,b,m)},l)})})}(),Ira(c,a,b)):a};
Kra=function(a,b){return null!=$CLJS.z($CLJS.y($CLJS.mf($CLJS.Xa,function f(d,e){try{if(l5(e,b))return new $CLJS.P(null,1,5,$CLJS.Q,[e],null);throw $CLJS.Y;}catch(l){if(l instanceof Error){var k=l;if(k===$CLJS.Y)return $CLJS.XU(f,d,e);throw k;}throw l;}}($CLJS.Df,a))))};k5=new $CLJS.M(null,"custom-column","custom-column",103523961);Lra=new $CLJS.M(null,"remove","remove",-131428414);Dra=new $CLJS.M(null,"custom-aggregation","custom-aggregation",406285362);var q5;$CLJS.p5=function(){function a(d,e,f){return $CLJS.xd(f)&&$CLJS.E.g($CLJS.Fj.h(f),$CLJS.KK)?$CLJS.o5.j?$CLJS.o5.j(d,e,f):$CLJS.o5.call(null,d,e,f):j5(d,e,f,Lra,null)}function b(d,e){return c.j?c.j(d,-1,e):c.call(null,d,-1,e)}var c=null;c=function(d,e,f){switch(arguments.length){case 2:return b.call(this,d,e);case 3:return a.call(this,d,e,f)}throw Error("Invalid arity: "+arguments.length);};c.g=b;c.j=a;return c}();q5=$CLJS.IE.h($CLJS.sE);
$CLJS.s5=function(){function a(d,e,f,k){if($CLJS.xd(f)&&$CLJS.E.g($CLJS.Fj.h(f),$CLJS.KK))d=$CLJS.r5.v?$CLJS.r5.v(d,e,f,k):$CLJS.r5.call(null,d,e,f,k);else{var l=q5.h?q5.h(f):q5.call(null,f);$CLJS.n(l)&&(l=q5.h?q5.h(k):q5.call(null,k),l=$CLJS.n(l)?$CLJS.E.g($CLJS.D_.j(d,e,k),$CLJS.D_.j(d,e,f))&&$CLJS.VE($CLJS.h0.j(d,e,k),$CLJS.h0.j(d,e,f)):l);d=$CLJS.n(l)?Era(d,e,f,k):j5(d,e,f,$CLJS.dG,k)}return d}function b(d,e,f){return c.v?c.v(d,-1,e,f):c.call(null,d,-1,e,f)}var c=null;c=function(d,e,f,k){switch(arguments.length){case 3:return b.call(this,
d,e,f);case 4:return a.call(this,d,e,f,k)}throw Error("Invalid arity: "+arguments.length);};c.j=b;c.v=a;return c}();$CLJS.Mra=function(){function a(d,e,f,k){f=Hra(d,e,f);return $CLJS.n(f)?$CLJS.nW.l(d,e,Gra,$CLJS.H([f,k])):d}function b(d,e,f){return c.v?c.v(d,-1,e,f):c.call(null,d,-1,e,f)}var c=null;c=function(d,e,f,k){switch(arguments.length){case 3:return b.call(this,d,e,f);case 4:return a.call(this,d,e,f,k)}throw Error("Invalid arity: "+arguments.length);};c.j=b;c.v=a;return c}();
$CLJS.o5=function(){function a(d,e,f){try{return n5(d,e,f,function(u,v){return $CLJS.Ge($CLJS.B0(function(x){return!($CLJS.E.g($CLJS.qE.h(x),v)||Kra(x,v))},u))})}catch(u){var k=u,l=$CLJS.O($CLJS.im(k)),m=$CLJS.J.g(l,$CLJS.aL),t=$CLJS.J.g(l,$CLJS.cy);l=$CLJS.J.g(l,$CLJS.hW);if($CLJS.E.g(t,$CLJS.gW))return d=c.j?c.j(d,m,l):c.call(null,d,m,l),c.j?c.j(d,e,f):c.call(null,d,e,f);throw k;}}function b(d,e){return c.j?c.j(d,-1,e):c.call(null,d,-1,e)}var c=null;c=function(d,e,f){switch(arguments.length){case 2:return b.call(this,
d,e);case 3:return a.call(this,d,e,f)}throw Error("Invalid arity: "+arguments.length);};c.g=b;c.j=a;return c}();
$CLJS.r5=function(){function a(d,e,f,k){return null==k?$CLJS.o5.j(d,e,f):n5(d,e,f,function(l,m){return $CLJS.Sk.g(function(t){return $CLJS.E.g($CLJS.qE.h(t),m)?k:t},l)})}function b(d,e,f){return c.v?c.v(d,-1,e,f):c.call(null,d,-1,e,f)}var c=null;c=function(d,e,f,k){switch(arguments.length){case 3:return b.call(this,d,e,f);case 4:return a.call(this,d,e,f,k)}throw Error("Invalid arity: "+arguments.length);};c.j=b;c.v=a;return c}();