var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./malli.core.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.id.js");require("./metabase.mbql.schema.js");require("./metabase.util.malli.registry.js");
'use strict';var zU,AU,BU,DU,EU,FU,GU,HU,IU,JU;zU=new $CLJS.M("metabase.lib.schema.template-tag","widget-type","metabase.lib.schema.template-tag/widget-type",2021141560);AU=new $CLJS.M("metabase.lib.schema.template-tag","raw-value","metabase.lib.schema.template-tag/raw-value",-530604923);BU=new $CLJS.M("metabase.lib.schema.template-tag","template-tag","metabase.lib.schema.template-tag/template-tag",-1178540449);
$CLJS.CU=new $CLJS.M("metabase.lib.schema.template-tag","template-tag-map","metabase.lib.schema.template-tag/template-tag-map",1471181299);DU=new $CLJS.M("metabase.lib.schema.template-tag","type","metabase.lib.schema.template-tag/type",1926679295);EU=new $CLJS.M("metabase.lib.schema.template-tag","field-filter","metabase.lib.schema.template-tag/field-filter",1376344463);FU=new $CLJS.M("metabase.lib.schema.template-tag","snippet","metabase.lib.schema.template-tag/snippet",190689821);
GU=new $CLJS.M("metabase.lib.schema.template-tag","common","metabase.lib.schema.template-tag/common",1713198790);HU=new $CLJS.M("metabase.lib.schema.template-tag","value.common","metabase.lib.schema.template-tag/value.common",-974864959);IU=new $CLJS.M("metabase.lib.schema.template-tag","raw-value.type","metabase.lib.schema.template-tag/raw-value.type",-805651412);JU=new $CLJS.M("metabase.lib.schema.template-tag","source-query","metabase.lib.schema.template-tag/source-query",479361081);$CLJS.X(zU,$CLJS.fg.g(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Jr,new $CLJS.h(null,1,[$CLJS.Ys,"Valid template tag :widget-type"],null),$CLJS.Fz],null),$CLJS.Yg($CLJS.uU)));$CLJS.X(DU,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.Jr,$CLJS.cM,$CLJS.SM,$CLJS.YE,$CLJS.tt,$CLJS.XA,$CLJS.YO],null));
$CLJS.X(GU,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.ik,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.T,$CLJS.ND],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ID,$CLJS.ND],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.$i,new $CLJS.h(null,1,[$CLJS.fs,!0],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Mr,$CLJS.ND,$CLJS.as],null)],null)],null));
$CLJS.X(HU,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Xs,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,GU],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ik,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.di,new $CLJS.h(null,1,[$CLJS.fs,!0],null),$CLJS.El],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.tN,new $CLJS.h(null,1,[$CLJS.fs,!0],null),$CLJS.bs],null)],null)],null));
$CLJS.X(EU,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Xs,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,HU],null),new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.ik,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.nj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.or,$CLJS.YE],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.YE,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,$CLJS.uE],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.mN,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,zU],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.gj,new $CLJS.h(null,1,[$CLJS.fs,!0],
null),$CLJS.ik],null)],null)],null));
$CLJS.X(FU,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Xs,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,GU],null),new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.ik,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.nj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.or,$CLJS.cM],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.PL,$CLJS.ND],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.pP,new $CLJS.h(null,1,[$CLJS.fs,!0],null),$CLJS.EK],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.qP,new $CLJS.h(null,1,[$CLJS.fs,!0],null),$CLJS.tK],null)],null)],null));
$CLJS.X(JU,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Xs,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,GU],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ik,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.nj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.or,$CLJS.SM],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.yQ,$CLJS.sK],null)],null)],null));$CLJS.X(IU,$CLJS.fg.g(new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.Jr],null),$CLJS.iU));
$CLJS.X(AU,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Xs,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,HU],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ik,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.nj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,IU],null)],null)],null)],null));
$CLJS.X(BU,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Tr,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ik,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.nj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,DU],null)],null)],null),new $CLJS.P(null,6,5,$CLJS.Q,[$CLJS.Sr,new $CLJS.h(null,1,[$CLJS.Pi,$CLJS.nj],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.YE,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,EU],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.cM,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,FU],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.SM,
new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,JU],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Eo,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,AU],null)],null)],null)],null));
$CLJS.X($CLJS.CU,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Tr,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Rr,$CLJS.ND,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,BU],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Si,new $CLJS.h(null,1,[$CLJS.Ys,"keys in template tag map must match the :name of their values"],null),function(a){return $CLJS.Re(function(b){var c=$CLJS.I(b,0,null);b=$CLJS.I(b,1,null);return $CLJS.E.g(c,$CLJS.T.h(b))},a)}],null)],null));