var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.set.js");require("./clojure.string.js");require("./medley.core.js");require("./metabase.lib.metadata.js");require("./metabase.lib.query.js");require("./metabase.lib.schema.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.template_tag.js");require("./metabase.lib.util.js");require("./metabase.shared.util.i18n.js");require("./metabase.util.humanization.js");require("./metabase.util.malli.js");require("./metabase.util.malli.registry.js");
'use strict';var Tta,Uta,Wta,Xta,Yta,Zta;Tta=function(a,b){return $CLJS.hA(function(c){return function(d,e,f){return $CLJS.n(a.h?a.h(e):a.call(null,e))?c.j?c.j(d,e,f):c.call(null,d,e,f):d}},b)};Uta=function(a,b){return $CLJS.gc($CLJS.eb(function(c,d){return $CLJS.ig.j(c,a.h?a.h(d):a.call(null,d),d)},$CLJS.ec($CLJS.N),b))};Wta=function(a){return $CLJS.fg.j($CLJS.ch,$CLJS.Qk.g($CLJS.CD(function(b){return $CLJS.qW(b,a)}),$CLJS.jf.h($CLJS.hd)),Vta)};
Xta=function(a){return new $CLJS.h(null,3,[$CLJS.nj,$CLJS.XA,$CLJS.T,a,$CLJS.$i,$CLJS.p.h($CLJS.dE())],null)};
Yta=function(a){a=$CLJS.O(a);var b=$CLJS.J.g(a,$CLJS.T);return $CLJS.hl.l($CLJS.H([a,function(){var c=$CLJS.oh(/^#(\d+)(-[a-z0-9-]*)?$/,b);$CLJS.n(c)?($CLJS.I(c,0,null),c=$CLJS.I(c,1,null),c=$CLJS.QV(c)):c=null;return $CLJS.n(c)?new $CLJS.h(null,2,[$CLJS.nj,$CLJS.SM,$CLJS.yQ,c],null):null}(),function(){var c=0==b.lastIndexOf("snippet:",0)?(0,$CLJS.ma)(b.substring(8)):null;return $CLJS.n(c)?new $CLJS.h(null,2,[$CLJS.nj,$CLJS.cM,$CLJS.PL,c],null):null}(),$CLJS.n($CLJS.ID.h(a))?null:new $CLJS.h(null,
1,[$CLJS.ID,$CLJS.g1.g($CLJS.f1,b)],null)]))};$CLJS.z8=function(a){a=$CLJS.Z0(a);return $CLJS.n($CLJS.bA(a,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Gz,Zta],null)))?$CLJS.ce.g($CLJS.ch,$CLJS.IN):$CLJS.ch};$CLJS.A8=function(a,b){var c=$CLJS.z8(a);return(0,$CLJS.nW)(a,0,function(d){var e=$CLJS.Gt.g($ta,c);d=$CLJS.Te.j($CLJS.Ik,d,e);d=$CLJS.hl.l($CLJS.H([d,$CLJS.fm(b,c)]));$CLJS.Gt.g(c,$CLJS.gh($CLJS.Yg(b)));$CLJS.bW(a,0);return d})};$CLJS.B8=function(a){return $CLJS.Ge($CLJS.fm($CLJS.bW(a,0),$CLJS.z8(a)))};
$CLJS.C8=function(a){return $CLJS.FP.h($CLJS.bW(a,0))};Zta=new $CLJS.M(null,"native-requires-specified-collection","native-requires-specified-collection",1239149925);$CLJS.aua=new $CLJS.M(null,"write","write",-1857649168);$CLJS.bua=new $CLJS.M(null,"native-permissions","native-permissions",1277672164);var Vta,$ta;Vta=new $CLJS.P(null,3,5,$CLJS.Q,[/\{\{\s*([A-Za-z0-9_\.]+)\s*\}\}/,/\{\{\s*(snippet:\s*[^}]+)\s*\}\}/,/\{\{\s*(#([0-9]*)(-[a-z0-9-]*)?)\s*\}\}/],null);
$CLJS.D8=function(){function a(d,e){d=$CLJS.Ge(Wta(d));var f=$CLJS.Ge($CLJS.gh($CLJS.Yg(e)));if($CLJS.n($CLJS.n(d)?d:f)){var k=$CLJS.Gt.g(d,f);d=$CLJS.Gt.g(f,d);if($CLJS.E.l(1,$CLJS.D(k),$CLJS.H([$CLJS.D(d)]))){d=$CLJS.z(d);k=$CLJS.z(k);f=$CLJS.J.g(e,d);var l=$CLJS.E.g($CLJS.ID.h(f),$CLJS.g1.g($CLJS.f1,d))?$CLJS.g1.g($CLJS.f1,k):$CLJS.ID.h(f);f=$CLJS.R.l($CLJS.Ik.l(f,$CLJS.PL,$CLJS.H([$CLJS.yQ,$CLJS.pP])),$CLJS.ID,l,$CLJS.H([$CLJS.T,k]));e=$CLJS.R.j($CLJS.Ik.g(e,d),k,f)}else e=$CLJS.hl.l($CLJS.H([Tta($CLJS.Ue(d),
e),Uta($CLJS.T,$CLJS.jf.g(Xta,k))]));e=$CLJS.Ls(e,Yta)}else e=$CLJS.N;return e}function b(d){return $CLJS.D8.g?$CLJS.D8.g(d,null):$CLJS.D8.call(null,d,null)}var c=null;c=function(d,e){switch(arguments.length){case 1:return b.call(this,d);case 2:return a.call(this,d,e)}throw Error("Invalid arity: "+arguments.length);};c.h=b;c.g=a;return c}();$ta=new $CLJS.bh(null,new $CLJS.h(null,1,[$CLJS.IN,null],null),null);
$CLJS.X(new $CLJS.M("metabase.lib.native","native-extras","metabase.lib.native/native-extras",1125545077),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ik,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.IN,new $CLJS.h(null,1,[$CLJS.fs,!0],null),$CLJS.ND],null)],null));
$CLJS.E8=function(){function a(d,e,f,k){var l=$CLJS.D8.h(e);return $CLJS.A8($CLJS.b1.g(d,new $CLJS.P(null,1,5,$CLJS.Q,[new $CLJS.h(null,4,[$CLJS.Fj,$CLJS.yV,$CLJS.KU,f,$CLJS.bQ,l,$CLJS.FP,e],null)],null)),k)}function b(d,e){return $CLJS.E8.v?$CLJS.E8.v(d,e,null,null):$CLJS.E8.call(null,d,e,null,null)}var c=null;c=function(d,e,f,k){switch(arguments.length){case 2:return b.call(this,d,e);case 4:return a.call(this,d,e,f,k)}throw Error("Invalid arity: "+arguments.length);};c.g=b;c.v=a;return c}();
$CLJS.F8=function(){function a(d,e,f){$CLJS.bW(d,0);return $CLJS.A8($CLJS.b1.g(e,$CLJS.PK.h(d)),f)}function b(d,e){return $CLJS.F8.j?$CLJS.F8.j(d,e,null):$CLJS.F8.call(null,d,e,null)}var c=null;c=function(d,e,f){switch(arguments.length){case 2:return b.call(this,d,e);case 3:return a.call(this,d,e,f)}throw Error("Invalid arity: "+arguments.length);};c.g=b;c.j=a;return c}();$CLJS.a1.m(null,$CLJS.yV,function(a){return $CLJS.U0($CLJS.z8(a),$CLJS.gh($CLJS.Yg($CLJS.B8(a))))&&!$CLJS.gA($CLJS.C8(a))});