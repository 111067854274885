var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.set.js");require("./clojure.walk.js");require("./medley.core.js");require("./metabase.mbql.util.js");require("./metabase.mbql.util.match.js");require("./metabase.shared.util.i18n.js");require("./metabase.util.log.js");require("./metabase.util.malli.js");
'use strict';var tW,ama,vW,xW,yW,bma,AW,BW,sW,CW,cma,dma,ema,fma,FW,GW,IW,JW,KW,LW,MW,NW,uW,hma,ima,QW,jma,RW,kma,TW,lma,mma,nma,oma,pma,pW;$CLJS.qW=function(a,b){if("string"===typeof b)return pW(a,b);throw new TypeError("re-seq must match against a string.");};$CLJS.rW=function(a,b,c){var d=$CLJS.gm(a,b);if($CLJS.n(d)){var e=$CLJS.R.j;d=$CLJS.Fb(d);c=c.h?c.h(d):c.call(null,d);a=e.call($CLJS.R,a,b,c)}return a};
tW=function(a){var b=sW;return $CLJS.hA(function(c){return function(d,e,f){e=b.h?b.h(e):b.call(null,e);return c.j?c.j(d,e,f):c.call(null,d,e,f)}},a)};ama=function(a){var b=uW;return $CLJS.hA(function(c){return function(d,e,f){f=b.h?b.h(f):b.call(null,f);return c.j?c.j(d,e,f):c.call(null,d,e,f)}},a)};vW=function(a){if($CLJS.md(a))return a;throw $CLJS.ii("Invalid pattern: don't know how to handle symbol.",new $CLJS.h(null,1,[$CLJS.Ii,a],null));};
$CLJS.wW=function(a,b,c){return $CLJS.xd(c)?$CLJS.fg.g(c,function(){return function f(e){return new $CLJS.oe(null,function(){for(;;){var k=$CLJS.y(e);if(k){if($CLJS.Ad(k)){var l=$CLJS.lc(k),m=$CLJS.D(l),t=$CLJS.re(m);return function(){for(var A=0;;)if(A<m){var C=$CLJS.kd(l,A),G=$CLJS.I(C,0,null),K=$CLJS.I(C,1,null);C=t;var S=$CLJS.Q,W=G;G=$CLJS.ce.g(b,G);G=a.g?a.g(G,K):a.call(null,G,K);C.add(new $CLJS.P(null,2,5,S,[W,G],null));A+=1}else return!0}()?$CLJS.ue($CLJS.we(t),f($CLJS.mc(k))):$CLJS.ue($CLJS.we(t),
null)}var u=$CLJS.z(k),v=$CLJS.I(u,0,null),x=$CLJS.I(u,1,null);return $CLJS.fe(new $CLJS.P(null,2,5,$CLJS.Q,[v,function(){var A=$CLJS.ce.g(b,v),C=x;return a.g?a.g(A,C):a.call(null,A,C)}()],null),f($CLJS.Lc(k)))}return null}},null,null)}(c)}()):$CLJS.wd(c)?$CLJS.Sk.g($CLJS.We(a,$CLJS.z(c)instanceof $CLJS.M?$CLJS.ce.g(b,$CLJS.z(c)):b),c):c};xW=function(a){var b=(b=a instanceof $CLJS.M)?$CLJS.je(a):b;return $CLJS.n(b)?[$CLJS.je(a),"/",$CLJS.hh(a)].join(""):$CLJS.hh(a)};
yW=function(a,b){var c=$CLJS.wd(b)&&!$CLJS.Af(b)&&$CLJS.z(b)instanceof $CLJS.M;return c?$CLJS.ud(a)?(b=$CLJS.z(b),a=$CLJS.gh(a),a.h?a.h(b):a.call(null,b)):$CLJS.E.g(a,$CLJS.z(b)):c};
bma=function(a,b){return $CLJS.kf(function(c){var d=$CLJS.mf;a:try{if($CLJS.zd(c)&&1<=$CLJS.D(c))try{var e=$CLJS.Xk.j(c,0,1);if($CLJS.zd(e)&&1===$CLJS.D(e))try{var f=$CLJS.F(e,0);if($CLJS.n($CLJS.We($CLJS.E,a)(f))){var k=$CLJS.Xk.g(c,1);var l=new $CLJS.P(null,1,5,$CLJS.Q,[k],null)}else throw $CLJS.Y;}catch(t){if(t instanceof Error){var m=t;if(m===$CLJS.Y)throw $CLJS.Y;throw m;}throw t;}else throw $CLJS.Y;}catch(t){if(t instanceof Error){m=t;if(m===$CLJS.Y)throw $CLJS.Y;throw m;}throw t;}else throw $CLJS.Y;
}catch(t){if(t instanceof Error){m=t;if(m===$CLJS.Y){l=new $CLJS.P(null,1,5,$CLJS.Q,[new $CLJS.P(null,1,5,$CLJS.Q,[c],null)],null);break a}throw m;}throw t;}return $CLJS.z($CLJS.y(d($CLJS.Xa,l)))},$CLJS.H([b]))};
AW=function(a,b){for(;;)switch(b=$CLJS.jl.h($CLJS.mf($CLJS.Xa,b)),$CLJS.D(b)){case 0:return null;case 1:return a=$CLJS.z(b),zW.h?zW.h(a):zW.call(null,a);default:if($CLJS.n($CLJS.Se($CLJS.We(yW,a),b))){var c=a;b=bma(a,b);a=c}else{c=$CLJS.jf.g(zW,b);if($CLJS.E.g(c,b))return $CLJS.fg.g(new $CLJS.P(null,1,5,$CLJS.Q,[a],null),b);b=c}}};BW=function(a){var b=$CLJS.wd(a);return b?(b=!$CLJS.Af(a))?$CLJS.$z($CLJS.he,$CLJS.Za)($CLJS.z(a)):b:b};
sW=function(a){return $CLJS.n($CLJS.$z($CLJS.he,$CLJS.Za)(a))?$CLJS.Ah.h($CLJS.fA(xW(a).toLowerCase(),/_/,"-")):a};CW=function(a,b){var c=BW(b);return $CLJS.n(c)?(b=sW($CLJS.z(b)),$CLJS.ud(a)?(a=$CLJS.gh(a),a.h?a.h(b):a.call(null,b)):$CLJS.E.g(a,b)):c};cma=function(a){return $CLJS.J.j(new $CLJS.h(null,3,[$CLJS.di,$CLJS.Td,$CLJS.nj,sW,$CLJS.mN,sW],null),a,function(b){var c=$CLJS.J,d=c.g;b=$CLJS.Fe([a,b]);b=DW.g?DW.g(b,EW):DW.call(null,b,EW);return d.call(c,b,a)})};
dma=function(a){a=$CLJS.fg.j($CLJS.N,$CLJS.jf.h(function(b){var c=$CLJS.I(b,0,null);b=$CLJS.I(b,1,null);c=sW(c);var d=cma(c);return new $CLJS.P(null,2,5,$CLJS.Q,[c,d.h?d.h(b):d.call(null,b)],null)}),a);return $CLJS.E.g($CLJS.nj.h(a),$CLJS.YE)&&$CLJS.Ua($CLJS.mN.h(a))?$CLJS.R.j(a,$CLJS.mN,$CLJS.ZF):a};ema=function(a){return $CLJS.fg.j($CLJS.N,$CLJS.jf.h(function(b){var c=$CLJS.I(b,0,null);b=$CLJS.I(b,1,null);c=xW(c);return new $CLJS.P(null,2,5,$CLJS.Q,[c,$CLJS.R.j(dma(b),$CLJS.T,c)],null)}),a)};
fma=function(a){a=DW.h?DW.h(a):DW.call(null,a);return uW.h?uW.h(a):uW.call(null,a)};FW=function(a){return $CLJS.rW($CLJS.rW($CLJS.eb(function(b,c){return $CLJS.rW(b,c,$CLJS.Ah)},a,new $CLJS.P(null,6,5,$CLJS.Q,[$CLJS.zC,$CLJS.wfa,$CLJS.$N,gma,$CLJS.Az,$CLJS.ti],null)),$CLJS.yB,fma),$CLJS.oN,$CLJS.SU)};GW=function(a){return $CLJS.Fd(a)?new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.FF,a,null],null):a};IW=function(a){return HW.h(GW(a))};
JW=function(a){var b=$CLJS.y(a);a=$CLJS.z(b);b=$CLJS.B(b);return zW($CLJS.fg.g(new $CLJS.P(null,1,5,$CLJS.Q,[a],null),$CLJS.jf.g(HW,b)))};KW=function(a){var b=$CLJS.y(a);a=$CLJS.z(b);var c=$CLJS.B(b);b=$CLJS.z(c);c=$CLJS.B(c);return $CLJS.fg.g(new $CLJS.P(null,2,5,$CLJS.Q,[a,IW(b)],null),$CLJS.jf.g(HW,c))};LW=function(a){var b=$CLJS.I(a,0,null);a=$CLJS.I(a,1,null);return $CLJS.n(a)?new $CLJS.P(null,2,5,$CLJS.Q,[b,IW(a)],null):new $CLJS.P(null,1,5,$CLJS.Q,[b],null)};
MW=function(a){var b=$CLJS.I(a,0,null);a=$CLJS.I(a,1,null);return new $CLJS.P(null,2,5,$CLJS.Q,[b,IW(a)],null)};NW=function(a){var b=$CLJS.I(a,0,null);a=$CLJS.I(a,1,null);return new $CLJS.P(null,2,5,$CLJS.Q,[b,HW.h(a)],null)};
uW=function(a){return OW(function(b){if($CLJS.xd(b))return ama(b);if($CLJS.Ua(BW(b)))return b;try{return HW.h(b)}catch(f){if(f instanceof Error){var c=f,d=$CLJS.lA($CLJS.cy);if($CLJS.n($CLJS.kA("metabase.mbql.normalize",d))){var e=$CLJS.eE("Invalid clause:");e instanceof Error?$CLJS.jA("metabase.mbql.normalize",d,$CLJS.aw.l($CLJS.H([b])),e):$CLJS.jA("metabase.mbql.normalize",d,$CLJS.aw.l($CLJS.H([e,b])),null)}throw $CLJS.hi($CLJS.DD("Invalid MBQL clause: {0}",$CLJS.H([$CLJS.PU(c)])),new $CLJS.h(null,
1,[$CLJS.vR,b],null),c);}throw f;}},a)};hma=function(a){return $CLJS.Ef($CLJS.dm(uW,PW(a)))};ima=function(a){for(;;)if($CLJS.n(BW(a)))a=new $CLJS.P(null,1,5,$CLJS.Q,[a],null);else return $CLJS.Ge($CLJS.Sk.g(GW,a))};QW=function(a){a=$CLJS.O(a);var b=$CLJS.J.g(a,$CLJS.YE);return $CLJS.n(b)?$CLJS.Tk.j(a,$CLJS.YE,HW):a};
jma=function(a){return $CLJS.fg.g($CLJS.N,function(){return function d(c){return new $CLJS.oe(null,function(){for(;;){var e=$CLJS.y(c);if(e){if($CLJS.Ad(e)){var f=$CLJS.lc(e),k=$CLJS.D(f),l=$CLJS.re(k);a:for(var m=0;;)if(m<k){var t=$CLJS.kd(f,m),u=$CLJS.I(t,0,null);t=$CLJS.I(t,1,null);u=new $CLJS.P(null,2,5,$CLJS.Q,[u,QW(t)],null);l.add(u);m+=1}else{f=!0;break a}return f?$CLJS.ue($CLJS.we(l),d($CLJS.mc(e))):$CLJS.ue($CLJS.we(l),null)}f=$CLJS.z(e);l=$CLJS.I(f,0,null);f=$CLJS.I(f,1,null);return $CLJS.fe(new $CLJS.P(null,
2,5,$CLJS.Q,[l,QW(f)],null),d($CLJS.Lc(e)))}return null}},null,null)}(a)}())};RW=function(a){a=$CLJS.O(a);var b=$CLJS.J.g(a,$CLJS.bQ);return $CLJS.n(b)?$CLJS.Tk.j(a,$CLJS.bQ,jma):a};kma=function(a){var b=$CLJS.O(a);a=$CLJS.J.g(b,$CLJS.FP);b=$CLJS.Ua(a)?SW.h?SW.h(b):SW.call(null,b):b;return $CLJS.n(a)?RW(b):b};TW=function(a){return $CLJS.ud(a)?$CLJS.y(a):null!=a};
lma=function(a){var b=$CLJS.O(a);a=$CLJS.J.g(b,$CLJS.aF);var c=$CLJS.J.g(b,$CLJS.RM),d=$CLJS.J.g(b,$CLJS.nO),e=$CLJS.J.g(b,$CLJS.FP);try{if($CLJS.n(d)){var f=$CLJS.O(b),k=$CLJS.J.g(f,$CLJS.nO);var l=$CLJS.sL($CLJS.Ik.g(f,$CLJS.nO),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.aF,$CLJS.nO],null),k)}else l=b;var m=$CLJS.n(a)?$CLJS.Tk.j(l,$CLJS.aF,SW):l,t=$CLJS.n(c)?$CLJS.Tk.j(m,$CLJS.RM,$CLJS.We($CLJS.Sk,uW)):m,u=$CLJS.n(e)?$CLJS.Tk.j(t,$CLJS.FP,RW):t;return uW(u)}catch(v){if(v instanceof Error)throw m=v,$CLJS.hi($CLJS.DD("Error canonicalizing query: {0}",
$CLJS.H([$CLJS.PU(m)])),new $CLJS.h(null,1,[$CLJS.aF,a],null),m);throw v;}};
mma=function(a){a=$CLJS.O(a);var b=$CLJS.J.g(a,$CLJS.aF),c=$CLJS.O(b);b=$CLJS.J.g(c,$CLJS.tF);c=$CLJS.J.g(c,$CLJS.mE);return $CLJS.y(b)&&$CLJS.y(c)?(b=$CLJS.fg.j($CLJS.ch,$CLJS.LB,$CLJS.y($CLJS.mf($CLJS.Xa,function k(e,f){try{if($CLJS.zd(f)&&3===$CLJS.D(f))try{var l=$CLJS.F(f,0);if($CLJS.ie(l,$CLJS.FF)){var m=$CLJS.F(f,1),t=$CLJS.F(f,2);return new $CLJS.P(null,1,5,$CLJS.Q,[new $CLJS.P(null,2,5,$CLJS.Q,[f,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.FF,m,$CLJS.Ik.g(t,$CLJS.LF)],null)],null)],null)}throw $CLJS.Y;
}catch(u){if(u instanceof Error){l=u;if(l===$CLJS.Y)throw $CLJS.Y;throw l;}throw u;}else throw $CLJS.Y;}catch(u){if(u instanceof Error){l=u;if(l===$CLJS.Y)return $CLJS.XU(k,e,f);throw l;}throw u;}}($CLJS.Df,b)))),$CLJS.UW.j(a,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.aF,$CLJS.mE],null),$CLJS.Qk.g($CLJS.Ef,$CLJS.We($CLJS.Rk,b)))):a};
nma=function(a){try{return mma(a)}catch(c){if(c instanceof Error){var b=c;throw $CLJS.hi($CLJS.eE("Error performing whole query transformations"),new $CLJS.h(null,1,[$CLJS.aF,a],null),b);}throw c;}};
oma=function(a,b){var c=$CLJS.fg.g($CLJS.jd(a),function(){return function f(e){return new $CLJS.oe(null,function(){for(var k=e;;)if(k=$CLJS.y(k)){if($CLJS.Ad(k)){var l=$CLJS.lc(k),m=$CLJS.D(l),t=$CLJS.re(m);return function(){for(var A=0;;)if(A<m){var C=$CLJS.kd(l,A),G=$CLJS.I(C,0,null);C=$CLJS.I(C,1,null);var K=$CLJS.ce.g(b,G);C=VW.g?VW.g(C,K):VW.call(null,C,K);null!=C&&t.add(new $CLJS.P(null,2,5,$CLJS.Q,[G,C],null));A+=1}else return!0}()?$CLJS.ue($CLJS.we(t),f($CLJS.mc(k))):$CLJS.ue($CLJS.we(t),
null)}var u=$CLJS.z(k),v=$CLJS.I(u,0,null),x=$CLJS.I(u,1,null);u=function(){var A=x,C=$CLJS.ce.g(b,v);return VW.g?VW.g(A,C):VW.call(null,A,C)}();if(null!=u)return $CLJS.fe(new $CLJS.P(null,2,5,$CLJS.Q,[v,u],null),f($CLJS.Lc(k)));k=$CLJS.Lc(k)}else return null},null,null)}(a)}());return $CLJS.y(c)?c:null};pma=function(a,b){a=$CLJS.Sk.g(function(c){var d=$CLJS.ce.g(b,WW);return VW.g?VW.g(c,d):VW.call(null,c,d)},a);return $CLJS.n($CLJS.Se($CLJS.Xa,a))?a:null};
pW=function pW(a,b){var d=a.exec(b);if(null==d)return null;var e=d[0],f=1===d.length?e:$CLJS.Ef(d);return $CLJS.fe(f,new $CLJS.oe(null,function(){var k=e.length;k=d.index+(1>k?1:k);return k<=b.length?(k=b.substring(k),pW.g?pW.g(a,k):pW.call(null,a,k)):null},null,null))};
$CLJS.UW=function UW(a){switch(arguments.length){case 3:return UW.j(arguments[0],arguments[1],arguments[2]);case 4:return UW.v(arguments[0],arguments[1],arguments[2],arguments[3]);case 5:return UW.N(arguments[0],arguments[1],arguments[2],arguments[3],arguments[4]);case 6:return UW.W(arguments[0],arguments[1],arguments[2],arguments[3],arguments[4],arguments[5]);default:for(var c=[],d=arguments.length,e=0;;)if(e<d)c.push(arguments[e]),e+=1;else break;return UW.l(arguments[0],arguments[1],arguments[2],
arguments[3],arguments[4],arguments[5],new $CLJS.w(c.slice(6),0,null))}};$CLJS.UW.j=function(a,b,c){var d=$CLJS.y(b);b=$CLJS.z(d);if(d=$CLJS.B(d))a=$CLJS.R.j(a,b,$CLJS.UW.j($CLJS.J.g(a,b),d,c));else{d=$CLJS.R.j;var e=$CLJS.J.g(a,b);c=c.h?c.h(e):c.call(null,e);a=d.call($CLJS.R,a,b,c)}return a};
$CLJS.UW.v=function(a,b,c,d){var e=$CLJS.y(b);b=$CLJS.z(e);if(e=$CLJS.B(e))a=$CLJS.R.j(a,b,$CLJS.UW.v($CLJS.J.g(a,b),e,c,d));else{e=$CLJS.R.j;var f=$CLJS.J.g(a,b);c=c.g?c.g(f,d):c.call(null,f,d);a=e.call($CLJS.R,a,b,c)}return a};$CLJS.UW.N=function(a,b,c,d,e){var f=$CLJS.y(b);b=$CLJS.z(f);if(f=$CLJS.B(f))a=$CLJS.R.j(a,b,$CLJS.UW.N($CLJS.J.g(a,b),f,c,d,e));else{f=$CLJS.R.j;var k=$CLJS.J.g(a,b);c=c.j?c.j(k,d,e):c.call(null,k,d,e);a=f.call($CLJS.R,a,b,c)}return a};
$CLJS.UW.W=function(a,b,c,d,e,f){var k=$CLJS.y(b);b=$CLJS.z(k);if(k=$CLJS.B(k))a=$CLJS.R.j(a,b,$CLJS.UW.W($CLJS.J.g(a,b),k,c,d,e,f));else{k=$CLJS.R.j;var l=$CLJS.J.g(a,b);c=c.v?c.v(l,d,e,f):c.call(null,l,d,e,f);a=k.call($CLJS.R,a,b,c)}return a};$CLJS.UW.l=function(a,b,c,d,e,f,k){var l=$CLJS.y(b);b=$CLJS.z(l);return(l=$CLJS.B(l))?$CLJS.R.j(a,b,$CLJS.Te.l($CLJS.UW,$CLJS.J.g(a,b),l,c,d,$CLJS.H([e,f,k]))):$CLJS.R.j(a,b,$CLJS.Te.l(c,$CLJS.J.g(a,b),d,e,f,$CLJS.H([k])))};
$CLJS.UW.B=function(a){var b=$CLJS.z(a),c=$CLJS.B(a);a=$CLJS.z(c);var d=$CLJS.B(c);c=$CLJS.z(d);var e=$CLJS.B(d);d=$CLJS.z(e);var f=$CLJS.B(e);e=$CLJS.z(f);var k=$CLJS.B(f);f=$CLJS.z(k);k=$CLJS.B(k);return this.l(b,a,c,d,e,f,k)};$CLJS.UW.A=6;
var OW=function OW(a,b){return $CLJS.QU($CLJS.We(OW,a),$CLJS.Td,a.h?a.h(b):a.call(null,b))},zW=function zW(a){for(;;){if($CLJS.xd(a))return $CLJS.Ls(a,zW);if($CLJS.Dd(a))a=$CLJS.Ef(a);else if($CLJS.zd(a)){if($CLJS.Ua($CLJS.Se($CLJS.Xa,a)))return null;var c=a,d=$CLJS.y(c),e=$CLJS.z(d),f=$CLJS.B(d),k=e,l=f,m=$CLJS.I(l,0,null),t=l,u=k,v=u instanceof $CLJS.M?u.S:null;switch(v){case "not":if($CLJS.Nl(m)){var x=$CLJS.z(m),A=x instanceof $CLJS.M?x.S:null;switch(A){case "not":a=$CLJS.hd(m);continue;case "and":return AW($CLJS.Mr,
$CLJS.jf.g(function(){return function(C){return new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Or,C],null)}}(a,x,A,u,v,c,d,e,f,k,l,m,t),$CLJS.Lc(m)));case "or":return AW($CLJS.Tr,$CLJS.jf.g(function(){return function(C){return new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Or,C],null)}}(a,x,A,u,v,c,d,e,f,k,l,m,t),$CLJS.Lc(m)));default:return a}}else return a;case "and":return AW($CLJS.Tr,t);case "or":return AW($CLJS.Mr,t);default:return $CLJS.Sk.g(zW,a)}}else return a}},qma=new $CLJS.M(null,"value_field","value_field",
-980977878),XW=new $CLJS.M(null,"ascending","ascending",-988350486),YW=new $CLJS.M(null,"named","named",-422393479),ZW=new $CLJS.M(null,"values_source_config","values_source_config",-590570309),$W=new $CLJS.M(null,"descending","descending",-24766135),aX=new $CLJS.M(null,"datetime-field","datetime-field",21731696),rma=new $CLJS.M(null,"use-as-display-name?","use-as-display-name?",686752941),bX=new $CLJS.M(null,"field-literal","field-literal",-1295883554),cX=new $CLJS.M(null,"viz-settings","viz-settings",
256055379),sma=new $CLJS.M(null,"rows","rows",850049680),tma=new $CLJS.M(null,"special-fn","special-fn",1290649344),WW=new $CLJS.M("metabase.mbql.normalize","sequence","metabase.mbql.normalize/sequence",-1700645683),EW=new $CLJS.M(null,"ignore-path","ignore-path",944069061),uma=new $CLJS.M(null,"label_field","label_field",-1573182765),vma=new $CLJS.M(null,"fk-\x3e","fk-\x3e",-499026738),wma=new $CLJS.M(null,"joined-field","joined-field",-2048778268),gma=new $CLJS.M(null,"visibility_type","visibility_type",
-508434247);var dX,xma=$CLJS.Ye($CLJS.N),yma=$CLJS.Ye($CLJS.N),zma=$CLJS.Ye($CLJS.N),Ama=$CLJS.Ye($CLJS.N),Bma=$CLJS.J.j($CLJS.N,$CLJS.ij,$CLJS.Wh.o?$CLJS.Wh.o():$CLJS.Wh.call(null));dX=new $CLJS.ei($CLJS.Gh.g("metabase.mbql.normalize","normalize-mbql-clause-tokens"),$CLJS.Qk.g(sW,$CLJS.z),Bma,xma,yma,zma,Ama);dX.m(null,$CLJS.uF,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);b=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.uF,b],null);return null!=a?$CLJS.ce.g(b,a):b});
dX.m(null,$CLJS.QA,function(a){$CLJS.I(a,0,null);a=$CLJS.I(a,1,null);return new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.QA,a instanceof $CLJS.M?xW(a):a],null)});dX.m(null,$CLJS.ZU,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null),c=$CLJS.I(a,2,null);a=$CLJS.I(a,3,null);return $CLJS.n(a)?$CLJS.ce.g(dX.h(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ZU,b,c],null)),a):new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ZU,DW.g?DW.g(b,EW):DW.call(null,b,EW),sW(c)],null)});
dX.m(null,$CLJS.FF,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);var c=DW.g?DW.g(a,EW):DW.call(null,a,EW);return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.FF,b,function(){var d=$CLJS.n($CLJS.TA.h(c))?$CLJS.Tk.j(c,$CLJS.TA,$CLJS.Ah):c;d=$CLJS.n($CLJS.LF.h(c))?$CLJS.Tk.j(d,$CLJS.LF,$CLJS.Ah):d;return $CLJS.n($CLJS.RQ.h(c))?$CLJS.Tk.j(d,$CLJS.RQ,function(e){return $CLJS.n($CLJS.YD.h(e))?$CLJS.Tk.j(e,$CLJS.YD,$CLJS.Ah):e}):d}()],null)});
dX.m(null,bX,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);return new $CLJS.P(null,3,5,$CLJS.Q,[bX,b instanceof $CLJS.M?xW(b):b,$CLJS.Ah.h(a)],null)});dX.m(null,aX,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null),c=$CLJS.I(a,2,null);a=$CLJS.I(a,3,null);return $CLJS.n(a)?new $CLJS.P(null,4,5,$CLJS.Q,[aX,DW.g?DW.g(b,EW):DW.call(null,b,EW),$CLJS.Bs,sW(a)],null):new $CLJS.P(null,3,5,$CLJS.Q,[aX,DW.g?DW.g(b,EW):DW.call(null,b,EW),sW(c)],null)});
dX.m(null,$CLJS.RF,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null),c=$CLJS.I(a,2,null),d=$CLJS.I(a,3,null);a=$CLJS.I(a,4,null);return $CLJS.n(a)?$CLJS.ce.g(dX.h(new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.RF,b,c,d],null)),DW.g?DW.g(a,EW):DW.call(null,a,EW)):new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.RF,DW.g?DW.g(b,EW):DW.call(null,b,EW),$CLJS.Fd(c)?c:sW(c),sW(d)],null)});
dX.m(null,$CLJS.vI,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);return $CLJS.n(a)?new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.vI,b,sW(a)],null):new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.vI,$CLJS.tv],null)});dX.m(null,$CLJS.tI,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.tI,b,sW(a)],null)});
dX.m(null,$CLJS.DI,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null),c=$CLJS.I(a,2,null);a=$CLJS.I(a,3,null);return new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.DI,DW.g?DW.g(b,EW):DW.call(null,b,EW),c,sW(a)],null)});dX.m(null,$CLJS.JI,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null),c=$CLJS.I(a,2,null);a=$CLJS.I(a,3,null);return new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.JI,DW.g?DW.g(b,EW):DW.call(null,b,EW),c,sW(a)],null)});
dX.m(null,$CLJS.BI,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);return $CLJS.n(a)?new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.BI,DW.g?DW.g(b,EW):DW.call(null,b,EW),sW(a)],null):new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.BI,DW.g?DW.g(b,EW):DW.call(null,b,EW)],null)});
dX.m(null,$CLJS.HI,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null),c=$CLJS.I(a,2,null);a=$CLJS.I(a,3,null);return $CLJS.n(a)?new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.HI,DW.g?DW.g(b,EW):DW.call(null,b,EW),sW(c),sW(a)],null):new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.HI,DW.g?DW.g(b,EW):DW.call(null,b,EW),sW(c)],null)});
dX.m(null,$CLJS.sI,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null),c=$CLJS.I(a,2,null);a=$CLJS.I(a,3,null);return new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.sI,DW.g?DW.g(b,EW):DW.call(null,b,EW),DW.g?DW.g(c,EW):DW.call(null,c,EW),sW(a)],null)});dX.m(null,$CLJS.Ej,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Ej,b,a],null)});
dX.m(null,$CLJS.di,function(a){var b=$CLJS.y(a);a=$CLJS.z(b);b=$CLJS.B(b);return $CLJS.fg.j(new $CLJS.P(null,1,5,$CLJS.Q,[sW(a)],null),$CLJS.jf.h(function(c){return DW.g?DW.g(c,EW):DW.call(null,c,EW)}),b)});
var eX=function eX(a){if($CLJS.n($CLJS.$z($CLJS.he,$CLJS.Za)(a))){var c=sW(a);var d=new $CLJS.bh(null,new $CLJS.h(null,18,[$CLJS.Rn,null,$CLJS.rr,null,$CLJS.VF,null,$CLJS.OF,null,$CLJS.Ds,null,$CLJS.GF,null,$CLJS.$F,null,$CLJS.aG,null,$CLJS.jF,null,$CLJS.qF,null,$CLJS.qr,null,$CLJS.kF,null,$CLJS.TF,null,$CLJS.MF,null,$CLJS.jk,null,$CLJS.rx,null,$CLJS.zF,null,$CLJS.SF,null],null),null);c=d.h?d.h(c):d.call(null,c)}else c=null;return $CLJS.n(c)?c:$CLJS.n(BW(a))?(a=$CLJS.z(a),eX.h?eX.h(a):eX.call(null,
a)):null},Cma=new $CLJS.h(null,8,[$CLJS.nj,sW,$CLJS.FP,function(a){a=tW(a);return $CLJS.y($CLJS.bQ.h(a))?$CLJS.Tk.j(a,$CLJS.bQ,ema):a},$CLJS.aF,new $CLJS.h(null,6,[$CLJS.uF,function fX(a){if($CLJS.n($CLJS.$z($CLJS.he,$CLJS.Za)(a)))return sW(a);if($CLJS.n(CW(YW,a))){a=$CLJS.y(a);$CLJS.z(a);var c=$CLJS.B(a);a=$CLJS.z(c);c=$CLJS.B(c);return $CLJS.fg.g(new $CLJS.P(null,2,5,$CLJS.Q,[YW,fX.h?fX.h(a):fX.call(null,a)],null),c)}return $CLJS.n($CLJS.n(BW(a))?eX($CLJS.hd(a)):null)?$CLJS.Sk.g(fX,a):DW.g?DW.g(a,
EW):DW.call(null,a,EW)},$CLJS.vP,function(a){return $CLJS.fg.g($CLJS.N,function(){return function d(c){return new $CLJS.oe(null,function(){for(;;){var e=$CLJS.y(c);if(e){if($CLJS.Ad(e)){var f=$CLJS.lc(e),k=$CLJS.D(f),l=$CLJS.re(k);a:for(var m=0;;)if(m<k){var t=$CLJS.kd(f,m),u=$CLJS.I(t,0,null);t=$CLJS.I(t,1,null);u=new $CLJS.P(null,2,5,$CLJS.Q,[xW(u),DW.g?DW.g(t,EW):DW.call(null,t,EW)],null);l.add(u);m+=1}else{f=!0;break a}return f?$CLJS.ue($CLJS.we(l),d($CLJS.mc(e))):$CLJS.ue($CLJS.we(l),null)}f=
$CLJS.z(e);l=$CLJS.I(f,0,null);f=$CLJS.I(f,1,null);return $CLJS.fe(new $CLJS.P(null,2,5,$CLJS.Q,[xW(l),DW.g?DW.g(f,EW):DW.call(null,f,EW)],null),d($CLJS.Lc(e)))}return null}},null,null)}(a)}())},$CLJS.kR,function(a){return $CLJS.Ef(function(){return function d(c){return new $CLJS.oe(null,function(){for(;;){var e=$CLJS.y(c);if(e){if($CLJS.Ad(e)){var f=$CLJS.lc(e),k=$CLJS.D(f),l=$CLJS.re(k);a:for(var m=0;;)if(m<k){var t=$CLJS.kd(f,m);t=$CLJS.n(BW(t))?dX.h(t):$CLJS.de(dX.h($CLJS.de(t)));l.add(t);m+=
1}else{f=!0;break a}return f?$CLJS.ue($CLJS.we(l),d($CLJS.mc(e))):$CLJS.ue($CLJS.we(l),null)}l=$CLJS.z(e);return $CLJS.fe($CLJS.n(BW(l))?dX.h(l):$CLJS.de(dX.h($CLJS.de(l))),d($CLJS.Lc(e)))}return null}},null,null)}(a)}())},$CLJS.dQ,function(a){a=tW(a);a=$CLJS.O(a);var b=$CLJS.J.g(a,$CLJS.FP);if($CLJS.n(b))return a=$CLJS.WU(a,new $CLJS.h(null,1,[$CLJS.FP,$CLJS.aF],null)),b=new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.FP],null),a=DW.g?DW.g(a,b):DW.call(null,a,b),$CLJS.WU(a,new $CLJS.h(null,1,[$CLJS.aF,$CLJS.FP],
null));b=new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.aF],null);return DW.g?DW.g(a,b):DW.call(null,a,b)},$CLJS.nO,new $CLJS.h(null,1,[WW,FW],null),$CLJS.$M,new $CLJS.h(null,1,[WW,function(a){a=DW.g?DW.g(a,$CLJS.aF):DW.call(null,a,$CLJS.aF);var b=$CLJS.O(a),c=$CLJS.J.g(b,$CLJS.YD),d=$CLJS.J.g(b,$CLJS.mE);a=$CLJS.J.g(b,$CLJS.qE);b=$CLJS.n(c)?$CLJS.Tk.j(b,$CLJS.YD,sW):b;d=$CLJS.n($CLJS.$z($CLJS.he,$CLJS.Za)(d))?$CLJS.Tk.j(b,$CLJS.mE,sW):b;return $CLJS.n(a)?$CLJS.Tk.j(d,$CLJS.qE,xW):d}],null)],null),$CLJS.gy,
new $CLJS.h(null,1,[$CLJS.LN,$CLJS.Td],null),$CLJS.RM,new $CLJS.h(null,1,[WW,function(a){var b=$CLJS.O(a),c=$CLJS.J.g(b,$CLJS.nj),d=$CLJS.J.g(b,$CLJS.OI),e=$CLJS.J.g(b,$CLJS.$i);a=$CLJS.J.g(b,ZW);b=$CLJS.n(e)?$CLJS.Tk.j(b,$CLJS.$i,xW):b;c=$CLJS.n(c)?$CLJS.Tk.j(b,$CLJS.nj,sW):b;d=$CLJS.n(d)?$CLJS.Tk.j(c,$CLJS.OI,function(f){return DW.g?DW.g(f,EW):DW.call(null,f,EW)}):c;d=$CLJS.n(a)?$CLJS.UW.j(d,new $CLJS.P(null,2,5,$CLJS.Q,[ZW,uma],null),function(f){return DW.g?DW.g(f,EW):DW.call(null,f,EW)}):d;return $CLJS.n(a)?
$CLJS.UW.j(d,new $CLJS.P(null,2,5,$CLJS.Q,[ZW,qma],null),function(f){return DW.g?DW.g(f,EW):DW.call(null,f,EW)}):d}],null),$CLJS.MM,function(a){return null==a?null:sW(a)},$CLJS.nO,new $CLJS.h(null,1,[WW,FW],null),cX,sW],null),DW=function DW(a){for(var c=[],d=arguments.length,e=0;;)if(e<d)c.push(arguments[e]),e+=1;else break;return DW.l(arguments[0],1<c.length?new $CLJS.w(c.slice(1),0,null):null)};
DW.l=function(a,b){b=$CLJS.I(b,0,null);var c=b instanceof $CLJS.M?new $CLJS.P(null,1,5,$CLJS.Q,[b],null):$CLJS.Ef(b);b=$CLJS.y(c)?$CLJS.bA(Cma,c):null;try{return $CLJS.md(b)?b.h?b.h(a):b.call(null,a):$CLJS.yd(a)?a:$CLJS.xd(a)?$CLJS.fg.g($CLJS.N,function(){return function k(f){return new $CLJS.oe(null,function(){for(;;){var l=$CLJS.y(f);if(l){if($CLJS.Ad(l)){var m=$CLJS.lc(l),t=$CLJS.D(m),u=$CLJS.re(t);a:for(var v=0;;)if(v<t){var x=$CLJS.kd(m,v),A=$CLJS.I(x,0,null);x=$CLJS.I(x,1,null);A=sW(A);A=new $CLJS.P(null,
2,5,$CLJS.Q,[A,DW.l(x,$CLJS.H([$CLJS.ce.g($CLJS.Ef(c),A)]))],null);u.add(A);v+=1}else{m=!0;break a}return m?$CLJS.ue($CLJS.we(u),k($CLJS.mc(l))):$CLJS.ue($CLJS.we(u),null)}m=$CLJS.z(l);u=$CLJS.I(m,0,null);m=$CLJS.I(m,1,null);u=sW(u);return $CLJS.fe(new $CLJS.P(null,2,5,$CLJS.Q,[u,DW.l(m,$CLJS.H([$CLJS.ce.g($CLJS.Ef(c),u)]))],null),k($CLJS.Lc(l)))}return null}},null,null)}(a)}()):$CLJS.n(BW(a))?dX.h(a):$CLJS.wd(a)?$CLJS.Sk.g(function(e){return DW.l(e,$CLJS.H([$CLJS.ce.g($CLJS.Ef(c),WW)]))},a):a}catch(e){if(e instanceof
Error){var d=e;throw $CLJS.hi($CLJS.DD("Error normalizing form: {0}",$CLJS.H([$CLJS.PU(d)])),new $CLJS.h(null,3,[$CLJS.yz,a,$CLJS.ql,c,tma,b],null),d);}throw e;}};DW.A=1;DW.B=function(a){var b=$CLJS.z(a);a=$CLJS.B(a);return this.l(b,a)};
var HW=function(){var a=$CLJS.Ye($CLJS.N),b=$CLJS.Ye($CLJS.N),c=$CLJS.Ye($CLJS.N),d=$CLJS.Ye($CLJS.N),e=$CLJS.J.j($CLJS.N,$CLJS.ij,$CLJS.Wh.o?$CLJS.Wh.o():$CLJS.Wh.call(null));return new $CLJS.ei($CLJS.Gh.g("metabase.mbql.normalize","canonicalize-mbql-clause"),function(f){return $CLJS.n(BW(f))?$CLJS.z(f):null},e,a,b,c,d)}();HW.m(null,$CLJS.di,function(a){return a});
HW.m(null,$CLJS.FF,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);if($CLJS.n(CW($CLJS.FF,b))){$CLJS.I(b,0,null);var c=$CLJS.I(b,1,null);b=$CLJS.I(b,2,null);return HW.h(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.FF,c,$CLJS.Ge($CLJS.hl.l($CLJS.H([b,a])))],null))}return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.FF,b,$CLJS.Ge(a)],null)});
HW.m(null,$CLJS.uF,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);return $CLJS.td(a)?new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.uF,b],null):new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.uF,b,a],null)});HW.m(null,$CLJS.rK,function(a){$CLJS.I(a,0,null);a=$CLJS.I(a,1,null);return $CLJS.n(BW(a))?HW.h(a):new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.FF,a,null],null)});
HW.m(null,bX,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.FF,b,new $CLJS.h(null,1,[$CLJS.TA,a],null)],null)});HW.m(null,vma,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);var c=IW(b);$CLJS.I(c,0,null);b=$CLJS.I(c,1,null);$CLJS.I(c,2,null);c=IW(a);$CLJS.I(c,0,null);a=$CLJS.I(c,1,null);c=$CLJS.I(c,2,null);return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.FF,a,$CLJS.R.j(c,$CLJS.aN,b)],null)});
HW.m(null,wma,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);a=IW(a);return $CLJS.Te.v($CLJS.dV,a,$CLJS.R,$CLJS.H([$CLJS.QO,b]))});
HW.m(null,aX,function(a){var b=$CLJS.D(a);switch(b){case 3:$CLJS.I(a,0,null);b=$CLJS.I(a,1,null);var c=$CLJS.I(a,2,null);a=IW(b);b=c;$CLJS.I(a,0,null);$CLJS.I(a,1,null);c=$CLJS.I(a,2,null);c=$CLJS.O(c);var d=$CLJS.J.g(c,$CLJS.TA);$CLJS.Ua(d)||$CLJS.hS.g(d,b)?a=$CLJS.Te.v($CLJS.dV,a,$CLJS.R,$CLJS.H([$CLJS.LF,b])):(c=$CLJS.lA($CLJS.ey),$CLJS.n($CLJS.kA("metabase.mbql.util",c))&&(b=$CLJS.DD("{0} is not a valid temporal unit for {1}; not adding to clause {2}",$CLJS.H([b,d,$CLJS.Nh.l($CLJS.H([a]))])),
b instanceof Error?$CLJS.jA("metabase.mbql.util",c,$CLJS.aw(),b):$CLJS.jA("metabase.mbql.util",c,$CLJS.aw.l($CLJS.H([b])),null)));return a;case 4:return $CLJS.I(a,0,null),b=$CLJS.I(a,1,null),$CLJS.I(a,2,null),c=$CLJS.I(a,3,null),HW.h(new $CLJS.P(null,3,5,$CLJS.Q,[aX,b,c],null));default:throw Error(["No matching clause: ",$CLJS.p.h(b)].join(""));}});
HW.m(null,$CLJS.ZU,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null),c=$CLJS.I(a,2,null),d=$CLJS.I(a,3,null);a=$CLJS.I(a,4,null);var e=IW(b);$CLJS.I(e,0,null);b=$CLJS.I(e,1,null);e=$CLJS.I(e,2,null);return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.FF,b,$CLJS.R.j(e,$CLJS.RQ,$CLJS.hl.l($CLJS.H([new $CLJS.h(null,1,[$CLJS.YD,c],null),$CLJS.n(d)?$CLJS.Fe([c,d]):null,a])))],null)});
for(var gX=$CLJS.y(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Tr,$CLJS.Mr,$CLJS.Or],null)),hX=null,iX=0,jX=0;;)if(jX<iX){var kX=hX.X(null,jX);HW.m(null,kX,function(){return function(a){return JW(a)}}(gX,hX,iX,jX,kX));jX+=1}else{var lX=$CLJS.y(gX);if(lX){var mX=lX;if($CLJS.Ad(mX)){var nX=$CLJS.lc(mX),Dma=$CLJS.mc(mX),Ema=nX,Fma=$CLJS.D(nX);gX=Dma;hX=Ema;iX=Fma}else{var oX=$CLJS.z(mX);HW.m(null,oX,function(){return function(a){return JW(a)}}(gX,hX,iX,jX,oX,mX,lX));gX=$CLJS.B(mX);hX=null;iX=0}jX=0}else break}
HW.m(null,$CLJS.mF,function(a){a=$CLJS.y(a);$CLJS.z(a);var b=$CLJS.B(a);a=$CLJS.z(b);var c=$CLJS.B(b);b=$CLJS.z(c);c=$CLJS.B(c);return $CLJS.fg.g(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.mF,IW(a),IW(b)],null),c)});HW.m(null,$CLJS.RF,function(a){a=$CLJS.y(a);$CLJS.z(a);var b=$CLJS.B(a);a=$CLJS.z(b);b=$CLJS.B(b);var c=IW(a);a=$CLJS.n(yW($CLJS.FF,a))?$CLJS.dV.l(c,$CLJS.Ik,$CLJS.H([$CLJS.LF])):c;return $CLJS.fg.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.RF,a],null),b)});
for(var pX=$CLJS.y(new $CLJS.P(null,15,5,$CLJS.Q,[$CLJS.wF,$CLJS.QF,$CLJS.EF,$CLJS.xF,$CLJS.or,$CLJS.XF,$CLJS.lr,$CLJS.nr,$CLJS.hr,$CLJS.jr,$CLJS.hF,$CLJS.oF,$CLJS.fF,$CLJS.rF,$CLJS.gF],null)),qX=null,rX=0,sX=0;;)if(sX<rX){var tX=qX.X(null,sX);HW.m(null,tX,function(){return function(a){return KW(a)}}(pX,qX,rX,sX,tX));sX+=1}else{var uX=$CLJS.y(pX);if(uX){var vX=uX;if($CLJS.Ad(vX)){var wX=$CLJS.lc(vX),Gma=$CLJS.mc(vX),Hma=wX,Ima=$CLJS.D(wX);pX=Gma;qX=Hma;rX=Ima}else{var xX=$CLJS.z(vX);HW.m(null,xX,
function(){return function(a){return KW(a)}}(pX,qX,rX,sX,xX,vX,uX));pX=$CLJS.B(vX);qX=null;rX=0}sX=0}else break}HW.m(null,sma,function(){return null});HW.m(null,$CLJS.RL,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.RL,HW.h(b),a],null)});
HW.m(null,YW,function(a){a=$CLJS.y(a);$CLJS.z(a);a=$CLJS.B(a);var b=$CLJS.z(a),c=$CLJS.B(a);a=$CLJS.z(c);var d=$CLJS.B(c);c=HW.h;var e=$CLJS.Q;b=HW.h(b);d=$CLJS.I(d,0,null);d=$CLJS.O(d);a=!1===$CLJS.J.g(d,rma)?new $CLJS.h(null,1,[$CLJS.T,a],null):new $CLJS.h(null,1,[$CLJS.ID,a],null);return c.call(HW,new $CLJS.P(null,3,5,e,[$CLJS.RL,b,a],null))});
for(var yX=$CLJS.y(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.rx,$CLJS.GF],null)),zX=null,AX=0,BX=0;;)if(BX<AX){var CX=zX.X(null,BX);HW.m(null,CX,function(){return function(a){return LW(a)}}(yX,zX,AX,BX,CX));BX+=1}else{var DX=$CLJS.y(yX);if(DX){var EX=DX;if($CLJS.Ad(EX)){var FX=$CLJS.lc(EX),Jma=$CLJS.mc(EX),Kma=FX,Lma=$CLJS.D(FX);yX=Jma;zX=Kma;AX=Lma}else{var GX=$CLJS.z(EX);HW.m(null,GX,function(){return function(a){return LW(a)}}(yX,zX,AX,BX,GX,EX,DX));yX=$CLJS.B(EX);zX=null;AX=0}BX=0}else break}
for(var HX=$CLJS.y(new $CLJS.P(null,9,5,$CLJS.Q,[$CLJS.zF,$CLJS.bG,$CLJS.jF,$CLJS.VF,$CLJS.SF,$CLJS.Rn,$CLJS.jk,$CLJS.TF,$CLJS.kF],null)),IX=null,JX=0,KX=0;;)if(KX<JX){var LX=IX.X(null,KX);HW.m(null,LX,function(){return function(a){return MW(a)}}(HX,IX,JX,KX,LX));KX+=1}else{var MX=$CLJS.y(HX);if(MX){var NX=MX;if($CLJS.Ad(NX)){var OX=$CLJS.lc(NX),Mma=$CLJS.mc(NX),Nma=OX,Oma=$CLJS.D(OX);HX=Mma;IX=Nma;JX=Oma}else{var PX=$CLJS.z(NX);HW.m(null,PX,function(){return function(a){return MW(a)}}(HX,IX,JX,KX,
PX,NX,MX));HX=$CLJS.B(NX);IX=null;JX=0}KX=0}else break}HW.m(null,$CLJS.qF,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.qF,IW(b),a],null)});
for(var QX=$CLJS.y(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.MF,$CLJS.OF],null)),RX=null,SX=0,TX=0;;)if(TX<SX){var UX=RX.X(null,TX);HW.m(null,UX,function(){return function(a){return NW(a)}}(QX,RX,SX,TX,UX));TX+=1}else{var VX=$CLJS.y(QX);if(VX){var WX=VX;if($CLJS.Ad(WX)){var XX=$CLJS.lc(WX),Pma=$CLJS.mc(WX),Qma=XX,Rma=$CLJS.D(XX);QX=Pma;RX=Qma;SX=Rma}else{var YX=$CLJS.z(WX);HW.m(null,YX,function(){return function(a){return NW(a)}}(QX,RX,SX,TX,YX,WX,VX));QX=$CLJS.B(WX);RX=null;SX=0}TX=0}else break}
HW.m(null,$CLJS.$F,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.$F,HW.h(b),HW.h(a)],null)});
HW.m(null,$CLJS.nF,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);return $CLJS.n(a)?$CLJS.ce.g(HW.h(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.nF,b],null)),DW.l(a,$CLJS.H([EW]))):new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.nF,$CLJS.Ef(function(){return function e(d){return new $CLJS.oe(null,function(){for(;;){var f=$CLJS.y(d);if(f){if($CLJS.Ad(f)){var k=$CLJS.lc(f),l=$CLJS.D(k),m=$CLJS.re(l);a:for(var t=0;;)if(t<l){var u=$CLJS.kd(k,t),v=$CLJS.I(u,0,null);u=$CLJS.I(u,1,null);v=new $CLJS.P(null,
2,5,$CLJS.Q,[HW.h(v),HW.h(u)],null);m.add(v);t+=1}else{k=!0;break a}return k?$CLJS.ue($CLJS.we(m),e($CLJS.mc(f))):$CLJS.ue($CLJS.we(m),null)}k=$CLJS.z(f);m=$CLJS.I(k,0,null);k=$CLJS.I(k,1,null);return $CLJS.fe(new $CLJS.P(null,2,5,$CLJS.Q,[HW.h(m),HW.h(k)],null),e($CLJS.Lc(f)))}return null}},null,null)}(b)}())],null)});
HW.m(null,$CLJS.XE,function(a){a=$CLJS.y(a);$CLJS.z(a);var b=$CLJS.B(a);a=$CLJS.z(b);var c=$CLJS.B(b);b=$CLJS.z(c);c=$CLJS.B(c);return $CLJS.fg.g(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.XE,HW.h(a),$CLJS.E.g(0,b)?1:HW.h(b)],null),$CLJS.jf.g(HW,c))});
var PW=function PW(a){return function f(d,e){try{if($CLJS.n(function(){var x=vW($CLJS.Dd);return x.h?x.h(e):x.call(null,e)}()))return f(d,$CLJS.Ef(e));throw $CLJS.Y;}catch(x){if(x instanceof Error)if(d=x,d===$CLJS.Y)try{if($CLJS.n(function(){var A=vW($CLJS.he);return A.h?A.h(e):A.call(null,e)}()))return new $CLJS.P(null,1,5,$CLJS.Q,[new $CLJS.P(null,1,5,$CLJS.Q,[e],null)],null);throw $CLJS.Y;}catch(A){if(A instanceof Error)if(d=A,d===$CLJS.Y)try{if($CLJS.zd(e)&&2<=$CLJS.D(e))try{var k=$CLJS.Xk.j(e,
0,2);if($CLJS.zd(k)&&2===$CLJS.D(k))try{var l=$CLJS.F(k,0);if($CLJS.n($CLJS.BD($CLJS.he,$CLJS.Ue(new $CLJS.bh(null,new $CLJS.h(null,5,[$CLJS.rr,null,$CLJS.Ds,null,$CLJS.aG,null,$CLJS.qr,null,YW,null],null),null)))(l)))try{var m=$CLJS.F(k,1);if($CLJS.n(eX(m)))return $CLJS.fg.j($CLJS.Df,$CLJS.CD(PW),a);throw $CLJS.Y;}catch(C){if(C instanceof Error){var t=C;if(t===$CLJS.Y)throw $CLJS.Y;throw t;}throw C;}else throw $CLJS.Y;}catch(C){if(C instanceof Error){t=C;if(t===$CLJS.Y)throw $CLJS.Y;throw t;}throw C;
}else throw $CLJS.Y;}catch(C){if(C instanceof Error){t=C;if(t===$CLJS.Y)throw $CLJS.Y;throw t;}throw C;}else throw $CLJS.Y;}catch(C){if(C instanceof Error)if(t=C,t===$CLJS.Y)try{if($CLJS.zd(e)&&1<=$CLJS.D(e))try{var u=$CLJS.Xk.j(e,0,1);if($CLJS.zd(u)&&1===$CLJS.D(u))try{if($CLJS.F(u,0)instanceof $CLJS.M)return new $CLJS.P(null,1,5,$CLJS.Q,[e],null);throw $CLJS.Y;}catch(G){if(G instanceof Error){var v=G;if(v===$CLJS.Y)throw $CLJS.Y;throw v;}throw G;}else throw $CLJS.Y;}catch(G){if(G instanceof Error){v=
G;if(v===$CLJS.Y)throw $CLJS.Y;throw v;}throw G;}else throw $CLJS.Y;}catch(G){if(G instanceof Error){v=G;if(v===$CLJS.Y)return e;throw v;}throw G;}else throw t;else throw C;}else throw d;else throw A;}else throw d;else throw x;}}($CLJS.Df,a)},ZX=function ZX(a){return function f(d,e){try{var k=vW($CLJS.Dd);var l=k.h?k.h(e):k.call(null,e);if($CLJS.n(l))return f(d,$CLJS.Ef(e));throw $CLJS.Y;}catch(C){if(C instanceof Error)if(l=C,l===$CLJS.Y)try{if($CLJS.zd(e)&&2===$CLJS.D(e))try{var m=$CLJS.F(e,1);if($CLJS.ie(m,
$CLJS.CF)){var t=$CLJS.F(e,0);return f(d,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.CF,t],null))}throw $CLJS.Y;}catch(G){if(G instanceof Error)if(l=G,l===$CLJS.Y)try{m=$CLJS.F(e,1);if($CLJS.ie(m,$CLJS.vF))return t=$CLJS.F(e,0),f(d,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.vF,t],null));throw $CLJS.Y;}catch(K){if(K instanceof Error){var u=K;if(u===$CLJS.Y)try{m=$CLJS.F(e,1);if($CLJS.ie(m,XW))return t=$CLJS.F(e,0),f(d,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.CF,t],null));throw $CLJS.Y;}catch(S){if(S instanceof Error)if(l=
S,l===$CLJS.Y)try{m=$CLJS.F(e,1);if($CLJS.ie(m,$W))return t=$CLJS.F(e,0),f(d,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.vF,t],null));throw $CLJS.Y;}catch(W){if(W instanceof Error)if(m=W,m===$CLJS.Y)try{var v=$CLJS.F(e,0);if($CLJS.ie(v,XW))return t=$CLJS.F(e,1),f(d,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.CF,t],null));throw $CLJS.Y;}catch(Z){if(Z instanceof Error)if(m=Z,m===$CLJS.Y)try{v=$CLJS.F(e,0);if($CLJS.ie(v,$W))return t=$CLJS.F(e,1),f(d,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.vF,t],null));throw $CLJS.Y;}catch(ha){if(ha instanceof
Error)if(m=ha,m===$CLJS.Y)try{v=$CLJS.F(e,0);if($CLJS.ie(v,$CLJS.CF))return t=$CLJS.F(e,1),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.CF,IW(t)],null);throw $CLJS.Y;}catch(ua){if(ua instanceof Error)if(m=ua,m===$CLJS.Y)try{v=$CLJS.F(e,0);if($CLJS.ie(v,$CLJS.vF))return t=$CLJS.F(e,1),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.vF,IW(t)],null);throw $CLJS.Y;}catch(Ta){if(Ta instanceof Error){t=Ta;if(t===$CLJS.Y)throw $CLJS.Y;throw t;}throw Ta;}else throw m;else throw ua;}else throw m;else throw ha;}else throw m;else throw Z;
}else throw m;else throw W;}else throw l;else throw S;}else throw u;}else throw K;}else throw l;else throw G;}else throw $CLJS.Y;}catch(G){if(G instanceof Error)if(l=G,l===$CLJS.Y)try{if($CLJS.zd(e)&&0<=$CLJS.D(e))try{var x=$CLJS.Xk.j(e,0,0);if($CLJS.zd(x)&&0===$CLJS.D(x))try{var A=$CLJS.Xk.g(e,0);if($CLJS.E.g(A,a))return $CLJS.Ef($CLJS.jl.h($CLJS.jf.g(ZX,a)));throw $CLJS.Y;}catch(K){if(K instanceof Error){u=K;if(u===$CLJS.Y)throw $CLJS.Y;throw u;}throw K;}else throw $CLJS.Y;}catch(K){if(K instanceof
Error){u=K;if(u===$CLJS.Y)throw $CLJS.Y;throw u;}throw K;}else throw $CLJS.Y;}catch(K){if(K instanceof Error){u=K;if(u===$CLJS.Y)return $CLJS.wW(f,d,e);throw u;}throw K;}else throw l;else throw G;}else throw l;else throw C;}}($CLJS.Df,a)},SW=$CLJS.Qk.g(uW,function(a){var b=TW($CLJS.uF.h(a))?$CLJS.Tk.j(a,$CLJS.uF,hma):a;b=TW($CLJS.tF.h(a))?$CLJS.Tk.j(b,$CLJS.tF,ima):b;b=TW($CLJS.mE.h(a))?$CLJS.Tk.j(b,$CLJS.mE,$CLJS.We($CLJS.Sk,GW)):b;b=TW($CLJS.kR.h(a))?$CLJS.Tk.j(b,$CLJS.kR,ZX):b;return TW($CLJS.dQ.h(a))?
$CLJS.Tk.j(b,$CLJS.dQ,kma):b}),Sma=new $CLJS.h(null,3,[$CLJS.FP,$CLJS.Td,$CLJS.aF,new $CLJS.h(null,2,[$CLJS.dQ,function(a){a=$CLJS.O(a);var b=$CLJS.J.g(a,$CLJS.FP);if($CLJS.n(b))return a=$CLJS.WU(a,new $CLJS.h(null,1,[$CLJS.FP,$CLJS.aF],null)),b=new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.FP],null),a=VW.g?VW.g(a,b):VW.call(null,a,b),$CLJS.WU(a,new $CLJS.h(null,1,[$CLJS.aF,$CLJS.FP],null));b=new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.aF],null);return VW.g?VW.g(a,b):VW.call(null,a,b)},$CLJS.$M,new $CLJS.h(null,1,
[WW,function(a){var b=new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.aF],null);return VW.g?VW.g(a,b):VW.call(null,a,b)}],null)],null),cX,$CLJS.Td],null),VW=function VW(a){switch(arguments.length){case 1:return VW.h(arguments[0]);case 2:return VW.g(arguments[0],arguments[1]);default:throw Error(["Invalid arity: ",$CLJS.p.h(arguments.length)].join(""));}};VW.h=function(a){return VW.g(a,$CLJS.Df)};
VW.g=function(a,b){try{var c=$CLJS.y(b)?$CLJS.bA(Sma,b):null;return $CLJS.md(c)?c.h?c.h(a):c.call(null,a):$CLJS.yd(a)?a:$CLJS.xd(a)?oma(a,b):$CLJS.wd(a)?pma(a,b):a}catch(d){if(d instanceof Error)throw $CLJS.hi("Error removing empty clauses from form.",new $CLJS.h(null,2,[$CLJS.yz,a,$CLJS.ql,b],null),d);throw d;}};VW.A=2;
$CLJS.$X=function(){var a=$CLJS.Qk.l(VW,nma,lma,$CLJS.H([DW]));return function(b){try{return a(b)}catch(d){if(d instanceof Error){var c=d;throw $CLJS.hi($CLJS.DD("Error normalizing query: {0}",$CLJS.H([$CLJS.PU(c)])),new $CLJS.h(null,1,[$CLJS.aF,b],null),c);}throw d;}}}();$CLJS.aY=function aY(a,b){if($CLJS.Ua($CLJS.y(a)))a=(0,$CLJS.$X)(b);else{var d=$CLJS.J,e=d.g;var f=$CLJS.Js(a);b=$CLJS.Fe([$CLJS.id(a),b]);f=aY.g?aY.g(f,b):aY.call(null,f,b);a=e.call(d,f,$CLJS.id(a))}return a};