var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./lambdaisland.glogi.js");require("./lambdaisland.glogi.print.js");
'use strict';var dz=function(){},ez=function(a){return $CLJS.J.g($CLJS.Tx,a).value},vea=function(a){var b=ez(a);return $CLJS.n(function(){var c=ez($CLJS.jy);return $CLJS.Lk.g?$CLJS.Lk.g(c,b):$CLJS.Lk.call(null,c,b)}())?$CLJS.Py:$CLJS.n(function(){var c=ez($CLJS.iy);return $CLJS.Lk.g?$CLJS.Lk.g(c,b):$CLJS.Lk.call(null,c,b)}())?$CLJS.Zy:$CLJS.n(function(){var c=ez($CLJS.gy);return $CLJS.Lk.g?$CLJS.Lk.g(c,b):$CLJS.Lk.call(null,c,b)}())?$CLJS.Sy:$CLJS.n(function(){var c=ez($CLJS.ay);return $CLJS.Lk.g?
$CLJS.Lk.g(c,b):$CLJS.Lk.call(null,c,b)}())?$CLJS.Yy:$CLJS.n(function(){var c=ez($CLJS.hy);return $CLJS.Lk.g?$CLJS.Lk.g(c,b):$CLJS.Lk.call(null,c,b)}())?$CLJS.$y:$CLJS.n(function(){var c=ez($CLJS.dy);return $CLJS.Lk.g?$CLJS.Lk.g(c,b):$CLJS.Lk.call(null,c,b)}())?$CLJS.Vy:$CLJS.n(function(){var c=ez($CLJS.Yx);return $CLJS.Lk.g?$CLJS.Lk.g(c,b):$CLJS.Lk.call(null,c,b)}())?$CLJS.Wy:$CLJS.Xy},fz=function(a){var b=$CLJS.I(a,0,null);a=$CLJS.I(a,1,null);return new $CLJS.P(null,2,5,$CLJS.Q,[[$CLJS.p.h(b),$CLJS.p.h(" ")].join(""),
a],null)},gz=function(a,b,c){var d=$CLJS.I(a,0,null);a=$CLJS.I(a,1,null);return new $CLJS.P(null,2,5,$CLJS.Q,[[$CLJS.p.h(d),"%c",$CLJS.p.h(b),"%c"].join(""),$CLJS.ce.l(a,["color:",$CLJS.p.h($CLJS.J.g($CLJS.cz,c))].join(""),$CLJS.H(["color:black"]))],null)},hz=function(a,b,c){var d=$CLJS.I(a,0,null);a=$CLJS.I(a,1,null);return new $CLJS.P(null,2,5,$CLJS.Q,[[$CLJS.p.h(d),"%c",$CLJS.p.h(b),"%c"].join(""),$CLJS.ce.l(a,["color:",$CLJS.p.h($CLJS.J.g($CLJS.cz,$CLJS.az)),";background-color:",$CLJS.p.h($CLJS.J.g($CLJS.cz,
c))].join(""),$CLJS.H(["color:black;background-color:inherit"]))],null)},wea=function(a){function b(c,d){return d>=c}a=ez(a);if(b(ez($CLJS.jy),a))return"error";if(b(ez($CLJS.iy),a))return"warn";if(b(ez($CLJS.gy),a))return"info";b(ez($CLJS.ay),a);return"log"},iz=function(a){return function(b){var c=$CLJS.O(b),d=$CLJS.J.g(c,$CLJS.Zx),e=$CLJS.J.g(c,$CLJS.Ts);b=$CLJS.J.g(c,$CLJS.ky);e=wea(e);e=$CLJS.Ca(console,e);e=$CLJS.n(e)?e:console.log;$CLJS.Te.g(e,a.h?a.h(c):a.call(null,c));return $CLJS.n(b)?(c=
["[",$CLJS.p.h(d),"]"].join(""),d=$CLJS.p.h(b),b=b.stack,e.v?e.v(c,d,"\n",b):e.call(null,c,d,"\n",b)):null}},jz=function jz(a,b){for(;;){if($CLJS.E.g($CLJS.Oy,b))return gz(a,", ",$CLJS.Xy);if($CLJS.E.g($CLJS.Qy,b))return fz(a);if(b instanceof $CLJS.M)return gz(a,b,$CLJS.Sy);if(b instanceof $CLJS.r)return gz(a,b,$CLJS.Yy);if("string"===typeof b)return gz(a,$CLJS.Nh.l($CLJS.H([b])),$CLJS.Ry);if($CLJS.Af(b)){var d=fz(function(){var f=a,k=$CLJS.Eb(b);return jz.g?jz.g(f,k):jz.call(null,f,k)}()),e=$CLJS.Fb(b);
return jz.g?jz.g(d,e):jz.call(null,d,e)}if(b instanceof $CLJS.h||b instanceof $CLJS.Ig)return d=a,d=gz(d,"{",$CLJS.bz),d=$CLJS.eb(jz,d,$CLJS.gf($CLJS.Oy,b)),gz(d,"}",$CLJS.bz);if($CLJS.xd(b))return d=a,d=gz(d,["#",$CLJS.p.h(function(){var f=$CLJS.ab(b),k=f.name;return $CLJS.td(k)?$CLJS.Nh.l($CLJS.H([f])):k}())," "].join(""),$CLJS.Ty),d=gz(d,"{",$CLJS.bz),d=$CLJS.eb(jz,d,$CLJS.gf($CLJS.Oy,b)),gz(d,"}",$CLJS.bz);if($CLJS.vd(b))return d=a,d=gz(d,"#{",$CLJS.bz),d=$CLJS.eb(jz,d,$CLJS.gf($CLJS.Qy,b)),gz(d,
"}",$CLJS.bz);if($CLJS.zd(b))return d=a,d=gz(d,"[",$CLJS.bz),d=$CLJS.eb(jz,d,$CLJS.gf($CLJS.Qy,b)),gz(d,"]",$CLJS.bz);if(b instanceof $CLJS.Sf)d=gz(a,"#queue ",$CLJS.Ty),e=$CLJS.fg.g($CLJS.Df,b),a=d,b=e;else{if($CLJS.Dd(b))return d=a,d=gz(d,"(",$CLJS.Ty),d=$CLJS.eb(jz,d,$CLJS.gf($CLJS.Qy,b)),gz(d,")",$CLJS.Ty);if(null!=b?b.I&16384||$CLJS.Bc===b.$i||(b.I?0:$CLJS.$a(dz,b)):$CLJS.$a(dz,b))d=gz(a,"#atom ",$CLJS.Ty),e=$CLJS.q(b),a=d,b=e;else if($CLJS.fi(b))d=gz(a,"#uuid ",$CLJS.Ty),e=$CLJS.p.h(b),a=d,
b=e;else if($CLJS.Ya(b))d=gz(a,"#js ",$CLJS.Ty),e=$CLJS.eb(function(f,k){return function(l,m){return $CLJS.R.j(l,$CLJS.Ah.h(m),$CLJS.Ca(k,m))}}(a,b),$CLJS.N,Object.keys(b)),a=d,b=e;else if($CLJS.Sa(b))d=gz(a,"#js ",$CLJS.Ty),e=$CLJS.fg.g($CLJS.Df,b),a=d,b=e;else return gz(a,$CLJS.Nh.l($CLJS.H([b])),$CLJS.Uy)}}};$CLJS.xea=iz(function(a){a=$CLJS.O(a);$CLJS.J.g(a,$CLJS.Ts);var b=$CLJS.J.g(a,$CLJS.Zx),c=$CLJS.J.g(a,$CLJS.Ri);$CLJS.J.g(a,$CLJS.ky);return new $CLJS.P(null,2,5,$CLJS.Q,[["[",$CLJS.p.h(b),"]"].join(""),c],null)});
$CLJS.yea=iz(function(a){var b=$CLJS.O(a),c=$CLJS.J.g(b,$CLJS.Ts);a=$CLJS.J.g(b,$CLJS.Zx);var d=$CLJS.J.g(b,$CLJS.Ri);$CLJS.J.g(b,$CLJS.ky);b=vea(c);d=jz(fz(hz(hz(hz(new $CLJS.P(null,2,5,$CLJS.Q,["",$CLJS.Df],null),"[",b),a,b),"]",b)),d);a=$CLJS.I(d,0,null);d=$CLJS.I(d,1,null);return $CLJS.fe(a,d)});
$CLJS.zea=iz(function(a){a=$CLJS.O(a);$CLJS.J.g(a,$CLJS.Ts);var b=$CLJS.J.g(a,$CLJS.Zx),c=$CLJS.J.g(a,$CLJS.Ri);$CLJS.J.g(a,$CLJS.ky);return new $CLJS.P(null,2,5,$CLJS.Q,[["[",$CLJS.p.h(b),"]"].join(""),$CLJS.Nh.l($CLJS.H([c]))],null)});