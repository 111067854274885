var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.string.js");require("./metabase.lib.cache.js");require("./metabase.lib.dispatch.js");require("./metabase.lib.hierarchy.js");require("./metabase.lib.join.util.js");require("./metabase.lib.metadata.js");require("./metabase.lib.options.js");require("./metabase.lib.schema.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.expression.js");require("./metabase.lib.schema.metadata.js");require("./metabase.lib.schema.temporal_bucketing.js");require("./metabase.lib.types.isa.js");require("./metabase.lib.util.js");require("./metabase.shared.util.i18n.js");require("./metabase.util.js");require("./metabase.util.log.js");require("./metabase.util.malli.js");require("./metabase.util.malli.registry.js");
'use strict';var Wna,p_,Xna,r_,s_,t_,u_,P_,$na,Zna,aoa,Yna,V_,H_,X_,C_,boa,J_;Wna=function(a){return $CLJS.y(a)?a.normalize("NFKD").replace(RegExp("[̀-ͯ]","gu"),""):null};p_=function(a,b){return 128>a.charCodeAt(0)?$CLJS.Hd($CLJS.Zea,a)?a:"_":$CLJS.n(b)?encodeURIComponent(a):a};
Xna=function(a){var b=$CLJS.O(new $CLJS.h(null,1,[$CLJS.VA,!0],null)),c=$CLJS.J.g(b,$CLJS.Yea),d=$CLJS.J.g(b,$CLJS.VA);return $CLJS.y(a)?(b=$CLJS.TU(function(){return function k(f){return new $CLJS.oe(null,function(){for(;;){var l=$CLJS.y(f);if(l){if($CLJS.Ad(l)){var m=$CLJS.lc(l),t=$CLJS.D(m),u=$CLJS.re(t);a:for(var v=0;;)if(v<t){var x=$CLJS.kd(m,v);x=p_(x,$CLJS.Ua(d));u.add(x);v+=1}else{m=!0;break a}return m?$CLJS.ue($CLJS.we(u),k($CLJS.mc(l))):$CLJS.ue($CLJS.we(u),null)}u=$CLJS.z(l);return $CLJS.fe(p_(u,
$CLJS.Ua(d)),k($CLJS.Lc(l)))}return null}},null,null)}(Wna($CLJS.AA(a)))}()),$CLJS.n(c)?$CLJS.TU($CLJS.bf(c,b)):b):null};$CLJS.q_=function(a){return $CLJS.BO.h($CLJS.z($CLJS.PK.h(a)))};r_=function(a,b){if(null!=a&&null!=a.zg)a=a.zg(a,b);else{var c=r_[$CLJS.wa(null==a?null:a)];if(null!=c)a=c.g?c.g(a,b):c.call(null,a,b);else if(c=r_._,null!=c)a=c.g?c.g(a,b):c.call(null,a,b);else throw $CLJS.bb("MetadataProvider.table",a);}return a};
s_=function(a,b){if(null!=a&&null!=a.qg)a=a.qg(a,b);else{var c=s_[$CLJS.wa(null==a?null:a)];if(null!=c)a=c.g?c.g(a,b):c.call(null,a,b);else if(c=s_._,null!=c)a=c.g?c.g(a,b):c.call(null,a,b);else throw $CLJS.bb("MetadataProvider.card",a);}return a};t_=function(a,b){if(null!=a&&null!=a.ug)a=a.ug(a,b);else{var c=t_[$CLJS.wa(null==a?null:a)];if(null!=c)a=c.g?c.g(a,b):c.call(null,a,b);else if(c=t_._,null!=c)a=c.g?c.g(a,b):c.call(null,a,b);else throw $CLJS.bb("MetadataProvider.metric",a);}return a};
u_=function(a,b){if(null!=a&&null!=a.tg)a=a.tg(a,b);else{var c=u_[$CLJS.wa(null==a?null:a)];if(null!=c)a=c.g?c.g(a,b):c.call(null,a,b);else if(c=u_._,null!=c)a=c.g?c.g(a,b):c.call(null,a,b);else throw $CLJS.bb("MetadataProvider.fields",a);}return a};$CLJS.v_=function(a){return $CLJS.TY(a)?a:$CLJS.ED.h(a)};$CLJS.w_=function(a,b){return r_($CLJS.v_(a),b)};$CLJS.x_=function(a,b){return u_($CLJS.v_(a),b)};$CLJS.y_=function(a,b){return s_($CLJS.v_(a),b)};$CLJS.z_=function(a,b){return t_($CLJS.v_(a),b)};
$CLJS.A_=function(a,b,c){if($CLJS.Ya(b)||$CLJS.xd(b)){$CLJS.n(b.__mbcache)||(b.__mbcache=$CLJS.Ye($CLJS.N));var d=b.__mbcache;if($CLJS.n(d)){var e=$CLJS.J.g($CLJS.q(d),a);if($CLJS.n(e))return e;b=c.h?c.h(b):c.call(null,b);$CLJS.Ph.v(d,$CLJS.R,a,b);return b}}return c.h?c.h(b):c.call(null,b)};
P_=function(a,b,c){var d=$CLJS.B_.j(a,b,c);return $CLJS.hl.l($CLJS.H([$CLJS.fm(d,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.T,$CLJS.ID,$CLJS.Wj],null)),function(){var e=$CLJS.mW.h(c);return $CLJS.n(e)?new $CLJS.h(null,2,[$CLJS.ID,e,C_,!0],null):null}(),function(){var e=$CLJS.D_.v(a,b,c,$CLJS.E_);return $CLJS.n(e)?new $CLJS.h(null,1,[$CLJS.F_,e],null):null}(),function(){var e=$CLJS.$z($CLJS.Gi,$CLJS.TA)(d);return $CLJS.n(e)?new $CLJS.h(null,1,[$CLJS.Gi,e],null):null}(),function(){var e=$CLJS.xK.h(d);return $CLJS.n(e)?
new $CLJS.h(null,1,[$CLJS.kW,$CLJS.G_.j(a,b,$CLJS.Fd(e)?$CLJS.w_(a,e):"string"===typeof e?$CLJS.y_(a,$CLJS.cW(e)):null)],null):null}(),function(){var e=$CLJS.iK.h(d);return $CLJS.n(e)?new $CLJS.h(null,6,[H_,$CLJS.E.g(e,$CLJS.VY),$CLJS.I_,$CLJS.E.g(e,$CLJS.fZ),J_,$CLJS.E.g(e,$CLJS.cZ),$CLJS.K_,$CLJS.E.g(e,$CLJS.eZ),Yna,$CLJS.E.g(e,$CLJS.IK),Zna,$CLJS.E.g(e,$CLJS.zK)],null):null}(),function(){var e=$CLJS.jZ.h(d);return null==e?null:new $CLJS.h(null,1,[$CLJS.L_,e],null)}(),$CLJS.fm(d,new $CLJS.P(null,
3,5,$CLJS.Q,[$CLJS.M_,$CLJS.N_,$CLJS.O_],null))]))};$CLJS.Q_=new $CLJS.M(null,"fk-join-alias","fk-join-alias",997510084);$CLJS.O_=new $CLJS.M(null,"filter-positions","filter-positions",378749375);$CLJS.R_=new $CLJS.M(null,"direction","direction",-633359395);$CLJS.K_=new $CLJS.M(null,"is-implicitly-joinable","is-implicitly-joinable",2054926844);$CLJS.M_=new $CLJS.M(null,"breakout-position","breakout-position",-760153191);
$CLJS.S_=new $CLJS.M(null,"include-implicitly-joinable?","include-implicitly-joinable?",-772701866);$CLJS.L_=new $CLJS.M(null,"selected","selected",574897764);$na=new $CLJS.M(null,"is-order-by-column","is-order-by-column",1320802549);Zna=new $CLJS.M(null,"is-breakout","is-breakout",-1878069983);aoa=new $CLJS.r("metabase.lib.metadata.calculation","display-name-method","metabase.lib.metadata.calculation/display-name-method",37675198,null);
$CLJS.T_=new $CLJS.M("metabase.lib.metadata.calculation","source-field-id","metabase.lib.metadata.calculation/source-field-id",352667413);$CLJS.U_=new $CLJS.M("metabase.lib.metadata.calculation","source-join-alias","metabase.lib.metadata.calculation/source-join-alias",-1432523829);Yna=new $CLJS.M(null,"is-aggregation","is-aggregation",872502629);V_=new $CLJS.M("metabase.lib.metadata.calculation","display-info","metabase.lib.metadata.calculation/display-info",-2072755534);
$CLJS.W_=new $CLJS.M(null,"include-joined?","include-joined?",-410632824);H_=new $CLJS.M(null,"is-from-previous-stage","is-from-previous-stage",956780376);X_=new $CLJS.M(null,"is-source-table","is-source-table",1559075056);C_=new $CLJS.M(null,"named?","named?",-213401078);$CLJS.Y_=new $CLJS.M(null,"unique-name-fn","unique-name-fn",-755402870);$CLJS.I_=new $CLJS.M(null,"is-from-join","is-from-join",-1107917905);$CLJS.Z_=new $CLJS.M(null,"requires-column","requires-column",934105295);
$CLJS.N_=new $CLJS.M(null,"order-by-position","order-by-position",-1307229997);boa=new $CLJS.M(null,"is-breakout-column","is-breakout-column",46784229);$CLJS.F_=new $CLJS.M(null,"long-display-name","long-display-name",1550784464);$CLJS.$_=new $CLJS.M(null,"include-implicitly-joinable-for-source-card?","include-implicitly-joinable-for-source-card?",1240062794);$CLJS.E_=new $CLJS.M(null,"long","long",-171452093);$CLJS.a0=new $CLJS.M(null,"short-name","short-name",-1767085022);
$CLJS.b0=new $CLJS.M(null,"include-expressions?","include-expressions?",-1817639288);J_=new $CLJS.M(null,"is-calculated","is-calculated",-86687269);$CLJS.c0=function(){var a=$CLJS.Ye($CLJS.N),b=$CLJS.Ye($CLJS.N),c=$CLJS.Ye($CLJS.N),d=$CLJS.Ye($CLJS.N),e=$CLJS.J.j(new $CLJS.h(null,1,[$CLJS.ij,$CLJS.zB],null),$CLJS.ij,$CLJS.Wh.o?$CLJS.Wh.o():$CLJS.Wh.call(null));return new $CLJS.ei($CLJS.Gh.g("metabase.lib.metadata.calculation","display-name-method"),function(f,k,l){return $CLJS.xB(l)},e,a,b,c,d)}();
$CLJS.d0=function(){var a=$CLJS.Ye($CLJS.N),b=$CLJS.Ye($CLJS.N),c=$CLJS.Ye($CLJS.N),d=$CLJS.Ye($CLJS.N),e=$CLJS.J.j(new $CLJS.h(null,1,[$CLJS.ij,$CLJS.zB],null),$CLJS.ij,$CLJS.Wh.o?$CLJS.Wh.o():$CLJS.Wh.call(null));return new $CLJS.ei($CLJS.Gh.g("metabase.lib.metadata.calculation","column-name-method"),function(f,k,l){return $CLJS.xB(l)},e,a,b,c,d)}();
$CLJS.D_=function(){function a(f,k,l,m){var t=$CLJS.$z($CLJS.ID,$CLJS.MD)($CLJS.aE(l));if($CLJS.n(t))return t;try{return $CLJS.c0.v(f,k,l,m)}catch(u){if(u instanceof Error)throw k=u,$CLJS.hi($CLJS.DD("Error calculating display name for {0}: {1}",$CLJS.H([$CLJS.Nh.l($CLJS.H([l])),$CLJS.PU(k)])),new $CLJS.h(null,2,[$CLJS.aF,f,$CLJS.VD,l],null),k);throw u;}}function b(f,k,l){return e.v?e.v(f,k,l,$CLJS.di):e.call(null,f,k,l,$CLJS.di)}function c(f,k){return e.j?e.j(f,-1,k):e.call(null,f,-1,k)}function d(f){return e.g?
e.g(f,f):e.call(null,f,f)}var e=null;e=function(f,k,l,m){switch(arguments.length){case 1:return d.call(this,f);case 2:return c.call(this,f,k);case 3:return b.call(this,f,k,l);case 4:return a.call(this,f,k,l,m)}throw Error("Invalid arity: "+arguments.length);};e.h=d;e.g=c;e.j=b;e.v=a;return e}();
$CLJS.e0=function(){function a(d,e,f){var k=$CLJS.T.h($CLJS.aE(f));if($CLJS.n(k))return k;try{return $CLJS.d0.j(d,e,f)}catch(l){if(l instanceof Error)throw k=l,$CLJS.hi($CLJS.DD("Error calculating column name for {0}: {1}",$CLJS.H([$CLJS.Nh.l($CLJS.H([f])),$CLJS.PU(k)])),new $CLJS.h(null,3,[$CLJS.VD,f,$CLJS.aF,d,$CLJS.aL,e],null),k);throw l;}}function b(d,e){return c.j?c.j(d,-1,e):c.call(null,d,-1,e)}var c=null;c=function(d,e,f){switch(arguments.length){case 2:return b.call(this,d,e);case 3:return a.call(this,
d,e,f)}throw Error("Invalid arity: "+arguments.length);};c.g=b;c.j=a;return c}();$CLJS.c0.m(null,$CLJS.di,function(a,b,c){a=$CLJS.lA($CLJS.ey);$CLJS.n($CLJS.kA("metabase.lib.metadata.calculation",a))&&$CLJS.jA("metabase.lib.metadata.calculation",a,$CLJS.RV("Don't know how to calculate display name for %s. Add an impl for %s for %s",$CLJS.H([$CLJS.Nh.l($CLJS.H([c])),aoa,$CLJS.xB(c)])),null);return $CLJS.zd(c)&&$CLJS.z(c)instanceof $CLJS.M?$CLJS.hh($CLJS.z(c)):$CLJS.Nh.l($CLJS.H([c]))});
$CLJS.d0.m(null,$CLJS.di,function(a,b,c){return Xna($CLJS.fA($CLJS.D_.j(a,b,c),/[\(\)]/,""))});$CLJS.f0=function(){var a=$CLJS.Ye($CLJS.N),b=$CLJS.Ye($CLJS.N),c=$CLJS.Ye($CLJS.N),d=$CLJS.Ye($CLJS.N),e=$CLJS.J.j(new $CLJS.h(null,1,[$CLJS.ij,$CLJS.zB],null),$CLJS.ij,$CLJS.Wh.o?$CLJS.Wh.o():$CLJS.Wh.call(null));return new $CLJS.ei($CLJS.Gh.g("metabase.lib.metadata.calculation","describe-top-level-key-method"),function(f,k,l){return l},e,a,b,c,d)}();
$CLJS.coa=function(){function a(d,e,f){return $CLJS.f0.j(d,e,$CLJS.Ah.h(f))}function b(d,e){return c.j?c.j(d,-1,e):c.call(null,d,-1,e)}var c=null;c=function(d,e,f){switch(arguments.length){case 2:return b.call(this,d,e);case 3:return a.call(this,d,e,f)}throw Error("Invalid arity: "+arguments.length);};c.g=b;c.j=a;return c}();
$CLJS.g0=function(){var a=$CLJS.Ye($CLJS.N),b=$CLJS.Ye($CLJS.N),c=$CLJS.Ye($CLJS.N),d=$CLJS.Ye($CLJS.N),e=$CLJS.J.j(new $CLJS.h(null,1,[$CLJS.ij,$CLJS.zB],null),$CLJS.ij,$CLJS.Wh.o?$CLJS.Wh.o():$CLJS.Wh.call(null));return new $CLJS.ei($CLJS.Gh.g("metabase.lib.metadata.calculation","type-of-method"),function(f,k,l){return $CLJS.xB(l)},e,a,b,c,d)}();
$CLJS.h0=function(){function a(d,e,f){var k=$CLJS.aE(f);k=$CLJS.O(k);var l=$CLJS.J.g(k,$CLJS.LF),m=$CLJS.Gi.h(k);if($CLJS.n(m))return m;l=$CLJS.n($CLJS.n(l)?$CLJS.Hd($CLJS.NG,l):l)?$CLJS.xj:null;if($CLJS.n(l))return l;k=$CLJS.TA.h(k);if($CLJS.n(k))return k;d=$CLJS.g0.j(d,e,f);return $CLJS.MB(d,$CLJS.Bj)?d:$CLJS.Bj}function b(d,e){return c.j?c.j(d,-1,e):c.call(null,d,-1,e)}var c=null;c=function(d,e,f){switch(arguments.length){case 2:return b.call(this,d,e);case 3:return a.call(this,d,e,f)}throw Error("Invalid arity: "+
arguments.length);};c.g=b;c.j=a;return c}();$CLJS.g0.m(null,$CLJS.di,function(a,b,c){return $CLJS.hE(c)});$CLJS.g0.m(null,$CLJS.xE,function(a,b,c){$CLJS.I(c,0,null);$CLJS.I(c,1,null);c=$CLJS.I(c,2,null);return $CLJS.h0.j(a,b,c)});$CLJS.g0.m(null,$CLJS.qI,function(a,b,c){$CLJS.I(c,0,null);$CLJS.I(c,1,null);var d=$CLJS.I(c,2,null);return"string"===typeof d?$CLJS.hE(c):$CLJS.h0.j(a,b,d)});
$CLJS.i0=function(){var a=$CLJS.Ye($CLJS.N),b=$CLJS.Ye($CLJS.N),c=$CLJS.Ye($CLJS.N),d=$CLJS.Ye($CLJS.N),e=$CLJS.J.j(new $CLJS.h(null,1,[$CLJS.ij,$CLJS.zB],null),$CLJS.ij,$CLJS.Wh.o?$CLJS.Wh.o():$CLJS.Wh.call(null));return new $CLJS.ei($CLJS.Gh.g("metabase.lib.metadata.calculation","metadata-method"),function(f,k,l){return $CLJS.xB(l)},e,a,b,c,d)}();
$CLJS.i0.m(null,$CLJS.di,function(a,b,c){try{return new $CLJS.h(null,4,[$CLJS.Fj,$CLJS.gK,$CLJS.TA,$CLJS.h0.j(a,b,c),$CLJS.T,$CLJS.e0.j(a,b,c),$CLJS.ID,$CLJS.D_.j(a,b,c)],null)}catch(e){if(e instanceof Error){var d=e;throw $CLJS.hi($CLJS.DD("Error calculating metadata for {0}: {1}",$CLJS.H([$CLJS.Nh.l($CLJS.H([$CLJS.xB(c)])),$CLJS.PU(d)])),new $CLJS.h(null,3,[$CLJS.aF,a,$CLJS.aL,b,$CLJS.VD,c],null),d);}throw e;}});
$CLJS.B_=function(){function a(e,f,k){return $CLJS.i0.j(e,f,k)}function b(e,f){return d.j?d.j(e,-1,f):d.call(null,e,-1,f)}function c(e){return d.j?d.j(e,-1,e):d.call(null,e,-1,e)}var d=null;d=function(e,f,k){switch(arguments.length){case 1:return c.call(this,e);case 2:return b.call(this,e,f);case 3:return a.call(this,e,f,k)}throw Error("Invalid arity: "+arguments.length);};d.h=c;d.g=b;d.j=a;return d}();
$CLJS.j0=function(){var a=$CLJS.Ye($CLJS.N),b=$CLJS.Ye($CLJS.N),c=$CLJS.Ye($CLJS.N),d=$CLJS.Ye($CLJS.N),e=$CLJS.J.j(new $CLJS.h(null,1,[$CLJS.ij,$CLJS.zB],null),$CLJS.ij,$CLJS.Wh.o?$CLJS.Wh.o():$CLJS.Wh.call(null));return new $CLJS.ei($CLJS.Gh.g("metabase.lib.metadata.calculation","display-info-method"),function(f,k,l){return $CLJS.xB(l)},e,a,b,c,d)}();
$CLJS.X(V_,new $CLJS.P(null,20,5,$CLJS.Q,[$CLJS.ik,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ID,new $CLJS.h(null,1,[$CLJS.fs,!0],null),$CLJS.ak],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.F_,new $CLJS.h(null,1,[$CLJS.fs,!0],null),$CLJS.ak],null),new $CLJS.P(null,3,5,$CLJS.Q,[C_,new $CLJS.h(null,1,[$CLJS.fs,!0],null),$CLJS.bs],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.kW,new $CLJS.h(null,1,[$CLJS.fs,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Lr,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,V_],null)],null)],null),
new $CLJS.P(null,3,5,$CLJS.Q,[H_,new $CLJS.h(null,1,[$CLJS.fs,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Lr,$CLJS.bs],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.I_,new $CLJS.h(null,1,[$CLJS.fs,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Lr,$CLJS.bs],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[J_,new $CLJS.h(null,1,[$CLJS.fs,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Lr,$CLJS.bs],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.K_,new $CLJS.h(null,1,[$CLJS.fs,!0],null),new $CLJS.P(null,
2,5,$CLJS.Q,[$CLJS.Lr,$CLJS.bs],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[X_,new $CLJS.h(null,1,[$CLJS.fs,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Lr,$CLJS.bs],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[boa,new $CLJS.h(null,1,[$CLJS.fs,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Lr,$CLJS.bs],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$na,new $CLJS.h(null,1,[$CLJS.fs,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Lr,$CLJS.bs],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.T,new $CLJS.h(null,
1,[$CLJS.fs,!0],null),$CLJS.ak],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.pF,new $CLJS.h(null,1,[$CLJS.fs,!0],null),$CLJS.ak],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.lt,new $CLJS.h(null,1,[$CLJS.fs,!0],null),$CLJS.ak],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.a0,new $CLJS.h(null,1,[$CLJS.fs,!0],null),$CLJS.ak],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Z_,new $CLJS.h(null,1,[$CLJS.fs,!0],null),$CLJS.bs],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.L_,new $CLJS.h(null,1,[$CLJS.fs,!0],null),$CLJS.bs],
null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.di,new $CLJS.h(null,1,[$CLJS.fs,!0],null),$CLJS.bs],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.R_,new $CLJS.h(null,1,[$CLJS.fs,!0],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Jr,$CLJS.CF,$CLJS.vF],null)],null)],null));
$CLJS.G_=function(){function a(d,e,f){return $CLJS.A_($CLJS.Ah.g("display-info",["stage-",$CLJS.p.h(e)].join("")),f,function(k){try{return $CLJS.j0.j(d,e,k)}catch(m){if(m instanceof Error){var l=m;throw $CLJS.hi($CLJS.DD("Error calculating display info for {0}: {1}",$CLJS.H([$CLJS.xB(k),$CLJS.PU(l)])),new $CLJS.h(null,3,[$CLJS.aF,d,$CLJS.aL,e,$CLJS.VD,k],null),l);}throw m;}})}function b(d,e){return c.j?c.j(d,-1,e):c.call(null,d,-1,e)}var c=null;c=function(d,e,f){switch(arguments.length){case 2:return b.call(this,
d,e);case 3:return a.call(this,d,e,f)}throw Error("Invalid arity: "+arguments.length);};c.g=b;c.j=a;return c}();$CLJS.j0.m(null,$CLJS.di,function(a,b,c){return P_(a,b,c)});$CLJS.j0.m(null,$CLJS.oK,function(a,b,c){return $CLJS.hl.l($CLJS.H([P_(a,b,c),new $CLJS.h(null,1,[X_,$CLJS.E.g($CLJS.q_(a),$CLJS.$i.h(c))],null)]))});
$CLJS.k0=function(){var a=$CLJS.Ye($CLJS.N),b=$CLJS.Ye($CLJS.N),c=$CLJS.Ye($CLJS.N),d=$CLJS.Ye($CLJS.N),e=$CLJS.J.j(new $CLJS.h(null,1,[$CLJS.ij,$CLJS.zB],null),$CLJS.ij,$CLJS.Wh.o?$CLJS.Wh.o():$CLJS.Wh.call(null));return new $CLJS.ei($CLJS.Gh.g("metabase.lib.metadata.calculation","returned-columns-method"),function(f,k,l){return $CLJS.xB(l)},e,a,b,c,d)}();$CLJS.k0.m(null,$CLJS.WA,function(){return $CLJS.Df});
$CLJS.l0=function(){function a(f,k,l,m){m=$CLJS.hl.l($CLJS.H([new $CLJS.h(null,1,[$CLJS.Y_,$CLJS.eW()],null),m]));return $CLJS.k0.v(f,k,l,m)}function b(f,k,l){return e.v?e.v(f,k,l,null):e.call(null,f,k,l,null)}function c(f,k){return e.j?e.j(f,-1,k):e.call(null,f,-1,k)}function d(f){var k=$CLJS.bW(f,-1);return e.g?e.g(f,k):e.call(null,f,k)}var e=null;e=function(f,k,l,m){switch(arguments.length){case 1:return d.call(this,f);case 2:return c.call(this,f,k);case 3:return b.call(this,f,k,l);case 4:return a.call(this,
f,k,l,m)}throw Error("Invalid arity: "+arguments.length);};e.h=d;e.g=c;e.j=b;e.v=a;return e}();$CLJS.m0=function(){var a=$CLJS.Ye($CLJS.N),b=$CLJS.Ye($CLJS.N),c=$CLJS.Ye($CLJS.N),d=$CLJS.Ye($CLJS.N),e=$CLJS.J.j(new $CLJS.h(null,1,[$CLJS.ij,$CLJS.zB],null),$CLJS.ij,$CLJS.Wh.o?$CLJS.Wh.o():$CLJS.Wh.call(null));return new $CLJS.ei($CLJS.Gh.g("metabase.lib.metadata.calculation","visible-columns-method"),function(f,k,l){return $CLJS.xB(l)},e,a,b,c,d)}();$CLJS.m0.m(null,$CLJS.WA,function(){return $CLJS.Df});
$CLJS.m0.m(null,$CLJS.di,function(a,b,c,d){return $CLJS.k0.v(a,b,c,d)});
$CLJS.n0=function(){function a(f,k,l,m){m=$CLJS.hl.l($CLJS.H([$CLJS.hl.l($CLJS.H([new $CLJS.h(null,1,[$CLJS.Y_,$CLJS.eW()],null),new $CLJS.h(null,4,[$CLJS.W_,!0,$CLJS.b0,!0,$CLJS.S_,!0,$CLJS.$_,!0],null)])),m]));return $CLJS.m0.v(f,k,l,m)}function b(f,k,l){return e.v?e.v(f,k,l,null):e.call(null,f,k,l,null)}function c(f,k){return e.j?e.j(f,-1,k):e.call(null,f,-1,k)}function d(f){var k=$CLJS.bW(f,-1);return e.g?e.g(f,k):e.call(null,f,k)}var e=null;e=function(f,k,l,m){switch(arguments.length){case 1:return d.call(this,
f);case 2:return c.call(this,f,k);case 3:return b.call(this,f,k,l);case 4:return a.call(this,f,k,l,m)}throw Error("Invalid arity: "+arguments.length);};e.h=d;e.g=c;e.j=b;e.v=a;return e}();module.exports={display_name:$CLJS.D_};